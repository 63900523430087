import * as React from 'react'
import {connect} from 'react-redux'
import {currentInitiativeSelector, updateCurrentInitiative} from '../../../../../data/initiative'
import {peopleCostOnConflictCalculationSelector} from '../../../../../data/plan/selectors/peopleCostOnConflictCalculationSelector'
import {showConflictsSelector} from '../../../../../data/scenario'

import {IDestructuredRoleConflict, IRole} from '../../../../../shared/models'
import {IInitiativeRole} from '../../../../../shared/models/initiative'
import {IDistribution} from '../../../../../shared/models/initiative/IDistribution'
import {CarouselDistributionComponent} from '../carousel/CarouselDistributionComponent'

export interface IRoleDistributionComponentProps {
  role: IInitiativeRole
  initiativeRoles: IInitiativeRole[]
  isFocused: boolean
  onFocus: () => void
  onBlur: () => void
  showConflicts: boolean
  updateCurrentInitiativeRolesFunction: (
    roles,
    showConflicts,
    usePeopleCostOnConflictCalculation: boolean
  ) => void
  usePeopleCostOnConflictCalculation: boolean
  periodConflictDetails: IDestructuredRoleConflict
}

export const RoleDistribution: React.FC<IRoleDistributionComponentProps> = React.memo(
  ({
    role,
    initiativeRoles,
    updateCurrentInitiativeRolesFunction,
    showConflicts,
    usePeopleCostOnConflictCalculation,
    isFocused,
    onFocus,
    onBlur,
    periodConflictDetails,
  }) => {
    const onBlurHandler = React.useCallback(
      (distribution: IDistribution): void => {
        onBlur()
        const changedRole = {
          ...role,
          distribution,
        }

        const newRoles: IRole[] = initiativeRoles.map((initiativeRole) =>
          initiativeRole.id === changedRole.id ? changedRole : initiativeRole
        )

        updateCurrentInitiativeRolesFunction(
          newRoles,
          showConflicts,
          usePeopleCostOnConflictCalculation
        )
      },
      [onBlur, role, showConflicts, usePeopleCostOnConflictCalculation, initiativeRoles]
    )

    return (
      <CarouselDistributionComponent
        isFocused={isFocused}
        roleId={role.id}
        testID={`role-distribution-${role.id}`}
        onBlur={onBlurHandler}
        onFocus={onFocus}
        distribution={role.distribution}
        periodConflictDetails={periodConflictDetails}
      />
    )
  }
)

const mapStateToProps = (state) => ({
  initiativeRoles: currentInitiativeSelector(state)!.people.roles,
  showConflicts: showConflictsSelector(state),
  usePeopleCostOnConflictCalculation: peopleCostOnConflictCalculationSelector(state),
})

RoleDistribution.displayName = 'RoleDistribution'

const mapDispatchToProps = (dispatch) => ({
  updateCurrentInitiativeRolesFunction: (
    roles,
    showConflicts,
    usePeopleCostOnConflictCalculation
  ) => {
    const people = {roles}
    dispatch(updateCurrentInitiative({people}, showConflicts, usePeopleCostOnConflictCalculation))
  },
})

export const RoleDistributionComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(RoleDistribution)
