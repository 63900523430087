import {fetchData} from './api/ApiService'
import {IPublishedInitiatives} from '../models'
export class PublishService {
  publishInitiatives(scenarioId: string, data: string[]) {
    return fetchData<IPublishedInitiatives>(`/scenarios/${scenarioId}/publish`, {
      data,
      method: 'POST',
    })
  }
}
