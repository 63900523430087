import * as React from 'react'
import {css} from 'react-emotion'
import {primary} from '@phoenix/all'

const SeparatorStyle = css`
  width: 1px;
  height: 24px;
  position: relative;
  background-color: ${primary.gray(200)};
`

function SeparatorWidget(/*props*/) {
  return <div className={SeparatorStyle} />
}

export default React.memo(SeparatorWidget)
