import {ConfirmDialog, Text} from '@phoenix/all'
import {Moment} from 'moment'
import * as React from 'react'
import styled, {keyframes} from 'react-emotion'
import {connect} from 'react-redux'

import {changeCurrentInitiative} from '../../../../data/initiative'

import {currentInitiativeSelector} from '../../../../data/initiative/'
import {currentPlanSelector} from '../../../../data/plan'
import {
  resolveConflicts,
  setImportedInitiativesIds,
  updateInitiative,
} from '../../../../data/scenario'
import {currentScenarioSelector} from '../../../../data/scenario/'
import {importedInitiativesIdsSelector} from '../../../../data/scenario/selectors/importedInitiativesIdsSelector'
import {
  changeResolvableRoles,
  changeVisibleConflict,
  isBudgetResolvableSelector,
  isEditSidebarOpenSelector,
  isReorderSelector,
  resolvableRolesSelector,
  showConflictsSelector,
  toggleBudgetResolvable,
  updateBudgetConflictMonthIndex,
  updateRoleConflictMonthIndex,
} from '../../../../data/viewOptions'
import {
  getBackgroundColor,
  getBackgroundHoverColor,
  getColorFullOpacity,
  hasInitiativeChanges,
  resolveBudgetConflict,
  resolveRoleConflicts,
} from '../../../../shared/helpers'
import {IInitiative} from '../../../../shared/models/initiative'
import {IScenario, IScenarioBudgets, IScenarioRole} from '../../../../shared/models/scenario'
import {InitiativeInfoComponent} from './InitiativeInfo/InitiativeInfoComponent'
import {InitiativeRightComponent} from './InitiativeRightComponent'
import {peopleCostOnConflictCalculationSelector} from '../../../../data/plan/selectors/peopleCostOnConflictCalculationSelector'
import {SelectionCountIndicatorComponent} from '../SelectionCountIndicatorComponent'
import {globalRolesSelector} from '../../../../data/globalRoles'
import {IGlobalRoles} from '../../../../shared/models/IGlobalRoles'
import {hoursForMeasurementsSelector} from '../../../../data/plan/selectors/hoursForMeasurementsSelector'
import {Translate} from '../../../shared/Translate'
import {ConfirmationDialogHeaderComponent} from '../../../shared/ConfirmationDialogHeaderComponent'

export interface IInitiativeComponentProps {
  initiative: IInitiative
  currentInitiative: IInitiative
  currentScenario: IScenario
  changeCurrentInitiativeFunction: (
    initiative: IInitiative | null,
    currentScenario: IScenario | null,
    usePeopleCostOnConflictCalculation: boolean
  ) => void
  applyChangesFunction: (
    currentInitiative: IInitiative,
    usePeopleCostOnConflictCalculation: boolean
  ) => void
  isScrolling: boolean
  isReordering: boolean
  isEditSidebarOpen: boolean
  isBudgetResolvable: boolean
  resolvableRoles: string[]
  hasConflict: boolean
  resetCarouselFunction: () => void
  changeVisibleConflictFunction: (conflict) => void
  resolveConflictsFunction: (
    initiative: IInitiative,
    resolvedRoles: IScenarioRole[],
    resolvedBudgets: IScenarioBudgets | null,
    usePeopleCostOnConflictCalculation: boolean
  ) => void
  toggleBudgetResolvableFunction: (isBudgetResolvable: boolean) => void
  resolvableRolesChangeFunction: (resolvableRoles: string[]) => void
  showConflicts: boolean
  planStartDate: Moment
  planDuration: number
  importedInitiativesIds: string[]
  setImportedInitiativesIdsFunction: (ids: string[]) => void
  usePeopleCostOnConflictCalculation: boolean
  isScrollbarAtLeft: boolean
  globalRoles: IGlobalRoles
  useHoursForMeasurements: boolean
}

export interface IInitiativeComponentState {
  isWarningDialogOpen: boolean
  isHovered: boolean
}

interface IInitiativeRowStyledProps {
  isEditSidebarOpen: boolean
  isReordering: boolean
  isCurrentInitiative: boolean
  hasConflict: boolean
  animateImportedInitiative: boolean
}

const animateImport = keyframes`
  0% {
    background-color: rgba(179, 214, 243, 0.3);
  }

  25% {
    background-color: rgba(179, 214, 243, 0.3);
  }

  to {
    background: transparent;
  }
`

const ANIMATE_TIME = 3

const InitiativeRowStyled = styled('div')<IInitiativeRowStyledProps>`
  display: flex;

  ${(props) =>
    props.isEditSidebarOpen &&
    `
    &:after {
      cursor: pointer;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 10;
    }
    `}
  .initiative-info-background-container,
  .timeline-row {
    background-color: ${(props) =>
      getBackgroundColor(props.hasConflict, props.isCurrentInitiative, props.isEditSidebarOpen)};
  }

  .initiative-published-flag {
    stroke: ${(props) =>
      getColorFullOpacity(
        getBackgroundColor(props.hasConflict, props.isCurrentInitiative, props.isEditSidebarOpen)
      )};
  }

  &:hover {
    .initiative-info-background-container,
    .timeline-row {
      background-color: ${(props) =>
        getBackgroundHoverColor(props.hasConflict, props.isCurrentInitiative)};
    }

    .initiative-published-flag {
      stroke: ${(props) =>
        getColorFullOpacity(getBackgroundHoverColor(props.hasConflict, props.isCurrentInitiative))};
    }

    .priority-drag-handler {
      opacity: ${(props) => (props.isReordering ? 0 : 1)};
    }
  }

  ${(props) =>
    props.animateImportedInitiative &&
    `
        .initiative-right-container {
            position: relative;
        }
        .initiative-info-container:before,
        .initiative-right-container:after {
            animation: ${animateImport} ${ANIMATE_TIME}s;
            content: '';
            position: absolute;
            width: 100%;
            height: calc(100% - 4px);
            top: 2px;
            left: 0;
        }
    `}
`

export class Initiative extends React.Component<
  IInitiativeComponentProps,
  IInitiativeComponentState
> {
  constructor(props: IInitiativeComponentProps) {
    super(props)
    this.state = {
      isWarningDialogOpen: false,
      isHovered: false,
    }
  }

  componentDidUpdate(): void {
    if (this.props.importedInitiativesIds && this.props.importedInitiativesIds.length) {
      setTimeout(() => {
        this.props.setImportedInitiativesIdsFunction([])
      }, ANIMATE_TIME * 1000)
    }
  }

  render() {
    const {
      initiative,
      currentInitiative,
      isScrolling,
      isReordering,
      isEditSidebarOpen,
      hasConflict,
      isScrollbarAtLeft,
    } = this.props
    const isCurrentInitiative = currentInitiative && currentInitiative.id === initiative.id
    const animateImportedInitiative =
      this.props.importedInitiativesIds && this.props.importedInitiativesIds.includes(initiative.id)

    return (
      <>
        <InitiativeRowStyled
          hasConflict={hasConflict}
          animateImportedInitiative={animateImportedInitiative}
          data-testid="initiative-row-inner"
          isCurrentInitiative={isCurrentInitiative}
          onClick={this.handleClick}
          isEditSidebarOpen={isEditSidebarOpen}
          isReordering={isReordering}
          onMouseEnter={this.onMouseEnterHandler}
          onMouseLeave={this.onMouseOutHandler}
        >
          <InitiativeInfoComponent
            isScrolling={isScrolling}
            makeRowMouseOut={this.onMouseOutHandler}
            initiative={initiative}
            isScrollbarAtLeft={isScrollbarAtLeft}
          />

          <InitiativeRightComponent initiative={initiative} isHovered={this.state.isHovered} />

          {/* The selection container is shown only when drag dropping multiple initiatives */}
          <SelectionCountIndicatorComponent />
        </InitiativeRowStyled>

        {this.state.isWarningDialogOpen && (
          <ConfirmDialog
            header={<ConfirmationDialogHeaderComponent messageKey={'global.unapplied'} />}
            confirmText={<Translate messageKey={'form.button.apply'} />}
            denyText={<Translate messageKey={'form.button.discard'} />}
            onConfirmClick={this.applyChanges}
            onDenyClick={this.discardChanges}
          >
            <Text>
              <Translate messageKey={'sidebar.confirmation.message'} />{' '}
            </Text>
          </ConfirmDialog>
        )}
      </>
    )
  }

  private onMouseOutHandler = () => {
    this.setState({
      isHovered: false,
    })
  }

  private onMouseEnterHandler = () => {
    if (!this.props.isEditSidebarOpen) {
      this.setState({
        isHovered: true,
      })
    }
  }

  private handleClick = () => {
    if (
      this.props.isEditSidebarOpen &&
      this.props.currentInitiative.id !== this.props.initiative.id
    ) {
      const {currentInitiative, currentScenario} = this.props

      const hasChanges = hasInitiativeChanges(currentScenario, currentInitiative)

      if (hasChanges) {
        this.setState({
          isWarningDialogOpen: true,
        })
      } else {
        this.changeCurrentInitiative()
      }
    }
  }

  private resolveConflicts = () => {
    const {
      showConflicts,
      currentInitiative,
      resolvableRoles,
      isBudgetResolvable,
      planStartDate,
      planDuration,
      globalRoles,
      useHoursForMeasurements,
    } = this.props
    const {people, budgets} = this.props.currentScenario

    if (showConflicts && currentInitiative.conflicts !== null) {
      const resolvedRoles = resolveRoleConflicts(
        people.roles,
        resolvableRoles,
        currentInitiative.conflicts.roleConflicts,
        planStartDate,
        planDuration,
        globalRoles,
        useHoursForMeasurements
      )
      const resolvedBudgets =
        isBudgetResolvable && currentInitiative.conflicts.budgetConflicts
          ? resolveBudgetConflict(budgets!, currentInitiative.conflicts.budgetConflicts)
          : null
      this.props.resolveConflictsFunction(
        currentInitiative,
        resolvedRoles,
        resolvedBudgets,
        this.props.usePeopleCostOnConflictCalculation
      )
    } else {
      this.props.applyChangesFunction(
        this.props.currentInitiative,
        this.props.usePeopleCostOnConflictCalculation
      )
    }
  }

  private applyChanges = () => {
    this.resolveConflicts()

    this.setState({
      isWarningDialogOpen: false,
    })
    this.props.resolvableRolesChangeFunction([])
    this.props.toggleBudgetResolvableFunction(false)

    setTimeout(this.changeCurrentInitiative)
  }

  private discardChanges = () => {
    this.setState({
      isWarningDialogOpen: false,
    })
    this.changeCurrentInitiative()
  }

  private changeCurrentInitiative = () => {
    this.props.resetCarouselFunction()
    this.props.changeCurrentInitiativeFunction(
      this.props.initiative,
      this.props.showConflicts ? this.props.currentScenario : null,
      this.props.usePeopleCostOnConflictCalculation
    )
    this.props.changeVisibleConflictFunction(null)
    if (this.props.resolvableRoles.length > 0) {
      this.props.resolvableRolesChangeFunction([])
    }
    if (this.props.isBudgetResolvable) {
      this.props.toggleBudgetResolvableFunction(false)
    }
  }
}

const mapStateToProps = (state) => ({
  currentInitiative: currentInitiativeSelector(state),
  currentScenario: currentScenarioSelector(state),
  isReordering: isReorderSelector(state),
  isEditSidebarOpen: isEditSidebarOpenSelector(state),
  isBudgetResolvable: isBudgetResolvableSelector(state),
  resolvableRoles: resolvableRolesSelector(state),
  showConflicts: showConflictsSelector(state),
  planStartDate: currentPlanSelector(state)!.startDate,
  planDuration: currentPlanSelector(state)!.duration,
  importedInitiativesIds: importedInitiativesIdsSelector(state),
  usePeopleCostOnConflictCalculation: peopleCostOnConflictCalculationSelector(state),
  globalRoles: globalRolesSelector(state),
  useHoursForMeasurements: hoursForMeasurementsSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  changeCurrentInitiativeFunction: (
    currentInitiative,
    currentScenario,
    usePeopleCostOnConflictCalculation
  ) =>
    dispatch(
      changeCurrentInitiative(
        currentInitiative,
        currentScenario,
        usePeopleCostOnConflictCalculation
      )
    ),
  applyChangesFunction: (currentInitiative, usePeopleCostOnConflictCalculation) =>
    dispatch(updateInitiative(currentInitiative, usePeopleCostOnConflictCalculation)),
  changeVisibleConflictFunction: (conflict) => dispatch(changeVisibleConflict(conflict)),
  toggleBudgetResolvableFunction: (isBudgetResolvable) =>
    dispatch(toggleBudgetResolvable(isBudgetResolvable)),
  resolvableRolesChangeFunction: (resolvableRoles) =>
    dispatch(changeResolvableRoles(resolvableRoles)),
  resetCarouselFunction: () => {
    dispatch(updateRoleConflictMonthIndex(0))
    dispatch(updateBudgetConflictMonthIndex(0))
  },
  resolveConflictsFunction: (
    initiative,
    resolvedRoles,
    resolvedBudgets,
    usePeopleCostOnConflictCalculation
  ) =>
    dispatch(
      resolveConflicts(
        initiative,
        resolvedRoles,
        resolvedBudgets,
        usePeopleCostOnConflictCalculation
      )
    ),
  setImportedInitiativesIdsFunction: (ids) => dispatch(setImportedInitiativesIds(ids)),
})

export const InitiativeComponent = connect(mapStateToProps, mapDispatchToProps)(Initiative)
