import {IScenarioRole, IScenarioRoleChange} from '../models'
import {IGlobalRoles} from '../models/IGlobalRoles'
import {ITimelineResolvedRole} from '../models/scenario/ITimelineResolvedRole'
import {isSameDateByMonthAndYear} from '../utilities/dateHelpers'
import find from 'lodash/find'
import {YEAR_MONTH_FORMAT} from '../constants/dateFormats'

export function deserializeGlobalRoles(roles): IGlobalRoles {
  const availableRoles: IGlobalRoles = {}

  roles.forEach((role) => {
    availableRoles[role.ID] = {
      name: role.name,
      costPerHour: role.costPerHour || null,
    }
  })

  return availableRoles
}

export function createTimelineResolvedRoles(
  scenarioRoles: IScenarioRole[]
): ITimelineResolvedRole[] | null {
  const timelineResolvedRoles: ITimelineResolvedRole[] = []

  scenarioRoles.forEach((scenarioRole: IScenarioRole) => {
    scenarioRole.changes.forEach((roleChange: IScenarioRoleChange) => {
      const resolvedRole = timelineResolvedRoles.find((role) => {
        return isSameDateByMonthAndYear(role.resolvedDate, roleChange.yearMonthDate)
      })

      if (resolvedRole) {
        resolvedRole.totalCount += roleChange.change
        resolvedRole.roles!.push({
          id: scenarioRole.role.id,
          name: scenarioRole.role.name,
          count: roleChange.change,
        })
      } else {
        timelineResolvedRoles.push({
          resolvedDate: roleChange.yearMonthDate,
          totalCount: roleChange.change,
          roles: [
            {
              id: scenarioRole.role.id,
              name: scenarioRole.role.name,
              count: roleChange.change,
            },
          ],
        })
      }
    })
  })

  return timelineResolvedRoles.length ? timelineResolvedRoles : null
}

export function updateRoleChanges(
  updatedRoles: IScenarioRole[],
  scenarioRoles: IScenarioRole[]
): IScenarioRole[] {
  const updatedResolvedRoles: IScenarioRole[] = []

  updatedRoles.forEach((updatedRole) => {
    let removedCount = 0
    const roleId = updatedRole.role.id
    const changes = [...updatedRole.changes]
    const scenarioRoleDetails = scenarioRoles.find(
      (scenarioRole) => scenarioRole.role.id === roleId
    )

    if (scenarioRoleDetails) {
      updatedRole.changes.forEach((changedItem, index) => {
        const date = changedItem.yearMonthDate.format(YEAR_MONTH_FORMAT)
        const availableCount = find(
          scenarioRoleDetails.availablePerPeriod,
          (count, period) => period === date
        )

        if (
          typeof availableCount !== 'undefined' &&
          availableCount !== updatedRole.availablePerPeriod[date]
        ) {
          changes.splice(index - removedCount, 1)
          removedCount++
        }
      })
    }

    updatedResolvedRoles.push({
      ...updatedRole,
      changes,
    })
  })

  return updatedResolvedRoles
}
