import {PageTitle, ConfirmDialog, Text} from '@phoenix/all'
import {getStorageUtil} from '@workfront/storage'
import {css} from 'emotion'
import * as React from 'react'
import {connect} from 'react-redux'
import {EspPlan} from 'workfront-objcodes'
import {currentPlanSelector, deletePlan, updateCurrentPlan} from '../../data/plan'
import {viewAccessSelector} from '../../data/settings'
import {isNewPlan} from '../../shared/helpers'
import {IPlan} from '../../shared/models'
import SplitContext from '../../shared/context/SplitContext'
import {FavoriteComponent} from './FavoriteComponent'
import {PlanMoreOptionsComponent} from './PlanMoreOptionsComponent'
import {Translate} from '../shared/Translate'
import {ConfirmationDialogHeaderComponent} from '../shared/ConfirmationDialogHeaderComponent'

interface IPlanActionsComponentProps {
  plan: IPlan
  isViewMode: boolean
  showSharingDialog: () => void
  navigateToListFunction: () => void
  applyChangeOnCurrentPlanFunction: (changes) => void
  deletePlanFunction: (plan: IPlan) => Promise<void>
}

interface IPlanActionsComponentState {
  inlineEditValue: string
  isPlanNameInEditMode: boolean
  isConfirmDialogOpen: boolean
}

const scenarioHeaderActionsClass = css`
  display: flex;
  align-items: center;
  transition: width 0.5s ease;
  width: 100%;
  max-width: calc(100vw - 700px);
  padding-top: 8px;
  padding-right: 48px;

  label {
    display: none;
  }
`

const flex = css`
  display: flex;
`

export class PlanActions extends React.Component<
  IPlanActionsComponentProps,
  IPlanActionsComponentState
> {
  static contextType = SplitContext

  planNameRef

  constructor(props: IPlanActionsComponentProps) {
    super(props)
    this.state = {
      inlineEditValue: props.plan.name || '',
      isPlanNameInEditMode: false,
      isConfirmDialogOpen: false,
    }

    this.planNameRef = React.createRef()
  }

  componentDidUpdate(prevProps: Readonly<IPlanActionsComponentProps>): void {
    if (prevProps.plan.name !== this.props.plan.name) {
      this.setState({
        inlineEditValue: this.props.plan.name,
      })
    }
    if (this.planNameRef.current) {
      this.planNameRef.current.focus()
    }
  }

  render() {
    const {inlineEditValue} = this.state
    const {isViewMode, plan} = this.props

    const siblingContent = (
      <>
        <FavoriteComponent
          objID={plan.id}
          objCode={EspPlan}
          objName={plan.name}
          disabled={isNewPlan(plan)}
        />

        <PlanMoreOptionsComponent performAction={this.performAction} />
      </>
    )

    return (
      <div className={scenarioHeaderActionsClass}>
        <PageTitle
          isEditable={!isViewMode}
          key={inlineEditValue}
          objectCode={EspPlan}
          objectLabel={<Translate messageKey="sp.plan" />}
          onChange={this.handleNewPageTitleChange}
          titleText={inlineEditValue}
          siblingContent={<div className={flex}>{siblingContent}</div>}
        />
        {this.state.isConfirmDialogOpen && (
          <ConfirmDialog
            header={
              <ConfirmationDialogHeaderComponent
                messageKey={'sp.dialog.header.plan.delete'}
                args={{count: 1}}
              />
            }
            confirmText={<Translate messageKey={'form.button.delete'} />}
            denyText={<Translate messageKey={'form.button.cancel'} />}
            onConfirmClick={() => this.deletePlan()}
            onDenyClick={this.closeDeleteConfirmDialog}
          >
            <Text>
              <Translate messageKey={'sp.plan.delete.message'} />
            </Text>
          </ConfirmDialog>
        )}
      </div>
    )
  }

  private performAction = (action: string) => {
    if (action === 'delete') {
      this.openDeleteConfirmDialog()
    }

    if (action === 'share') {
      this.props.showSharingDialog()
    }
  }

  private openDeleteConfirmDialog = () => {
    this.setState({
      isConfirmDialogOpen: true,
    })
  }

  private handleInlineEditBlur = () => {
    this.setState(
      ({inlineEditValue}) => ({
        isPlanNameInEditMode: false,
        inlineEditValue: inlineEditValue.trim() ? inlineEditValue.trim() : this.props.plan.name,
      }),
      () => {
        this.updatePlanAfterInlineEdit(this.state.inlineEditValue)
      }
    )
  }

  private updatePlanAfterInlineEdit = (inlineEditValue) => {
    const {plan, applyChangeOnCurrentPlanFunction} = this.props
    const updatedPlan = {
      ...plan,
      name: inlineEditValue,
    }
    applyChangeOnCurrentPlanFunction(updatedPlan)
  }

  private handleNewPageTitleChange = (value) => {
    this.setState(
      {
        inlineEditValue: value,
      },
      this.handleInlineEditBlur
    )
  }

  private deletePlan = () => {
    const {deletePlanFunction, navigateToListFunction, plan} = this.props
    this.setState({
      isConfirmDialogOpen: false,
    })
    deletePlanFunction(plan).finally(() => {
      setTimeout(() => {
        navigateToListFunction()

        const storageUtil = getStorageUtil()
        storageUtil.remove(plan.id)
      }, 2000)
    })
  }

  private closeDeleteConfirmDialog = () => {
    this.setState({
      isConfirmDialogOpen: false,
    })
  }
}

const mapStateToProps = (state) => ({
  plan: currentPlanSelector(state),
  isViewMode: viewAccessSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  applyChangeOnCurrentPlanFunction: (changes) => dispatch(updateCurrentPlan(changes)),
  deletePlanFunction: (plan) => dispatch(deletePlan(plan)),
})

export const PlanActionsComponent = connect(mapStateToProps, mapDispatchToProps)(PlanActions)
