import {createSelector} from 'reselect'
import {IScenario} from '../../../shared/models/scenario'
import {initialScenarioSelector} from './initialScenarioSelector'
import {publishedScenarioSelector} from './publishedScenarioSelector'
import {timelineWidthSelector} from './timelineWidthSelector'

export const activeScenarioSelector = createSelector(
  [publishedScenarioSelector, initialScenarioSelector, timelineWidthSelector],
  (publishedScenario: IScenario | null, initialScenario: IScenario) => {
    if (publishedScenario !== null) {
      return publishedScenario
    } else {
      return initialScenario
    }
  }
)
