import * as React from 'react'

export const TimelineBarShadowFilterComponent: React.FunctionComponent = React.memo(() => (
  <defs>
    <filter id="f1" x="0" y="0" width="100%" height="200%">
      <feGaussianBlur in="SourceAlpha" stdDeviation="5" />
      <feOffset dx="0" dy="5" result="offsetblur" />
      <feFlood floodColor="#000000" floodOpacity={0.2} />
      <feComposite in2="offsetblur" operator="in" />
      <feMerge>
        <feMergeNode />
        <feMergeNode in="SourceGraphic" />
      </feMerge>
    </filter>
  </defs>
))

TimelineBarShadowFilterComponent.displayName = 'TimelineBarShadowFilterComponent'
