import {FilterData} from '../../../../shared/toolbar/filters/useWrapperReducer'
import {loadSPFilters} from '../../../services/apiFactory'
import {redrockClient} from '../../../../../shared/L10n'

export function transformResult(result) {
  return {
    id: result.id.toString(),
    name: result.name,
    canEdit: false,
    canDelete: false,
    canShare: false,
  }
}

export default function loadFilters(): Promise<FilterData> {
  return loadSPFilters().then((data: unknown[]) => {
    const saved = [
      {
        id: '',
        name: redrockClient.getTextSync('all', 'All'),
      },
      ...data,
    ].map(transformResult)
    return {
      saved,
      shared: [],
    }
  })
}
