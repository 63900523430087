import {primary} from '@phoenix/all'
import {css} from 'emotion'
import * as React from 'react'
import styled from 'react-emotion'
import {connect} from 'react-redux'
import {MeasurementsContext} from '../../../../shared/context/MeasurementsContext'
import {currentScenarioInitiativesSelector} from '../../../../data/scenario'
import {highlightedColumnIndexSelector} from '../../../../data/scenario/selectors/highlightedColumnIndexSelector'
import {highlightColumnSelector} from '../../../../data/scenario/selectors/highlightColumnSelector'

interface ITimelineColumnShadowComponentProps {
  cellWidth: number
  periodCount: number
  highlightColumn: boolean
  initiativesCount: number
  placeholder?: boolean
  highlightedColumnIndex: number | null
}

const ShadowElementStyled = styled('div')<{top: number; width: number; left: number}>`
  position: absolute;
  width: ${(props) => props.width}px;
  margin-left: ${(props) => props.left}px;
  ${(props) => {
    if (props.top) {
      return `
      top: ${props.top}px;
      bottom: 0;
      `
    }

    return 'height: 100%;'
  }}
`

const shadowIClass = css`
  overflow: hidden;
  position: absolute;
  width: 8px;
  height: 100%;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -8px;
    right: -8px;
    bottom: -8px;
    width: 8px;
    box-shadow: 0px 0px 8px ${primary.gray(700)}33;
  }
`

export const ShadowILeftStyled = styled('i')<{top?: string; right?: string}>`
  ${shadowIClass}
  ${(props) => props.top && `top: ${props.top};`};
  right: ${(props) => props.right || '100%'};

  &:before {
    right: -8px;
  }
`

const ShadowIRightStyled = styled('i')`
  ${shadowIClass}
  left: 100%;

  &:before {
    left: -8px;
  }
`

export const TimelineColumnShadow: React.FunctionComponent<ITimelineColumnShadowComponentProps> = React.memo(
  (props) => {
    if (!props.highlightColumn) {
      return null
    }
    return (
      <MeasurementsContext.Consumer>
        {({initiativeRowHeight}) => (
          <ShadowElementStyled
            top={props.placeholder ? initiativeRowHeight * props.initiativesCount + 1 : 0}
            width={Math.ceil(props.cellWidth)}
            left={Math.floor(props.highlightedColumnIndex! * props.cellWidth)}
          >
            <ShadowILeftStyled />
            {props.periodCount - 1 !== props.highlightedColumnIndex! && <ShadowIRightStyled />}
          </ShadowElementStyled>
        )}
      </MeasurementsContext.Consumer>
    )
  }
)

TimelineColumnShadow.displayName = 'TimelineColumnShadow'

const mapStateToProps = (state) => ({
  cellWidth: state.plan.timeline.cellWidth,
  periodCount: state.plan.timeline.periods.length,
  highlightColumn: highlightColumnSelector(state),
  initiativesCount: currentScenarioInitiativesSelector(state).length,
  highlightedColumnIndex: highlightedColumnIndexSelector(state),
})

export const TimelineColumnShadowComponent = connect(mapStateToProps)(TimelineColumnShadow)
