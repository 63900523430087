import UpArrowFilledSmallIcon from 'phoenix-icons/dist/UpArrowFilledSmallIcon.js'
import {css, cx} from 'emotion'
import * as React from 'react'
import {connect} from 'react-redux'
import {Ellipse, primary, secondary, Text} from '@phoenix/all'
import {
  currencySelector,
  currentScenarioSelector,
  financialAccessDeniedSelector,
  globalRolesSelector,
} from '../../data'
import {peopleCostOnConflictCalculationSelector} from '../../data/plan/selectors/peopleCostOnConflictCalculationSelector'
import {getPreCalculatedDataForCurrentInitiative} from '../../shared/helpers'
import {getPreviouslyUsedAmount} from '../../shared/helpers/conflicts/DistributionsHelper'
import {IGlobalRoles} from '../../shared/models/IGlobalRoles'
import {IInitiative, IScenario} from '../../shared/models'
import {TooltipComponent} from '../Plan/Scenario/Header/TooltipComponent'
import {FormattedCurrencyComponent} from './index'
import {
  IInitiativeConflictsDetailsDates,
  IRoleConflictsByDates,
} from '../../shared/models/initiative/IInitiativeConflicsDetailsByDate'
import map from 'lodash/map'
import {formatNumber} from '../../shared/utilities'
import {Translate} from './Translate'
import {hoursForMeasurementsSelector} from '../../data/plan/selectors/hoursForMeasurementsSelector'

export interface IConflictsTooltipProps {
  initiative?: IInitiative
  hasFinancialAccess: boolean
  usePeopleCostOnConflictCalculation: boolean
  currentScenario: IScenario
  conflictsByDate: IInitiativeConflictsDetailsDates
  currency: string
  date: string
  globalRoles: IGlobalRoles
  title: JSX.Element | string
  useHoursForMeasurements: boolean
}

const noBudgetClass = css`
  padding-bottom: 10px;
`

const conflictsClass = css`
  width: 100%;
  max-height: 149px;
  overflow-y: auto;
  padding: 0 16px;
`

const headingClass = css`
  padding: 0 16px;
`

const headingTextClass = css`
  padding: 8px 0;
  display: block;
  border-bottom: 1px solid ${primary.wallE()};
`

const conflictsRow = css`
  display: flex;
  justify-content: space-between;

  & + & {
    padding-top: 4px;
  }
`

const smallSizeClass = css`
  font-size: 12px;
  font-weight: 400;
  color: ${primary.gray(500)};
`

const budgetConflictsRow = css`
  ${conflictsRow}
  padding-top: 8px;
  border-top: 1px solid ${primary.wallE()};
  margin-top: 12px;
`

const sharedForCellsClass = css`
  margin-right: 16px;
`

const cellsDetailsClass = [
  css`
    width: 106px;
    ${sharedForCellsClass}
  `,
  css`
    width: 60px;

    ${sharedForCellsClass}
    svg {
      margin-left: 8px;
    }
  `,
  css`
    width: 106px;
    ${sharedForCellsClass}
  `,
  css`
    width: 60px;
    ${sharedForCellsClass}
  `,
  css`
    width: 75px;
    ${sharedForCellsClass}
    margin-right: 0;
  `,
]

const mediumFontSize = css`
  font-size: 14px;
  font-weight: 400;
`

const redColor = css`
  ${mediumFontSize}
  color: ${secondary.captainMarvel()};
`

const stickyClass = css`
  position: sticky;
  background-color: ${primary.white()};
  padding: 8px 0;
`

const headerClass = css`
  ${stickyClass};
  top: 0;
`

const footerClass = css`
  ${stickyClass};
  padding-top: 0;
  bottom: 0;
`

export const ConflictsTooltip: React.FC<IConflictsTooltipProps> = ({
  globalRoles,
  conflictsByDate,
  hasFinancialAccess,
  usePeopleCostOnConflictCalculation,
  initiative,
  currentScenario,
  currency,
  date,
  title,
  useHoursForMeasurements,
}) => {
  const preCalculatedData = initiative
    ? getPreCalculatedDataForCurrentInitiative(
        initiative,
        currentScenario.initiatives,
        currentScenario.people.roles,
        currentScenario.budgets && currentScenario.budgets.distribution,
        usePeopleCostOnConflictCalculation,
        hasFinancialAccess
      )
    : null

  const hasPrevAllocated = preCalculatedData

  const getJobRolesHeading = () => (useHoursForMeasurements ? 'job.roles.hour' : 'job.roles.fte')

  const headings = hasPrevAllocated
    ? [
        conflictsByDate.roleConflicts ? getJobRolesHeading() : 'scenario.card.costs',
        'available',
        'previously.allocated',
        'header.widget.role.required',
        'timeline.conflicts.overallocated',
      ]
    : [
        conflictsByDate.roleConflicts ? getJobRolesHeading() : 'scenario.card.costs',
        'available',
        'header.widget.role.required',
        'timeline.conflicts.overallocated',
      ]

  const getDataTestIds = (prefix) => {
    return [
      `${prefix}_name`,
      `${prefix}_available`,
      `${prefix}_prev_allocated`,
      `${prefix}_required`,
      `${prefix}_overallocated`,
    ]
  }

  const previouslyAllocated = (data, month) => {
    return getPreviouslyUsedAmount(data, month)
  }

  const getPeopleTooltip = (roleConflicts: IRoleConflictsByDates) => {
    const dataTestIds = getDataTestIds('role')

    return (
      <div>
        {map(roleConflicts, (conflictDetails, roleId) => {
          return (
            <div className={conflictsRow} key={`conflictDetails${roleId}`}>
              <div className={cellsDetailsClass[0]} data-testid={dataTestIds[0]}>
                <Text className={mediumFontSize}>
                  {globalRoles[roleId] ? (
                    <Ellipse>{globalRoles[roleId].name}</Ellipse>
                  ) : (
                    <Translate messageKey={'global.na'} />
                  )}
                </Text>
              </div>
              <div
                className={cellsDetailsClass[1]}
                key={`periodDetails${1}`}
                data-testid={dataTestIds[1]}
              >
                <TooltipComponent
                  value={formatNumber(conflictDetails.availableValue, 3, true, 6)!}
                  tooltipValue={formatNumber(conflictDetails.availableValue, 3, false, 6)!}
                />
                {conflictDetails.isResolvedRole && <UpArrowFilledSmallIcon />}
              </div>
              {hasPrevAllocated && (
                <div
                  className={cellsDetailsClass[2]}
                  key={`periodDetails${2}`}
                  data-testid={dataTestIds[2]}
                >
                  <TooltipComponent
                    value={
                      formatNumber(
                        previouslyAllocated(preCalculatedData!.roles.get(roleId), date),
                        3,
                        true,
                        6
                      )!
                    }
                    tooltipValue={
                      formatNumber(
                        previouslyAllocated(preCalculatedData!.roles.get(roleId), date),
                        3,
                        false,
                        6
                      )!
                    }
                  />
                </div>
              )}
              <div
                className={cellsDetailsClass[3]}
                key={`periodDetails${3}`}
                data-testid={dataTestIds[3]}
              >
                <TooltipComponent
                  value={formatNumber(conflictDetails.requiredValue, 3, true, 6)!}
                  tooltipValue={formatNumber(conflictDetails.requiredValue, 3, false, 6)!}
                />
              </div>
              <div
                className={cellsDetailsClass[4]}
                key={`periodDetails${4}`}
                data-testid={dataTestIds[4]}
              >
                <TooltipComponent
                  className={conflictDetails.missingValue > 0 ? redColor : ''}
                  value={formatNumber(conflictDetails.missingValue, 3, true, 6)!}
                  tooltipValue={formatNumber(conflictDetails.missingValue, 3, false, 6)!}
                />
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  const getBudgetTooltip = (budgetConflicts, hasRoleConflicts: boolean) => {
    const dataTestIds = getDataTestIds('budget')
    const budgetConflictWhitePreviousAllocation = hasPrevAllocated
      ? [
          budgetConflicts[0],
          previouslyAllocated(preCalculatedData!.budget, date),
          budgetConflicts[1],
          budgetConflicts[2],
        ]
      : budgetConflicts
    return (
      <div className={cx(footerClass, hasRoleConflicts ? budgetConflictsRow : conflictsRow)}>
        <div
          className={cellsDetailsClass[0]}
          key={`budgetConflictDetails${0}`}
          data-testid={dataTestIds[0]}
        >
          {hasRoleConflicts && (
            <Text className={smallSizeClass}>
              <Translate messageKey={'scenario.card.costs'} key={'scenario.card.costs'} />
            </Text>
          )}
        </div>
        {budgetConflictWhitePreviousAllocation.map((value, index) => {
          const overAllocatedIndex = hasPrevAllocated ? 3 : 2
          let styleIndex = index + 1
          if (!hasPrevAllocated && index > 0) {
            styleIndex = styleIndex + 1
          }
          return (
            <div
              className={cellsDetailsClass[styleIndex]}
              key={`budgetConflictDetails${styleIndex}`}
              data-testid={dataTestIds[styleIndex]}
            >
              <Text>
                <FormattedCurrencyComponent
                  cost={value}
                  currency={currency}
                  className={index === overAllocatedIndex && value > 0 ? redColor : mediumFontSize}
                />
              </Text>
            </div>
          )
        })}
      </div>
    )
  }
  return (
    <div>
      <div className={headingClass}>
        <Text className={headingTextClass}>{title}</Text>
      </div>

      <div className={cx(conflictsClass, !conflictsByDate.budgetConflicts ? noBudgetClass : '')}>
        <div className={cx(conflictsRow, headerClass)}>
          {headings.map((messageKey, index) => {
            let styleIndex = index
            if (!hasPrevAllocated && index > 1) {
              styleIndex = styleIndex + 1
            }
            return (
              <div className={cellsDetailsClass[styleIndex]} key={`heading${styleIndex}`}>
                <Text className={smallSizeClass}>
                  {messageKey ? <Translate messageKey={messageKey} /> : ''}
                </Text>
              </div>
            )
          })}
        </div>

        {conflictsByDate.roleConflicts && getPeopleTooltip(conflictsByDate.roleConflicts)}
        {conflictsByDate.budgetConflicts &&
          getBudgetTooltip(conflictsByDate.budgetConflicts, !!conflictsByDate.roleConflicts)}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  currency: currencySelector(state),
  globalRoles: globalRolesSelector(state),
  usePeopleCostOnConflictCalculation: peopleCostOnConflictCalculationSelector(state),
  currentScenario: currentScenarioSelector(state),
  hasFinancialAccess: !financialAccessDeniedSelector(state),
  useHoursForMeasurements: hoursForMeasurementsSelector(state),
})

export const ConflictsTooltipComponent = connect(mapStateToProps)(ConflictsTooltip)
