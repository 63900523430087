import {Moment} from 'moment'
import {
  IBudgetConflict,
  ICompetingInitiative,
  IInitiative,
  IPeriodConflict,
  IScenarioBudgets,
  IYearlyBudget,
} from '../../models'
import {filterInitiativesByPeriod, hasDistributionPositiveValue} from './ConflictHelper'
import {IUsedResourceData} from './IUsedResourceData'

// this function should be called when has financial access
export function calculateBudgetConflicts(
  scenarioBudget: IScenarioBudgets | null,
  initiatives: IInitiative[],
  usePeopleCostOnConflictCalculation: boolean,
  startDate?: Moment,
  endDate?: Moment
): Map<string, IBudgetConflict> {
  const budgetConflicts: Map<string, IBudgetConflict> = new Map()

  if (!isBudgetFilled(scenarioBudget!.distribution)) {
    return budgetConflicts
  }

  let filteredInitiatives = filterInitiativesWithCost(
    initiatives,
    usePeopleCostOnConflictCalculation
  )

  if (startDate && endDate) {
    filteredInitiatives = filterInitiativesByPeriod(filteredInitiatives, startDate, endDate)
  }

  if (filteredInitiatives.length === 0) {
    return budgetConflicts
  }

  const budgetPerMonth: Map<string, number> = calculateBudgetPerMonth(scenarioBudget!)
  const budgetUsagePerMonth: Map<string, IUsedResourceData> = new Map()

  for (const initiative of filteredInitiatives) {
    let costPerMonth
    const monthConflicts: IPeriodConflict[] = []
    const periodStart =
      startDate && startDate.isAfter(initiative.dates.startDate)
        ? startDate
        : initiative.dates.startDate
    const periodEnd =
      endDate && endDate.isBefore(initiative.dates.endDate) ? endDate : initiative.dates.endDate
    let month = periodStart.clone()
    while (month.isBefore(periodEnd)) {
      const monthKey = month.format('YYYY-MM')
      costPerMonth = usePeopleCostOnConflictCalculation
        ? initiative.costs!.overallCostDistribution![monthKey]
        : initiative.costs!.fixedCostDistribution![monthKey]
      if (costPerMonth > 0) {
        const usedBudget: IUsedResourceData = budgetUsagePerMonth.has(monthKey)
          ? budgetUsagePerMonth.get(monthKey)!
          : {
              usedAmount: 0,
              competingInitiatives: [],
            }
        const monthConflict = calculateMonthConflict(
          budgetPerMonth,
          costPerMonth,
          month,
          usedBudget
        )
        if (monthConflict !== null) {
          monthConflicts.push(monthConflict)
        }
        budgetUsagePerMonth.set(monthKey, {
          usedAmount: usedBudget.usedAmount + costPerMonth,
          competingInitiatives: [
            ...usedBudget.competingInitiatives,
            {
              id: initiative.id,
              priority: initiative.order,
            },
          ],
        })
      }
      month = month.add(1, 'month')
    }

    // Merge with the conflicts outside of filtered range
    if (
      startDate &&
      endDate &&
      (initiative.dates.startDate.isBefore(startDate) || initiative.dates.endDate.isAfter(endDate))
    ) {
      if (initiative.conflicts !== null && initiative.conflicts.budgetConflicts) {
        for (const periodConflict of initiative.conflicts.budgetConflicts.periodConflicts) {
          if (periodConflict.period.isBefore(startDate)) {
            monthConflicts.unshift(periodConflict)
          } else if (periodConflict.period.isAfter(endDate)) {
            monthConflicts.push(periodConflict)
          }
        }
      }
    }

    if (monthConflicts.length > 0) {
      budgetConflicts.set(initiative.order.toString(), createBudgetConflict(monthConflicts))
    }
  }

  return budgetConflicts
}

function filterInitiativesWithCost(
  initiatives: IInitiative[],
  usePeopleCostOnConflictCalculation: boolean
): IInitiative[] {
  if (usePeopleCostOnConflictCalculation) {
    return initiatives.filter((initiative) =>
      hasDistributionPositiveValue(initiative.costs!.overallCostDistribution!)
    )
  } else {
    return initiatives.filter((initiative) =>
      hasDistributionPositiveValue(initiative.costs!.fixedCostDistribution!)
    )
  }
}

function createBudgetConflict(periodConflicts: IPeriodConflict[]): IBudgetConflict {
  const totalMissingAmountForInitiative = periodConflicts.reduce(
    (sum, conflict) => sum + conflict.missingAmount,
    0
  )
  const totalBudgetForInitiativeDuration = periodConflicts.reduce(
    (sum, conflict) => sum + conflict.totalAmount,
    0
  )

  const allCompetingInitiatives: ICompetingInitiative[] = []
  const existingInitiatives = new Map()
  for (const periodConflict of periodConflicts) {
    periodConflict.competingInitiatives.forEach((initiative) => {
      if (!existingInitiatives.has(initiative.id)) {
        allCompetingInitiatives.push(initiative)
        existingInitiatives.set(initiative.id, true)
      }
    })
  }

  return {
    totalMissingAmountForInitiative,
    totalBudgetForInitiativeDuration,
    periodConflicts,
    allCompetingInitiatives,
  }
}

function calculateBudgetPerMonth(scenarioBudget: IScenarioBudgets): Map<string, number> {
  const budgetPerMonth = new Map()
  for (const yearlyBudget of scenarioBudget.distribution.values()) {
    yearlyBudget.budgetMonths.forEach((yearMonthBudget) =>
      budgetPerMonth.set(yearMonthBudget.yearMonthDate.format('YYYY-MM'), yearMonthBudget.amount)
    )
  }
  return budgetPerMonth
}

function calculateMonthConflict(
  budgetPerMonth: Map<string, number>,
  costs: number,
  month: Moment,
  usedBudgetData: IUsedResourceData
): IPeriodConflict | null {
  const available = budgetPerMonth.get(month.format('YYYY-MM'))!

  if (available >= usedBudgetData.usedAmount + costs) {
    return null
  } else {
    return {
      period: month.clone(),
      missingAmount: usedBudgetData.usedAmount + costs - available,
      totalAmount: available!,
      competingInitiatives: usedBudgetData.competingInitiatives,
    }
  }
}

function isBudgetFilled(budgetDistribution: Map<string, IYearlyBudget>) {
  for (const yearlyBudget of budgetDistribution.values()) {
    const hasFilledValues =
      yearlyBudget.total !== null ||
      yearlyBudget.budgetMonths.some((budget) => budget.amount !== null)
    if (hasFilledValues) {
      return true
    }
  }
  return false
}
