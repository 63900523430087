import {injectGlobal} from 'emotion'

injectGlobal`
    html[lang^="es"]{
      .people-widget-content {
        width: 684px; 
      }
      
      .budget-widget {
        width: 300px;
      }
      
      .expanded-budget-widget-inner {
        width: 433px;
      }
      
      .column-details-value {
        white-space: nowrap;
      }
      
      .conflict-column-label {
        line-height: 16px;
        height: 37px;
      }
      
      .conflict-table-cell {
        white-space: normal;
        vertical-align: top;
      }
      
      .conflict-table-footer {
        flex-direction: column;
        align-items: start; 
    
        .conflict-table-cell {
          &:last-child {
            order: 1;
            padding-left: 5px;
            padding-bottom: 0;
          }
          
          &:first-child {
            order: 2;
          }
        }
      }
      
      .conflict-table-row.conflicting-header-row {
        vertical-align: top;
      }
      
      .preview-conflict-periods {
        justify-content: flex-start;
      }
    }
    
    // es-US
    
    html[lang="es-US"] {
      .timeline-period-label {
        font-size: 9px;
      }
    }
    
    // es-ES
    
    html[lang="es-ES"] {
      .budget-widget {
        width: 300px;
      }
      
      .expanded-budget-widget-inner {
        width: 433px;
      }
      
      .people-widget-content {
        width: 684px;
      }
    }
`
