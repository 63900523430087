import {createSelector} from 'reselect'
import {AccessMode} from '../../../shared/enums'
import {espAccessSelector} from './espAccessSelector'

export const hasAccessToEspSelector = createSelector(
  espAccessSelector,
  (espAccessMode: AccessMode | null) => {
    return espAccessMode !== null && espAccessMode !== AccessMode.noAccess
  }
)
