import * as React from 'react'
import {Text} from '@phoenix/all'
import {TRenderer} from '@wftypes/list'
import {QuickFilterMatcher} from '../types/quickFilter'

export const PercentCompleteRenderer: TRenderer<{value: number}> = (props) => {
  let displayValue = props.value.toString()
  if (props.value % 1 !== 0) {
    displayValue = props.value.toFixed(1)
  }
  return <Text>{props.highlight(props.filterValue, displayValue + '%')}</Text>
}

export const percenteCompleteMatcher: QuickFilterMatcher = ({value}, filterValue) =>
  `${value}%`.indexOf(filterValue) > -1
