import {IInitiative} from '../../../shared/models/initiative/IInitiative'

interface IState {
  plan: {
    selectedInitiatives: {
      [initiativeID: string]: boolean
    }
  }
}

interface IProps {
  initiative: IInitiative
}

export const isInitiativeSelectedSelector = (state: IState, props: IProps) =>
  !!state.plan.selectedInitiatives[props.initiative.id]
