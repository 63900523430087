import {css} from 'emotion'
import * as React from 'react'
import {connect} from 'react-redux'
import LaunchSmallIcon from 'phoenix-icons/dist/LaunchSmallIcon.js'
import {Button, ConfirmDialog, Text} from '@phoenix/all'
import {
  currentPlanSelector,
  publishedScenarioSelector,
  savedPlanSelector,
} from '../../../../data/plan'
import {currentScenarioSelector} from '../../../../data/scenario'
import {viewAccessSelector, projectNoAccessSelector} from '../../../../data/settings'
import {togglePublishDialog} from '../../../../data/viewOptions'
import {
  hasPlanChanges,
  hasPublishedScenario,
  hasScenarioChanges,
  isNewPlan,
} from '../../../../shared/helpers'
import {useSavePlanHook} from '../../../../shared/helpers/useSavePlanHook'
import {PopperComponent, TooltipContentComponent} from '../../../shared'
import {Translate} from '../../../shared/Translate'
import {ConfirmationDialogHeaderComponent} from '../../../shared/ConfirmationDialogHeaderComponent'

interface IPublishButton {
  isVisible: boolean
  isProjectEditAccessDenied: boolean
  initiativeCount: number
  hasPlanChanges: boolean
  scenarioId: string
  isPeopleCostsConflictsFeatureEnabled: boolean
  openPublishDialogFn: (scenarioId: string) => void
  onSuccessfulSaveFunction: () => void
}

const containerClass = css`
  margin: 3px 0 0 18px;
`
const disabledClass = css`
  cursor: initial;
  color: #b3d6f3;
  pointer-events: none;
  svg path {
    fill: #b3d6f3;
  }
`
export const PublishButton: React.FunctionComponent<IPublishButton> = (props) => {
  const [isConfirmDialogOpen, setConfirmDialog] = React.useState(false)
  const {savePlan} = useSavePlanHook(props.onSuccessfulSaveFunction)

  const onPublishClick = () => {
    if (props.hasPlanChanges) {
      setConfirmDialog(true)
    } else {
      props.openPublishDialogFn(props.scenarioId)
    }
  }

  const saveChanges = () => {
    savePlan()
    setConfirmDialog(false)
  }

  const onDeny = () => {
    setConfirmDialog(false)
  }
  const isDisabled = () => props.isProjectEditAccessDenied || props.initiativeCount === 0
  const reference = (
    <div className={containerClass}>
      <Button
        tabIndex={isDisabled() ? -1 : 0}
        testID={'go-to-publish-button'}
        icon={LaunchSmallIcon}
        onClick={isDisabled() ? null : onPublishClick}
        text
        className={isDisabled() ? `${disabledClass} publish-disabled` : ''}
      >
        <span>
          <Translate messageKey={'form.button.publish'} />
        </span>
      </Button>
    </div>
  )

  const disabledButtonTooltipContent = props.isProjectEditAccessDenied ? (
    <Translate messageKey={'sp.messages.publish.button.no.project.access'} />
  ) : (
    <Translate messageKey={'sp.messages.publish.button.disabled'} />
  )

  return (
    <React.Fragment>
      {props.isVisible &&
        (props.isProjectEditAccessDenied || props.initiativeCount === 0 ? (
          <PopperComponent
            reference={reference}
            content={<TooltipContentComponent text={disabledButtonTooltipContent} />}
            isInsidePortal={true}
            showOnOver={true}
            placement="bottom"
          />
        ) : (
          reference
        ))}
      {isConfirmDialogOpen && (
        <ConfirmDialog
          header={<ConfirmationDialogHeaderComponent messageKey={'scenario.unsaved'} />}
          confirmText={<Translate messageKey={'form.button.publish.save'} />}
          onConfirmClick={saveChanges}
          denyText={<Translate messageKey={'form.button.cancel'} />}
          onDenyClick={onDeny}
          testID="publish-scenario-confirmation-dialog"
        >
          <Text>
            <Translate messageKey={'sp.messages.publish.button'} />
          </Text>
        </ConfirmDialog>
      )}
    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  isVisible:
    !viewAccessSelector(state) &&
    (!hasPublishedScenario(currentPlanSelector(state)!) ||
      currentScenarioSelector(state)!.id === publishedScenarioSelector(state)!.id),
  scenarioId: currentScenarioSelector(state)!.id,
  hasPlanChanges:
    isNewPlan(currentPlanSelector(state)!) ||
    hasPlanChanges(savedPlanSelector(state)!, currentPlanSelector(state)!) ||
    hasScenarioChanges(currentPlanSelector(state)!, currentScenarioSelector(state)),
  initiativeCount: currentScenarioSelector(state)!.initiatives.length,
  isProjectEditAccessDenied: projectNoAccessSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  openPublishDialogFn: (scenarioId) => dispatch(togglePublishDialog(scenarioId)),
})

export const PublishButtonComponent = connect(mapStateToProps, mapDispatchToProps)(PublishButton)
