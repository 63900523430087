import {css, cx} from 'emotion'
import * as React from 'react'
import {connect} from 'react-redux'

import {primary, Text} from '@phoenix/all'
import {
  changeVisibleConflict,
  currentScenarioBudgetSelector,
  isBudgetResolvableSelector,
  preCalculatedDataSelector,
  showConflictsSelector,
  toggleBudgetResolvable,
  viewAccessSelector,
  visibleConflictSelector,
} from '../../../../../data'
import {updateCurrentInitiative} from '../../../../../data/initiative'

import {currentInitiativeSelector} from '../../../../../data/initiative'
import {financialViewAccessSelector} from '../../../../../data/settings'
import {financialEditAccessSelector} from '../../../../../data/settings/selectors/financialEditAccessSelector'
import {hasEditAccessToPlanSelector} from '../../../../../data/settings/selectors/hasEditAccessToPlanSelector'
import {budgetConflictMonthIndexSelector} from '../../../../../data/viewOptions/selectors/budgetConflictMonthIndexSelector'
import {destructureConflicts} from '../../../../../shared/helpers/conflicts/RoleConflictHelper'
import {IInitiative, IPreCalculatedData, IScenarioBudgets} from '../../../../../shared/models'
import {IDistribution} from '../../../../../shared/models/initiative/IDistribution'
import {formatNumber} from '../../../../../shared/utilities'
import {TextWithTooltipComponent} from '../../../../shared'
import {CarouselDistributionComponent} from '../carousel/CarouselDistributionComponent'
import {CarouselProviderComponent} from '../carousel/CarouselProviderComponent'
import {ConflictDetailWrapperComponent} from '../ConflictDetailWrapperComponent'
import {ResolveCheckboxComponent} from '../ResolveCheckboxComponent'
import {CarouselItemComponent} from '../carousel/CarouselItemComponent'
import {CarouselComponent} from '../carousel/CarouselComponent'
import {RoleMonthsCarouselComponent} from '../roles/RoleMonthsCarouselComponent'
import {SidebarConflictDetailsComponent} from '../SidebarConflictDetailsComponent'
import {peopleCostOnConflictCalculationSelector} from '../../../../../data/plan/selectors/peopleCostOnConflictCalculationSelector'
import {isBudgetConflict} from '../../../../../shared/helpers/TimelineBarHelper'
import {isNewPlan, restructureBudgetsDistribution} from '../../../../../shared/helpers'
import {Translate} from '../../../../shared/Translate'

export interface ICostFormComponent {
  initiative: IInitiative
  isViewMode: boolean
  isEditModeForPlan: boolean
  isFinancialViewMode: boolean
  isFinancialEditMode: boolean
  budgetConflictMonthIndex: number
  updateCurrentInitiativeFunction: (
    changes: any,
    showConflicts: boolean,
    usePeopleCostOnConflictCalculation: boolean
  ) => void
  showConflicts: boolean
  usePeopleCostOnConflictCalculation: boolean
  isVisibleConflict: boolean
  preCalculatedData: IPreCalculatedData
  changeVisibleConflictFunction: (conflict) => void
  isBudgetResolvable: boolean
  toggleBudgetResolvableFunction: (isBudgetResolvable: boolean) => void
  currentScenarioBudgets: IScenarioBudgets
}

const costContainerClass = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const intervalClass = css`
  margin-top: 15px;
`

const peopleCostWrapperClass = css`
  align-items: flex-start;
`

const paddingClass = css`
  padding-right: 32px;
`

const viewModePaddingClass = css`
  padding-right: 8px;
`

const peopleCostValueClass = css`
  color: ${primary.gray(700)};
  padding-left: 13px;
`

const costDistributionClass = css`
  display: flex;
  justify-content: space-between;
`

const peopleCostDistributionItemClass = css`
  padding-top: 0 !important;
`

const borderClass = css`
  border-top: 1px solid ${primary.wallE()};
`

const tooltipContainerClass = css`
  width: 220px;
`

export const CostForm: React.FunctionComponent<ICostFormComponent> = React.memo(
  ({
    showConflicts,
    budgetConflictMonthIndex,
    initiative,
    initiative: {costs, conflicts},
    updateCurrentInitiativeFunction,
    usePeopleCostOnConflictCalculation,
    isViewMode,
    isEditModeForPlan,
    isFinancialEditMode,
    isFinancialViewMode,
    isVisibleConflict,
    preCalculatedData,
    changeVisibleConflictFunction,
    isBudgetResolvable,
    toggleBudgetResolvableFunction,
    currentScenarioBudgets,
  }) => {
    //here props.initiative.costs cant be null or undefined

    const onBlurHandler = (fixedCostDistribution: IDistribution): void => {
      const initiativeCosts = {
        ...costs,
        fixedCostDistribution,
      }

      updateCurrentInitiativeFunction(
        {costs: initiativeCosts},
        showConflicts,
        usePeopleCostOnConflictCalculation
      )
    }

    const destructuredPeriodConflicts = React.useMemo(() => {
      if (conflicts && conflicts.budgetConflicts) {
        const budgetsDistribution = restructureBudgetsDistribution(
          currentScenarioBudgets.distribution
        )
        return destructureConflicts(
          conflicts.budgetConflicts.periodConflicts,
          costs!.fixedCostDistribution,
          budgetsDistribution
        )
      }
      return null
    }, [conflicts])

    const expandCollapseHandler = () => {
      isVisibleConflict
        ? changeVisibleConflictFunction(null)
        : changeVisibleConflictFunction('budget')
    }

    const isBudgetConflicted = React.useMemo(() => !!(conflicts && conflicts.budgetConflicts), [
      initiative,
    ])

    const onChangeHandler = React.useCallback(() => {
      toggleBudgetResolvableFunction(!isBudgetResolvable)
    }, [isBudgetResolvable])

    const distributionWrapperClass =
      isFinancialViewMode && isViewMode ? viewModePaddingClass : paddingClass

    return (
      <CarouselProviderComponent
        distribution={costs!.fixedCostDistribution || {}}
        conflictIndex={budgetConflictMonthIndex}
      >
        <RoleMonthsCarouselComponent
          testID="cost-months-carousel"
          distribution={costs!.fixedCostDistribution!}
          label={<Translate messageKey={'scenario.card.costs'} />}
        />

        <ConflictDetailWrapperComponent testID="sidebar-cost-row" hasConflict={isBudgetConflicted}>
          <div className={costContainerClass}>
            <TextWithTooltipComponent
              testID="cost-form-label"
              text={<Translate messageKey={'sidebar.costs.fixed'} />}
            />

            <div className={cx(costDistributionClass, distributionWrapperClass)}>
              <CarouselDistributionComponent
                testID="cost-distribution"
                onBlur={onBlurHandler}
                distribution={costs!.fixedCostDistribution}
                periodConflictDetails={destructuredPeriodConflicts}
              />
            </div>
          </div>

          {usePeopleCostOnConflictCalculation && (
            <div
              className={cx(
                costContainerClass,
                isBudgetConflicted ? peopleCostWrapperClass : intervalClass
              )}
              data-testid="sidebar-people-cost"
            >
              <TextWithTooltipComponent
                testID="people-cost-form-label"
                text={<Translate messageKey={'sidebar.costs.people'} />}
              />
              <div className={distributionWrapperClass}>
                <CarouselComponent testID="sidebar-people-cost-distribution">
                  {Object.keys(costs!.peopleCostDistribution!).map((month) => (
                    <CarouselItemComponent
                      className={peopleCostDistributionItemClass}
                      periodConflictDetails={destructuredPeriodConflicts}
                      month={month}
                      testID={`sidebar-people-cost-${month}`}
                      key={month}
                    >
                      <Text className={peopleCostValueClass}>
                        {formatNumber(costs!.peopleCostDistribution![month])}
                      </Text>
                    </CarouselItemComponent>
                  ))}
                </CarouselComponent>
              </div>
            </div>
          )}

          {isBudgetConflicted && (
            <div
              data-testid="conflicted-cost-form"
              className={`${borderClass} initiative-conflict`}
            >
              <SidebarConflictDetailsComponent
                testID="people-cost-conflict-distribution"
                distribution={costs!.fixedCostDistribution!}
                isVisibleConflict={isVisibleConflict}
                periodConflictDetails={destructuredPeriodConflicts!}
                preCalculatedData={preCalculatedData.budget}
                expandCollapseHandler={expandCollapseHandler}
              />

              {isFinancialEditMode && isEditModeForPlan && (
                <ResolveCheckboxComponent
                  tooltipContent="sp.scenario.resolve.cost.conflict.description"
                  testID="cost-checkbox"
                  name="resolve-budget"
                  label="sp.sidebar.resolve.budget.conflict"
                  isChecked={isBudgetResolvable}
                  onChange={onChangeHandler}
                  tooltipContainerClass={tooltipContainerClass}
                />
              )}
            </div>
          )}
        </ConflictDetailWrapperComponent>
      </CarouselProviderComponent>
    )
  }
)

CostForm.displayName = 'CostForm'

const mapStateToProps = (state) => {
  const visibleConflict = visibleConflictSelector(state)

  return {
    initiative: currentInitiativeSelector(state),
    budgetConflictMonthIndex: budgetConflictMonthIndexSelector(state),
    isViewMode: viewAccessSelector(state),
    isEditModeForPlan: hasEditAccessToPlanSelector(state) || isNewPlan(state.plan.currentPlan),
    isFinancialViewMode: financialViewAccessSelector(state),
    isFinancialEditMode: financialEditAccessSelector(state),
    showConflicts: showConflictsSelector(state),
    isVisibleConflict: !!visibleConflict && isBudgetConflict(visibleConflict),
    usePeopleCostOnConflictCalculation: peopleCostOnConflictCalculationSelector(state),
    preCalculatedData: preCalculatedDataSelector(state),
    isBudgetResolvable: isBudgetResolvableSelector(state),
    currentScenarioBudgets: currentScenarioBudgetSelector(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateCurrentInitiativeFunction: (changes, showConflicts, usePeopleCostOnConflictCalculation) =>
    dispatch(updateCurrentInitiative(changes, showConflicts, usePeopleCostOnConflictCalculation)),
  changeVisibleConflictFunction: (conflict) => dispatch(changeVisibleConflict(conflict)),
  toggleBudgetResolvableFunction: (isBudgetResolvable) =>
    dispatch(toggleBudgetResolvable(isBudgetResolvable)),
})

export const CostFormComponent = connect(mapStateToProps, mapDispatchToProps)(CostForm)
