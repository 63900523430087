import * as React from 'react'
import styled from 'react-emotion'
import {primary} from '@phoenix/all'

interface IPillProps {
  label: string | React.ReactElement
  color?: string
  className?: string
  onClick?: (e) => void
}

const PillStyled = styled('div')<{textColor: string}>`
  border-radius: 12px;
  color: ${(props) => props.textColor};
  font-size: 14px;
  padding: 0px 8px;
  height: 24px;
  line-height: 24px;
  display: inline-block;
  background-color: ${primary.gray(200)};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

export const Pill: React.FC<IPillProps> = (props) => {
  const {label, color, ...rest} = props

  return (
    <PillStyled textColor={color || primary.gray(700)} {...rest}>
      {label}
    </PillStyled>
  )
}
