import {css} from 'emotion'
import {useMemo} from 'react'
import * as React from 'react'
import {isOpenRoleDistributionDialogSelector} from '../../../../data/viewOptions/selectors/isOpenRoleDistributionDialogSelector'
import {connect} from 'react-redux'
import {useCurrentUser} from '@wf-mfe/auth'
import {IPlan, IScenario} from '../../../../shared/models'
import {BackToListButtonComponent} from '../../../shared/BackToListButtonComponent'
import {HeaderComponent} from '../../../shared/HeaderComponent'
import {PlanActionsComponent} from '../../PlanActionsComponent'
import {inlineCenterItemsClass} from '../StyleHelper'
import {RoleDistributionDialogComponent} from './People/roleDistributionDialog/RoleDistributionDialogComponent'
import {PublishButtonComponent} from './PublishButtonComponent'
import {ScenarioHeaderActionsComponent} from './ScenarioHeaderActionsComponent'
import {ScenarioHeaderWidgetsComponent} from './ScenarioHeaderWidgetsComponent'
import {SharedUserListWrapper} from './SharedUserListWrapper'
import {ShareDialogComponent, IPlanShareData} from '@workfront/sp-sharing-dialog'
import {currentPlanSelector, updatePlanAccessorObjects} from '../../../../data/plan'
import {hasEditAccess} from '../../../../../spLists/shared/utils/hasEditAccess'

interface IScenarioHeaderComponent {
  plan: IPlan
  updatePlanAccessorObjects: (currentObjects) => void
  navigateToListFunction: () => void
  onSuccessfulSaveFunction: () => void
  currentScenario: IScenario
  isOpenRoleDistributionDialog: boolean
}

const headerBodyClass = css`
  ${inlineCenterItemsClass};
  justify-content: space-between;
`

const headerWidgetItemsWrapperClass = css`
  ${inlineCenterItemsClass};
  z-index: 1;

  & > div + div {
    margin-left: 16px;
  }
`

const inlineEditContainerClass = css`
  flex-grow: 1;
`

const headerTopContainerClassName = css`
  display: flex;
  align-items: center;
`

const headerRightClass = css`
  display: flex;
  margin-left: auto;
  align-items: center;
`

export enum SharingDialogShownMode {
  NONE,
  VIEW,
  EDIT,
}

const ScenarioHeaderComponentDisconnected: React.FunctionComponent<IScenarioHeaderComponent> = (
  props
) => {
  const {
    plan,
    navigateToListFunction,
    currentScenario,
    updatePlanAccessorObjects,
    isOpenRoleDistributionDialog,
  } = props

  const currentUser = useCurrentUser()

  const planId = plan && plan.id
  const planName = plan && plan.name
  const planOwnerId = plan && plan.owner && plan.owner.id

  const planShareData = useMemo<IPlanShareData[]>(
    () => [
      {
        id: planId,
        name: planName,
        ownerId: planOwnerId!,
      },
    ],
    [planId, planName, planOwnerId]
  )

  const [sharingDialogShownMode, setSharingDialogShownMode] = React.useState<
    SharingDialogShownMode
  >(SharingDialogShownMode.NONE)

  const showSharingDialog = React.useCallback(
    (currentUser) => {
      setSharingDialogShownMode(
        plan.owner?.id &&
          plan.accessorObjects &&
          hasEditAccess(currentUser.ID, plan.owner.id, plan.accessorObjects)
          ? SharingDialogShownMode.EDIT
          : SharingDialogShownMode.VIEW
      )
    },
    [plan]
  )

  const cancelSharingDialog = React.useCallback(() => {
    setSharingDialogShownMode(SharingDialogShownMode.NONE)
  }, [])

  const confirmSharingDialog = React.useCallback((currentObjects) => {
    setSharingDialogShownMode(SharingDialogShownMode.NONE)
    updatePlanAccessorObjects(currentObjects)
  }, [])

  return (
    <HeaderComponent>
      <div>
        <div className={headerTopContainerClassName}>
          <BackToListButtonComponent navigateToListFunction={navigateToListFunction} />
          <div className={headerRightClass}>
            <div>
              <SharedUserListWrapper onSelect={() => showSharingDialog(currentUser)} />
            </div>
            <PublishButtonComponent onSuccessfulSaveFunction={props.onSuccessfulSaveFunction} />
          </div>
        </div>
        <div className={headerBodyClass} data-testid="scenario-header-body">
          <div className={inlineEditContainerClass}>
            <PlanActionsComponent
              showSharingDialog={() => showSharingDialog(currentUser)}
              navigateToListFunction={navigateToListFunction}
            />
            <ScenarioHeaderActionsComponent currentScenario={currentScenario} />
          </div>

          <div className={headerWidgetItemsWrapperClass}>
            <ScenarioHeaderWidgetsComponent currentScenario={currentScenario} />
          </div>
        </div>
        {sharingDialogShownMode !== SharingDialogShownMode.NONE && (
          <ShareDialogComponent
            plans={planShareData}
            onCancel={cancelSharingDialog}
            onShare={confirmSharingDialog}
            isViewMode={sharingDialogShownMode === SharingDialogShownMode.VIEW}
          />
        )}

        <RoleDistributionDialogComponent show={isOpenRoleDistributionDialog} />
      </div>
    </HeaderComponent>
  )
}

const mapStateToProps = (state) => ({
  plan: currentPlanSelector(state),
  isOpenRoleDistributionDialog: isOpenRoleDistributionDialogSelector(state),
})
const mapDispatchToProps = (dispatch) => ({
  updatePlanAccessorObjects: (currentObjects) =>
    dispatch(updatePlanAccessorObjects(currentObjects)),
})

export const ScenarioHeaderComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScenarioHeaderComponentDisconnected)
