import {Moment} from 'moment'
import {createSelector} from 'reselect'
import {TimelineMode} from '../../../shared/enums'
import {getTimelinePeriods} from '../../../shared/helpers'
import {currentPlanStartDateSelector} from './currentPlanStartDateSelector'
import {planDurationSelector} from './planDurationSelector'

export const timelineMonthPeriodsSelector = createSelector(
  [planDurationSelector, currentPlanStartDateSelector],
  (duration: number, startDate: Moment) => {
    return getTimelinePeriods(TimelineMode.MONTHLY, startDate, duration)
  }
)
