import {combineReducers} from 'redux'
import {initiative} from './initiative'
import {plan} from './plan'
import {globalRoles} from './globalRoles'
import {scenario} from './scenario'
import {settings} from './settings'
import {viewOptions} from './viewOptions'
import {features} from './features'

export const combinedPlanReducer = combineReducers({
  plan,
  scenario,
  initiative,
  globalRoles,
  settings,
  viewOptions,
  features,
})

export const combinedPlanListReducer = combineReducers({
  settings,
})
