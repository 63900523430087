import {createSelector} from 'reselect'
import {IScenario} from '../../../shared/models'
import {currentScenarioSelector} from './currentScenarioSelector'

export const currentScenarioPeopleSelector = createSelector(
  [currentScenarioSelector],
  (currentScenario: IScenario | null) => {
    return currentScenario!.people
  }
)
