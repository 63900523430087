import {css} from 'emotion'
import moment, {Moment} from 'moment'
import * as React from 'react'
import {connect} from 'react-redux'

import {Button, Dialog, H2, Input, Option, primary, Select, Text} from '@phoenix/all'
import {
  defaultCurrencySelector,
  updateCurrentPlanStartDateOrDuration,
  updateCurrentScenarioAccordingPlanStatdateAndDuration,
} from '../../data'
import {hoursForMeasurementsSelector} from '../../data/plan/selectors/hoursForMeasurementsSelector'
import {MonthDatePicker} from '../shared'
import {FTEHoursSettingComponent} from './FTEHoursSettingComponent'
import {inlineCenterItemsClass} from './Scenario/StyleHelper'
import {Translate} from '../shared/Translate'
import {translate} from '../../shared/utilities/TranslateHelper'

export interface IPlanDialogComponentProps {
  currency: string
  navigateToListFunction: () => void
  applyChangeOnCurrentPlanFunction: (plan) => void
  useHoursForMeasurements: boolean
}

interface IPlanDialogComponentState {
  startDate: Moment
  name: string
  selectedDuration: number
  isFormValid: boolean
  isPopupOpen: boolean
}

const divider = css`
  height: 1px;
  margin: 22px 0 24px;
  background-color: ${primary.gray(200)};
`

const newPlanTitleClass = css`
  line-height: 0.7;
  margin-bottom: 14px;
`

const durationClass = css`
  width: 95px;
  margin-left: 8px;
`

const datesLabelClass = css`
  margin: 22px 0 7px;
`

const startDateClass = css`
  width: 176px;
`

const buttonsClass = css`
  margin-bottom: 8px;
`

const inputWrapperClass = css`
  position: relative;
`

const planDurations = [
  {
    value: 1,
    messageKey: 'form.duration.year',
    messageKeyArgs: [],
  },
  {
    value: 3,
    messageKey: 'form.duration.years',
    messageKeyArgs: [3],
  },
  {
    value: 5,
    messageKey: 'form.duration.years',
    messageKeyArgs: [5],
  },
]

export class PlanDialog extends React.Component<
  IPlanDialogComponentProps,
  IPlanDialogComponentState
> {
  durationInputRef: React.RefObject<HTMLInputElement>
  newPlanNameRef: React.RefObject<HTMLInputElement>

  constructor(props: IPlanDialogComponentProps) {
    super(props)
    this.state = {
      name: '',
      selectedDuration: planDurations[0].value,
      startDate: moment.utc().startOf('month'),
      isFormValid: true,
      isPopupOpen: true,
    }

    this.newPlanNameRef = React.createRef()
    this.durationInputRef = React.createRef()
  }

  componentDidMount(): void {
    setTimeout(() => {
      this.newPlanNameRef.current?.focus()
      this.durationInputRef.current?.setAttribute('readonly', '')
    })
  }

  render() {
    return (
      <React.Fragment>
        {this.state.isPopupOpen && (
          <Dialog width={480}>
            <H2 className={newPlanTitleClass}>
              <Translate messageKey={'sp.plan.new'} />
            </H2>

            <div className={inputWrapperClass}>
              <Input
                onKeyDown={this.handleKeyDown}
                forwardedRef={this.newPlanNameRef}
                name="name"
                testID="new-plan-name"
                value={this.state.name}
                label={<Translate messageKey={'form.name'} />}
                onChange={this.setName}
                minLength={1}
                autoComplete="off"
                maxLength={255}
                required={true}
                error={this.state.isFormValid ? '' : <Translate messageKey={'required.field'} />}
                placeholder={translate('form.start.typing')}
                hideMaxLengthInfo={true}
              />
            </div>

            <FTEHoursSettingComponent />

            <div className={datesLabelClass}>
              <Text.Small>
                <Translate messageKey={'sp.form.dates.label'} />
              </Text.Small>
            </div>
            <div className={inlineCenterItemsClass}>
              <div className={startDateClass}>
                <MonthDatePicker
                  testId={'plan-start'}
                  onDateChange={this.setDate}
                  label={translate('form.dates.start')}
                  value={this.state.startDate}
                  minValue={moment('Tue Jan 01 1900 00:00:00 GMT+0400')}
                  maxValue={moment('Tue Jan 01 2999 00:00:00 GMT+0400')}
                />
              </div>
              <div className={durationClass}>
                <Select
                  testID="duration-select"
                  onChange={this.setDuration}
                  value={this.state.selectedDuration}
                  forwardedRef={this.durationInputRef}
                  label={translate('form.dates.duration')}
                >
                  {planDurations.map((duration) => (
                    <Option value={duration.value} key={`duration_${duration.value}`}>
                      <Translate messageKey={duration.messageKey} args={duration.messageKeyArgs} />
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div className={divider} />
            <div className={buttonsClass}>
              <Button action={this.applyChangesToPlan} testID="next-plan">
                <Translate messageKey={'form.button.next'} />
              </Button>
              <Button text action={this.props.navigateToListFunction} testID="cancel-plan">
                <Translate messageKey={'form.button.cancel'} />
              </Button>
            </div>
          </Dialog>
        )}
      </React.Fragment>
    )
  }

  private handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.newPlanNameRef.current!.blur()
    }
  }

  private setName = (event) => {
    const name = event.target.value
    this.setState(
      {
        name,
      },
      () => {
        const isFormValid = this.isFormValid()
        this.setState({
          isFormValid,
        })
      }
    )
  }

  private isFormValid = () => Boolean(this.state.name)

  private setDuration = (selectedDuration: number) => {
    if (planDurations.find((item) => item.value === selectedDuration)) {
      this.setState({
        selectedDuration,
      })
    }
  }

  private setDate = (startDate: Moment) => {
    this.setState({
      startDate,
    })
  }

  private applyChangesToPlan = () => {
    const isFormValid = this.isFormValid() && this.state.name.trim() !== ''

    if (isFormValid) {
      const {name, startDate, selectedDuration} = this.state
      const trimName = name.trim()
      const plan = {
        name: trimName,
        duration: selectedDuration,
        startDate,
        settings: {
          currency: this.props.currency,
          usePeopleCostOnConflictCalculation: true, // TODO get from selector
          useHoursForMeasurements: this.props.useHoursForMeasurements,
        },
      }
      this.setState({
        isPopupOpen: false,
      })
      this.props.applyChangeOnCurrentPlanFunction(plan)
    } else {
      this.setState({
        isFormValid,
      })
    }
  }
}

const mapStateToProps = (state) => ({
  currency: defaultCurrencySelector(state),
  useHoursForMeasurements: hoursForMeasurementsSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  applyChangeOnCurrentPlanFunction: (changes) => {
    dispatch(updateCurrentPlanStartDateOrDuration(changes))
    dispatch(updateCurrentScenarioAccordingPlanStatdateAndDuration(changes))
  },
})

export const PlanDialogComponent = connect(mapStateToProps, mapDispatchToProps)(PlanDialog)

// TODO: Check if after compare story we need isPopupOpen state, if not cleanup
