import {primary, secondary} from '@phoenix/all'
import {Moment} from 'moment'
import * as React from 'react'
import {connect} from 'react-redux'
import {
  currentPlanSelector,
  timelineCellWidthSelector,
  timelineWidthSelector,
} from '../../../../data/plan'
import {
  BAR_RADIUS,
  getEmptyBarProperties,
  hasInitiativeConflict,
  standardTimelineBarClass,
} from '../../../../shared/helpers/TimelineBarHelper'
import {IBudgetConflict, IInitiative, IRoleConflict} from '../../../../shared/models/initiative'
import {MeasurementsContext} from '../../../../shared/context/MeasurementsContext'
import {RoleContouringListComponent} from './RoleContouring/RoleContouringListComponent'
import {visibleConflictSelector} from '../../../../data/viewOptions'
import {currentInitiativeOrderSelector} from '../../../../data/initiative/selectors/currentInitiativeOrderSelector'

export interface ITimelineEmptyBarComponentProps {
  initiative: IInitiative
  handleOnClick: () => void
  cellWidth: number
  mode: number
  planStartDate: Moment
  planDuration: number
  timelineWidth: number
  visibleConflict: IBudgetConflict | IRoleConflict | null
  currentInitiativeOrder: number
}

export const BAR_TRIANGLE_WIDTH = 12.015

export const TimelineEmptyBar: React.FunctionComponent<ITimelineEmptyBarComponentProps> = React.memo(
  (props) => {
    const {
      planStartDate,
      planDuration,
      initiative,
      initiative: {refObject, conflicts},
      visibleConflict,
      currentInitiativeOrder,
    } = props

    const [isBarLeftTriangleConflicted, setIsBarLeftTriangleConflicted] = React.useState<boolean>(
      false
    )
    const [isBarRightTriangleConflicted, setIsBarRightTriangleConflicted] = React.useState<boolean>(
      false
    )
    const {barWidth, leftDraggablePosition, arrowWidth} = getEmptyBarProperties(props, {}, false)

    const hasConflict = React.useMemo(() => hasInitiativeConflict(conflicts), [initiative])

    const compareDatesForLeftTriangle = () => {
      return (
        refObject &&
        refObject.plannedStartDate &&
        refObject.plannedStartDate.isBefore(planStartDate) &&
        (initiative.dates.startDate.diff(planStartDate, 'day') === 0 ||
          initiative.dates.startDate.isBefore(planStartDate))
      )
    }

    const compareDatesForRightTriangle = () => {
      const planEndDate = planStartDate.clone().add(planDuration, 'year')

      return (
        refObject &&
        refObject.plannedCompletionDate &&
        refObject.plannedCompletionDate.isAfter(planEndDate) &&
        (initiative.dates.endDate.diff(planEndDate, 'day') === 0 ||
          initiative.dates.endDate.isAfter(planEndDate))
      )
    }

    const showLeftTriangle = React.useMemo(compareDatesForLeftTriangle, [initiative])
    const showRightTriangle = React.useMemo(compareDatesForRightTriangle, [initiative])

    const barRealWidth = React.useMemo(
      () =>
        showLeftTriangle && showRightTriangle
          ? barWidth + arrowWidth - 2 * (BAR_TRIANGLE_WIDTH - BAR_RADIUS)
          : showLeftTriangle || showRightTriangle
          ? barWidth + arrowWidth - (BAR_TRIANGLE_WIDTH - BAR_RADIUS)
          : barWidth + arrowWidth,
      [showLeftTriangle, showRightTriangle, barWidth, arrowWidth]
    )

    const leftPosition = React.useMemo(
      () =>
        showLeftTriangle
          ? leftDraggablePosition + (BAR_TRIANGLE_WIDTH - BAR_RADIUS)
          : leftDraggablePosition,
      [showLeftTriangle, leftDraggablePosition]
    )

    const getBackgroundColor = (isConflicted) => {
      return isConflicted ? secondary.red(400) : primary.blue(400)
    }

    const visibleConflictCondition = visibleConflict && currentInitiativeOrder >= initiative.order

    return (
      <MeasurementsContext.Consumer>
        {({timelineBarArrowHeight, timelineBarArrowYPosition, initiativeRowHeight}) => (
          <g data-testid="duration-bar-contouring" onClick={props.handleOnClick}>
            <rect
              data-testid="initiative-duration-empty-bar"
              className={standardTimelineBarClass}
              x={leftPosition}
              y={timelineBarArrowYPosition}
              rx={BAR_RADIUS}
              ry={BAR_RADIUS}
              width={barRealWidth}
              height={timelineBarArrowHeight}
            />

            {(hasConflict || visibleConflictCondition) && (
              <RoleContouringListComponent
                isEmptyBar
                initiative={initiative}
                setIsBarLeftTriangleConflicted={setIsBarLeftTriangleConflicted}
                setIsBarRightTriangleConflicted={setIsBarRightTriangleConflicted}
                showLeftTriangle={showLeftTriangle}
                showRightTriangle={showRightTriangle}
              />
            )}

            {showLeftTriangle && (
              <path
                d={`M${BAR_TRIANGLE_WIDTH},0 L${BAR_TRIANGLE_WIDTH},20 L9.39850128,20.002214 C8.79150128,20.002214 8.21750128,19.727214 7.83750128,19.253214 L0.439501284,11.272214 C-0.145498716,10.543214 -0.146498716,9.50421398 0.436501284,8.77421398 L7.83750128,0.75421398 C8.21750128,0.27921398 8.79250128,0.00221397971 9.40050128,0.00221397971 L${BAR_TRIANGLE_WIDTH},0 Z`}
                data-testid="left-triangle"
                fill={getBackgroundColor(isBarLeftTriangleConflicted && hasConflict)}
                transform={`translate(0, ${Math.ceil(
                  (initiativeRowHeight - timelineBarArrowHeight) / 2
                )})`}
              />
            )}

            {showRightTriangle && (
              <path
                d={`M${BAR_TRIANGLE_WIDTH},7.27595761e-12 L${BAR_TRIANGLE_WIDTH},20 L9.39850128,20.002214 C8.79150128,20.002214 8.21750128,19.727214 7.83750128,19.253214 L0.439501284,11.272214 C-0.145498716,10.543214 -0.146498716,9.50421398 0.436501284,8.77421398 L7.83750128,0.75421398 C8.21750128,0.27921398 8.79250128,0.00221397972 9.40050128,0.00221397972 L${BAR_TRIANGLE_WIDTH},7.27595761e-12 Z`}
                data-testid="right-triangle"
                fill={getBackgroundColor(isBarRightTriangleConflicted && hasConflict)}
                transform={`translate(${props.timelineWidth}, ${
                  timelineBarArrowYPosition + timelineBarArrowHeight
                }) rotate(-180)`}
              />
            )}
          </g>
        )}
      </MeasurementsContext.Consumer>
    )
  }
)

const mapStateToProps = (state) => ({
  cellWidth: timelineCellWidthSelector(state),
  mode: state.plan.timeline.mode,
  planStartDate: currentPlanSelector(state)!.startDate,
  planDuration: currentPlanSelector(state)!.duration,
  timelineWidth: timelineWidthSelector(state),
  visibleConflict: visibleConflictSelector(state),
  currentInitiativeOrder: currentInitiativeOrderSelector(state),
})

export const TimelineEmptyBarComponent = connect(mapStateToProps)(TimelineEmptyBar)
