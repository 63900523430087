import {Localization} from '@wf-mfe/localize-react'
import * as React from 'react'
import {getTranslationFallback} from '../../shared/utilities/TranslateHelper'

interface ITranslateProps {
  messageKey: string
  args?: string[] | number[] | Record<string, any>
  tag?: keyof React.ReactHTML | 'text' | 'tspan'
}

export const Translate: React.FC<ITranslateProps> = React.memo(
  ({messageKey, args, tag = undefined}) => {
    const fallback = getTranslationFallback(messageKey, args)

    return args ? (
      <Localization sync={true} tag={tag} messageKey={messageKey} fallback={fallback} args={args} />
    ) : (
      <Localization sync={true} tag={tag} messageKey={messageKey} fallback={fallback} />
    )
  }
)

Translate.displayName = 'Translate'
