import * as React from 'react'

import {LoadLocalizations, LocalizationProvider} from '@wf-mfe/localize-react'
import {exportMessageKeys} from './messageKeys'
import {DataFetcherOptions} from '../import/plugins/dataFetcher'
import {InitiativeExportList} from './InitiativeExportList'
import {espClientFactory, espContext} from '../../shared/L10n'

export interface InitiativeExportListComponentProps extends Partial<DataFetcherOptions> {
  scenarioId: string
  planId: string
  onSelectionChange?: (ids: string[]) => void
  newFilterSpPlansListSplit: boolean
}

export const InitiativeExportListWrapperComponent: React.FC<InitiativeExportListComponentProps> = (
  props
) => {
  const {scenarioId, planId, onSelectionChange, newFilterSpPlansListSplit} = props

  return (
    <LocalizationProvider clientFactory={espClientFactory} context={espContext}>
      <LoadLocalizations messageKeys={exportMessageKeys}>
        <InitiativeExportList
          scenarioId={scenarioId}
          planId={planId}
          onSelectionChange={onSelectionChange}
          newFilterSpPlansListSplit={newFilterSpPlansListSplit}
        />
      </LoadLocalizations>
    </LocalizationProvider>
  )
}
