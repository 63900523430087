import {RESOLVE_CONFLICTS} from '../scenario/actions'
import {
  CHANGE_BUDGET_RESOLVABLE,
  CHANGE_BUDGET_WIDGET_ANIMATION,
  CHANGE_IMPORT_LOADING_MODE,
  CHANGE_LOADING_MODE,
  CHANGE_PEOPLE_WIDGET_ANIMATION,
  CHANGE_RESOLVABLE_ROLES,
  CHANGE_VISIBLE_CONFLICT,
  OPEN_EDIT_SIDEBAR,
  REORDER_DRAG_AND_DROP,
  SLIDE_COPIED_SCENARIO,
  TOGGLE_BUDGET_WIDGET,
  TOGGLE_IMPORT_DIALOG,
  SET_DRAGGING_INITIATIVE_ID,
  TOGGLE_PEOPLE_WIDGET,
  TOGGLE_PUBLISH_DIALOG,
  UPDATE_RESIZE_LINE_POSITION,
  UPDATE_BUDGET_CONFLICT_MONTH_INDEX,
  UPDATE_ROLE_CONFLICT_MONTH_INDEX,
  OPEN_ROLE_DISTRIBUTION_DIALOG,
} from './actions'
import {IViewOptionsState} from './IViewOptionsState'

const viewOptionsInitialState: IViewOptionsState = {
  isEditSidebarOpen: false,
  isImportDialogOpen: false,
  publishedScenarioId: null,
  resizeLinePosition: null,
  roleConflictMonthIndex: 0,
  budgetConflictMonthIndex: 0,
  isLoading: false,
  isImportLoading: false,
  isReordering: false,
  isBudgetResolvable: false,
  resolvableRoles: [],
  visibleConflict: null,
  shouldAnimatePeopleWidget: false,
  shouldAnimateBudgetWidget: false,
  shouldSlideScenario: false,
  isPeopleWidgetExpanded: false,
  isBudgetWidgetExpanded: false,
  draggingInitiativeId: null,
  isOpenRoleDistributionDialog: false,
}

export function viewOptions(state = viewOptionsInitialState, action): IViewOptionsState {
  switch (action.type) {
    case OPEN_EDIT_SIDEBAR:
      return {
        ...state,
        isEditSidebarOpen: action.isOpen,
        visibleConflict: null,
      }
    case TOGGLE_IMPORT_DIALOG:
      return {
        ...state,
        isImportDialogOpen: action.isOpen,
      }
    case TOGGLE_PUBLISH_DIALOG:
      return {
        ...state,
        publishedScenarioId: action.id,
      }
    case REORDER_DRAG_AND_DROP:
      return {
        ...state,
        isReordering: action.isReordering,
      }
    case UPDATE_RESIZE_LINE_POSITION:
      return {
        ...state,
        resizeLinePosition: action.resizeLinePosition,
      }
    case CHANGE_LOADING_MODE: {
      return {
        ...state,
        isLoading: action.isLoading,
      }
    }
    case CHANGE_IMPORT_LOADING_MODE: {
      return {
        ...state,
        isImportLoading: action.isLoading,
      }
    }
    case CHANGE_RESOLVABLE_ROLES:
      return {
        ...state,
        resolvableRoles: action.resolvableRoles,
      }
    case CHANGE_BUDGET_RESOLVABLE:
      return {
        ...state,
        isBudgetResolvable: action.isBudgetResolvable,
      }
    case CHANGE_VISIBLE_CONFLICT:
      return {
        ...state,
        visibleConflict: action.conflict,
      }
    case RESOLVE_CONFLICTS: {
      return {
        ...state,
        shouldAnimatePeopleWidget: action.resolvedRoles.length > 0,
        shouldAnimateBudgetWidget: action.budgets !== null,
      }
    }
    case CHANGE_PEOPLE_WIDGET_ANIMATION:
      return {
        ...state,
        shouldAnimatePeopleWidget: action.shouldAnimatePeopleWidget,
      }
    case CHANGE_BUDGET_WIDGET_ANIMATION:
      return {
        ...state,
        shouldAnimateBudgetWidget: action.shouldAnimateBudgetWidget,
      }
    case SLIDE_COPIED_SCENARIO:
      return {
        ...state,
        shouldSlideScenario: action.shouldSlideScenario,
      }
    case TOGGLE_PEOPLE_WIDGET: {
      const isPeopleWidgetExpanded = state.isEditSidebarOpen ? false : action.isPeopleWidgetExpanded
      const isBudgetWidgetExpanded = isPeopleWidgetExpanded ? false : action.isBudgetWidgetExpanded

      return {
        ...state,
        isPeopleWidgetExpanded,
        isBudgetWidgetExpanded,
      }
    }
    case TOGGLE_BUDGET_WIDGET: {
      const isBudgetWidgetExpanded = state.isEditSidebarOpen ? false : action.isBudgetWidgetExpanded
      const isPeopleWidgetExpanded = isBudgetWidgetExpanded ? false : state.isPeopleWidgetExpanded
      return {
        ...state,
        isPeopleWidgetExpanded,
        isBudgetWidgetExpanded,
      }
    }
    case UPDATE_ROLE_CONFLICT_MONTH_INDEX: {
      const {roleConflictMonthIndex} = action
      return {
        ...state,
        roleConflictMonthIndex,
      }
    }
    case UPDATE_BUDGET_CONFLICT_MONTH_INDEX: {
      const {budgetConflictMonthIndex} = action
      return {
        ...state,
        budgetConflictMonthIndex,
      }
    }
    case SET_DRAGGING_INITIATIVE_ID:
      return {
        ...state,
        draggingInitiativeId: action.draggingInitiativeId,
      }
    case OPEN_ROLE_DISTRIBUTION_DIALOG:
      return {
        ...state,
        isOpenRoleDistributionDialog: action.isOpenRoleDistributionDialog,
      }
    default:
      return state
  }
}
