import {Moment} from 'moment'
import {getIntersection, resetInitiativesBudgetConflicts} from '..'
import {IInitiative, IInitiativeConflicts, IInitiativeCosts} from '../../models/initiative'
import {IScenario, IScenarioRole} from '../../models/scenario'
import {resetInitiativesConflicts} from './RealtimeConflictsHelper'
import {calculateBudgetConflicts} from './BudgetConflictHelper'
import {
  calculateConflicts,
  calculateConflictsSubset,
  hasDistributionChangedValue,
  processBudgetConflicts,
} from './ConflictHelper'
import {calculateSingleRoleConflicts} from './RoleConflictHelper'

export function updateAllConflictsInScenario(
  showConflicts: boolean,
  scenario: IScenario,
  usePeopleCostOnConflictCalculation: boolean,
  hasFinancialAccess: boolean
) {
  scenario.initiatives = resetInitiativesConflicts(scenario.initiatives)
  if (showConflicts) {
    const conflicts = calculateConflicts(
      scenario.people.roles,
      scenario.budgets,
      scenario.initiatives,
      usePeopleCostOnConflictCalculation,
      hasFinancialAccess
    )
    for (const [order, conflict] of conflicts.entries()) {
      scenario.initiatives[+order - 1].conflicts = conflict
    }
  }
}

// this function should be called when has financial access
export function updateBudgetConflictsInScenario(
  scenario: IScenario,
  usePeopleCostOnConflictCalculation: boolean
) {
  scenario.initiatives = resetInitiativesBudgetConflicts(scenario.initiatives)
  const budgetConflicts: Map<string, IInitiativeConflicts> = new Map()

  processBudgetConflicts(
    scenario.budgets!, // here scenario.budgets can't be null
    scenario.initiatives,
    budgetConflicts,
    usePeopleCostOnConflictCalculation
  )

  for (const [order, conflict] of budgetConflicts.entries()) {
    if (scenario.initiatives[+order - 1].conflicts) {
      scenario.initiatives[+order - 1].conflicts!.budgetConflicts = conflict.budgetConflicts
    } else {
      scenario.initiatives[+order - 1].conflicts = {
        budgetConflicts: conflict.budgetConflicts,
        roleConflicts: new Map(),
        isPriorityConflict: false,
      }
    }
  }

  updatePriorityConflicts(scenario.initiatives)
}

// updates conflicts after initiative update
export function updateConflictsAfterInitiativeUpdate(
  showConflicts: boolean,
  scenario: IScenario,
  updatedInitiative: IInitiative,
  usePeopleCostOnConflictCalculation: boolean,
  startDate?: Moment,
  endDate?: Moment
) {
  const initiatives = scenario.initiatives.map((initiative) => {
    if (initiative.id === updatedInitiative.id) {
      return updatedInitiative
    } else {
      return {...initiative}
    }
  })
  if (showConflicts) {
    const correspondingInitiative = scenario.initiatives.find((i) => i.id === updatedInitiative.id)
    const {added, updated, deleted} = getIntersection(
      correspondingInitiative ? correspondingInitiative!.people.roles : [],
      updatedInitiative.people.roles
    )
    const changedRoleIds = [...added, ...updated, ...deleted].map((r) => r.id)
    // updatedInitiative.costs can be null when not financial access
    const hasBudget =
      updatedInitiative.costs !== null &&
      (!correspondingInitiative ||
        hasBudgetChanged(
          updatedInitiative.costs!,
          correspondingInitiative.costs!,
          usePeopleCostOnConflictCalculation
        ))

    const conflicts = calculateConflictsSubset(
      scenario.people.roles,
      scenario.budgets,
      initiatives,
      changedRoleIds,
      hasBudget,
      usePeopleCostOnConflictCalculation,
      startDate,
      endDate
    )
    //reset for period
    for (const initiative of initiatives) {
      if (
        initiative.conflicts &&
        (!(startDate && endDate) ||
          initiative.dates.startDate.isBetween(startDate, endDate, 'd', '[]') ||
          startDate.isBetween(initiative.dates.startDate, initiative.dates.endDate, 'd', '[]'))
      ) {
        changedRoleIds.forEach((id) => initiative.conflicts!.roleConflicts.delete(id))
        if (hasBudget) {
          initiative.conflicts.budgetConflicts = null
        }
      }
    }
    for (const [order, conflict] of conflicts.entries()) {
      if (initiatives[+order - 1].conflicts === null) {
        initiatives[+order - 1].conflicts = {
          roleConflicts: new Map(),
          budgetConflicts: null,
          isPriorityConflict: false,
        }
      }
      if (hasBudget) {
        initiatives[+order - 1].conflicts!.budgetConflicts = conflict.budgetConflicts
      }
      for (const [roleId, roleConflict] of conflict.roleConflicts.entries()) {
        initiatives[+order - 1].conflicts!.roleConflicts.set(roleId, roleConflict)
      }
    }
    updatePriorityConflicts(initiatives)
  }
  scenario.initiatives = initiatives
}

export function updateBudgetConflicts(
  showConflicts: boolean,
  scenario: IScenario,
  usePeopleCostOnConflictCalculation: boolean
) {
  if (showConflicts) {
    const initiatives: IInitiative[] = scenario.initiatives.map((initiative) => {
      if (initiative.conflicts !== null) {
        initiative.conflicts.budgetConflicts = null
      }
      return {...initiative}
    })
    const budgetConflicts = calculateBudgetConflicts(
      scenario.budgets,
      initiatives,
      usePeopleCostOnConflictCalculation
    )
    for (const [order, budgetConflict] of budgetConflicts.entries()) {
      const initiative = initiatives[+order - 1]
      if (initiative.conflicts !== null) {
        initiative.conflicts = {
          ...initiative.conflicts,
          budgetConflicts: budgetConflict,
        }
      } else {
        initiative.conflicts = {
          roleConflicts: new Map(),
          budgetConflicts: budgetConflict,
          isPriorityConflict: false,
        }
      }
    }
    updatePriorityConflicts(initiatives)
    scenario.initiatives = initiatives
  }
}

export function updateSingleRoleConflicts(
  showConflicts: boolean,
  scenarioRole: IScenarioRole,
  initiatives: IInitiative[]
) {
  if (showConflicts) {
    initiatives.forEach((initiative, index) => {
      if (initiative.conflicts !== null) {
        initiative.conflicts.roleConflicts.delete(scenarioRole.role.id)
      }
      initiatives[index] = {...initiative}
    })
    const roleConflicts = calculateSingleRoleConflicts(scenarioRole, initiatives)
    for (const [order, roleConflict] of roleConflicts.entries()) {
      const initiative = initiatives[+order - 1]
      if (initiative.conflicts !== null) {
        initiatives[+order - 1] = {
          ...initiative,
          conflicts: {
            ...initiative.conflicts,
            roleConflicts: new Map([
              ...initiative.conflicts.roleConflicts,
              [roleConflict.roleId, roleConflict],
            ]),
          },
        }
      } else {
        initiatives[+order - 1] = {
          ...initiative,
          conflicts: {
            roleConflicts: new Map([[roleConflict.roleId, roleConflict]]),
            budgetConflicts: null,
            isPriorityConflict: false,
          },
        }
      }
    }
    updatePriorityConflicts(initiatives)
  }
}

function updatePriorityConflicts(initiatives: IInitiative[]) {
  let hasConflictedInitiative = false
  for (let i = 0; i < initiatives.length; i++) {
    const initiative = initiatives[i]
    if (initiative.conflicts !== null) {
      if (
        initiative.conflicts.roleConflicts.size > 0 ||
        initiative.conflicts.budgetConflicts !== null
      ) {
        hasConflictedInitiative = true
      } else if (hasConflictedInitiative) {
        initiative.conflicts.isPriorityConflict = true
      } else {
        initiative.conflicts = null
      }
    } else if (hasConflictedInitiative) {
      initiative.conflicts = {
        roleConflicts: new Map(),
        budgetConflicts: null,
        isPriorityConflict: true,
      }
    }
  }
}

function hasBudgetChanged(
  oldCosts: IInitiativeCosts,
  newCosts: IInitiativeCosts,
  usePeopleCostOnConflictCalculation: boolean
): boolean {
  return usePeopleCostOnConflictCalculation
    ? hasDistributionChangedValue(
        oldCosts.overallCostDistribution!,
        newCosts.overallCostDistribution!
      )
    : hasDistributionChangedValue(oldCosts.fixedCostDistribution!, newCosts.fixedCostDistribution!)
}
