import {primary} from '@phoenix/all'
import {css, cx} from 'emotion'
import * as React from 'react'
import {sidebarRowClass} from './roles/StyleHelper'

interface IConflictDetailWrapperComponentProps {
  className?: string
  hasConflict: boolean
  index?: number
  testID: string
}

const hasConflictedRolesClass = css`
  padding-top: 0 !important;
  background-color: ${primary.gray(100)};

  & + & {
    margin-top: 12px;
  }
`

const firstConflictedRowClass = css`
  margin-top: 0;
`

export const ConflictDetailWrapperComponent: React.FC<IConflictDetailWrapperComponentProps> = React.memo(
  ({children, className, hasConflict, index, testID}) => {
    const conflictedClassNames = React.useMemo(
      () =>
        `${hasConflict ? hasConflictedRolesClass : ''} ${
          index === 0 && hasConflict ? firstConflictedRowClass : ''
        }`,
      [index, hasConflict]
    )

    return (
      <div
        className={`${cx(className || '', sidebarRowClass)} ${conflictedClassNames}`}
        data-testid={testID}
      >
        {children}
      </div>
    )
  }
)

ConflictDetailWrapperComponent.displayName = 'ConflictDetailWrapperComponent'

ConflictDetailWrapperComponent.defaultProps = {
  index: 0,
}
