import {FVGPlugin} from '../../../../shared/toolbar/filters/FVGPlugin'
import {bindPluginClass} from '@workfront/list-plugin-utils'
import loadFilters from './loadFilters'
import {IDataTableAPI} from '@wftypes/list'
import {FilterChangeParams} from '../../../../shared/toolbar/filters/types'
import DataFetcherPlugin, {DataFetcherAPI} from '../../dataFetcher'
import {getStorageUtil} from '@workfront/storage'
import IconWidget from '../../../../shared/toolbar/widgets/IconWidget'
import SeparatorWidget from '../../../../shared/toolbar/widgets/SeparatorWidget'
import * as React from 'react'
import FVGOverlay from '../../../../shared/toolbar/filters/FVGOverlay'
import {IToolbarApi} from '@wf-mfe/lists'
import {TOOLBAR_PLUGIN_NAME} from '../../../../shared/toolbar/constants'
import FilterIcon from 'phoenix-icons/dist/FilterIcon.js'
const storageUtil = getStorageUtil()

export class ExportFVGPlugin extends FVGPlugin {
  setDefaultFilter = (filter: {id: string; name: string}) => {
    if (filter.id === '') {
      this.filterWidgetApi!.setTextMessageKey('all')
    } else {
      this.filterWidgetApi!.setText(filter.name)
      this.overlayComponentRef.current!.setCurrentFilterID(filter.id)
    }
  }
  handleFilterChange = (listApi: IDataTableAPI, planId) => (filter: FilterChangeParams) => {
    this.handleClosePanel('')
    const currentFilterID = this.overlayComponentRef.current!.getCurrentFilterID()
    this.overlayComponentRef.current!.setCurrentFilterID(filter.ID)
    if (currentFilterID !== filter.ID && filter.ID !== undefined) {
      storageUtil.set(`sp-export-initiative-default-filter-${planId}`, filter.ID)
      const dataFetcherApi = listApi.getPluginAPI<DataFetcherAPI>(DataFetcherPlugin.getName())
      this.filterWidgetApi!.setText(filter.text)
      dataFetcherApi!.filterListData(filter)
    }
  }

  ready(listApi: IDataTableAPI, options?) {
    const toolbarApi = listApi.getPluginAPI<IToolbarApi>(TOOLBAR_PLUGIN_NAME)!
    const dataFetcherApi = listApi.getPluginAPI<DataFetcherAPI>(DataFetcherPlugin.getName())!
    const WIDGET_TYPE_ICON = 'icon'

    this.addShutdownRoutine(
      toolbarApi.registerWidget(WIDGET_TYPE_ICON, IconWidget),
      toolbarApi.registerWidget(toolbarApi.SEPARATOR_WIDGET_TYPE, SeparatorWidget),
      listApi.overlays.appendOverlay(listApi.overlays.locations.RIGHT_SIDEBAR, () => {
        return (
          <FVGOverlay
            key="fvg-selection-overlay"
            ref={this.overlayComponentRef}
            listApi={listApi}
            onClosePanel={this.handleClosePanel}
            loadFilters={this.loadFilters()}
            onFilterChange={this.handleFilterChange(listApi, options.planId)}
          />
        )
      })
    )

    listApi.overlays.forceUpdateOverlayContainer(listApi.overlays.locations.RIGHT_SIDEBAR)

    let position = 900
    this.addShutdownRoutine(
      toolbarApi.registerCommand({
        id: 'open-filter-selection-panel',
        requirements: {},
        executor: this.openFilterSelectionPanel,
      })
    )

    this.addShutdownRoutine(
      toolbarApi.insertComponent(toolbarApi.ToolbarLocations.secondary, {
        id: 'separator-before-filter-icon',
        position: position++,
        widgetType: toolbarApi.SEPARATOR_WIDGET_TYPE,
      }),
      toolbarApi.insertComponent(toolbarApi.ToolbarLocations.secondary, {
        id: 'filter-icon',
        position: position++,
        primaryCommandId: 'open-filter-selection-panel',
        widgetType: WIDGET_TYPE_ICON,
        widgetOptions: {
          dataTestId: 'open-filter-selection-panel',
          icon: FilterIcon,
          showText: true,
          showBadge: false,
          isCheckable: true,
          getApi: (widgetApi) => {
            this.filterWidgetApi = widgetApi
          },
          labelMessageKey: 'filter',
        },
      }),
      toolbarApi.insertComponent(toolbarApi.ToolbarLocations.secondary, {
        id: 'separator-after-filter-icon',
        position: position++,
        widgetType: toolbarApi.SEPARATOR_WIDGET_TYPE,
      })
    )
    dataFetcherApi.getDefaultFilterPromise().then(({id, name = ''}) => {
      setTimeout(() => {
        this.setDefaultFilter({id, name})
      }, 0)
    })
  }
  loadFilters = () => {
    return loadFilters
  }
}

export default bindPluginClass(
  () => new ExportFVGPlugin(),
  FVGPlugin.PLUGIN_NAME,
  FVGPlugin.PLUGIN_DEPS || []
)
