import {css, cx} from 'emotion'
import isEqual from 'lodash/isEqual'
import {Moment} from 'moment'
import * as React from 'react'
import {connect} from 'react-redux'

import {Button, ConfirmDialog, primary, Text} from '@phoenix/all'
import CloseIcon from 'phoenix-icons/dist/CloseIcon.js'
import ProjectSmallHeroIcon from 'phoenix-icons/dist/ProjectSmallHeroIcon.js'

import {
  changeCurrentInitiative,
  changePreCalculatedData,
  changeResolvableRoles,
  currentInitiativeSelector,
  currentPlanSelector,
  currentScenarioBudgetSelector,
  currentScenarioPeopleSelector,
  currentScenarioSelector,
  globalRolesSelector,
  isBudgetResolvableSelector,
  resolvableRolesSelector,
  resolveConflicts,
  showConflictsSelector,
  toggleBudgetResolvable,
  toggleEditSidebar,
  updateBudgetConflictMonthIndex,
  updateCurrentInitiative,
  updateInitiative,
  updateRoleConflictMonthIndex,
  viewAccessSelector,
} from '../../../../data'
import {
  resolveBudgetConflict,
  resolveRoleConflicts,
  serializeInitiative,
} from '../../../../shared/helpers'
import {
  IInitiative,
  IScenarioBudgets,
  IScenarioPeople,
  IScenarioRole,
} from '../../../../shared/models'
import {InlineEditComponent, resetButtonDefaultStyles} from '../../../shared'
import {LinkedProjectIcon} from '../Initiative/LinkedProjectIconComponent'
import {EditSidebarBodyComponent} from './EditSidebarBodyComponent'
import {peopleCostOnConflictCalculationSelector} from '../../../../data/plan/selectors/peopleCostOnConflictCalculationSelector'
import {IGlobalRoles} from '../../../../shared/models/IGlobalRoles'
import {hoursForMeasurementsSelector} from '../../../../data/plan/selectors/hoursForMeasurementsSelector'
import {Translate} from '../../../shared/Translate'
import {translate} from '../../../../shared/utilities/TranslateHelper'
import {ConfirmationDialogHeaderComponent} from '../../../shared/ConfirmationDialogHeaderComponent'

interface IEditSidebarProps {
  currentInitiative: IInitiative
  people: IScenarioPeople
  resolvableRoles: string[]
  budgets: IScenarioBudgets
  isBudgetResolvable: boolean
  hasChanges: boolean
  isViewMode: boolean
  showConflicts: boolean
  planStartDate: Moment
  planDuration: number
  updateCurrentInitiative: (
    changes: any,
    showConflicts: boolean,
    usePeopleCostOnConflictCalculation: boolean
  ) => void
  resolveConflictsFunction: (
    initiative: IInitiative,
    resolvedRoles: IScenarioRole[],
    resolvedBudgets: IScenarioBudgets | null,
    usePeopleCostOnConflictCalculation: boolean
  ) => void
  applyChangesFunction: (
    initiative: IInitiative,
    usePeopleCostOnConflictCalculation: boolean
  ) => void
  closeSidebarFunction: (usePeopleCostOnConflictCalculation: boolean) => void
  usePeopleCostOnConflictCalculation: boolean
  globalRoles: IGlobalRoles
  useHoursForMeasurements: boolean
}

interface IEditSidebarState {
  name: string
  isWarningDialogOpen: boolean
}

const sidebarClass = css`
  box-shadow: -4px 0 4px 0 ${primary.gray(200)};
  background-color: ${primary.gray(0)};
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 400px;
  z-index: 101;
`
const sidebarHeaderClass = css`
  padding: 0 24px;
`

const sidebarCloseButtonClass = css`
  ${resetButtonDefaultStyles};
`

const dropShadow = `
  display: block;
  width: calc(100% + 16px);
  margin-left: -8px;
`

const sidebarBodyClass = css`
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  flex-grow: 1;
  padding: 0 8px;

  &:after,
  &:before {
    ${dropShadow};
    content: '';
    position: sticky;
    z-index: 10;
    height: 1px;
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
    background: ${primary.gray(0)};
  }

  &:after {
    bottom: -1px;
  }

  &:before {
    top: 0;
  }
`

const hideBodyShadowClass = css`
  ${dropShadow};
  background: ${primary.gray(0)};
  position: absolute;
  z-index: 100;
  height: 10px;
`

const hideBodyBottomShadowClass = css`
  margin-top: -10px;
`

const sidebarFooterClass = css`
  padding: 0 16px;
`

const sidebarFooterInnerClass = css`
  border-top: 1px solid ${primary.gray(200)};
`

const initiativeName = css`
  width: 300px;
  display: flex;
  align-items: center;
`

const sidebarHeaderInnerClass = css`
  justify-content: space-between;
`

const innerBorderClass = css`
  display: flex;
  padding: 16px 0;
`

const inlineEditClass = css`
  font-size: 16px;
  color: ${primary.gray(800)};
  margin-right: 17px;
  min-width: 108px;
  flex: 1;
`

const initiativeNameClass = css`
  ${inlineEditClass};
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
  line-height: 22px;
  margin-left: 1px;
`

export class EditSidebar extends React.Component<IEditSidebarProps, IEditSidebarState> {
  initiativeNameRef: React.RefObject<HTMLInputElement>

  constructor(props: IEditSidebarProps) {
    super(props)
    this.state = {
      name: props.currentInitiative.name,
      isWarningDialogOpen: false,
    }

    this.initiativeNameRef = React.createRef()
  }

  componentDidUpdate(prevProps: IEditSidebarProps) {
    if (this.props.currentInitiative.id !== prevProps.currentInitiative.id) {
      this.setState({
        name: this.props.currentInitiative.name,
      })
    }
  }

  render() {
    return (
      <aside data-testid="edit-sidebar" className={sidebarClass}>
        <header className={sidebarHeaderClass} data-testid="edit-sidebar-header">
          <div className={cx(innerBorderClass, sidebarHeaderInnerClass)}>
            <div className={initiativeName}>
              <LinkedProjectIcon
                icon={ProjectSmallHeroIcon}
                refObject={this.props.currentInitiative.refObject}
              />
              {!this.props.isViewMode ? (
                <InlineEditComponent
                  ref={this.initiativeNameRef}
                  maxLength={255}
                  onKeyDown={this.handleKeyDown}
                  tabIndex={-1}
                  className={css`
                    font-size: 16px;
                  `}
                  placeholder={translate('form.start.typing.name')}
                  onChange={this.handleNameChange}
                  onBlur={this.onBlurHandler}
                  onFocus={() => {
                    if (
                      translate('sp.initiative.default.name') === this.props.currentInitiative.name
                    ) {
                      // setTimeout fix safari issue
                      setTimeout(() => {
                        this.initiativeNameRef.current!.select()
                      })
                    }
                  }}
                  value={this.state.name}
                  testId={'initiative-name'}
                />
              ) : (
                <div className={initiativeNameClass}>{this.props.currentInitiative.name}</div>
              )}
            </div>
            <button
              className={sidebarCloseButtonClass}
              data-testid="edit-sidebar-close"
              onClick={this.handleClose}
            >
              <CloseIcon />
            </button>
          </div>
        </header>

        <div className={`${sidebarBodyClass} edit-sidebar-body`} data-testid="edit-sidebar-body">
          <span className={hideBodyShadowClass} />
          <EditSidebarBodyComponent />
          <span className={cx(hideBodyShadowClass, hideBodyBottomShadowClass)} />
        </div>

        {!this.props.isViewMode && (
          <footer className={sidebarFooterClass} data-testid="edit-sidebar-footer">
            <div className={cx(innerBorderClass, sidebarFooterInnerClass)}>
              <Button
                secondary
                disabled={!this.props.hasChanges}
                onClick={this.onApplyClickedAtDialog}
                testID="apply-sidebar"
              >
                <Translate messageKey={'form.button.apply'} />
              </Button>

              <Button
                text
                disabled={!this.props.hasChanges}
                onClick={this.onDiscardClickedAtSidebar}
                testID="discard-sidebar"
              >
                <Translate messageKey={'form.button.discard'} />
              </Button>
            </div>
          </footer>
        )}
        {this.state.isWarningDialogOpen && (
          <ConfirmDialog
            header={<ConfirmationDialogHeaderComponent messageKey={'global.unapplied'} />}
            confirmText={<Translate messageKey={'form.button.apply'} />}
            denyText={<Translate messageKey={'form.button.discard'} />}
            onConfirmClick={this.onApplyClickedAtSidebar}
            onDenyClick={this.onDiscardClickedAtDialog}
          >
            <Text>
              <Translate messageKey={'sidebar.confirmation.message'} />
            </Text>
          </ConfirmDialog>
        )}
      </aside>
    )
  }

  private handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.initiativeNameRef.current!.blur()
    }
  }
  private onApplyClickedAtSidebar = () => {
    this.applyChanges()
  }

  private onApplyClickedAtDialog = () => {
    this.applyChanges()

    this.setState({
      isWarningDialogOpen: false,
    })
  }
  private applyChanges = () => {
    const {
      people,
      budgets,
      currentInitiative,
      planStartDate,
      planDuration,
      resolvableRoles,
      isBudgetResolvable,
      showConflicts,
      globalRoles,
      useHoursForMeasurements,
    } = this.props
    if (
      showConflicts &&
      currentInitiative.conflicts !== null &&
      (isBudgetResolvable || resolvableRoles.length > 0)
    ) {
      const resolvedRoles = resolveRoleConflicts(
        people.roles,
        resolvableRoles,
        currentInitiative.conflicts.roleConflicts,
        planStartDate,
        planDuration,
        globalRoles,
        useHoursForMeasurements
      )
      const resolvedBudgets =
        isBudgetResolvable && currentInitiative.conflicts.budgetConflicts
          ? resolveBudgetConflict(budgets, currentInitiative.conflicts.budgetConflicts)
          : null
      this.props.resolveConflictsFunction(
        currentInitiative,
        resolvedRoles,
        resolvedBudgets,
        this.props.usePeopleCostOnConflictCalculation
      )
    } else {
      this.props.applyChangesFunction(
        this.props.currentInitiative,
        this.props.usePeopleCostOnConflictCalculation
      )
    }

    this.props.closeSidebarFunction(this.props.usePeopleCostOnConflictCalculation)
  }

  private handleClose = () => {
    if (this.props.hasChanges) {
      this.setState({
        isWarningDialogOpen: true,
      })
    } else {
      this.props.closeSidebarFunction(this.props.usePeopleCostOnConflictCalculation)
    }
  }

  private updateInitiativeName = (name) => {
    const changes = {
      name,
    }
    this.props.updateCurrentInitiative(
      changes,
      this.props.showConflicts,
      this.props.usePeopleCostOnConflictCalculation
    )
  }

  private onBlurHandler = ({target}) => {
    const name =
      target.value.trim() === '' ? this.props.currentInitiative.name : target.value.trim()

    this.setState({name})
    this.updateInitiativeName(name)
  }

  private handleNameChange = ({target}) => {
    this.setState({
      name: target.value,
    })
  }
  private onDiscardClickedAtSidebar = () => {
    this.props.closeSidebarFunction(this.props.usePeopleCostOnConflictCalculation)
  }

  private onDiscardClickedAtDialog = () => {
    this.props.closeSidebarFunction(this.props.usePeopleCostOnConflictCalculation)

    this.setState({
      isWarningDialogOpen: false,
    })
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateCurrentInitiative: (changes, showConflicts, usePeopleCostOnConflictCalculation) =>
    dispatch(updateCurrentInitiative(changes, showConflicts, usePeopleCostOnConflictCalculation)),
  applyChangesFunction: (currentInitiative, usePeopleCostOnConflictCalculation) =>
    dispatch(updateInitiative(currentInitiative, usePeopleCostOnConflictCalculation)),
  closeSidebarFunction: (usePeopleCostOnConflictCalculation) => {
    dispatch(updateRoleConflictMonthIndex(0))
    dispatch(updateBudgetConflictMonthIndex(0))
    dispatch(toggleEditSidebar(false))
    dispatch(changeCurrentInitiative(null, null, usePeopleCostOnConflictCalculation))
    dispatch(changePreCalculatedData(null, usePeopleCostOnConflictCalculation))
    dispatch(changeResolvableRoles([]))
    dispatch(toggleBudgetResolvable(false))
  },
  resolveConflictsFunction: (
    initiative,
    resolvedRoles,
    resolvedBudgets,
    usePeopleCostOnConflictCalculation
  ) =>
    dispatch(
      resolveConflicts(
        initiative,
        resolvedRoles,
        resolvedBudgets,
        usePeopleCostOnConflictCalculation
      )
    ),
})

const mapStateToProps = (state) => ({
  currentInitiative: currentInitiativeSelector(state),
  people: currentScenarioPeopleSelector(state),
  budgets: currentScenarioBudgetSelector(state),
  isBudgetResolvable: isBudgetResolvableSelector(state),
  hasChanges:
    (currentInitiativeSelector(state) &&
      !isEqual(
        serializeInitiative(currentInitiativeSelector(state)!),
        serializeInitiative(
          currentScenarioSelector(state)!.initiatives.find(
            (initiative) => initiative.id === currentInitiativeSelector(state)!.id
          )!
        )
      )) ||
    ((resolvableRolesSelector(state).length > 0 || isBudgetResolvableSelector(state)) &&
      showConflictsSelector(state)),
  isViewMode: viewAccessSelector(state),
  resolvableRoles: resolvableRolesSelector(state),
  showConflicts: showConflictsSelector(state),
  planStartDate: currentPlanSelector(state)!.startDate,
  planDuration: currentPlanSelector(state)!.duration,
  usePeopleCostOnConflictCalculation: peopleCostOnConflictCalculationSelector(state),
  globalRoles: globalRolesSelector(state),
  useHoursForMeasurements: hoursForMeasurementsSelector(state),
})

export const EditSidebarComponent = connect(mapStateToProps, mapDispatchToProps)(EditSidebar)
