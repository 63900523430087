import {css} from 'emotion'
import * as React from 'react'
import {connect} from 'react-redux'

import {Button, primary, ConfirmDialog, Text} from '@phoenix/all'
import {currentPlanSelector, savedPlanSelector} from '../../data/plan'
import {currentScenarioSelector} from '../../data/scenario'
import {isEditSidebarOpenSelector, isLoadingSelector} from '../../data/viewOptions'
import {hasPlanChanges, hasScenarioChanges, isNewPlan} from '../../shared/helpers'
import {useSavePlanHook} from '../../shared/helpers/useSavePlanHook'
import {IPlan, IScenario} from '../../shared/models'
import {Translate} from '../shared/Translate'
import {ConfirmationDialogHeaderComponent} from '../shared/ConfirmationDialogHeaderComponent'

export interface IGridFooterComponentProps {
  savedPlan: IPlan
  currentPlan: IPlan
  currentScenario: IScenario | null
  isLoading: boolean
  isEditSidebarOpen: boolean
  onSuccessfulSaveFunction: (planId: string, currentScenarioId: string | null) => void
  navigateToListFunction: () => void
  lastScenario: IScenario
}

const footerClass = css`
  background: ${primary.gray(0)};
  border-top: 1px solid ${primary.gray(200)};
  padding: 16px 17px;
  width: 100%;
`

export const Footer: React.FunctionComponent<IGridFooterComponentProps> = (props) => {
  const [isConfirmDialogOpen, setConfirmDialog] = React.useState(false)

  const {savePlan} = useSavePlanHook(props.onSuccessfulSaveFunction)

  const {currentPlan, savedPlan, currentScenario, isLoading} = props
  const {navigateToListFunction} = props
  const isPlanChanged =
    isNewPlan(currentPlan) ||
    hasPlanChanges(savedPlan, currentPlan) ||
    hasScenarioChanges(currentPlan, currentScenario)

  const onCancel = () => {
    isPlanChanged ? setConfirmDialog(true) : navigateToListFunction()
  }

  const discard = () => {
    setConfirmDialog(false)
    navigateToListFunction()
  }

  const cancel = () => {
    setConfirmDialog(false)
  }

  return (
    <footer className={footerClass} data-testid="feedback-button">
      <Button primary onClick={savePlan} disabled={props.isEditSidebarOpen} testID="save">
        {isLoading ? (
          <Translate messageKey={'sp.form.button.saving'} />
        ) : (
          <Translate messageKey={'sp.form.button.save'} />
        )}
      </Button>
      <Button text onClick={onCancel} disabled={props.isEditSidebarOpen} testID="cancel">
        <Translate messageKey={'form.button.cancel'} />
      </Button>
      {isConfirmDialogOpen && (
        <ConfirmDialog
          header={<ConfirmationDialogHeaderComponent messageKey={'scenario.unsaved'} />}
          confirmText={<Translate messageKey={'form.button.discard'} />}
          denyText={<Translate messageKey={'form.button.cancel'} />}
          onConfirmClick={discard}
          onDenyClick={cancel}
        >
          <Text>
            <Translate messageKey={'scenario.unsaved.message'} />
          </Text>
        </ConfirmDialog>
      )}
    </footer>
  )
}

const mapStateToProps = (state) => ({
  savedPlan: savedPlanSelector(state),
  currentPlan: currentPlanSelector(state),
  currentScenario: currentScenarioSelector(state),
  isLoading: isLoadingSelector(state),
  isEditSidebarOpen: isEditSidebarOpenSelector(state),
})

export const FooterComponent = connect(mapStateToProps)(Footer)
