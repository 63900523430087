import {createSelector} from 'reselect'
import {hasScenarioConflict} from '../../../shared/helpers'
import {IScenario} from '../../../shared/models/scenario'
import {currentScenarioSelector} from './currentScenarioSelector'

export const currentScenarioHasConflictSelector = createSelector(
  currentScenarioSelector,
  (currentScenario: IScenario | null) => {
    return hasScenarioConflict(currentScenario!)
  }
)
