import ShareIcon from 'phoenix-icons/dist/ShareIcon.js'
import * as React from 'react'
import ToolbarButton from '../../../../shared/toolbar/components/ToolbarButton'
import {Localization, LocalizationProvider} from '@wf-mfe/localize-react'
import {redrockClientFactory} from '../../../../../shared/L10n'

export interface ISharePlanComponent {
  onShareFunction: () => void
}

export const SharePlanComponent: React.FunctionComponent<ISharePlanComponent> = (props) => {
  return (
    <LocalizationProvider clientFactory={redrockClientFactory}>
      <Localization messageKey="action.share" fallback="Share">
        {(getShareMessage) => (
          <ToolbarButton
            dataTestId="share-plans"
            icon={ShareIcon}
            tooltipLabel={getShareMessage()}
            onClick={props.onShareFunction}
          />
        )}
      </Localization>
    </LocalizationProvider>
  )
}
