import {PluginBase, bindPluginClass} from '@workfront/list-plugin-utils'
import type {IDataTableAPI} from '@wftypes/list'
import {IToolbarApi} from '@wf-mfe/lists'
import QuickFilterWidget from '../../../shared/toolbar/widgets/QuickFilterWidget'
import {SelectionCountWidget} from '../../../shared/toolbar/widgets/SelectionCountWidget'
import {PLUGIN_NAME, TOOLBAR_PLUGIN_NAME} from '../../../shared/toolbar/constants'
import {LIST_ID} from '../../table-data'
import {EmptyListWidget} from '../../../shared/renderers/EmptyListComponent'

export const SELECTION_CHANGED_EVENT = 'listSelectionChanged'

class ToolbarDefinitionsPlugin extends PluginBase<IToolbarApi> {
  static PLUGIN_NAME = PLUGIN_NAME
  static PLUGIN_DEPS = [TOOLBAR_PLUGIN_NAME]

  init(listApi: IDataTableAPI) {
    listApi!.preloadMessageKeys([
      'esp.import.project.selected',
      'sp.import.project.selected.singular',
      'sp.import.project.selected.plural',
      'esp.import.project.selection.limit',
      'esp.import.list.noresults',
      'esp.import.list.noresults.hint',
    ])
  }

  ready(listApi: IDataTableAPI /*, options*/) {
    const toolbarApi = listApi.getPluginAPI(TOOLBAR_PLUGIN_NAME) as IToolbarApi

    this.addShutdownRoutine(
      toolbarApi.registerWidget('selectionCount', SelectionCountWidget),
      toolbarApi.registerWidget('quickFilter', QuickFilterWidget),
      toolbarApi.registerWidget('emptyList', EmptyListWidget)
    )
    this.addShutdownRoutine(
      toolbarApi.insertComponent(toolbarApi.ToolbarLocations.primary, {
        id: 'selectionCount',
        position: 0,
        widgetType: 'selectionCount',
        widgetOptions: {
          listApi,
          selectionLimit: 100,
          selectedMsg: 'sp.import.project.selected',
          selectionLimitMsg: 'esp.import.project.selection.limit',
        },
      }),
      toolbarApi.insertComponent(toolbarApi.ToolbarLocations.secondary, {
        id: 'quickFilter',
        position: 0,
        widgetType: 'quickFilter',
        widgetOptions: {
          listApi,
        },
      }),
      toolbarApi.insertComponent(toolbarApi.ToolbarLocations.primary, {
        id: 'emptyList',
        position: 0,
        widgetType: 'emptyList',
        widgetOptions: {
          listApi,
          mainMsgKey: 'esp.import.list.noresults',
          hintMsgKey: 'esp.import.list.noresults.hint',
        },
      })
    )

    this.addShutdownRoutine(
      listApi.interceptors.registerInterceptor(
        PLUGIN_NAME,
        listApi.interceptors.events.SELECTION_CHANGED,
        () => {
          const customEvent = document.createEvent('CustomEvent')
          const selectionRange = listApi.selection.getSelectionRanges()
          customEvent.initCustomEvent(SELECTION_CHANGED_EVENT, false, false, {
            selectedIDs: selectionRange.reduce((acc, item) => acc.concat(item), []),
          })
          document.getElementById(LIST_ID)!.dispatchEvent(customEvent)
        }
      )
    )

    // Prevent row deselection when clicking outside the list
    this.addShutdownRoutine(
      listApi.interceptors.registerInterceptor(
        PLUGIN_NAME,
        listApi.interceptors.events.BEFORE_OUTSIDE_CLICK,
        () => true
      )
    )
  }
}

export default bindPluginClass(
  () => new ToolbarDefinitionsPlugin(),
  ToolbarDefinitionsPlugin.PLUGIN_NAME,
  ToolbarDefinitionsPlugin.PLUGIN_DEPS || []
)
