import {primary, ConfirmDialog, Text} from '@phoenix/all'
import {css} from 'emotion'
import * as React from 'react'
import {connect} from 'react-redux'
import {currentInitiativeSelector} from '../../data/initiative'
import {currentPlanSelector} from '../../data/plan'
import {currentScenarioSelector} from '../../data/scenario'
import {
  hasInitiativeChanges,
  hasPlanChanges,
  hasScenarioChanges,
  isNewPlan,
} from '../../shared/helpers'
import {IInitiative, IPlan, IScenario} from '../../shared/models'
import {inlineCenterItemsClass} from '../Plan/Scenario/StyleHelper'
import {resetButtonDefaultStyles} from './StyleHelper'
import {Translate} from './Translate'
import {ConfirmationDialogHeaderComponent} from './ConfirmationDialogHeaderComponent'

interface IBackToListButtonComponent {
  currentPlan: IPlan
  savedPlan: IPlan
  currentScenario: IScenario | null
  currentInitiative: IInitiative | null
  navigateToListFunction: () => void
}

const headerTopClass = css`
  ${inlineCenterItemsClass};
  justify-content: space-between;
  padding-top: 20px;
`
const backToListClass = css`
  ${resetButtonDefaultStyles};
  color: ${primary.gray(500)};
  font-size: 12px;
  font-weight: 600;
  font-weight: 16px;
  border-radius: 3px;

  &:hover {
    text-decoration: underline;
  }
`

export const BackToListButton: React.FunctionComponent<IBackToListButtonComponent> = (props) => {
  const [isConfirmDialogOpen, setConfirmDialog] = React.useState(false)

  const {navigateToListFunction, currentPlan, savedPlan, currentScenario, currentInitiative} = props

  const onClick = () => {
    const isPlanChanged =
      isNewPlan(currentPlan) ||
      hasPlanChanges(savedPlan, currentPlan) ||
      hasScenarioChanges(currentPlan, currentScenario) ||
      hasInitiativeChanges(currentScenario, currentInitiative)

    if (isPlanChanged) {
      setConfirmDialog(true)
    } else {
      navigateToListFunction()
    }
  }
  const onDiscard = () => {
    setConfirmDialog(false)
    navigateToListFunction()
  }

  const onCancel = () => {
    setConfirmDialog(false)
  }

  return (
    <React.Fragment>
      <div className={headerTopClass} data-testid="scenario-header-top">
        <button onClick={() => onClick()} className={backToListClass} data-testid="back-to-list">
          <Translate messageKey={'sp.scenario.back.to.list'} />
        </button>
      </div>
      {isConfirmDialogOpen && (
        <ConfirmDialog
          header={<ConfirmationDialogHeaderComponent messageKey={'scenario.unsaved'} />}
          confirmText={<Translate messageKey={'form.button.discard'} />}
          onConfirmClick={onDiscard}
          denyText={<Translate messageKey={'form.button.cancel'} />}
          onDenyClick={onCancel}
        >
          <Text>
            <Translate messageKey={'scenario.unsaved.message'} />
          </Text>
        </ConfirmDialog>
      )}
    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  savedPlan: state.plan.plan,
  currentPlan: currentPlanSelector(state),
  currentScenario: currentScenarioSelector(state),
  currentInitiative: currentInitiativeSelector(state),
})

export const BackToListButtonComponent = connect(mapStateToProps)(BackToListButton)

// TODO: Move near  scenario header. This is not a shared component
