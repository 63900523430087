import {primary} from '@phoenix/all'
import * as React from 'react'
import {connect} from 'react-redux'
import Draggable from 'react-draggable'
import {MeasurementsContext} from '../../../../shared/context/MeasurementsContext'
import {sidebarSizeSelector} from '../../../../data/settings/selectors/sidebarSizeSelector'
import {useCallback, useState} from 'react'
import styled from 'react-emotion'
import {setSidebarSize} from '../../../../data'
import {SIDEBAR_MAX_SIZE, SIDEBAR_SIZE} from '../../../../shared/constants/Sizes'
import {getStorageUtil} from '@workfront/storage'
import {SIDEBAR_SIZE_KEY} from '../../../../shared/constants/localStorage'

export interface ISidebarResizeLine {
  reactWindowHeight: number
  sidebarSize: number
  toggleEditSidebarFunction: (isOpen: boolean) => void
  setSidebarWidth: (width: number) => void
}

const ResizeLineStyled = styled('div')<{
  isResizing: boolean
  lineHeight: number
  initiativeHeaderHeight: number
}>`
  position: absolute;
  z-index: 4;
  width: 8px;
  height: ${(props) => props.lineHeight + props.initiativeHeaderHeight}px;
  top: 0;
  left: -5px;
  min-height: 100%;
  box-sizing: border-box;
  cursor: col-resize;

  &::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 2px;
    background: ${(props) => (props.isResizing ? primary.blue() : 'none')};
    left: 3px;
  }

  &:hover::after {
    background: ${primary.blue()};
  }
`

const FullScreenDivStyled = styled('div')<{lineHeight: number; initiativeHeaderHeight: number}>`
  height: ${(props) => props.lineHeight}px;
  min-height: calc(100% - ${(props) => props.initiativeHeaderHeight}px);
  top: ${(props) => props.initiativeHeaderHeight + 1}px;
  position: absolute;
  width: 100%;
  z-index: 3;
  background: transparent;
  cursor: col-resize;
`

export const SidebarResizeLine: React.FunctionComponent<ISidebarResizeLine> = React.memo(
  (props) => {
    const [isResizing, setIsResizing] = useState<boolean>(false)

    const onDragStart = useCallback(() => {
      setIsResizing(true)
    }, [])

    const onDrag = useCallback(
      (data) => {
        let size = data.screenX

        if (size > SIDEBAR_MAX_SIZE) {
          size = SIDEBAR_MAX_SIZE
        }

        if (size < SIDEBAR_SIZE) {
          size = SIDEBAR_SIZE
        }
        props.setSidebarWidth(size)
      },
      [props.setSidebarWidth]
    )

    const onDragStop = useCallback(() => {
      setIsResizing(false)
      getStorageUtil().set(SIDEBAR_SIZE_KEY, props.sidebarSize)
    }, [props.sidebarSize])

    return (
      <MeasurementsContext.Consumer>
        {({initiativeRowHeight, initiativeHeaderHeight}) => (
          <>
            <Draggable
              enableUserSelectHack={true}
              bounds={{top: 0, left: SIDEBAR_SIZE, right: SIDEBAR_MAX_SIZE, bottom: 0}}
              axis="x"
              position={{x: props.sidebarSize, y: 0}}
              onStart={onDragStart}
              onStop={onDragStop}
              onDrag={onDrag}
            >
              <ResizeLineStyled
                data-testid={'sidebar-resize-line'}
                lineHeight={props.reactWindowHeight - initiativeRowHeight}
                initiativeHeaderHeight={initiativeHeaderHeight}
                isResizing={isResizing}
              />
            </Draggable>

            {isResizing && (
              <FullScreenDivStyled
                lineHeight={props.reactWindowHeight - initiativeRowHeight}
                initiativeHeaderHeight={initiativeHeaderHeight}
              />
            )}
          </>
        )}
      </MeasurementsContext.Consumer>
    )
  }
)

SidebarResizeLine.displayName = 'SidebarResizeLine'

const mapStateToProps = (state) => ({
  sidebarSize: sidebarSizeSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  setSidebarWidth: (size) => dispatch(setSidebarSize(size)),
})

export const SidebarResizeLineComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(SidebarResizeLine)
