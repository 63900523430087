import {css} from 'emotion'
import * as React from 'react'
import {primary, Text} from '@phoenix/all'
import {formatNumber} from '../../shared/utilities'
import {PhoenixNumberEditorComponent} from './PhoenixNumberEditorComponent'

interface ICounterComponent {
  id: string
  testID: string
  roleCount: number | null
  tabIndex?: number
  isFocused?: boolean
  isViewMode?: boolean
  onKeyDown?: (event) => void
  onFocus?: (event?) => void
  onBlur: (count: number | null) => void
  changePeopleCount: (count: number | null) => void
  inputHeight?: number
  className?: string
}

const containerClass = css`
  display: flex;
  align-items: center;
  .count {
    color: ${primary.gray(500)};

    label {
      display: none;
    }
  }
`

const inputClassShared = css`
  margin: 0;

  &,
  input {
    padding-top: 0;
    background-color: transparent;
  }

  &.focused {
    border: 1px solid ${primary.blue(400)} !important;
  }
`

const viewPaddingLeftClass = css`
  padding-left: 9px;
`
const countClass = css`
  width: 100%;
`

const getInputClass = (height: number) => {
  return css`
      ${inputClassShared}
      height: ${height + 2}px;
      input {
        height: ${height}px;
        border-radius: 2px;
        width: 100%;
      }
    `
}

export const CounterComponent: React.FunctionComponent<ICounterComponent> = (props) => {
  const roleCountRef: React.RefObject<HTMLInputElement> = React.useRef(null)

  const handleKeyDown = (event) => {
    if (props.onKeyDown) {
      props.onKeyDown(event)
    }
    if (event.key === 'Enter') {
      roleCountRef.current!.blur()
    }
  }

  const getValidCount = (value: number | null) => {
    return value !== null ? parseFloat(value.toString()) : null
  }

  const onBlur = (count) => {
    const validCount = count !== '' ? getValidCount(parseFloat(count))! : 0
    props.onBlur(validCount)
  }

  const onChange = (count) => {
    if (props.changePeopleCount) {
      props.changePeopleCount(count)
    }
  }

  React.useEffect(() => {
    if (props.isFocused) {
      setTimeout(() => {
        roleCountRef.current!.focus()
        roleCountRef.current!.select()
      })
    }
  }, [props.isFocused])

  return (
    <div className={`${containerClass}`}>
      <div className={`count ${countClass}`}>
        {props.isViewMode ? (
          <Text>
            <span className={viewPaddingLeftClass}>
              {formatNumber(props.roleCount, 2, true, 5)}
            </span>
          </Text>
        ) : (
          <PhoenixNumberEditorComponent
            tabIndex={props.tabIndex ? props.tabIndex : 0}
            ref={roleCountRef}
            label="number-input"
            testID={props.testID}
            name={`number-input-${props.id}`}
            className={`number-input ${getInputClass(props.inputHeight || 22)} ${props.className}`}
            value={props.roleCount}
            minValue={0}
            precision={3}
            autoComplete={'off'}
            hideMaxLengthInfo
            onKeyDown={handleKeyDown}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={(event) => props.onFocus && props.onFocus(event)}
          />
        )}
      </div>
    </div>
  )
}
