import LeftCaratSmallIcon from 'phoenix-icons/dist/LeftCaratSmallIcon.js'
import RightCaratSmallIcon from 'phoenix-icons/dist/RightCaratSmallIcon.js'
import {primary, Text} from '@phoenix/all'
import {css, cx} from 'emotion'
import moment from 'moment'
import * as React from 'react'
import {connect} from 'react-redux'
import {viewAccessSelector} from '../../../../../data/settings'
import {CarouselContext} from '../../../../../shared/context/CarouselContext'
import {IDistribution} from '../../../../../shared/models/initiative/IDistribution'
import {formatDateBasedOnLocale, optionsForMonth} from '../../../../../shared/utilities/dateHelpers'
import {resetButtonDefaultStyles} from '../../../../shared'
import {CarouselItemComponent} from '../carousel/CarouselItemComponent'
import {CarouselComponent} from '../carousel/CarouselComponent'

interface IRoleMonthsCarouselProps {
  distribution: IDistribution
  label: string | React.ReactElement
  className?: string
  testID: string
  isViewMode: boolean
}

const carouselClass = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 15px 10px 15px;
  margin: 0 -21px 0 -17px;
  position: sticky;
  top: 0;
  background-color: ${primary.gray(0)};
  z-index: 2;
`

const rightPaddingClass = css`
  padding-right: 27px;
`

const monthsClass = css`
  padding-right: 14px;
  position: relative;

  span {
    display: inline-block;
    margin-left: 10px;

    &:first-child {
      margin: 0;
    }
  }
`

const monthsViewModeClass = css`
  padding-right: 8px;
`

const carouselArrowBtnClass = css`
  ${resetButtonDefaultStyles};
  background-color: ${primary.white()};
  border-radius: 3px;
  position: absolute;
  top: 2px;
`

const leftClass = css`
  left: -23px;
`

const rightClass = css`
  right: 0;
`

const carouselArrowBtnViewModeClass = css`
  right: -5px !important;
`

const borderClass = css`
  border-bottom: 1px solid ${primary.gray(200)};
`

const STICKY_POSITION = 243

export const RoleMonthsCarousel: React.FC<IRoleMonthsCarouselProps> = React.memo(
  ({distribution, label, className, testID, isViewMode}) => {
    const carouselContext = React.useContext(CarouselContext)
    const stickyElementRef: React.RefObject<HTMLDivElement> = React.createRef()
    const [shouldAddBorder, setShouldAddBorder] = React.useState(false)

    React.useEffect(() => {
      const sidebarBody = document.querySelector('.edit-sidebar-body')

      const onScrollHandler = ({target}) => {
        if (
          stickyElementRef.current &&
          stickyElementRef.current.classList.contains('role-months-carousel')
        ) {
          if (target.scrollTop >= STICKY_POSITION) {
            setShouldAddBorder(true)
          } else if (target.scrollTop < STICKY_POSITION && shouldAddBorder) {
            setShouldAddBorder(false)
          }
        }
      }

      if (sidebarBody) {
        sidebarBody.addEventListener('scroll', onScrollHandler)
      }

      return () => {
        if (sidebarBody) {
          sidebarBody!.removeEventListener('scroll', onScrollHandler)
        }
      }
    }, [stickyElementRef, shouldAddBorder])

    return (
      <div
        className={cx(
          carouselClass,
          !isViewMode ? rightPaddingClass : '',
          className || '',
          shouldAddBorder ? borderClass : ''
        )}
        data-testid={testID}
        ref={stickyElementRef}
      >
        <Text.Small>{label}</Text.Small>

        <div className={`${monthsClass} ${isViewMode ? monthsViewModeClass : ''}`}>
          {carouselContext.isLeftArrowVisible && (
            <button
              data-testid="carousel-left-button"
              className={cx(carouselArrowBtnClass, leftClass)}
              onClick={carouselContext.onLeftArrowClickHandler}
            >
              <LeftCaratSmallIcon />
            </button>
          )}

          <CarouselComponent testID="role-distribution-month-carousel">
            {Object.keys(distribution).map((month) => {
              return (
                <CarouselItemComponent key={month} testID={`role-distribution-month-${month}`}>
                  <Text.Small>{formatDateBasedOnLocale(moment(month), optionsForMonth)}</Text.Small>
                </CarouselItemComponent>
              )
            })}
          </CarouselComponent>

          {carouselContext.isRightArrowVisible && (
            <button
              data-testid="carousel-right-button"
              className={`${cx(carouselArrowBtnClass, rightClass)} ${
                isViewMode ? carouselArrowBtnViewModeClass : ''
              }`}
              onClick={carouselContext.onRightArrowClickHandler}
            >
              <RightCaratSmallIcon />
            </button>
          )}
        </div>
      </div>
    )
  }
)

RoleMonthsCarousel.displayName = 'RoleMonthsCarousel'

const mapStateToProps = (state) => ({
  isViewMode: viewAccessSelector(state),
})

export const RoleMonthsCarouselComponent = connect(mapStateToProps)(RoleMonthsCarousel)
