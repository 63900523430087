export enum AvatarSize {
  TINY = 'TINY',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
  FULL = 'FULL',
  ORIGINAL = 'ORIGINAL',
}

export function getAvatarUrl(userId, avatarDate, size = AvatarSize.TINY) {
  return `/internal/user/avatar?ID=${userId}&time=${avatarDate}&size=${size}`
}
