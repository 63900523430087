import {css} from 'react-emotion'
import * as React from 'react'

import ErrorFilledSmallIcon from 'phoenix-icons/dist/ErrorFilledSmallIcon.js'
import {primary, Text, Tooltip} from '@phoenix/all'
import {Translate} from '../../../shared/Translate'

interface ISectionTitleComponent {
  icon: JSX.Element
  titleMessageKey: string
  value: string | null | JSX.Element
  hasError?: boolean
  testId: string
  errorMessage?: string
  tooltipContainerClass?: string
}

const textClass = css`
  font-weight: 600;
  color: ${primary.gray(700)};
`

const containerClass = css`
  display: flex;
  align-items: center;

  & > span {
    margin-left: 8px;
  }
`

const errorIconAbsoluteClass = css`
  position: absolute;
  right: 22px;
  top: 17px;
`

const titleExtraValueClass = css`
  margin-left: 6px;
`

export const SectionTitleComponent: React.FunctionComponent<ISectionTitleComponent> = (
  props: ISectionTitleComponent
) => {
  const {icon, titleMessageKey, value, hasError, testId, errorMessage} = props

  return (
    <div className={containerClass}>
      {icon}
      <Text.Small className={textClass}>
        <Translate messageKey={titleMessageKey} />
        {value !== null && <span className={titleExtraValueClass}>{value}</span>}
      </Text.Small>
      {hasError && (
        <div className={errorIconAbsoluteClass}>
          {!errorMessage && (
            <ErrorFilledSmallIcon height={14} width={14} data-testid={`${testId}-error`} />
          )}

          {errorMessage && (
            <Tooltip
              content={
                <div className={props.tooltipContainerClass || ''}>
                  <Translate messageKey={errorMessage!} />
                </div>
              }
              delay={300}
              position={'top'}
            >
              <ErrorFilledSmallIcon height={14} width={14} data-testid={`${testId}-error`} />
            </Tooltip>
          )}
        </div>
      )}
    </div>
  )
}
