import {createSelector} from 'reselect'
import {IScenarioPeople} from '../../../shared/models'
import {currentScenarioPeopleSelector} from './currentScenarioPeopleSelector'

export const currentScenarioRolesSelectorSelector = createSelector(
  [currentScenarioPeopleSelector],
  (currentScenarioPeople: IScenarioPeople) => {
    return currentScenarioPeople.roles
  }
)
