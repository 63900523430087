import React from 'react'
import {connect} from 'react-redux'
import {hasEditAccessToPlanSelector} from '../../../../../data/settings/selectors/hasEditAccessToPlanSelector'
import {
  changeResolvableRoles,
  changeVisibleConflict,
  resolvableRolesSelector,
  visibleConflictSelector,
} from '../../../../../data/viewOptions'
import {isNewPlan} from '../../../../../shared/helpers'
import {
  IDestructuredRoleConflict,
  IInitiativeRole,
  IPreCalculatedData,
} from '../../../../../shared/models/initiative'
import {ResolveCheckboxComponent} from '../ResolveCheckboxComponent'
import {SidebarConflictDetailsComponent} from '../SidebarConflictDetailsComponent'
import {preCalculatedDataSelector} from '../../../../../data/initiative'
import {css} from 'emotion'

const tooltipContainerClass = css`
  width: 324px;
`

export interface IPeopleListItemConflictComponentProps {
  role: IInitiativeRole
  periodConflictDetails: IDestructuredRoleConflict
  resolvableRoles: string[]
  isEditModeForPlan: boolean
  resolvableRolesChangeFunction: (resolvableRoles: string[]) => void
  preCalculatedData: IPreCalculatedData
  visibleConflictRoleId: string | null
  changeVisibleConflict: (roleId: null | string) => void
}

export const PeopleListItemConflict: React.FunctionComponent<IPeopleListItemConflictComponentProps> = React.memo(
  ({
    role,
    resolvableRoles,
    resolvableRolesChangeFunction,
    isEditModeForPlan,
    periodConflictDetails,
    preCalculatedData,
    visibleConflictRoleId,
    changeVisibleConflict,
  }) => {
    const roleId = role.id
    const isChecked = resolvableRoles.indexOf(roleId) !== -1

    const onChangeHandler = React.useCallback(() => {
      const roles = isChecked
        ? resolvableRoles.filter((id) => id !== roleId)
        : [...resolvableRoles, roleId]

      resolvableRolesChangeFunction(roles)
    }, [roleId, resolvableRoles])

    const expandCollapseHandler = () => {
      const roleId = role.id === visibleConflictRoleId ? null : role.id
      changeVisibleConflict(roleId)
    }

    return (
      <>
        <SidebarConflictDetailsComponent
          roleId={roleId}
          testID="role-conflict-distribution"
          distribution={role.distribution}
          periodConflictDetails={periodConflictDetails}
          preCalculatedData={preCalculatedData.roles.get(role.id)}
          isVisibleConflict={role.id === visibleConflictRoleId}
          expandCollapseHandler={expandCollapseHandler}
        />

        {isEditModeForPlan && (
          <ResolveCheckboxComponent
            tooltipContent="sp.scenario.resolve.role.conflict.description"
            testID="people-checkbox"
            name={`resolve-${roleId}`}
            label="sp.scenario.add.job.roles.to.available.resources"
            isChecked={isChecked}
            onChange={onChangeHandler}
            tooltipContainerClass={tooltipContainerClass}
          />
        )}
      </>
    )
  }
)

PeopleListItemConflict.displayName = 'PeopleListItemConflict'

const mapStateToProps = (state) => {
  const visibleConflict: any = visibleConflictSelector(state)

  return {
    resolvableRoles: resolvableRolesSelector(state),
    isEditModeForPlan: hasEditAccessToPlanSelector(state) || isNewPlan(state.plan.currentPlan),
    preCalculatedData: preCalculatedDataSelector(state),
    visibleConflictRoleId:
      visibleConflict && visibleConflict.roleId ? visibleConflict.roleId : null,
  }
}

const mapDispatchToProps = (dispatch) => ({
  resolvableRolesChangeFunction: (resolvableRoles) =>
    dispatch(changeResolvableRoles(resolvableRoles)),
  changeVisibleConflict: (conflict) => dispatch(changeVisibleConflict(conflict)),
})

export const PeopleListItemConflictComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(PeopleListItemConflict)
