import {primary} from '@phoenix/all'
import {getStorageUtil} from '@workfront/storage'
import {IPlanShareData, ShareDialogComponent} from '@workfront/sp-sharing-dialog'
import {css} from 'emotion'
import * as React from 'react'
import {connect} from 'react-redux'
import {
  activeScenarioSelector,
  currentPlanSelector,
  deletePlan,
  updatePlanAccessorObjects,
} from '../../../data/plan'
import {changeCurrentScenario, lastScenarioSelector} from '../../../data/scenario'
import {hasPublishedScenario} from '../../../shared/helpers'
import {IPlan, IScenario} from '../../../shared/models'
import {resetButtonDefaultStyles} from '../../shared'
import {HeaderComponent} from '../../shared/HeaderComponent'
import {PlanActionsComponent} from '../PlanActionsComponent'
import {inlineCenterItemsClass} from '../Scenario/StyleHelper'

import {ScenarioCardListComponent} from './ScenarioCardListComponent'
import {peopleCostOnConflictCalculationSelector} from '../../../data/plan/selectors/peopleCostOnConflictCalculationSelector'
import {Translate} from '../../shared/Translate'

interface ICompareScenario {
  navigateToListFunction: () => void
  onSuccessfulSaveFunction: (planId: string, currentScenarioId: string | null) => void
  updatePlanAccessorObjects: (currentObjects) => void
  changeCurrentScenarioFunction: (
    scenario: IScenario,
    usePeopleCostOnConflictCalculation: boolean
  ) => void
  deletePlanFunction: (plan: IPlan) => Promise<void>
  lastScenario: IScenario
  activeScenario: IScenario
  plan: IPlan
  usePeopleCostOnConflictCalculation: boolean
}

const headerBodyClass = css`
  ${inlineCenterItemsClass};
  justify-content: space-between;
`

const inlineEditContainerClass = css`
  flex-grow: 1;
`

const headerTopClass = css`
  ${inlineCenterItemsClass};
  justify-content: space-between;
  padding-top: 18px;
`
const actionsItemClass = css`
  color: ${primary.gray(400)};
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 16px;
  margin-top: 7px;
  text-transform: uppercase;
`

const backToListClass = css`
  ${resetButtonDefaultStyles};
  color: ${primary.gray(500)};
  font-size: 12px;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
`

export const CompareScenario: React.FunctionComponent<ICompareScenario> = (props) => {
  const {
    plan,
    lastScenario,
    activeScenario,
    updatePlanAccessorObjects,
    changeCurrentScenarioFunction,
    navigateToListFunction,
    deletePlanFunction,
  } = props

  const [isShowingSharingDialog, setIsShowingSharingDialog] = React.useState<boolean>(false)

  const planShareData = React.useMemo<IPlanShareData[]>(() => {
    if (!plan || !plan.owner) {
      return []
    }

    return [
      {
        id: plan && plan.id,
        name: plan && plan.name,
        ownerId: plan && plan.owner && plan.owner.id,
      },
    ]
  }, [plan])

  const deletePlanAndNavigate = () => {
    deletePlanFunction(plan).finally(() => {
      setTimeout(() => {
        navigateToListFunction()
        const storageUtil = getStorageUtil()
        storageUtil.remove(plan.id!)
      }, 2000)
    })
  }

  const navigateToLastScenario = () => {
    const scenarioToNavigate =
      plan.scenarios.find((scenario) => scenario.id === lastScenario.id) || plan.scenarios[0]
    changeCurrentScenarioFunction(scenarioToNavigate, props.usePeopleCostOnConflictCalculation)
  }

  const showSharingDialog = React.useCallback(() => {
    setIsShowingSharingDialog(true)
  }, [])

  const cancelSharingDialog = React.useCallback(() => {
    setIsShowingSharingDialog(false)
  }, [])

  const confirmSharingDialog = React.useCallback((currentObjects) => {
    setIsShowingSharingDialog(false)
    updatePlanAccessorObjects(currentObjects)
  }, [])

  const scenarioCount = plan.scenarios.length
  const scenarios = [
    activeScenario,
    ...plan.scenarios.filter((scenario) => scenario.id !== activeScenario.id),
  ]

  return (
    <React.Fragment>
      <HeaderComponent>
        <div className={headerTopClass} data-testid="compare-header-top">
          <button
            onClick={navigateToLastScenario}
            className={backToListClass}
            data-testid="back-to-last-scenario"
          >
            <Translate messageKey={'sp.back.to.scenario'} />
          </button>
        </div>

        <div className={headerBodyClass} data-testid="compare-header-body">
          <div className={inlineEditContainerClass}>
            <PlanActionsComponent
              showSharingDialog={showSharingDialog}
              navigateToListFunction={navigateToListFunction}
            />

            <div className={actionsItemClass} data-testid="scenario-count">
              <Translate
                messageKey={'sp.scenario.header.count'}
                args={{
                  count: scenarioCount,
                }}
              />
            </div>
          </div>
        </div>
        {isShowingSharingDialog && (
          <ShareDialogComponent
            plans={planShareData}
            onCancel={cancelSharingDialog}
            onShare={confirmSharingDialog}
          />
        )}
      </HeaderComponent>
      <ScenarioCardListComponent
        hasPublishedScenario={hasPublishedScenario(plan)}
        scenarios={scenarios}
        onSuccessfulSaveFunction={props.onSuccessfulSaveFunction}
        deletePlanFunction={deletePlanAndNavigate}
      />
    </React.Fragment>
  )
}
const mapStateToProps = (state) => ({
  plan: currentPlanSelector(state),
  lastScenario: lastScenarioSelector(state),
  activeScenario: activeScenarioSelector(state),
  usePeopleCostOnConflictCalculation: peopleCostOnConflictCalculationSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  updatePlanAccessorObjects: (currentObjects) =>
    dispatch(updatePlanAccessorObjects(currentObjects)),
  changeCurrentScenarioFunction: (scenario, usePeopleCostOnConflictCalculation) =>
    dispatch(changeCurrentScenario(scenario, null, usePeopleCostOnConflictCalculation)),
  deletePlanFunction: (plan) => dispatch(deletePlan(plan)),
})

export const CompareScenarioComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(CompareScenario)
