import * as React from 'react'
import {List, getInstance} from '@wf-mfe/lists'
import * as toolbar from '@wf-mfe/lists'
import toolbarDefinitionPlugin, {
  SELECTION_CHANGED_EVENT,
} from './plugins/toolbar/ToolbarDefinitionsPlugin'
import {columns, LIST_ID, metadata} from './table-data'
import DataFetcherPlugin from './plugins/dataFetcher'
import {primary} from '@phoenix/all'
import {css} from 'react-emotion'
import RendererPlugin from '../export/plugins/renderers'
import ExportFVGPlugin from './plugins/toolbar/filters/ExportFVGPlugin'
import {InitiativeExportListComponentProps} from './InitiativeExportListWrapper'
import {LocalizationProvider} from '@wf-mfe/localize-react'
import ExportDropDownFilterPlugin from './plugins/toolbar/filters/ExportDropdownFilterPlugin'
import {espClient, redrockClientFactory, redrockContext} from '../../shared/L10n'

const ToolbarComponent = toolbar.getToolbarComponent(LIST_ID)

const wrapperDivStyle = css`
  // subtract toolbar height
  div[data-test-name='HeaderCell']:last-child .table-header-cell-styled {
    padding-right: 0px !important;
  }
  height: calc(100% - 41px);
  // Match to the Phoenix <Text/> styles
  .body-inner-cell {
    font-size: 14px;
    color: ${primary.gray()};
  }
  // with line-height: 26px; the rows height become 36px
  .list-body-cell {
    line-height: 26px;
  }
  // align the cell items in the middle of the rows
  .table-body-cell-styled {
    padding: 5px 8px;
    & > div {
      display: flex;
      align-items: center;
    }
  }
  // prevent wrapping of header cell texts
  .table-header-cell-styled > div {
    white-space: nowrap;
  }
  // enforce checkbox sizes to 14px and position them in the center of its cell
  div[data-test-id='header-checkbox'],
  div[data-test-id='row-checkbox'] {
    width: 14px;
    height: 14px;
    margin: 0 auto;
  }
  .table_container {
    height: 100%;
    justify-content: space-between;
  }
`

const InitiativeExportListComponent: React.FC<InitiativeExportListComponentProps> = (props) => {
  const messages = {
    formName: espClient.getTextSync('form.name', 'Name'),
    formDatesStart: espClient.getTextSync('form.dates.start', 'Start date'),
    formDatesEnd: espClient.getTextSync('form.dates.end', 'End date'),
    formDatesDuration: espClient.getTextSync('form.dates.duration', 'Duration'),
    initiativePublishListRoles: espClient.getTextSync(
      'sp.initiative.publish.list.roles.fte',
      'Initiative Job Roles in FTEs and Hours'
    ),
    initiativePublishListLastPublished: espClient.getTextSync(
      'initiative.publish.list.last_published',
      'Last published'
    ),
    espPublishMonth: espClient.getTextSync('esp.publish.month', '1 month'),
    espPublishMonths: (params) =>
      espClient.getTextSync('esp.publish.months', `${params[0]} months`, params),
    initiativePublishProjectIcon: espClient.getTextSync(
      'initiative.publish.project.icon',
      'Published: Open associated project'
    ),
    initiativeImportProjectIcon: espClient.getTextSync(
      'initiative.import.project.icon',
      'Open associated project'
    ),
  }
  const plugins = React.useRef([
    {
      importPromise: Promise.resolve(toolbar),
      initOptions: {
        toolbarId: 'initiatives',
      },
    },
    {
      importPromise: Promise.resolve(toolbarDefinitionPlugin),
    },
    {
      importPromise: Promise.resolve(DataFetcherPlugin),
      initOptions: {
        scenarioId: props.scenarioId,
        planId: props.planId,
      },
    },
    {
      importPromise: Promise.resolve(RendererPlugin),
      initOptions: {
        messages,
      },
    },
    {
      importPromise: props.newFilterSpPlansListSplit
        ? Promise.resolve(ExportDropDownFilterPlugin)
        : Promise.resolve(ExportFVGPlugin),
      initOptions: {
        planId: props.planId,
      },
    },
  ])
  const columnNames = Object.values(messages)
  const data = React.useRef({
    metadata: metadata,
    columns: columns.map((column, index) => {
      if (index == 0) {
        return column
      }

      return {
        ...column,
        display: columnNames[index - 1],
      }
    }),
    collapsedGroups: [],
    groups: {},
    collapsedRows: [],
    messageKeys: ['sp.initiatives', 'sp.initiative'],
    rows: {},
  })
  const options = React.useRef({
    isInModal: false,
    isReactListToolbarsEnabled: true,
    listDataStore: getInstance(LIST_ID),
    bottomOffset: 0,
    useAvailableHeight: true,
  })

  const initiativeExportListRef = React.useRef<HTMLDivElement>(null)
  const selectionHandler = React.useCallback(
    (event) => {
      if (props.onSelectionChange) {
        props.onSelectionChange(event.detail.selectedIDs)
      }
    },
    [props]
  )
  React.useLayoutEffect(() => {
    const wrapperElement = initiativeExportListRef.current!
    wrapperElement.addEventListener(SELECTION_CHANGED_EVENT, selectionHandler, false)
    return () => {
      wrapperElement.removeEventListener(SELECTION_CHANGED_EVENT, selectionHandler, false)
    }
  }, [selectionHandler])

  return (
    <div id={LIST_ID} ref={initiativeExportListRef} className={wrapperDivStyle}>
      <LocalizationProvider clientFactory={redrockClientFactory} context={redrockContext}>
        <>
          <ToolbarComponent />
          <List data={data.current} options={options.current} plugins={plugins.current} />
        </>
      </LocalizationProvider>
    </div>
  )
}

export const InitiativeExportList = React.memo<InitiativeExportListComponentProps>(
  InitiativeExportListComponent
)
InitiativeExportListComponent.displayName = 'InitiativeExportList'
