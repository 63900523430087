type PendoTrack = typeof pendo.track

export const PENDO_TRACK_PREFIX = 'SP'

export const track: PendoTrack = (trackType, metadata) => {
  if ('pendo' in window && pendo && typeof pendo.track === 'function') {
    pendo.track?.(trackType, metadata)
  }
}

export const trackSP: PendoTrack = (trackType, metadata) => {
  track(PENDO_TRACK_PREFIX + ' - ' + trackType, metadata)
}
