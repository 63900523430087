const KEY_CODE_F = 70

const isIE = () => {
  if (!navigator) {
    return
  }
  const userAgent = navigator.userAgent
  return userAgent.indexOf('MSIE ') > -1 || userAgent.indexOf('Trident/') > -1
}

export const isQuickFilterShortcutPressed = (event: KeyboardEvent): boolean => {
  const isShortcutPressed = event.altKey && event.keyCode === KEY_CODE_F
  return isIE() ? isShortcutPressed && event.shiftKey : isShortcutPressed
}

export const getQuickFilterShortcut = (): string => {
  let shortcut = /mac/.test(window.navigator.platform.toLowerCase()) ? '(Option + F)' : '(Alt + F)'

  if (isIE()) {
    shortcut = shortcut.slice(0, -5) + ' + Shift' + shortcut.slice(-5)
  }
  return shortcut
}
