import React from 'react'
import SplitContext, {ISplitContext} from './SplitContext'
import {setFeatures} from '../../data/features'
import {Store} from 'redux'
import {setGlobalSplitInfo} from '../utilities/globalSplit'

interface ISplitProviderProps {
  value: ISplitContext
  store?: Store<any>
}

export const SplitProvider: React.FC<ISplitProviderProps> = React.memo(
  ({store, value, children}) => {
    React.useEffect(() => {
      store?.dispatch(setFeatures(value))
      setGlobalSplitInfo(value)
    }, [store, value])

    return <SplitContext.Provider value={value}>{children}</SplitContext.Provider>
  }
)
SplitProvider.displayName = 'SplitProvider Component'
