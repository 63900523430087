export const quickFilterKeys = [
  'status',
  'ownerID',
  'statusEquatesWith',
  'name',
  'portfolioID',
  'groupID',
  'percentComplete',
  'programID',
  'queueDef:isPublic',
]
