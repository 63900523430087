import {css} from 'emotion'
import {isEqual} from 'lodash'
import * as React from 'react'
import {connect} from 'react-redux'
import ErrorFilledSmallIcon from 'phoenix-icons/dist/ErrorFilledSmallIcon.js'

import {Button, secondary, Input} from '@phoenix/all'

import {currentScenarioBudgetSelector} from '../../../../../data/scenario'
import {financialViewAccessSelector} from '../../../../../data/settings'
import {calculateRemaining} from '../../../../../shared/helpers'
import {IScenarioBudgets} from '../../../../../shared/models/scenario'
import {formatNumber, isListItemsEqual} from '../../../../../shared/utilities'
import {isValidIntegerNumber} from '../../../../../shared/utilities'
import {formatDateBasedOnLocale, optionsForMonth} from '../../../../../shared/utilities/dateHelpers'
import {MoneyFieldViewComponent} from '../../../../shared'
import {LABEL_DATE_FORMAT, STATE_DATE_FORMAT} from '../../../../../shared/constants/dateFormats'
import {Translate} from '../../../../shared/Translate'

interface IHeaderBudgetAdvancedSectionProps {
  currentDistributionKey: string
  budgets: IScenarioBudgets
  isViewMode: boolean
  handleBudgetMonthBlur: (event, index) => void
  resetBudget: () => void
}

interface IHeaderBudgetAdvancedSectionState {
  propsBudget: IScenarioBudgets | null
  currentDistributionKey: string
}

const distributionTopClass = css`
  display: flex;
  margin-bottom: 14px;
  justify-content: space-between;
`

const resetBtnClass = css`
  font-size: 12px;
  height: auto;
  line-height: normal;
  padding: 0;
`

const remainingClass = css`
  font-size: 12px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
  }
`

const distributionMonthsWrapper = css`
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
  padding-bottom: 15px;
`

const budgetMonthClass = css`
  width: 23.5%;
  margin-top: 12px;

  &:nth-child(-n + 4) {
    margin: 0;
  }

  label {
    margin: 0 0 4px 0;

    & + div {
      margin: 0;
    }
  }
`
export class HeaderBudgetAdvancedSection extends React.Component<
  IHeaderBudgetAdvancedSectionProps,
  IHeaderBudgetAdvancedSectionState
> {
  static getDerivedStateFromProps(
    nextProps: IHeaderBudgetAdvancedSectionProps,
    prevState: IHeaderBudgetAdvancedSectionState
  ) {
    const prevDistribution = prevState.propsBudget
      ? prevState.propsBudget.distribution.get(prevState.currentDistributionKey)!
      : null
    const nextDistribution = nextProps.budgets.distribution.get(nextProps.currentDistributionKey)!

    if (!isEqual(prevDistribution, nextDistribution)) {
      const newState = {
        propsBudget: nextProps.budgets,
        currentDistributionKey: nextProps.currentDistributionKey,
      }
      for (const key of nextDistribution.budgetMonths) {
        newState[key.yearMonthDate.format(STATE_DATE_FORMAT)] = key.amount
      }

      return newState
    }

    return null
  }

  constructor(props: IHeaderBudgetAdvancedSectionProps) {
    super(props)

    this.state = {
      propsBudget: null,
      currentDistributionKey: props.currentDistributionKey,
    }
  }

  componentDidMount(): void {
    const {
      budgets: {distribution},
      currentDistributionKey,
    } = this.props
    const currentDistribution = distribution.get(currentDistributionKey)!

    for (const key of currentDistribution.budgetMonths) {
      this.setState({
        [key.yearMonthDate.format(STATE_DATE_FORMAT)]: key.amount,
      } as any)
    }

    this.setState({
      currentDistributionKey: this.props.currentDistributionKey,
    })
  }

  render() {
    const {
      budgets: {distribution},
      currentDistributionKey,
      resetBudget,
      isViewMode,
    } = this.props
    const currentDistribution = distribution.get(currentDistributionKey)!
    const remaining = calculateRemaining(currentDistribution)
    const isResetDisabled =
      remaining < 12 && remaining >= 0 && isListItemsEqual(currentDistribution.budgetMonths)

    return (
      <div data-testid="distribution">
        <div className={distributionTopClass}>
          {isViewMode ? (
            <div />
          ) : (
            <Button
              text
              testID="reset"
              className={resetBtnClass}
              onClick={resetBudget}
              disabled={isResetDisabled}
            >
              <Translate messageKey={'form.button.reset'} />
            </Button>
          )}

          {this.renderRemaining(remaining)}
        </div>
        <div className={distributionMonthsWrapper}>
          {currentDistribution.budgetMonths.map(({yearMonthDate}, index) => (
            <div className={budgetMonthClass} key={index}>
              {isViewMode ? (
                <MoneyFieldViewComponent
                  cost={this.state[yearMonthDate.format(STATE_DATE_FORMAT)]}
                  label={yearMonthDate.format(LABEL_DATE_FORMAT)}
                />
              ) : (
                <Input
                  testID={`budget-month-${index}`}
                  label={formatDateBasedOnLocale(yearMonthDate, optionsForMonth)}
                  name={`budget-month-${index}`}
                  maxLength="14"
                  autoComplete="off"
                  hideMaxLengthInfo={true}
                  value={this.getInputValue(yearMonthDate)}
                  onKeyDown={this.handleKeyDown}
                  onChange={({target}) => this.handleBudgetMonthChange(target.value, yearMonthDate)}
                  onBlur={({target}) => {
                    this.handleBudgetMonthBlur(target.value, yearMonthDate, index)
                  }}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    )
  }

  private getInputValue = (yearMonthDate) => {
    return this.state[yearMonthDate.format(STATE_DATE_FORMAT)] !== null
      ? this.state[yearMonthDate.format(STATE_DATE_FORMAT)]
      : ''
  }

  private handleKeyDown = ({key, target}) => {
    if (key === 'Enter') {
      target.blur()
    }
  }

  private updateBudgetMonthChange = (amount, yearMonthDate) => {
    if (amount === '' || isValidIntegerNumber(amount)) {
      this.setState({
        [yearMonthDate.format(STATE_DATE_FORMAT)]: amount ? parseInt(amount, 10) : amount,
      } as Pick<IHeaderBudgetAdvancedSectionState, keyof IHeaderBudgetAdvancedSectionState>)
    }
  }

  private handleBudgetMonthBlur = (amount, yearMonthDate, index) => {
    this.props.handleBudgetMonthBlur(amount, index)
    const finalValue = amount === '' ? '0' : amount
    this.updateBudgetMonthChange(finalValue, yearMonthDate)
  }

  private handleBudgetMonthChange = (amount, yearMonthDate) => {
    this.updateBudgetMonthChange(amount, yearMonthDate)
  }

  private renderRemaining = (remaining) => {
    if (!remaining) {
      return null
    }
    // TODO: This should be separate component
    if (remaining < 0) {
      return (
        <div data-testid="exceeded" className={remainingClass}>
          <ErrorFilledSmallIcon color={secondary.yellow()} data-testid="warning-icon" />
          <Translate messageKey={'scenario.widget.budget.exceeded'} />{' '}
          {formatNumber(Math.abs(remaining), 1, false)}
        </div>
      )
    } else {
      return (
        <div data-testid="remaining" className={remainingClass}>
          <Translate messageKey={'scenario.widget.budget.remaining'} />{' '}
          {formatNumber(remaining, 1, false)}
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => ({
  budgets: currentScenarioBudgetSelector(state),
  isViewMode: financialViewAccessSelector(state),
})

export const HeaderBudgetAdvancedSectionComponent = connect(mapStateToProps)(
  HeaderBudgetAdvancedSection
)
