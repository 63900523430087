import moment, {Moment} from 'moment'
import {createSelector} from 'reselect'
import {currentPlanStartDateSelector} from './currentPlanStartDateSelector'
import {planEndDateSelector} from './planEndDateSelector'
import {timelineWidthSelector} from './timelineWidthSelector'

export const todayLinePositionSelector = createSelector(
  [currentPlanStartDateSelector, planEndDateSelector, timelineWidthSelector],
  (startDate: Moment, endDate: Moment, timelineWidth: number) => {
    const today = moment()
    if (today.isBefore(startDate) || today.isAfter(endDate)) {
      return null
    } else {
      const durationInDays = endDate.diff(startDate, 'days')
      const differenceByDay = moment.utc().diff(startDate, 'days')

      return (differenceByDay * timelineWidth) / durationInDays
    }
  }
)
