import {PluginBase, bindPluginClass} from '@workfront/list-plugin-utils'
import {IDataTableAPI} from '@wftypes/list'

import {InitiativeDuration, initiativeDurationMatcher} from './widgets/InitiativeDuration'
import {InitiativeName, initiativeNameMatcher} from './widgets/InitiativeName'
import {initiativeRolesMatcher, RolesWidget} from './widgets/roles/RolesWidget'

class RendererPlugin extends PluginBase<unknown, {messages}> {
  static PLUGIN_NAME = 'RendererPlugin'
  static PLUGIN_DEPS = []

  init(listApi: IDataTableAPI, {messages}) {
    this.addShutdownRoutine(
      listApi.renderers.registerViewRenderer(
        'component.initiativeduration',
        InitiativeDuration(messages)
      ),
      listApi.renderers.registerViewRenderer('component.initiativeroles', RolesWidget),

      listApi.renderers.registerViewRenderer('component.initiativename', InitiativeName()),
      listApi.quickFilter.registerQuickFilterMatchers({
        'component.initiativename': initiativeNameMatcher,
        'component.initiativeroles': initiativeRolesMatcher,
        'component.initiativeduration': initiativeDurationMatcher(messages),
      })
    )
  }
}

export default bindPluginClass(
  () => new RendererPlugin(),
  RendererPlugin.PLUGIN_NAME,
  RendererPlugin.PLUGIN_DEPS || []
)
