import {Moment} from 'moment'
import * as React from 'react'
import {connect} from 'react-redux'
import Draggable from 'react-draggable'
import RightCaratSmallIcon from 'phoenix-icons/dist/RightCaratSmallIcon.js'
import LeftCaratSmallIcon from 'phoenix-icons/dist/LeftCaratSmallIcon.js'
import {currentPlanSelector} from '../../../../data/plan'
import {timelineCellWidthSelector} from '../../../../data/plan'
import {
  currentScenarioPeopleSelector,
  updateInitiativeAndRecalculateConflicts,
  updateInitiativeDates,
} from '../../../../data/scenario'
import {
  draggingInitiativeIdSelector,
  resizeLinePosition,
  setDraggingInitiativeId,
} from '../../../../data/viewOptions'
import {updateLineResizePosition} from '../../../../data/viewOptions'
import {
  BAR_RADIUS,
  calculateArrowWidth,
  canScroll,
  conflictEdArrowHover,
  getResizingProperties,
  getResizeLinePosition,
  hasInitiativeConflict,
  ICON_WIDTH,
  isHScrollable,
  parentScrollTo,
  SCROLL_STEP,
  standardArrowClass,
  standardArrowHover,
} from '../../../../shared/helpers/TimelineBarHelper'
import {IInitiative, IInitiativeRole} from '../../../../shared/models/initiative'
import {IDistribution} from '../../../../shared/models/initiative/IDistribution'
import {peopleCostOnConflictCalculationSelector} from '../../../../data/plan/selectors/peopleCostOnConflictCalculationSelector'
import {IScenarioPeople} from '../../../../shared/models/scenario'
import {IResizingData} from './TimelineBarComponent'
import {sidebarSizeSelector} from '../../../../data/settings/selectors/sidebarSizeSelector'

interface ITimelineBarComponentProps {
  planStartDate: Moment
  planDuration: number
  cellWidth: number
  isRowHovered: boolean
  mode: number
  people: IScenarioPeople
  resizeLinePosition: number | null
  initiative: IInitiative
  updateInitiativeDatesFunction: (initiative: IInitiative) => void
  updateInitiativeAndRecalculateConflictsFunction: (
    initiative: IInitiative,
    usePeopleCostOnConflictCalculation: boolean
  ) => void
  updateLineResizePositionFunction: (position: number | null) => void
  usePeopleCostOnConflictCalculation: boolean
  setDraggingInitiativeIdFunction: (id: string | null) => void
  draggingInitiativeId: string | null

  timelineBarArrowYPosition: number
  timelineBarArrowHeight: number
  changeBarWidthAndPositionState: (state: IResizingData) => void

  isLeftArrowConflicted: boolean // TODO not  a good solution to get  it as prop
  isRightArrowConflicted: boolean // TODO not  a good solution to get  it as prop
  shouldScaleLeftArrow: boolean // TODO not  a good solution to get  it as prop
  shouldScaleRightArrow: boolean // TODO not  a good solution to get  it as prop
  sidebarSize: number
}

interface ITimelineBarComponentState {
  rightDragScrollPosition: number
  leftDragScrollPosition: number
}

class TimelineBarResize extends React.Component<
  ITimelineBarComponentProps,
  ITimelineBarComponentState
> {
  private scrollParentElement
  private scrollDebounce
  private leftValidArea
  private rightValidArea
  private arrowWidth = 22
  private readonly planEndDate
  private readonly leftRectRef
  private readonly rightRectRef
  private readonly rightDraggableRef
  private readonly leftDraggableRef

  constructor(props) {
    super(props)

    this.planEndDate = props.planStartDate
      .clone()
      .add(props.planDuration, 'years')
      .startOf('month')
      .subtract(1, 'day')
      .endOf('day')

    this.state = {
      rightDragScrollPosition: 0,
      leftDragScrollPosition: 0,
    }

    this.arrowWidth = calculateArrowWidth(props.mode)
    this.leftRectRef = React.createRef()
    this.rightRectRef = React.createRef()
    this.rightDraggableRef = React.createRef()
    this.leftDraggableRef = React.createRef()
  }

  render() {
    const {
      leftDraggablePosition,
      rightDraggablePosition,
      rightIconPosition,
      leftIconPosition,
      arrowWidth,
    } = getResizingProperties(this.props)
    this.arrowWidth = arrowWidth

    const leftArrowConflictedClass = this.getArrowsClassName(this.props.isLeftArrowConflicted)
    const rightArrowConflictedClass = this.getArrowsClassName(this.props.isRightArrowConflicted)
    return (
      <g className={`${standardArrowClass} arrow`}>
        <Draggable
          enableUserSelectHack={false}
          bounds={this.leftValidArea}
          ref={this.leftDraggableRef}
          axis="x"
          position={{x: this.state.leftDragScrollPosition, y: 0}}
          onStart={this.leftStart}
          onStop={this.leftStop}
          onDrag={this.leftDrag}
        >
          <g data-testid="duration-bar-left-arrow">
            <rect
              className={leftArrowConflictedClass}
              rx={BAR_RADIUS}
              ry={BAR_RADIUS}
              {...this.getArrowCoordinates(
                this.props.timelineBarArrowYPosition,
                this.props.timelineBarArrowHeight,
                leftDraggablePosition,
                true
              )}
              ref={this.leftRectRef}
            />
            <LeftCaratSmallIcon
              y={this.props.timelineBarArrowYPosition}
              x={this.props.isLeftArrowConflicted ? leftIconPosition - 1 : leftIconPosition}
              height={this.props.timelineBarArrowHeight}
              width={ICON_WIDTH}
            />
          </g>
        </Draggable>
        <Draggable
          enableUserSelectHack={false}
          bounds={this.rightValidArea}
          axis="x"
          ref={this.rightDraggableRef}
          position={{x: this.state.rightDragScrollPosition, y: 0}}
          onStart={this.rightStart}
          onStop={this.rightStop}
          onDrag={this.rightDrag}
        >
          <g data-testid="duration-bar-right-arrow">
            <rect
              className={rightArrowConflictedClass}
              rx={BAR_RADIUS}
              ry={BAR_RADIUS}
              {...this.getArrowCoordinates(
                this.props.timelineBarArrowYPosition,
                this.props.timelineBarArrowHeight,
                rightDraggablePosition
              )}
              ref={this.rightRectRef}
            />
            <RightCaratSmallIcon
              y={this.props.timelineBarArrowYPosition}
              x={this.props.isRightArrowConflicted ? rightIconPosition + 1 : rightIconPosition}
              height={this.props.timelineBarArrowHeight}
              width={ICON_WIDTH}
            />
          </g>
        </Draggable>
      </g>
    )
  }

  private getArrowsClassName = (isArrowConflicted) => {
    return hasInitiativeConflict(this.props.initiative.conflicts) &&
      isArrowConflicted &&
      this.props.isRowHovered &&
      !this.props.draggingInitiativeId
      ? conflictEdArrowHover
      : standardArrowHover
  }

  private getArrowCoordinates = (
    timelineBarArrowYPosition,
    timelineBarArrowHeight,
    draggablePosition,
    isLeft = false
  ) => {
    const {shouldScaleLeftArrow, shouldScaleRightArrow} = this.props
    const shouldScaleArrow = isLeft ? shouldScaleLeftArrow : shouldScaleRightArrow
    const scaleXUnit = isLeft ? 0 : -1

    return {
      y: shouldScaleArrow ? timelineBarArrowYPosition - 1 : timelineBarArrowYPosition,
      height: shouldScaleArrow ? timelineBarArrowHeight + 2 : timelineBarArrowHeight,
      width: shouldScaleArrow ? this.arrowWidth + 1 : this.arrowWidth,
      x: shouldScaleArrow ? draggablePosition + scaleXUnit : draggablePosition,
    }
  }

  private hasScroll = () => {
    return (
      this.rightRectRef.current.getBoundingClientRect().left >=
      this.leftRectRef.current.getBoundingClientRect().left + this.props.cellWidth
    )
  }

  private hideDragLine = () => {
    if (this.props.resizeLinePosition !== null) {
      this.props.updateLineResizePositionFunction(null)
    }
  }

  private beforeDragStart = () => {
    this.props.setDraggingInitiativeIdFunction(this.props.initiative.id)
    document.body.classList.add('draging')
    this.scrollParentElement = document.querySelector('.scrollContent')
    const {leftValidArea, rightValidArea} = this.calculateDraggableBounds()
    this.leftValidArea = leftValidArea
    this.rightValidArea = rightValidArea
  }

  private afterDragEnd = () => {
    document.body.classList.remove('draging')
    this.props.setDraggingInitiativeIdFunction(null)
  }

  // calculation
  private calculateDraggableBounds = () => {
    const leftValidArea = {top: 0, left: -999999, right: 9999999, bottom: 0}
    const rightValidArea = {top: 0, left: -999999, right: 9999999, bottom: 0}
    const rightArrowPosition =
      this.rightRectRef.current && this.rightRectRef.current.getBoundingClientRect().left
    const leftArrowPosition =
      this.leftRectRef.current && this.leftRectRef.current.getBoundingClientRect().left
    if (this.leftRectRef.current) {
      leftValidArea.right =
        rightArrowPosition - leftArrowPosition - this.props.cellWidth + this.arrowWidth
    }
    if (this.rightRectRef.current) {
      rightValidArea.left =
        leftArrowPosition + this.props.cellWidth - rightArrowPosition - this.arrowWidth
    }
    return {
      leftValidArea,
      rightValidArea,
    }
  }

  private calculateInitiativeDuration = (axisX: number, isLeftResized = false) => {
    const {cellWidth, planStartDate, initiative} = this.props
    const {startDate, endDate} = initiative.dates

    const shiftedMonths = Math.round(axisX / cellWidth)

    let newStartDate = startDate.clone()
    let newEndDate = endDate.clone()

    const direction = isLeftResized ? -1 : 1

    let newDuration = direction * shiftedMonths
    let changedMonths: string[]

    if (isLeftResized) {
      newStartDate = startDate.clone().subtract(newDuration, 'month')
      changedMonths = this.getChangedDates(
        startDate,
        newStartDate,
        Math.abs(newDuration),
        isLeftResized
      )

      if (newStartDate.isSameOrBefore(planStartDate)) {
        newStartDate = planStartDate.clone()
      }
      if (newStartDate.isAfter(this.planEndDate)) {
        newEndDate = this.planEndDate.clone()
        newStartDate = this.planEndDate.clone().subtract(1, 'month')
      }
    } else {
      newEndDate = endDate.clone().add(newDuration, 'month').endOf('month')
      changedMonths = this.getChangedDates(endDate, newEndDate, Math.abs(newDuration))

      if (newEndDate.isSameOrAfter(this.planEndDate)) {
        newEndDate = this.planEndDate.clone()
      }
      if (newEndDate.isBefore(planStartDate)) {
        newEndDate = planStartDate.clone().add(1, 'day')
        newStartDate = this.planEndDate.clone()
      }
    }

    newDuration = Math.round(newEndDate.diff(newStartDate, 'months', true))

    return {
      changedMonths,
      startDate: newStartDate,
      endDate: newEndDate,
      duration: newDuration,
    }
  }

  private getChangedDates = (
    date: Moment,
    newDate: Moment,
    duration,
    isLeftResized = false
  ): string[] => {
    const dateClone = date.clone()
    const changedDates: string[] = []

    if (newDate.isBefore(date)) {
      for (let i = 0; i < duration; i++) {
        if (isLeftResized) {
          changedDates.push(dateClone.subtract(1, 'month').format('YYYY-MM'))
        } else {
          changedDates.push(
            dateClone.clone().endOf('month').add(1, 'second').subtract(1, 'month').format('YYYY-MM')
          )
          dateClone.subtract(1, 'month')
        }
      }
    } else if (newDate.isAfter(date)) {
      for (let i = 0; i < duration; i++) {
        if (isLeftResized) {
          changedDates.push(dateClone.format('YYYY-MM'))
          dateClone.add(1, 'month')
        } else {
          changedDates.push(dateClone.add(1, 'month').format('YYYY-MM'))
        }
      }
    }

    return changedDates
  }

  private calculateRightScroll = (scrollLeft, align, scrollTo) => {
    this.hideDragLine()
    this.scrollDebounce = setInterval(() => {
      if (
        canScroll(this.scrollParentElement, scrollTo) &&
        this.rightDraggableRef.current &&
        this.hasScroll()
      ) {
        const {x} = this.rightDraggableRef.current.state
        const step = align * SCROLL_STEP
        this.setState({
          rightDragScrollPosition: x + step,
        })
        this.props.changeBarWidthAndPositionState({
          rightDragScrollPosition: x + step,
          rightDragDelta: 0,
          leftDragScrollPosition: 0,
          leftDragDelta: 0,
        })
        scrollLeft = scrollLeft + step
        parentScrollTo(this.scrollParentElement, scrollLeft)
      }
    }, 10)
  }

  private calculateLeftScroll = (scrollLeft, align, scrollTo) => {
    this.hideDragLine()
    this.scrollDebounce = setInterval(() => {
      if (
        this.scrollParentElement &&
        canScroll(this.scrollParentElement, scrollTo) &&
        this.leftDraggableRef.current &&
        this.hasScroll()
      ) {
        const {x} = this.leftDraggableRef.current.state
        const step = align * SCROLL_STEP

        this.setState({
          leftDragScrollPosition: x + step,
        })
        this.props.changeBarWidthAndPositionState({
          rightDragScrollPosition: 0,
          rightDragDelta: 0,
          leftDragScrollPosition: x + step,
          leftDragDelta: 0,
        })
        scrollLeft = scrollLeft + step
        parentScrollTo(this.scrollParentElement, scrollLeft)
      }
    }, 10)
  }

  private stopResizing = (isLeftResized: boolean, x: number) => {
    const {initiative} = this.props

    clearInterval(this.scrollDebounce)
    const {changedMonths, ...newDates} = this.calculateInitiativeDuration(x, isLeftResized)
    const newRoles = this.reformRoleDistribution(changedMonths, isLeftResized)

    const newInitiative = {
      ...this.props.initiative,
      people: {
        ...this.props.initiative.people,
        roles: newRoles,
      },
      dates: newDates,
      costs: initiative.costs && {
        ...initiative.costs,
        fixedCostDistribution: this.reformDistribution(
          changedMonths,
          initiative.costs.fixedCostDistribution!,
          isLeftResized
        ),
        peopleCostDistribution: this.reformDistribution(
          changedMonths,
          initiative.costs.peopleCostDistribution!,
          isLeftResized
        ),
        overallCostDistribution: this.reformDistribution(
          changedMonths,
          initiative.costs.overallCostDistribution!,
          isLeftResized
        ),
      },
    }

    const shouldRecalculateConflicts = isLeftResized
      ? newInitiative.dates.startDate.isAfter(this.props.initiative.dates.startDate)
      : newInitiative.dates.endDate.isBefore(this.props.initiative.dates.endDate)

    if (shouldRecalculateConflicts) {
      this.props.updateInitiativeAndRecalculateConflictsFunction(
        newInitiative,
        this.props.usePeopleCostOnConflictCalculation
      )
    } else {
      this.props.updateInitiativeDatesFunction(newInitiative)
    }

    if (isLeftResized) {
      this.setState({
        leftDragScrollPosition: 0,
      })
    } else {
      this.setState({
        rightDragScrollPosition: 0,
      })
    }

    this.props.changeBarWidthAndPositionState({
      rightDragScrollPosition: 0,
      rightDragDelta: 0,
      leftDragDelta: 0,
      leftDragScrollPosition: 0,
    })

    if (this.props.resizeLinePosition !== null) {
      this.props.updateLineResizePositionFunction(null)
    }
    this.afterDragEnd()
  }

  // Left ------
  private leftStart = () => {
    this.beforeDragStart()
  }

  private leftDrag = (event, {x}) => {
    clearInterval(this.scrollDebounce)
    if (isHScrollable(this.scrollParentElement)) {
      const rect = this.leftRectRef.current.getBoundingClientRect()
      const rectX = rect.x || rect.left
      const scrollLeft = this.scrollParentElement.scrollLeft
      const scrollToLeft =
        rect.width + rectX >= window.innerWidth && canScroll(this.scrollParentElement, true)
      const scrollToRight =
        rectX <= this.props.sidebarSize && canScroll(this.scrollParentElement, false)
      if (scrollToLeft) {
        this.calculateLeftScroll(scrollLeft, 1, true)
        return
      } else if (scrollToRight) {
        this.calculateLeftScroll(scrollLeft, -1, false)
        return
      }
    }

    this.setState({
      leftDragScrollPosition: 0,
    })
    this.props.changeBarWidthAndPositionState({
      rightDragScrollPosition: 0,
      rightDragDelta: 0,
      leftDragDelta: -x,
      leftDragScrollPosition: 0,
    })
    const linePosition = getResizeLinePosition(this.leftRectRef, 0, this.props.sidebarSize)
    this.props.updateLineResizePositionFunction(linePosition)
  }

  private leftStop = (event, {x}) => {
    this.stopResizing(true, x)
  }
  // Left ------

  // Right ------
  private rightStart = () => {
    this.beforeDragStart()
  }

  private rightDrag = (event, {x}) => {
    clearInterval(this.scrollDebounce)
    if (isHScrollable(this.scrollParentElement)) {
      const rect = this.rightRectRef.current.getBoundingClientRect()
      const rectX = rect.x || rect.left
      const scrollLeft = this.scrollParentElement.scrollLeft
      const scrollToRight =
        rect.width + rectX >= window.innerWidth && canScroll(this.scrollParentElement, true)
      const scrollToLeft =
        rectX <= this.props.sidebarSize && canScroll(this.scrollParentElement, false)
      if (scrollToRight) {
        this.calculateRightScroll(scrollLeft, 1, true)
        return
      } else if (scrollToLeft) {
        this.calculateRightScroll(scrollLeft, -1, false)
        return
      }
    }

    this.setState({
      rightDragScrollPosition: 0,
    })

    this.props.changeBarWidthAndPositionState({
      rightDragScrollPosition: 0,
      rightDragDelta: x,
      leftDragDelta: 0,
      leftDragScrollPosition: 0,
    })
    const linePosition = getResizeLinePosition(
      this.rightRectRef,
      this.arrowWidth,
      this.props.sidebarSize
    )
    this.props.updateLineResizePositionFunction(linePosition)
  }

  private rightStop = (event, {x}) => {
    this.stopResizing(false, x)
  }

  private reformRoleDistribution = (
    changedMonths: string[],
    isResizedToLeft = false
  ): IInitiativeRole[] => {
    const {
      initiative: {
        people: {roles},
      },
    } = this.props

    return roles.map((role: IInitiativeRole) => {
      const distribution = this.reformDistribution(
        changedMonths,
        role.distribution!,
        isResizedToLeft
      )

      return {...role, distribution}
    })
  }

  private reformDistribution = (
    changedMonths: string[],
    distribution: IDistribution,
    isResizedToLeft = false
  ) => {
    if (!distribution) {
      return distribution
    }

    let distributionClone = {...distribution}

    changedMonths.forEach((month) => {
      if (month in distribution) {
        delete distributionClone[month]
      } else {
        const distributionItem = {[month]: 0}

        if (isResizedToLeft) {
          distributionClone = {...distributionItem, ...distributionClone}
        } else {
          distributionClone = {...distributionClone, ...distributionItem}
        }
      }
    })

    return distributionClone
  }
}

const mapStateToProps = (state) => ({
  planStartDate: currentPlanSelector(state)!.startDate,
  planDuration: currentPlanSelector(state)!.duration,
  cellWidth: timelineCellWidthSelector(state),
  mode: state.plan.timeline.mode,
  resizeLinePosition: resizeLinePosition(state),
  usePeopleCostOnConflictCalculation: peopleCostOnConflictCalculationSelector(state),
  people: currentScenarioPeopleSelector(state),
  draggingInitiativeId: draggingInitiativeIdSelector(state),
  sidebarSize: sidebarSizeSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  updateInitiativeDatesFunction: (initiative) => dispatch(updateInitiativeDates(initiative)),
  updateInitiativeAndRecalculateConflictsFunction: (
    initiative,
    usePeopleCostOnConflictCalculation
  ) =>
    dispatch(
      updateInitiativeAndRecalculateConflicts(initiative, usePeopleCostOnConflictCalculation)
    ),
  updateLineResizePositionFunction: (position: number | null) =>
    dispatch(updateLineResizePosition(position)),
  setDraggingInitiativeIdFunction: (initiativeId) =>
    dispatch(setDraggingInitiativeId(initiativeId)),
})
export const TimelineBarResizeComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(TimelineBarResize)
