import ExpensesIcon from 'phoenix-icons/dist/ExpensesIcon.js'
import {primary, secondary} from '@phoenix/all'
import * as React from 'react'
import {connect} from 'react-redux'
import {activeScenarioSelector, currencySelector} from '../../../../data/plan'
import {IScenarioBudgets} from '../../../../shared/models/scenario'
import {formatCurrency, formatPercentage} from '../../../../shared/utilities'
import {CircleInfoComponent, getArrowDirection, InfoComponent} from './shared'

interface IBudgetRowComponent {
  budget: IScenarioBudgets
  activeScenarioBudget: IScenarioBudgets
  currency: string
}

export const BudgetRow: React.FunctionComponent<IBudgetRowComponent> = (
  props: IBudgetRowComponent
) => {
  const {budget, activeScenarioBudget, currency} = props
  const circleColor =
    budget.utilization !== null && budget.utilization > 100 ? secondary.red(400) : primary.blue(400)

  const budgetArrowDirection = getArrowDirection(budget.total, activeScenarioBudget.total)
  const budgetAbbreviatedValue =
    budget.total !== null ? formatCurrency(budget.total, currency) : '-'
  const budgetValue = budget.total !== null ? formatCurrency(budget.total, currency, false) : '-'

  const costsArrowDirection = getArrowDirection(budget.costs, activeScenarioBudget.costs)
  const costsAbbreviatedValue = budget.costs !== null ? formatCurrency(budget.costs, currency) : '-'
  const costsValue = budget.costs !== null ? formatCurrency(budget.costs, currency, false) : '-'

  const utilizationArrowDirection = getArrowDirection(
    budget.utilization,
    activeScenarioBudget.utilization
  )
  const utilizationColor =
    budget.utilization !== null && budget.utilization > 100 ? secondary.red(400) : null
  const utilizationAbbreviatedValue =
    budget.utilization !== null ? formatPercentage(budget.utilization, true) : '-'
  const utilizationValue =
    budget.utilization !== null ? formatPercentage(budget.utilization, false) : '-'

  return (
    <>
      <CircleInfoComponent
        icon={<ExpensesIcon />}
        color={circleColor}
        part={budget.costs || 0}
        total={budget.total || 0}
      />
      <InfoComponent
        testId={'scenario.widget.budget'}
        labelMessageKey={'scenario.widget.budget'}
        arrowDirection={budgetArrowDirection}
        abbreviatedValue={budgetAbbreviatedValue!}
        value={budgetValue!}
      />

      <InfoComponent
        testId={'scenario.card.costs'}
        labelMessageKey={'scenario.card.costs'}
        arrowDirection={costsArrowDirection}
        abbreviatedValue={costsAbbreviatedValue!}
        value={costsValue!}
      />

      <InfoComponent
        testId={'scenario.card.utilization'}
        labelMessageKey={'scenario.card.utilization'}
        arrowDirection={utilizationArrowDirection}
        abbreviatedValue={utilizationAbbreviatedValue}
        value={utilizationValue}
        color={utilizationColor}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  activeScenarioBudget: activeScenarioSelector(state).budgets,
  currency: currencySelector(state),
})

export const BudgetRowComponent = connect(mapStateToProps)(BudgetRow)
