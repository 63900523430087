import * as React from 'react'
import {VariableSizeList, VariableSizeList as List, ListOnItemsRenderedProps} from 'react-window'
import {IInitiative} from '../../../shared/models/initiative'
import {IScenario} from '../../../shared/models/scenario'
import {InitiativePlaceholderComponent} from './Initiative/InitiativePlaceholderComponent'
import {MeasurementsContext} from '../../../shared/context/MeasurementsContext'

interface IListRendererComponentProps {
  initiatives: IInitiative[]
  onItemsRendered: (renderedProps: ListOnItemsRenderedProps) => any
  height: number
  width: number
  itemSize: number
  innerRef: React.RefObject<HTMLDivElement>
  reactWindowRef: React.Ref<VariableSizeList>
  currentScenario: IScenario
  shrinkInitiatives: string[]
  isScrollbarAtLeft: boolean
}

interface IInnerElementTypeProps {
  style: {
    height: number
  }
  children: React.ReactNode
}

const ListRendererContext = React.createContext<{
  currentScenario: IScenario | null
  isScrollbarAtLeft: boolean
}>({
  currentScenario: null,
  isScrollbarAtLeft: true,
})
ListRendererContext.displayName = 'ListRendererContext'

export const ItemWrapper = (props) => {
  const {data, index, style, isScrolling} = props
  const {ItemRenderer, initiatives, height, isScrollbarAtLeft} = data
  const initiative = initiatives[index]

  return (
    <ItemRenderer
      index={index}
      isScrolling={isScrolling}
      isScrollbarAtLeft={isScrollbarAtLeft}
      initiative={initiative}
      style={style}
      height={height}
    />
  )
}

// eslint-disable-next-line react/display-name
const innerElementType: React.FunctionComponent<IInnerElementTypeProps> = React.forwardRef(
  (props, ref: React.Ref<HTMLDivElement>) => (
    <ListRendererContext.Consumer>
      {({currentScenario, isScrollbarAtLeft}) => (
        <div ref={ref} style={{height: props.style.height, minHeight: '100%'}}>
          <InitiativePlaceholderComponent
            currentScenario={currentScenario}
            isScrollbarAtLeft={isScrollbarAtLeft}
          />
          {props.children}
        </div>
      )}
    </ListRendererContext.Consumer>
  )
)

// eslint-disable-next-line react/display-name
export const ListRendererComponent: React.FunctionComponent<IListRendererComponentProps> = (
  props
) => {
  const shrinkItemsSet = React.useMemo(() => {
    const set = new Set()
    props.shrinkInitiatives.forEach((initiativeId) => {
      set.add(props.initiatives.findIndex((initiative) => initiative.id === initiativeId))
    })
    return set
  }, [props.initiatives, props.shrinkInitiatives])

  const getItemSize = React.useCallback(
    (index) => {
      return shrinkItemsSet.has(index) ? 0 : props.itemSize
    },
    [shrinkItemsSet]
  )

  return (
    <MeasurementsContext.Consumer>
      {({initiativeHeaderHeight}) => (
        <ListRendererContext.Provider
          value={{
            currentScenario: props.currentScenario,
            isScrollbarAtLeft: props.isScrollbarAtLeft,
          }}
        >
          <List
            useIsScrolling
            innerRef={props.innerRef}
            ref={props.reactWindowRef}
            height={props.height - initiativeHeaderHeight}
            itemCount={props.initiatives.length}
            itemSize={getItemSize}
            width={props.width}
            innerElementType={innerElementType}
            className="scrollContent"
            onItemsRendered={props.onItemsRendered}
            itemData={{
              ItemRenderer: props.children,
              initiatives: props.initiatives,
              height: props.height,
              isScrollbarAtLeft: props.isScrollbarAtLeft,
            }}
          >
            {ItemWrapper}
          </List>
        </ListRendererContext.Provider>
      )}
    </MeasurementsContext.Consumer>
  )
}

// TODO: Separate different components to different files
