import React from 'react'
import {SortableHandle} from 'react-sortable-hoc'
import DragHandleIcon from 'phoenix-icons/dist/DragHandleIcon.js'
import {cx, css} from 'react-emotion'
import {resetButtonDefaultStyles} from '../../../../shared'

const dragHandlerClass = css`
  ${resetButtonDefaultStyles};
  opacity: 0;
  left: 0;
  margin-top: -12px;
  position: absolute;
  top: 50%;
  z-index: 1;
`

const InnerDragHandle = SortableHandle(() => <DragHandleIcon />)

interface IDragHandleProps {
  dragHandleContainerClass?: string
}

export const DragHandleComponent: React.FC<IDragHandleProps> = ({dragHandleContainerClass}) => (
  <span
    className={`${cx(dragHandlerClass, dragHandleContainerClass)} priority-drag-handler`}
    data-testid="reorder-button"
  >
    <InnerDragHandle />
  </span>
)
