import {primary} from '@phoenix/all'
import {css} from 'react-emotion'

export const carouselItemConflictedBorderStyles = css`
  position: relative;

  & + & {
    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 1px;
      background-color: ${primary.gray(200)};
      top: 0;
      z-index: -1;
      left: 0;
    }
  }

  &:first-of-type {
    &:before {
      display: none;
    }
  }
`

export const conflictDetailLabelClass = css`
  flex-grow: 1;
  justify-content: space-between;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
`

export const sidebarRowClass = css`
  border-radius: 2px;
  position: relative;
  margin: 0 -22px 0 -17px;
  padding: 12px 16px 12px 16px;
`
