import {css} from 'emotion'
import * as React from 'react'

import {primary} from '@phoenix/all'

interface ITimelineHeaderCellComponentProps {
  index: number
  translateX: number
  width: number
  mode: number
  lineY1?: number
  lineY2?: number
  defaultFill?: string
  text?: string
  isDashedLine?: boolean
  isConflicted?: boolean
  testId?: string
  highlight?: boolean
  showBorders?: boolean
  hideLeftLine?: boolean
  onMouseOver?: any
  onMouseLeave?: any
}

const textClass = css`
  fill: ${primary.gray(500)};
  opacity: 0.8;
  letter-spacing: 0.5px;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  pointer-events: none;
`

const leftBorderClass = css`
  stroke: ${primary.gray(200)};
  stroke-width: 1px;
`

const bottomBorderClass = css`
  transform: translateY(-1px);
`

// eslint-disable-next-line react/display-name
export const TimelineCellComponent = React.forwardRef<
  SVGGeometryElement,
  ITimelineHeaderCellComponentProps
>((props, ref) => (
  <g
    data-testid="timeline-header-period"
    ref={ref}
    onMouseOver={props.onMouseOver}
    onMouseLeave={props.onMouseLeave}
    transform={`translate(${props.translateX}, 0)`}
    width={props.width}
  >
    <rect
      x="0"
      width={props.width}
      height="100%"
      fill={
        props.isConflicted ? 'url(#diagonalHatch)' : props.defaultFill ? props.defaultFill : 'none'
      }
      data-testid={props.testId}
      filter={props.highlight ? 'url(#hoverShadow)' : ''}
    />
    {props.highlight ? (
      <rect x="0" width={props.width} height="2" fill={primary.blue(400)} />
    ) : (
      props.showBorders && <rect x="0" width={props.width} height="1" fill={primary.gray(200)} />
    )}
    {props.text && (
      <svg width={props.width}>
        <text
          className={`${textClass} timeline-period-label`}
          x="50%"
          y="50%"
          textAnchor="middle"
          alignmentBaseline="middle"
        >
          {props.text}
        </text>
      </svg>
    )}
    {!props.hideLeftLine && props.index > 0 && (
      <line
        className={leftBorderClass}
        x1="0"
        y1={props.lineY1 ? `${props.lineY1}px` : '0'}
        x2="0"
        y2={props.lineY2 ? `${props.lineY2}px` : '100%'}
        strokeDasharray={props.isDashedLine ? '3px, 4.5' : 0}
        shapeRendering="crispEdges"
      />
    )}
    {!props.highlight && props.showBorders && (
      <rect
        x="0"
        y="100%"
        className={bottomBorderClass}
        width={props.width}
        height="1"
        fill={primary.gray(200)}
      />
    )}
  </g>
))
