import {css} from 'emotion'
import {Moment} from 'moment'
import * as React from 'react'
import {connect} from 'react-redux'

import MoreStrokeSmallIcon from 'phoenix-icons/dist/MoreStrokeSmallIcon.js'
import EditIcon from 'phoenix-icons/dist/EditIcon.js'
import CopyIcon from 'phoenix-icons/dist/CopyIcon.js'
import DeleteIcon from 'phoenix-icons/dist/DeleteIcon.js'
import ProjectSmallHeroIcon from 'phoenix-icons/dist/ProjectSmallHeroIcon.js'
import {primary, ConfirmDialog, LabelDropdown, Option, Text, Ellipse, Checkbox} from '@phoenix/all'

import {changeCurrentInitiative, currentInitiativeSelector} from '../../../../../data/initiative'
import {
  resetPublishedScenario,
  toggleInitiativeSelection,
  selectInitiativeRange,
} from '../../../../../data/plan'
import {
  copyInitiative,
  currentScenarioBudgetSelector,
  currentScenarioInitiativesSelector,
  currentScenarioPeopleSelector,
  currentScenarioSelector,
  deleteInitiativeWithAnimation,
  updateInitiativeName,
} from '../../../../../data/scenario'
import {viewAccessSelector} from '../../../../../data/settings'
import {
  changeVisibleConflict,
  isEditSidebarOpenSelector,
  resizeLinePosition,
  showConflictsSelector,
  toggleEditSidebar,
  updateBudgetConflictMonthIndex,
  updateRoleConflictMonthIndex,
} from '../../../../../data/viewOptions'
import {ActionType} from '../../../../../shared/enums'
import {
  IBudgetConflict,
  IScenario,
  IScenarioBudgets,
  IScenarioPeople,
} from '../../../../../shared/models'
import {IInitiative} from '../../../../../shared/models/initiative'
import {inlineCenterItemsClass} from '../../StyleHelper'
import {LinkedProjectIcon} from '../LinkedProjectIconComponent'
import {isInitiativeSelectedSelector} from '../../../../../data/plan/selectors/isInitiativeSelectedSelector'
import {MenuItemContent} from './MenuItemContent'
import {PublishedInitiativeIndicatorIcon} from './PublishedInitiativeIndicatorIcon'
import {NewInlineEditComponent} from '../../../../shared/NewInlineEditComponent'
import {peopleCostOnConflictCalculationSelector} from '../../../../../data/plan/selectors/peopleCostOnConflictCalculationSelector'
import {ConflictTooltipComponent} from './ConflictTooltipComponent'
import {Translate} from '../../../../shared/Translate'
import {translate} from '../../../../../shared/utilities/TranslateHelper'
import {ConfirmationDialogHeaderComponent} from '../../../../shared/ConfirmationDialogHeaderComponent'

export interface IInitiativeHeaderComponentProps {
  initiative: IInitiative
  initiatives: IInitiative[]
  currentScenario: IScenario
  budgets: IScenarioBudgets
  people: IScenarioPeople
  isViewMode: boolean
  resizeLinePosition: number | null
  isScrolling: boolean
  isEditSidebarOpen: boolean
  currentInitiative: IInitiative | null
  isSelected: boolean
  isPublished: boolean
  hasConflicts: boolean
  usePeopleCostOnConflictCalculation: boolean
  toggleSelection: () => void
  changeCurrentInitiativeFunction: (
    initiative: IInitiative | null,
    usePeopleCostOnConflictCalculation: boolean
  ) => void
  openSidebarFunction: (
    currentInitiative: IInitiative,
    currentScenario: IScenario | null,
    usePeopleCostOnConflictCalculation: boolean
  ) => void
  updateInitiativeFunction: (initiative: IInitiative) => void
  copyInitiativeFunction: (initiative, usePeopleCostOnConflictCalculation: boolean) => void
  deleteInitiativeFunction: (initiative, usePeopleCostOnConflictCalculation: boolean) => void
  resetPublishedScenarioFunction: () => void
  selectInitiatives: (endInitiativeId: string) => void
  updateBudgetConflictMonthIndexFunction: (endInitiativeId: number) => void
  updateRoleConflictMonthIndexFunction: (endInitiativeId: number) => void
  showConflicts: boolean
}

interface IInitiativeHeaderComponentState {
  isDeleteConfirmDialogOpen: boolean
  isMoreMenuOpen: boolean
}

const initiativeHeaderClass = css`
  ${inlineCenterItemsClass};
  justify-content: space-between;
`

const headerLeftClass = css`
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 8px;
  flex-grow: 1;
`

const inlineEditClass = css`
  font-size: 16px;
  color: ${primary.gray(800)};
  margin-right: 5px;
  min-width: 108px;
  flex: 1;
`

const initiativeNameClass = css`
  ${inlineEditClass}
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
  font-size: 14px;
  line-height: 22px;
  margin-left: 1px;
  max-width: 225px;
`

const initiativeNameLongClass = css`
  max-width: 200px;
`

const initiativeMoreClass = css`
  display: none;
  margin-top: 2px;
  label {
    display: none;
  }
`

const initiativePriorityClass = css`
  font-size: 14px;
  flex-basis: 24px;
  flex-shrink: 0;
  text-indent: 4px;
  min-width: 31px;
`

const checkboxContainerClass = css`
  margin: 0 8px 0 0;
  height: 14px;
  width: 14px;

  input[type='checkbox'] {
    width: 14px;
    height: 14px;
    margin: 0 12px 0 0;
  }
`

const linkedProjectContainerClass = css`
  position: relative;
  width: 25px;
  text-align: right;
`

const linkedProjectClass = css`
  margin: 0;
`

const publishedProjectIconContainerClass = css`
  position: absolute;
  top: -5px;
  right: -5px;
  width: 10px;
  height: 10px;
  display: flex;
`

class InitiativeHeader extends React.PureComponent<
  IInitiativeHeaderComponentProps,
  IInitiativeHeaderComponentState
> {
  initiativeNameRef: React.RefObject<HTMLInputElement>
  initiativeName: string

  constructor(props: IInitiativeHeaderComponentProps) {
    super(props)

    this.initiativeName = props.initiative.name
    this.initiativeNameRef = React.createRef()
    this.state = {
      isDeleteConfirmDialogOpen: false,
      isMoreMenuOpen: false,
    }
  }

  componentDidMount(): void {
    if (
      this.props.currentInitiative &&
      this.initiativeNameRef.current &&
      !this.props.isEditSidebarOpen &&
      this.props.currentInitiative.id === this.props.initiative.id
    ) {
      this.initiativeNameRef.current.focus({preventScroll: true})
      this.initiativeNameRef.current.select()
    }
  }

  componentDidUpdate(prevProps: Readonly<IInitiativeHeaderComponentProps>) {
    if (this.props.isScrolling && !prevProps.isScrolling) {
      this.setState({isMoreMenuOpen: false})
    }
  }

  handleCheckboxClick = (evt) => {
    if (evt.nativeEvent.shiftKey) {
      // We are selecting a range of initiatives using the Shift key
      this.props.selectInitiatives(this.props.initiative.id)
    } else {
      this.props.toggleSelection()
    }
  }

  onMoreMenuOpenStateChange = () => {
    this.setState((prevState) => ({
      ...prevState,
      isMoreMenuOpen: !prevState.isMoreMenuOpen,
    }))
  }
  render() {
    const tabIndex = this.props.currentInitiative ? -1 : 0
    const {name} = this.props.initiative
    const activeClass =
      this.props.currentInitiative && this.props.currentInitiative.id === this.props.initiative.id
        ? 'current-initiative-name'
        : ''
    const confirmationMessage =
      this.props.initiative.lastPublishedDate !== null &&
      this.props.currentScenario.publishedInitiativesCount === 1 ? (
        <Translate messageKey={'sp.messages.initiative.warning.last_published.delete'} />
      ) : (
        <Translate messageKey={'sp.messages.initiative.warning.delete'} />
      )

    return (
      <div className={initiativeHeaderClass}>
        {!this.props.isViewMode && (
          <div className={checkboxContainerClass}>
            <Checkbox
              label=""
              checked={this.props.isSelected}
              testID="initiative-row-checkbox"
              onChange={this.handleCheckboxClick}
              disabled={this.props.isEditSidebarOpen}
              name={`initiative-${this.props.initiative.id}-checkbox`}
            />
          </div>
        )}
        <span className={initiativePriorityClass} data-testid="initiative-priority">
          {this.props.initiative.order}.
        </span>

        <div className={headerLeftClass}>
          {this.props.hasConflicts && (
            <ConflictTooltipComponent
              initiative={this.props.initiative}
              onKeyPress={this.onKeyPressHandler}
              onClick={this.goToConflict}
            />
          )}
          {this.props.isViewMode ? (
            <div
              className={`${initiativeNameClass} ${
                this.props.initiative.refObject ? initiativeNameLongClass : ''
              }`}
            >
              <Ellipse>{name}</Ellipse>
            </div>
          ) : (
            <NewInlineEditComponent
              className={`initiative-name ${activeClass}`}
              ref={this.initiativeNameRef}
              onBlur={this.onBlurHandler}
              onFocus={this.onFocus}
              onChange={this.onChangeHandler}
              onKeyDown={this.handleKeyDown}
              testId={'initiative-input'}
              maxLength={255}
              tabIndex={tabIndex}
              placeholder={translate('form.start.typing.name')}
              value={name}
            />
          )}
        </div>
        <div
          className={`${initiativeMoreClass} initiative-more`}
          style={{
            display: this.state.isMoreMenuOpen ? 'block' : '',
          }}
        >
          {!this.props.isScrolling && (
            <LabelDropdown
              testID="more-icon"
              onIsOpenChanged={this.onMoreMenuOpenStateChange}
              trigger={<MoreStrokeSmallIcon />}
              onChange={(value) => this.onDropdownChange(value)}
            >
              {this.props.isViewMode && (
                <Option value={ActionType.DETAILS} withPadding={false} testID="details">
                  <Translate messageKey={'initiative.options.details'} />
                </Option>
              )}
              {!this.props.isViewMode && (
                <Option value={ActionType.EDIT} withPadding={false} testID="edit">
                  <MenuItemContent
                    Icon={EditIcon}
                    text={<Translate messageKey={'initiative.options.edit'} />}
                  />
                </Option>
              )}
              {!this.props.isViewMode && (
                <Option value={ActionType.COPY} withPadding={false} testID="copy">
                  <MenuItemContent
                    Icon={CopyIcon}
                    text={<Translate messageKey={'initiative.options.copy'} />}
                  />
                </Option>
              )}
              {!this.props.isViewMode && (
                <Option value={ActionType.DELETE} withPadding={false} testID="delete">
                  <MenuItemContent
                    Icon={DeleteIcon}
                    text={<Translate messageKey={'initiative.options.delete'} />}
                  />
                </Option>
              )}
            </LabelDropdown>
          )}
        </div>
        {this.props.initiative.refObject && (
          <div className={linkedProjectContainerClass}>
            <LinkedProjectIcon
              className={linkedProjectClass}
              icon={ProjectSmallHeroIcon}
              tooltipMessageKey={
                this.props.isPublished
                  ? 'initiative.publish.project.icon'
                  : 'initiative.import.project.icon'
              }
              refObject={this.props.initiative.refObject}
            />
            {this.props.isPublished && (
              <div className={publishedProjectIconContainerClass} data-testid="published-flag">
                <PublishedInitiativeIndicatorIcon className="initiative-published-flag" />
              </div>
            )}
          </div>
        )}
        {this.state.isDeleteConfirmDialogOpen && (
          <ConfirmDialog
            header={
              <ConfirmationDialogHeaderComponent messageKey={'sp.scenario.initiative.delete'} />
            }
            confirmText={<Translate messageKey={'form.button.delete'} />}
            denyText={<Translate messageKey={'form.button.cancel'} />}
            onConfirmClick={this.confirmDelete}
            onDenyClick={this.denyDelete}
            testID="delete-initiative-confirmation-dialog"
          >
            <Text>{confirmationMessage}</Text>
          </ConfirmDialog>
        )}
      </div>
    )
  }

  private onKeyPressHandler = (e) => {
    const keyboardClickKeys = ['Enter', 'Space', ' ']
    if (keyboardClickKeys.includes(e.key)) {
      this.goToConflict()
    }
  }
  private getRoleConflictMonthIndex = (roleConflicts) => {
    let firstConflict = this.props.initiative.dates.endDate
    roleConflicts.forEach((conflict) => {
      if (firstConflict.isAfter(conflict.periodConflicts[0].period)) {
        firstConflict = conflict.periodConflicts[0].period
      }
    })
    return firstConflict.diff(this.props.initiative.dates.startDate, 'month') + 1
  }
  private getBudgetConflictMonthIndex = (budgetConflicts: IBudgetConflict) => {
    const firstConflict: Moment = budgetConflicts.periodConflicts[0].period

    return firstConflict.diff(this.props.initiative.dates.startDate, 'month') + 1
  }
  private goToConflict = () => {
    const {conflicts} = this.props.initiative
    const hasRoleConflicts: boolean = !!conflicts && !!conflicts.roleConflicts!.size
    const hasBudgetConflicts: boolean = !!conflicts && !!conflicts.budgetConflicts
    if (hasRoleConflicts) {
      this.props.updateRoleConflictMonthIndexFunction(
        this.getRoleConflictMonthIndex(conflicts!.roleConflicts)
      )
    } else if (hasBudgetConflicts) {
      this.props.updateBudgetConflictMonthIndexFunction(
        this.getBudgetConflictMonthIndex(conflicts!.budgetConflicts!)
      )
    }
    this.openSidebar()
    setTimeout(() => {
      const conflictTop =
        document.querySelector('.initiative-conflict')!.getClientRects()[0].top - 140
      const sidebarScroll = document.querySelector('.edit-sidebar-body')!.scrollTop
      document.querySelector('.edit-sidebar-body')!.scrollTo(0, conflictTop - sidebarScroll)
    }, 100)
  }

  private handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.initiativeNameRef.current!.blur()
    }
  }

  private updateInitiativeName = (name: string) => {
    const initiative = {
      ...this.props.initiative,
      name,
    }
    this.props.updateInitiativeFunction(initiative)
  }

  private onChangeHandler = ({target}) => {
    const {value} = target

    this.updateInitiativeName(value)
  }

  private onFocus = ({target}) => {
    if (target.value !== '') {
      this.props.changeCurrentInitiativeFunction(
        this.props.initiative,
        this.props.usePeopleCostOnConflictCalculation
      )
    }

    if (
      translate('sp.initiative.default.name') === this.props.initiative.name &&
      this.initiativeNameRef.current
    ) {
      this.initiativeNameRef.current!.select()
    }
  }

  private onBlurHandler = ({target}) => {
    let name = target.value.trim()
    if (name === '') {
      name = this.initiativeName
    } else {
      this.initiativeName = name
    }
    this.updateInitiativeName(name)
    if (this.props.resizeLinePosition === null) {
      this.props.changeCurrentInitiativeFunction(
        null,
        this.props.usePeopleCostOnConflictCalculation
      )
    }
  }

  private deleteInitiative = () => {
    this.setState({
      isDeleteConfirmDialogOpen: true,
    })
  }

  private openSidebar = () => {
    this.props.openSidebarFunction(
      this.props.initiative,
      this.props.showConflicts ? this.props.currentScenario : null,
      this.props.usePeopleCostOnConflictCalculation
    )
  }

  private confirmDelete = () => {
    this.props.deleteInitiativeFunction(
      this.props.initiative,
      this.props.usePeopleCostOnConflictCalculation
    )
    if (
      this.props.initiative.lastPublishedDate !== null &&
      this.props.currentScenario.publishedInitiativesCount === 1
    ) {
      this.props.resetPublishedScenarioFunction()
    }
    this.setState({
      isDeleteConfirmDialogOpen: false,
    })
  }

  private denyDelete = () => {
    this.setState({
      isDeleteConfirmDialogOpen: false,
    })
  }
  private onDropdownChange = (action) => {
    switch (action) {
      case ActionType.DELETE:
        this.deleteInitiative()
        break
      case ActionType.COPY:
        this.copyInitiative()
        break
      default:
        this.openSidebar()
        break
    }
  }

  private copyInitiative = () => {
    this.props.copyInitiativeFunction(
      this.props.initiative,
      this.props.usePeopleCostOnConflictCalculation
    )
  }
}

const mapStateToProps = (state, props) => ({
  currentInitiative: currentInitiativeSelector(state),
  currentScenario: currentScenarioSelector(state),
  isViewMode: viewAccessSelector(state),
  initiatives: currentScenarioInitiativesSelector(state),
  resizeLinePosition: resizeLinePosition(state),
  isEditSidebarOpen: isEditSidebarOpenSelector(state),
  people: currentScenarioPeopleSelector(state),
  budgets: currentScenarioBudgetSelector(state),
  showConflicts: showConflictsSelector(state),
  isSelected: isInitiativeSelectedSelector(state, props),
  usePeopleCostOnConflictCalculation: peopleCostOnConflictCalculationSelector(state),
})

const mapDispatchToProps = (dispatch, props) => ({
  updateRoleConflictMonthIndexFunction: (index) => dispatch(updateRoleConflictMonthIndex(index)),
  updateBudgetConflictMonthIndexFunction: (index) =>
    dispatch(updateBudgetConflictMonthIndex(index)),
  copyInitiativeFunction: (initiative, usePeopleCostOnConflictCalculation) =>
    dispatch(copyInitiative(initiative, usePeopleCostOnConflictCalculation)),
  deleteInitiativeFunction: (initiative, usePeopleCostOnConflictCalculation) =>
    dispatch(deleteInitiativeWithAnimation(initiative, usePeopleCostOnConflictCalculation)),
  changeCurrentInitiativeFunction: (initiative, usePeopleCostOnConflictCalculation) =>
    dispatch(changeCurrentInitiative(initiative, null, usePeopleCostOnConflictCalculation)),
  updateInitiativeFunction: (initiative) => dispatch(updateInitiativeName(initiative)),
  openSidebarFunction: (initiative, currentScenario, usePeopleCostOnConflictCalculation) => {
    dispatch(
      changeCurrentInitiative(initiative, currentScenario, usePeopleCostOnConflictCalculation)
    )
    dispatch(toggleEditSidebar(true))
    dispatch(changeVisibleConflict(null))
  },
  resetPublishedScenarioFunction: () => dispatch(resetPublishedScenario()),
  toggleSelection: () => dispatch(toggleInitiativeSelection(props.initiative.id)),
  selectInitiatives: (endInitiativeId) => dispatch(selectInitiativeRange(endInitiativeId)),
})

export const NewInitiativeHeaderComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(InitiativeHeader)
