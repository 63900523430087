import {css, cx} from 'emotion'
import * as React from 'react'
import {FC, ReactNode} from 'react'
import {useSelector} from 'react-redux'
import {currentScenarioSelector} from '../../data'

const headerClass = css`
  padding: 0px 16px 0 16px;
  position: relative;
  z-index: 2;
`

const marginBottomClass = css`
  margin-bottom: 6px;
`

export const HeaderComponent: FC<{children: ReactNode}> = (props) => {
  const currentScenario = useSelector(currentScenarioSelector)

  const className = React.useMemo(() => (currentScenario ? marginBottomClass : ''), [
    currentScenario,
  ])

  return (
    <header data-testid="scenario-nav-bar" className={cx(headerClass, className)}>
      {props.children}
    </header>
  )
}
