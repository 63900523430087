import {PluginBase, bindPluginClass} from '@workfront/list-plugin-utils'
import {IDataTableAPI} from '@wftypes/list'
import {objectLinkMatcher, ObjectLinkRenderer} from '../../shared/renderers/ObjectLink'
import {
  PercentCompleteRenderer,
  percenteCompleteMatcher,
} from '../../shared/renderers/PercentComplete'

class RendererPlugin extends PluginBase {
  static PLUGIN_NAME = 'RendererPlugin'
  static PLUGIN_DEPS = []

  init(listApi: IDataTableAPI) {
    this.addShutdownRoutine(
      listApi.renderers.registerViewRenderer(
        'component.percentcompletelistview',
        PercentCompleteRenderer
      ),
      listApi.quickFilter.registerQuickFilterMatcher(
        'component.percentcompletelistview',
        percenteCompleteMatcher
      ),

      listApi.renderers.registerViewRenderer('component.object.link', ObjectLinkRenderer),
      listApi.quickFilter.registerQuickFilterMatcher('component.object.link', objectLinkMatcher)
    )
    // this.addShutdownRoutine(
    //   listApi.interceptors.registerInterceptor(RendererPlugin.PLUGIN_NAME, 'COLUMN_REORDER', ({data}) => {
    //     console.log(data.newColumnOrder)
    //   })
    // )
  }
}

export default bindPluginClass(
  () => new RendererPlugin(),
  RendererPlugin.PLUGIN_NAME,
  RendererPlugin.PLUGIN_DEPS || []
)
