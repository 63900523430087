import {primary} from '@phoenix/all'
import {css, cx} from 'emotion'
import * as React from 'react'
import {connect} from 'react-redux'
import {SortableElement} from 'react-sortable-hoc'
import {planDurationSelector, timelineModeSelector} from '../../../../data/plan'
import {TimelineMode} from '../../../../shared/enums'

import {IInitiative} from '../../../../shared/models/initiative'
import {InitiativeComponent} from './InitiativeComponent'
import {selectedInitiativeCountSelector} from '../../../../data/plan/selectors/selectedInitiativeCountSelector'
import {isInitiativeSelectedSelector} from '../../../../data/plan/selectors/isInitiativeSelectedSelector'
import {isEditSidebarOpenSelector} from '../../../../data/viewOptions'
import {INITIATIVE_ANIMATION_DURATION} from '../../../../shared/constants/Animations'
import {enableInitiativeRowTransitionSelector} from '../../../../data/scenario/selectors/enableInitiativeRowTransitionSelector'
import {hiddenInitiativesSelector} from '../../../../data/scenario/selectors/hiddenInitiativesSelector'

interface IInitiativeRowProps {
  style: {
    top: number
  }
  isScrolling: boolean
  initiative: IInitiative
  height: number
  planDuration: number
  timelineMode: TimelineMode
  isEditSidebarOpen: boolean
  isInitiativeSelected: boolean
  selectedInitiativeCount: number
  enableInitiativeRowTransition: boolean
  hiddenInitiatives: string[]
  isScrollbarAtLeft: boolean
}

const initiativeRowClass = css`
  &:last-child {
    .initiative-info-background-container {
      padding: 11px 8px 9px 24px;
    }
  }
  &:first-child {
    svg {
      display: block;
    }
  }

  &:nth-child(2) {
    .initiative-info-container:after {
      display: none;
    }
  }

  &.reordering-row {
    display: flex;
    align-items: center;
    background-color: ${primary.gray(0)};
    left: 0 !important;
    margin-left: 8px;
    min-width: calc(100% - 16px) !important;
    border-radius: 3px;
    overflow: hidden;
    width: calc(100% - 16px) !important;
    box-shadow: 0 8px 10px 0 rgba(0, 35, 64, 0.2);
    z-index: 100000;
    &.has-conflict {
      .initiative-right-container {
        background-color: rgba(255, 23, 68, 0.05);
      }
      .timeline-row {
        background-color: rgba(0, 0, 0, 0);
      }
    }
    .timeline {
      & > line,
      & > g {
        display: none;
      }

      g:last-child {
        display: block;
      }
    }

    .priority-drag-handler {
      opacity: 1;
    }

    .initiative-info-container {
      border-right: 0;

      &:after {
        height: 0px;
      }
    }

    .initiative-right-container {
      overflow: auto;
      scrollbar-width: none; /* for Firefox */
      -ms-overflow-style: none; /* IE 11 */

      &::-webkit-scrollbar {
        display: none; /* for Chrome, Safari */
      }
    }

    .initiative-more {
      display: none;
    }
  }

  &:hover {
    .initiative-more {
      display: block;
    }
  }
`

const hideMoreButtonClass = css`
  &:hover {
    .initiative-more {
      display: none;
    }
  }
`

const hideClass = css`
  opacity: 0;
`

const enableAnimationsClass = css`
  transition: all ${INITIATIVE_ANIMATION_DURATION}ms cubic-bezier(0.25, 1, 0.5, 1);
`

const InitiativeRowContentWrapper = (props) => {
  const hideMoreButton =
    props.isEditSidebarOpen ||
    props.selectedInitiativeCount > 1 ||
    (props.selectedInitiativeCount === 1 && !props.isInitiativeSelected)

  const hideInitiative = React.useMemo(() => {
    return props.hiddenInitiatives.indexOf(props.initiative.id) > -1
  }, [props.initiative.id, props.hiddenInitiatives])

  return (
    <div
      style={props.style}
      className={cx(initiativeRowClass, {
        'has-conflict': props.hasConflict,
        [hideMoreButtonClass]: hideMoreButton,
        [hideClass]: hideInitiative,
        [enableAnimationsClass]: props.enableInitiativeRowTransition,
      })}
      data-testid={props.testId}
    >
      {props.children}
    </div>
  )
}

const InitiativeRowSortableItem = SortableElement((props) => {
  return (
    <InitiativeRowContentWrapper {...props} testId="initiative-row">
      <InitiativeComponent
        isScrolling={props.isScrolling}
        initiative={props.initiative}
        key={props.initiative.id}
        hasConflict={props.hasConflict}
        isScrollbarAtLeft={props.isScrollbarAtLeft}
      />
    </InitiativeRowContentWrapper>
  )
})

export const InitiativeRow: React.FunctionComponent<IInitiativeRowProps> = React.memo((props) => {
  const {style, isScrolling, initiative} = props
  const hasConflict = initiative.conflicts
  const newStyle = {
    ...style,
    width: 'auto',
  }

  return (
    <InitiativeRowSortableItem
      hasConflict={hasConflict}
      initiative={initiative}
      index={initiative.order}
      style={newStyle}
      isScrolling={isScrolling}
      isEditSidebarOpen={props.isEditSidebarOpen}
      isInitiativeSelected={props.isInitiativeSelected}
      selectedInitiativeCount={props.selectedInitiativeCount}
      hiddenInitiatives={props.hiddenInitiatives}
      enableInitiativeRowTransition={props.enableInitiativeRowTransition}
      isScrollbarAtLeft={props.isScrollbarAtLeft}
    />
  )
})

InitiativeRow.displayName = 'InitiativeRow'

const mapStateToProps = (state, props) => ({
  planDuration: planDurationSelector(state),
  timelineMode: timelineModeSelector(state),
  isEditSidebarOpen: isEditSidebarOpenSelector(state),
  isInitiativeSelected: isInitiativeSelectedSelector(state, props),
  selectedInitiativeCount: selectedInitiativeCountSelector(state),
  hiddenInitiatives: hiddenInitiativesSelector(state),
  enableInitiativeRowTransition: enableInitiativeRowTransitionSelector(state),
})

export const InitiativeRowComponent = connect(mapStateToProps)(InitiativeRow)
