import {createSelector} from 'reselect'
import {IScenarioBudgets} from '../../../shared/models'
import {currentScenarioBudgetSelector} from './currentScenarioBudgetSelector'

export const currentScenarioBudgetTotalSelector = createSelector(
  currentScenarioBudgetSelector,
  (currentScenarioBudgetSelector: IScenarioBudgets | null) => {
    return currentScenarioBudgetSelector ? currentScenarioBudgetSelector.total : null
  }
)
