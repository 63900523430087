import {primary} from '@phoenix/all'
import {css} from 'emotion'
import * as React from 'react'
import styled from 'react-emotion'
import {connect} from 'react-redux'
import {timelineModeSelector} from '../../../../data/plan'
import {timelinePeriodSelector} from '../../../../data/plan/selectors/timelinePeriodsSelector'
import {visibleConflictSelector} from '../../../../data/viewOptions'

import {planDurationSelector, timelineWidthSelector} from '../../../../data/plan'
import {CONFLICT_COLOR} from '../../../../shared/constants/Colors'
import {TimelineMode} from '../../../../shared/enums'
import {hasScenarioConflict} from '../../../../shared/helpers'
import {IBudgetConflict, IInitiative, IRoleConflict} from '../../../../shared/models/initiative'
import {IScenario} from '../../../../shared/models/scenario'
import {IPeriod} from '../../../../shared/models/timeline'
import {isSameDateByMonthAndYear} from '../../../../shared/utilities/dateHelpers'
import {TimelineCellComponent} from '../Timeline/TimelineCellComponent'
import {TimelineRowComponent} from '../Timeline/TimelineRowComponent'
import {MeasurementsContext} from '../../../../shared/context/MeasurementsContext'
import {shrinkInitiativesCountSelector} from '../../../../data/scenario/selectors/shrinkInitiativesCountSelector'
import {ShadowILeftStyled} from '../Timeline/TimelineColumnShadowComponent'
import {highlightColumnSelector} from '../../../../data/scenario/selectors/highlightColumnSelector'
import {highlightedColumnIndexSelector} from '../../../../data/scenario/selectors/highlightedColumnIndexSelector'
import {sidebarSizeSelector} from '../../../../data/settings/selectors/sidebarSizeSelector'

interface IInitiativePlaceholderComponentProps {
  timelineWidth: number
  currentScenarioHasConflict: boolean
  periods: IPeriod[]
  cellWidth: number
  initiatives: IInitiative[]
  visibleConflict: IRoleConflict | IBudgetConflict | null
  currentScenario: IScenario
  isScrollbarAtLeft: boolean
  planDuration: number
  timelineMode: TimelineMode
  shrinkInitiativesCount: number
  highlightColumn: boolean
  highlightedColumnIndex: number | null
  sidebarSize: number
}

interface IInitiativePlaceholderStyledProps {
  timelineWidth: string
  hasConflicts: boolean
  initiativesCount: number
  initiativeRowHeight: number
}

const InitiativePlaceholderStyled = styled('div')<IInitiativePlaceholderStyledProps>`
  background-color: ${primary.gray(0)};
  display: flex;
  height: 100%;
  position: sticky;
  left: 0;
  top: 0;

  ${(props) =>
    props.hasConflicts &&
    `
        &, .left-sidebar-background-container {
            background-image: linear-gradient(
              ${primary.gray(0)} ${props.initiativesCount * props.initiativeRowHeight}px,
              ${CONFLICT_COLOR} ${props.initiativesCount * props.initiativeRowHeight}px
            )
        }
    `}

  svg > line {
    visibility: hidden;
  }

  width: ${(props) => props.timelineWidth};
`

const leftSidebarBackgroundContainerClass = css`
  height: 100%;
  width: 100%;
`

const timelineBackGroundBodyClass = css`
  flex: 1;
  height: 100%;
  overflow: hidden;

  .timeline-row {
    height: 100%;
  }
`

const LeftSidebarStyled = styled('div')<{sidebarSize: number}>`
  width: ${(props) => props.sidebarSize}px;
  position: sticky;
  z-index: 1;
  background-color: ${primary.gray(0)};
  border-right: 1px solid ${primary.gray(200)};
  left: 0;
  height: 100%;
`

const borderClass = css`
  stroke: ${primary.gray(200)};
  stroke-width: 2px;
`

export const InitiativePlaceholder: React.FunctionComponent<IInitiativePlaceholderComponentProps> = (
  props
) => {
  const hasConflicts = props.currentScenarioHasConflict
  const timelineCells: JSX.Element[] = []
  let previousCellCount = 0

  for (let i = 0; i < props.periods.length; i++) {
    const isPeriodConflicted =
      props.visibleConflict !== null &&
      props.visibleConflict!.periodConflicts.find((periodConflict) =>
        isSameDateByMonthAndYear(periodConflict.period, props.periods[i].startDate)
      ) !== undefined
    const periodFirstCell = (
      <TimelineCellComponent
        mode={props.timelineMode}
        index={i}
        key={props.periods[i].label || props.periods[i].startDate.format()}
        translateX={previousCellCount * props.cellWidth}
        width={props.cellWidth}
        isConflicted={isPeriodConflicted}
        testId={props.periods[i].startDate.format('YYYY-MM')}
      />
    )
    previousCellCount++
    timelineCells.push(periodFirstCell)
    for (let j = 1; j < props.periods[i].monthCount; j++) {
      const isSubPeriodConflicted =
        props.visibleConflict !== null &&
        props.visibleConflict!.periodConflicts.find((periodConflict) =>
          periodConflict.period.isSame(props.periods[i].startDate.clone().add(j, 'month'))
        ) !== undefined
      const periodCell = (
        <TimelineCellComponent
          mode={props.timelineMode}
          index={j}
          key={`${props.periods[i].label || props.periods[i].startDate.format()} - ${j}`}
          translateX={previousCellCount * props.cellWidth}
          width={props.cellWidth}
          isDashedLine={true}
          isConflicted={isSubPeriodConflicted}
          testId={props.periods[i].startDate.clone().add(j, 'month').format('YYYY-MM')}
        />
      )
      timelineCells.push(periodCell)
      previousCellCount++
    }
  }

  const timelineWidth =
    props.planDuration > 1 && props.timelineMode !== TimelineMode.YEARLY
      ? `${props.timelineWidth + props.sidebarSize}px`
      : 'auto'

  const initiativesCountWithSpace = React.useMemo(
    () => props.initiatives.length - props.shrinkInitiativesCount,
    [props.initiatives.length, props.shrinkInitiativesCount]
  )

  return (
    <MeasurementsContext.Consumer>
      {({initiativeRowHeight}) => (
        <InitiativePlaceholderStyled
          initiativeRowHeight={initiativeRowHeight}
          initiativesCount={initiativesCountWithSpace}
          hasConflicts={hasConflicts}
          timelineWidth={timelineWidth}
          data-testid="list-background"
          className={`${hasConflicts ? 'list-background-conflicted' : ''}`}
        >
          <LeftSidebarStyled sidebarSize={props.sidebarSize} data-testid={'plan-sidebar'}>
            <div
              className={`${leftSidebarBackgroundContainerClass} left-sidebar-background-container`}
            />
            {props.isScrollbarAtLeft &&
              props.highlightColumn &&
              props.highlightedColumnIndex === 0 && <ShadowILeftStyled top="0" right="0" />}
          </LeftSidebarStyled>

          <div className={timelineBackGroundBodyClass}>
            <TimelineRowComponent
              placeholderShadow={true}
              width={previousCellCount * props.cellWidth}
            >
              <line
                className={`${borderClass} timeline-border`}
                x1="0"
                y1="0"
                x2="100%"
                y2="0"
                strokeDasharray="3"
              />

              {timelineCells}
            </TimelineRowComponent>
          </div>
        </InitiativePlaceholderStyled>
      )}
    </MeasurementsContext.Consumer>
  )
}

const mapStateToProps = (state, ownProps) => ({
  periods: timelinePeriodSelector(state),
  cellWidth: state.plan.timeline.cellWidth,
  timelineWidth: timelineWidthSelector(state),
  currentScenarioHasConflict: hasScenarioConflict(ownProps.currentScenario),
  initiatives: ownProps.currentScenario.initiatives,
  visibleConflict: visibleConflictSelector(state),
  planDuration: planDurationSelector(state),
  timelineMode: timelineModeSelector(state),
  shrinkInitiativesCount: shrinkInitiativesCountSelector(state),
  highlightColumn: highlightColumnSelector(state),
  highlightedColumnIndex: highlightedColumnIndexSelector(state),
  sidebarSize: sidebarSizeSelector(state),
})

export const InitiativePlaceholderComponent = connect(mapStateToProps)(InitiativePlaceholder)
