import * as React from 'react'
import {primary, ConfirmDialog, toast, Text} from '@phoenix/all'
import {LIST_ID, COLUMN_MESSAGES, metadataDefinition, columnsDefinition} from './table-data'
import {css} from 'react-emotion'
import * as toolbar from '@wf-mfe/lists'
import {LocalizationProvider} from '@wf-mfe/localize-react'
import {getInstance, List} from '@wf-mfe/lists'
import RendererPlugin from './plugins/renderers'
import DataFetcherPlugin from './plugins/dataFetcher'
import toolbarDefinitionPlugin from './plugins/toolbar/ToolbarDefinitionsPlugin'
import {IPlan} from '../shared/types'
import PlanFVGPlugin from './plugins/toolbar/filters/PlanFVGPlugin'
import {ErrorPageComponent} from '../shared/ErrorPageComponent'
import {AccessMode} from '../shared/constants'
import {LoaderComponent} from '../shared/LoaderComponent'
import {deletePlans} from './services/apiFactory'
import {ShareDialogComponent, IPlanShareData} from '@workfront/sp-sharing-dialog'
import PlanDropdownFilterPlugin from './plugins/toolbar/filters/PlanDropdownFilterPlugin'
import {espClient, redrockClientFactory} from '../../shared/L10n'
import {hasEditAccess} from '../shared/utils/hasEditAccess'

const ToolbarComponent = toolbar.getToolbarComponent(LIST_ID)

interface IPlanListProps {
  currentUserID: string
  loadPermissionsFunction: (objID: string | null) => Promise<Map<string, string>>
  createNewPlanFunction: () => void
  redirectToLoginPage: () => void
  newFilterSpPlansListSplit: boolean
}

interface IDeletePlansData {
  isDeleteConfirmDialogOpen: boolean
  isDeleting: boolean
  plansToDelete: IPlan[]
}

const wrapperDivStyle = css`
  width: 100vw;

  height: calc(100% - 41px);

  button[data-testid='new-plan'] {
    margin-left: 24px;
  }

  div[data-valuefield] {
    border-right: 1px solid ${primary.gray(200)} !important;
    border-bottom: 1px solid ${primary.gray(200)};
  }

  div[data-test-name='HeaderCell'] {
    border-bottom: 0;
  }

  [data-colindex='0'] .table-header-cell-styled {
    margin: 0;
    padding: 0;
  }

  [data-colindex='0'] [data-testid='header-label'] {
    display: flex;
    justify-content: center;
    min-width: unset;
  }

  [data-colindex='0'] [data-testid='header-label']:first-child {
    padding: 8px 0;
  }

  div[data-test-id='header-checkbox'] {
    position: relative;
    right: 1px;
    width: 14px;
    height: 14px;
  }

  div[data-valuefield=''] .table-body-cell-styled {
    padding: 0;
  }

  div[data-valuefield=''] .table-body-cell-styled > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div[data-valuefield=''] .body-inner-cell {
    width: auto;
  }

  div[data-valuefield=''] [data-test-id='row-checkbox'] {
    width: 14px;
    height: 14px;
  }

  .accessorObjects-cell > .table-body-cell-styled {
    padding: 0;
  }

  .accessorObjects-cell > .table-body-cell-styled > div {
    display: flex;
    align-items: center;
    margin-left: 8px;
  }

  .accessorObjects-cell .accessorObjects {
    height: 24px;
  }

  .scenarioCount,
  .createdOn,
  .lastUpdatedOn {
    font-size: 14px;
    line-height: 20px;
    color: ${primary.gray(600)};
  }
`

const reloadPlans = () => {
  window.postMessage(
    {
      action: 'reloadPlans',
    },
    window.location.origin
  )
}

export const PlanListComponent: React.FC<IPlanListProps> = ({
  loadPermissionsFunction,
  redirectToLoginPage,
  createNewPlanFunction,
  currentUserID,
  newFilterSpPlansListSplit,
}) => {
  const planListRef = React.useRef<HTMLDivElement>(null)

  const [isLoadingPermissions, setIsLoadingPermissions] = React.useState<boolean>(true)
  const [isAccessDenied, setIsAccessDenied] = React.useState<boolean>(false)
  const [hasOnlyViewAccess, setHasOnlyViewAccess] = React.useState<boolean>(false)

  const [deletePlansData, setDeletePlansData] = React.useState<IDeletePlansData>({
    isDeleteConfirmDialogOpen: false,
    isDeleting: false,
    plansToDelete: [],
  })

  const [plansToShare, setPlansToShare] = React.useState<IPlanShareData[] | null>(null)
  const [isShareInViewMode, setIsShareInViewMode] = React.useState<boolean>(false)

  const messages = {
    newPlan: espClient.getTextSync('sp.plan.new', 'New plan'),
    noAccess: espClient.getHtmlSync(
      'messages.plan.noaccess',
      "You don't have access to Scenario Planner."
    ),
    administrator: espClient.getHtmlSync(
      'messages.plan.contact.administrator',
      'Contact your system administrator to request access.'
    ),
    delete: espClient.getHtmlSync('sp.dialog.header.plan.delete', 'Delete plan'),
    deletePlural: espClient.getHtmlSync('sp.dialog.header.plan.delete.plural', 'Delete plans'),
    deleteButton: espClient.getHtmlSync('form.button.delete', 'Yes, delete it'),
    cancelButton: espClient.getHtmlSync('form.button.cancel', 'Cancel'),
    deleteMessage: espClient.getHtmlSync(
      'sp.plan.delete.message',
      'When you delete a plan, you delete all the scenarios and initiatives associated with the plan. Are you sure you want to proceed?'
    ),
  }

  const onShareFunction = React.useCallback(
    (plans: IPlan[]) => {
      const plansToShare = plans.map(
        (plan) =>
          ({
            id: plan.id,
            name: plan.name,
            ownerId: plan.owner.ID,
            accessorObjects: plan.accessorObjects,
          } as IPlanShareData)
      )
      const result = plansToShare.filter((plan) => {
        return (
          plan.accessorObjects && hasEditAccess(currentUserID, plan.ownerId, plan.accessorObjects)
        )
      })
      const isEditMode = plansToShare.length > 0 && result.length == plansToShare.length
      setIsShareInViewMode(!isEditMode)
      setPlansToShare(plansToShare)
    },
    [currentUserID]
  )

  const onCancelShare = React.useCallback(() => setPlansToShare(null), [])

  const onConfirmShare = React.useCallback(() => {
    setPlansToShare(null)
    reloadPlans()
  }, [])

  const onDeleteFunction = React.useCallback((plans: IPlan[]) => {
    setDeletePlansData((prevState) => ({
      ...prevState,
      isDeleteConfirmDialogOpen: true,
      plansToDelete: plans,
    }))
  }, [])

  const onConfirmDelete = React.useCallback(() => {
    const ids = deletePlansData.plansToDelete.map((plan) => plan.id)
    setDeletePlansData((prevState) => ({
      ...prevState,
      isDeleting: true,
    }))

    deletePlans(ids)
      .then(() => {
        toast.success(
          espClient.getTextSync(
            ids.length > 1 ? 'sp.messages.plan.delete.plural' : 'sp.messages.plan.delete',
            ids.length > 1
              ? 'You have successfully deleted the selected plans'
              : 'You have successfully deleted the plan'
          )
        )
        reloadPlans()
      })
      .catch((err) => {
        toast.error(err.message)
      })
      .then(() => {
        setDeletePlansData((prevState) => ({
          ...prevState,
          isDeleteConfirmDialogOpen: false,
          isDeleting: false,
        }))
      })
  }, [deletePlansData.plansToDelete])

  const onDenyDelete = React.useCallback(() => {
    setDeletePlansData((prevState) => ({
      ...prevState,
      isDeleteConfirmDialogOpen: false,
      plansToDelete: [],
    }))
  }, [])

  const onAccessDenied = React.useCallback(() => {
    setIsAccessDenied(true)
  }, [])

  React.useEffect(() => {
    loadPermissionsFunction(null)
      .then((result) => {
        const permission = result.get('ESP_DATA')
        switch (permission) {
          case AccessMode.noAccess:
            onAccessDenied()
            break
          case AccessMode.view:
            setHasOnlyViewAccess(true)
            break
        }
      })
      .catch(() => {
        onAccessDenied()
      })
      .finally(() => {
        setIsLoadingPermissions(false)
      })
  }, [loadPermissionsFunction, onAccessDenied])

  const plugins = React.useMemo(
    () => [
      {
        importPromise: Promise.resolve(toolbar),
        initOptions: {
          toolbarId: 'plans',
        },
      },
      {
        importPromise: Promise.resolve(toolbarDefinitionPlugin),
        initOptions: {
          currentUserID,
          createNewPlanFunction,
          newPlanMessage: messages.newPlan,
          onShareFunction,
          onDeleteFunction,
          hasOnlyViewAccess,
        },
      },
      {
        importPromise: Promise.resolve(DataFetcherPlugin),
        initOptions: {
          onShareClick: onShareFunction,
          onAccessDeniedDetected: onAccessDenied,
          redirectToLoginPage,
          hasOnlyViewAccess,
        },
      },
      {
        importPromise: Promise.resolve(RendererPlugin),
      },
      {
        importPromise: newFilterSpPlansListSplit
          ? Promise.resolve(PlanDropdownFilterPlugin)
          : Promise.resolve(PlanFVGPlugin),
      },
    ],
    [hasOnlyViewAccess]
  )

  const metadata = React.useMemo(() => {
    return {
      ...metadataDefinition,
      noCheckboxColumn: hasOnlyViewAccess,
    }
  }, [hasOnlyViewAccess])

  const columns = React.useMemo(() => {
    let columns
    if (!hasOnlyViewAccess) {
      columns = columnsDefinition
    } else {
      columns = columnsDefinition.map((item) => ({
        ...item,
        index: item.index - 1,
      }))
      columns.shift()
    }

    return columns.map((column, index) => {
      const messageIndex = hasOnlyViewAccess ? index : index - 1
      const display =
        index === 0 && !hasOnlyViewAccess
          ? ' '
          : espClient.getTextSync(
              COLUMN_MESSAGES[messageIndex][0],
              COLUMN_MESSAGES[messageIndex][1]
            )
      return {
        ...column,
        display,
      }
    })
  }, [hasOnlyViewAccess])

  const data = React.useMemo(() => {
    return {
      metadata,
      columns,
      collapsedGroups: [],
      groups: {},
      collapsedRows: [],
      messageKeys: ['plan.list.pagination.plans', 'plan.list.pagination.plan'],
      rows: {},
    }
  }, [metadata, columns])

  const options = React.useRef({
    isInModal: false,
    isReactListToolbarsEnabled: true,
    listDataStore: getInstance(LIST_ID),
    bottomOffset: 0,
    useAvailableHeight: true,
  })

  return (
    <div id={LIST_ID} ref={planListRef} className={wrapperDivStyle}>
      <LoaderComponent isLoading={isLoadingPermissions} />
      {!isLoadingPermissions && (
        <LocalizationProvider clientFactory={redrockClientFactory}>
          <>
            {isAccessDenied ? (
              <ErrorPageComponent
                primaryMessage={messages.noAccess}
                secondaryMessage={messages.administrator}
                testId={'noaccess-message'}
              />
            ) : (
              <>
                {plansToShare && (
                  <ShareDialogComponent
                    plans={plansToShare}
                    onCancel={onCancelShare}
                    onShare={onConfirmShare}
                    isViewMode={isShareInViewMode}
                  />
                )}
                {deletePlansData.isDeleteConfirmDialogOpen && (
                  <ConfirmDialog
                    header={
                      deletePlansData.plansToDelete.length > 1
                        ? messages.deletePlural
                        : messages.delete
                    }
                    confirmText={messages.deleteButton}
                    denyText={messages.cancelButton}
                    onConfirmClick={onConfirmDelete}
                    confirmProps={{
                      disabled: deletePlansData.isDeleting,
                    }}
                    onDenyClick={onDenyDelete}
                    testID="delete-plans-confiramtion-dialog"
                  >
                    <Text>{messages.deleteMessage}</Text>
                  </ConfirmDialog>
                )}
                <ToolbarComponent />
                <List data={data} options={options.current} plugins={plugins} />
              </>
            )}
          </>
        </LocalizationProvider>
      )}
    </div>
  )
}
