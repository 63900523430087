import React, {useCallback, useMemo, useState} from 'react'
import {Localization, LocalizationProvider} from '@wf-mfe/localize-react'
import QuickFilter from '../../../import/plugins/toolbar/quick-filter/QuickFilter'
import {IDataTableAPI} from '@wftypes/list'
import {redrockClientFactory} from '../../../../shared/L10n'

function debounce(fn, delay) {
  let handle = 0
  return (...args) => {
    if (handle) {
      clearTimeout(handle)
      handle = 0
    }
    handle = (setTimeout(() => fn(...args), delay) as unknown) as number
  }
}

interface QuickFilterWidgetProps {
  listApi: IDataTableAPI
  placeholder: string
  placeholderMessageKey?: string
}
function QuickFilterWidget(props: QuickFilterWidgetProps) {
  const {placeholder, placeholderMessageKey = 'quickfilter.input', listApi} = props

  const [value, setValue] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  const throttledSetQuickFilterValue = useMemo(
    () =>
      debounce((value) => {
        listApi.quickFilter.setQuickFilterValue(value)
      }, 400),
    [listApi]
  )

  const handleChange = useCallback(
    (e) => {
      const value = e.target.value
      setValue(value)
      throttledSetQuickFilterValue(value)
    },
    [throttledSetQuickFilterValue]
  )

  const handleOpenStateChanged = useCallback(
    (isOpen) => {
      listApi.quickFilter.enableQuickFilter(isOpen)
      setIsOpen(isOpen)
    },
    [listApi]
  )

  const handleValueCleared = useCallback(() => {
    setValue('')
    listApi.quickFilter.setQuickFilterValue('')
  }, [listApi])

  const qfProps = {
    listApi,
    value,
    isOpen,
    handleOpenStateChanged,
    handleChange,
    handleValueCleared,
  }

  if (placeholder) {
    return <QuickFilter {...qfProps} placeholder={placeholder} />
  } else {
    return (
      <LocalizationProvider clientFactory={redrockClientFactory}>
        <Localization messageKey={placeholderMessageKey} fallback="Filter page">
          {(placeholder) => <QuickFilter {...qfProps} placeholder={placeholder()} />}
        </Localization>
      </LocalizationProvider>
    )
  }
}

export default React.memo(QuickFilterWidget)
