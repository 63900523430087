import {css} from 'emotion'
import {useState} from 'react'
import * as React from 'react'
import styled from 'react-emotion'
import {connect} from 'react-redux'
import {financialAccessDeniedSelector} from '../../../data/settings'
import {CARD_LG_SIZE, CARD_SM_SIZE} from '../../../shared/constants/Sizes'
import {IScenario} from '../../../shared/models/scenario'
import {isSafari} from '../../../shared/utilities'
import {ScenarioCardComponent} from './ScenarioCardComponent'

export interface IScenarioCardList {
  hasPublishedScenario: boolean
  scenarios: IScenario[]
  deletePlanFunction: () => void
  onSuccessfulSaveFunction: (planId: string, currentScenarioId: string | null) => void
  financialAccessDenied: boolean
}

interface ICardListContainerProps {
  financialAccessDenied: boolean
  scenariosLength: number
}

export const CARD_PADDING = 8
const CONTAINER_PADDING = 23
const HEADER_HEIGHT = isSafari() ? 77 : 86
const FOOTER_HEIGHT = 65
//const COMPARE_PAGE_FOOTER_HEADER_HEIGHT = `${HEADER_HEIGHT + FOOTER_HEIGHT}px`

const scenarioCardWrapperClass = css`
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  height: 100%;
  white-space: nowrap;
  margin: 0 -${CARD_PADDING}px;
  padding-top: 10px;
`

const CardListContainer = styled('div')<ICardListContainerProps>`
  padding: ${CONTAINER_PADDING}px 16px 0px;
  min-width: 1000px;
  position: relative;
  height: calc(100% - ${HEADER_HEIGHT + FOOTER_HEIGHT}px);
  overflow: auto;
  padding-bottom: 0;
  & > div {
    height: auto;
    min-height: 100%;
  }

  .${scenarioCardWrapperClass} {
    align-items: ${(props) => (props.financialAccessDenied ? 'flex-start' : 'initial')};
  }

  ${(props) =>
    props.scenariosLength > 1 &&
    `
        .${scenarioCardWrapperClass} {
            padding-left: ${CARD_SM_SIZE}px;
        
            @media (min-width: 1441px) {
                padding-left: ${CARD_LG_SIZE}px;
            }
        }
    `}
`

const hideScrollClass = css`
  overflow: hidden;
`

export const ScenarioCardList: React.FunctionComponent<IScenarioCardList> = (props) => {
  const scrollContainer: React.RefObject<HTMLDivElement> = React.useRef(null)
  const [isScrolled, setScrolled] = React.useState(false)
  const [isMoreIconClicked, setIsMoreIconClicked] = React.useState(false)
  const [focusedScenarioId, setFocusedScenarioId] = useState<string | null>(null)

  const onScrollHandler = (event) => {
    if (event.currentTarget.scrollLeft > 10 && !isScrolled) {
      setScrolled(true)
    } else if (event.currentTarget.scrollLeft < 10 && isScrolled) {
      setScrolled(false)
    }
  }

  const scrollToCopiedCard = () => {
    setTimeout(() => {
      const scrollableContainer = (scrollContainer &&
        scrollContainer.current) as HTMLDivElement | null
      if (scrollableContainer !== null) {
        if (isSafari()) {
          scrollToCopiedCardForSafariAndIE11(scrollableContainer)
        } else {
          scrollableContainer.scroll({
            left: scrollableContainer.scrollWidth,
            top: 0,
            behavior: 'smooth',
          })
        }
      }
    }, 100)
  }

  const scrollToCopiedCardForSafariAndIE11 = (element: HTMLDivElement): void => {
    const intervalId = setInterval(() => {
      element.scrollLeft += 80

      if (element.scrollLeft === element.scrollWidth - element.offsetWidth) {
        clearInterval(intervalId)
      }
    }, 15)
  }

  return (
    <CardListContainer
      financialAccessDenied={props.financialAccessDenied}
      scenariosLength={props.scenarios.length}
    >
      <div
        className={`${scenarioCardWrapperClass} ${isMoreIconClicked ? hideScrollClass : ''}`}
        ref={scrollContainer}
        onScroll={onScrollHandler}
        data-testid="scenario-cards"
      >
        {props.scenarios.map((scenario, index) => {
          return (
            <ScenarioCardComponent
              scenarioId={scenario.id}
              setFocusedScenarioId={setFocusedScenarioId}
              focusedScenarioId={focusedScenarioId}
              hasPublishedScenario={props.hasPublishedScenario}
              financialAccessDenied={props.financialAccessDenied}
              setIsMoreIconClicked={setIsMoreIconClicked}
              onSuccessfulSaveFunction={props.onSuccessfulSaveFunction}
              scenario={scenario}
              key={scenario.id}
              deletePlanFunction={props.deletePlanFunction}
              scrollToEndFunction={scrollToCopiedCard}
              isScrolled={index === 0 ? isScrolled : false}
              scenariosLength={props.scenarios.length}
            />
          )
        })}
      </div>
    </CardListContainer>
  )
}
const mapStateToProps = (state) => ({
  financialAccessDenied: financialAccessDeniedSelector(state),
})

export const ScenarioCardListComponent = connect(mapStateToProps)(ScenarioCardList)
