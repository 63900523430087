import * as React from 'react'
import {connect} from 'react-redux'
import {currencySelector, timelineModeSelector} from '../../../../../data/plan'

import {IVisibleConflictInfoByDate} from '../../../../../shared/helpers/TimelineBarHelper'
import {TimelineMode} from '../../../../../shared/enums'
import {formatCurrency} from '../../../../../shared/utilities'
import {Text} from '@phoenix/all'

export interface IVisibleConflictInfoProps {
  visibleConflictInfo: IVisibleConflictInfoByDate
  currency: string
  timelineMode: TimelineMode
}

export const VisibleConflictInfo: React.FC<IVisibleConflictInfoProps> = React.memo(
  ({visibleConflictInfo, currency, timelineMode}) => {
    const label = React.useMemo(() => {
      if (visibleConflictInfo.isBudgetInfo) {
        return formatCurrency(+visibleConflictInfo.details, currency, true)
      }

      return timelineMode === TimelineMode.MONTHLY
        ? visibleConflictInfo.details
        : (visibleConflictInfo.details as string).split(' ')[0] // visibleConflictInfo.details in this case always a string
    }, [visibleConflictInfo, currency, timelineMode])

    return <Text.Small className="role-contouring-name">{label}</Text.Small>
  }
)

VisibleConflictInfo.displayName = 'VisibleConflictInfo'

const mapStateToProps = (state) => ({
  currency: currencySelector(state),
  timelineMode: timelineModeSelector(state),
})

export const VisibleConflictInfoComponent = connect(mapStateToProps)(VisibleConflictInfo)
