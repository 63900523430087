import {createSelector} from 'reselect'
import {AccessMode} from '../../../shared/enums'
import {financialAccessSelector} from './financialAccessSelector'

export const financialAccessDeniedSelector = createSelector(
  financialAccessSelector,
  (accessMode: AccessMode | null) => {
    return accessMode === AccessMode.noAccess
  }
)
