import {alerts, primary, Text} from '@phoenix/all'
import styled, {css, keyframes} from 'react-emotion'
import {IScenarioRole} from '../../../../../../shared/models/scenario'

export const LEFT_BORDER_WIDTH = 1

export const fillingInputClass = css`
  border-style: dashed;
  position: relative;
  z-index: 5;
  background-color: rgba(0, 120, 215, 0.05) !important;
`

const dragEndingKeyframes = keyframes`
    from {
        background-color: rgba(0, 120, 215, 0.05);
        border-color: ${primary.blue(400)};
    to {
        background-color: transparent;
        border-color: ${primary.gray(400)};
    }
`

export const dragEndingClassName = css`
  animation: ${dragEndingKeyframes} 1s ease infinite;
  border-width: 1px;
  border-style: solid;
`

export const inputsWrapperClass = css`
  display: flex;
`

export const CellStyled = styled('div')<{cellWidth: number}>`
  width: ${({cellWidth}) => cellWidth}px;
  margin-left: -1px;

  &:first-of-type {
    margin: 0;
  }

  .number-input {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      opacity: 0;
      right: 0;
      width: 1px;
      height: 100%;
      background-color: ${alerts.info()};
      z-index: 1;
    }

    &.focused {
      border-right: 0 !important;
      &:after {
        opacity: 1;
      }

      input {
        box-shadow: none !important;
      }
    }
  }

  .number-input,
  .number-input input {
    border-radius: 0;
    height: 32px;
  }

  &:first-child {
    .number-input,
    .number-input input {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
  }

  &:last-child {
    .number-input {
      &:after {
        height: calc(100% - 2px);
        top: 1px;
        right: 0;
      }
    }
    .number-input,
    .number-input input {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }
  input:focus {
    box-shadow: none;
  }
`

export const SubValueStyled = styled(Text)<{color: string | null}>`
  color: ${({color}) => (color ? color : primary.gray(900))};
  display: block;
  padding-left: 13px;
  margin-top: 5px;

  &:last-child {
    margin-top: 9px;
  }
`
export const inputItemClass = css`
  position: relative;
`

export const getDraggableElementStyle = (top) => css`
  width: 5px;
  height: 5px;
  background-color: ${primary.blue(400)};
  cursor: ew-resize;
  top: -${top}px;
`

export interface IRolesDetails {
  [roleId: string]: IScenarioRole & {isNew?: boolean}
}

export const getRolesDetailsFromState = (roles: IScenarioRole[]): IRolesDetails => {
  const rolesDetails = {}
  // TODO may be need to uses deepClone
  roles.forEach((role) => {
    rolesDetails[role.role.id] = {
      ...role,
      availablePerPeriod: {
        ...role.availablePerPeriod,
      },
      requiredPerPeriod: {
        ...role.requiredPerPeriod,
      },
      role: {
        ...role.role,
      },
    }
  })

  return rolesDetails
}

export const getRolesIds = (roles: IScenarioRole[]): string[] => {
  return roles.map((role) => role.role.id)
}

//TODO roleIds and roleDetails can be combined to a map
export const getNewAndUpdatedRoles = (
  roleIds: string[],
  rolesDetails: IRolesDetails,
  addedRoleIds: Set<string>
): {
  newRoles: IScenarioRole[]
  updatedRoles: IScenarioRole[]
} => {
  const newRoles: IScenarioRole[] = []
  const updatedRoles: IScenarioRole[] = []

  roleIds.forEach((roleId) => {
    if (addedRoleIds.has(roleId)) {
      newRoles.push(rolesDetails[roleId])
    } else {
      updatedRoles.push(rolesDetails[roleId])
    }
  })

  return {
    newRoles,
    updatedRoles,
  }
}

export const getDeletedRoleIds = (
  rolesDetails: IRolesDetails,
  rolesFromState: IScenarioRole[],
  addedRoleIds: Set<string>
): Set<string> => {
  const deletedRoles: Set<string> = new Set()

  rolesFromState.forEach((role) => {
    if (!rolesDetails[role.role.id] || addedRoleIds.has(role.role.id)) {
      deletedRoles.add(role.role.id)
    }
  })

  return deletedRoles
}
