import {FIRST, LIMIT, SORT as SORT_SUFFIX} from 'workfront-api-constants'

export function resetSortPaginationInfo(queryParams: {[key: string]: unknown}): void {
  delete queryParams[FIRST]
  delete queryParams[LIMIT]
  for (const key in queryParams) {
    if (key.indexOf(SORT_SUFFIX) > 0) {
      delete queryParams[key]
    }
  }
}
