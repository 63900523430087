import {ICombinedPlanState} from '../../types'
import {currentScenarioInitiativesSelector} from './currentScenarioInitiativesSelector'
import {isInitiativeSelectedSelector} from '../../plan/selectors/isInitiativeSelectedSelector'
import {IInitiative} from '../../../shared/models'
import {selectedInitiativeCountSelector} from '../../plan/selectors/selectedInitiativeCountSelector'
import {isEditSidebarOpenSelector} from '../../viewOptions'

export const isInitiativeDraggableSelector = (
  state: ICombinedPlanState,
  initiative: IInitiative
) => {
  const areMultipleInitiativesPresent = currentScenarioInitiativesSelector(state).length > 1

  if (!areMultipleInitiativesPresent) {
    return false
  }

  const isEditSidebarOpen = isEditSidebarOpenSelector(state)
  const areAnyInitiativesSelected = selectedInitiativeCountSelector(state) > 0
  const isInitiativeSelected = isInitiativeSelectedSelector(state, {initiative})

  return !isEditSidebarOpen && (!areAnyInitiativesSelected || isInitiativeSelected)
}
