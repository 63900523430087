import * as React from 'react'
import {css} from 'react-emotion'
import {Text} from '@phoenix/all'
import type {TToolbarWidgetProps} from '@wf-mfe/lists'
import {Localization, LocalizationProvider} from '@wf-mfe/localize-react'
import {IDataTableAPI} from '@wftypes/list'
import {SELECTION_COUNT_PLUGIN_NAME} from '../constants'
import {redrockClientFactory} from '../../../../shared/L10n'

const selectionCountStyle = css`
  padding-left: 16px;
`

type SelectionCountWidgetProps = TToolbarWidgetProps<{
  listApi: IDataTableAPI
  selectionLimit: number
  selectedMsg: string
  selectionLimitMsg: string
}>

export const SelectionCountWidget: React.FC<SelectionCountWidgetProps> = ({
  listApi,
  selectionLimit,
  selectedMsg,
  selectionLimitMsg,
}) => {
  const [selectedCount, setSelectedCount] = React.useState(0)
  const [availableItems, setAvailableItems] = React.useState(0)
  React.useEffect(() => {
    return listApi.interceptors.registerInterceptor(
      SELECTION_COUNT_PLUGIN_NAME,
      listApi.interceptors.events.SELECTION_CHANGED,
      function handleSelectionChange() {
        setSelectedCount(listApi.selection.getSelectedRowCount())
      }
    )
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  React.useEffect(() => {
    return listApi.interceptors.registerInterceptor(
      SELECTION_COUNT_PLUGIN_NAME,
      listApi.interceptors.events.AFTER_LOAD_PAGE_ITEMS,
      function handleAfterLoadPageItems({data}) {
        setAvailableItems(data.pagination.count)
      }
    )
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  const selectedMsgPlural =
    availableItems <= 1 ? `${selectedMsg}.singular` : `${selectedMsg}.plural`

  return (
    <LocalizationProvider clientFactory={redrockClientFactory}>
      <Localization messageKeys={[selectedMsgPlural, selectionLimitMsg]}>
        {({_t}) => {
          if (availableItems <= selectionLimit) {
            return (
              <Text className={selectionCountStyle}>
                {_t[selectedMsgPlural]('{0} out of {1} project selected.', [
                  selectedCount,
                  availableItems,
                ])}
              </Text>
            )
          } else {
            return (
              <Text className={selectionCountStyle}>
                {_t[selectedMsgPlural]('{0} out of {1} project selected.', [
                  selectedCount,
                  availableItems,
                ]) +
                  ' ' +
                  _t[selectionLimitMsg]('Choose up to {0} projects.', selectionLimit)}
              </Text>
            )
          }
        }}
      </Localization>
    </LocalizationProvider>
  )
}
