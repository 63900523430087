import * as React from 'react'

const useHandleItemClick: (onFVGChange) => (index, item) => void = (onFVGChange) => {
  return React.useCallback(
    (index, item) => {
      onFVGChange(item)
    },
    [onFVGChange]
  )
}
export default useHandleItemClick
