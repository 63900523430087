import * as React from 'react'
import {css} from 'react-emotion'
import {StickyAccordionComponent} from '@workfront/triton-filter-ui/dist/esm/components/StickyAccordionComponent'

const pushPinListContainer = css`
  height: calc(100% - 50px);
  max-height: calc(100% - 50px);
  overflow-y: auto;
`

export default function PushPinListWrapper(props: Record<string, unknown>): JSX.Element {
  return (
    <div className={pushPinListContainer}>
      <StickyAccordionComponent listData={props} />
    </div>
  )
}
