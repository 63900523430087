import {ShareAccessActionType} from '@workfront/sp-sharing-dialog'
import {IAccessorObject} from '../types'

export const hasEditAccess = (
  currentUserID: string,
  planOwnerId: string,
  accessorObjects: IAccessorObject[]
): boolean => {
  if (planOwnerId === currentUserID) {
    return true
  }
  const result = accessorObjects.filter((accessorObject) => {
    return (
      accessorObject.guid == currentUserID && accessorObject.access == ShareAccessActionType.EDIT
    )
  })
  return result.length > 0
}
