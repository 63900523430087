import {useLocalization} from '@wf-mfe/localize-react'
import {css} from 'emotion'
import * as React from 'react'
import {connect} from 'react-redux'

import MoreStrokeIcon from 'phoenix-icons/dist/MoreStrokeIcon.js'
import {
  primary,
  secondary,
  ConfirmDialog,
  LabelDropdown,
  Option,
  toast,
  A,
  Text,
} from '@phoenix/all'
import {copyScenario, currentPlanSelector, deleteScenario} from '../../../../data/plan'
import {changeCurrentScenario} from '../../../../data/scenario'
import {viewAccessSelector} from '../../../../data/settings'
import {ActionType} from '../../../../shared/enums'
import {hasPublishedScenario, track} from '../../../../shared/helpers'
import {IScenario} from '../../../../shared/models/scenario'
import {peopleCostOnConflictCalculationSelector} from '../../../../data/plan/selectors/peopleCostOnConflictCalculationSelector'
import {Translate} from '../../../shared/Translate'
import {ConfirmationDialogHeaderComponent} from '../../../shared/ConfirmationDialogHeaderComponent'

interface IScenarioCardHeader {
  scenario: IScenario
  isViewMode: boolean
  isSingleScenario: boolean
  hasPlanPublishedScenario: boolean
  copyScenarioFunction: (scenario: IScenario) => void
  deleteCardAnimation: (scenario: IScenario, callback) => void
  deleteScenarioFunction: (scenario: IScenario) => void
  changeCurrentScenarioFunction: (
    scenario: IScenario,
    usePeopleCostOnConflictCalculation: boolean
  ) => void
  scrollToEndFunction: () => void
  deletePlanFunction: () => void
  setIsMoreIconClicked: (isMoreIconClicked: boolean) => void
  setHovered: (isHovered: boolean) => void
  usePeopleCostOnConflictCalculation: boolean
}

const scenarioNameClass = css`
  font-size: 16px;
  &:visited {
    color: ${primary.blue(400)};
  }
  &:hover {
    color: ${primary.blue(600)};
  }
  &:active {
    color: ${primary.blue(600)};
  }
  &:focus {
    color: ${primary.blue(600)};
  }
`

const containerClass = css`
  display: flex;
  margin-bottom: 3px;
`

const nameContainerClass = css`
  flex-grow: 1;
`
const publishedContainerClass = css`
  display: flex;
  span {
    color: ${secondary.green()};
    text-transform: uppercase;
    margin-right: 10px;
    padding-top: 4px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 15px;
  }
`
export const CardHeader: React.FunctionComponent<IScenarioCardHeader> = (props) => {
  const [isConfirmDialogOpen, setConfirmDialog] = React.useState(false)
  const [isMoreClicked, setIsMoreClicked] = React.useState(false)

  const {
    scenario,
    copyScenarioFunction,
    deleteScenarioFunction,
    deleteCardAnimation,
    changeCurrentScenarioFunction,
    isSingleScenario,
    deletePlanFunction,
    scrollToEndFunction,
    isViewMode,
    hasPlanPublishedScenario,
  } = props
  const href = window.location.href.replace('compare', scenario.id)
  const {order} = scenario

  const onNameClick = (event) => {
    event.preventDefault()
    changeCurrentScenarioFunction(scenario, props.usePeopleCostOnConflictCalculation)
  }

  const onPerformAction = (value) => {
    if (value === ActionType.COPY) {
      track('Copy scenario from card')
      copyScenarioFunction(scenario)
      scrollToEndFunction()
    } else if (value === ActionType.DELETE) {
      setConfirmDialog(true)
    }

    props.setHovered(false)
  }

  const [initialScenarioMsg] = useLocalization('sp.scenario.initial', 'Initial scenario')
  const [scenarioMsg] = useLocalization('sp.scenario', 'Scenario {0}', order + 1)
  const deletedScenarioName = order === 0 ? initialScenarioMsg : scenarioMsg
  const [successfullDeleteMsg] = useLocalization(
    'messages.scenario.delete',
    'You have successfully deleted {0}',
    deletedScenarioName
  )

  const performDeleteScenario = () => {
    setConfirmDialog(false)
    deleteCardAnimation(scenario, deleteScenarioFunction)
    toast.success(successfullDeleteMsg)
  }

  const confirm = () => {
    if (!isSingleScenario) {
      track('Delete scenario from card')
      performDeleteScenario()
    } else {
      setConfirmDialog(false)
      deletePlanFunction()
    }
  }

  const cancel = () => {
    setConfirmDialog(false)
  }

  const moreIconOnClickHandler = () => {
    setIsMoreClicked(true)
    props.setIsMoreIconClicked(true)
  }

  let confirmationMessage
  if (isSingleScenario) {
    confirmationMessage = (
      <Translate messageKey={'sp.scenario.actions.delete.lastScenario.confirmation'} />
    )
  } else if (scenario.isPublished) {
    confirmationMessage = (
      <Translate messageKey={'sp.scenario.actions.delete.published.confirmation'} />
    )
  } else if (scenario.isInitial && !hasPlanPublishedScenario) {
    confirmationMessage = (
      <Translate messageKey={'sp.scenario.actions.delete.initial.confirmation'} />
    )
  } else {
    confirmationMessage = <Translate messageKey={'sp.scenario.actions.delete.confirmation'} />
  }

  React.useEffect(() => {
    const clickHandler = () => {
      if (isMoreClicked) {
        props.setIsMoreIconClicked(false)
        setIsMoreClicked(false)
      }
    }

    document.addEventListener('click', clickHandler)

    return () => {
      document.removeEventListener('click', clickHandler)
    }
  }, [isMoreClicked])

  return (
    <React.Fragment>
      <div className={containerClass}>
        <div className={nameContainerClass}>
          <A
            className={`${scenarioNameClass} scenario-name`}
            href={href}
            onClick={onNameClick}
            data-testid={`scenario-name-${order}`}
          >
            {order === 0 ? (
              <Translate messageKey={'sp.scenario.initial'} />
            ) : (
              <Translate messageKey={'sp.scenario'} args={[order + 1]} />
            )}
          </A>
        </div>
        {!isViewMode && (
          <div className={publishedContainerClass}>
            {scenario.isPublished && (
              <span data-testid={'is-published'}>
                <Translate messageKey={'scenario.published'} />
              </span>
            )}
            <LabelDropdown
              testID={`scenario-actions-${order}`}
              trigger={<MoreStrokeIcon onClick={moreIconOnClickHandler} />}
              onChange={onPerformAction}
            >
              <Option value={ActionType.COPY} withPadding={false} testID={`scenario-copy-${order}`}>
                <Translate messageKey={'scenario.actions.copy'} />
              </Option>
              <Option
                value={ActionType.DELETE}
                withPadding={false}
                testID={`scenario-delete-${order}`}
              >
                <Translate messageKey={'scenario.actions.delete'} />
              </Option>
            </LabelDropdown>
          </div>
        )}
      </div>
      {isConfirmDialogOpen && (
        <ConfirmDialog
          header={
            <ConfirmationDialogHeaderComponent messageKey={'sp.dialog.header.scenario.delete'} />
          }
          confirmText={<Translate messageKey={'form.button.delete'} />}
          denyText={<Translate messageKey={'form.button.cancel'} />}
          onConfirmClick={confirm}
          onDenyClick={cancel}
        >
          <Text>{confirmationMessage}</Text>
        </ConfirmDialog>
      )}
    </React.Fragment>
  )
}
const mapStateToProps = (state) => ({
  isViewMode: viewAccessSelector(state),
  isSingleScenario: currentPlanSelector(state)!.scenarios.length === 1,
  hasPlanPublishedScenario: hasPublishedScenario(currentPlanSelector(state)!),
  usePeopleCostOnConflictCalculation: peopleCostOnConflictCalculationSelector(state),
})
const mapDispatchToProps = (dispatch) => ({
  copyScenarioFunction: (scenario) => dispatch(copyScenario(scenario)),
  deleteScenarioFunction: (scenario) => dispatch(deleteScenario(scenario)),
  changeCurrentScenarioFunction: (scenario, usePeopleCostOnConflictCalculation) =>
    dispatch(changeCurrentScenario(scenario, null, usePeopleCostOnConflictCalculation)),
})

export const CardHeaderComponent = connect(mapStateToProps, mapDispatchToProps)(CardHeader)
