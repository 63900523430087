import {PluginBase, bindPluginClass} from '@workfront/list-plugin-utils'
import {IDataTableAPI} from '@wftypes/list'
import {ObjectLink, objectLinkMatcher} from '../../shared/widgets/ObjectLink'
import {sharedUserMatcher, SharedUserWidget} from './widgets/SharedUserWidget'

class RendererPlugin extends PluginBase {
  static PLUGIN_NAME = 'RendererPlugin'
  static PLUGIN_DEPS = []

  init(listApi: IDataTableAPI) {
    this.addShutdownRoutine(
      listApi.renderers.registerViewRenderer('component.planname', ObjectLink),
      listApi.renderers.registerViewRenderer('component.owner', ObjectLink),
      listApi.renderers.registerViewRenderer('component.sharedwith', SharedUserWidget),

      listApi.quickFilter.registerQuickFilterMatchers({
        'component.planname': objectLinkMatcher,
        'component.owner': objectLinkMatcher,
        'component.sharedwith': sharedUserMatcher,
      })
    )
  }
}

export default bindPluginClass(
  () => new RendererPlugin(),
  RendererPlugin.PLUGIN_NAME,
  RendererPlugin.PLUGIN_DEPS || []
)
