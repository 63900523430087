import {FVGPlugin} from '../../../../shared/toolbar/filters/FVGPlugin'
import {bindPluginClass} from '@workfront/list-plugin-utils'
import loadFilters from './loadFilters'
import {IDataTableAPI} from '@wftypes/list'
import {FilterChangeParams} from '../../../../shared/toolbar/filters/types'
import DataFetcherPlugin, {DataFetcherAPI} from '../../dataFetcher'
import {getStorageUtil} from '@workfront/storage'
import IconWidget from '../../../../shared/toolbar/widgets/IconWidget'
import SeparatorWidget from '../../../../shared/toolbar/widgets/SeparatorWidget'
import * as React from 'react'
import FVGOverlay from '../../../../shared/toolbar/filters/FVGOverlay'
import {IToolbarApi} from '@wf-mfe/lists'
import {TOOLBAR_PLUGIN_NAME} from '../../../../shared/toolbar/constants'
import FilterIcon from 'phoenix-icons/dist/FilterIcon.js'
import {FILTER_LOCAL_STORAGE_KEY} from '../../constants'
const storageUtil = getStorageUtil()

export class PlanFVGPlugin extends FVGPlugin {
  lastSelectedFilterName: string | null = null

  setDefaultFilter = (filter: {id: string; name: string}) => {
    if (filter.id === '') {
      this.lastSelectedFilterName = ''
      this.filterWidgetApi!.setTextMessageKey('all')
    } else {
      this.lastSelectedFilterName = filter.name
      this.filterWidgetApi!.setText(filter.name)
      this.overlayComponentRef.current!.setCurrentFilterID(filter.id)
    }
  }
  handleFilterChange = (listApi: IDataTableAPI) => (filter: FilterChangeParams) => {
    this.handleClosePanel('')
    const currentFilterID = this.overlayComponentRef.current!.getCurrentFilterID()
    this.overlayComponentRef.current!.setCurrentFilterID(filter.ID)
    if (currentFilterID !== filter.ID && filter.ID !== undefined) {
      storageUtil.set(FILTER_LOCAL_STORAGE_KEY, filter.ID)
      const dataFetcherApi = listApi.getPluginAPI<DataFetcherAPI>(DataFetcherPlugin.getName())
      this.lastSelectedFilterName = filter.text
      this.filterWidgetApi!.setText(filter.text)
      dataFetcherApi!.filterListData(filter)
    }
  }

  ready(listApi: IDataTableAPI) {
    const toolbarApi = listApi.getPluginAPI<IToolbarApi>(TOOLBAR_PLUGIN_NAME)!
    const dataFetcherApi = listApi.getPluginAPI<DataFetcherAPI>(DataFetcherPlugin.getName())!
    const WIDGET_TYPE_ICON = 'icon'

    this.addShutdownRoutine(
      toolbarApi.registerWidget(WIDGET_TYPE_ICON, IconWidget),
      toolbarApi.registerWidget(toolbarApi.SEPARATOR_WIDGET_TYPE, SeparatorWidget),
      listApi.overlays.appendOverlay(listApi.overlays.locations.RIGHT_SIDEBAR, () => {
        return (
          <FVGOverlay
            key="fvg-selection-overlay"
            ref={this.overlayComponentRef}
            listApi={listApi}
            onClosePanel={this.handleClosePanel}
            loadFilters={this.loadFilters()}
            onFilterChange={this.handleFilterChange(listApi)}
          />
        )
      })
    )

    listApi.overlays.forceUpdateOverlayContainer(listApi.overlays.locations.RIGHT_SIDEBAR)

    let position = 900
    this.addShutdownRoutine(
      toolbarApi.registerCommand({
        id: 'open-filter-selection-panel',
        requirements: {},
        executor: this.openFilterSelectionPanel,
      })
    )

    this.addShutdownRoutine(
      toolbarApi.insertComponent(toolbarApi.ToolbarLocations.secondary, {
        id: 'separator-before-filter-icon',
        position: position++,
        widgetType: toolbarApi.SEPARATOR_WIDGET_TYPE,
      }),
      toolbarApi.insertComponent(toolbarApi.ToolbarLocations.secondary, {
        id: 'filter-icon',
        position: position++,
        primaryCommandId: 'open-filter-selection-panel',
        widgetType: WIDGET_TYPE_ICON,
        widgetOptions: {
          dataTestId: 'open-filter-selection-panel',
          icon: FilterIcon,
          showText: true,
          showBadge: false,
          isCheckable: true,
          getApi: (widgetApi) => {
            this.filterWidgetApi = widgetApi
            if (this.lastSelectedFilterName !== null) {
              // We have to reset the name, otherwise, whenever a toolbar action is performed, the filter name is going to be cleared
              if (this.lastSelectedFilterName === '') {
                this.filterWidgetApi!.setTextMessageKey('all')
              } else {
                this.filterWidgetApi!.setText(this.lastSelectedFilterName)
              }
            }
          },
          labelMessageKey: 'filter',
        },
      }),
      toolbarApi.insertComponent(toolbarApi.ToolbarLocations.secondary, {
        id: 'separator-after-filter-icon',
        position: position++,
        widgetType: toolbarApi.SEPARATOR_WIDGET_TYPE,
      })
    )
    dataFetcherApi.getDefaultFilterPromise().then((filter) => {
      setTimeout(() => {
        this.setDefaultFilter({
          id: filter.id,
          name: filter.name ?? '',
        })
      }, 0)
    })
  }
  loadFilters = () => {
    return loadFilters
  }
}

export default bindPluginClass(
  () => new PlanFVGPlugin(),
  FVGPlugin.PLUGIN_NAME,
  FVGPlugin.PLUGIN_DEPS || []
)
