import {useDispatch, useSelector} from 'react-redux'
import {css} from 'emotion'
import * as React from 'react'
import {primary, Text} from '@phoenix/all'
import {changePlanSettings} from '../../data'
import {hoursForMeasurementsSelector} from '../../data/plan/selectors/hoursForMeasurementsSelector'
import {Translate} from '../shared/Translate'
import {translate} from '../../shared/utilities/TranslateHelper'

const radioButtonGroupLabelClass = css`
  margin: 30px 0 18px;
`

const radioGroupClass = css`
  display: flex;
  align-items: center;

  > div:first-child {
    margin-right: 24px;
  }

  label {
    display: flex;
    align-items: center;
    color: ${primary.storm()};
    margin-bottom: 8px;
    align-self: start;

    input {
      margin: 3px 12px 0 0;
      height: 16px;
      width: 16px;
      border-radius: 4px;
      opacity: 1;
      flex-shrink: 0;
    }

    span {
      color: ${primary.storm()};
      font-family: 'Proxima Nova', 'Tahoma', 'Verdana', 'Arial', sans-serif;
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.25;
      margin: 0;
      padding: 2px 0;
    }
  }
`

export const FTEHoursSettingComponent: React.FC = React.memo(() => {
  const dispatch = useDispatch()
  const useHoursForMeasurements = useSelector(hoursForMeasurementsSelector)

  const onChangeHandler = ({target}): void => {
    const planSettings = {
      useHoursForMeasurements: target.value === 'hours',
    }
    dispatch(changePlanSettings(planSettings))
  }
  return (
    <div role="radiogroup" aria-labelledby="fte-hours-label">
      <p className={radioButtonGroupLabelClass}>
        <Text.Small id="fte-hours-label">
          <Translate messageKey="fte.hours.label" />
        </Text.Small>
      </p>

      <div className={radioGroupClass}>
        <div>
          <label>
            <input
              id="fte-radio-button"
              type="radio"
              name="fteHours"
              data-testid="fte-radio-button"
              onChange={onChangeHandler}
              checked={!useHoursForMeasurements}
              aria-label={translate('header.widget.role.job.fte.long')}
              value="fte"
            />
            <span>{translate('header.widget.role.job.fte')}</span>
          </label>
        </div>

        <div>
          <label>
            <input
              id="hours-radio-button"
              type="radio"
              name="fteHours"
              data-testid="hours-radio-button"
              onChange={onChangeHandler}
              checked={useHoursForMeasurements}
              aria-label={translate('hours')}
              value="hours"
            />
            <span>{translate('hours')}</span>
          </label>
        </div>
      </div>
    </div>
  )
})

FTEHoursSettingComponent.displayName = 'FTEHoursSettingComponent'
