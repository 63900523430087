import {primary} from '@phoenix/all'
import {css} from 'emotion'
import * as React from 'react'
import {Translate} from '../../../shared/Translate'

interface IHeaderDescriptionComponentProps {
  messageKey: string
  args?: string[]
  testID: string
}

const widgetDescriptionClass = css`
  color: ${primary.gray(400)};
  font-size: 11px;
  margin-bottom: 8px;
  padding-left: 8px;
`

export const HeaderDescriptionComponent: React.FC<IHeaderDescriptionComponentProps> = React.memo(
  ({messageKey, args, testID}) => {
    return (
      <div className={widgetDescriptionClass} data-testid={`${testID}-widget-description`}>
        <Translate messageKey={messageKey} args={args} />
      </div>
    )
  }
)

HeaderDescriptionComponent.displayName = 'HeaderDescriptionComponent'

HeaderDescriptionComponent.defaultProps = {
  args: [],
}
