export const CHANGE_CURRENT_SCENARIO = 'CHANGE_CURRENT_SCENARIO'
export const UPDATE_CURRENT_SCENARIO_ACCORDING_PLAN_STARTDATE_AND_DURATION =
  'UPDATE_CURRENT_SCENARIO_ACCORDING_PLAN_STARTDATE_AND_DURATION'
export const CHANGE_LAST_SCENARIO_AFTER_COPY = 'CHANGE_LAST_SCENARIO_AFTER_COPY'
export const TOGGLE_CONFLICTS = 'TOGGLE_CONFLICTS'

export const ADD_INITIATIVE = 'ADD_INITIATIVE'
export const UPDATE_INITIATIVE = 'UPDATE_INITIATIVE'
export const UPDATE_INITIATIVE_NAME = 'UPDATE_INITIATIVE_NAME'
export const UPDATE_INITIATIVE_DATES = 'UPDATE_INITIATIVE_DATES'
export const UPDATE_INITIATIVE_AFTER_DRAG_DROP = 'UPDATE_INITIATIVE_AFTER_DRAG_DROP'
export const UPDATE_INITIATIVE_AND_RECALCULATE_CONFLICTS =
  'UPDATE_INITIATIVE_AND_RECALCULATE_CONFLICTS'
export const REORDER_INITIATIVES = 'REORDER_INITIATIVES'
export const COPY_INITIATIVES = 'COPY_INITIATIVES'
export const PRIORITIZE_INITIATIVES = 'PRIORITIZE_INITIATIVES'
export const DELETE_INITIATIVE = 'DELETE_INITIATIVE'
export const DELETE_INITIATIVES = 'DELETE_INITIATIVES'

// Scenario Job Roles
export const ADD_SCENARIO_ROLE = 'ADD_SCENARIO_ROLE'
export const UPDATE_SCENARIO_ROLE = 'UPDATE_SCENARIO_ROLE'
export const DELETE_SCENARIO_ROLE = 'DELETE_SCENARIO_ROLE'

// Scenario Budget
export const UPDATE_SCENARIO_BUDGET = 'UPDATE_SCENARIO_BUDGET'

// Conflicts
export const RESOLVE_CONFLICTS = 'RESOLVE_CONFLICTS'
export const RECALCULATE_BUDGET_CONFLICTS = 'RECALCULATE_BUDGET_CONFLICTS'

// Import
export const IMPORT_INITIATIVES = 'IMPORT_INITIATIVES'
export const IMPORT_INITIATIVES_FAIL = 'IMPORT_INITIATIVES_FAIL'
export const SET_IMPORTED_INITIATIVES_IDS = 'SET_IMPORTED_INITIATIVES_IDS'

// Initiative list
export const SET_UNSAVED_CHANGES_DIALOG_VISIBILITY = 'SET_UNSAVED_CHANGES_DIALOG_VISIBILITY'
export const MARK_DRAGGABLE_INITIATIVES = 'MARK_DRAGGABLE_INITIATIVES'
export const FINALIZE_BULK_DRAG = 'FINALIZE_BULK_DRAG'

// Animations
export const REMOVE_INITIATIVES_FROM_ORDER = 'REMOVE_SHRINKED_INITIATIVES_FROM_ORDER'
export const SHRINK_INITIATIVES = 'SHRINK_INITIATIVES'
export const UNSHRINK_INITIATIVES = 'UNSHRINK_INITIATIVES'
export const SET_INITIATIVE_ROW_TRANSITION = 'SET_INITIATIVE_ROW_TRANSITION'
export const HIDE_INITIATIVES = 'HIDE_INITIATIVES'
export const SHOW_INITIATIVES = 'SHOW_INITIATIVES'
export const SET_HIGHLIGHTED_COLUMN_INDEX = 'SET_HIGHLIGHTED_COLUMN_INDEX'
export const CLEAR_OLD_HIGHLIGHTED_COLUMN_INDEX = 'CLEAR_OLD_HIGHLIGHTED_COLUMN_INDEX'
