import {css} from 'emotion'
import {Moment} from 'moment'
import React from 'react'

import {ITimelineResolvedRole} from '../../../../shared/models/scenario/ITimelineResolvedRole'
import {TimelineResolvedIconComponent} from './TimelineResolvedIconComponent'

interface TimelineResolvedIconsComponentProps {
  timelineResolvedRoles: ITimelineResolvedRole[]
  planStartDate: Moment
  testID: string
}

const bubbleContainerClass = css`
  pointer-events: none;
  position: relative;
  top: 5px;
`
export const TimelineResolvedIconsComponent: React.FunctionComponent<TimelineResolvedIconsComponentProps> = React.memo(
  (props) => {
    return (
      <div className={bubbleContainerClass} data-testid={props.testID}>
        {props.timelineResolvedRoles.map((resolvedRole: ITimelineResolvedRole) => (
          <TimelineResolvedIconComponent
            key={resolvedRole.resolvedDate.format('MM-YYYY')}
            startDate={props.planStartDate}
            period={resolvedRole.resolvedDate}
          />
        ))}
      </div>
    )
  }
)

TimelineResolvedIconsComponent.displayName = 'TimelineResolvedIconsComponent'
