import React, {Suspense, useState, useCallback, useImperativeHandle, forwardRef, memo} from 'react'

// TODO investigate why Quicksilver's webpack tries to fetch the chunk file from relative path (i.e. https://static.dev.workfront.tech/plan/1.index.js)
import WrapperComponent from './FilterWrapper'

import {css} from 'react-emotion'
import Loader from './Loader'
import {IDataTableAPI} from '@wftypes/list'
import {FilterChangeParams} from './types'
import useWrapperReducer, {FilterData} from './useWrapperReducer'

export const UITYPE_FILTER = 'filter'

interface FVGOverlayProps {
  listApi: IDataTableAPI
  onClosePanel: (uiType: string) => void
  loadFilters: (listApi: IDataTableAPI) => Promise<FilterData>
  onFilterChange: (filter: FilterChangeParams) => void
}

const overlayStyles = css`
  width: 472px;
  height: 100%;

  box-shadow: rgba(0, 35, 64, 0.2) 0 3px 6px 0;
  position: relative;
  z-index: 1;
`

function FVGOverlay(props: FVGOverlayProps, ref) {
  const {listApi, onClosePanel, onFilterChange} = props

  const [uiType, setUiType] = useState('')

  const [currentFilterID, setCurrentFilterID] = React.useState('')

  const {data, isDataLoaded, setData} = useWrapperReducer()

  useImperativeHandle(
    ref,
    () => ({
      setUiType,
      getUiType: () => uiType,
      getCurrentFilterID: () => currentFilterID,
      setCurrentFilterID,
    }),
    [uiType, currentFilterID]
  )

  const handleClosePanel = useCallback(() => {
    onClosePanel(uiType)
  }, [onClosePanel, uiType])

  return uiType !== '' ? (
    <div className={overlayStyles}>
      <Suspense fallback={<Loader />}>
        <WrapperComponent
          listApi={listApi}
          onClosePanel={handleClosePanel}
          onFVGChange={onFilterChange}
          currentFilterID={currentFilterID}
          data={data}
          isDataLoaded={isDataLoaded}
          setData={setData}
          loadFilters={props.loadFilters}
        />
      </Suspense>
    </div>
  ) : null
}

export default memo(forwardRef(FVGOverlay))
