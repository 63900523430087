import {css} from 'emotion'
import * as React from 'react'
import {useSelector} from 'react-redux'
import {primary, Text} from '@phoenix/all'
import {viewAccessSelector} from '../../../../../data/settings'
import {Translate} from '../../../../shared/Translate'
import CoffeeCupIcon from 'phoenix-icons/dist/CoffeeCupIcon.js'

const coffeeWrapperClass = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const coffeeCupClass = css`
  margin-bottom: 22px;
`

const textClassBlock = css`
  text-align: center;
  color: ${primary.pebble()};
`

const textClass = css`
  color: ${primary.pebble()};
`

export const EmptyRolesList = React.memo(() => {
  const isViewMode = useSelector(viewAccessSelector)

  return (
    <div className={coffeeWrapperClass} data-testid="coffee-cup-wrapper">
      <div className={coffeeCupClass} data-testid={'coffee-cup'}>
        <CoffeeCupIcon color={primary.gray(300)} />
      </div>
      <div className={textClassBlock} data-testid={'empty-state-text'}>
        {isViewMode ? (
          <Text className={textClass}>
            <Translate messageKey={'sp.have.not.roles.in.list.view.mode'} />
          </Text>
        ) : (
          <>
            <Text className={textClass}>
              <Translate messageKey={'sp.have.not.roles.in.list'} />
            </Text>
            <br />
            <Text className={textClass}>
              <Translate messageKey={'start.typing.role'} />
            </Text>
          </>
        )}
      </div>
    </div>
  )
})

EmptyRolesList.displayName = 'EmptyRolesList'
