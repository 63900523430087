import React from 'react'

interface IPublishedInitiativeIndicatorIconProps {
  className?: string
}

export const PublishedInitiativeIndicatorIcon = ({
  className,
}: IPublishedInitiativeIndicatorIconProps) => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle className={className} cx="5" cy="5" r="4.5" fill="#50B83C" stroke="white" />
  </svg>
)
