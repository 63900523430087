import {css} from 'emotion'
import * as React from 'react'
import {connect} from 'react-redux'

import {primary, Text} from '@phoenix/all'
import {hoursForMeasurementsSelector} from '../../../../../data/plan/selectors/hoursForMeasurementsSelector'
import {currentScenarioRolesSelectorSelector} from '../../../../../data/scenario/selectors/currentScenarioRolesSelector'
import {financialAccessDeniedSelector} from '../../../../../data'
import {IScenarioRole} from '../../../../../shared/models'
import {HeaderRoleRowComponent} from './HeaderRoleRowComponent'
import {EmptyRolesList} from './EmptyRolesList'
import {
  availableRolesCellClass,
  hourlyRateCellClass,
  nameCellClass,
  requiredRolesCellClass,
} from './RoleListStyleHelper'
import {Translate} from '../../../../shared/Translate'

export interface IHeaderRolesListComponentProps {
  roles: IScenarioRole[]
  hasFinancialAccess: boolean
  useHoursForMeasurements: boolean
}

interface IHeaderRolesListComponentState {
  isFocused: boolean
  roleCount: number
}

const containerClass = css`
  height: 200px;
  overflow: auto;
`

const tableClass = css`
  display: table;
  width: 100%;

  > div > div {
    border-bottom: 1px solid ${primary.gray(100)};
  }
`

const headerTextClass = css`
  color: ${primary.gray(600)};
`

const headerRowClass = css`
  display: table-row;

  > div {
    height: 20px;
    vertical-align: top;
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: ${primary.gray(0)};
  }
`

export class HeaderRolesList extends React.Component<
  IHeaderRolesListComponentProps,
  IHeaderRolesListComponentState
> {
  constructor(props: IHeaderRolesListComponentProps) {
    super(props)
    this.state = {
      isFocused: false,
      roleCount: props.roles.length,
    }
  }

  static getDerivedStateFromProps(
    props: IHeaderRolesListComponentProps,
    state: IHeaderRolesListComponentState
  ) {
    if (props.roles.length > state.roleCount) {
      return {isFocused: true}
    } else {
      return {isFocused: false}
    }
  }

  render() {
    const {hasFinancialAccess, roles, useHoursForMeasurements} = this.props
    const {isFocused} = this.state
    const rolesLength = roles.length

    return (
      <div className={containerClass}>
        <div className={tableClass}>
          {rolesLength > 0 && (
            <div className={headerRowClass} data-testid={'roles-header'}>
              <div className={nameCellClass}>
                <Text.Small className={headerTextClass} data-testid="fte-hours-roles-label">
                  <Translate
                    messageKey={useHoursForMeasurements ? 'job.roles.hour' : 'job.roles.fte'}
                  />
                </Text.Small>
              </div>
              <div className={availableRolesCellClass}>
                <Text.Small className={headerTextClass}>
                  <span data-testid="fte-hours-available-label">
                    <Translate
                      messageKey={useHoursForMeasurements ? 'total.available' : 'max.available'}
                    />
                  </span>
                </Text.Small>
              </div>
              <div className={requiredRolesCellClass}>
                <Text.Small className={headerTextClass}>
                  <span>
                    <Translate
                      messageKey={useHoursForMeasurements ? 'total.required' : 'max.required'}
                    />
                  </span>
                </Text.Small>
              </div>
              {hasFinancialAccess && (
                <div className={hourlyRateCellClass}>
                  <Text.Small className={headerTextClass} data-testid={'hourly-rate'}>
                    <Translate messageKey={'scenario.widget.people.hourly.rate'} />
                  </Text.Small>
                </div>
              )}
            </div>
          )}

          {roles.map((scenarioRole, index) => {
            return (
              <HeaderRoleRowComponent
                key={scenarioRole.role.id}
                scenarioRole={scenarioRole}
                isFocused={index === 0 && isFocused}
              />
            )
          })}

          {rolesLength === 0 && <EmptyRolesList />}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  roles: currentScenarioRolesSelectorSelector(state),
  hasFinancialAccess: !financialAccessDeniedSelector(state),
  useHoursForMeasurements: hoursForMeasurementsSelector(state),
})

export const HeaderRolesListComponent = connect(mapStateToProps)(HeaderRolesList)
