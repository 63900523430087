import * as React from 'react'
import {Provider} from 'react-redux'
import {applyMiddleware, createStore} from 'redux'
import thunk from 'redux-thunk'

import {combinedPlanListReducer} from '../../data/reducers'
import {composeEnhancers} from '../../shared/DevTool'
import {NewPlanListComponent} from './NewPlanListComponent'
import {SplitProvider} from '../../shared/context/SplitProvider'
import {LoadLocalizations, LocalizationProvider} from '@wf-mfe/localize-react'
import {espClientFactory} from '../../../shared/L10n'
import {useIsTreatmentEnabled} from '@wf-mfe/toggles'
import {redirectToLogin} from '@wf-mfe/api'

const store = createStore(
  combinedPlanListReducer,
  composeEnhancers('ESP Grid')(applyMiddleware(thunk))
)
export const PlanListWrapperComponent = () => {
  const toggles = {
    FILTER_SP_PLANS_LIST: useIsTreatmentEnabled('new-filter-sp-plans-list'),
  }

  return (
    <Provider store={store}>
      <SplitProvider value={toggles}>
        <LocalizationProvider clientFactory={espClientFactory}>
          <LoadLocalizations messageKeys={['sp.scenarios']}>
            <NewPlanListComponent redirectToLoginPage={redirectToLogin} />
          </LoadLocalizations>
        </LocalizationProvider>
      </SplitProvider>
    </Provider>
  )
}
