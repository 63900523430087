import cloneDeep from 'lodash/cloneDeep'
import {Moment} from 'moment'
import {
  IBudgetConflict,
  IRoleConflict,
  IScenarioBudgets,
  IScenarioRole,
  IScenarioRoleChange,
} from '../../models'
import {mapToArray} from '../../utilities'
import {calculateAvailableForRole} from './ScenarioRolesCalculationHelper'
import {createScenarioRole} from '../ScenarioHelper'
import {IGlobalRoles} from '../../models/IGlobalRoles'

export function resolveBudgetConflict(
  budgets: IScenarioBudgets,
  budgetConflicts: IBudgetConflict
): IScenarioBudgets {
  const newBudget: IScenarioBudgets = cloneDeep(budgets)
  const {periodConflicts} = budgetConflicts

  for (const periodConflict of periodConflicts) {
    const {period, missingAmount, totalAmount} = periodConflict
    for (let j = 0; j < newBudget.distribution.size; j++) {
      const yearlyBudget = mapToArray(newBudget.distribution)[j]
      const itemIndex = yearlyBudget.budgetMonths.findIndex((item) =>
        item.yearMonthDate.isSame(period, 'day')
      )
      if (itemIndex !== -1) {
        // TODO: remove Math.ceil remove after budget report refactor
        yearlyBudget.budgetMonths[itemIndex].amount = totalAmount + Math.ceil(missingAmount)
        yearlyBudget.total! = yearlyBudget.total! + Math.ceil(missingAmount)
        break
      }
    }
  }
  return newBudget
}

export function resolveRoleConflicts(
  scenarioRoles: IScenarioRole[],
  resolvableRoleIds: string[],
  roleConflicts: Map<string, IRoleConflict>,
  planStartDate: Moment,
  planDuration: number,
  globalRoles: IGlobalRoles,
  useHoursForMeasurements: boolean
): IScenarioRole[] {
  const roles = resolvableRoleIds.map((roleId) => {
    return (
      scenarioRoles.find((r) => r.role.id === roleId) ||
      createScenarioRole(
        {
          id: roleId,
          ...globalRoles[roleId],
        },
        planStartDate,
        planDuration
      )
    )
  })
  return roles.map((r) => {
    const roleConflict = roleConflicts.get(r.role.id)!
    return resolveSingleRoleConflict(
      r,
      roleConflict,
      planStartDate,
      planDuration,
      useHoursForMeasurements
    )
  })
}

function resolveSingleRoleConflict(
  scenarioRole: IScenarioRole,
  roleConflict: IRoleConflict,
  planStartDate: Moment,
  planDuration: number,
  useHoursForMeasurements
): IScenarioRole {
  const currentChanges = calculateMissingRoleChanges(roleConflict)
  const changes = mergeRoleChanges(currentChanges, scenarioRole.changes)
  const {availableValue, availablePerPeriod} = calculateAvailableForRole(
    currentChanges,
    scenarioRole.availablePerPeriod,
    useHoursForMeasurements
  )

  return {
    ...scenarioRole,
    changes,
    availablePerPeriod,
    availableValue,
  }
}

function mergeRoleChanges(
  newChanges: IScenarioRoleChange[],
  changes: IScenarioRoleChange[]
): IScenarioRoleChange[] {
  const mergedChanges: IScenarioRoleChange[] = [...changes]
  const length = newChanges.length

  for (let i = 0; i < length; i++) {
    const currentItem = newChanges[i]

    const itemIndex = mergedChanges.findIndex((item) =>
      item.yearMonthDate.isSame(currentItem.yearMonthDate, 'day')
    )
    if (itemIndex !== -1) {
      mergedChanges[itemIndex].change = mergedChanges[itemIndex].change + currentItem.change
    } else {
      mergedChanges.push(currentItem)
    }
  }

  return sortRoleChanges(mergedChanges)
}

function sortRoleChanges(mergedChanges: IScenarioRoleChange[]): IScenarioRoleChange[] {
  return mergedChanges.sort((change1, change2) => {
    if (change1.yearMonthDate.isBefore(change2.yearMonthDate)) {
      return -1
    } else if (change2.yearMonthDate.isBefore(change1.yearMonthDate)) {
      return 1
    } else {
      return 0
    }
  })
}

function calculateMissingRoleChanges(conflictedRole: IRoleConflict): IScenarioRoleChange[] {
  const changes: IScenarioRoleChange[] = []

  for (const periodConflict of conflictedRole.periodConflicts) {
    const change = periodConflict.missingAmount
    changes.push({
      yearMonthDate: periodConflict.period,
      change,
    })
  }
  return changes
}
