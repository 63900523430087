import {css, cx} from 'emotion'
import moment from 'moment'
import * as React from 'react'
import styled from 'react-emotion'
import {connect} from 'react-redux'
import {timelineCellWidthSelector} from '../../../../../data/plan'
import {LABEL_DATE_FORMAT} from '../../../../../shared/constants/dateFormats'
import {MeasurementsContext} from '../../../../../shared/context/MeasurementsContext'
import {IVisibleConflictInfoByDate} from '../../../../../shared/helpers/TimelineBarHelper'
import {IInitiative} from '../../../../../shared/models/initiative'
import {PopperComponent} from '../../../../shared'
import {ConflictsTooltipComponent} from '../../../../shared/ConflictsTooltipComponent'
import {InfoTooltipContentComponent} from '../../../../shared/InfoTooltipContentComponent'
import {BAR_TRIANGLE_WIDTH} from '../TimelineEmptyBarComponent'
import {secondary, alerts, primary} from '@phoenix/all'
import {IInitiativeConflictsDetailsDates} from '../../../../../shared/models/initiative/IInitiativeConflicsDetailsByDate'
import {VisibleConflictInfoComponent} from './VisibleConflictInfoComponent'
import {Translate} from '../../../../shared/Translate'

export interface IRoleContouringComponentProps {
  cellWidth: number
  isEmptyBar?: boolean
  date: string
  contouringX: number
  conflictsByDate: null | IInitiativeConflictsDetailsDates
  initiative: IInitiative
  setMouseInsideTooltip?: (value: boolean) => void
  setBarArrowsStates?: (state: {[propsrty: string]: boolean}) => void
  barWidth: number
  barStartPosition: number
  setIsBarLeftTriangleConflicted?: (value: boolean) => void
  setIsBarRightTriangleConflicted?: (value: boolean) => void
  className?: string
  visibleConflictInfo: IVisibleConflictInfoByDate | null
  showLeftTriangle?: boolean
  showRightTriangle?: boolean
}

const tooltipWrapperClass = css`
  box-shadow: none !important;
`

const contouringClass = css`
  width: 100%;
  height: 100%;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${primary.white()};
  padding: 0 5px;
  text-align: center;

  span {
    color: inherit;
    font-weight: 600;
    line-height: normal;
  }

  &:hover {
    border: 1px solid ${alerts.error(500)};
  }
`

const ContouringWrapperStyled = styled('g')<{
  isConflicted: boolean
}>`
  &.conflict-preview .contouring {
    border: 1px solid ${(props) => (props.isConflicted ? alerts.error(500) : primary.blue(400))};
  }

  conflict-preview rect {
    stroke: ${(props) => (props.isConflicted ? alerts.error(500) : primary.blue(400))};
  }

  .contouring {
    background-color: ${(props) => (props.isConflicted ? secondary.red(400) : primary.blue(400))};
  }

  rect {
    fill: ${(props) => (props.isConflicted ? secondary.red(400) : primary.blue(400))};
  }

  &.conflict-preview + &.conflict-preview .contouring {
    border-left: none;
  }
`

const firstClass = css`
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`

const lastClass = css`
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`

const headerClass = css`
  display: flex;
  justify-content: flex-start;
  span {
    display: inline-block;
    white-space: nowrap;
    &:first-child {
      overflow: hidden !important;
      text-overflow: ellipsis;
    }
  }
`

const ARROW_VISIBLE_WIDTH = 7

const RoleContouring: React.FC<IRoleContouringComponentProps> = React.memo(
  ({
    date,
    isEmptyBar,
    initiative,
    conflictsByDate,
    cellWidth,
    contouringX,
    setMouseInsideTooltip,
    setBarArrowsStates,
    barStartPosition,
    barWidth,
    setIsBarLeftTriangleConflicted,
    setIsBarRightTriangleConflicted,
    className,
    visibleConflictInfo,
    showLeftTriangle,
    showRightTriangle,
  }) => {
    const [placement, setPlacement] = React.useState<'first' | 'middle' | 'last' | 'firstLast'>(
      'middle'
    )
    const [width, setWidth] = React.useState<number>(cellWidth)
    const [recalculatedContouringX, setRecalculatedContouringX] = React.useState<number>(
      contouringX
    )
    const [isHovered, setIsHovered] = React.useState<boolean>(false)
    const {timelineBarArrowYPosition, timelineBarArrowHeight} = React.useContext(
      MeasurementsContext
    )
    const onMouseEnterTooltipHandler = () => setMouseInsideTooltip && setMouseInsideTooltip(true)
    const onMouseLeaveTooltipHandler = () => setMouseInsideTooltip && setMouseInsideTooltip(false)

    React.useEffect(() => {
      const endX = barStartPosition + barWidth
      const isCloseToRightArrow = Math.ceil(contouringX + cellWidth) >= Math.floor(endX)

      if (contouringX === barStartPosition && isCloseToRightArrow) {
        setPlacement('firstLast')

        setBarArrowsStates &&
          setBarArrowsStates({
            shouldScaleLeftArrow: isHovered,
            shouldScaleRightArrow: isHovered,
          })

        setIsBarLeftTriangleConflicted && setIsBarLeftTriangleConflicted(true)
        setIsBarRightTriangleConflicted && setIsBarRightTriangleConflicted(true)
      } else if (contouringX === barStartPosition) {
        setPlacement('first')

        setBarArrowsStates && setBarArrowsStates({shouldScaleLeftArrow: isHovered})

        setIsBarLeftTriangleConflicted && setIsBarLeftTriangleConflicted(true)
      } else if (isCloseToRightArrow) {
        setPlacement('last')

        setBarArrowsStates && setBarArrowsStates({shouldScaleRightArrow: isHovered})

        setIsBarRightTriangleConflicted && setIsBarRightTriangleConflicted(true)
      } else {
        setPlacement('middle')
      }
    }, [isEmptyBar, contouringX, barStartPosition, cellWidth, barWidth, isHovered])

    React.useEffect(() => {
      let width: number = cellWidth
      let contouringXValue: number = contouringX

      if (placement === 'firstLast') {
        const calculatedWidth = isEmptyBar ? cellWidth : cellWidth - 2 * ARROW_VISIBLE_WIDTH
        width =
          isEmptyBar && (showLeftTriangle || showRightTriangle)
            ? calculatedWidth - BAR_TRIANGLE_WIDTH
            : calculatedWidth

        contouringXValue = isEmptyBar
          ? contouringX + (showLeftTriangle ? BAR_TRIANGLE_WIDTH : 0)
          : contouringX + ARROW_VISIBLE_WIDTH
      }

      if (placement === 'first') {
        contouringXValue =
          isEmptyBar && showLeftTriangle
            ? contouringX + BAR_TRIANGLE_WIDTH
            : isEmptyBar
            ? contouringX
            : contouringX + ARROW_VISIBLE_WIDTH
        width =
          isEmptyBar && showLeftTriangle
            ? cellWidth - BAR_TRIANGLE_WIDTH
            : isEmptyBar
            ? cellWidth
            : cellWidth - ARROW_VISIBLE_WIDTH
      } else if (placement === 'last') {
        width =
          isEmptyBar && showRightTriangle
            ? cellWidth - BAR_TRIANGLE_WIDTH
            : isEmptyBar
            ? cellWidth
            : cellWidth - ARROW_VISIBLE_WIDTH
      }

      setRecalculatedContouringX(contouringXValue)
      setWidth(width)
    }, [placement, cellWidth, contouringX])

    const classNameForContouring = React.useMemo(() => {
      const firstClassName = showLeftTriangle ? '' : firstClass
      const lastClassName = showRightTriangle ? '' : lastClass

      return `${contouringClass} contouring ${
        placement === 'firstLast'
          ? `${firstClassName} ${lastClassName}`
          : placement === 'first'
          ? firstClassName
          : placement === 'last'
          ? lastClassName
          : ''
      }`
    }, [placement])

    const onMouseOverHandler = (): void => {
      setIsHovered(true)
    }

    const onMouseLeaveHandler = (): void => {
      setIsHovered(false)
    }

    const svgCoordinates = React.useMemo(() => {
      const y =
        !visibleConflictInfo && (isEmptyBar || !isHovered)
          ? timelineBarArrowYPosition
          : timelineBarArrowYPosition - 1

      return {
        x: recalculatedContouringX,
        y,
        width,
        height:
          !visibleConflictInfo && (isEmptyBar || !isHovered)
            ? timelineBarArrowHeight
            : timelineBarArrowHeight + 2,
      }
    }, [
      visibleConflictInfo,
      isEmptyBar,
      isHovered,
      timelineBarArrowYPosition,
      width,
      timelineBarArrowHeight,
      recalculatedContouringX,
    ])

    return (
      <ContouringWrapperStyled
        className={cx(className || '', visibleConflictInfo ? 'conflict-preview' : '')}
        isConflicted={!!conflictsByDate}
      >
        <PopperComponent
          key={date}
          placement="bottom"
          keepPopupVisible={true}
          useTopIndent={false}
          className={tooltipWrapperClass}
          onMouseEnter={onMouseEnterTooltipHandler}
          onMouseLeave={onMouseLeaveTooltipHandler}
          openDelay={300}
          content={
            <InfoTooltipContentComponent>
              <ConflictsTooltipComponent
                conflictsByDate={conflictsByDate}
                date={date}
                initiative={initiative}
                initiativeOrder={initiative.order}
                title={
                  <div className={headerClass}>
                    <span>
                      <Translate
                        messageKey={'scenario.conflict.tooltip.heading'}
                        args={[initiative.name]}
                      />
                    </span>
                    <span>{`, ${moment(date).format(LABEL_DATE_FORMAT)}`}</span>
                  </div>
                }
              />
            </InfoTooltipContentComponent>
          }
          showOnOver
          reference={
            <foreignObject {...svgCoordinates}>
              <div
                data-testid="role-contouring"
                className={classNameForContouring}
                onMouseOver={onMouseOverHandler}
                onMouseLeave={onMouseLeaveHandler}
                onFocus={onMouseOverHandler}
              >
                {visibleConflictInfo && (
                  <VisibleConflictInfoComponent visibleConflictInfo={visibleConflictInfo} />
                )}
              </div>
            </foreignObject>
          }
        />
      </ContouringWrapperStyled>
    )
  }
)

RoleContouring.displayName = 'RoleContouring'

const mapStateToProps = (state) => ({
  cellWidth: timelineCellWidthSelector(state),
})

export const RoleContouringComponent = connect(mapStateToProps)(RoleContouring)
