import {primary} from '@phoenix/all'
import {css} from 'emotion'
import * as React from 'react'

import {PopperComponent} from './index'
import {TooltipContentComponent} from './index'

interface IEllipsisWithTooltipComponentProps {
  className?: string
  testID: string
  text: string | React.ReactElement
}

const nameTextWrapperClass = css`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  color: ${primary.gray(500)};
  line-height: normal;
`

export const TextWithTooltipComponent: React.FunctionComponent<IEllipsisWithTooltipComponentProps> = React.memo(
  (props) => {
    const textRef = React.useRef<HTMLDivElement | null>(null)
    const [showTooltip, setShowTooltip] = React.useState(false)

    React.useEffect(() => {
      const boundingClientRectWidth = textRef.current!.getBoundingClientRect().width
      if (
        textRef.current!.scrollWidth > textRef.current!.offsetWidth &&
        boundingClientRectWidth === textRef.current!.offsetWidth
      ) {
        setShowTooltip(true)
      }
    }, [textRef])

    const textWrapper = (testIDSuffix = '') => (
      <div
        className={nameTextWrapperClass}
        ref={textRef}
        data-testid={`${props.testID}${testIDSuffix}`}
      >
        <span className={props.className || ''}>{props.text}</span>
      </div>
    )

    return (
      <React.Fragment>
        {showTooltip ? (
          <PopperComponent
            showOnOver={true}
            placement={'top'}
            content={<TooltipContentComponent text={props.text} />}
            reference={textWrapper('-has-tooltip')}
          />
        ) : (
          textWrapper()
        )}
      </React.Fragment>
    )
  }
)

TextWithTooltipComponent.displayName = 'TextWithTooltipComponent'
