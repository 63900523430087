import {css} from 'emotion'
import * as React from 'react'
import {Manager, Popper, Reference} from 'react-popper'
import {connect} from 'react-redux'
import {financialAccessDeniedSelector} from '../../../../../data/settings'
import {
  isPeopleWidgetExpandedSelector,
  shouldAnimatePeopleWidgetSelector,
  togglePeopleWidget,
  togglePeopleWidgetAnimation,
} from '../../../../../data/viewOptions'
import {fadeWidgetBorderClass} from '../AnimationHelper'
import {HeaderPeopleExpandedWidgetComponent} from './HeaderPeopleExpandedWidgetComponent'
import {HeaderPeopleWidgetComponent} from './HeaderPeopleWidgetComponent'

export interface IHeaderPeopleWidgetContainerComponentProps {
  isWidgetExpanded: boolean
  shouldAnimatePeopleWidget: boolean
  hasFinancialAccess: boolean
  collapseWidgetFunction: () => void
  togglePeopleWidgetAnimationFunction: (shouldAnimatePeopleWidget: boolean) => void
}

const containerClass = css`
  position: relative;
`

const expandedWidgetContainerClass = css`
  position: absolute;
  margin-top: 2px;
  left: 0px;
  z-index: 1;
`

const noFinancialAccessExpandedWidgetClass = css`
  ${expandedWidgetContainerClass};
  right: 0px;
  left: auto;
`

export class HeaderPeopleWidgetContainer extends React.Component<
  IHeaderPeopleWidgetContainerComponentProps
> {
  containerRef

  constructor(props: IHeaderPeopleWidgetContainerComponentProps) {
    super(props)
    this.containerRef = React.createRef()
  }

  componentDidMount(): void {
    document.addEventListener('mousedown', this.collapseWidget, true)
  }

  componentWillUnmount(): void {
    document.removeEventListener('mousedown', this.collapseWidget)
  }

  componentDidUpdate(prevProps: Readonly<IHeaderPeopleWidgetContainerComponentProps>): void {
    if (prevProps.shouldAnimatePeopleWidget !== this.props.shouldAnimatePeopleWidget) {
      setTimeout(() => {
        this.props.togglePeopleWidgetAnimationFunction(false)
      }, 2000)
    }
  }

  render() {
    return (
      <div
        ref={(node) => (this.containerRef = node)}
        className={this.props.shouldAnimatePeopleWidget ? fadeWidgetBorderClass : containerClass}
      >
        <Manager>
          <Reference>{() => <HeaderPeopleWidgetComponent />}</Reference>
          {this.props.isWidgetExpanded && (
            <Popper placement="bottom-start">
              {() => (
                <div
                  className={
                    this.props.hasFinancialAccess
                      ? expandedWidgetContainerClass
                      : noFinancialAccessExpandedWidgetClass
                  }
                  data-testid="expanded-people-widget"
                >
                  <HeaderPeopleExpandedWidgetComponent />
                </div>
              )}
            </Popper>
          )}
        </Manager>
      </div>
    )
  }

  private collapseWidget = ({target}) => {
    if (this.props.isWidgetExpanded) {
      const canSetState =
        !this.containerRef.contains(target) &&
        target.closest('div[data-testid="header-people-roles-options-options"]') === null &&
        target.closest('#dialog-container') === null

      if (canSetState) {
        setTimeout(() => {
          this.props.collapseWidgetFunction()
        })
      }
    }
  }
}

const mapStateToProps = (state) => ({
  isWidgetExpanded: isPeopleWidgetExpandedSelector(state),
  shouldAnimatePeopleWidget: shouldAnimatePeopleWidgetSelector(state),
  hasFinancialAccess: !financialAccessDeniedSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  collapseWidgetFunction: () => dispatch(togglePeopleWidget(false)),
  togglePeopleWidgetAnimationFunction: (shouldAnimatePeopleWidget) =>
    dispatch(togglePeopleWidgetAnimation(shouldAnimatePeopleWidget)),
})

export const HeaderPeopleWidgetContainerComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderPeopleWidgetContainer)
