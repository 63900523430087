import {secondary} from '@phoenix/all'
import {css} from 'emotion'
import * as React from 'react'
import ErrorFilledSmallIcon from 'phoenix-icons/dist/ErrorFilledSmallIcon.js'

import {calculateRemaining} from '../../../../../shared/helpers/scenario/ScenarioBudgetCalculationHelper'

import {IYearlyBudget} from '../../../../../shared/models/scenario/IYearlyBudget'
import {PopperComponent} from '../../../../shared/PopperComponent'
import {TooltipContentComponent} from '../../../../shared/TooltipContentComponent'
import {inlineCenterItemsClass} from '../../StyleHelper'
import {Translate} from '../../../../shared/Translate'

interface IHeaderBudgetWarningIconProps {
  currentDistribution: IYearlyBudget
  label: string
}

const alertIconClass = css`
  margin-left: 5px;
`

const yearLabelClass = css`
  display: inline-block;
  padding-bottom: 3px;
`

const labelWrapperClass = css`
  ${inlineCenterItemsClass};
  height: 18px;
`

export const HeaderBudgetYearLabelComponent: React.FunctionComponent<IHeaderBudgetWarningIconProps> = (
  props
) => {
  const currentDistribution = props.currentDistribution
  const remaining = calculateRemaining(currentDistribution)

  return (
    <div className={labelWrapperClass}>
      <span className={yearLabelClass}>{props.label}</span>
      {remaining < 0 && (
        <PopperComponent
          reference={
            <div className={alertIconClass} data-testid="budget-alert-icon">
              <ErrorFilledSmallIcon color={secondary.yellow()} />
            </div>
          }
          content={
            <TooltipContentComponent
              text={<Translate messageKey={'scenario.widget.budget.warning'} />}
            />
          }
          showOnOver={true}
          placement="top"
        />
      )}
    </div>
  )
}
