import React from 'react'
import {TRenderer} from '@wftypes/list'
import {A, Tooltip} from '@phoenix/all'
import {getObjectLink} from '@quicksilver/objectmetadata'
import {css} from 'emotion'
import {TObjCode} from 'workfront-objcodes'
import {QuickFilterMatcher} from '../types/quickFilter'

interface ObjectLinkProps {
  id: string
  name: string
  objCode: TObjCode
}

const nameWrapperClass = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const textOverflowClass = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 5px;
`

const linkClass = css`
  font-weight: normal !important;
`

const tooltipClass = css`
  max-width: calc(100% - 20px);
`

export const ObjectLink: TRenderer<ObjectLinkProps> = ({
  id,
  name,
  objCode,
  isSelected,
  highlight,
  filterValue,
}) => {
  const clickHandler = React.useCallback(
    (e) => {
      if (isSelected) {
        e.preventDefault()
      }
    },
    [isSelected]
  )

  return (
    <div className={nameWrapperClass}>
      <Tooltip position={'auto'} className={tooltipClass} content={name}>
        <div className={textOverflowClass}>
          <A className={linkClass} to={getObjectLink({objCode, ID: id}).to} onClick={clickHandler}>
            {highlight(filterValue, name)}
          </A>
        </div>
      </Tooltip>
    </div>
  )
}
export const objectLinkMatcher: QuickFilterMatcher = ({name}, filterValue) =>
  typeof name === 'string' && name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1
