import * as React from 'react'
import styled from 'react-emotion'

import {primary} from '@phoenix/all'
import {resetFocusStateClass} from './StyleHelper'

interface IInlineEditComponentProps {
  tabIndex?: number
  placeholder?: string
  autoComplete?: string
  value: string
  onChange: (event) => void
  onFocus?: (event) => void
  onBlur?: (event) => void
  onKeyDown?: (event, ref) => void
  fontSize?: number
  maxLength?: number
  testId?: string
  className?: string
  ref?: React.RefObject<HTMLInputElement>
  readOnly?: boolean
}

interface IEditableNameStyledProps {
  fontSize?: number
}

const InlineEditStyled = styled('input')<IEditableNameStyledProps>`
  background-color: transparent;
  color: ${primary.gray(800)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : 14)}px;
  border: 1px solid transparent;
  width: 100%;
  border-bottom: 1px solid transparent;
  text-overflow: ellipsis;
  padding: 1px 4px 2px 4px;

  &:hover {
    border: 1px solid ${primary.blue(100)};
    border-radius: 2px;
  }

  &:focus {
    border: 1px solid #0078d7;
    border-radius: 2px;
    background: ${primary.white()};
    ${resetFocusStateClass};
  }

  &::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
`

export const NewInlineEditComponent: React.FunctionComponent<IInlineEditComponentProps> = React.memo(
  React.forwardRef((props, ref) => {
    return (
      <InlineEditStyled
        innerRef={ref}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        maxLength={props.maxLength}
        onKeyDown={(e) => (props.onKeyDown ? props.onKeyDown(e, ref) : null)}
        type="text"
        name="inlineEdit"
        autoComplete={props.autoComplete ? props.autoComplete : 'off'}
        data-testid={props.testId}
        className={props.className}
        tabIndex={props.tabIndex}
        placeholder={props.placeholder}
        onChange={props.onChange}
        value={props.value ? props.value : ''}
        title={props.value}
        readOnly={props.readOnly}
      />
    )
  })
)

NewInlineEditComponent.displayName = 'NewInlineEditComponent'
