export function deselectInitiative(selectedInitiatives, initiativeId) {
  const newSelections = {...selectedInitiatives}
  delete newSelections[initiativeId]
  return newSelections
}

export function toggleInitiative(selectedInitiatives, initiativeId) {
  const newSelections = {...selectedInitiatives}
  const isSelected = !newSelections[initiativeId]
  if (isSelected) {
    newSelections[initiativeId] = isSelected
  } else {
    delete newSelections[initiativeId]
  }

  return newSelections
}
