import * as React from 'react'
import {connect} from 'react-redux'
import {selectedInitiativeCountSelector} from '../../../data/plan/selectors/selectedInitiativeCountSelector'
import {cx, css} from 'react-emotion'
import {Text} from '@phoenix/all'
import {DragHandleComponent} from './Initiative/InitiativeInfo/DragHandleComponent'
import {Translate} from '../../shared/Translate'

interface ISelectionCountIndicatorProps {
  selectedInitiativeCount: number
}

const dragDropSelectionInfoContainerClass = css`
  display: none;
  align-items: center;
`

const SelectionCountIndicator: React.FC<ISelectionCountIndicatorProps> = ({
  selectedInitiativeCount,
}) => {
  return (
    <div className={cx('drag-drop-selection-info', dragDropSelectionInfoContainerClass)}>
      <DragHandleComponent
        dragHandleContainerClass={css`
          position: static;
          margin: 0;
        `}
      />
      <Text>
        <Translate messageKey={'sp.initiative.selected.plural'} args={[selectedInitiativeCount]} />
      </Text>
    </div>
  )
}

const mapStateToProps = (state) => ({
  selectedInitiativeCount: selectedInitiativeCountSelector(state),
})

export const SelectionCountIndicatorComponent = connect(mapStateToProps)(SelectionCountIndicator)
