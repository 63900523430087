import isEqual from 'lodash/isEqual'
import forEach from 'lodash/forEach'
import reduce from 'lodash/reduce'
import moment, {Moment} from 'moment'
import {
  IInitiative,
  IInitiativeCosts,
  IInitiativeDates,
  IInitiativeNetValue,
  IInitiativePeople,
  IInitiativeRole,
  IScenario,
} from '../models'
import {getDefaultDistribution} from '../utilities'
import cloneDeep from 'lodash/cloneDeep'
import {IDistribution} from '../models/initiative/IDistribution'
import {deserializeDistribution} from './ScenarioHelper'
import {WORKING_HOURS_IN_MONTH} from '../constants/Measurements'
import {translate} from '../utilities/TranslateHelper'
import nanoid from 'nanoid'

const ISO_8601 = 'YYYY-MM-DDTHH:mm:ssZ'

export function createInitiative(
  name: string,
  hasFinancialAccess: boolean,
  startDate = moment().startOf('month'),
  endDate = startDate.clone().endOf('month'),
  order = 1
): IInitiative {
  const id = nanoid()
  const defaultCostDistribution = hasFinancialAccess
    ? getDefaultDistribution(startDate, endDate)
    : null

  const costs: IInitiativeCosts | null = hasFinancialAccess
    ? {
        fixedCostDistribution: defaultCostDistribution,
        peopleCostDistribution: defaultCostDistribution,
        overallCostDistribution: defaultCostDistribution,
      }
    : null
  const netValue: IInitiativeNetValue | null = hasFinancialAccess
    ? {
        plannedBenefit: 0,
        calculatedNetValue: 0,
      }
    : null

  return {
    id,
    name,
    order,
    dates: {
      startDate: startDate.clone(),
      endDate,
      duration: 1,
    },
    people: {
      total: 0,
      roles: [],
    },
    costs: costs,
    netValue: netValue,
    conflicts: null,
    refObject: null,
    lastPublishedDate: null,
  }
}

export function copyInitiative(initiative: IInitiative): IInitiative {
  const id = nanoid()
  const newInitiative = cloneDeep(initiative) as IInitiative
  newInitiative.id = id
  newInitiative.name = translate('initiative.copy.prefix') + ' ' + newInitiative.name
  newInitiative.conflicts = null
  newInitiative.refObject = null
  newInitiative.lastPublishedDate = null

  return newInitiative
}

export function serializeInitiative(initiative: IInitiative): Record<string, unknown> {
  return {
    id: initiative.id,
    name: initiative.name,
    fixedCostDistribution:
      initiative.costs && initiative.costs.fixedCostDistribution
        ? filterDistribution(initiative.costs.fixedCostDistribution)
        : null,
    roleCounts: initiative.people.roles.map((role) => {
      return {
        role: {
          ID: role.id,
        },
        distribution: filterDistribution(role.distribution),
      }
    }),
    dates: {
      startDate: initiative.dates.startDate.format(ISO_8601),
      endDate: initiative.dates.endDate.format(ISO_8601),
    },
    plannedBenefit: initiative.netValue && initiative.netValue.plannedBenefit,
    refObject: initiative.refObject
      ? {
          id: initiative.refObject.id,
        }
      : null,
  }
}

function filterDistribution(distribution: IDistribution): IDistribution {
  const filteredDistribution: IDistribution = {}
  Object.keys(distribution).forEach((key) => {
    if (distribution[key] > 0) {
      filteredDistribution[key] = distribution[key]
    }
  })

  return filteredDistribution
}

export function deserializeInitiative(
  initiativeJson,
  order: number,
  usePeopleCostOnConflictCalculation: boolean,
  hasFinancialAccess: boolean
): IInitiative {
  const startDate = moment.utc(initiativeJson.dates.startDate)
  const endDate = moment.utc(initiativeJson.dates.endDate)
  const dates = {
    startDate,
    endDate,
    duration: Math.round(endDate.diff(startDate, 'months', true)),
  }
  const people = deserializeInitiativePeople(initiativeJson.roleCounts, startDate, endDate)
  let costs, netValue

  const deserializedFixedCostsDistribution = hasFinancialAccess
    ? deserializeDistribution(initiativeJson.fixedCostDistribution, startDate, endDate)
    : null

  if (hasFinancialAccess) {
    costs = calculateCosts(people.roles, dates, deserializedFixedCostsDistribution)
    netValue = {
      ...calculateNetValue(
        initiativeJson.plannedBenefit,
        costs,
        usePeopleCostOnConflictCalculation
      ),
    }
  }

  const {refObject} = initiativeJson
  const plannedStartDate =
    refObject && refObject.plannedStartDate ? moment.utc(refObject.plannedStartDate) : null
  const plannedCompletionDate =
    refObject && refObject.plannedCompletionDate
      ? moment.utc(refObject.plannedCompletionDate)
      : null
  const lastPublishedDate = initiativeJson.lastPublishedDate
    ? moment(initiativeJson.lastPublishedDate)
    : null

  return {
    id: initiativeJson.id,
    name: initiativeJson.name,
    order,
    dates,
    people,
    costs: costs || null,
    netValue: netValue || null,
    conflicts: null,
    refObject: initiativeJson.refObject
      ? {
          id: initiativeJson.refObject.id,
          plannedStartDate,
          plannedCompletionDate,
        }
      : initiativeJson.refObject,
    lastPublishedDate,
  }
}

function deserializeInitiativePeople(
  rolesJson,
  startDate: Moment,
  endDate: Moment
): IInitiativePeople {
  const roles: IInitiativeRole[] = []
  let total: number | null = 0
  for (const roleJson of rolesJson) {
    const role: IInitiativeRole = {
      id: roleJson.role.ID,
      name: roleJson.role.name,
      costPerHour: roleJson.role.costPerHour || null,
      distribution: deserializeDistribution(roleJson.distribution, startDate, endDate),
    }
    roles.push(role)
    total = (total || 0) + getMaxValueOfDistribution(role.distribution)
  }

  return {
    roles,
    total,
  }
}

export function getMaxValueOfDistribution(distribution: IDistribution): number {
  let maxCount = 0
  forEach(distribution, (count) => (maxCount = maxCount < count ? count : maxCount))
  return maxCount
}

export function getInitiativeWithCalculatedFields(
  initiative: IInitiative,
  usePeopleCostOnConflictCalculation: boolean,
  hasFinancialAccess,
  useHoursForMeasurements: boolean
): IInitiative {
  const {roles} = initiative.people
  let costs, netValue

  const people = {
    roles: [...roles],
    total: roles.reduce((sum, r) => sum + getMaxValueOfDistribution(r.distribution), 0),
  }
  if (hasFinancialAccess) {
    costs = calculateCosts(
      roles,
      initiative.dates,
      initiative.costs!.fixedCostDistribution,
      useHoursForMeasurements
    )
    netValue = calculateNetValue(
      initiative.netValue!.plannedBenefit,
      costs,
      usePeopleCostOnConflictCalculation
    )
  } else {
    costs = initiative.costs
    netValue = initiative.netValue
  }

  return {
    ...initiative,
    people,
    costs,
    netValue,
  }
}

function calculateCosts(
  roles: IInitiativeRole[],
  dates: IInitiativeDates,
  fixedCostsDistribution: IDistribution | null,
  useHoursForMeasurements?: boolean
): IInitiativeCosts {
  const peopleCostsDistribution = fixedCostsDistribution
    ? calculatePeopleCostDistribution(roles, dates, useHoursForMeasurements)
    : null

  const overallCostsDistribution = calculateOverallCosts(
    peopleCostsDistribution!,
    fixedCostsDistribution!
  )

  return {
    fixedCostDistribution: fixedCostsDistribution,
    peopleCostDistribution: peopleCostsDistribution,
    overallCostDistribution: overallCostsDistribution,
  }
}

function calculateOverallCosts(
  peopleCostsDistribution: IDistribution,
  fixedCostsDistribution: IDistribution
): IDistribution | null {
  if (fixedCostsDistribution) {
    const overallCostsDistribution: IDistribution = {}
    Object.keys(peopleCostsDistribution).forEach((key) => {
      overallCostsDistribution[key] = peopleCostsDistribution[key] + fixedCostsDistribution[key]
    })
    return overallCostsDistribution
  }
  return null
}

function calculatePeopleCostDistribution(
  roles: IInitiativeRole[],
  dates: IInitiativeDates,
  useHoursForMeasurements?: boolean
): IDistribution {
  const rolesWithCost = roles.filter((role) => role.costPerHour !== null)

  if (rolesWithCost.length === 0) {
    return getDefaultDistribution(dates.startDate, dates.endDate)
  }

  const peopleCostDistribution: IDistribution = {}
  const startDateClone = dates.startDate.clone()

  while (startDateClone.isSameOrBefore(dates.endDate)) {
    const key = startDateClone.format('YYYY-MM')
    peopleCostDistribution[key] = getPeopleCostForMonth(roles, key, useHoursForMeasurements)
    startDateClone.add(1, 'month')
  }

  return peopleCostDistribution
}

function getPeopleCostForMonth(
  roles: IInitiativeRole[],
  key: string,
  useHoursForMeasurements?: boolean
) {
  return roles.reduce((total, r) => {
    const fteForMonth = r.distribution![key] ? r.distribution![key] : 0
    const hoursPerMonth = useHoursForMeasurements ? 1 : WORKING_HOURS_IN_MONTH

    return total + fteForMonth * r.costPerHour! * hoursPerMonth
  }, 0)
}

export function getDistributionSum(distribution: IDistribution): number {
  return reduce(distribution, (sum, count) => sum + count, 0)
}

function calculateNetValue(
  plannedBenefit: number | null,
  costs: IInitiativeCosts,
  usePeopleCostOnConflictCalculation
): IInitiativeNetValue {
  const overallCost = usePeopleCostOnConflictCalculation
    ? getOverallCostSum(costs)
    : getFixedCostSum(costs)
  const calculatedNetValue = plannedBenefit! - overallCost!
  return {
    plannedBenefit: plannedBenefit || 0,
    calculatedNetValue,
  }
}

export function getOverallCostSum(costs: IInitiativeCosts) {
  return getDistributionSum(costs.overallCostDistribution!)
}

export function getFixedCostSum(costs: IInitiativeCosts) {
  return getDistributionSum(costs.fixedCostDistribution!)
}

export function getPeopleCostSum(costs: IInitiativeCosts) {
  return getDistributionSum(costs.peopleCostDistribution!)
}

export const hasInitiativeChanges = (
  scenario: IScenario | null,
  currentInitiative: IInitiative | null
): boolean => {
  if (currentInitiative !== null && scenario !== null) {
    const initiative = scenario!.initiatives.find(
      (initiativeItem) => initiativeItem.id === currentInitiative.id
    )
    return !isEqual(serializeInitiative(initiative!), serializeInitiative(currentInitiative))
  }
  return false
}

export function recalculatesInitiativesNetValues(
  initiatives: IInitiative[],
  usePeopleCostOnConflictCalculation: boolean
): IInitiative[] {
  // here initiative.netValue and initiative.costs can't be null
  // as  when it's null we never call recalculatesInitiativesNetValues
  return initiatives.map((initiative) => {
    const netValue = {
      ...calculateNetValue(
        initiative.netValue!.plannedBenefit,
        initiative.costs!,
        usePeopleCostOnConflictCalculation
      ),
    }
    return {
      ...initiative,
      netValue,
    }
  })
}
