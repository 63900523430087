import isEqual from 'lodash/isEqual'
import moment from 'moment'
import {IInitiative, IPlan, IScenario} from '../models'
import {createScenario, deserializeScenario, serializeScenario} from './ScenarioHelper'
import {WORKING_HOURS_IN_MONTH} from '../constants/Measurements'
import forEach from 'lodash/forEach'
import {getInitiativeWithCalculatedFields} from './InitiativeHelper'
import {calculateScenarioBudgetCosts} from './scenario/ScenarioBudgetCalculationHelper'
import {
  calculateAvailableTotal,
  calculateRequiredTotal,
  calculateRequiredValue,
} from './scenario/ScenarioRolesCalculationHelper'

import nanoid from 'nanoid'

const ISO_8601 = 'YYYY-MM-DDTHH:mm:ssZ'

export function createPlan(hasFinancialAccess: boolean): IPlan {
  const id = nanoid()
  const startDate = moment().startOf('month')
  const scenario = createScenario(hasFinancialAccess, true, startDate)
  return {
    id,
    name: '',
    owner: null,
    duration: 1,
    startDate: startDate,
    scenarios: [scenario],
    scenarioCount: 1,
    createdOn: null,
    lastUpdatedOn: null,
    settings: {
      usePeopleCostOnConflictCalculation: true, // TODO get from selector
      useHoursForMeasurements: false,
    },
  }
}

export function deserializePlan(planJson, hasFinancialAccess: boolean): IPlan {
  const startDate = moment.utc(planJson.startDate)

  return {
    id: planJson.id,
    name: planJson.name,
    duration: planJson.duration,
    owner: {
      id: planJson.owner.ID,
      name: planJson.owner.name,
    },
    startDate: startDate,
    settings: planJson.settings,
    scenarios: planJson.scenarios?.map((scenarioJson, index) => {
      const order = planJson.scenarios[0].isInitial ? index : index + 1
      return deserializeScenario(
        scenarioJson,
        startDate,
        planJson.duration,
        order,
        planJson.settings.usePeopleCostOnConflictCalculation,
        hasFinancialAccess
      )
    }),
    scenarioCount: planJson.scenarios?.length,
    lastUpdatedOn: moment(planJson.lastUpdatedOn),
    createdOn: moment(planJson.createdOn),
    accessorObjects: planJson.accessorObjects,
  }
}

export function serializePlan(plan: IPlan): Record<string, unknown> {
  return {
    id: !isNewPlan(plan) ? plan.id : null,
    name: plan.name,
    startDate: plan.startDate.format(ISO_8601),
    duration: plan.duration,
    settings: plan.settings,
    scenarios: plan.scenarios.map((scenario) => serializeScenario(scenario)),
  }
}

export function isNewPlan(plan: IPlan): boolean {
  return plan.createdOn === null
}

export const hasPlanChanges = (savedPlan: IPlan, currentPlan: IPlan): boolean => {
  return isNewPlan(currentPlan) || !isEqual(serializePlan(savedPlan!), serializePlan(currentPlan))
}

export function hasPublishedScenario(plan: IPlan): boolean {
  return plan.scenarios.some((scenario) => scenario.isPublished)
}

export const getHourMeasurement = () => WORKING_HOURS_IN_MONTH

export const getFTEMeasurement = () => 1 / WORKING_HOURS_IN_MONTH

const getMeasuredValue = (value: number | null, measurement: number) =>
  value !== null ? value * measurement : value

export const convertToRightMeasurement = (
  scenarios: IScenario[],
  measurement: number,
  usePeopleCostOnConflictCalculation?: boolean,
  hasFinancialAccess?: boolean
) => {
  scenarios.forEach((scenario) => {
    const scenarioRoles = convertPeopleRoles(scenario.people.roles, measurement)
    scenario.people = {
      ...scenario.people,
      available: calculateAvailableTotal(scenarioRoles),
      required: calculateRequiredTotal(scenarioRoles),
      roles: scenarioRoles,
    }

    // converting initiative people
    scenario.initiatives = convertInitiativesData(
      scenario.initiatives,
      measurement,
      usePeopleCostOnConflictCalculation,
      hasFinancialAccess
    )

    if (typeof hasFinancialAccess !== 'undefined' && scenario.budgets) {
      const {peopleCosts, costs} = calculateScenarioBudgetCosts(
        scenario.initiatives,
        usePeopleCostOnConflictCalculation! // usePeopleCostOnConflictCalculation can't be undefined when exists hasFinancialAccess
      )
      scenario.budgets = {
        ...scenario.budgets,
        peopleCosts,
        costs,
      }
    }
  })
}

const convertPeopleRoles = (roles, measurement) => {
  return roles.map((role) => {
    const requiredPerPeriod = convertPerPeriodObject(role.requiredPerPeriod, measurement)
    const availablePerPeriod = convertPerPeriodObject(role.availablePerPeriod, measurement)

    return {
      ...role,
      availableValue: calculateRequiredValue(availablePerPeriod, measurement > 1),
      requiredValue: calculateRequiredValue(requiredPerPeriod, measurement > 1),
      availablePerPeriod: availablePerPeriod,
      requiredPerPeriod: requiredPerPeriod,
      changes: role.changes.map((item) => {
        item.change = item.change * measurement
        return item
      }),
    }
  })
}

const convertPerPeriodObject = (perPeriodObj, measurement) => {
  const obj = {}
  forEach(perPeriodObj, (value, key) => {
    obj[key] = value * measurement
  })
  return obj
}

export const convertInitiativesData = (
  initiatives: IInitiative[],
  measurement: number,
  usePeopleCostOnConflictCalculation?: boolean,
  hasFinancialAccess?: boolean
) => {
  return initiatives.map((initiative) => {
    initiative.people.total = getMeasuredValue(initiative.people.total, measurement)
    initiative.people.roles.forEach((role) => {
      forEach(
        role.distribution,
        (value, key) => (role.distribution![key] = role.distribution![key] * measurement)
      )
    })

    if (typeof usePeopleCostOnConflictCalculation !== 'undefined') {
      return getInitiativeWithCalculatedFields(
        initiative,
        usePeopleCostOnConflictCalculation,
        hasFinancialAccess,
        measurement > 1
      )
    }
    return initiative
  })
}
