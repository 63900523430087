import cloneDeep from 'lodash/cloneDeep'

import {toast} from '@phoenix/all'
import moment from 'moment'
import {TimelineMode} from '../../shared/enums'
import {
  cloneScenario,
  convertToRightMeasurement,
  createPlan,
  deserializePlan,
  deserializeScenario,
  getFTEMeasurement,
  getHourMeasurement,
} from '../../shared/helpers'
import {IPlan, IPublishedInitiativeObj, IScenario} from '../../shared/models'
import {PlanService} from '../../shared/services'
import {changeCurrentScenario, currentScenarioInitiativesSelector} from '../scenario'
import {financialAccessDeniedSelector} from '../settings'
import {
  ADD_SCENARIO,
  CHANGE_PLAN_SETTINGS,
  CHANGE_TIMELINE_CONTAINER_WIDTH,
  CHANGE_TIMELINE_MODE,
  CREATE_NEW_PLAN_FAILURE,
  CREATE_NEW_PLAN_SUCCESS,
  DELETE_PLAN_FAILURE,
  DELETE_PLAN_SUCCESS,
  DELETE_SCENARIO,
  DESELECT_ALL_INITIATIVES,
  DESELECT_INITIATIVE,
  LOAD_PLAN_FAILURE,
  LOAD_PLAN_SUCCESS,
  RECALCULATE_PLAN_SCENARIOS_BUDGETS,
  RESET_PUBLISHED_SCENARIO,
  SET_LAST_SELECTED_INITIATIVE,
  SET_SELECTED_INITIATIVES,
  TOGGLE_INITIATIVE_SELECTION,
  UPDATE_CURRENT_PLAN,
  UPDATE_PLAN_ACCESSOR_OBJECTS,
  UPDATE_PLAN_FAILURE,
  UPDATE_PLAN_SUCCESS,
  UPDATE_PLAN_WITH_STARTDATE_OR_DURATION,
  UPDATE_SCENARIO,
  UPDATE_SCENARIO_INITIATIVES_REF_OBJECT,
} from './actions'
import {lastSelectedInitiativeIdSelector} from './selectors/lastSelectedInitiativeIdSelector'
import {selectedInitiativesSelector} from './selectors/selectedInitiativesSelector'
import {hoursForMeasurementsSelector} from './selectors/hoursForMeasurementsSelector'
import {peopleCostOnConflictCalculationSelector} from './selectors/peopleCostOnConflictCalculationSelector'
import {translate} from '../../shared/utilities/TranslateHelper'

const planService = new PlanService()

export function loadPlan(planId: string | null, scenarioId: string | null) {
  return (dispatch, getState) => {
    const state = getState()
    const hasFinancialAccess = !financialAccessDeniedSelector(state)

    if (planId === null) {
      return Promise.resolve(createPlan(hasFinancialAccess)).then((newPlan) => {
        dispatch(loadPlanSuccess(newPlan))
        dispatch(changeCurrentScenario(cloneDeep(newPlan.scenarios[0])))
      })
    } else {
      return planService.getPlan(planId).then(
        (loadedPlanJson) => {
          const useHoursForMeasurements = loadedPlanJson.settings?.useHoursForMeasurements

          const plan = deserializePlan(loadedPlanJson, hasFinancialAccess)

          const usePeopleCostOnConflictCalculation = !!plan.settings!
            .usePeopleCostOnConflictCalculation

          if (useHoursForMeasurements) {
            const measurement = getHourMeasurement()
            convertToRightMeasurement(
              plan.scenarios,
              measurement,
              usePeopleCostOnConflictCalculation,
              hasFinancialAccess
            )
          }

          const accessorObjects = loadedPlanJson.accessorObjects || []
          const scenario =
            scenarioId !== null
              ? plan.scenarios.find((planScenario) => planScenario.id === scenarioId)
              : null
          const currentScenario =
            scenario !== undefined ? cloneDeep(scenario) : cloneDeep(plan.scenarios[0])
          // This is the case when user opens compare screen directly from link.
          // In this case the the last scenario link should go to initial scenario
          const lastScenario = currentScenario !== null ? null : cloneDeep(plan.scenarios[0])
          dispatch(loadPlanSuccess(plan))
          dispatch(
            changeCurrentScenario(currentScenario, lastScenario, usePeopleCostOnConflictCalculation)
          )
          dispatch(updatePlanAccessorObjects(accessorObjects))
        },
        (error) => dispatch(loadPlanFail(error))
      )
    }
  }
}

const loadPlanSuccess = (plan) => (dispatch, getState) => {
  const hasFinancialAccess = !financialAccessDeniedSelector(getState())

  dispatch({
    type: LOAD_PLAN_SUCCESS,
    plan,
    hasFinancialAccess,
  })
}

function loadPlanFail(error) {
  return {
    type: LOAD_PLAN_FAILURE,
    error,
  }
}

export function updatePlan(
  plan: IPlan,
  currentScenarioIndex: number | null,
  lastScenario: IScenario,
  hasFinancialAccess: boolean
) {
  return function (dispatch, getState) {
    const state = getState()
    const useHoursForMeasurements = hoursForMeasurementsSelector(state)
    let clonedPlan

    if (useHoursForMeasurements) {
      const measurement = getFTEMeasurement()
      clonedPlan = cloneDeep(plan)
      convertToRightMeasurement(clonedPlan.scenarios, measurement)
    }

    return planService.putPlan(clonedPlan || plan).then(
      (updatedPlan: IPlan) => {
        const usePeopleCostOnConflictCalculation = !!updatedPlan.settings!
          .usePeopleCostOnConflictCalculation

        const scenario =
          currentScenarioIndex !== null
            ? deserializeScenario(
                updatedPlan.scenarios[currentScenarioIndex],
                moment.utc(updatedPlan.startDate),
                updatedPlan.duration,
                currentScenarioIndex,
                usePeopleCostOnConflictCalculation,
                hasFinancialAccess
              )
            : null

        if (useHoursForMeasurements && scenario) {
          const measurement = getHourMeasurement()
          convertToRightMeasurement(
            [scenario],
            measurement,
            usePeopleCostOnConflictCalculation,
            hasFinancialAccess
          )
        }

        dispatch(changeCurrentScenario(scenario, lastScenario, usePeopleCostOnConflictCalculation))
        return dispatch(updatePlanSuccess(updatedPlan, useHoursForMeasurements))
      },
      (error) => Promise.reject(dispatch(updatePlanFail(error)))
    )
  }
}

export function updatePlanAccessorObjects(accessorObjects) {
  return {
    type: UPDATE_PLAN_ACCESSOR_OBJECTS,
    accessorObjects,
  }
}

const updatePlanSuccess = (plan, useHoursForMeasurements: boolean) => (dispatch, getState) => {
  const state = getState()
  const hasFinancialAccess = !financialAccessDeniedSelector(state)

  const deserializedPlan = deserializePlan(plan, hasFinancialAccess)

  if (useHoursForMeasurements) {
    // may be also need convert budgets
    const measurement = getHourMeasurement()
    convertToRightMeasurement(deserializedPlan.scenarios, measurement)
  }

  dispatch({
    type: UPDATE_PLAN_SUCCESS,
    plan: deserializedPlan,
    hasFinancialAccess,
  })
}

function updatePlanFail(error) {
  return {
    type: UPDATE_PLAN_FAILURE,
    error,
  }
}

export function createNewPlan(plan: IPlan) {
  return function (dispatch, getState) {
    const state = getState()
    const useHoursForMeasurements = hoursForMeasurementsSelector(state)
    let clonedPlan

    if (useHoursForMeasurements) {
      const measurement = getFTEMeasurement()
      clonedPlan = cloneDeep(plan)
      convertToRightMeasurement(clonedPlan.scenarios, measurement)
    }

    return planService.postPlan(clonedPlan || plan).then(
      (createdPlan) => {
        toast.success(translate('sp.messages.plan.save.initial.scenario'))
        // As there are no calculations done in backend, we don't change the current scenario at this point
        // Otherwise we will need to update the current scenario here
        return dispatch(createNewPlanSuccess(createdPlan, useHoursForMeasurements))
      },
      (error) => dispatch(createNewPlanFail(error))
    )
  }
}

const createNewPlanSuccess = (plan, useHoursForMeasurements: boolean) => (dispatch, getState) => {
  const state = getState()
  const usePeopleCostOnConflictCalculation = peopleCostOnConflictCalculationSelector(state)
  const hasFinancialAccess = !financialAccessDeniedSelector(state)

  const deserializedPlan = deserializePlan(plan, hasFinancialAccess)

  if (useHoursForMeasurements) {
    const measurement = getHourMeasurement()
    convertToRightMeasurement(
      deserializedPlan.scenarios,
      measurement,
      usePeopleCostOnConflictCalculation,
      hasFinancialAccess
    )
  }

  return dispatch({
    type: CREATE_NEW_PLAN_SUCCESS,
    plan: deserializedPlan,
    hasFinancialAccess,
  })
}

function createNewPlanFail(error) {
  return {
    type: CREATE_NEW_PLAN_FAILURE,
    error,
  }
}

export function deletePlan(plan: IPlan) {
  return function (dispatch) {
    return planService.deletePlan(plan).then(
      () => {
        dispatch(deletePlanSuccess())
        toast.success(translate('sp.messages.plan.delete'))
      },
      (error) => dispatch(deletePlanFail(error))
    )
  }
}

function deletePlanFail(error) {
  return {
    type: DELETE_PLAN_FAILURE,
    error,
  }
}

function deletePlanSuccess() {
  return {
    type: DELETE_PLAN_SUCCESS,
  }
}

export function changeTimelineMode(timelineMode: TimelineMode) {
  return {
    type: CHANGE_TIMELINE_MODE,
    timelineMode,
  }
}

export function changeTimelineContainerWidth(containerWidth: number) {
  return {
    type: CHANGE_TIMELINE_CONTAINER_WIDTH,
    containerWidth,
  }
}

export function updateCurrentPlan(changes: Record<string, unknown>) {
  return {
    type: UPDATE_CURRENT_PLAN,
    changes,
  }
}

export function updateCurrentPlanStartDateOrDuration(changes: Record<string, unknown>) {
  return {
    type: UPDATE_PLAN_WITH_STARTDATE_OR_DURATION,
    changes,
  }
}

export function addScenario(scenario: IScenario) {
  return {
    type: ADD_SCENARIO,
    scenario,
  }
}

export const updateScenario = (scenario: IScenario) => (dispatch, getState) => {
  const hasFinancialAccess = !financialAccessDeniedSelector(getState())

  dispatch(deselectAllInitiatives())
  dispatch({
    type: UPDATE_SCENARIO,
    scenario,
    hasFinancialAccess,
  })
}

export function updateScenarioInitiativesRefobjects(
  publishedInitiativesMap: Map<string, IPublishedInitiativeObj>,
  publishedScenarioId: string
) {
  return {
    type: UPDATE_SCENARIO_INITIATIVES_REF_OBJECT,
    publishedInitiativesMap,
    publishedScenarioId,
  }
}

export function copyScenario(scenario: IScenario) {
  return function (dispatch) {
    const scenarioCopy = cloneScenario(scenario)
    dispatch(addScenario(scenarioCopy))
  }
}

export function copyScenarioAndNavigate(
  scenario: IScenario,
  order: number,
  usePeopleCostOnConflictCalculation: boolean
) {
  return function (dispatch) {
    return Promise.resolve(cloneScenario(scenario, order)).then((scenarioCopy) => {
      dispatch(addScenario(scenarioCopy))
      dispatch(
        changeCurrentScenario(cloneDeep(scenarioCopy), null, usePeopleCostOnConflictCalculation)
      )
    })
  }
}

export function deleteScenario(scenario: IScenario) {
  return {
    type: DELETE_SCENARIO,
    scenario,
  }
}

export function resetPublishedScenario() {
  return {
    type: RESET_PUBLISHED_SCENARIO,
  }
}

export const toggleInitiativeSelection = (initiativeId) => (dispatch) => {
  dispatch({
    type: TOGGLE_INITIATIVE_SELECTION,
    initiativeId,
  })
  dispatch(setLastSelectedInitiativeId(initiativeId))
}

export function deselectInitiative(initiativeId) {
  return {
    type: DESELECT_INITIATIVE,
    initiativeId,
  }
}

function setSelectedInitiatives(initiativeIds: string[]) {
  const selectedInitiatives = initiativeIds.reduce((acc, id) => {
    acc[id] = true
    return acc
  }, {})

  return {
    type: SET_SELECTED_INITIATIVES,
    selectedInitiatives,
  }
}

export const selectAllInitiatives = () => (dispatch, getState) => {
  const state = getState()

  const currentScenarioInitiatives = currentScenarioInitiativesSelector(state)

  const ids = currentScenarioInitiatives.map((initiative) => initiative.id)

  dispatch(setSelectedInitiatives(ids))
}

export const deselectAllInitiatives = () => (dispatch) => {
  dispatch(setLastSelectedInitiativeId(''))
  dispatch({
    type: DESELECT_ALL_INITIATIVES,
  })
}

function changePlanSettingsAction(
  planSettings: {[key: string]: any},
  planKey: 'plan' | 'currentPlan'
) {
  return {
    type: CHANGE_PLAN_SETTINGS,
    planSettings,
    planKey,
  }
}

export function changePlanSettings(planSettings: {[key: string]: any}, planId?: string) {
  return function (dispatch, getState) {
    dispatch(changePlanSettingsAction(planSettings, 'currentPlan'))

    if (planId) {
      const settings = {
        ...getState().plan.currentPlan.settings,
        ...planSettings,
      }

      planService.putPlanSettings(planId, settings).then(() => {
        dispatch(changePlanSettingsAction(planSettings, 'plan'))
      })
    }
  }
}

export function recalculatePlanScenariosBudgets(usePeopleCostOnConflictCalculation: boolean) {
  return {
    type: RECALCULATE_PLAN_SCENARIOS_BUDGETS,
    usePeopleCostOnConflictCalculation,
  }
}

export function setLastSelectedInitiativeId(lastSelectedInitiativeId) {
  return {
    type: SET_LAST_SELECTED_INITIATIVE,
    lastSelectedInitiativeId,
  }
}

export const selectInitiativeRange = (endInitiativeId) => (dispatch, getState) => {
  const state = getState()

  const allInitiatives = currentScenarioInitiativesSelector(state)

  const selectedInitiatives = selectedInitiativesSelector(state)

  const lastSelectedInitiativeId = lastSelectedInitiativeIdSelector(state)

  let startInitiative = allInitiatives.find(
    (initiative) => initiative.id === lastSelectedInitiativeId
  )

  if (!startInitiative) {
    startInitiative = allInitiatives[0]
  }

  const updatedSelectedInitiatives = {
    ...selectedInitiatives,
  }

  if (startInitiative.id === endInitiativeId) {
    // There is only one selection in the range, just select it
    updatedSelectedInitiatives[endInitiativeId] = true
  } else {
    let firstEndpointFound = false

    for (let i = 0; i < allInitiatives.length; i++) {
      const initiative = allInitiatives[i]

      if (initiative.id === startInitiative.id || initiative.id === endInitiativeId) {
        updatedSelectedInitiatives[initiative.id] = true

        if (firstEndpointFound) {
          // We have encountered the second endpoint which means that we have already processed all the necessary initiatives
          break
        }
        firstEndpointFound = true
      } else if (firstEndpointFound) {
        // We have already encountered the first endpoint
        // which means that all the initiatives afterwards are in the selection range
        updatedSelectedInitiatives[initiative.id] = true
      }
    }
  }

  dispatch({
    type: SET_SELECTED_INITIATIVES,
    selectedInitiatives: updatedSelectedInitiatives,
  })
}
