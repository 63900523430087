import RoiIcon from 'phoenix-icons/dist/RoiIcon.js'
import {secondary} from '@phoenix/all'
import * as React from 'react'
import {connect} from 'react-redux'
import {activeScenarioSelector, currencySelector} from '../../../../data/plan'
import {formatCurrency} from '../../../../shared/utilities'
import {CircleInfoComponent, getArrowDirection, InfoComponent} from './shared'

export interface INetValueRowComponent {
  netValue: number | null
  activeScenarioNetValue: number | null
  currency: string
}

export const NetValueRow: React.FunctionComponent<INetValueRowComponent> = (
  props: INetValueRowComponent
) => {
  const {netValue, activeScenarioNetValue, currency} = props
  const color: null | string = netValue !== null && netValue < 0 ? secondary.red(400) : null

  const abbreviatedValue = netValue !== null ? formatCurrency(netValue, currency) : '-'
  const value = netValue !== null ? formatCurrency(netValue, currency, false) : '-'
  const arrowDirection = getArrowDirection(netValue, activeScenarioNetValue)
  return (
    <>
      <CircleInfoComponent
        icon={<RoiIcon />}
        color={color}
        part={netValue !== null && netValue < 0 ? 100 : 0}
        total={100}
      />
      <InfoComponent
        labelMessageKey={'scenario.widget.net'}
        arrowDirection={arrowDirection}
        abbreviatedValue={abbreviatedValue!}
        value={value!}
        color={color}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  activeScenarioNetValue: activeScenarioSelector(state).netValue,
  currency: currencySelector(state),
})

export const NetValueRowComponent = connect(mapStateToProps)(NetValueRow)
