import {Button, primary, Textarea, Text} from '@phoenix/all'
import {css} from 'emotion'
import Linkify from 'linkifyjs/react'
import {useState} from 'react'
import * as React from 'react'
import {connect} from 'react-redux'

import {updateScenario} from '../../../../data/plan'
import {viewAccessSelector} from '../../../../data/settings'
import {IScenario} from '../../../../shared/models/scenario'
import {Translate} from '../../../shared/Translate'

interface IDescriptionRowComponent {
  scenario: IScenario
  hasEditAccess: boolean
  updateScenarioFunction: (scenario) => void
}

const addDescriptionClass = `
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
`
const addDescriptionButtonClass = css`
  ${addDescriptionClass};
  color: ${primary.blue(400)};
  cursor: pointer;
`

const addDescriptionDisabledButtonClass = css`
    ${addDescriptionClass}
    color: ${primary.gray(300)};
`

const descriptionClass = css`
  white-space: normal;
  word-break: break-all;
  height: 77px;

  &:after {
    content: '';
    clear: both;
    display: block;
  }
`

const textAreaWrapperClass = css`
  margin: 0 -6px;
  height: 100%;

  label {
    display: none;
  }
  textarea + div {
    float: left;
    padding-left: 7px;
    height: 20px;
  }
  textarea {
    margin: 0;
    color: ${primary.gray(500)};
    font-size: 12px;
  }
`

const addDescriptionButtonDefaultClass = css`
  height: auto;
  padding: 0;
`

const descriptionContentClass = css`
  white-space: pre-wrap;
  overflow: hidden;
  height: 5rem;
  &:hover {
    background-color: rgba(230, 241, 251, 0.5);
  }

  & > span {
    color: ${primary.gray(500)};
    font-size: 12px;
    width: 100%;
    display: inline-block;
  }
`

const descriptionTextareaClass = css`
  height: calc(100% - 18px);
  resize: none;
`
const descriptionShadowClass = css`
  position: relative;

  &:after {
    position: absolute;
    bottom: 0;
    height: 1rem;
    width: 100%;
    left: 0;
    content: '';
    display: block;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.39) 27.53%,
      ${primary.gray(0)} 99.92%
    );
  }
`
export const DescriptionRow: React.FunctionComponent<IDescriptionRowComponent> = (props) => {
  const wrapper = React.useRef(null)

  const [isEditMode, setEditMode] = useState(false)
  const [hasShadow, setShadow] = useState(false)
  const {scenario, hasEditAccess, updateScenarioFunction} = props
  const toggleEditMode = () => {
    setEditMode(!isEditMode)
  }
  const handleKeyDown = (event) => {
    const keyboardClickKeys = ['Enter', 'Space', ' ']
    if (keyboardClickKeys.includes(event.key)) {
      toggleEditMode()
    }
  }
  const handleEsc = (event) => {
    if (event.key === 'Escape') {
      toggleEditMode()
    }
  }

  const setDescription = (value) => {
    const updatedScenario = {
      ...scenario,
      description: value,
    }
    updateScenarioFunction(updatedScenario)
  }

  const options = {
    attributes: {
      rel: 'noopener noreferrer',
      onClick: (event) => {
        event.stopPropagation()
      },
    },
  }

  React.useEffect(() => {
    const element: HTMLDivElement = wrapper && wrapper.current!
    if (element !== null && element.querySelector('span')!.offsetHeight > element.offsetHeight) {
      if (!hasShadow) {
        setShadow(true)
      }
    } else {
      if (hasShadow) {
        setShadow(false)
      }
    }
  }, [isEditMode, wrapper])

  React.useEffect(() => {
    const textarea: HTMLInputElement | null = document.querySelector('[data-testid=description]')
    if (isEditMode && textarea) {
      textarea.focus()
    }
  }, [isEditMode])

  return (
    <React.Fragment>
      <div className={`card-description ${descriptionClass}`}>
        {!scenario.description && !isEditMode && (
          <Button
            text
            data-testid="add-description"
            className={`${
              hasEditAccess ? addDescriptionButtonClass : addDescriptionDisabledButtonClass
            } ${addDescriptionButtonDefaultClass} add-description`}
            onClick={toggleEditMode}
            disabled={!hasEditAccess}
          >
            +
            <Translate messageKey={'scenario.add.description'} />
          </Button>
        )}
        {scenario.description && !isEditMode && (
          <div
            role="button"
            className={`${descriptionContentClass} ${hasShadow && descriptionShadowClass}`}
            onClick={toggleEditMode}
            tabIndex={0}
            onKeyDown={handleKeyDown}
            ref={wrapper}
          >
            <Text>
              <Linkify options={options}>{scenario.description}</Linkify>
            </Text>
          </div>
        )}
        {isEditMode && (
          <div className={textAreaWrapperClass}>
            <Textarea
              onKeyDown={handleEsc}
              testID="description"
              onChange={(event) => hasEditAccess && setDescription(event.target.value)}
              onBlur={(event) => {
                toggleEditMode()
                if (hasEditAccess) {
                  setDescription(event.target.value.trim())
                }
              }}
              label={<Translate messageKey={'form.description'} />}
              name="description"
              className={descriptionTextareaClass}
              value={scenario.description === null ? '' : scenario.description}
              helpText=" "
              maxLength={4000}
              hideMaxLengthInfo={false}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  hasEditAccess: !viewAccessSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  updateScenarioFunction: (scenario) => dispatch(updateScenario(scenario)),
})
export const DescriptionRowComponent = connect(mapStateToProps, mapDispatchToProps)(DescriptionRow)
