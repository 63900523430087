import cloneDeep from 'lodash/cloneDeep'
import {
  calculateNetValueTotal,
  createTimelineResolvedRoles,
  generateBudgetDistributions,
  getInitiativeWithCalculatedFields,
  processBudgetAfterInitiativeCopy,
  processBudgetAfterInitiativeDelete,
  processBudgetAfterInitiativeListChange,
  processBudgetAfterInitiativeUpdate,
  processBudgetAfterScenarioBudgetUpdate,
  processNetValueAfterInitiativeCopy,
  processNetValueAfterInitiativeDelete,
  processNetValueAfterInitiativeUpdate,
  processPeopleAfterConflictsResolved,
  processPeopleAfterInitiativeCopy,
  processPeopleAfterInitiativeDateChange,
  processPeopleAfterInitiativeDelete,
  processPeopleAfterInitiativeRoleChanges,
  processPeopleAfterInitiativesImport,
  processPeopleAfterScenarioRoleDelete,
  processPeopleAfterScenarioRoleUpdate,
  recalculatesInitiativesNetValues,
  track,
  updateRoleChanges,
} from '../../shared/helpers'
import {
  updateAllConflictsInScenario,
  updateBudgetConflicts,
  updateBudgetConflictsInScenario,
  updateConflictsAfterInitiativeUpdate,
  updateSingleRoleConflicts,
} from '../../shared/helpers/conflicts/ConflictRecalculationHandlers'
import {IInitiative, IScenarioPeople} from '../../shared/models'
import {
  ADD_INITIATIVE,
  ADD_SCENARIO_ROLE,
  CHANGE_CURRENT_SCENARIO,
  CHANGE_LAST_SCENARIO_AFTER_COPY,
  CLEAR_OLD_HIGHLIGHTED_COLUMN_INDEX,
  COPY_INITIATIVES,
  DELETE_INITIATIVE,
  DELETE_INITIATIVES,
  DELETE_SCENARIO_ROLE,
  FINALIZE_BULK_DRAG,
  HIDE_INITIATIVES,
  IMPORT_INITIATIVES,
  MARK_DRAGGABLE_INITIATIVES,
  PRIORITIZE_INITIATIVES,
  RECALCULATE_BUDGET_CONFLICTS,
  REMOVE_INITIATIVES_FROM_ORDER,
  REORDER_INITIATIVES,
  RESOLVE_CONFLICTS,
  SET_HIGHLIGHTED_COLUMN_INDEX,
  SET_IMPORTED_INITIATIVES_IDS,
  SET_INITIATIVE_ROW_TRANSITION,
  SET_UNSAVED_CHANGES_DIALOG_VISIBILITY,
  SHOW_INITIATIVES,
  SHRINK_INITIATIVES,
  TOGGLE_CONFLICTS,
  UNSHRINK_INITIATIVES,
  UPDATE_CURRENT_SCENARIO_ACCORDING_PLAN_STARTDATE_AND_DURATION,
  UPDATE_INITIATIVE,
  UPDATE_INITIATIVE_AFTER_DRAG_DROP,
  UPDATE_INITIATIVE_AND_RECALCULATE_CONFLICTS,
  UPDATE_INITIATIVE_DATES,
  UPDATE_INITIATIVE_NAME,
  UPDATE_SCENARIO_BUDGET,
  UPDATE_SCENARIO_ROLE,
} from './actions'
import {IScenarioState} from './IScenarioState'

export const scenarioInitialState: IScenarioState = {
  currentScenario: null,
  lastScenarioAfterCopy: null,
  lastScenario: null,
  timelineResolvedRoles: null,
  showConflicts: true, // the value of this field is loaded from localstorage when store is created
  firstImportedInitiativeIndex: null,
  importedInitiativesIds: [],
  draggableInitiatives: [],
  draggableInitiativeId: '',
  deleteInitiativesConfirmDialogData: {
    isOpen: false,
    deleteMultiple: false,
  },
  shrinkInitiativeIds: [],
  hiddenInitiativeIds: [],
  enableInitiativeRowTransition: false,
  highlightedColumnIndex: null,
}

export function scenario(state = scenarioInitialState, action): IScenarioState {
  switch (action.type) {
    case CHANGE_CURRENT_SCENARIO: {
      const clonedScenario = action.scenario ? cloneDeep(action.scenario) : null
      if (clonedScenario !== null) {
        updateAllConflictsInScenario(
          state.showConflicts,
          clonedScenario,
          action.usePeopleCostOnConflictCalculation,
          action.hasFinancialAccess
        )
      }

      return {
        ...state,
        currentScenario: clonedScenario,
        lastScenario: action.lastScenario,
        timelineResolvedRoles: action.scenario
          ? createTimelineResolvedRoles(action.scenario.people.roles)
          : null,
      }
    }
    case ADD_INITIATIVE: {
      const initiativesCount = state.currentScenario!.initiatives.length
      const lastInitiative =
        initiativesCount > 0 ? state.currentScenario!.initiatives[initiativesCount - 1] : null
      let newInitiative: IInitiative = {
        ...action.initiative,
        order: state.currentScenario!.initiatives.length + 1,
      }
      if (lastInitiative !== null && lastInitiative.conflicts !== null) {
        newInitiative = {
          ...newInitiative,
          conflicts: {
            roleConflicts: new Map(),
            budgetConflicts: null,
            isPriorityConflict: true,
          },
        }
      }
      const initiatives = [...state.currentScenario!.initiatives, newInitiative]
      return {
        ...state,
        currentScenario: {
          ...state.currentScenario!,
          initiatives,
        },
      }
    }
    case UPDATE_INITIATIVE_NAME: {
      const initiatives = state.currentScenario!.initiatives.map((initiative) => {
        if (initiative.id === action.initiative.id) {
          return action.initiative
        } else {
          return initiative
        }
      })
      return {
        ...state,
        currentScenario: {
          ...state.currentScenario!,
          initiatives,
        },
      }
    }
    case UPDATE_INITIATIVE_AFTER_DRAG_DROP: {
      const correspondingInitiative = state.currentScenario!.initiatives.find(
        (initiative) => initiative.id === action.initiative.id
      )!

      const scenarioPeople = processPeopleAfterInitiativeDateChange(
        state.currentScenario!.people,
        correspondingInitiative!,
        action.initiative,
        action.useHoursForMeasurements
      )

      const scenario = {
        ...state.currentScenario!,
        people: scenarioPeople,
      }
      updateConflictsAfterInitiativeUpdate(
        state.showConflicts,
        scenario,
        action.initiative,
        action.usePeopleCostOnConflictCalculation
      )

      return {
        ...state,
        currentScenario: scenario,
      }
    }
    case UPDATE_INITIATIVE_DATES: {
      const correspondingInitiativeIndex = state.currentScenario!.initiatives.findIndex(
        (initiative) => initiative.id === action.initiative.id
      )

      const newInitiatives = [...state.currentScenario!.initiatives]
      newInitiatives.splice(correspondingInitiativeIndex, 1, action.initiative)

      return {
        ...state,
        currentScenario: {
          ...state.currentScenario!,
          initiatives: newInitiatives,
        },
      }
    }
    case UPDATE_INITIATIVE_AND_RECALCULATE_CONFLICTS: {
      const correspondingInitiative = state.currentScenario!.initiatives.find(
        (initiative) => initiative.id === action.initiative.id
      )!
      const updatedInitiative = getInitiativeWithCalculatedFields(
        action.initiative,
        action.usePeopleCostOnConflictCalculation,
        action.hasFinancialAccess,
        action.useHoursForMeasurements
      )

      let netValue = state.currentScenario!.netValue
      let scenarioBudgets = state.currentScenario!.budgets

      if (action.hasFinancialAccess) {
        netValue = processNetValueAfterInitiativeUpdate(
          state.currentScenario!.netValue,
          action.initiative.netValue.calculatedNetValue,
          updatedInitiative.netValue!.calculatedNetValue
        )

        scenarioBudgets = processBudgetAfterInitiativeUpdate(
          state.currentScenario!.budgets!, //budgets cant be null when hasFinancialAccess
          correspondingInitiative.costs!,
          updatedInitiative.costs!,
          action.usePeopleCostOnConflictCalculation
        )
      }

      const scenarioPeople = processPeopleAfterInitiativeDateChange(
        state.currentScenario!.people,
        correspondingInitiative!,
        action.initiative,
        action.useHoursForMeasurements
      )

      const scenario = {
        ...state.currentScenario!,
        people: scenarioPeople,
        budgets: scenarioBudgets,
        netValue,
      }
      updateConflictsAfterInitiativeUpdate(
        state.showConflicts,
        scenario,
        updatedInitiative,
        action.usePeopleCostOnConflictCalculation
      )

      return {
        ...state,
        currentScenario: scenario,
      }
    }
    case IMPORT_INITIATIVES: {
      const people = processPeopleAfterInitiativesImport(
        state.currentScenario!.people,
        action.initiatives,
        action.planInfo,
        action.useHoursForMeasurements
      )

      const initiatives = [
        ...state.currentScenario!.initiatives,
        ...action.initiatives,
      ].map((initiative, index) => ({...initiative, order: index + 1}))

      const budgets = action.hasFinancialAccess
        ? processBudgetAfterInitiativeListChange(
            state.currentScenario!.budgets!, // can't be null when hasFinancialAccess
            initiatives,
            action.usePeopleCostOnConflictCalculation
          )
        : state.currentScenario!.budgets

      const netValue = action.hasFinancialAccess
        ? calculateNetValueTotal(initiatives)
        : state.currentScenario!.netValue

      const scenario = {
        ...state.currentScenario!,
        people,
        budgets,
        netValue,
        initiatives,
      }
      updateAllConflictsInScenario(
        state.showConflicts,
        scenario,
        action.usePeopleCostOnConflictCalculation,
        action.hasFinancialAccess
      )

      return {
        ...state,
        firstImportedInitiativeIndex: state.currentScenario!.initiatives.length,
        currentScenario: scenario,
      }
    }
    case UPDATE_INITIATIVE: {
      const correspondingInitiative = state.currentScenario!.initiatives.find(
        (initiative) => initiative.id === action.initiative.id
      )!

      const people = processPeopleAfterInitiativeRoleChanges(
        state.currentScenario!.people,
        correspondingInitiative!,
        action.initiative,
        action.planInfo,
        action.useHoursForMeasurements
      )

      let budgets = state.currentScenario!.budgets
      let netValue = state.currentScenario!.netValue

      if (action.hasFinancialAccess) {
        budgets = processBudgetAfterInitiativeUpdate(
          state.currentScenario!.budgets!, // can't be null when hasFinancialAccess
          correspondingInitiative.costs!,
          action.initiative.costs,
          action.usePeopleCostOnConflictCalculation
        )
        netValue = processNetValueAfterInitiativeUpdate(
          state.currentScenario!.netValue,
          correspondingInitiative.netValue!.calculatedNetValue,
          action.initiative.netValue.calculatedNetValue
        )
      }

      const scenario = {
        ...state.currentScenario!,
        people,
        budgets,
        netValue,
      }
      updateConflictsAfterInitiativeUpdate(
        state.showConflicts,
        scenario,
        action.initiative,
        action.usePeopleCostOnConflictCalculation,
        action.initiative.dates.startDate,
        action.initiative.dates.endDate
      )

      return {
        ...state,
        currentScenario: scenario,
      }
    }
    case REORDER_INITIATIVES: {
      const initiatives = action.initiatives.map((initiative, i) => ({
        ...initiative,
        conflicts: null,
        order: i + 1,
      }))
      const scenario = {
        ...state.currentScenario!,
        initiatives,
      }
      updateAllConflictsInScenario(
        state.showConflicts,
        scenario,
        action.usePeopleCostOnConflictCalculation,
        action.hasFinancialAccess
      )
      return {
        ...state,
        currentScenario: scenario,
      }
    }
    case REMOVE_INITIATIVES_FROM_ORDER: {
      let index = 0
      const initiatives = state.currentScenario!.initiatives.map((initiative) => {
        if (action.initiativeIds.indexOf(initiative.id) > -1) {
          return {...initiative}
        }

        index++
        return {
          ...initiative,
          order: index,
        }
      })

      return {
        ...state,
        currentScenario: {
          ...state.currentScenario!,
          initiatives,
        },
      }
    }
    // TODO: remove the DELETE_INITIATIVE action when sp-initiative-list-enhancements split is enabled
    case DELETE_INITIATIVE: {
      const currentScenario = state.currentScenario!

      const initiatives = currentScenario.initiatives
        .filter((initiative) => initiative.id !== action.initiative.id)
        .map((initiative, index) => ({...initiative, order: index + 1}))
      const publishedInitiativesCount =
        action.initiative.lastPublishedDate !== null
          ? currentScenario.publishedInitiativesCount - 1
          : currentScenario.publishedInitiativesCount

      const netValue = action.hasFinancialAccess
        ? processNetValueAfterInitiativeDelete(
            currentScenario.netValue,
            action.initiative.netValue.calculatedNetValue
          )
        : currentScenario.netValue

      const budgets = action.hasFinancialAccess
        ? processBudgetAfterInitiativeDelete(
            currentScenario.budgets!, // can't be null when hasFinancialAccess
            action.initiative.costs,
            action.usePeopleCostOnConflictCalculation
          )
        : currentScenario.budgets

      const people = processPeopleAfterInitiativeDelete(
        currentScenario.people,
        action.initiative,
        action.useHoursForMeasurements
      )

      if (!initiatives.length) {
        people.roles = people.roles.map((role) => ({
          ...role,
          changes: [],
        }))
      }

      const scenario = {
        ...currentScenario,
        people,
        budgets,
        netValue,
        initiatives,
        publishedInitiativesCount,
        isPublished: publishedInitiativesCount > 0,
      }
      updateConflictsAfterInitiativeUpdate(
        state.showConflicts,
        scenario,
        action.initiative,
        action.usePeopleCostOnConflictCalculation,
        action.initiative.dates.startDate,
        action.initiative.dates.endDate
      )

      const shrinkInitiativeIds = state.shrinkInitiativeIds.filter(
        (initiative) => initiative !== action.initiative.id
      )

      const hiddenInitiativeIds = state.hiddenInitiativeIds.filter(
        (initiative) => initiative !== action.initiative.id
      )

      return {
        ...state,
        currentScenario: scenario,
        shrinkInitiativeIds,
        hiddenInitiativeIds,
        timelineResolvedRoles: initiatives.length ? state.timelineResolvedRoles : null,
      }
    }
    case DELETE_INITIATIVES: {
      const currentScenario = state.currentScenario!

      const initiatives = currentScenario.initiatives
        .filter((initiative) => !action.selectedInitiativeIds[initiative.id])
        .map((initiative, index) => ({...initiative, order: index + 1}))

      const publishedInitiativesCount =
        currentScenario.publishedInitiativesCount - action.publishedInitiativeCount

      const netValue = action.hasFinancialAccess
        ? action.selectedInitiatives.reduce((accNetValue, initiative) => {
            return processNetValueAfterInitiativeDelete(
              accNetValue,
              initiative.netValue.calculatedNetValue
            )
          }, currentScenario.netValue)
        : currentScenario.netValue

      const budgets = action.hasFinancialAccess
        ? action.selectedInitiatives.reduce((accBudgets, initiative) => {
            return processBudgetAfterInitiativeDelete(
              accBudgets,
              initiative.costs,
              action.usePeopleCostOnConflictCalculation
            )
          }, currentScenario.budgets)
        : currentScenario.budgets

      const people = action.selectedInitiatives.reduce((accPeople, initiative) => {
        return processPeopleAfterInitiativeDelete(
          accPeople,
          initiative,
          action.useHoursForMeasurements
        )
      }, currentScenario.people)

      if (!initiatives.length) {
        people.roles = people.roles.map((role) => ({
          ...role,
          changes: [],
        }))
      }

      const scenario = {
        ...currentScenario,
        people,
        budgets,
        netValue,
        initiatives,
        publishedInitiativesCount,
        isPublished: publishedInitiativesCount > 0,
      }

      updateAllConflictsInScenario(
        state.showConflicts,
        scenario,
        action.usePeopleCostOnConflictCalculation,
        action.hasFinancialAccess
      )

      const selectedInitiativeIds = Object.keys(action.selectedInitiativeIds)
      const shrinkInitiativeIds = state.shrinkInitiativeIds.filter(
        (initiativeId) => selectedInitiativeIds.indexOf(initiativeId) === -1
      )

      const hiddenInitiativeIds = state.hiddenInitiativeIds.filter(
        (initiativeId) => selectedInitiativeIds.indexOf(initiativeId) === -1
      )

      return {
        ...state,
        currentScenario: scenario,
        shrinkInitiativeIds,
        hiddenInitiativeIds,
        timelineResolvedRoles: initiatives.length ? state.timelineResolvedRoles : null,
      }
    }
    case COPY_INITIATIVES: {
      const currentScenario = state.currentScenario!

      const indexOfOriginalInitiative = currentScenario.initiatives.indexOf(
        action.placeNextToInitiative
      )
      let initiatives = [...currentScenario.initiatives]
      initiatives.splice(indexOfOriginalInitiative + 1, 0, ...action.initiatives)

      initiatives = initiatives.map((initiative, index) => ({
        ...initiative,
        order: index + 1,
      }))

      const netValue = action.hasFinancialAccess
        ? action.initiatives.reduce(
            (netValue, initiative) =>
              processNetValueAfterInitiativeCopy(netValue, initiative.netValue.calculatedNetValue),
            currentScenario.netValue
          )
        : currentScenario.netValue

      const budgets = action.hasFinancialAccess
        ? action.initiatives.reduce(
            (budgets, initiative) =>
              processBudgetAfterInitiativeCopy(
                budgets,
                initiative.costs,
                action.usePeopleCostOnConflictCalculation
              ),
            currentScenario.budgets
          )
        : currentScenario.budgets

      const people = action.initiatives.reduce(
        (people, initiative) =>
          processPeopleAfterInitiativeCopy(
            people,
            initiative,
            action.planInfo,
            action.useHoursForMeasurements
          ),
        currentScenario.people
      )

      const scenario = {
        ...currentScenario,
        people,
        budgets,
        netValue,
        initiatives,
      }

      updateAllConflictsInScenario(
        state.showConflicts,
        scenario,
        action.usePeopleCostOnConflictCalculation,
        action.hasFinancialAccess
      )

      return {
        ...state,
        currentScenario: scenario,
      }
    }
    case PRIORITIZE_INITIATIVES: {
      const currentScenario = state.currentScenario!

      let initiatives = currentScenario.initiatives.filter(
        (init) => action.initiatives.indexOf(init) === -1
      )

      let indexToPut = 0
      if (action.placeAfterPriority) {
        indexToPut =
          initiatives.indexOf(currentScenario.initiatives[action.placeAfterPriority - 1]) + 1
      }

      initiatives.splice(indexToPut, 0, ...action.initiatives)

      initiatives = initiatives.map((initiative, index) => ({
        ...initiative,
        order: index + 1,
      }))

      const scenario = {
        ...currentScenario,
        initiatives,
      }

      updateAllConflictsInScenario(
        state.showConflicts,
        scenario,
        action.usePeopleCostOnConflictCalculation,
        action.hasFinancialAccess
      )

      return {
        ...state,
        currentScenario: scenario,
      }
    }
    case FINALIZE_BULK_DRAG: {
      const scenario = {
        ...state.currentScenario!,
        initiatives: action.updatedInitiatives,
      }

      updateAllConflictsInScenario(
        state.showConflicts,
        scenario,
        action.usePeopleCostOnConflictCalculation,
        action.hasFinancialAccess
      )

      return {
        ...state,
        currentScenario: scenario,
        draggableInitiatives: [],
        draggableInitiativeId: '',
      }
    }
    case UPDATE_CURRENT_SCENARIO_ACCORDING_PLAN_STARTDATE_AND_DURATION: {
      // TODO: write unit test for this case
      const distribution = generateBudgetDistributions(
        action.changes.duration,
        action.changes.startDate
      )
      return {
        ...state,
        currentScenario: {
          ...state.currentScenario!,
          budgets: action.hasFinancialAccess
            ? {
                distribution,
                total: 0,
                peopleCosts: 0,
                fixedCosts: 0,
                costs: 0,
                utilization: 0,
              }
            : null,
        },
      }
    }
    case TOGGLE_CONFLICTS: {
      const scenario = {
        ...state.currentScenario!,
      }

      updateAllConflictsInScenario(
        action.showConflicts,
        scenario,
        action.usePeopleCostOnConflictCalculation,
        action.hasFinancialAccess
      )
      return {
        ...state,
        showConflicts: action.showConflicts,
        currentScenario: scenario,
      }
    }
    case CHANGE_LAST_SCENARIO_AFTER_COPY:
      return {
        ...state,
        lastScenarioAfterCopy: action.scenario,
      }

    case ADD_SCENARIO_ROLE: {
      let people: IScenarioPeople
      let initiatives: IInitiative[]

      if (action.fromRoleDistribution) {
        people = processPeopleAfterScenarioRoleUpdate(
          state.currentScenario!.people,
          action.roles,
          true
        )

        initiatives = [...state.currentScenario!.initiatives]

        action.roles.forEach((role) => {
          updateSingleRoleConflicts(
            state.showConflicts,
            people.roles.find((r) => r.role.id === role.role.id) || role,
            initiatives
          )
        })
      } else {
        people = state.currentScenario!.people
        initiatives = state.currentScenario!.initiatives
      }

      return {
        ...state,
        currentScenario: {
          ...state.currentScenario!,
          initiatives,
          people: {
            ...people,
            roles: [...action.roles, ...state.currentScenario!.people.roles],
          },
        },
      }
    }
    case DELETE_SCENARIO_ROLE: {
      const initiatives = state.currentScenario!.initiatives.map((initiative) => {
        const roles = initiative.people.roles.filter((r) => !action.deletedRoleIds.has(r.id))

        return getInitiativeWithCalculatedFields(
          {
            ...initiative,
            people: {...initiative.people, roles},
          },
          action.usePeopleCostOnConflictCalculation,
          action.hasFinancialAccess,
          action.useHoursForMeasurements
        )
      })
      const people = processPeopleAfterScenarioRoleDelete(
        state.currentScenario!.people,
        action.deletedRoleIds
      )
      const budgets = action.hasFinancialAccess
        ? processBudgetAfterInitiativeListChange(
            state.currentScenario!.budgets!, // can't be null when hasFinancialAccess
            initiatives,
            action.usePeopleCostOnConflictCalculation
          )
        : state.currentScenario!.budgets

      const netValue = action.hasFinancialAccess
        ? calculateNetValueTotal(initiatives)
        : state.currentScenario!.netValue

      const scenario = {
        ...state.currentScenario!,
        initiatives,
        people,
        budgets,
        netValue,
      }
      updateAllConflictsInScenario(
        state.showConflicts,
        scenario,
        action.usePeopleCostOnConflictCalculation,
        action.hasFinancialAccess
      )

      return {
        ...state,
        currentScenario: scenario,
        timelineResolvedRoles: createTimelineResolvedRoles(people.roles),
      }
    }
    case UPDATE_SCENARIO_ROLE: {
      const scenarioRoles =
        state.timelineResolvedRoles && action.isFromDistributionDialog
          ? updateRoleChanges(action.scenarioRoles, state.currentScenario!.people.roles)
          : action.scenarioRoles

      const people = processPeopleAfterScenarioRoleUpdate(
        state.currentScenario!.people,
        scenarioRoles
      )
      const initiatives = [...state.currentScenario!.initiatives]

      scenarioRoles.forEach((role) => {
        updateSingleRoleConflicts(
          state.showConflicts,
          people.roles.find((r) => r.role.id === role.role.id)!,
          initiatives
        )
      })

      return {
        ...state,
        currentScenario: {
          ...state.currentScenario!,
          initiatives,
          people,
        },
        timelineResolvedRoles: createTimelineResolvedRoles(people.roles),
      }
    }
    case UPDATE_SCENARIO_BUDGET: {
      const budgets = processBudgetAfterScenarioBudgetUpdate(action.budget)
      const scenario = {
        ...state.currentScenario!,
        budgets,
      }
      updateBudgetConflicts(
        state.showConflicts,
        scenario,
        action.usePeopleCostOnConflictCalculation
      )

      return {
        ...state,
        currentScenario: scenario,
      }
    }

    case RESOLVE_CONFLICTS: {
      const correspondingInitiative = state.currentScenario!.initiatives.find(
        (initiative) => initiative.id === action.initiative.id
      )!
      if (action.resolvedRoles.length > 0) {
        track('Resolve role conflict', {
          roleCount: action.resolvedRoles.length,
        })
      }
      let people =
        action.resolvedRoles.length > 0
          ? processPeopleAfterConflictsResolved(state.currentScenario!.people, action.resolvedRoles)
          : state.currentScenario!.people
      people = processPeopleAfterInitiativeRoleChanges(
        people,
        correspondingInitiative!,
        action.initiative,
        action.planInfo,
        action.useHoursForMeasurements
      )
      if (action.budgets) {
        track('Resolve budget conflict')
      }

      let budgets = state.currentScenario!.budgets
      let netValue = state.currentScenario!.netValue

      if (action.hasFinancialAccess) {
        budgets = action.budgets
          ? processBudgetAfterScenarioBudgetUpdate(action.budgets)
          : state.currentScenario!.budgets

        budgets = processBudgetAfterInitiativeUpdate(
          budgets!,
          correspondingInitiative.costs!,
          action.initiative.costs,
          action.usePeopleCostOnConflictCalculation
        )
        netValue = processNetValueAfterInitiativeUpdate(
          state.currentScenario!.netValue,
          correspondingInitiative.netValue!.calculatedNetValue,
          action.initiative.netValue.calculatedNetValue
        )
      }

      const initiatives = state.currentScenario!.initiatives.map((initiative) => {
        if (initiative.id === action.initiative.id) {
          return action.initiative
        } else {
          return initiative
        }
      })
      const scenario = {
        ...state.currentScenario!,
        people,
        budgets,
        netValue,
        initiatives,
      }
      updateAllConflictsInScenario(
        state.showConflicts,
        scenario,
        action.usePeopleCostOnConflictCalculation,
        action.hasFinancialAccess
      )

      return {
        ...state,
        currentScenario: scenario,
        timelineResolvedRoles: createTimelineResolvedRoles(people.roles),
      }
    }
    case SET_IMPORTED_INITIATIVES_IDS: {
      return {
        ...state,
        importedInitiativesIds: action.importedInitiativesIds,
      }
    }
    case SET_UNSAVED_CHANGES_DIALOG_VISIBILITY:
      return {
        ...state,
        deleteInitiativesConfirmDialogData: {
          ...state.deleteInitiativesConfirmDialogData,
          ...action,
        },
      }
    case RECALCULATE_BUDGET_CONFLICTS: {
      const initiatives = recalculatesInitiativesNetValues(
        state.currentScenario!.initiatives,
        action.usePeopleCostOnConflictCalculation
      )

      const budgets = action.hasFinancialAccess
        ? processBudgetAfterInitiativeListChange(
            state.currentScenario!.budgets!, // can't be null when hasFinancialAccess
            initiatives,
            action.usePeopleCostOnConflictCalculation
          )
        : state.currentScenario!.budgets

      const scenario = {
        ...state.currentScenario!,
        initiatives,
        budgets,
        netValue: action.hasFinancialAccess
          ? calculateNetValueTotal(initiatives)
          : state.currentScenario!.netValue,
      }

      if (action.hasFinancialAccess && action.showConflicts) {
        updateBudgetConflictsInScenario(scenario, action.usePeopleCostOnConflictCalculation)
      }

      return {
        ...state,
        currentScenario: scenario,
      }
    }
    case MARK_DRAGGABLE_INITIATIVES: {
      return {
        ...state,
        currentScenario: {
          ...state.currentScenario!,
          initiatives: action.filteredInitiatives,
        },
        draggableInitiativeId: action.draggableInitiativeId,
        draggableInitiatives: action.draggableInitiatives,
      }
    }
    case SHRINK_INITIATIVES: {
      return {
        ...state,
        shrinkInitiativeIds: [...state.shrinkInitiativeIds, ...action.initiatives],
      }
    }
    case UNSHRINK_INITIATIVES: {
      return {
        ...state,
        shrinkInitiativeIds: state.shrinkInitiativeIds.filter((initiative) => {
          return action.initiatives.indexOf(initiative) === -1
        }),
      }
    }
    case HIDE_INITIATIVES: {
      return {
        ...state,
        hiddenInitiativeIds: [...state.hiddenInitiativeIds, ...action.initiatives],
      }
    }
    case SHOW_INITIATIVES: {
      return {
        ...state,
        hiddenInitiativeIds: state.hiddenInitiativeIds.filter((initiative) => {
          return action.initiatives.indexOf(initiative) === -1
        }),
      }
    }
    case SET_INITIATIVE_ROW_TRANSITION: {
      return {
        ...state,
        enableInitiativeRowTransition: action.status,
      }
    }
    case SET_HIGHLIGHTED_COLUMN_INDEX: {
      return {
        ...state,
        highlightedColumnIndex: action.index,
      }
    }
    case CLEAR_OLD_HIGHLIGHTED_COLUMN_INDEX: {
      return {
        ...state,
        highlightedColumnIndex:
          state.highlightedColumnIndex === action.index ? null : state.highlightedColumnIndex,
      }
    }
    default:
      return state
  }
}

// TODO: Fix updateInitiativeName, updateInitiativeDates usage (should be called by id, date or by id, name on corresponding places)
