import {FVGPlugin} from '../../../../shared/toolbar/filters/FVGPlugin'
import {bindPluginClass, PluginBase} from '@workfront/list-plugin-utils'
import {IDataTableAPI} from '@wftypes/list'
import {
  AppliedFilters,
  AppliedFiltersCount,
  FiltersChangeParams,
  FiltersDetails,
} from '../../../../shared/toolbar/filters/types'
import DataFetcherPlugin, {DataFetcherAPI} from '../../dataFetcher'
import {IToolbarApi} from '@wf-mfe/lists'
import {TOOLBAR_PLUGIN_NAME} from '../../../../shared/toolbar/constants'
import SeparatorWidget from '../../../../shared/toolbar/widgets/SeparatorWidget'
import NFVGOverlay from '../../../../shared/toolbar/filters/NFVGOverlay'
import * as React from 'react'
import {convert, getAppliedFiltersCount} from '@workfront/filter-redrock-integration'
import ToolbarFilterButton from '../../../../shared/toolbar/components/ToolbarFilterButton'
import {UITYPE_FILTER} from '../../../../shared/toolbar/filters/FVGOverlay'
import {filterServiceRequest} from '../../../services/apiFactory'
import {ToolbarButtonApi} from '@workfront/panel-components/typings/ToolbarButton/ToolbarButton'
import {redrockClient} from '../../../../../shared/L10n'

export class FilterPlugin extends PluginBase {
  filterWidgetApi: ToolbarButtonApi | null = null
  overlayComponentRef = React.createRef<{
    setUiType: (uiType: string) => void
    getUiType: () => string
  }>()

  updateToolbarButton = (appliedFiltersCount: AppliedFiltersCount) => {
    const count = appliedFiltersCount.count
    const filterMessage = redrockClient.getTextSync(
      'filters.ui.label',
      '{ count , plural , =0 {Filter} one {Filter} other {Filters} }',
      {count: count}
    )

    if (count === 1 && appliedFiltersCount.name) {
      this.filterWidgetApi!.setText(appliedFiltersCount.name)
    } else {
      this.filterWidgetApi!.setText(filterMessage)
    }

    this.filterWidgetApi!.setIsActive(count > 0)
    this.filterWidgetApi!.setCount(count)
  }

  openFilterSelectionPanel = () => {
    if (this.overlayComponentRef.current!.getUiType() === UITYPE_FILTER) {
      this.overlayComponentRef.current!.setUiType('')
    } else {
      this.overlayComponentRef.current!.setUiType(UITYPE_FILTER)
    }
  }

  handleClosePanel = () => {
    this.overlayComponentRef.current!.setUiType('')
  }

  ready(listApi: IDataTableAPI) {
    const toolbarApi = listApi.getPluginAPI<IToolbarApi>(TOOLBAR_PLUGIN_NAME)!
    const dataFetcherApi = listApi.getPluginAPI<DataFetcherAPI>(DataFetcherPlugin.getName())!
    const WIDGET_TYPE_ICON = 'icon'

    this.addShutdownRoutine(
      toolbarApi.registerWidget(WIDGET_TYPE_ICON, ToolbarFilterButton),
      toolbarApi.registerWidget(toolbarApi.SEPARATOR_WIDGET_TYPE, SeparatorWidget),
      listApi.overlays.appendOverlay(listApi.overlays.locations.RIGHT_SIDEBAR, () => {
        return (
          <NFVGOverlay
            key="fvg-selection-overlay"
            ref={this.overlayComponentRef}
            listApi={listApi}
            onClosePanel={this.handleClosePanel}
            onFilterChange={this.handleFilterChange(listApi)}
          />
        )
      })
    )

    listApi.overlays.forceUpdateOverlayContainer(listApi.overlays.locations.RIGHT_SIDEBAR)

    let position = 900
    this.addShutdownRoutine(
      toolbarApi.registerCommand({
        id: 'open-filter-selection-panel',
        requirements: {},
        executor: this.openFilterSelectionPanel,
      })
    )

    this.addShutdownRoutine(
      toolbarApi.insertComponent(toolbarApi.ToolbarLocations.secondary, {
        id: 'separator-before-filter-icon',
        position: position++,
        widgetType: toolbarApi.SEPARATOR_WIDGET_TYPE,
      }),
      toolbarApi.insertComponent(toolbarApi.ToolbarLocations.secondary, {
        id: 'filter-icon',
        position: position++,
        primaryCommandId: 'open-filter-selection-panel',
        widgetType: WIDGET_TYPE_ICON,
        widgetOptions: {
          dataTestId: 'open-filter-selection-panel',
          typeMessageKey: 'filters.ui.label',
          typeFallback: 'Filter',
          getApi: (widgetApi) => {
            this.filterWidgetApi = widgetApi
          },
        },
      }),
      toolbarApi.insertComponent(toolbarApi.ToolbarLocations.secondary, {
        id: 'separator-after-filter-icon',
        position: position++,
        widgetType: toolbarApi.SEPARATOR_WIDGET_TYPE,
      })
    )
    dataFetcherApi
      .getDefaultFiltersDetailsPromise()
      .then(({selectedFilterIDs, count}: FiltersDetails) => {
        const appliedFiltersCount = {
          name: null,
          count: count,
        }

        if (selectedFilterIDs && selectedFilterIDs.length === 1 && count === 1) {
          filterServiceRequest(`filter/id/${selectedFilterIDs[0]}`, 'GET').then(
            ({data: {name}}) => {
              this.updateToolbarButton({...appliedFiltersCount, name})
            }
          )
        } else {
          setTimeout(() => {
            this.updateToolbarButton(appliedFiltersCount)
          })
        }
      })
  }

  handleFilterChange = (listApi: IDataTableAPI) => (
    selectedFilters: FiltersChangeParams[],
    filterExpression: FiltersChangeParams
  ) => {
    const objCode = listApi.metadata.getProperty('objCode')
    const dataFetcherApi = listApi.getPluginAPI<DataFetcherAPI>(DataFetcherPlugin.getName())
    const selectedFiltersLength = selectedFilters.length
    let appliedFilters: AppliedFilters = []

    if (selectedFiltersLength > 0 || filterExpression) {
      appliedFilters = filterExpression ? [...selectedFilters, {filterExpression}] : selectedFilters

      convert(appliedFilters, objCode).then((queryMap) => {
        dataFetcherApi!.filterListData(queryMap, {selectedFilters, filterExpression})
      })
    } else {
      dataFetcherApi!.filterListData({})
    }

    const count = getAppliedFiltersCount(selectedFilters || [], filterExpression)
    const name = count === 1 && selectedFiltersLength > 0 ? selectedFilters[0].name : null

    this.updateToolbarButton({
      count,
      name,
    })
  }
}

export default bindPluginClass(
  () => new FilterPlugin(),
  FVGPlugin.PLUGIN_NAME,
  FVGPlugin.PLUGIN_DEPS || []
)
