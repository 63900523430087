import {css} from 'emotion'
import * as React from 'react'

import {H1, H2} from '@phoenix/all'

interface IErrorPageComponentProps {
  primaryMessage: JSX.Element | string
  secondaryMessage?: JSX.Element | string
  testId: string
}

const wrapperClass = css`
  margin: 0 auto;
`

const errorPageClass = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
`

const primaryMessageClass = css`
  ${errorPageClass};
  padding-top: 32px;
`

export const ErrorPageComponent: React.FunctionComponent<IErrorPageComponentProps> = (props) => {
  return (
    <div className={wrapperClass} data-testid={props.testId}>
      <div className={primaryMessageClass}>
        <H1>{props.primaryMessage}</H1>
      </div>
      <div className={errorPageClass}>
        {props.secondaryMessage && <H2>{props.secondaryMessage}</H2>}
      </div>
    </div>
  )
}
