import {css} from 'emotion'

export function checkPlacement(placement = '', result: string) {
  return placement.split('-').includes(result)
}

export const resetButtonDefaultStyles = css`
  cursor: pointer;
  padding: 0;
  border: 0;
  background: none;

  &:focus {
    outline: 0;
  }
`

export const tooltipBoxShadowClass = css`
  box-shadow: 0 3px 6px 1px rgba(0, 35, 64, 0.2);
`

export const fullHeightFlexClass = css`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

export const resetFocusStateClass = css`
  &:focus {
    outline: none;
    box-shadow: none;
  }
`
