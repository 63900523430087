import {css, cx} from 'react-emotion'
import * as React from 'react'
import {IconComponent} from 'phoenix-icons/dist'

const buttonIconWrapper = css`
  position: relative;
  margin: 0 auto;
`

interface ToolbarButtonIconProps {
  icon: IconComponent
  children?: React.ReactNode
  className?: string
}
export default function ToolbarButtonIcon(props: ToolbarButtonIconProps): JSX.Element {
  const {icon: Icon, children, className} = props
  return (
    <span aria-hidden={true} className={cx(buttonIconWrapper, className)}>
      <Icon focusable="false" />
      {children}
    </span>
  )
}
