import * as React from 'react'
import {PluginBase} from '@workfront/list-plugin-utils'
import {GetApiParameter} from '../widgets/IconWidget'
import {IDataTableAPI} from '@wftypes/list'
import DataFetcherPlugin from '../../../import/plugins/dataFetcher'
import {FilterChangeParams} from './types'
import {UITYPE_FILTER} from './FVGOverlay'
import {FilterData} from './useWrapperReducer'

const TOOLBAR_PLUGIN_NAME = 'toolbar'

export class FVGPlugin extends PluginBase {
  static PLUGIN_DEPS = [TOOLBAR_PLUGIN_NAME, DataFetcherPlugin.getName()]
  static PLUGIN_NAME = 'toolbar-fvg'
  filterWidgetApi: GetApiParameter | null = null

  /**
   * @type {React.RefObject<FVGOverlay>}
   */
  overlayComponentRef = React.createRef<{
    setUiType: (uiType: string) => void
    getUiType: () => string
    setCurrentFilterID: (filterID: string) => void
    getCurrentFilterID: () => string
  }>()

  openFilterSelectionPanel = () => {
    if (this.overlayComponentRef.current!.getUiType() === UITYPE_FILTER) {
      this.filterWidgetApi!.setIsChecked(false)
      this.overlayComponentRef.current!.setUiType('')
    } else {
      this.overlayComponentRef.current!.setUiType(UITYPE_FILTER)
    }
  }

  handleClosePanel = (uiType) => {
    if (uiType === UITYPE_FILTER) {
      this.filterWidgetApi!.setIsChecked(false)
    }
    this.overlayComponentRef.current!.setUiType('')
  }

  setDefaultFilter = (filter): void => {
    throw new Error(`Unimplemented Method. Cannot set default filter ${filter.id}`)
  }

  loadFilters = (): ((listApi: IDataTableAPI) => Promise<FilterData>) => {
    throw new Error(`Unimplemented Method. Cannot set load filters`)
  }

  init(listApi: IDataTableAPI) {
    listApi.preloadMessageKeys([
      'all',
      'loading',
      'filter',
      'filter.by',
      'uifilters',
      'saved',
      'sharedwithme',
    ])
  }
  // eslint-disable-next-line
  ready(listApi: IDataTableAPI, options?: object) {
    console.log(listApi, options)
    throw new Error(`Unimplemented Method`)
  }

  handleFilterChange = (listApi: IDataTableAPI, planId?) => (filter: FilterChangeParams): any => {
    console.log(listApi, planId, filter)
    throw new Error(`Unimplemented Method. Cannot handle filter change`)
  }
}
