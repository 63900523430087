import {css} from 'emotion'
import {Moment} from 'moment'
import * as React from 'react'
import {connect} from 'react-redux'

import {Button} from '@phoenix/all'
import {
  addScenarioRoles,
  currentPlanStartDateSelector,
  currentScenarioPeopleSelector,
  planDurationSelector,
  togglePeopleWidget,
  toggleRoleDistributionDialog,
  viewAccessSelector,
} from '../../../../../data'
import {hoursForMeasurementsSelector} from '../../../../../data/plan/selectors/hoursForMeasurementsSelector'
import {createScenarioRole, track} from '../../../../../shared/helpers'
import {IScenarioRole} from '../../../../../shared/models'
import {HeaderExpandedWidgetComponent} from '../HeaderExpandedWidgetComponent'
import {HeaderRolesListComponent} from './HeaderRolesListComponent'
import {JobRoleSelectComponent} from './JobRoleSelectComponent'
import {Translate} from '../../../../shared/Translate'

interface IHeaderPeopleWidgetComponent {
  isViewMode: boolean
  collapseWidgetFunction: () => void
  toggleRoleDistributionDialog: (isOpenRoleDistributionDialog: boolean) => void
  addRoleFunction: (newRole: IScenarioRole[]) => void
  planDuration: number
  planStartDate: Moment
  scenarioRoles: IScenarioRole[]
  toggleWidgetFunction: (isWidgetExpanded) => void
  useHoursForMeasurements: boolean
}

const contentClass = css`
  width: 640px;

  p {
    top: 44px;
  }

  .count {
    width: 73px;
  }
`

const roleDropdownClass = css`
  margin-bottom: 20px;
  display: flex;

  .header-people-roles-select {
    width: 323px;
  }
`

const distributionLinkClass = css`
  margin-left: auto;
`

const distributionButtonClass = css`
  font-weight: normal;
`

export class HeaderPeopleExpandedWidget extends React.Component<IHeaderPeopleWidgetComponent> {
  render() {
    const label = this.props.isViewMode
      ? 'sp.header.widget.role.info.view.mode'
      : this.props.useHoursForMeasurements
      ? 'sp.header.widget.role.info.hour'
      : 'sp.header.widget.role.info.fte'

    return (
      <HeaderExpandedWidgetComponent
        paddingBottom={0}
        text={<Translate messageKey={label} />}
        collapseWidget={this.props.collapseWidgetFunction}
      >
        <div className={`${contentClass} people-widget-content`}>
          <div className={roleDropdownClass}>
            <>
              {!this.props.isViewMode && (
                <JobRoleSelectComponent
                  testID="header-people-roles-options"
                  addRole={(role) => this.addRole(role)}
                  scenarioRolesIds={this.props.scenarioRoles.map((role) => role.role.id)}
                />
              )}
              <div className={distributionLinkClass} data-testid="role-distribution-button">
                <Button
                  className={distributionButtonClass}
                  onClick={this.openRoleDistributionDialog}
                  text
                >
                  <Translate messageKey="job.role.distribution" />
                </Button>
              </div>
            </>
          </div>
          <HeaderRolesListComponent />
        </div>
      </HeaderExpandedWidgetComponent>
    )
  }

  private addRole = (role) => {
    const {planStartDate, planDuration} = this.props
    if (role) {
      track('Add job role from supply', {
        roleName: role.name,
      })

      const newRole = createScenarioRole(role, planStartDate, planDuration)
      this.props.addRoleFunction([newRole])
    }
  }

  private openRoleDistributionDialog = () => {
    this.props.toggleRoleDistributionDialog(true)
    this.props.toggleWidgetFunction(false)
  }
}

const mapStateToProps = (state) => ({
  isViewMode: viewAccessSelector(state),
  planDuration: planDurationSelector(state),
  planStartDate: currentPlanStartDateSelector(state),
  scenarioRoles: currentScenarioPeopleSelector(state).roles,
  useHoursForMeasurements: hoursForMeasurementsSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  collapseWidgetFunction: () => dispatch(togglePeopleWidget(false)),
  toggleRoleDistributionDialog: (isOpenRoleDistributionDialog) =>
    dispatch(toggleRoleDistributionDialog(isOpenRoleDistributionDialog)),
  addRoleFunction: (role) => dispatch(addScenarioRoles(role)),
  toggleWidgetFunction: (isPeopleWidgetExpanded) =>
    dispatch(togglePeopleWidget(isPeopleWidgetExpanded)),
})

export const HeaderPeopleExpandedWidgetComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderPeopleExpandedWidget)
