import React from 'react'
import {css} from 'react-emotion'
import {connect} from 'react-redux'
import {primary, ButtonDropdown, Options, Option, Text} from '@phoenix/all'
import {currentScenarioInitiativesSelector} from '../../../data/scenario'
import {IInitiative} from '../../../shared/models/initiative'
import {selectedInitiativesSelector} from '../../../data/plan/selectors/selectedInitiativesSelector'
import {Translate} from '../../shared/Translate'

interface IFloatingMenuPrioritizeDropdownProps {
  initiatives: IInitiative[]
  selectedInitiatives: {
    [key: string]: boolean
  }
  onChange: (opt: any) => void
}

const dropdownCss = css`
  height: 32px;
  border-color: ${primary.gray[300]} !important;
  padding: 0 32px 2px 8px;
  color: ${primary.gray[400]} !important;
  font-weight: normal;

  > span {
    top: 7px;
    right: 5px;
    filter: grayscale(1);
  }

  &:hover {
    color: ${primary.gray[600]} !important;
  }
`

const afterOptionCss = css`
  text-transform: uppercase;
`

const optionsCss = css`
  > ul {
    margin-top: 106px;
    max-height: 170px;

    > li {
      min-width: 80px;
    }

    > li:nth-child(1),
    > li:nth-child(2),
    > li:nth-child(3) {
      position: absolute;
      width: 100%;
    }

    > li:nth-child(1) {
      top: 0;
    }

    > li:nth-child(2) {
      top: 35px;
    }

    > li:nth-child(3) {
      top: 70px;
    }
  }
`

const FloatingMenuPrioritizeDropdownDisconnected: React.FC<IFloatingMenuPrioritizeDropdownProps> = ({
  initiatives,
  selectedInitiatives,
  onChange,
}) => {
  const lastNotSelectablePriority = React.useMemo(() => {
    const lastNotSelectedInitiative = initiatives
      .slice()
      .reverse()
      .find((initiative) => !selectedInitiatives[initiative.id])
    if (lastNotSelectedInitiative) {
      return lastNotSelectedInitiative.order
    }

    return 0
  }, [initiatives, selectedInitiatives])

  const options = React.useMemo(() => {
    return initiatives.map((initiative) => (
      <Option
        key={initiative.id}
        value={initiative.order}
        label={initiative.order.toString()}
        disabled={selectedInitiatives[initiative.id]}
      />
    ))
  }, [initiatives, selectedInitiatives])

  return (
    <ButtonDropdown
      testID="bulk-prioritize-dropdown"
      className={dropdownCss}
      onChange={onChange}
      options={
        <Options className={optionsCss} multiSelect={false}>
          <Option value={0}>
            <Text>
              <Translate messageKey="initiative.options.prioritize.top" />
            </Text>
          </Option>
          <Option value={lastNotSelectablePriority}>
            <Text>
              <Translate messageKey="initiative.options.prioritize.bottom" />
            </Text>
          </Option>
          <Option disabled dividerPosition="top">
            <Text className={afterOptionCss}>
              <Translate messageKey="initiative.options.prioritize.after" />
            </Text>
          </Option>
          {options}
        </Options>
      }
      secondary
    >
      <Translate messageKey={'initiative.options.prioritize'} />
    </ButtonDropdown>
  )
}

const mapStateToProps = (state) => ({
  initiatives: currentScenarioInitiativesSelector(state),
  selectedInitiatives: selectedInitiativesSelector(state),
})

export const FloatingMenuPrioritizeDropdownComponent = connect(mapStateToProps)(
  FloatingMenuPrioritizeDropdownDisconnected
)
