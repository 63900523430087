import CopyIcon from 'phoenix-icons/dist/CopyIcon.js'
import {primary, LabelDropdown, Option} from '@phoenix/all'
import {css, injectGlobal} from 'emotion'
import * as React from 'react'
import {connect} from 'react-redux'
import {currentPlanSelector} from '../../../../data/plan'
import {viewAccessSelector} from '../../../../data/settings'
import {IPlan} from '../../../../shared/models'
import {IScenario} from '../../../../shared/models/scenario'
import {resetButtonDefaultStyles} from '../../../shared'
import {IScenarioOptions} from './ScenarioHeaderActionsComponent'
import {Translate} from '../../../shared/Translate'

interface IScenarioDropdownComponentProps {
  currentScenario: IScenario
  handleScenarioChange: () => void
  copyButtonOnClickHandler: (event, scenarioItem) => void
  isViewMode: boolean
  plan: IPlan
}

injectGlobal`
    [id*='scenario-dropdown-'] {
        &.focused .copy-button,
        &.mousedowned .copy-button{
            color: inherit;
        }
        
        &:hover {
            .copy-button-spilt-off {
                opacity: 1;
            }
        }
        
        & > div > span {
            align-items: center;
            min-width: 180px;
            flex-grow: unset;
        }
    }
`

const copyButtonClass = css`
  ${resetButtonDefaultStyles};
  color: ${primary.blue(400)};
  cursor: pointer;
  font-size: 14px;
  margin: 0 3px 0 8px;
  display: flex;
  align-items: center;
  border-radius: 3px;

  &.copy-button-spilt-off {
    opacity: 0;
  }
`
const copyClass = css`
  display: flex;
  margin-left: 8px;
`

const scenarioDropDownWrapperClass = css`
  button {
    border-radius: 3px;
  }
`

const scenarioNameClass = css`
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const ScenarioDropdown: React.FC<IScenarioDropdownComponentProps> = React.memo((props) => {
  const getScenarioName = ({isInitial, order}: IScenario) => {
    return isInitial ? (
      <Translate messageKey={'sp.scenario.initial'} />
    ) : (
      <Translate messageKey={'sp.scenario'} args={[order + 1]} />
    )
  }

  const copyScenario = (e, scenarioItem) => {
    props.copyButtonOnClickHandler(e, scenarioItem)
  }

  const onCopyClickHandle = React.useCallback(copyScenario, [props.copyButtonOnClickHandler])

  const onKeyDownHandler = React.useCallback(
    (e, scenarioItem) => {
      const keyboardClickKeys = ['Enter', 'Space', ' ']

      if (keyboardClickKeys.includes(e.key)) {
        copyScenario(e, scenarioItem)
      }
    },
    [props.copyButtonOnClickHandler]
  )

  const scenarioOptions: IScenarioOptions[] = React.useMemo(
    () =>
      props.plan.scenarios.map((scenarioItem) => ({
        label: getScenarioName(scenarioItem),
        value: scenarioItem,
      })),
    [props.plan.scenarios]
  )

  const selectedScenarioOption: IScenarioOptions | undefined = React.useMemo(
    () =>
      scenarioOptions.find(
        (scenarioOption: IScenarioOptions) => scenarioOption.value.id === props.currentScenario.id
      ),
    [scenarioOptions, props.currentScenario]
  )

  return (
    <div className={scenarioDropDownWrapperClass}>
      <LabelDropdown
        label={null}
        testID="scenario"
        value={selectedScenarioOption}
        onChange={props.handleScenarioChange}
      >
        {scenarioOptions.map((scenarioOption: IScenarioOptions, index) => {
          const scenarioItem = scenarioOption.value
          return (
            <Option id={`scenario-dropdown-${index}`} key={scenarioItem.id} value={scenarioOption}>
              <div className={scenarioNameClass}>{getScenarioName(scenarioItem)}</div>

              {!props.isViewMode && scenarioOption.value.id === props.currentScenario.id && (
                <button
                  data-testid={`copy-scenario-${index}`}
                  className={`${copyButtonClass} copy-button`}
                  onClick={(e) => onCopyClickHandle(e, scenarioItem)}
                  onKeyDown={(e) => onKeyDownHandler(e, scenarioItem)}
                >
                  <CopyIcon color={primary.blue(400)} />
                  <span className={copyClass}>
                    <Translate messageKey={'scenario.actions.copy'} />
                  </span>
                </button>
              )}
            </Option>
          )
        })}
      </LabelDropdown>
    </div>
  )
})

ScenarioDropdown.displayName = 'ScenarioDropdown'

const mapStateToProps = (state) => ({
  isViewMode: viewAccessSelector(state),
  plan: currentPlanSelector(state),
})

export const ScenarioDropdownComponent = connect(mapStateToProps)(ScenarioDropdown)
