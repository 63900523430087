import React from 'react'
import {connect} from 'react-redux'
import moment from 'moment'
import {peopleCostOnConflictCalculationSelector} from '../../../data/plan/selectors/peopleCostOnConflictCalculationSelector'
import {currentScenarioBudgetSelector, currentScenarioPeopleSelector} from '../../../data/scenario'
import {initiativesByPeriodSelectorFactory} from '../../../data/scenario/selectors/initiativesByPeriodSelectorFactory'
import {financialAccessDeniedSelector} from '../../../data/settings'
import {IInitiative} from '../../../shared/models/initiative'
import {IScenarioBudgets, IScenarioRole} from '../../../shared/models/scenario'
import {ConflictsTooltipComponent} from '../../shared/ConflictsTooltipComponent'
import {YEAR_MONTH_FORMAT} from '../../../shared/constants/dateFormats'
import {size} from 'lodash'
import {IRoleConflictsByDates} from '../../../shared/models/initiative/IInitiativeConflicsDetailsByDate'
import {Translate} from '../../shared/Translate'

export interface IMonthResourceSummaryComponentOwnProps {
  periodTitle: string
  startDate: moment.Moment
  endDate: moment.Moment
  usePeopleCostOnConflictCalculation: boolean
  hasFinancialAccess: boolean
  roles: IScenarioRole[]
  budgets: IScenarioBudgets
}

export interface IMonthResourceSummaryComponentState {
  initiatives: IInitiative[]
}

export type TMonthResourceSummaryComponentProps = IMonthResourceSummaryComponentState &
  IMonthResourceSummaryComponentOwnProps

export const MonthResourceSummaryComponentContent: React.FC<TMonthResourceSummaryComponentProps> = ({
  periodTitle,
  startDate,
  initiatives,
  usePeopleCostOnConflictCalculation,
  hasFinancialAccess,
  roles,
  budgets,
}) => {
  const monthDateKey = startDate.format(YEAR_MONTH_FORMAT)

  const resourceSummary = React.useMemo<IRoleConflictsByDates>(() => {
    const roleConflicts: IRoleConflictsByDates = {}

    roles.forEach((role: IScenarioRole) => {
      if (role.usedInitiativeCount > 0 && isRoleUsedInInitiatives(initiatives, role)) {
        const availableValue = role.availablePerPeriod[monthDateKey]
        const requiredValue = role.requiredPerPeriod[monthDateKey] || 0
        const missingValue = requiredValue > availableValue ? requiredValue - availableValue : 0
        const foundChange = role.changes.find((change) => change.yearMonthDate.isSame(startDate))
        const isResolvedRole = foundChange && foundChange.change > 0
        roleConflicts[role.role.id] = {availableValue, requiredValue, missingValue, isResolvedRole}
      }
    })

    return size(roleConflicts) > 0
      ? roleConflicts
      : {noRole: {availableValue: 0, requiredValue: 0, missingValue: 0}}
  }, [roles])

  function isRoleUsedInInitiatives(initiatives: IInitiative[], scenarioRole: IScenarioRole) {
    let isUsed = false

    for (const initiative of initiatives) {
      const initiativeRole = initiative.people.roles.find((r) => r.id === scenarioRole.role.id)
      if (initiativeRole) {
        isUsed = true
        break
      }
    }

    return isUsed
  }

  const budgetSummary = React.useMemo<number[] | null>(() => {
    if (hasFinancialAccess) {
      let budgetForMonth

      for (const yearlyBudgetDistribution of budgets.distribution.values()) {
        budgetForMonth = yearlyBudgetDistribution.budgetMonths.find(
          (item) => item.yearMonthDate.format(YEAR_MONTH_FORMAT) === monthDateKey
        )
        if (budgetForMonth) {
          break
        }
      }

      const availableValue = budgetForMonth ? budgetForMonth.amount : 0
      const requiredValue = initiatives.reduce((overallCost: number, initiative: IInitiative) => {
        const initiativeOverallCost = usePeopleCostOnConflictCalculation
          ? initiative.costs!.overallCostDistribution![monthDateKey]
          : initiative.costs!.fixedCostDistribution![monthDateKey]
        return overallCost + initiativeOverallCost
      }, 0)
      const missingValue = requiredValue > availableValue ? requiredValue - availableValue : 0

      return [availableValue, requiredValue, missingValue]
    }

    return null
  }, [budgets, initiatives])

  return (
    <ConflictsTooltipComponent
      conflictsByDate={{
        roleConflicts: resourceSummary,
        budgetConflicts: budgetSummary,
      }}
      title={<Translate messageKey="required.resources.for" args={[periodTitle]} />}
    />
  )
}

const mapStateToProps = (state, props) => ({
  hasFinancialAccess: !financialAccessDeniedSelector(state),
  initiatives: initiativesByPeriodSelectorFactory(props.startDate, props.endDate)(state),
  usePeopleCostOnConflictCalculation: peopleCostOnConflictCalculationSelector(state),
  roles: currentScenarioPeopleSelector(state).roles,
  budgets: currentScenarioBudgetSelector(state),
})

export const MonthResourceSummaryComponent = connect(mapStateToProps)(
  MonthResourceSummaryComponentContent
)
