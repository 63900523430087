import {BrowserClient, createLocalizeContext} from '@wf-mfe/localize-react'

export const espContext = createLocalizeContext()
export const espClient = new BrowserClient({
  namespace: ['esp'],
  enableTerminology: true,
})
export const espClientFactory = () => espClient

export const redrockContext = createLocalizeContext()
export const redrockClient = new BrowserClient({
  namespace: 'redrock',
  enableTerminology: true,
})
export const redrockClientFactory = () => redrockClient
