import {createSelector} from 'reselect'
import {timelineModeSelector} from '../../plan'
import {highlightedColumnIndexSelector} from './highlightedColumnIndexSelector'
import {TimelineMode} from '../../../shared/enums'

export const highlightColumnSelector = createSelector(
  timelineModeSelector,
  highlightedColumnIndexSelector,
  (timelineMode: TimelineMode, index: number | null) =>
    index !== null && timelineMode === TimelineMode.MONTHLY
)
