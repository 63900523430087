import {css} from 'emotion'
import * as React from 'react'

import {primary} from '@phoenix/all'

interface ILoaderComponentProps {
  isLoading: boolean
}

const loadingOverlay = css`
  background-color: ${primary.gray(0)};
  position: absolute;
  cursor: progress;
  opacity: 0.5;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 100;
`

// TODO: Look for this kind of component in phoenix
export const LoaderComponent: React.FunctionComponent<ILoaderComponentProps> = (props) => {
  return (
    <React.Fragment>
      {props.isLoading && <div data-testid="plan-loading-overlay" className={loadingOverlay} />}
    </React.Fragment>
  )
}
