import * as React from 'react'
import {Pill} from './Pill'
import {css} from 'react-emotion'
import {Button} from '@phoenix/all'
import {TRenderer} from '@wftypes/list'
import {Localization} from '@wf-mfe/localize-react'
import {useForceUpdate} from '../../../../shared/hooks/useForceUpdate'
import {getPillWidth} from '../../../../shared/utils/getPillWidthHelpers'
import {QuickFilterMatcher} from '../../../../shared/types/quickFilter'
import {redrockContext} from '../../../../../shared/L10n'

interface IRolesWidgetProps {
  roleList: {value: string; id: string}[]
}

const PILL_RIGHT_MARGIN = 4
const PILL_TOP_MARGIN = 5
const SEE_MORE_WIDTH = 100

const rolesClass = css`
  display: flex;
  flex-wrap: wrap;
`

const pillClass = css`
  margin-right: ${PILL_RIGHT_MARGIN}px;

  &:last-child {
    margin-right: 0;
  }
`

const showMoreButtonClass = css`
  height: 24px;

  &:focus {
    box-shadow: none;
    outline: none;
  }
`

const topIntervalClass = css`
  margin-top: ${PILL_TOP_MARGIN}px;
`

const clearPillsTopIntervalClass = css`
  margin-top: -${PILL_TOP_MARGIN}px;
`

export const RolesWidget: TRenderer<IRolesWidgetProps> = (props) => {
  const {roleList, highlight, filterValue} = props

  const pillsWrapperRef: React.RefObject<HTMLDivElement> = React.useRef(null)
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false)
  const [pillsWidths, setPillsWidths] = React.useState<number[]>([])

  const containerOffsetWidth = pillsWrapperRef.current ? pillsWrapperRef.current!.offsetWidth : -1

  React.useLayoutEffect(() => {
    const arr: number[] = []
    for (let i = 0; i < roleList.length; i++) {
      arr.push(
        getPillWidth(
          roleList[i].value,
          '"Proxima Nova", Tahoma, Verdana, Arial, sans-serif',
          14,
          'bold'
        )
      )
    }

    setPillsWidths(arr)
  }, [props.rowId, roleList])

  let placedPillsCount = 0

  if (containerOffsetWidth >= 0) {
    let widthSum = 0

    for (let i = 0; i < pillsWidths.length; i++) {
      const singlePillWidth = pillsWidths[i] + PILL_RIGHT_MARGIN
      widthSum += singlePillWidth

      if (widthSum < containerOffsetWidth) {
        placedPillsCount = i + 1
      } else {
        let j = i
        widthSum += SEE_MORE_WIDTH

        while ((widthSum -= singlePillWidth) > containerOffsetWidth) {
          j--
          placedPillsCount = j
        }

        break
      }
    }
  }

  const forceUpdate = useForceUpdate()

  React.useLayoutEffect(() => {
    const width = pillsWrapperRef.current && pillsWrapperRef.current.offsetWidth

    if (width !== containerOffsetWidth) {
      forceUpdate()
    }
  })

  const showMoreOnClickHandler = React.useCallback(
    (e): void => {
      e.stopPropagation()
      setIsExpanded((isExpanded) => !isExpanded)
    },
    [roleList]
  )

  const rolesForRender = pillsWidths.length > 0 ? roleList.slice(0, placedPillsCount) : roleList
  const isShowMore = roleList.length > rolesForRender.length
  const label = isExpanded ? (
    <Localization messageKey="show.less" fallback="Show less" context={redrockContext} />
  ) : (
    <Localization
      messageKey="show.with.number"
      args={[roleList.length - rolesForRender.length]}
      fallback="Show +{0}"
      context={redrockContext}
    />
  )

  return (
    <div
      className={`${rolesClass} ${isExpanded ? clearPillsTopIntervalClass : ''}`}
      ref={pillsWrapperRef}
      data-testid="roles-wrapper"
    >
      {(isExpanded ? roleList : rolesForRender).map((role, i) => {
        return (
          <Pill
            data-testid={`pill-${i}`}
            className={`${pillClass} ${isExpanded ? topIntervalClass : ''}`}
            label={highlight(filterValue, role.value)}
            key={role.id}
          />
        )
      })}

      {isShowMore && (
        <Button
          className={`${showMoreButtonClass} ${
            isExpanded ? `${topIntervalClass} show-less` : 'show-more'
          }`}
          onClick={showMoreOnClickHandler}
          text
        >
          {label}
        </Button>
      )}
    </div>
  )
}

export const initiativeRolesMatcher: QuickFilterMatcher = ({roleList}, filterValue) =>
  roleList.some(
    (role) =>
      typeof role.value === 'string' &&
      role.value.toLowerCase().indexOf(filterValue.toLowerCase()) > -1
  )
