import * as React from 'react'
import styled, {css} from 'react-emotion'
import {connect} from 'react-redux'

import {primary} from '@phoenix/all'

import {changeCurrentInitiative} from '../../../../../data/initiative'
import {currentScenarioSelector, deleteInitiative} from '../../../../../data/scenario'
import {viewAccessSelector} from '../../../../../data/settings'
import {changeVisibleConflict, toggleEditSidebar} from '../../../../../data/viewOptions'
import {IInitiative} from '../../../../../shared/models/initiative'
import {IScenario} from '../../../../../shared/models/scenario'
import {NewInitiativeHeaderComponent} from './NewInitiativeHeaderComponent'
import {peopleCostOnConflictCalculationSelector} from '../../../../../data/plan/selectors/peopleCostOnConflictCalculationSelector'
import {DragHandleComponent} from './DragHandleComponent'
import {isInitiativeDraggableSelector} from '../../../../../data/scenario/selectors/isInitiativeDraggableSelector'
import {ShadowILeftStyled} from '../../Timeline/TimelineColumnShadowComponent'
import {highlightColumnSelector} from '../../../../../data/scenario/selectors/highlightColumnSelector'
import {highlightedColumnIndexSelector} from '../../../../../data/scenario/selectors/highlightedColumnIndexSelector'
import {sidebarSizeSelector} from '../../../../../data/settings/selectors/sidebarSizeSelector'

interface IInitiativeInfoComponentProps {
  initiative: IInitiative
  isScrolling: boolean
  isDraggable: boolean
  currentScenario: IScenario
  changeCurrentInitiativeFunction: (
    initiative: IInitiative | null,
    currentScenario: IScenario,
    usePeopleCostOnConflictCalculation: boolean
  ) => void
  deleteInitiativeFunction: (
    initiative: IInitiative,
    usePeopleCostOnConflictCalculation: boolean
  ) => void
  toggleEditSidebarFunction: (isOpen: boolean) => void
  isViewMode: boolean
  changeVisibleConflictFunction: () => void
  usePeopleCostOnConflictCalculation: boolean
  isScrollbarAtLeft: boolean
  highlightColumn: boolean
  highlightedColumnIndex: number | null
  sidebarSize: number
}

interface IInitiativeInfoComponentState {
  isDeleteConfirmDialogOpen: boolean
}

const initiativeInfoBodyClass = css`
  flex-grow: 1;
`

const backgroundContainerClass = css`
  display: inline-flex;
  height: 100%;
  padding: 11px 8px 11px 24px;
  width: 100%;
`

const ContainerStyled = styled('div')<{sidebarSize: number}>`
  background-color: ${primary.gray(0)};
  color: ${primary.gray()};
  height: 100%;
  border-right: 1px solid ${primary.gray(200)};
  width: ${(props) => props.sidebarSize}px;
  position: sticky;
  left: 0;
  z-index: 1;

  &:after {
    top: 0;
    background-image: linear-gradient(to right, ${primary.gray(200)} 36%, transparent 0);
    background-position: top;
    background-size: 6px 1px;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    width: 100%;
  }
`

export class InitiativeInfo extends React.Component<
  IInitiativeInfoComponentProps,
  IInitiativeInfoComponentState
> {
  constructor(props: IInitiativeInfoComponentProps) {
    super(props)
    this.state = {
      isDeleteConfirmDialogOpen: false,
    }
  }

  render() {
    const {
      initiative,
      isScrolling,
      isDraggable,
      isViewMode,
      isScrollbarAtLeft,
      highlightColumn,
      highlightedColumnIndex,
      sidebarSize,
    } = this.props

    const hasConflicts: boolean =
      !!initiative.conflicts &&
      (!!initiative.conflicts.roleConflicts!.size || !!initiative.conflicts.budgetConflicts)

    const initiativeHeaderProps = {
      moreActions: this.moreActions,
      initiative,
    }

    return (
      <ContainerStyled className={`initiative-info-container`} sidebarSize={sidebarSize}>
        <div
          className={`${backgroundContainerClass} initiative-info-background-container`}
          data-testid="initiative-info-background-container"
        >
          {!isViewMode && isDraggable && <DragHandleComponent />}

          <div className={initiativeInfoBodyClass} data-testid="initiative-info">
            <NewInitiativeHeaderComponent
              {...initiativeHeaderProps}
              hasConflicts={hasConflicts}
              isScrolling={isScrolling}
              isPublished={!!initiative.lastPublishedDate}
            />
          </div>
        </div>
        {isScrollbarAtLeft && highlightColumn && highlightedColumnIndex === 0 && (
          <ShadowILeftStyled right="0" />
        )}
      </ContainerStyled>
    )
  }

  private openSidebar = (initiative) => {
    this.props.changeCurrentInitiativeFunction(
      initiative,
      this.props.currentScenario,
      this.props.usePeopleCostOnConflictCalculation
    )
    this.props.toggleEditSidebarFunction(true)
    this.props.changeVisibleConflictFunction() // TODO: this can be reset at each current initiative change
  }

  private moreActions = (action, initiative) => {
    if (action.type === 'delete') {
      this.setState({
        isDeleteConfirmDialogOpen: true,
      })
    } else {
      this.openSidebar(initiative)
    }
  }
}

const mapStateToProps = (state, props) => ({
  isViewMode: viewAccessSelector(state),
  isDraggable: isInitiativeDraggableSelector(state, props.initiative),
  currentScenario: currentScenarioSelector(state),
  usePeopleCostOnConflictCalculation: peopleCostOnConflictCalculationSelector(state),
  highlightColumn: highlightColumnSelector(state),
  highlightedColumnIndex: highlightedColumnIndexSelector(state),
  sidebarSize: sidebarSizeSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  deleteInitiativeFunction: (initiative, usePeopleCostOnConflictCalculation) =>
    dispatch(deleteInitiative(initiative, usePeopleCostOnConflictCalculation)),
  changeCurrentInitiativeFunction: (
    initiative,
    currentScenario,
    usePeopleCostOnConflictCalculation
  ) => {
    dispatch(
      changeCurrentInitiative(initiative, currentScenario, usePeopleCostOnConflictCalculation)
    )
  },
  toggleEditSidebarFunction: (isOpen) => dispatch(toggleEditSidebar(isOpen)),
  changeVisibleConflictFunction: () => dispatch(changeVisibleConflict(null)),
})

export const InitiativeInfoComponent = connect(mapStateToProps, mapDispatchToProps)(InitiativeInfo)
