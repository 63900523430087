import {Button, primary} from '@phoenix/all'
import {css} from 'emotion'
import * as React from 'react'
import {PopperComponent, TooltipContentComponent} from '../../shared'
import {Translate} from '../../shared/Translate'

interface IButtonWithTooltipComponentProps {
  buttonMsgKey: string
  isDisabled: boolean
  showTooltip: boolean
  tooltipContent: JSX.Element
  testId: string
  onClickFunction: () => void
}

const disableClass = css`
  cursor: default;

  &,
  &:hover {
    background-color: ${primary.blue(100)};
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
`

export const ButtonWithTooltipComponent: React.FunctionComponent<IButtonWithTooltipComponentProps> = ({
  onClickFunction,
  isDisabled,
  showTooltip,
  tooltipContent,
  testId,
  buttonMsgKey,
}) => {
  const buttonText = <Translate messageKey={buttonMsgKey} />
  return (
    <>
      {showTooltip ? (
        <PopperComponent
          placement="top"
          content={<TooltipContentComponent text={tooltipContent} />}
          showOnOver
          reference={
            <Button
              className={showTooltip ? disableClass : ''}
              primary
              testID={`${testId}-with-tooltip`}
              onClick={!showTooltip ? onClickFunction : null}
            >
              {buttonText}
            </Button>
          }
        />
      ) : (
        <Button primary testID={testId} onClick={onClickFunction} disabled={isDisabled}>
          {buttonText}
        </Button>
      )}
    </>
  )
}
