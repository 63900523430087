import * as React from 'react'
import {AvatarGroup, Avatar} from '@phoenix/all'
import {css} from 'react-emotion'
import {avatarUtil} from '../../../shared/utils/avatarUtil'
import {TRenderer} from '@wftypes/list'
import {IPlan} from '../../../shared/types'
import {QuickFilterMatcher} from '../../../shared/types/quickFilter'

export interface ISharedUserListProps {
  plan: IPlan
  maxVisibleUsers?: number
  onSelect: () => void
}

const avatarContainerStyles = css`
  display: inline-block;
  cursor: pointer;
`

export const SharedUserWidget: TRenderer<ISharedUserListProps> = ({
  plan,
  maxVisibleUsers,
  onSelect,
  isSelected,
}) => {
  const onContainerClick = React.useCallback(
    (event) => {
      if (!isSelected) {
        // We are stopping the event propagation in the capture phase to make sure that the click event is NOT handled by the Avatar Group component.
        // Otherwise, if there are extra N avatars, when the user clicks the "+N" button, those avatars will be shown (which is something we do not want to happen because we have to show the "Sharing" dialog regardless of whether the click happened).
        event.stopPropagation()
        onSelect()
      }
    },
    [onSelect, isSelected]
  )

  return (
    <div className={avatarContainerStyles} onClickCapture={onContainerClick}>
      <AvatarGroup groupMax={maxVisibleUsers} type="small">
        {plan.accessorObjects.map((accessorObj) => (
          <Avatar
            key={accessorObj.guid}
            type="small"
            avatarURL={avatarUtil.getAvatarUrl(accessorObj.guid, accessorObj.avatarDate)}
            label={accessorObj.name}
          />
        ))}
      </AvatarGroup>
    </div>
  )
}

export const sharedUserMatcher: QuickFilterMatcher = ({plan}, filterValue) =>
  plan.accessorObjects.some(
    ({name}) =>
      typeof name === 'string' && name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1
  )
