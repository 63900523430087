import * as React from 'react'
import SmallDeleteIcon from 'phoenix-icons/dist/SmallDeleteIcon.js'
import {RoleDistributionContext} from '../../../../../../shared/context/RoleDistributionContext'
import {css} from 'react-emotion'
import {resetButtonDefaultStyles} from '../../../../../shared'
import {RemoveRoleConfirmationDialog} from '../RemoveRoleConfirmationDialog'
import {useCallback} from 'react'

interface IRoleDistributionRemoveProps {
  roleId: string
}

export const roleDistributionRemoveRowClass = css`
  ${resetButtonDefaultStyles};
  position: absolute;
  top: 8px;
  right: 16px;
  visibility: hidden;
`

export const RoleDistributionRemove: React.FC<IRoleDistributionRemoveProps> = React.memo(
  ({roleId}) => {
    const {removeRole, rolesDetails} = React.useContext(RoleDistributionContext)
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState<boolean>(false)
    const usedInitiativeCount = rolesDetails[roleId].usedInitiativeCount

    const onDeny = useCallback(() => {
      setIsConfirmDialogOpen(false)
    }, [isConfirmDialogOpen])

    const onConfirm = useCallback(() => {
      setIsConfirmDialogOpen(false)
      removeRole(roleId)
    }, [isConfirmDialogOpen])

    const deleteRole = () => {
      if (usedInitiativeCount > 0) {
        setIsConfirmDialogOpen(true)
      } else {
        removeRole(roleId)
      }
    }

    return (
      <>
        <button
          data-testid={`role-delete-${roleId}`}
          className={roleDistributionRemoveRowClass}
          onClick={deleteRole}
        >
          <SmallDeleteIcon />
        </button>

        {isConfirmDialogOpen && (
          <RemoveRoleConfirmationDialog
            onConfirmHandler={onConfirm}
            onDenyHandler={onDeny}
            usedInitiativeCount={usedInitiativeCount}
          />
        )}
      </>
    )
  }
)

RoleDistributionRemove.displayName = 'RoleDistributionRemove'
