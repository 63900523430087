import {primary} from '@phoenix/all'
import {css} from 'emotion'
import * as React from 'react'
import {connect} from 'react-redux'
import {timelineWidthSelector} from '../../../../data/plan'
import {TimelineColumnShadowComponent} from './TimelineColumnShadowComponent'

interface ITimelineRowComponentProps {
  timelineWidth: number
  height?: number | string
  placeholderShadow: boolean
}

const containerClass = css`
  display: flex;
  align-items: center;
`

const borderClass = css`
  stroke: ${primary.gray(200)};
  stroke-width: 2px;
`

export const TimelineRow: React.FunctionComponent<ITimelineRowComponentProps> = React.memo(
  (props) => {
    return (
      <div className={`${containerClass} timeline-row`} data-testid="timeline-row-inner">
        <TimelineColumnShadowComponent placeholder={props.placeholderShadow} />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="timeline"
          width={props.timelineWidth}
          height={props.height}
        >
          <line
            className={`${borderClass} timeline-border`}
            x1="0"
            y1="0"
            x2="100%"
            y2="0"
            strokeDasharray="3"
          />

          {props.children}
        </svg>
      </div>
    )
  }
)

TimelineRow.displayName = 'TimelineRow'
TimelineRow.defaultProps = {
  height: '100%',
}

const mapStateToProps = (state) => ({
  timelineWidth: timelineWidthSelector(state),
})

export const TimelineRowComponent = connect(mapStateToProps)(TimelineRow)
