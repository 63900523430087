import * as React from 'react'
import {connect} from 'react-redux'
import {
  ISharedUserListComponentProps,
  SharedUserListComponent,
} from '../../../shared/SharedUserListComponent'
import {accessorObjectsSelector} from '../../../../data/plan/selectors/accessorObjectsSelector'

const SharedAvatarGroupWrapper: React.FC<ISharedUserListComponentProps> = ({
  accessorObjects,
  onSelect,
}) => {
  return (
    <SharedUserListComponent
      accessorObjects={accessorObjects}
      maxVisibleUsers={4}
      onSelect={onSelect}
    />
  )
}

const mapStateToProps = (state) => ({
  accessorObjects: accessorObjectsSelector(state),
})

export const SharedUserListWrapper = connect(mapStateToProps)(SharedAvatarGroupWrapper)
