export const OPEN_EDIT_SIDEBAR = 'OPEN_EDIT_SIDEBAR'
export const TOGGLE_IMPORT_DIALOG = 'TOGGLE_IMPORT_DIALOG'
export const TOGGLE_PUBLISH_DIALOG = 'TOGGLE_PUBLISH_DIALOG'
export const CHANGE_LOADING_MODE = 'CHANGE_LOADING_MODE'
export const CHANGE_IMPORT_LOADING_MODE = 'CHANGE_IMPORT_LOADING_MODE'
export const REORDER_DRAG_AND_DROP = 'REORDER_DRAG_AND_DROP'
export const UPDATE_RESIZE_LINE_POSITION = 'UPDATE_RESIZE_LINE_POSITION'
export const CHANGE_RESOLVABLE_ROLES = 'CHANGE_RESOLVABLE_ROLES'
export const CHANGE_VISIBLE_CONFLICT = 'CHANGE_VISIBLE_CONFLICT'
export const CHANGE_PEOPLE_WIDGET_ANIMATION = 'CHANGE_PEOPLE_WIDGET_ANIMATION'
export const CHANGE_BUDGET_WIDGET_ANIMATION = 'CHANGE_BUDGET_WIDGET_ANIMATION'
export const CHANGE_BUDGET_RESOLVABLE = 'CHANGE_BUDGET_RESOLVABLE'
export const SLIDE_COPIED_SCENARIO = 'SLIDE_COPIED_SCENARIO'
export const TOGGLE_PEOPLE_WIDGET = 'TOGGLE_PEOPLE_WIDGET'
export const TOGGLE_BUDGET_WIDGET = 'TOGGLE_BUDGET_WIDGET'
export const SET_DRAGGING_INITIATIVE_ID = 'SET_DRAGGING_INITIATIVE_ID'
export const UPDATE_ROLE_CONFLICT_MONTH_INDEX = 'UPDATE_ROLE_CONFLICT_MONTH_INDEX'
export const UPDATE_BUDGET_CONFLICT_MONTH_INDEX = 'UPDATE_BUDGET_CONFLICT_MONTH_INDEX'
export const OPEN_ROLE_DISTRIBUTION_DIALOG = 'OPEN_ROLE_DISTRIBUTION_DIALOG'
