import {primary, secondary} from '@phoenix/all'
import {css, cx} from 'emotion'
import * as React from 'react'
import {getAllocatedAmount} from '../../../../../shared/helpers/conflicts/DistributionsHelper'
import {IDestructuredRoleConflict} from '../../../../../shared/models/initiative'
import {carouselItemConflictedBorderStyles} from '../roles/StyleHelper'

interface ICarouselItemComponentProps {
  className?: string
  testID: string
  periodConflictDetails?: IDestructuredRoleConflict | null
  month?: string
  isCountValue?: boolean
}

export const CAROUSEL_ITEM_WIDTH = 60

const carouselItemClass = css`
  min-width: ${CAROUSEL_ITEM_WIDTH}px;
  width: ${CAROUSEL_ITEM_WIDTH}px;
`

const highlightedClass = css`
  ${carouselItemConflictedBorderStyles};

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: ${primary.gray(200)};
    opacity: 0.5;
    z-index: -1;
  }
`

const conflictedBorderClass = css`
  .number-input {
    border: 1px solid ${secondary.captainMarvel()} !important;
  }
`

const paddingClass = css`
  padding: 12px 0;
`

export const CarouselItemComponent: React.FC<ICarouselItemComponentProps> = React.memo(
  ({children, className, testID, periodConflictDetails, month, isCountValue}) => {
    const missingAmount = periodConflictDetails
      ? getAllocatedAmount(periodConflictDetails, month!, 'missingAmount')
      : 0

    return (
      <li
        className={cx(
          carouselItemClass,
          className || '',
          missingAmount > 0 ? highlightedClass : '',
          periodConflictDetails ? paddingClass : '',
          isCountValue && missingAmount > 0 ? conflictedBorderClass : ''
        )}
        data-testid={testID}
      >
        {children}
      </li>
    )
  }
)

CarouselItemComponent.displayName = 'CarouselItemComponent'
