import {createSelector} from 'reselect'
import {AccessMode} from '../../../shared/enums'
import {planAccessSelector} from './planAccessSelector'

export const hasAccessToPlanSelector = createSelector(
  planAccessSelector,
  (planAccessMode: AccessMode | null) => {
    return planAccessMode !== null && planAccessMode !== AccessMode.noAccess
  }
)
