import {IInitiative, IInitiativeCosts} from '../../models/initiative'
import {IScenarioBudgets, IYearlyBudget} from '../../models/scenario'
import {calculateDifference} from '../../utilities'
import {getFixedCostSum, getOverallCostSum, getPeopleCostSum} from '../InitiativeHelper'

export function processBudgetAfterScenarioBudgetUpdate(budget: IScenarioBudgets): IScenarioBudgets {
  const total = calculateScenarioBudgetTotal(budget.distribution)
  const utilization = calculateScenarioBudgetUtilization(budget.costs, total)
  return {
    ...budget,
    total,
    utilization,
  }
}

export function processBudgetAfterInitiativeUpdate(
  budget: IScenarioBudgets,
  oldCosts: IInitiativeCosts,
  newCosts: IInitiativeCosts,
  usePeopleCostOnConflictCalculation: boolean
): IScenarioBudgets {
  const costDiff = usePeopleCostOnConflictCalculation
    ? calculateDifference(getOverallCostSum(newCosts), getOverallCostSum(oldCosts))
    : calculateDifference(getFixedCostSum(newCosts), getFixedCostSum(oldCosts))

  const costs = (budget.costs || 0) + costDiff!

  const peopleCostDiff = calculateDifference(getPeopleCostSum(newCosts), getPeopleCostSum(oldCosts))
  const peopleCosts = (budget.peopleCosts || 0) + peopleCostDiff!

  const fixedCostDiff = calculateDifference(getFixedCostSum(newCosts), getFixedCostSum(oldCosts))
  const fixedCosts = (budget.fixedCosts || 0) + fixedCostDiff!

  const utilization = calculateScenarioBudgetUtilization(costs, budget.total)

  return {
    ...budget,
    peopleCosts,
    fixedCosts,
    costs,
    utilization,
  }
}

// TODO add unit tests
export function processBudgetAfterInitiativeCopy(
  budget: IScenarioBudgets,
  initiativeCosts: IInitiativeCosts,
  usePeopleCostOnConflictCalculation: boolean
): IScenarioBudgets {
  const initiativeFixedCost = getFixedCostSum(initiativeCosts)!

  const costs = usePeopleCostOnConflictCalculation
    ? budget.costs! + getOverallCostSum(initiativeCosts)!
    : budget.costs! + initiativeFixedCost!

  const peopleCosts = budget.peopleCosts! + getPeopleCostSum(initiativeCosts)!
  const fixedCosts = budget.fixedCosts! + initiativeFixedCost!

  const utilization = calculateScenarioBudgetUtilization(costs, budget.total)

  return {
    ...budget,
    peopleCosts,
    fixedCosts,
    costs,
    utilization,
  }
}

export function processBudgetAfterInitiativeDelete(
  budget: IScenarioBudgets,
  initiativeCosts: IInitiativeCosts,
  usePeopleCostOnConflictCalculation
): IScenarioBudgets {
  const initiativeFixedCost = getFixedCostSum(initiativeCosts)!

  const costs = usePeopleCostOnConflictCalculation
    ? budget.costs! - getOverallCostSum(initiativeCosts)!
    : budget.costs! - initiativeFixedCost!

  const peopleCosts = budget.peopleCosts! - getPeopleCostSum(initiativeCosts)!

  const fixedCosts = budget.fixedCosts! - initiativeFixedCost!

  const utilization = calculateScenarioBudgetUtilization(costs, budget.total)

  return {
    ...budget,
    peopleCosts,
    fixedCosts,
    costs,
    utilization,
  }
}

export function processBudgetAfterInitiativeListChange(
  budget: IScenarioBudgets,
  initiatives: IInitiative[],
  usePeopleCostOnConflictCalculation: boolean
): IScenarioBudgets {
  const {peopleCosts, fixedCosts, costs} = calculateScenarioBudgetCosts(
    initiatives,
    usePeopleCostOnConflictCalculation
  )
  const utilization = calculateScenarioBudgetUtilization(costs, budget.total)

  return {
    ...budget,
    peopleCosts,
    fixedCosts,
    costs,
    utilization,
  }
}

export function calculateScenarioBudgetUtilization(
  costs: number | null,
  total: number | null
): number | null {
  if (total === null) {
    total = 0
  }
  return total !== 0 ? (costs! * 100) / total : 0
}

export function calculateScenarioBudgetTotal(
  distributions: Map<string, IYearlyBudget>
): number | null {
  let total = 0

  if (distributions.size) {
    distributions.forEach((item) => {
      total = total + item.total!
    })
  }

  return total
}

export function calculateScenarioBudgetCosts(
  initiatives: IInitiative[],
  usePeopleCostOnConflictCalculation: boolean
) {
  let peopleCosts = 0
  let fixedCosts = 0
  let costs = 0

  for (const initiative of initiatives) {
    const initiativeCosts = initiative.costs! // initiative.costs can't be null

    const initiativePeopleCost = getPeopleCostSum(initiativeCosts)!
    const initiativeFixedCost = getFixedCostSum(initiativeCosts)!
    const initiativeOverallCost = getOverallCostSum(initiativeCosts)!

    peopleCosts = peopleCosts + initiativePeopleCost
    fixedCosts = fixedCosts + initiativeFixedCost

    const overallCost = usePeopleCostOnConflictCalculation
      ? initiativeOverallCost
      : initiativeFixedCost

    costs = costs + overallCost!
  }

  return {
    peopleCosts,
    fixedCosts,
    costs,
  }
}

export const calculateRemaining = (yearlyBudget: IYearlyBudget): number => {
  const {total} = yearlyBudget

  const sumOfMonths = yearlyBudget.budgetMonths.reduce((accumulator, month) => {
    return accumulator + (month.amount ? month.amount : 0)
  }, 0)

  return total ? total - sumOfMonths : -sumOfMonths
}
