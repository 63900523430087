import React from 'react'

export interface IMeasurementsContext {
  initiativeRowHeight: number
  timelineBarArrowHeight: number
  timelineBarArrowYPosition: number
  initiativeHeaderHeight: number
}

export const MeasurementsContext = React.createContext<IMeasurementsContext>({
  initiativeRowHeight: 67,
  timelineBarArrowHeight: 0,
  timelineBarArrowYPosition: 0,
  initiativeHeaderHeight: 0,
})

export const MeasurementDataProvider = MeasurementsContext.Provider

export const useMeasurements = () => React.useContext(MeasurementsContext)
