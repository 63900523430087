import {primary} from '@phoenix/all'
import {css, keyframes} from 'emotion'
import React from 'react'
import styled from 'react-emotion'
import {connect} from 'react-redux'
import {changeLastScenarioAfterCopy, currentScenarioSelector} from '../../../data/scenario'
import {lastScenarioAfterCopySelector} from '../../../data/scenario'

import {toggleSlideScenarioAnimation} from '../../../data/viewOptions'
import {shouldSlideScenarioSelector} from '../../../data/viewOptions'
import {IScenario} from '../../../shared/models'
import {fullHeightFlexClass} from '../../shared'
import {ScenarioComponent} from './ScenarioComponent'

interface IScenarioComponentCopiedProps {
  navigateToListFunction: () => void
  onSuccessfulSaveFunction: () => void
  toggleSlideScenarioAnimationFunction: (shouldUpdateScenario: boolean) => void
  shouldSlideScenario: boolean
  lastScenarioAfterCopy: IScenario
  currentScenario: IScenario
  changeLastScenarioAfterCopyFunction: (scenario: IScenario | null) => void
}

interface IScenarioInnerStyledProps {
  shouldSlideScenario: boolean
}

const ANIMATION_TIME = 0.7
const ANIMATION_DELAY = 0.2
const DELAY_TIME_AFTER_SLIDE = 0.6
const TIMEOUT_TIME = (ANIMATION_TIME + DELAY_TIME_AFTER_SLIDE) * 1000

const slideToLeftKeyframes = keyframes`
    0% {
        transform: translateX(0);
    }
    
    100% {
        transform: translateX(-100%);
    }
`

const ScenarioInnerStyled = styled('div')<IScenarioInnerStyledProps>`
  position: relative;
  width: ${(props) => (props.shouldSlideScenario ? '200vw' : 'auto')};
  flex-grow: 1;
  display: flex;

  .scenario-wrapper {
    width: 100vw;
    ${fullHeightFlexClass};
    position: relative;
    ${(props) =>
      props.shouldSlideScenario &&
      `
            float: left;
            animation: ${slideToLeftKeyframes} ${ANIMATION_TIME}s cubic-bezier(0.5, 1, 0.89, 1) ${ANIMATION_DELAY}s; 
            animation-fill-mode: forwards;
        `}
  }
`

const slidingScenarioClass = css`
  background-color: ${primary.gray(0)};
  box-shadow: -2px 0 6px ${primary.gray(200)};
  z-index: 200;
`

export const ScenarioWrapper: React.FunctionComponent<IScenarioComponentCopiedProps> = React.memo(
  (props) => {
    React.useEffect(() => {
      if (props.shouldSlideScenario) {
        setTimeout(() => {
          props.toggleSlideScenarioAnimationFunction(false)
        }, TIMEOUT_TIME)

        setTimeout(() => {
          props.changeLastScenarioAfterCopyFunction(null)
        }, TIMEOUT_TIME - 300)
      }
    }, [props.shouldSlideScenario])

    return (
      <ScenarioInnerStyled shouldSlideScenario={props.shouldSlideScenario}>
        <div className="scenario-wrapper" data-testid="scenario-wrapper">
          <ScenarioComponent
            onSuccessfulSaveFunction={props.onSuccessfulSaveFunction}
            navigateToListFunction={props.navigateToListFunction}
            currentScenario={props.lastScenarioAfterCopy || props.currentScenario}
          />
        </div>

        {props.shouldSlideScenario && (
          <div
            className={`scenario-wrapper ${slidingScenarioClass}`}
            data-testid="scenario-wrapper-sliding"
          >
            <ScenarioComponent
              onSuccessfulSaveFunction={props.onSuccessfulSaveFunction}
              navigateToListFunction={props.navigateToListFunction}
              currentScenario={props.currentScenario}
            />
          </div>
        )}
      </ScenarioInnerStyled>
    )
  }
)

ScenarioWrapper.displayName = 'ScenarioWrapper'

const mapStateToProps = (state) => ({
  shouldSlideScenario: shouldSlideScenarioSelector(state),
  lastScenarioAfterCopy: lastScenarioAfterCopySelector(state),
  currentScenario: currentScenarioSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  toggleSlideScenarioAnimationFunction: (shouldSlideScenario) =>
    dispatch(toggleSlideScenarioAnimation(shouldSlideScenario)),
  changeLastScenarioAfterCopyFunction: (scenario) =>
    dispatch(changeLastScenarioAfterCopy(scenario)),
})

export const ScenarioWrapperComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScenarioWrapper)
