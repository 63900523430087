import {FVGPlugin} from '../../../../shared/toolbar/filters/FVGPlugin'
import {bindPluginClass} from '@workfront/list-plugin-utils'
import loadFilters from './loadFilters'
import {IDataTableAPI} from '@wftypes/list'
import DataFetcherPlugin, {DataFetcherAPI} from '../../dataFetcher'
import {getStorageUtil} from '@workfront/storage'
import SeparatorWidget from '../../../../shared/toolbar/widgets/SeparatorWidget'
import {IToolbarApi} from '@wf-mfe/lists'
import {TOOLBAR_PLUGIN_NAME} from '../../../../shared/toolbar/constants'
import FilterIcon from 'phoenix-icons/dist/FilterIcon.js'
import DropDownWidget, {IOption} from '../../../../shared/toolbar/widgets/DropDownWidget'
import {GetApiParameter} from '../../../../shared/toolbar/widgets/DropDownWidget'
import {PluginBase} from '@workfront/list-plugin-utils'
import {redrockClient} from '../../../../../shared/L10n'

const storageUtil = getStorageUtil()

export class ExportDropdownFilterPlugin extends PluginBase {
  static PLUGIN_DEPS = [TOOLBAR_PLUGIN_NAME, DataFetcherPlugin.getName()]
  static PLUGIN_NAME = 'toolbar-fvg'
  filterWidgetApi?: GetApiParameter

  setDropDownOptions = (filters: any[], selectedFilterId: string) => {
    const options = filters.map((filter) => ({
      id: filter.id,
      label: filter.id === '' ? redrockClient.getTextSync('all', 'All') : filter.name,
      value: filter.id || 'all',
    }))

    const selectedOption = options.find((option) => option.id === selectedFilterId)
    this.filterWidgetApi!.setOptions(options)
    this.filterWidgetApi!.setSelectedOption(selectedOption!)
  }

  handleFilterChange = (listApi: IDataTableAPI, planId) => (filter: IOption) => {
    this.filterWidgetApi!.setSelectedOption(filter)
    storageUtil.set(`sp-export-initiative-default-filter-${planId}`, filter.id)
    const dataFetcherApi = listApi.getPluginAPI<DataFetcherAPI>(DataFetcherPlugin.getName())
    dataFetcherApi!.filterListData({
      ID: filter.id,
      editIconClick: () => {
        return
      },
      text: filter.label,
    })
  }

  ready(listApi: IDataTableAPI, options?) {
    const toolbarApi = listApi.getPluginAPI<IToolbarApi>(TOOLBAR_PLUGIN_NAME)!
    const dataFetcherApi = listApi.getPluginAPI<DataFetcherAPI>(DataFetcherPlugin.getName())!
    const WIDGET_TYPE_DROPDOWN = 'dropDown'

    this.addShutdownRoutine(
      toolbarApi.registerWidget(WIDGET_TYPE_DROPDOWN, DropDownWidget),
      toolbarApi.registerWidget(toolbarApi.SEPARATOR_WIDGET_TYPE, SeparatorWidget)
    )

    listApi.overlays.forceUpdateOverlayContainer(listApi.overlays.locations.RIGHT_SIDEBAR)

    let position = 900

    this.addShutdownRoutine(
      toolbarApi.insertComponent(toolbarApi.ToolbarLocations.secondary, {
        id: 'separator-before-filter-icon',
        position: position++,
        widgetType: toolbarApi.SEPARATOR_WIDGET_TYPE,
      }),
      toolbarApi.insertComponent(toolbarApi.ToolbarLocations.secondary, {
        id: 'open-filter-selection',
        position: position++,
        widgetType: WIDGET_TYPE_DROPDOWN,
        widgetOptions: {
          dataTestId: 'open-filter-selection',
          icon: FilterIcon,
          handleChange: this.handleFilterChange(listApi, options.planId),
          getApi: (widgetApi) => {
            this.filterWidgetApi = widgetApi
          },
        },
      }),
      toolbarApi.insertComponent(toolbarApi.ToolbarLocations.secondary, {
        id: 'separator-after-filter-icon',
        position: position++,
        widgetType: toolbarApi.SEPARATOR_WIDGET_TYPE,
      })
    )
    dataFetcherApi.getDefaultFilterPromise().then((filter) => {
      loadFilters().then((savedFilters) => {
        this.setDropDownOptions(savedFilters.saved, filter.id)
      })
    })
  }
}

export default bindPluginClass(
  () => new ExportDropdownFilterPlugin(),
  FVGPlugin.PLUGIN_NAME,
  FVGPlugin.PLUGIN_DEPS || []
)
