import moment, {Moment} from 'moment'
import {
  IBudgetConflict,
  ICompetingInitiative,
  IInitiative,
  IInitiativeRole,
  IPreCalculatedData,
  IRoleConflict,
  IScenarioRole,
  IYearlyBudget,
} from '../../models'
import {IPeriodConflict} from '../../models/initiative'
import {IPeriodRemainingData} from '../../models/initiative'
import {IDistribution} from '../../models/initiative/IDistribution'
import {formatFloating} from '../../utilities'
import {hasDistributionPositiveValue} from './ConflictHelper'

const FORMAT_DATE = 'YYYY-MM'

const createCompetingInitiatives = (initiatives: IInitiative[]) =>
  initiatives.map((initiativeWithPriority: IInitiative) => {
    return {
      id: initiativeWithPriority.id,
      priority: initiativeWithPriority.order,
    }
  })

function hasAnyBudget(budgetDistribution: Map<string, IYearlyBudget>) {
  for (const yearlyBudgetDistribution of budgetDistribution.values()) {
    if (yearlyBudgetDistribution.total !== null) {
      return true
    }
  }
  return false
}

function createRoleConflictObject(periodConflicts, role) {
  if (periodConflicts.length > 0) {
    const competingInitiatives: ICompetingInitiative[] = []
    for (const periodConflict of periodConflicts) {
      for (const competingInitiative of periodConflict.competingInitiatives) {
        if (
          competingInitiatives.find(
            (initiative: ICompetingInitiative) => competingInitiative.id === initiative.id
          ) === undefined
        ) {
          competingInitiatives.push(competingInitiative)
        }
      }
    }

    return {
      roleId: role.id,
      roleName: role.name,
      totalRoleCount: Math.max(
        ...periodConflicts.map((periodConflict) => periodConflict.totalAmount)
      ),
      missingRoleCount: Math.max(
        ...periodConflicts.map((periodConflict) => periodConflict.missingAmount)
      ),
      periodConflicts,
      competingInitiatives: competingInitiatives.sort(
        (initiative1, initiative2) => initiative1.priority - initiative2.priority
      ),
    }
  } else {
    return null
  }
}

function getAllocatedRolesCount(role, crossingInitiatives, timeStartPoint) {
  return crossingInitiatives.reduce((roleCount: number, initiative: IInitiative) => {
    const peopleRole = initiative.people.roles.find((peopleRole) => peopleRole.id === role.id)
    const key = timeStartPoint.format('YYYY-MM')

    return roleCount + peopleRole!.distribution![key]
  }, 0)
}

function getCrossingInitiatives(
  role: IInitiativeRole,
  startDate: Moment,
  endDate: Moment,
  previousInitiatives: any[]
) {
  return previousInitiatives
    .filter((initiative) => {
      const initiativeDates = initiative.dates
      return !(
        initiativeDates.startDate.isAfter(endDate) || initiativeDates.endDate.isBefore(startDate)
      )
    })
    .filter((initiative) => {
      return (
        initiative.people.roles.find((initiativeRole) => initiativeRole.id === role.id) !==
        undefined
      )
    })
}

export function recalculateRoleConflicts(
  role: IInitiativeRole,
  preCalculatedData: IPreCalculatedData
): IRoleConflict | null {
  const periodConflicts: IPeriodConflict[] = []
  if (preCalculatedData.roles.has(role.id)) {
    const preCalculatedDataForRole = preCalculatedData.roles.get(role.id)
    for (const [key, value] of preCalculatedDataForRole!.entries()) {
      const roleCount = role.distribution![key]

      if (roleCount !== 0 && formatFloating(value.remainingAmount - roleCount, 3) < 0) {
        periodConflicts.push({
          period: moment.utc(key),
          missingAmount: formatFloating(roleCount - value.remainingAmount, 3),
          totalAmount: value.totalAmount,
          competingInitiatives: value.competingInitiatives,
        })
      }
    }
  }

  return createRoleConflictObject(periodConflicts, role)
}

export function recalculateBudgetConflicts(
  overallCostDistribution: IDistribution,
  preCalculatedData: IPreCalculatedData
): IBudgetConflict | null {
  const preCalculatedDataForBudget = preCalculatedData.budget
  const periodConflicts: IPeriodConflict[] = []

  for (const [date, periodRemainingData] of preCalculatedDataForBudget!.entries()) {
    if (
      overallCostDistribution[date] !== 0 &&
      formatFloating(periodRemainingData.remainingAmount - overallCostDistribution[date], 3) < 0
    ) {
      periodConflicts.push({
        period: moment.utc(date),
        missingAmount: formatFloating(
          overallCostDistribution[date] - periodRemainingData.remainingAmount,
          3
        ),
        totalAmount: periodRemainingData.totalAmount,
        competingInitiatives: periodRemainingData.competingInitiatives,
      })
    }
  }

  if (periodConflicts.length > 0) {
    const competingInitiatives: ICompetingInitiative[] = []
    for (const periodConflict of periodConflicts) {
      for (const competingInitiative of periodConflict.competingInitiatives) {
        if (
          competingInitiatives.find(
            (initiative: ICompetingInitiative) => competingInitiative.id === initiative.id
          ) === undefined
        ) {
          competingInitiatives.push(competingInitiative)
        }
      }
    }
    return {
      totalMissingAmountForInitiative: periodConflicts
        .map((periodConflict) => periodConflict.missingAmount)
        .reduce((a, b) => a + b, 0),
      totalBudgetForInitiativeDuration: periodConflicts
        .map((periodConflict) => periodConflict.totalAmount)
        .reduce((a, b) => a + b, 0),
      periodConflicts,
      allCompetingInitiatives: competingInitiatives.sort(
        (initiative1, initiative2) => initiative1.priority - initiative2.priority
      ),
    }
  } else {
    return null
  }
}

export function getInitiativeWithRecalculatedConflicts(
  initiative: IInitiative,
  preCalculatedData: IPreCalculatedData,
  usePeopleCostOnConflictCalculation: boolean,
  hasFinancialAccess: boolean
): IInitiative {
  // when has hasFinancialAccess the initiative.costs can't be null
  let budgetConflicts

  if (hasFinancialAccess) {
    budgetConflicts = initiative.costs!.overallCostDistribution
      ? recalculateBudgetConflicts(
          usePeopleCostOnConflictCalculation
            ? initiative.costs!.overallCostDistribution!
            : initiative.costs!.fixedCostDistribution!,
          preCalculatedData
        )
      : null
  } else {
    budgetConflicts = null
  }

  const roleConflicts: IRoleConflict[] = initiative.people.roles
    .map((role) => recalculateRoleConflicts(role, preCalculatedData))
    .filter((conflict) => conflict !== null) as IRoleConflict[]
  const conflicts =
    budgetConflicts === null && roleConflicts.length === 0
      ? null
      : {
          budgetConflicts,
          roleConflicts: new Map(roleConflicts.map((c) => [c.roleId, c])),
          isPriorityConflict: false,
        }

  return {
    ...initiative,
    conflicts,
  }
}

export function getPreCalculatedDataForCurrentInitiative(
  currentInitiative: IInitiative,
  initiatives: IInitiative[],
  scenarioRoles: IScenarioRole[],
  budgetDistribution: Map<string, IYearlyBudget> | null,
  usePeopleCostOnConflictCalculation,
  hasFinancialAccess: boolean
): IPreCalculatedData {
  const indexOfCurrentInitiative = initiatives.findIndex(
    (initiative) => initiative.id === currentInitiative.id
  )
  const previousInitiatives = initiatives.slice(0, indexOfCurrentInitiative)
  const rolesPreCalculatedData = new Map<string, Map<string, IPeriodRemainingData>>()
  const budgetPreCalculatedData: Map<string, IPeriodRemainingData> | null = new Map<
    string,
    IPeriodRemainingData
  >()

  const preCalculatedData: IPreCalculatedData = {
    roles: rolesPreCalculatedData,
    budget: budgetPreCalculatedData,
  }

  const startDate = currentInitiative.dates.startDate
  const endDate = currentInitiative.dates.endDate

  const crossingInitiativesInDuration = getCrossingInitiativesInDuration(
    startDate,
    endDate,
    previousInitiatives
  )

  if (hasFinancialAccess) {
    preCalculatedData.budget = getPreCalculatedDataForBudget(
      startDate,
      endDate,
      crossingInitiativesInDuration,
      budgetDistribution!, // budgetDistribution can't be null when hasFinancialAccess
      usePeopleCostOnConflictCalculation
    )
  }

  for (const scenarioRole of scenarioRoles) {
    const roleRemainingData: Map<string, IPeriodRemainingData> = getSingleRolePreCalculatedData(
      scenarioRole.role,
      startDate,
      endDate,
      scenarioRoles,
      crossingInitiativesInDuration
    )
    rolesPreCalculatedData.set(scenarioRole.role.id, roleRemainingData)
  }

  return preCalculatedData
}

function getSingleRolePreCalculatedData(
  role,
  startDate,
  endDate,
  scenarioRoles,
  crossingInitiativesInDuration
): Map<string, IPeriodRemainingData> {
  const correspondingScenarioRole: IScenarioRole = scenarioRoles.find(
    (scenarioRole) => scenarioRole.role.id === role.id
  )!

  const roleRemainingData: Map<string, IPeriodRemainingData> = new Map<
    string,
    IPeriodRemainingData
  >()

  let timeStartPoint = startDate
  while (timeStartPoint.isBefore(endDate)) {
    const crossingInitiatives = getCrossingInitiatives(
      role,
      timeStartPoint,
      timeStartPoint.clone().endOf('month'),
      crossingInitiativesInDuration
    )

    const allocatedRolesCount = getAllocatedRolesCount(role, crossingInitiatives, timeStartPoint)
    const availablePeriod =
      correspondingScenarioRole.availablePerPeriod[timeStartPoint.format('YYYY-MM')]
    const scenarioRoleCount =
      availablePeriod !== undefined ? availablePeriod : correspondingScenarioRole.availableValue
    const remainingRoleCount = scenarioRoleCount! - allocatedRolesCount
    const periodRoleRemainingData: IPeriodRemainingData = {
      totalAmount: scenarioRoleCount!,
      remainingAmount: remainingRoleCount,
      competingInitiatives: createCompetingInitiatives(crossingInitiatives),
    }

    roleRemainingData.set(timeStartPoint.format(FORMAT_DATE), periodRoleRemainingData)

    timeStartPoint = timeStartPoint.clone().add(1, 'month')
  }

  return roleRemainingData
}

// here can enter only when have financial access
function getPreCalculatedDataForBudget(
  startDate: Moment,
  endDate: Moment,
  crossingInitiatives: IInitiative[],
  budgetDistribution: Map<string, IYearlyBudget>,
  usePeopleCostOnConflictCalculation: boolean
): Map<string, IPeriodRemainingData> | null {
  let timeStartPoint = startDate

  const budgetPreCalculatedData: Map<string, IPeriodRemainingData> = new Map<
    string,
    IPeriodRemainingData
  >()

  if (!hasAnyBudget(budgetDistribution)) {
    return budgetPreCalculatedData
  }

  const scenarioBudgetByPeriod = new Map()

  for (const yearlyBudgetDistribution of budgetDistribution.values()) {
    yearlyBudgetDistribution.budgetMonths.forEach((yearMonthBudget) =>
      scenarioBudgetByPeriod.set(
        yearMonthBudget.yearMonthDate.format(FORMAT_DATE),
        yearMonthBudget.amount
      )
    )
  }

  while (timeStartPoint.isBefore(endDate)) {
    const date = timeStartPoint.format(FORMAT_DATE)
    const scenarioBudgetForPeriod = scenarioBudgetByPeriod.get(date)

    const crossingInitiativesWithCosts = getCrossingInitiativesWithCosts(
      timeStartPoint,
      timeStartPoint.clone().endOf('month'),
      crossingInitiatives,
      usePeopleCostOnConflictCalculation
    )

    const allocatedBudget = getAllocatedBudgetForPeriod(
      crossingInitiativesWithCosts,
      usePeopleCostOnConflictCalculation,
      date
    )

    const remainingBudget = scenarioBudgetForPeriod - allocatedBudget

    const periodRemainingBudgetData: IPeriodRemainingData = {
      totalAmount: scenarioBudgetForPeriod,
      remainingAmount: remainingBudget,
      competingInitiatives: createCompetingInitiatives(crossingInitiativesWithCosts),
    }

    budgetPreCalculatedData.set(date, periodRemainingBudgetData)

    timeStartPoint = timeStartPoint.clone().add(1, 'month')
  }

  return budgetPreCalculatedData
}

function getAllocatedBudgetForPeriod(
  initiatives: IInitiative[],
  usePeopleCostOnConflictCalculation: boolean,
  date: string
) {
  //initiative.costs can't be null here as it calls when has financial access
  if (usePeopleCostOnConflictCalculation) {
    return initiatives.reduce((overallCost: number, initiative: IInitiative) => {
      return overallCost + initiative.costs!.overallCostDistribution![date]
    }, 0)
  } else {
    return initiatives.reduce((overallCost: number, initiative: IInitiative) => {
      return overallCost + initiative.costs!.fixedCostDistribution![date]
    }, 0)
  }
}

function getCrossingInitiativesInDuration(
  startDate: Moment,
  endDate: Moment,
  previousInitiatives: any[]
) {
  return previousInitiatives.filter((initiative) => {
    const initiativeDates = initiative.dates
    return !(
      initiativeDates.startDate.isAfter(endDate) || initiativeDates.endDate.isBefore(startDate)
    )
  })
}

function getCrossingInitiativesWithCosts(
  startDate: Moment,
  endDate: Moment,
  previousInitiatives: any[],
  usePeopleCostOnConflictCalculation: boolean
) {
  return previousInitiatives
    .filter((initiative) => {
      const initiativeDates = initiative.dates
      return !(
        initiativeDates.startDate.isAfter(endDate) || initiativeDates.endDate.isBefore(startDate)
      )
    })
    .filter((initiative) => {
      return usePeopleCostOnConflictCalculation
        ? hasDistributionPositiveValue(initiative.costs.overallCostDistribution)
        : hasDistributionPositiveValue(initiative.costs.fixedCostDistribution)
    })
}

export function resetInitiativesConflicts(initiatives: IInitiative[]): IInitiative[] {
  return initiatives.map((initiative: IInitiative) => {
    return {
      ...initiative,
      conflicts: null,
    }
  })
}

export function resetInitiativesBudgetConflicts(initiatives: IInitiative[]): IInitiative[] {
  return initiatives.map((initiative: IInitiative) => {
    const conflicts =
      !initiative.conflicts ||
      (!initiative.conflicts.roleConflicts.size && !initiative.conflicts.isPriorityConflict)
        ? null
        : {
            ...initiative.conflicts,
            budgetConflicts: null,
          }

    return {
      ...initiative,
      conflicts: conflicts,
    }
  })
}
