import React from 'react'
import {primary, Text, IconComponent} from '@phoenix/all'
import {css} from 'react-emotion'
import {cx} from 'react-emotion'

interface IFloatingMenuActionItemProps {
  Icon?: IconComponent | null
  text?: string | JSX.Element
  testId?: string
  tabIndex?: number
  className?: string
  onClick?: () => void | null
}

const actionItemContainerClass = css`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;

  :hover {
    cursor: pointer;
    background-color: ${primary.gray(100)};
  }
`

const textClass = css`
  margin: 0 0 0 9px;
  font-size: 14px;
  line-height: 20px;
  color: ${primary.gray(700)};
`

export const FloatingMenuActionItemComponent: React.FC<IFloatingMenuActionItemProps> = ({
  className,
  Icon,
  text,
  testId,
  tabIndex,
  onClick,
  children,
}) => {
  const handleKeyPress = (e) => {
    const keyboardClickKeys = ['Enter', 'Space', ' ']
    if (onClick && keyboardClickKeys.includes(e.key)) {
      onClick()
    }
  }

  return (
    <div
      data-testid={testId}
      className={cx(actionItemContainerClass, className)}
      onClick={onClick}
      role="button"
      tabIndex={tabIndex || 0}
      onKeyPress={handleKeyPress}
    >
      {Icon && <Icon />}
      {text && <Text className={textClass}>{text}</Text>}
      {children}
    </div>
  )
}
