import {toast} from '@phoenix/all'
import {getStorageUtil} from '@workfront/storage'
import isEqual from 'lodash/isEqual'
import {useDispatch, useSelector} from 'react-redux'
import {createNewPlan, currentPlanSelector, savedPlanSelector, updatePlan} from '../../data/plan'
import {
  changeCurrentScenario,
  currentScenarioSelector,
  lastScenarioSelector,
} from '../../data/scenario'
import {isLoadingSelector, togglePlanLoading} from '../../data/viewOptions'
import {IScenario} from '../models'
import {hasPlanChanges, hasPublishedScenario, isNewPlan} from './PlanHelper'
import {hasScenarioChanges, serializeScenario} from './ScenarioHelper'
import {financialAccessDeniedSelector} from '../../data/settings'
import {translate} from '../utilities/TranslateHelper'

export const useSavePlanHook = (onSuccessfulSaveFunction) => {
  const messages = {
    scenarioInitial: translate('sp.scenario.initial'),
    scenario: (params) => translate('sp.scenario', params),
    messagesPlanSave: translate('sp.messages.plan.save'),
    messagesPlanSaveChanges: (params) => translate('sp.messages.plan.save.changes', params),
  }

  const dispatch = useDispatch()
  const props = {
    savedPlan: useSelector(savedPlanSelector),
    currentPlan: useSelector(currentPlanSelector),
    currentScenario: useSelector(currentScenarioSelector),
    isLoading: useSelector(isLoadingSelector),
    lastScenario: useSelector(lastScenarioSelector),
    hasFinancialAccess: !useSelector(financialAccessDeniedSelector),

    updatePlanFunction: (plan, currentScenarioIndex, _lastScenario, hasFinancialAccess) =>
      dispatch(updatePlan(plan, currentScenarioIndex, _lastScenario, hasFinancialAccess)),
    togglePlanLoadingFunction: (loading) => dispatch(togglePlanLoading(loading)),
    createNewPlanFunction: (plan) => dispatch(createNewPlan(plan)),
    changeCurrentScenarioFunction: (scenario, usePeopleCostOnConflictCalculation) =>
      dispatch(changeCurrentScenario(scenario, null, usePeopleCostOnConflictCalculation)),
  }

  const {
    isLoading,
    currentPlan,
    savedPlan,
    currentScenario,
    lastScenario,
    togglePlanLoadingFunction,
    updatePlanFunction,
    createNewPlanFunction,
    changeCurrentScenarioFunction,
    hasFinancialAccess,
  } = props

  const isPlanChanged =
    isNewPlan(currentPlan) ||
    hasPlanChanges(savedPlan, currentPlan) ||
    hasScenarioChanges(currentPlan, currentScenario)

  const changeLocalStoragePlanId = (planInitialId: string, newId: string): void => {
    const storageUtil = getStorageUtil()
    const initialValue = storageUtil.get(planInitialId)
    storageUtil.remove(planInitialId)
    storageUtil.set(newId, initialValue)
  }

  const getCurrentScenarioIndex = () => {
    return currentScenario !== null
      ? currentPlan.scenarios.findIndex((scenario) => scenario.id === currentScenario!.id)
      : null
  }

  const getChangedScenarioIdes = (): string[] => {
    const changedScenarios: IScenario[] = []

    currentPlan.scenarios.forEach((scenario) => {
      const hasScenario = savedPlan.scenarios.find((item) => item.id === scenario.id)
      const isCurrentScenario = currentScenario && currentScenario.id === scenario.id
      if (!hasScenario) {
        changedScenarios.push(scenario)
      } else if (
        hasScenario &&
        !isEqual(serializeScenario(scenario), serializeScenario(hasScenario)) &&
        !isCurrentScenario
      ) {
        changedScenarios.push(scenario)
      }
    })
    const currentScenarioFromSavedPlan = savedPlan.scenarios[getCurrentScenarioIndex()!]
    if (
      currentScenario &&
      currentScenarioFromSavedPlan &&
      !isEqual(serializeScenario(currentScenario), serializeScenario(currentScenarioFromSavedPlan))
    ) {
      changedScenarios.push(currentScenario)
    }

    return changedScenarios.map((scenario) => scenario.id)
  }

  const generatePlanSaveNotification = (scenarios: IScenario[], scenarioIdes: string[]): string => {
    let notification = ''

    for (let i = 0; i < scenarios.length; i++) {
      const scenario = scenarios[i]
      if (scenarioIdes.includes(scenario.id)) {
        if (scenario.isInitial) {
          notification += messages.scenarioInitial
        } else {
          if (notification !== '') {
            notification += `, `
          }
          notification += `${messages.scenario([i + 1])}`
        }
      }
    }
    if (notification === '') {
      return messages.messagesPlanSave
    }
    return messages.messagesPlanSaveChanges([notification])
  }

  const savePlan = () => {
    if (isPlanChanged && !isLoading) {
      const planToSave = {
        ...currentPlan,
      }
      if (currentScenario !== null) {
        planToSave.scenarios = currentPlan.scenarios.map((scenario) => {
          if (scenario.id === currentScenario.id) {
            return {...currentScenario}
          } else {
            return scenario
          }
        })
      }
      if (!hasPublishedScenario(planToSave)) {
        planToSave.scenarios[0].isInitial = true
      }
      const currentScenarioIndex = getCurrentScenarioIndex()

      const changedScenarioIdes = getChangedScenarioIdes()

      togglePlanLoadingFunction(true)
      if (!isNewPlan(planToSave)) {
        updatePlanFunction(planToSave, currentScenarioIndex, lastScenario, hasFinancialAccess)
          .then(() => {
            const notification = generatePlanSaveNotification(
              currentPlan.scenarios,
              changedScenarioIdes
            )
            toast.success(notification)
          })
          .then(() => {
            document.title = planToSave.name
          })
          .finally(() => {
            togglePlanLoadingFunction(false)
          })
      } else {
        createNewPlanFunction(planToSave)
          .then((newPlan) => {
            const planNewId = newPlan.plan.id
            const usePeopleCostOnConflictCalculation =
              newPlan.plan.settings.usePeopleCostOnConflictCalculation

            changeLocalStoragePlanId(planToSave.id!, planNewId)

            const scenarioId =
              currentScenarioIndex !== null
                ? newPlan.plan.scenarios[currentScenarioIndex].id
                : 'compare'
            onSuccessfulSaveFunction(planNewId, scenarioId)
            const scenario =
              currentScenarioIndex !== null ? newPlan.plan.scenarios[currentScenarioIndex!] : null
            changeCurrentScenarioFunction(scenario, usePeopleCostOnConflictCalculation)
            document.title = newPlan.plan.name
          })
          .finally(() => {
            togglePlanLoadingFunction(false)
          })
      }
    }
  }

  return {savePlan}
}
