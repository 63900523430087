import {primary, Text, Ellipse} from '@phoenix/all'
import {css, cx} from 'emotion'
import * as React from 'react'
import {useSelector} from 'react-redux'
import {hoursForMeasurementsSelector} from '../../../../../../data/plan/selectors/hoursForMeasurementsSelector'
import {formatFloating} from '../../../../../../shared/utilities'
import {Translate} from '../../../../../shared/Translate'
import {LEFT_SECTION_WIDTH} from './constans'
import {RoleDistributionDetailsRow} from './RoleDistributionDetailsRow'
import {RoleDistributionContext} from '../../../../../../shared/context/RoleDistributionContext'
import {RoleDistributionRemove, roleDistributionRemoveRowClass} from './RoleDistributionRemove'

interface IRoleDistributionTableRowComponentProps {
  index: number
  style: any
  data: string
}

const rowClass = css`
  border-bottom: 1px solid ${primary.gray(200)};
  &:hover {
    .${roleDistributionRemoveRowClass} {
      visibility: visible;
    }
  }
`

const roleNameClass = css`
  color: ${primary.gray(900)};
  padding-left: 15px;
`

const roleNameWrapperClass = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 176px;
  padding-top: 8px;
`

const rowContentClass = css`
  align-items: stretch;
  display: flex;
  height: 100%;
`

const rowLeftClass = css`
  min-width: ${LEFT_SECTION_WIDTH}px;
  position: sticky;
  left: 0;
  background-color: ${primary.white()};
  z-index: 100;
`

const rowRightClass = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 19px;
`

const labelWrapperClass = css`
  padding: 15px 8px 0 15px;
`

const labelClass = css`
  color: ${primary.gray(400)};
  display: flex;

  & + & {
    margin-top: 18px;
  }
`

const shadowClass = css`
  box-shadow: 2px 0px 3px -1px rgba(0, 0, 0, 0.1);
`

const ellipseWrapperClass = css`
  max-width: 103px;
  display: inline-block;
  margin-left: 3px;
`

export const RoleDistributionTableRowComponent: React.FC<IRoleDistributionTableRowComponentProps> = React.memo(
  ({style, data: roleId}) => {
    const rowRef: React.RefObject<HTMLDivElement> = React.useRef(null)
    const [parentScrollLeft, setParentScrollLeft] = React.useState(0)
    const useHoursForMeasurements = useSelector(hoursForMeasurementsSelector)
    const {rolesDetails} = React.useContext(RoleDistributionContext)
    let role = rolesDetails[roleId]

    React.useEffect(() => {
      role = rolesDetails[roleId]
    }, [rolesDetails])

    React.useEffect(() => {
      const parent = rowRef.current!.closest('.scrollContainer')
      setParentScrollLeft(parent!.scrollLeft)

      const onScrollHandler = () => {
        setParentScrollLeft(parent!.scrollLeft)
      }

      parent!.addEventListener('scroll', onScrollHandler)

      return () => {
        parent!.removeEventListener('scroll', onScrollHandler)
      }
    }, [])

    return (
      <div
        className={rowClass}
        ref={rowRef}
        style={{...style, width: `fit-content`}}
        data-testid={`role-distribution-row-${roleId}`}
      >
        <div className={rowContentClass}>
          <div className={cx(rowLeftClass, parentScrollLeft > 0 ? shadowClass : '')}>
            <div className={roleNameWrapperClass}>
              <Text
                className={roleNameClass}
                title={role.role.name}
                data-testid="distribution-role-name"
              >
                {role.role.name}
              </Text>
              <RoleDistributionRemove roleId={roleId} />
            </div>

            <div className={labelWrapperClass}>
              <Text.Small className={labelClass}>
                <Translate messageKey="timeline.available" /> (
                <Translate
                  messageKey={
                    useHoursForMeasurements ? 'total' : 'header.widget.role.available.max'
                  }
                />
                <span className={ellipseWrapperClass} data-testid={`available-${roleId}`}>
                  <Ellipse>
                    {role.availableValue !== null
                      ? formatFloating(role.availableValue, 3)
                      : role.availableValue}
                  </Ellipse>
                </span>
                )
              </Text.Small>

              <Text.Small
                className={labelClass}
                data-testid={useHoursForMeasurements ? 'required-total' : 'required-max'}
              >
                <Translate messageKey="required" /> (
                <Translate
                  messageKey={
                    useHoursForMeasurements ? 'total' : 'header.widget.role.available.max'
                  }
                />
                <span className={ellipseWrapperClass}>
                  <Ellipse>
                    {role.requiredValue !== null
                      ? formatFloating(role.requiredValue, 3)
                      : role.requiredValue}
                  </Ellipse>
                </span>
                )
              </Text.Small>

              <Text.Small className={labelClass}>
                <Translate messageKey="difference" />
              </Text.Small>

              <Text.Small className={labelClass}>
                <Translate messageKey="header.widget.role.utilization" /> (%)
              </Text.Small>
            </div>
          </div>

          <div className={rowRightClass}>
            <RoleDistributionDetailsRow
              roleId={roleId}
              roleDetails={rolesDetails[roleId]}
              draggableElementTop={style.top}
            />
          </div>
        </div>
      </div>
    )
  }
)

RoleDistributionTableRowComponent.displayName = 'RoleDistributionTableRowComponent'
