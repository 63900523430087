import EnterpriseScenarioPlanningIcon from 'phoenix-icons/dist/EnterpriseScenarioPlanningIcon.js'
import {secondary} from '@phoenix/all'
import * as React from 'react'
import {connect} from 'react-redux'
import {activeScenarioSelector} from '../../../../data/plan'
import {formatNumber} from '../../../../shared/utilities'
import {CircleInfoComponent, getArrowDirection, InfoComponent} from './shared'

interface IInitiativesRowComponent {
  initiativeCount: number
  conflictedInitiativeCount: number
  activeScenarioInitiativeCount: number
  activeScenarioConflictedInitiativeCount: number
  publishedCount: number
  isScenarioPublished: boolean
}

export const InitiativesRow: React.FunctionComponent<IInitiativesRowComponent> = (
  props: IInitiativesRowComponent
) => {
  const {
    initiativeCount,
    conflictedInitiativeCount,
    activeScenarioInitiativeCount,
    activeScenarioConflictedInitiativeCount,
    publishedCount,
    isScenarioPublished,
  } = props

  const initiativesArrowDirection = getArrowDirection(
    initiativeCount,
    activeScenarioInitiativeCount
  )

  const conflictedInitiativesArrowDirection = getArrowDirection(
    conflictedInitiativeCount,
    activeScenarioConflictedInitiativeCount
  )

  return (
    <>
      <CircleInfoComponent
        icon={<EnterpriseScenarioPlanningIcon />}
        color={secondary.red(400)}
        part={conflictedInitiativeCount}
        total={initiativeCount}
      />
      <InfoComponent
        testId={'initiatives'}
        labelMessageKey={'sp.initiatives'}
        arrowDirection={initiativesArrowDirection}
        abbreviatedValue={formatNumber(initiativeCount)!}
        value={formatNumber(initiativeCount, 2, false)!}
      />
      {conflictedInitiativeCount > 0 && (
        <React.Fragment>
          <InfoComponent
            testId={'conflicting.initiatives'}
            labelMessageKey={'scenario.card.conflicting.initiatives'}
            arrowDirection={conflictedInitiativesArrowDirection}
            abbreviatedValue={formatNumber(conflictedInitiativeCount)!}
            value={formatNumber(conflictedInitiativeCount, 2, false)!}
            color={secondary.red(400)}
          />
        </React.Fragment>
      )}

      {isScenarioPublished && (
        <InfoComponent
          testId="published-count"
          labelMessageKey="scenario.published"
          abbreviatedValue={formatNumber(publishedCount)!}
          value={formatNumber(publishedCount, 2, false)!}
        />
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  activeScenarioInitiativeCount: activeScenarioSelector(state).initiatives.length,
  activeScenarioConflictedInitiativeCount: activeScenarioSelector(state).conflictsCount,
})

export const InitiativesRowComponent = connect(mapStateToProps)(InitiativesRow)
