import * as React from 'react'
import {css, keyframes} from 'react-emotion'
import {LocalizationProvider, Localization} from '@wf-mfe/localize-react'
import {primary} from '@phoenix/all'
import {redrockClientFactory} from '../../../../shared/L10n'

const loaderAnimation = keyframes`
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
`

const loader = css`
  padding-top: 30%;
  text-align: center;
  color: ${primary.gray(400)};
  animation: ${loaderAnimation} 1.5s linear infinite;
`

export default function Loader(/*props*/): JSX.Element {
  return (
    <LocalizationProvider clientFactory={redrockClientFactory}>
      <Localization messageKey="loading" fallback="Loading">
        {(loadingMsg) => <div className={loader}>{loadingMsg()}</div>}
      </Localization>
    </LocalizationProvider>
  )
}
