interface BuildUiItemReturnType {
  ID: string
  text: string
  editIconClick: undefined
}

export default function () {
  return function buildUiItem(dataItem: Record<string, unknown>): BuildUiItemReturnType {
    return {
      ID: dataItem.id as string,
      text: dataItem.name as string,
      editIconClick: undefined,
    }
  }
}
