import moment, {Moment} from 'moment'
import * as React from 'react'
import {connect} from 'react-redux'
import {currentPlanSelector, timelineCellWidthSelector} from '../../../../../data/plan'
import {visibleConflictSelector} from '../../../../../data/viewOptions'
import {peopleCostOnConflictCalculationSelector} from '../../../../../data/plan/selectors/peopleCostOnConflictCalculationSelector'
import {YEAR_MONTH_FORMAT} from '../../../../../shared/constants/dateFormats'
import {
  calculateBarWidthAndPosition,
  calculateStartDate,
  getInitiativeConflictsByDate,
  getInitiative,
  getVisibleConflictByDate,
  IVisibleConflictInfoByDate,
} from '../../../../../shared/helpers/TimelineBarHelper'
import {IInitiative, IRoleConflict} from '../../../../../shared/models/initiative'
import {RoleContouringComponent} from './RoleContouringComponent'
import {IInitiativeConflictsDetailsDates} from '../../../../../shared/models/initiative/IInitiativeConflicsDetailsByDate'
import map from 'lodash/map'
import {currentInitiativeSelector} from '../../../../../data/initiative'

export interface IRoleContouringListComponentProps {
  cellWidth: number
  initiative: IInitiative
  planStartDate: Moment
  setMouseInsideTooltip?: (value: boolean) => void
  setBarArrowsStates?: (state: {[propsrty: string]: boolean}) => void
  isEmptyBar?: boolean
  setIsBarLeftTriangleConflicted?: (value: boolean) => void
  setIsBarRightTriangleConflicted?: (value: boolean) => void
  className?: string
  visibleConflict: IRoleConflict | null
  currentInitiative: IInitiative | null
  usePeopleCostOnConflictCalculation: boolean
  showLeftTriangle?: boolean
  showRightTriangle?: boolean
}

interface IInfoByDate {
  [date: string]: {
    conflictsByDate: null | IInitiativeConflictsDetailsDates
    visibleConflictInfoByDate: IVisibleConflictInfoByDate
  }
}

export const RoleContouringList: React.FC<IRoleContouringListComponentProps> = React.memo(
  ({
    initiative,
    initiative: {
      conflicts,
      dates: {startDate, endDate},
    },
    planStartDate,
    cellWidth,
    setMouseInsideTooltip,
    isEmptyBar,
    setBarArrowsStates,
    setIsBarLeftTriangleConflicted,
    setIsBarRightTriangleConflicted,
    className,
    usePeopleCostOnConflictCalculation,
    visibleConflict,
    currentInitiative,
    showLeftTriangle,
    showRightTriangle,
  }) => {
    const distribution: IInfoByDate = React.useMemo(() => {
      const infoByDate = {} // TODO refactor
      const conflictCondition = conflicts && (conflicts.budgetConflicts || conflicts.roleConflicts)
      const visibleConflictCondition =
        visibleConflict && currentInitiative && currentInitiative.order >= initiative.order

      if (conflictCondition || visibleConflictCondition) {
        const startDateClone = startDate.clone()

        while (startDateClone.isSameOrBefore(endDate)) {
          const formattedDate = startDateClone.format(YEAR_MONTH_FORMAT)
          const conflictsByDate = conflictCondition
            ? getInitiativeConflictsByDate(
                initiative,
                formattedDate,
                usePeopleCostOnConflictCalculation
              )
            : null

          const visibleConflictInfoByDate = visibleConflictCondition
            ? getVisibleConflictByDate(
                visibleConflict!,
                getInitiative(currentInitiative, initiative),
                formattedDate,
                usePeopleCostOnConflictCalculation
              )
            : null

          infoByDate[formattedDate] = {
            conflictsByDate: conflictsByDate,
            visibleConflictInfoByDate: visibleConflictInfoByDate,
          }

          startDateClone.add(1, 'months')
        }
      }
      const isLeftArrowConflicted =
        infoByDate[startDate.clone().format(YEAR_MONTH_FORMAT)].conflictsByDate !== null
      const isRightArrowConflicted =
        infoByDate[endDate.clone().format(YEAR_MONTH_FORMAT)].conflictsByDate !== null
      setBarArrowsStates &&
        setBarArrowsStates({
          isLeftArrowConflicted,
          isRightArrowConflicted,
        })
      return infoByDate
    }, [startDate, endDate, conflicts, visibleConflict, currentInitiative, initiative])

    const {width, startPosition} = React.useMemo(
      () => calculateBarWidthAndPosition(initiative.dates, planStartDate, cellWidth),
      [initiative, planStartDate, cellWidth]
    )

    return (
      <g>
        {map(distribution, (infoByDate, date) => {
          const contouringX = calculateStartDate(
            moment(date),
            moment(planStartDate.format('YYYY-MM')),
            cellWidth
          )

          if (infoByDate.conflictsByDate || infoByDate.visibleConflictInfoByDate) {
            return (
              <RoleContouringComponent
                visibleConflictInfo={infoByDate.visibleConflictInfoByDate}
                key={`${initiative.order}-${date}`}
                className={className}
                isEmptyBar={isEmptyBar}
                date={date}
                setBarArrowsStates={setBarArrowsStates}
                initiative={initiative}
                conflictsByDate={infoByDate.conflictsByDate}
                cellWidth={cellWidth}
                contouringX={contouringX}
                setMouseInsideTooltip={setMouseInsideTooltip}
                setIsBarLeftTriangleConflicted={setIsBarLeftTriangleConflicted}
                setIsBarRightTriangleConflicted={setIsBarRightTriangleConflicted}
                barWidth={width}
                barStartPosition={startPosition}
                showLeftTriangle={showLeftTriangle}
                showRightTriangle={showRightTriangle}
              />
            )
          }
        })}
      </g>
    )
  }
)

RoleContouringList.displayName = 'RoleContouring'

const mapStateToProps = (state) => ({
  cellWidth: timelineCellWidthSelector(state),
  planStartDate: currentPlanSelector(state)!.startDate,
  visibleConflict: visibleConflictSelector(state),
  currentInitiative: currentInitiativeSelector(state),
  usePeopleCostOnConflictCalculation: peopleCostOnConflictCalculationSelector(state),
})

export const RoleContouringListComponent = connect(mapStateToProps)(RoleContouringList)
