import {primary} from '@phoenix/all'
import {useState} from 'react'
import * as React from 'react'
import styled, {css, cx} from 'react-emotion'
import {activeScenarioSelector} from '../../../data/plan'
import {viewAccessSelector} from '../../../data/settings'
import {togglePublishDialog} from '../../../data/viewOptions'
import {CARD_LG_SIZE, CARD_SM_SIZE} from '../../../shared/constants/Sizes'
import {getLineColor} from '../../../shared/helpers'
import {IScenario} from '../../../shared/models/scenario'

import {connect} from 'react-redux'
import {
  BudgetRowComponent,
  CardHeaderComponent,
  DescriptionRowComponent,
  InitiativesRowComponent,
  NetValueRowComponent,
  RolesRowComponent,
} from './Card'
import {PublishButtonComponent} from './Card/PublishButtonComponent'
import {CARD_PADDING} from './ScenarioCardListComponent'

interface IScenarioCard {
  scenario: IScenario
  activeScenario: IScenario
  togglePublishDialogFunction: (id: string) => void
  hasPublishedScenario: boolean
  hasEditAccess: boolean
  isScrolled: boolean
  deletePlanFunction: () => void
  scrollToEndFunction: () => void
  setIsMoreIconClicked: (isMoreIconClicked: boolean) => void
  financialAccessDenied: boolean
  scenariosLength: number
  onSuccessfulSaveFunction: (planId: string, currentScenarioId: string | null) => void
  navigateToListFunction: () => void
  setFocusedScenarioId: (focusedScenarioId: string | null) => void
  scenarioId: string
  focusedScenarioId: string
}

interface IScenarioCardStyledProps {
  hasPublishedScenario: boolean
  isPublished: boolean
  deleteAnimation: boolean
  isHovered: boolean
  isActive: boolean
  isScrolled: boolean
  minHeight: number
  padding: number
  hasEditAccess: boolean
  financialAccessDenied: boolean
  scenariosLength: number
}

interface IStatusLineProps {
  color: string
}

export const CARD_MIN_HEIGHT = 523
export const NOT_FINANCIAL_CARD_MIN_HEIGHT = 375
const CARD_HOVER_PADDING = 16

const cardInnerClass = css`
  padding: 16px 15px 0 15px;
  border: 1px solid ${primary.gray(200)};
  background-color: #fff;
  border-radius: 4px;
  height: 100%;
`
const StatusLineStyled = styled('span')<IStatusLineProps>`
  transition: all 0.2s;
  display: inline-block;
  height: 5px;
  width: calc(100% - 16px);
  position: absolute;
  top: 0px;
  left: 8px;
  border: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: ${(props) => props.color};
`
export const ScenarioCardStyled = styled('div')<IScenarioCardStyledProps>`
  position: relative;
  transition: padding 0.2s;
  & > div {
    transition: padding-left 0.2s, padding-right 0.2s;
  }
  padding: 0 ${(props) => props.padding}px;
  height: ${(props) => props.minHeight}px;
  min-width: ${CARD_SM_SIZE}px;
  max-width: ${CARD_SM_SIZE}px;
  ${(props) => props.deleteAnimation && ShadowAnimation};
  ${(props) =>
    props.isActive &&
    props.scenariosLength > 1 &&
    `
        margin-left: 0;
        position: absolute;
        left: ${CARD_PADDING}px;
        z-index: 2;
     `};

  &:before {
    ${(props) =>
      props.isActive &&
      `
            content: '';
            height: 100%;
            width: 8px;
            background-color: ${primary.gray(0)};
            position: absolute;
            left: 0;
        `};
  }

  .card-inner {
    ${(props) => (props.isScrolled ? 'box-shadow: 3px 0 6px 0 rgba(0,35,64,0.2)' : '')};
  }

  ${(props) =>
    props.financialAccessDenied &&
    `
        .${cardRowsWrapperClass} {
            margin-bottom: 47px;
        }
            
        .card-description {
            height: 77px;
        }
    `}

  @media (min-height: 791px) {
    height: ${(props) => props.minHeight}px;
  }

  @media (min-width: 1441px) {
    min-width: ${CARD_LG_SIZE}px;
    max-width: ${CARD_LG_SIZE}px;
  }
  ${(props) =>
    props.hasEditAccess &&
    props.isHovered &&
    (!props.hasPublishedScenario || props.isPublished) &&
    `
        margin: -9px 0 0;
        padding:0 0px;
        &>div{
            box-shadow: 0px 3px 6px 0 rgba(0,35,64,0.2);
            border-color: transparent;
            padding: 25px 23px 0;
        }
        &>span{
            width: 100%;
            left: 0;
        }
        .card-description {
            padding-bottom: ${!props.financialAccessDenied ? '' : '77px'};
        }
        
        @media (min-height: 791px) {
            height: ${props.minHeight + CARD_HOVER_PADDING}px;
        }
        
        @media (min-height: 500px) {
           height: ${props.minHeight + CARD_HOVER_PADDING}px;
        }
    `}
`

const ShadowAnimation = css`
  @keyframes ShadowAnimation {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  animation: ShadowAnimation 0.5s;
  animation-fill-mode: forwards;
  & ~ div {
    transform: translateX(-100%);
    transition: transform 0.5s;
  }
`

const cardRowsWrapperClass = css`
  display: table;
  cursor: default;
  position: relative;
  width: 100%;
`

const cardRowClass = css`
  display: table-row;
  padding: 20px 0;
  height: 86px;

  & + & {
    &:after {
      content: '';
      background-color: ${primary.gray(200)};
      position: absolute;
      left: 0;
      height: 1px;
      width: 100%;
    }
  }
`

const cardRowFooterClass = css`
  display: table-caption;
  padding: 0;
  caption-side: bottom;
  border: none;
`

export const ScenarioCard: React.FunctionComponent<IScenarioCard> = (props) => {
  const [deleteAnimation, setDeleteAnimation] = useState(false)
  const [isHovered, setHovered] = useState(false)

  const deleteCardAnimation = (
    scenario: IScenario,
    deleteScenario: (scenario: IScenario) => void
  ) => {
    setDeleteAnimation(true)
    setTimeout(() => {
      deleteScenario(scenario)
    }, 1000)
  }

  React.useEffect(() => {
    const onKeyupHandler = (e: any) => {
      if (e.key === 'Tab' && e.target && e.target.offsetParent) {
        if (
          !(
            e.target.offsetParent.firstChild.classList.contains('card-inner') ||
            e.target.offsetParent.offsetParent.firstElementChild.classList.contains('card-inner')
          )
        ) {
          props.setFocusedScenarioId(null)
        }
      }
    }

    document.addEventListener('keyup', onKeyupHandler)

    return () => {
      document.removeEventListener('keyup', onKeyupHandler)
    }
  }, [])

  return (
    <ScenarioCardStyled
      isHovered={isHovered || props.scenarioId === props.focusedScenarioId}
      isPublished={props.scenario.isPublished}
      hasPublishedScenario={props.hasPublishedScenario}
      isActive={props.scenario.id === props.activeScenario.id}
      scenariosLength={props.scenariosLength}
      isScrolled={props.isScrolled}
      padding={CARD_PADDING}
      minHeight={!props.financialAccessDenied ? CARD_MIN_HEIGHT : NOT_FINANCIAL_CARD_MIN_HEIGHT}
      deleteAnimation={deleteAnimation}
      data-testid="scenario-card"
      hasEditAccess={props.hasEditAccess}
      financialAccessDenied={props.financialAccessDenied}
    >
      <div
        className={`${cardInnerClass} card-inner`}
        onMouseEnter={() => {
          setHovered(true)
          props.setFocusedScenarioId(null)
        }}
        onMouseLeave={() => {
          setHovered(false)
        }}
        onFocus={() => {
          props.setFocusedScenarioId(props.scenarioId)
        }}
      >
        <CardHeaderComponent
          setIsMoreIconClicked={props.setIsMoreIconClicked}
          scenario={props.scenario}
          deleteCardAnimation={deleteCardAnimation}
          scrollToEndFunction={props.scrollToEndFunction}
          deletePlanFunction={props.deletePlanFunction}
          setHovered={setHovered}
        />

        <DescriptionRowComponent scenario={props.scenario} />

        <div className={cardRowsWrapperClass}>
          <div className={cardRowClass}>
            <InitiativesRowComponent
              initiativeCount={props.scenario.initiatives.length}
              conflictedInitiativeCount={props.scenario.conflictsCount}
              publishedCount={props.scenario.publishedInitiativesCount}
              isScenarioPublished={props.scenario.isPublished}
            />
          </div>
          <div className={cardRowClass}>
            <RolesRowComponent
              available={props.scenario.people.available}
              required={props.scenario.people.required}
            />
          </div>
          {!props.financialAccessDenied && (
            <React.Fragment>
              <div className={cardRowClass} data-testid="card-budget-row">
                <BudgetRowComponent budget={props.scenario.budgets} />
              </div>
              <div className={cardRowClass} data-testid="card-netValue-row">
                <NetValueRowComponent netValue={props.scenario.netValue} />
              </div>
            </React.Fragment>
          )}
          {props.hasEditAccess && (!props.hasPublishedScenario || props.scenario.isPublished) && (
            <div className={cx(cardRowClass, cardRowFooterClass)}>
              <PublishButtonComponent
                isPublished={props.scenario.isPublished}
                hasPublishedScenario={props.hasPublishedScenario}
                isHovered={isHovered || props.focusedScenarioId === props.scenarioId}
                scenario={props.scenario}
                initiativeCount={props.scenario.initiatives.length}
                onSuccessfulSaveFunction={props.onSuccessfulSaveFunction}
              />
            </div>
          )}
        </div>
      </div>
      <StatusLineStyled
        color={getLineColor(props.hasPublishedScenario, props.scenario.isPublished)}
      />
    </ScenarioCardStyled>
  )
}
const mapDispatchToProps = (dispatch) => ({
  togglePublishDialogFunction: (isOpen) => dispatch(togglePublishDialog(isOpen)),
})
const mapStateToProps = (state) => ({
  hasEditAccess: !viewAccessSelector(state),
  activeScenario: activeScenarioSelector(state),
})
export const ScenarioCardComponent = connect(mapStateToProps, mapDispatchToProps)(ScenarioCard)
