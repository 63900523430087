import {LOAD_GLOBAL_ROLES_SUCCESS} from './actions'
import {IGlobalRolesState} from './IGlobalRolesState'

const globalRolesInitialState: IGlobalRolesState = {
  globalRoles: {},
}

export function globalRoles(state = globalRolesInitialState, action): IGlobalRolesState {
  switch (action.type) {
    case LOAD_GLOBAL_ROLES_SUCCESS:
      return {
        ...state,
        globalRoles: action.globalRoles,
      }
    default:
      return state
  }
}
