import {getApi, makeControllerCall} from '../../../services/apiFactory'
import {UIFilter, User} from 'workfront-objcodes'
import {IDataTableAPI} from '@wftypes/list'
import {FilterData} from '../../../../shared/toolbar/filters/useWrapperReducer'
import {redrockClient} from '../../../../../shared/L10n'

function transformResult(result) {
  const actionsSet = new Set()
  if (result.permissions && result.permissions.actions) {
    result.permissions.actions.forEach((action) => actionsSet.add(action))
  }
  return {
    id: result.ID,
    name: result.name,
    canEdit: actionsSet.has('EDIT'),
    canDelete: actionsSet.has('DELETE'),
    canShare: actionsSet.has('SHARE'),
  }
}

type TFilter = {
  item: Record<string, unknown>
  permissions: Record<string, unknown>
  canCustomize?: boolean
}

function loadSavedFilters(objCode) {
  return makeControllerCall<TFilter[]>('/availableFilters', 'GET', {
    objCode,
  }).then((results) =>
    results.map((result) =>
      transformResult({
        ...result.item,
        permissions: {
          ...result.permissions,
        },
      })
    )
  )
}

function loadSharedFilters(objCode) {
  const api = getApi()
  return api
    .namedQuery(UIFilter, 'sharedFiltersForObjCode', {objCode}, ['ID', 'name', 'permissions'])
    .then((results) => results.map(transformResult))
}
export default function loadFilters(listApi: IDataTableAPI): Promise<FilterData> {
  const objCode = listApi.metadata.getProperty('objCode')
  return Promise.all([loadSavedFilters(objCode), loadSharedFilters(objCode)]).then((results) => {
    const saved = results[0]
    const savedItemIDs = saved.map((item) => item.id)
    const shared = results[1].filter((item) => savedItemIDs.indexOf(item.id) === -1)
    return {
      saved,
      shared,
    }
  })
}

export function loadCurrentUserAccessLevel() {
  return getApi()
    .namedQuery(User, 'currentUser', {}, [
      'ID',
      'name',
      'accessLevel:accessLevelPermissions:coreAction',
      'accessLevel:accessLevelPermissions:forbiddenActions',
      'accessLevel:isAdmin',
      'accessLevel:accessLevelPermissions:objObjCode',
      'customer:customQuarters:*',
      'customer:isCustomQuarterEnabled',
    ])
    .then((data) => {
      const userData = data[0]
      const filterPermission = userData.accessLevel.accessLevelPermissions.find(
        (permission) => permission.objObjCode === UIFilter
      )
      const currentUserPermissions = {
        ID: userData.ID,
        name: userData.name,
        isAdmin: !!userData.accessLevel.isAdmin,
        coreAction: filterPermission ? filterPermission.coreAction : null,
        filterAccessLevelPermission: filterPermission
          ? {forbiddenActions: filterPermission.forbiddenActions}
          : null,
      }
      return {
        currentUserPermissions,
        customQuarters: userData.customer.isCustomQuarterEnabled
          ? userData.customer.customQuarters
          : [],
      }
    })
}

export const localizeWildcardsLabels = () =>
  redrockClient
    .load([
      'filters.user.wildcards.customForm',
      'filters.user.wildcards.me',
      'filters.user.wildcards.company',
      'filters.user.wildcards.Group',
      'filters.user.wildcards.groups',
      'filters.user.wildcards.team',
      'filters.user.wildcards.teams',
      'filters.user.wildcards.role',
      'filters.user.wildcards.roles',
      'filters.user.wildcards.access',
    ])
    .then(() => ({
      categoryID: redrockClient.getTextSync('filters.user.wildcards.customForm', 'My custom form'),
      me: redrockClient.getTextSync('filters.user.wildcards.me', 'Me (logged in user)'),
      companyID: redrockClient.getTextSync('filters.user.wildcards.company', 'My company'),
      homeGroupID: redrockClient.getTextSync('filters.user.wildcards.Group', 'My home group'),
      otherGroupIDs: redrockClient.getTextSync('filters.user.wildcards.groups', 'All my groups'),
      homeTeamID: redrockClient.getTextSync('filters.user.wildcards.team', 'My home team'),
      teamIDs: redrockClient.getTextSync('filters.user.wildcards.teams', 'All my teams'),
      roleID: redrockClient.getTextSync('filters.user.wildcards.role', 'My primary role'),
      roleIDs: redrockClient.getTextSync('filters.user.wildcards.roles', 'All my roles'),
      accessLevelID: redrockClient.getTextSync('filters.user.wildcards.access', 'My access level'),
    }))
