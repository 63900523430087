import {primary} from '@phoenix/all'
import {css} from 'emotion'

const cellClass = css`
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  padding: 0px 8px;
  height: 53px;
`

const borderClass = `
    border-left: 1px solid ${primary.gray(100)};
`

export const nameCellClass = css`
  ${cellClass};
  padding-left: 0;
  width: 293px;
  height: 53px;
`

export const nameCellClassSplitOff = css`
  ${cellClass};
  padding-left: 0;
  width: 230px;
  height: 53px;
`

export const availableRolesCellClass = css`
  ${cellClass};
  ${borderClass};
  width: 108px;
`

export const availableRolesCellClassSplitOff = css`
  ${cellClass};
  ${borderClass};
  width: 98px;
`

export const requiredRolesCellClass = css`
  ${cellClass};
  ${borderClass};
  width: 108px;
`

export const requiredRolesCellClassSplitOff = css`
  ${cellClass};
  ${borderClass};
  width: 98px;
`

export const utilizationCellClass = css`
  ${cellClass};
  ${borderClass};
  width: 98px;
`

export const hourlyRateCellClass = css`
  ${cellClass};
  ${borderClass};
  width: 108px;
  position: relative;
`

export const hourlyRateCellClassSplitOff = css`
  ${cellClass};
  ${borderClass};
  width: 114px;
  position: relative;
`
