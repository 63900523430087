import {createSelector} from 'reselect'
import {TimelineMode} from '../../../shared/enums'
import {calculateCellWidth} from '../../../shared/helpers'
import {ITimeline} from '../../../shared/models'
import {timelineSelector} from './timelineSelector'

export const timelineCellMonthWidthSelector = createSelector(
  timelineSelector,
  (timeline: ITimeline) => {
    return Math.floor(calculateCellWidth(timeline.containerWidth, TimelineMode.MONTHLY))
  }
)
