import moment, {Moment} from 'moment'
import * as React from 'react'
import {connect} from 'react-redux'
import {SortableContainer} from 'react-sortable-hoc'
import {ListOnItemsRenderedProps} from 'react-window'

import {ScrollSync} from '@wf-titan/dom-util'

import {
  changeTimelineContainerWidth,
  currentInitiativeSelector,
  findEarliestInitiativeIndex,
  firstImportedInitiativeIndexSelector,
  showConflictsSelector,
} from '../../../data'
import {changeTimelineMode, timelineSelector} from '../../../data/plan'
import {planEndDateSelector} from '../../../data/plan'
import {timelineWidthSelector} from '../../../data/plan'
import {todayLinePositionSelector} from '../../../data/plan'
import {
  reorderInitiatives,
  startMultipleInitiativeDragging,
  confirmMultipleInitiativeDragging,
} from '../../../data/scenario'
import {lastScenarioAfterCopySelector} from '../../../data/scenario'
import {reorderDragAndDrop} from '../../../data/viewOptions'
import {isEditSidebarOpenSelector} from '../../../data/viewOptions'
import {IScenario, ITimeline} from '../../../shared/models'
import {IInitiative} from '../../../shared/models/initiative'
import {ITimelineModeOption} from '../../../shared/models/timeline'
import {validateInitiatives} from '../../../shared/utilities'
import {InitiativeListHeaderComponent} from './Initiative/InitiativeListHeader'
import {InitiativeRowComponent} from './Initiative/InitiativeRowComponent'
import {ListRendererComponent} from './ListRendererComponent'
import {TodayLineComponent} from './Timeline/TodayLineComponent'
import {FloatingMenuComponent} from './FloatingMenuComponent'
import {DeleteInitiativeConfirmDialogComponent} from './DeleteInitiativeConfirmDialogComponent'
import {scrollToInitiativesSelector} from '../../../data/initiative/selectors/scrollToInitiativesSelector'
import {peopleCostOnConflictCalculationSelector} from '../../../data/plan/selectors/peopleCostOnConflictCalculationSelector'
import {selectedInitiativeCountSelector} from '../../../data/plan/selectors/selectedInitiativeCountSelector'
import {shrinkInitiativesSelector} from '../../../data/scenario/selectors/shrinkInitiativesSelector'
import {sidebarSizeSelector} from '../../../data/settings/selectors/sidebarSizeSelector'
import {SidebarResizeLineComponent} from './Timeline/SidebarResizeLineComponent'

type TScenarioListComponentProps = IScenarioListComponentOwnProps & IScenarioListComponentStateProps

export interface IScenarioListComponentOwnProps {
  initiatives: IInitiative[]
  height: number
  width: number
  initiativeRowHeight: number
  scrollLeft: number
  scrollToToday: () => void
  reorderInitiativesFunction: (
    initiatives: IInitiative[],
    usePeopleCostOnConflictCalculation: boolean
  ) => void
  reorderDragAndDropFunction: (isReordering?) => void
  timelineModeChangeFunction: (timelineOption: ITimelineModeOption) => void
  isEditSidebarOpen: boolean
  showConflicts: boolean
  currentScenario: IScenario
  scrollToInitiatives: IInitiative[]
  currentInitiative: IInitiative
  firstImportedInitiativeIndex: number | null
  usePeopleCostOnConflictCalculation: boolean
}

interface IScenarioListComponentStateProps {
  todayLinePosition: number
  timelineWidth: number
  endDate: Moment
  timeline: ITimeline
  selectedInitiativeCount: number
  shrinkInitiatives: string[]
  cleanListResetIndex: () => void
  confirmBulkDragDrop: (dropIndex: number, usePeopleCostOnConflictCalculation: boolean) => void
  timelineContainerWidthChangeFunction: (cellWidth: number) => void
  handleBulkDragDrop: (draggableInitiativeOrder: number) => void
  sidebarSize: number
}

interface IScenarioListComponentState {
  scrollLeft: number
  visibleStartIndex: number
  visibleStopIndex: number
}

const SortableList = SortableContainer(ListRendererComponent)

export class ScenarioList extends React.PureComponent<
  TScenarioListComponentProps,
  IScenarioListComponentState
> {
  reactWindowRef
  reactWindowInnerRef
  reactWindowHeaderRef
  scrollSync

  constructor(props: TScenarioListComponentProps) {
    super(props)

    this.reactWindowRef = React.createRef()
    this.reactWindowHeaderRef = React.createRef()
    this.reactWindowInnerRef = React.createRef()

    this.state = {
      scrollLeft: 0,
      visibleStartIndex: 0,
      visibleStopIndex: 0,
    }
  }

  componentDidUpdate(
    prevProps: Readonly<TScenarioListComponentProps>,
    prevState: Readonly<IScenarioListComponentState>
  ): void {
    if (this.props.timeline.mode !== prevProps.timeline.mode && prevState.scrollLeft > 0) {
      this.setState({
        scrollLeft: 0,
      })
    }

    if (!this.props.isEditSidebarOpen && !prevProps.showConflicts && this.props.showConflicts) {
      const firstConflictedInitiative = this.getFirstConflictedInitiative()

      if (firstConflictedInitiative) {
        const indexOfFirstConflictedInitiative = this.props.initiatives.indexOf(
          firstConflictedInitiative
        )

        this.scrollToFirstConflictedInitiative(indexOfFirstConflictedInitiative)
      }
    }

    if (this.props.currentScenario.id !== prevProps.currentScenario.id) {
      if (this.reactWindowInnerRef.current.parentElement.scrollTop > 0) {
        this.scrollToInitiative([0])
      }
    }

    if (
      this.props.firstImportedInitiativeIndex !== null &&
      this.props.firstImportedInitiativeIndex !== prevProps.firstImportedInitiativeIndex
    ) {
      this.scrollToInitiative([
        this.props.firstImportedInitiativeIndex > 0
          ? this.props.firstImportedInitiativeIndex - 1
          : 0,
      ])
    }

    if (
      this.props.scrollToInitiatives.length &&
      this.props.scrollToInitiatives !== prevProps.scrollToInitiatives
    ) {
      this.scrollToInitiative(
        this.props.scrollToInitiatives.map((init) =>
          this.props.initiatives.findIndex((scrollInit) => scrollInit.id === init.id)
        )
      )
    }

    if (
      this.props.width !== prevProps.width ||
      this.props.initiatives.length !== prevProps.initiatives.length ||
      this.props.sidebarSize !== prevProps.sidebarSize
    ) {
      this.updateContainerWidth()
    }

    if (this.props.shrinkInitiatives !== prevProps.shrinkInitiatives) {
      let resetAfterIndex = 0
      if (this.props.shrinkInitiatives.length) {
        resetAfterIndex = findEarliestInitiativeIndex(
          this.props.initiatives,
          this.props.shrinkInitiatives
        )
      }
      this.reactWindowRef.current.resetAfterIndex(resetAfterIndex, true)
    }
  }

  componentDidMount(): void {
    const scrollableContainerFromDom = document.querySelector('.scrollContent')!
    const elementsGetter = () => {
      return [
        {
          element: scrollableContainerFromDom,
          scrollPositionSetter: () => null,
        },
        {
          element: this.reactWindowHeaderRef.current,
          scrollPositionSetter: ({scrollLeft}) => {
            if (this.reactWindowHeaderRef.current.scrollLeft !== scrollLeft) {
              this.reactWindowHeaderRef.current.scrollLeft = scrollLeft
              this.setState({
                scrollLeft,
              })
            }
          },
        },
      ]
    }

    this.scrollSync = new ScrollSync(elementsGetter)
    this.scrollSync.attach()

    document.addEventListener('mousedown', this.onDocumentMouseDownHandler)

    if (this.reactWindowInnerRef.current) {
      this.updateContainerWidth()
    }
  }

  componentWillUnmount(): void {
    this.scrollSync.detach()

    document.removeEventListener('mousedown', this.onDocumentMouseDownHandler)
  }

  render() {
    const {height, initiatives, width, todayLinePosition, sidebarSize} = this.props

    const isStickyToLeft = todayLinePosition < this.state.scrollLeft
    const isStickyToRight = todayLinePosition + sidebarSize > this.state.scrollLeft + width

    return (
      <React.Fragment>
        <InitiativeListHeaderComponent
          innerRef={this.reactWindowHeaderRef}
          height={height}
          screenWidth={width}
          isScrollbarAtLeft={this.state.scrollLeft === 0}
          changeTimelineMode={this.changeTimelineMode}
        />

        <SidebarResizeLineComponent reactWindowHeight={height} />

        {this.props.endDate > moment() && (
          <TodayLineComponent
            leftPosition={this.state.scrollLeft}
            reactWindowHeight={height}
            isStickyToLeft={isStickyToLeft}
            scrollToPosition={this.scrollToPosition}
            isStickyToRight={isStickyToRight}
          />
        )}

        <div style={{position: 'relative', width: width}}>
          <DeleteInitiativeConfirmDialogComponent />

          <FloatingMenuComponent />

          <SortableList
            innerRef={this.reactWindowInnerRef}
            reactWindowRef={this.reactWindowRef}
            onItemsRendered={this.onItemsRendered}
            height={height}
            initiatives={initiatives}
            shrinkInitiatives={this.props.shrinkInitiatives}
            itemSize={this.props.initiativeRowHeight}
            width={width}
            lockAxis="y"
            lockToContainerEdges={true}
            helperClass="reordering-row"
            useDragHandle
            onSortStart={this.onSortStart}
            onSortEnd={this.onSortEnd}
            currentScenario={this.props.currentScenario}
            isScrollbarAtLeft={this.state.scrollLeft === 0}
          >
            {InitiativeRowComponent}
          </SortableList>
        </div>
      </React.Fragment>
    )
  }

  private updateContainerWidth = (): void => {
    this.props.timelineContainerWidthChangeFunction(
      this.reactWindowInnerRef.current.clientWidth - this.props.sidebarSize
    )
  }

  private onDocumentMouseDownHandler = ({target}) => {
    const validatedInitiatives = validateInitiatives(
      target,
      this.props.currentInitiative,
      this.props.currentScenario,
      this.props.isEditSidebarOpen
    )

    if (validatedInitiatives) {
      this.props.reorderInitiativesFunction(
        validatedInitiatives,
        this.props.usePeopleCostOnConflictCalculation
      )
    }
  }

  private onItemsRendered = (renderedProps: ListOnItemsRenderedProps): void => {
    const {visibleStartIndex, visibleStopIndex} = renderedProps
    this.setState({
      visibleStartIndex: visibleStartIndex + 1,
      visibleStopIndex: visibleStopIndex + 1,
    })
  }

  private getFirstConflictedInitiative = (): IInitiative | undefined => {
    return this.props.initiatives.find(
      (initiative: IInitiative) =>
        initiative.conflicts &&
        (initiative.conflicts.budgetConflicts || initiative.conflicts.roleConflicts.size)
    )
  }

  private isThereAConflictedInitiativeInVisibleArea = () => {
    const {visibleStartIndex, visibleStopIndex} = this.state
    return this.props.initiatives
      .slice(visibleStartIndex, visibleStopIndex)
      .some((initiative: IInitiative) => {
        return (
          initiative.conflicts &&
          (initiative.conflicts.budgetConflicts || initiative.conflicts.roleConflicts.size)
        )
      })
  }

  private scrollToFirstConflictedInitiative = (firstConflictedInitiativeIndex: number): void => {
    if (!this.isThereAConflictedInitiativeInVisibleArea()) {
      this.scrollToInitiative([firstConflictedInitiativeIndex])
    }
  }

  private scrollToPosition = (scrollPosition: null | number) => {
    const parentElement = this.reactWindowInnerRef.current!.offsetParent!
    parentElement.scrollLeft =
      scrollPosition !== null
        ? scrollPosition
        : this.props.todayLinePosition - parentElement.offsetWidth / 2 + this.props.sidebarSize
  }

  private prepareDragDropSelectionContainer(draggableInitiativeOrder) {
    if (this.props.selectedInitiativeCount > 1) {
      const initiativeInfoContainer = document.querySelector(
        '.reordering-row .initiative-info-container'
      )! as HTMLDivElement

      const initiativeRightContainer = document.querySelector(
        '.reordering-row .initiative-right-container'
      )! as HTMLDivElement

      const selectionInfoContainer = document.querySelector(
        '.reordering-row .drag-drop-selection-info'
      )! as HTMLDivElement

      initiativeInfoContainer.style.display = 'none'
      initiativeRightContainer.style.display = 'none'
      selectionInfoContainer.style.display = 'flex'

      this.props.handleBulkDragDrop(draggableInitiativeOrder)
    }
  }

  private onSortStart = ({index}) => {
    const reorderingRowElement = document.querySelector(
      '.reordering-row .initiative-right-container'
    )!
    reorderingRowElement.scrollLeft = this.reactWindowInnerRef.current.parentElement.scrollLeft

    this.prepareDragDropSelectionContainer(index)

    this.props.reorderDragAndDropFunction()
  }

  private onSortEnd = ({oldIndex, newIndex}) => {
    if (this.props.selectedInitiativeCount > 1) {
      this.props.confirmBulkDragDrop(newIndex - 1, this.props.usePeopleCostOnConflictCalculation)
    } else {
      const initiatives = [...this.props.initiatives]

      const reorderedInitiative = initiatives.splice(oldIndex - 1, 1)[0]
      initiatives.splice(newIndex - 1, 0, reorderedInitiative)
      this.props.reorderInitiativesFunction(
        initiatives,
        this.props.usePeopleCostOnConflictCalculation
      )
    }
    this.props.reorderDragAndDropFunction(false)
  }

  private scrollToInitiative = (initiativeIndexes: number[]) => {
    const minIndex = Math.min(...initiativeIndexes)
    const maxIndex = Math.max(...initiativeIndexes)
    const topBound = minIndex * this.props.initiativeRowHeight
    const bottomBound = (maxIndex + 1) * this.props.initiativeRowHeight

    const containerElement = this.reactWindowInnerRef.current
    const containerScrollTop = containerElement.offsetParent.scrollTop
    const containerHeight = containerElement.offsetParent.offsetHeight

    if (topBound < containerScrollTop) {
      this.reactWindowRef.current.scrollTo(topBound)
    } else if (bottomBound > containerScrollTop + containerHeight) {
      const spaceFromTop = topBound - containerScrollTop
      const spaceNeeded = bottomBound - (containerScrollTop + containerHeight)
      this.reactWindowRef.current.scrollTo(containerScrollTop + Math.min(spaceFromTop, spaceNeeded))
    }
  }

  private changeTimelineMode = (timelineOption) => {
    this.props.timelineModeChangeFunction(timelineOption)
    this.reactWindowHeaderRef.current.scrollLeft = 0
    this.reactWindowInnerRef.current.offsetParent.scrollLeft = 0
  }
}

const mapStateToProps = (state, ownProps) => ({
  timelineWidth: timelineWidthSelector(state),
  timeline: timelineSelector(state),
  scrollToInitiatives: scrollToInitiativesSelector(state),
  currentInitiative: currentInitiativeSelector(state),
  todayLinePosition: todayLinePositionSelector(state),
  endDate: planEndDateSelector(state),
  lastScenarioAfterCopy: lastScenarioAfterCopySelector(state),
  initiatives: ownProps.currentScenario.initiatives,
  isEditSidebarOpen: isEditSidebarOpenSelector(state),
  showConflicts: showConflictsSelector(state),
  firstImportedInitiativeIndex: firstImportedInitiativeIndexSelector(state),
  usePeopleCostOnConflictCalculation: peopleCostOnConflictCalculationSelector(state),
  selectedInitiativeCount: selectedInitiativeCountSelector(state),
  shrinkInitiatives: shrinkInitiativesSelector(state),
  sidebarSize: sidebarSizeSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  reorderInitiativesFunction: (initiatives, usePeopleCostOnConflictCalculation) =>
    dispatch(reorderInitiatives(initiatives, usePeopleCostOnConflictCalculation)),
  timelineModeChangeFunction: (timelineModeOption: ITimelineModeOption) =>
    dispatch(changeTimelineMode(timelineModeOption.value)),
  reorderDragAndDropFunction: (isReordering) => dispatch(reorderDragAndDrop(isReordering)),
  timelineContainerWidthChangeFunction: (containerWidth: number) =>
    dispatch(changeTimelineContainerWidth(containerWidth)),
  handleBulkDragDrop: (draggableInitiativeOrder) =>
    dispatch(startMultipleInitiativeDragging(draggableInitiativeOrder)),
  confirmBulkDragDrop: (dropIndex, usePeopleCostOnConflictCalculation) =>
    dispatch(confirmMultipleInitiativeDragging(dropIndex, usePeopleCostOnConflictCalculation)),
})

export const ScenarioListComponent = connect(mapStateToProps, mapDispatchToProps)(ScenarioList)
