import {primary} from '@phoenix/all'
import {css} from 'emotion'
import * as React from 'react'
import {ESP_FOOTER_HEIGHT} from '../../../shared/constants/Sizes'

const slidingPaneFooterClass = css`
  padding: 5px 15px;
  border-top: 1px solid ${primary.gray(200)};
  height: ${ESP_FOOTER_HEIGHT}px;
  display: flex;
  align-items: center;
`

export const SlidingPaneFooterComponent: React.FunctionComponent = React.memo((props) => {
  return <footer className={slidingPaneFooterClass}>{props.children}</footer>
})

SlidingPaneFooterComponent.displayName = 'SlidingPaneFooterComponent'
