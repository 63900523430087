import * as React from 'react'
import {connect} from 'react-redux'
import {currentInitiativeSelector} from '../../../../../data/initiative'
import {hoursForMeasurementsSelector} from '../../../../../data/plan/selectors/hoursForMeasurementsSelector'
import {roleConflictMonthIndexSelector} from '../../../../../data/viewOptions/selectors/roleConflictMonthIndexSelector'
import {IInitiative, IInitiativeRole} from '../../../../../shared/models'
import {CarouselProviderComponent} from '../carousel/CarouselProviderComponent'
import {PeopleListItemComponent} from './PeopleListItemComponent'
import {RoleMonthsCarouselComponent} from './RoleMonthsCarouselComponent'
import {Translate} from '../../../../shared/Translate'

interface IPeopleListProps {
  isNew: boolean
  initiativeRoles: IInitiativeRole[]
  currentInitiative: IInitiative
  roleConflictMonthIndex: number
  deleteRoleFunction: (id: string) => void
  changePeopleCountFunction: (role: IInitiativeRole) => void
  useHoursForMeasurements: boolean
}

export const PeopleList: React.FC<IPeopleListProps> = React.memo(
  ({
    initiativeRoles,
    isNew,
    deleteRoleFunction,
    currentInitiative,
    roleConflictMonthIndex,
    useHoursForMeasurements,
  }) => {
    const roleConflictsMap = {}
    if (currentInitiative.conflicts) {
      currentInitiative.conflicts.roleConflicts.forEach((c) => (roleConflictsMap[c.roleId] = c))
    }

    return (
      <CarouselProviderComponent
        distribution={initiativeRoles.length > 0 ? initiativeRoles[0].distribution : {}}
        conflictIndex={roleConflictMonthIndex}
      >
        {currentInitiative.people.roles.length > 0 && (
          <RoleMonthsCarouselComponent
            testID="role-months-carousel"
            className="role-months-carousel"
            distribution={initiativeRoles[0].distribution!}
            label={
              <Translate
                messageKey={useHoursForMeasurements ? 'job.roles.hour' : 'job.roles.fte'}
              />
            }
          />
        )}
        {initiativeRoles.map((role, index) => (
          <PeopleListItemComponent
            key={role.id}
            index={index}
            role={role}
            roleConflict={roleConflictsMap[role.id] || null}
            isNew={isNew}
            deleteRoleFunction={deleteRoleFunction}
            isFocused={index === 0 && isNew}
          />
        ))}
      </CarouselProviderComponent>
    )
  }
)

PeopleList.displayName = 'PeopleList'

const mapStateToProps = (state) => ({
  roleConflictMonthIndex: roleConflictMonthIndexSelector(state),
  currentInitiative: currentInitiativeSelector(state),
  initiativeRoles: currentInitiativeSelector(state)!.people.roles,
  useHoursForMeasurements: hoursForMeasurementsSelector(state),
})

export const PeopleListComponent = connect(mapStateToProps)(PeopleList)
