import {createSelector} from 'reselect'
import {IInitiative} from '../../../shared/models/initiative'
import {currentInitiativeSelector} from '../../initiative'
import {showConflictsSelector} from '../../scenario'
import {visibleConflictIdSelector} from './visibleConflictIdSelector'

export const visibleConflictSelector = createSelector(
  showConflictsSelector,
  currentInitiativeSelector,
  visibleConflictIdSelector,
  (
    showConflicts: boolean,
    currentInitiative: IInitiative | null,
    visibleConflictId: string | null
  ) => {
    if (!showConflicts) {
      return null
    } else {
      if (
        visibleConflictId === null ||
        !currentInitiative ||
        currentInitiative.conflicts === null
      ) {
        return null
      } else if (visibleConflictId === 'budget') {
        return currentInitiative.conflicts.budgetConflicts
      } else {
        const {roleConflicts} = currentInitiative.conflicts
        return roleConflicts.has(visibleConflictId) ? roleConflicts.get(visibleConflictId) : null
      }
    }
  }
)
