import React from 'react'
import CloseIcon from 'phoenix-icons/dist/CloseIcon.js'
import {Localization, LocalizationProvider} from '@wf-mfe/localize-react'
import PushPinListWrapper from './PushPinListWrapper'
import {closeButton, height100, panelCaption, panelHeader} from './shared-styles'
import getBuildUiItem from './getBuildUiItem'
import {FilterData, UseWrapperReducerReturnType} from './useWrapperReducer'
import useLoadData from './useLoadData'
import useHandleItemClick from './useHandleItemClick'
import {IDataTableAPI} from '@wftypes/list'
import {FilterChangeParams} from './types'
import {redrockClientFactory} from '../../../../shared/L10n'

const handleEditIconClick = undefined
const listMessageKeys = ['saved', 'sharedwithme']

interface FilterWrapperProps extends UseWrapperReducerReturnType {
  listApi: IDataTableAPI
  onClosePanel: React.MouseEventHandler<HTMLButtonElement>
  onFVGChange: (uiType: FilterChangeParams) => void
  loadFilters: (listApi: IDataTableAPI) => Promise<FilterData>
  currentFilterID?: string
}
function FilterWrapper({
  listApi,
  onClosePanel,
  onFVGChange,
  currentFilterID,
  data,
  isDataLoaded,
  setData,
  loadFilters,
}: FilterWrapperProps) {
  useLoadData(listApi, isDataLoaded, setData, loadFilters)

  const handleItemClick = useHandleItemClick(onFVGChange)

  return (
    <LocalizationProvider clientFactory={redrockClientFactory}>
      <div className={height100} data-testid="filter-panel">
        <div className={panelHeader}>
          <span className={panelCaption}>
            <Localization messageKey="uifilters" fallback="Filters" />
          </span>
          <Localization messageKey="action.close" fallback="Close">
            {(title) => (
              <button
                className={closeButton}
                data-testid="close-filter-panel-button"
                onClick={onClosePanel}
                title={title()}
              >
                <CloseIcon />
              </button>
            )}
          </Localization>
        </div>

        {isDataLoaded && (
          <Localization messageKeys={listMessageKeys}>
            {([saved, sharedwithme]) => {
              const buildUiItem = getBuildUiItem()

              const savedList = {
                header: {
                  text: saved('Saved'),
                },
                editIconClick: handleEditIconClick,
                isOpen: true,
                items: [] as unknown[],
              }
              const lists = [savedList]
              if (data.saved.length > 0) {
                savedList.items = data.saved.map(buildUiItem)
              }

              if (data.shared.length > 0) {
                lists.push({
                  header: {
                    text: sharedwithme('Shared with me'),
                  },
                  editIconClick: handleEditIconClick,
                  isOpen: true,
                  items: data.shared.map(buildUiItem),
                })
              }

              return (
                <PushPinListWrapper
                  listBunch={lists}
                  showActions={false}
                  itemClick={handleItemClick}
                  selectedItemID={currentFilterID || ''}
                />
              )
            }}
          </Localization>
        )}
      </div>
    </LocalizationProvider>
  )
}

export default React.memo(FilterWrapper)
