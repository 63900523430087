import {css, keyframes} from 'emotion'

const containerClass = css`
  position: relative;
`

const fadeClass = keyframes`
    0% {
        outline-width: 1px;
        outline-color: #dfe1e3;
        outline-style: solid;
        box-shadow: 0px 0px 0px rgba(90,81,81,0);
    }
    25% {
        box-shadow: 0px 0px 8px rgba(90,81,81,0.2);
    }
    50% {
        outline-color: transparent;
        outline-offset: 15px;
        box-shadow: 0px 0px 0px rgba(90,81,81,0);
    }
`

export const fadeWidgetBorderClass = css`
  ${containerClass};
  animation: ${fadeClass} 1.5s ease;
`
