import * as React from 'react'
import {connect} from 'react-redux'

import {Text, primary, secondary, Ellipse} from '@phoenix/all'

import styled, {css, cx} from 'react-emotion'
import {peopleCostOnConflictCalculationSelector} from '../../../../data/plan/selectors/peopleCostOnConflictCalculationSelector'
import {showConflictsSelector} from '../../../../data/scenario'
import {viewAccessSelector} from '../../../../data/settings'
import {
  getAllocatedAmount,
  getPreviouslyUsedAmount,
} from '../../../../shared/helpers/conflicts/DistributionsHelper'
import {IDestructuredRoleConflict, IPeriodRemainingData} from '../../../../shared/models/initiative'
import {IDistribution} from '../../../../shared/models/initiative/IDistribution'
import {formatNumber} from '../../../../shared/utilities'
import {CarouselItemComponent} from './carousel/CarouselItemComponent'
import {CarouselComponent} from './carousel/CarouselComponent'
import {carouselItemConflictedBorderStyles} from './roles/StyleHelper'

export interface IConflictDetailsDistributionComponentProps {
  distribution: IDistribution
  periodDetails: IDestructuredRoleConflict
  preCalculatedData: Map<string, IPeriodRemainingData>
  periodConflictKey: 'totalAmount' | 'missingAmount' | 'remainingAmount'
  testID: string
}

const numberCell = css`
  height: 32px;
  padding: 0 5px 0 12px;
  line-height: 32px;
`

const grayTextClass = css`
  color: ${primary.kyloRen()};
`

const redTextClass = css`
  color: ${secondary.captainMarvel()};
`

const bgLayerStyles = `
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
`

const bgRedClass = css`
  color: ${secondary.captainMarvel()} !important;
  position: relative;
  z-index: 1;
  
  &:after {
    ${bgLayerStyles}
    background-color: ${secondary.red(400)};
    opacity: 0.2;
  }
`

const numberClass = css`
  color: unset;
`

const CarouselItemStyled = styled('div')<{missingAmount: number}>`
  position: relative;
    
  &:after {
    ${bgLayerStyles}
    ${(props) =>
      props.missingAmount > 0 &&
      `
      background-color: ${primary.gray(200)};
      opacity: 0.5;
    `}
`

const ConflictDetailsDistribution: React.FC<IConflictDetailsDistributionComponentProps> = React.memo(
  ({distribution, periodDetails, periodConflictKey, preCalculatedData, testID}) => {
    const elementRefs = React.useRef({})

    const getColorsClasses = (missingAmount, periodConflictKey) => {
      if (missingAmount > 0 && periodConflictKey === 'missingAmount') {
        return {
          bgColor: bgRedClass,
          textColor: redTextClass,
        }
      }

      return {
        bgColor: '',
        textColor: grayTextClass,
      }
    }

    const getNumber = React.useCallback(
      (month: string) =>
        periodConflictKey !== 'remainingAmount'
          ? getAllocatedAmount(periodDetails, month, periodConflictKey)
          : getPreviouslyUsedAmount(preCalculatedData, month),
      [periodDetails, periodConflictKey]
    )

    return (
      <CarouselComponent testID={testID}>
        {distribution &&
          Object.keys(distribution).map((month) => {
            const missingAmount = getAllocatedAmount(periodDetails, month, 'missingAmount')
            const colorsClasses = getColorsClasses(missingAmount, periodConflictKey)

            const number = formatNumber(getNumber(month), 3, true, 6)

            return (
              <CarouselItemComponent
                className={missingAmount > 0 ? carouselItemConflictedBorderStyles : ''}
                key={month}
                testID={`${testID}-${periodConflictKey}-${month}`}
              >
                <CarouselItemStyled
                  missingAmount={missingAmount}
                  className="carousel-conflicted-item"
                >
                  <div
                    ref={(node) => (elementRefs.current[month] = node)}
                    className={`${cx(numberCell, colorsClasses.bgColor)} conflicted-number`}
                  >
                    <Ellipse>
                      <Text className={numberClass}>{number}</Text>
                    </Ellipse>
                  </div>
                </CarouselItemStyled>
              </CarouselItemComponent>
            )
          })}
      </CarouselComponent>
    )
  }
)

const mapStateToProps = (state) => ({
  isViewMode: viewAccessSelector(state),
  showConflicts: showConflictsSelector(state),
  usePeopleCostOnConflictCalculation: peopleCostOnConflictCalculationSelector(state),
})

ConflictDetailsDistribution.displayName = 'ConflictDetailsDistribution'

export const ConflictDetailsDistributionComponent = connect(
  mapStateToProps,
  null
)(ConflictDetailsDistribution)
