import {IInitiative} from '../models/initiative'
import {IScenario} from '../models/scenario'

export function isValidIntegerNumber(value: string): boolean {
  const regex = /^[0-9\b]+$/

  return regex.test(value)
}

export function validateAndReturnInputValue(value: string): number | null {
  if (value === '') {
    return null
  } else if (isValidIntegerNumber(value)) {
    return +value
  }

  return null
}

export function validateInitiatives(
  elementTarget: any,
  currentInitiative: IInitiative,
  currentScenario: IScenario,
  isEditSidebarOpen: boolean
) {
  if (
    !isEditSidebarOpen &&
    currentInitiative &&
    !elementTarget.classList.contains('current-initiative-name')
  ) {
    const initiatives = [...currentScenario!.initiatives]
    const indexOfCurrentInitiative = initiatives.findIndex(
      (scenarioInitiative: IInitiative) => scenarioInitiative.id === currentInitiative!.id
    )
    const initiative = currentScenario.initiatives[indexOfCurrentInitiative]
    const currentInitiativeName = initiative.name ? initiative.name : currentInitiative.name

    initiatives.splice(indexOfCurrentInitiative, 1, {
      ...currentInitiative,
      name: currentInitiativeName,
    })

    return initiatives
  }
}
