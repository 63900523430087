import {css} from 'emotion'
import React, {ReactElement} from 'react'
import {connect} from 'react-redux'

import {Button, primary, secondary, ConfirmDialog, Text} from '@phoenix/all'
import {
  changeCurrentInitiative,
  changeCurrentScenario,
  changeLastScenarioAfterCopy,
  copyScenarioAndNavigate,
  currentInitiativeSelector,
  currentPlanSelector,
  isEditSidebarOpenSelector,
  toggleEditSidebar,
  toggleSlideScenarioAnimation,
  updateScenario,
} from '../../../../data'

import SplitContext from '../../../../shared/context/SplitContext'
import {hasInitiativeChanges, track} from '../../../../shared/helpers'
import {IInitiative} from '../../../../shared/models'
import {IScenario} from '../../../../shared/models/scenario'
import {inlineCenterItemsClass} from '../StyleHelper'
import {ConflictsShowHideToggleComponent} from './ConflictsShowHideToggleComponent'
import {ScenarioDropdownComponent} from './ScenarioDropdownComponent'
import {peopleCostOnConflictCalculationSelector} from '../../../../data/plan/selectors/peopleCostOnConflictCalculationSelector'
import {Translate} from '../../../shared/Translate'
import {ConfirmationDialogHeaderComponent} from '../../../shared/ConfirmationDialogHeaderComponent'

interface IScenarioHeaderActionsProps {
  currentScenario: IScenario
  scenarioCount: number
  changeCurrentScenarioFunction: (
    scenario: IScenario | null,
    lastScenario: IScenario | null,
    usePeopleCostOnConflictCalculation: boolean
  ) => void
  toggleSlideScenarioAnimationFunction: (shouldUpdateScenario: boolean) => void
  updateScenarioFunction: (scenario: IScenario) => void
  addScenarioFunction: (
    scenario: IScenario,
    order: number,
    usePeopleCostOnConflictCalculation: boolean
  ) => void
  changeCurrentInitiativeFunction: (
    initiative: IInitiative | null,
    usePeopleCostOnConflictCalculation: boolean
  ) => void
  currentInitiative: IInitiative
  isEditSidebarOpen: boolean
  toggleEditSidebarFunction: (isOpen: boolean) => void
  changeLastScenarioAfterCopyFunction: (scenario: IScenario) => void
  usePeopleCostOnConflictCalculation: boolean
}

interface IScenarioHeaderActionsState {
  isConfirmDialogOpen: boolean
  selectedScenario: null | IScenario
  isSidebarConfirmDialogOpen: boolean
}

export interface IScenarioOptions {
  label: ReactElement
  value: IScenario
}

const actionsClass = css`
  ${inlineCenterItemsClass};
  font-size: 14px;
  margin-top: 9px;
`

const compareScenarioClass = css`
  color: ${primary.blue(400)};
  cursor: pointer;
  height: auto;
  padding: 0;
  border-radius: 3px;
`

const actionsItemClass = css`
  & + & {
    position: relative;
    margin-left: 10px;
    padding-left: 10px;

    @media (min-width: 1100px) {
      margin-left: 15px;
      padding-left: 15px;
    }

    &:before {
      background-color: ${primary.gray(300)};
      content: '';
      height: 15px;
      left: 0;
      position: absolute;
      width: 1px;
      top: 50%;
      margin-top: -7.5px;
    }
  }

  label {
    margin: 0;
  }

  .switch-label {
    margin-right: 0 !important;
    margin-left: 8 !important;
  }
`

const statusContainerClass = css`
  position: relative;
  margin-left: 8px;

  @media (min-width: 1100px) {
    margin-left: 8px;
  }
`

const statusClass = css`
  color: ${secondary.green()};
  font-weight: 600;
  text-transform: uppercase;
`

export class ScenarioHeaderActions extends React.PureComponent<
  IScenarioHeaderActionsProps,
  IScenarioHeaderActionsState
> {
  isCopyButtonClicked = false
  static contextType = SplitContext

  constructor(props: IScenarioHeaderActionsProps) {
    super(props)

    this.state = {
      isConfirmDialogOpen: false,
      selectedScenario: null,
      isSidebarConfirmDialogOpen: false,
    }
  }

  render() {
    return (
      <div className={actionsClass} data-testid="header-actions">
        <div className={actionsItemClass}>
          {this.context.SCENARIO_PUBLISH ? (
            <ConflictsShowHideToggleComponent />
          ) : (
            <ScenarioDropdownComponent
              currentScenario={this.props.currentScenario}
              handleScenarioChange={this.handleScenarioChange}
              copyButtonOnClickHandler={this.copyButtonOnClickHandler}
            />
          )}
        </div>
        <div className={actionsItemClass}>
          <Button
            text
            data-testid="compare-scenario-button"
            className={compareScenarioClass}
            onClick={this.onCompareClicked}
          >
            <Translate messageKey={'sp.scenario.compare'} />
          </Button>
        </div>
        <div className={actionsItemClass}>
          {!this.context.SCENARIO_PUBLISH ? (
            <ConflictsShowHideToggleComponent />
          ) : (
            <ScenarioDropdownComponent
              currentScenario={this.props.currentScenario}
              handleScenarioChange={this.handleScenarioChange}
              copyButtonOnClickHandler={this.copyButtonOnClickHandler}
            />
          )}
        </div>
        <div className={statusContainerClass}>
          {this.context.SCENARIO_PUBLISH && this.props.currentScenario.isPublished && (
            <Text.Small className={statusClass}>
              <Translate messageKey={'scenario.published'} />
            </Text.Small>
          )}
        </div>

        {this.state.isConfirmDialogOpen && (
          <ConfirmDialog
            testID="scenario-change-confirm-dialog"
            header={<ConfirmationDialogHeaderComponent messageKey={'scenario.unsaved'} />}
            confirmText={<Translate messageKey={'form.button.discard'} />}
            denyText={<Translate messageKey={'form.button.cancel'} />}
            onConfirmClick={this.dialogOnConfirmHandler}
            onDenyClick={this.dialogOnDenyHandler}
          >
            <Text>
              <Translate messageKey={'scenario.unsaved.message'} />
            </Text>
          </ConfirmDialog>
        )}
        {this.state.isSidebarConfirmDialogOpen && (
          <ConfirmDialog
            testID="scenario-change-confirm-dialog"
            header={<ConfirmationDialogHeaderComponent messageKey={'scenario.unsaved'} />}
            confirmText={<Translate messageKey={'form.button.discard'} />}
            denyText={<Translate messageKey={'form.button.cancel'} />}
            onConfirmClick={this.navigateToComparePage}
            onDenyClick={this.stayOnThePage}
          >
            <Text>
              <Translate messageKey={'scenario.unsaved.message'} />
            </Text>
          </ConfirmDialog>
        )}
      </div>
    )
  }

  private navigateToComparePage = () => {
    this.props.changeCurrentScenarioFunction(
      null,
      this.props.currentScenario,
      this.props.usePeopleCostOnConflictCalculation
    )
    this.props.updateScenarioFunction(this.props.currentScenario)
    this.props.toggleEditSidebarFunction(false)
    this.props.changeCurrentInitiativeFunction(null, this.props.usePeopleCostOnConflictCalculation)
  }

  private stayOnThePage = () => {
    this.setState({
      isSidebarConfirmDialogOpen: false,
    })
  }

  private onCompareClicked = () => {
    if (hasInitiativeChanges(this.props.currentScenario, this.props.currentInitiative)) {
      this.setState({
        isSidebarConfirmDialogOpen: true,
      })
    } else {
      this.navigateToComparePage()
    }
  }

  private hasChangesInSidebar = (): boolean => {
    return hasInitiativeChanges(this.props.currentScenario, this.props.currentInitiative)
  }

  private handleScenarioChange = ({value}: IScenarioOptions): void => {
    if (this.isCopyButtonClicked || value.id === this.props.currentScenario.id) {
      this.isCopyButtonClicked = false
      return
    }

    this.setState({
      selectedScenario: value,
    })

    if (this.props.isEditSidebarOpen) {
      if (this.hasChangesInSidebar()) {
        this.setState({isConfirmDialogOpen: true})
      } else {
        this.changeScenario(value)
      }
    } else {
      this.props.updateScenarioFunction(this.props.currentScenario)
      this.props.changeCurrentScenarioFunction(
        value,
        null,
        this.props.usePeopleCostOnConflictCalculation
      )
    }

    this.isCopyButtonClicked = false
  }

  private dialogOnConfirmHandler = (): void => {
    this.changeScenario(this.state.selectedScenario!)
    this.props.changeCurrentInitiativeFunction(null, this.props.usePeopleCostOnConflictCalculation)
    this.setState({
      isConfirmDialogOpen: false,
      selectedScenario: null,
    })
  }

  private dialogOnDenyHandler = (): void => {
    this.setState({
      isConfirmDialogOpen: false,
    })
  }

  private changeScenario = (scenario: IScenario): void => {
    this.props.toggleEditSidebarFunction(false)
    this.props.changeCurrentInitiativeFunction(null, this.props.usePeopleCostOnConflictCalculation)

    if (this.isCopyButtonClicked) {
      this.addNewScenario(this.state.selectedScenario!)
    } else {
      this.props.changeCurrentScenarioFunction(
        scenario,
        null,
        this.props.usePeopleCostOnConflictCalculation
      )
      this.props.updateScenarioFunction(this.props.currentScenario)
    }
  }

  private copyButtonOnClickHandler = (event, scenario: IScenario): void => {
    this.isCopyButtonClicked = true

    const newScenario =
      scenario.id === this.props.currentScenario.id ? this.props.currentScenario : scenario // ensure that the new added initiatives will be here

    this.setState({
      selectedScenario: newScenario,
    })

    if (this.props.isEditSidebarOpen) {
      if (this.hasChangesInSidebar()) {
        this.setState({isConfirmDialogOpen: true})
        return
      } else {
        this.props.toggleEditSidebarFunction(false)
        this.addNewScenario(newScenario)
      }
    } else {
      this.addNewScenario(newScenario)
    }
  }

  private addNewScenario = (newScenario: IScenario): void => {
    track('Copy scenario from dropdown')
    this.props.changeLastScenarioAfterCopyFunction(this.props.currentScenario)
    this.props.updateScenarioFunction(this.props.currentScenario)
    this.props.addScenarioFunction(
      newScenario,
      this.props.scenarioCount,
      this.props.usePeopleCostOnConflictCalculation
    )
    this.props.toggleSlideScenarioAnimationFunction(true)
  }
}

const mapStateToProps = (state) => ({
  currentInitiative: currentInitiativeSelector(state),
  isEditSidebarOpen: isEditSidebarOpenSelector(state),
  scenarioCount: currentPlanSelector(state)!.scenarios.length,
  usePeopleCostOnConflictCalculation: peopleCostOnConflictCalculationSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  updateScenarioFunction: (scenario) => dispatch(updateScenario(scenario)),
  addScenarioFunction: (scenario, order, usePeopleCostOnConflictCalculation) =>
    dispatch(copyScenarioAndNavigate(scenario, order, usePeopleCostOnConflictCalculation)),
  toggleEditSidebarFunction: (isOpen) => dispatch(toggleEditSidebar(isOpen)),
  changeCurrentInitiativeFunction: (initiative, usePeopleCostOnConflictCalculation) =>
    dispatch(changeCurrentInitiative(initiative, null, usePeopleCostOnConflictCalculation)),
  changeCurrentScenarioFunction: (scenario, lastScenario, usePeopleCostOnConflictCalculation) =>
    dispatch(changeCurrentScenario(scenario, lastScenario, usePeopleCostOnConflictCalculation)),
  toggleSlideScenarioAnimationFunction: (shouldSlideScenario) =>
    dispatch(toggleSlideScenarioAnimation(shouldSlideScenario)),
  changeLastScenarioAfterCopyFunction: (scenario) =>
    dispatch(changeLastScenarioAfterCopy(scenario)),
})

export const ScenarioHeaderActionsComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScenarioHeaderActions)
