import React, {useEffect, useState} from 'react'
import {IToolbarWidgetProps} from '@wf-mfe/lists'
import {IconComponent} from 'phoenix-icons/dist'
import ToolbarDropDown from '../components/ToolbarDropDown'

export interface IOption {
  id: string
  value: string
  label: string
}

export interface GetApiParameter {
  setOptions: React.Dispatch<React.SetStateAction<IOption[]>>
  setSelectedOption: React.Dispatch<React.SetStateAction<IOption>>
}

export interface DropDownWidgetOwnProps {
  icon: IconComponent
  dataTestId: string
  getApi: (param: GetApiParameter) => void
  handleChange: (filter: IOption) => void
}

/**
 * @param props
 * @param {Component} props.icon   A component to render as an icon
 *
 * @param {boolean} props.isCheckable   If true, icon will have persistent 'pressed' state
 *
 * @param {string} props.dataTestId   An attribute to use as identifier in tests
 *
 * @param {function} props.handleChange   A function which will be called with changed filter
 * @param {function} props.getApi   A function which will be called with widget public api
 */
function DropDownWidget(props: IToolbarWidgetProps & DropDownWidgetOwnProps) {
  const {icon, dataTestId, getApi, handleChange} = props

  const [options, setOptions] = useState([] as IOption[])
  const [selectedOption, setSelectedOption] = useState({} as IOption)

  useEffect(() => {
    getApi &&
      getApi({
        setOptions,
        setSelectedOption,
      })
  }, [getApi])

  const wrapperProps = {
    dataTestId: dataTestId,
    onChange: handleChange,
    options,
    selectedOption,
    icon,
  }

  return <ToolbarDropDown {...wrapperProps} />
}

export default React.memo(DropDownWidget)
