import {IPlan} from '../models/IPlan'
import {serializePlan} from '../helpers/PlanHelper'
import {fetchData} from './api/ApiService'
import {IPlanSettings} from '../models'

export class PlanService {
  postPlan(plan: IPlan) {
    return fetchData(`/plans?fields=**`, {
      method: 'POST',
      data: serializePlan(plan),
    })
  }

  putPlan(plan: IPlan) {
    return fetchData<IPlan>(`/plans/${plan.id}?fields=**`, {
      method: 'PUT',
      data: serializePlan(plan),
    })
  }

  getPlan(planId: string): Promise<IPlan> {
    return fetchData<IPlan>(`/plans/${planId}?fields=**`, {
      showToast: false,
    })
  }

  deletePlan(plan: IPlan) {
    return fetchData(`/plans/${plan.id}`, {
      method: 'DELETE',
    })
  }

  putPlanSettings(planId, planSetting: IPlanSettings) {
    return fetchData(`/plans/${planId}/settings`, {
      method: 'PUT',
      data: planSetting,
    })
  }
}
