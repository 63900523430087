export const importMessageKeys = [
  'filters.ui.label',
  'lists.import.portfolio.priority',
  'lists.import.name',
  'lists.import.portfolio',
  'lists.import.program',
  'lists.import.owner',
  'lists.import.status',
  'lists.import.percentcomp',
  'lists.import.plannedstartdate.abbr',
  'lists.import.plannedcompletiondate.abbr',
  'project.plural',
  'project',
]
