import {css} from 'emotion'
import * as React from 'react'
import DownCaratSmallIcon from 'phoenix-icons/dist/DownCaratSmallIcon.js'
import UpCaratSmallIcon from 'phoenix-icons/dist/UpCaratSmallIcon.js'
import {primary} from '@phoenix/all'
import {resetButtonDefaultStyles} from '../../../shared'
import {SectionTitleComponent} from './SectionTitleComponent'

interface ISectionComponentProps {
  icon: React.ReactElement
  titleMessageKey: string
  value: string | null | JSX.Element
  testId: string
  className?: string
  hasError?: boolean
  errorMessage?: string
  tooltipContainerClass?: string
}

interface ISectionComponentPropsState {
  isCollapsed: boolean
}

const toggleButtonClass = css`
  ${resetButtonDefaultStyles};
  border-radius: 3px;
  position: absolute;
  top: 50%;
  margin-top: -8px;
  right: 0;
`

const titleContainerClass = css`
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  margin: 0;
  padding: 16px 0;
  position: relative;
  text-transform: uppercase;
`

const containerClass = css`
  border-top: 1px solid ${primary.gray(200)};

  &:first-child {
    position: relative;
    z-index: 100;
  }
`

const contentClass = css`
  padding-bottom: 16px;
`

export class SectionComponent extends React.PureComponent<
  ISectionComponentProps,
  ISectionComponentPropsState
> {
  constructor(props: ISectionComponentProps) {
    super(props)
    this.state = {
      isCollapsed: true,
    }
  }

  render() {
    const {
      children,
      titleMessageKey,
      hasError,
      icon,
      value,
      className,
      testId,
      errorMessage,
      tooltipContainerClass,
    } = this.props

    const additionalBodyClass = className && ''
    const {isCollapsed} = this.state

    return (
      <section className={containerClass}>
        <div className={titleContainerClass} data-testid={`collapse-${testId}`}>
          <SectionTitleComponent
            icon={icon}
            titleMessageKey={titleMessageKey}
            value={value}
            hasError={hasError!}
            testId={testId}
            errorMessage={errorMessage}
            tooltipContainerClass={tooltipContainerClass}
          />
          <button className={toggleButtonClass} onClick={this.toggleCollapse}>
            {this.state.isCollapsed ? <UpCaratSmallIcon /> : <DownCaratSmallIcon />}
          </button>
        </div>

        {isCollapsed && (
          <div className={`${contentClass} ${additionalBodyClass}`} data-testid={testId}>
            {children}
          </div>
        )}
      </section>
    )
  }

  private toggleCollapse = () => {
    this.setState((prevState) => ({
      isCollapsed: !prevState.isCollapsed,
    }))
  }
}
