import {css} from 'emotion'
import * as React from 'react'
import {connect} from 'react-redux'

import ExpensesIcon from 'phoenix-icons/dist/ExpensesIcon.js'
import {primary, secondary, Text} from '@phoenix/all'
import {currencySelector} from '../../../../../data/plan'
import {currentScenarioBudgetCostsSelector} from '../../../../../data/scenario/selectors/currentScenarioBudgetCostsSelector'
import {currentScenarioBudgetTotalSelector} from '../../../../../data/scenario/selectors/currentScenarioBudgetTotalSelector'
import {currentScenarioBudgetUtilizationSelector} from '../../../../../data/scenario/selectors/currentScenarioBudgetUtilizationSelector'
import {
  isBudgetWidgetExpandedSelector,
  isEditSidebarOpenSelector,
  toggleBudgetWidget,
} from '../../../../../data/viewOptions'

import {formatCurrency, formatNumber, formatPercentage} from '../../../../../shared/utilities'
import {inlineCenterItemsClass} from '../../StyleHelper'
import {HeaderDescriptionComponent} from '../HeaderDescriptionComponent'
import {HeaderWidgetWrapperComponent} from '../HeaderWidgetWrapperComponent'
import {TooltipComponent} from '../TooltipComponent'
import {Translate} from '../../../../shared/Translate'

interface IHeaderBudgetWidgetComponent {
  budget: number | null
  costs: number | null
  utilization: number | null
  isWidgetExpanded: boolean
  currency: string
  toggleWidgetFunction: (isWidgetExpanded) => void
  isSidebarOpen: boolean
}

const dataContainerClass = css`
  padding: 0px 8px;
  flex: 1 0 0;
`

const labelClass = css`
  text-transform: uppercase;
  color: ${primary.gray(700)};
  font-weight: 600;
  letter-spacing: 0.5px;
`

const exceededUtilizationClass = css`
  color: ${secondary.red()};
`

export const HeaderBudgetWidget: React.FunctionComponent<IHeaderBudgetWidgetComponent> = (
  props: IHeaderBudgetWidgetComponent
) => {
  const {isWidgetExpanded, toggleWidgetFunction, budget, costs, utilization} = props

  return (
    <HeaderWidgetWrapperComponent
      isWidgetExpanded={isWidgetExpanded}
      toggleWidgetFunction={toggleWidgetFunction}
      type="budget"
      icon={<ExpensesIcon />}
    >
      <HeaderDescriptionComponent
        messageKey={'financial'}
        args={[props.currency]}
        testID="budget"
      />
      <div className={inlineCenterItemsClass}>
        <div className={dataContainerClass}>
          <div>
            {budget !== null ? (
              <TooltipComponent
                size="large"
                value={formatNumber(budget)!}
                tooltipValue={formatCurrency(budget, props.currency, false)!}
                testId={'budget-widget-total'}
              />
            ) : (
              <Text.Large>{'0'}</Text.Large>
            )}
          </div>
          <div>
            <Text.Small className={labelClass}>
              <Translate messageKey={'scenario.widget.budget'} />
            </Text.Small>
          </div>
        </div>
        <div className={dataContainerClass}>
          <div>
            {costs !== null ? (
              <TooltipComponent
                size="large"
                value={formatNumber(costs)!}
                tooltipValue={formatCurrency(costs, props.currency, false)!}
                testId={'budget-widget-costs'}
              />
            ) : (
              <Text.Large>{'0'}</Text.Large>
            )}
          </div>
          <div>
            <Text.Small className={labelClass}>
              <Translate messageKey={'header.widget.budget.costs'} />
            </Text.Small>
          </div>
        </div>
        <div className={dataContainerClass}>
          <div>
            {utilization !== null ? (
              <TooltipComponent
                size="large"
                value={formatPercentage(utilization, true)}
                tooltipValue={formatPercentage(utilization, false)}
                testId={'budget-widget-utilization'}
                className={utilization > 100 ? exceededUtilizationClass : ''}
              />
            ) : (
              <Text.Large>{'0'}</Text.Large>
            )}
          </div>
          <div>
            <Text.Small className={labelClass}>
              <Translate messageKey={'scenario.widget.util'} />
            </Text.Small>
          </div>
        </div>
      </div>
    </HeaderWidgetWrapperComponent>
  )
}

const mapStateToProps = (state) => ({
  budget: currentScenarioBudgetTotalSelector(state),
  costs: currentScenarioBudgetCostsSelector(state),
  utilization: currentScenarioBudgetUtilizationSelector(state),
  isWidgetExpanded: isBudgetWidgetExpandedSelector(state),
  currency: currencySelector(state),
  isSidebarOpen: isEditSidebarOpenSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  toggleWidgetFunction: (isExpanded) => dispatch(toggleBudgetWidget(isExpanded)),
})

export const HeaderBudgetWidgetComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderBudgetWidget)
