import {useLocalization} from '@wf-mfe/localize-react'
import * as React from 'react'
import {AccessDropdownComponent} from '@workfront/sp-sharing-dialog'
import {AccessMode} from '../../shared/enums'
import {css} from 'react-emotion'
import ApproveIcon from 'phoenix-icons/dist/ApproveIcon.js'
import {Text, A, Textarea, Button, primary, secondary} from '@phoenix/all'
import {injectGlobal} from 'emotion'
import {RequestAccessImage} from './RequestAccessImage'
import {fetchData} from '../../shared/services/api/ApiService'
import {EspPlan} from 'workfront-objcodes'
import {RequestAccessOwnerComponent} from './RequestAccessOwnerComponent'

interface IRequestAccessComponentProps {
  espAccess: AccessMode
  planID: string
}

const containerClass = css`
  display: flex;
  justify-content: center;
`

const headerMessageClass = css`
  font-size: 24px;
  line-height: 29px;
  color: ${primary.gray(800)};
`

const requestAccessMessageClass = css`
  line-height: 17px;
  color: ${primary.gray(800)};
`

const accessDropdownContainerClass = css`
  display: flex;
  align-items: center;
  margin: 30px 0 0 0;
`

const leaveCommentContainerClass = css`
  margin: 36px 0 0 0;

  label#request-access-comments-label {
    font-size: 14px;
  }
`

const requestAccessOwnerClass = css`
  position: relative;
  top: 3px;
  margin: 0 0 0 16px;
`

const requestSubmittedContainerClass = css`
  display: flex;
  margin: 114px 0 0 0;
`

const requestAccessAgainClass = css`
  margin: 24px 0 0 0;
`

const approveIconContainerClass = css`
  margin: 7px 18px 0 0;
`

injectGlobal`
  [data-testid="access-list-dropdown-button-options"] {
    max-width: 120px;
    margin-left: 10px;
  }
  [data-testid="access-list-dropdown-button-options"] li[role="menuitem"] > div {
    width: auto;
  }
`

const REQUEST_ACCESS_ENDPOINT = '/internal/requestAccess'

const submitRequestAccess = ({planID, selectedAccess, requestAccessComments}) => {
  const formData = JSON.stringify({
    requestee: '', // This is required field in backend so, passing empty string
    requestedAction: selectedAccess,
    message: requestAccessComments,
  })
  return fetchData(
    `${REQUEST_ACCESS_ENDPOINT}?objCode=${EspPlan}&objID=${planID}&form=${formData}`,
    {
      method: 'POST',
      omitBaseUrl: true,
    }
  )
}

export const RequestAccessComponent: React.FC<IRequestAccessComponentProps> = ({
  espAccess,
  planID,
}) => {
  const messages = {
    insufficientAccessMessage: useLocalization(
      'sp.messages.plan.noaccess.view',
      'You do not have sufficient access to view this plan.'
    )[0],
    requestAccessButtonMessage: useLocalization('messages.plan.requestaccess', 'Request access')[0],
    requestAccessHintMessage: useLocalization(
      'messages.plan.requestaccess.hint',
      'Request access to view or manage it.'
    )[0],
    requestAccessToMessage: useLocalization(
      'messages.plan.requestaccess.to',
      'Request access to'
    )[0],
    requestAccessFromMessage: useLocalization(
      'sp.messages.plan.requestaccess.from',
      'this plan from'
    )[0],
    leaveCommentMessage: useLocalization(
      'messages.plan.requestaccess.leavecomment',
      'Leave comment'
    )[0],
    viewAccessMessage: useLocalization('form.button.view', 'View')[0],
    manageAccessMessage: useLocalization('form.button.manage', 'Manage')[0],
    noAccessMessage: useLocalization(
      'sp.messages.plan.noaccess.edit',
      "The administrator restricted the user's access to manage plans"
    )[0],
    requestAccessSubmittedMessage: useLocalization(
      'messages.plan.requestaccess.submitted',
      'Your request for access has been submitted.'
    )[0],
    requestAccessSubmittedEmailMessage: useLocalization(
      'messages.plan.requestaccess.submitted.confirmation',
      'A confirmation email will be sent to you when you are granted access.'
    )[0],
    requestAccessAgain: useLocalization(
      'messages.plan.requestaccess.again',
      'Request Access Again'
    )[0],
  }

  const [selectedAccess, setSelectedAccess] = React.useState(AccessMode.view)

  const [requestAccessComments, setRequestAccessComments] = React.useState('')

  const [isSendingRequest, setIsSendingRequest] = React.useState(false)
  const [requestAccessSubmitted, setRequestAccessSubmitted] = React.useState(false)

  const allAccessOptions = React.useMemo(
    () =>
      [AccessMode.view, AccessMode.edit].map((type) => ({
        disabled: type === AccessMode.edit && espAccess !== type,
        id: type,
        testID: `request-${type}-access`,
        label: type === AccessMode.view ? messages.viewAccessMessage : messages.manageAccessMessage,
      })),
    [espAccess, messages.viewAccessMessage, messages.manageAccessMessage]
  )

  const onDropdownChange = React.useCallback(
    (option) => {
      if (option.id !== selectedAccess) {
        setSelectedAccess(option.id)
      }
    },
    [selectedAccess]
  )

  const selectedOptions = React.useMemo(() => {
    const selectedOptionIndex = selectedAccess === AccessMode.view ? 0 : 1
    return [allAccessOptions[selectedOptionIndex]]
  }, [selectedAccess, allAccessOptions])

  const getNoAccessTestId = React.useCallback((option) => {
    return `user-has-no-${option.id}-access`
  }, [])

  const requestAccessCommentChangeHandler = React.useCallback((e) => {
    setRequestAccessComments(e.target.value)
  }, [])

  const handleRequestAccess = React.useCallback(() => {
    setIsSendingRequest(true)
    submitRequestAccess({
      planID,
      selectedAccess,
      requestAccessComments,
    })
      .then(() => {
        setRequestAccessSubmitted(true)
      })
      .finally(() => {
        setIsSendingRequest(false)
      })
  }, [planID, selectedAccess, requestAccessComments])

  const openRequestAccessScreen = React.useCallback(() => {
    setRequestAccessSubmitted(false)
    setRequestAccessComments('')
    setSelectedAccess(AccessMode.view)
  }, [])

  return (
    <div className={containerClass}>
      {requestAccessSubmitted ? (
        <div>
          <div className={requestSubmittedContainerClass}>
            <div className={approveIconContainerClass}>
              {/* TODO: contribute an icon with this size to the Phoenix Design System. */}
              <ApproveIcon width={48} height={48} color={secondary.green(400)} />
            </div>
            <div>
              <Text className={headerMessageClass} title={messages.requestAccessSubmittedMessage}>
                {messages.requestAccessSubmittedMessage}
              </Text>
              <div>
                <Text
                  className={headerMessageClass}
                  title={messages.requestAccessSubmittedEmailMessage}
                >
                  {messages.requestAccessSubmittedEmailMessage}
                </Text>
              </div>
              <div className={requestAccessAgainClass}>
                <A onClick={openRequestAccessScreen} data-testid="request-access-again">
                  {messages.requestAccessAgain}
                </A>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className={containerClass}>
            <RequestAccessImage />
          </div>
          <div>
            <Text className={headerMessageClass} title={messages.insufficientAccessMessage}>
              {messages.insufficientAccessMessage}
            </Text>
            <div>
              <Text className={headerMessageClass} title={messages.requestAccessHintMessage}>
                {messages.requestAccessHintMessage}
              </Text>
            </div>
          </div>
          <div className={accessDropdownContainerClass}>
            <Text className={requestAccessMessageClass}>{messages.requestAccessToMessage}</Text>
            <AccessDropdownComponent
              isDisabled={false}
              testID="access-list-dropdown"
              optionContainerAlignment="left"
              selectedOptions={selectedOptions}
              onChange={onDropdownChange}
              allOptions={allAccessOptions}
              noAccessMessage={messages.noAccessMessage}
              getNoAccessTestId={getNoAccessTestId}
            />
            <Text className={requestAccessMessageClass}>{messages.requestAccessFromMessage}</Text>
            <div className={requestAccessOwnerClass}>
              <RequestAccessOwnerComponent planID={planID} />
            </div>
          </div>
          <div className={leaveCommentContainerClass}>
            <Textarea
              name="request-access-comments"
              label={messages.leaveCommentMessage}
              testID="request-access-comments"
              value={requestAccessComments}
              onChange={requestAccessCommentChangeHandler}
            />
            <Button
              testID="request-access-button"
              disabled={isSendingRequest}
              onClick={handleRequestAccess}
              primary
            >
              {messages.requestAccessButtonMessage}
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
