import {useLocalization} from '@wf-mfe/localize-react'
import React from 'react'
import ErrorFilledSmallIcon from 'phoenix-icons/dist/ErrorFilledSmallIcon.js'
import {css} from 'react-emotion'
import {primary} from '@phoenix/all'
import {IInitiative} from '../../../../../shared/models'
import {PopperComponent} from '../../../../shared'
import {TooltipContentComponent} from '../../../../shared'

const conflictIconClass = css`
  position: absolute;
  top: -6px;
  left: -6px;
  line-height: 0;
  cursor: pointer;
  border-radius: 3px;

  svg {
    position: relative;
    z-index: 2;
  }

  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 10%;
    width: 80%;
    height: 80%;
    margin-top: -40%;
    border-radius: 50%;
    background-color: ${primary.white()};
  }
`

interface IConflictTooltipComponentProps {
  initiative: IInitiative
  onClick: any
  onKeyPress: any
}

interface IMessages {
  hasBudgetConflictMessage: string
  hasJobRoleConflictMessage: string
  hasBothRoleAndBudgetConflictMessage: string
}

// eslint-disable-next-line react/display-name
const ErrorIcon = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(
  (props, ref) => (
    <div
      ref={ref}
      onClick={props.onClick}
      onKeyPress={props.onKeyPress}
      className={`${conflictIconClass} ${props.className}`}
      role="button"
      tabIndex={0}
    >
      <ErrorFilledSmallIcon data-testid={'initiative-error-icon'} width={14} height={14} />
    </div>
  )
)

const errorIconClass = css`
  :hover {
    cursor: pointer;
  }
`

export const ConflictTooltipComponent: React.FC<IConflictTooltipComponentProps> = ({
  initiative,
  onClick,
  onKeyPress,
}) => {
  const messages: IMessages = {
    hasBudgetConflictMessage: useLocalization('conflict.budget', 'Show budget conflict details')[0],
    hasJobRoleConflictMessage: useLocalization(
      'conflict.job.role',
      'Show job role conflict details'
    )[0],
    hasBothRoleAndBudgetConflictMessage: useLocalization(
      'conflict.budget.and.role',
      'Show job role and budget conflict details'
    )[0],
  }

  const tooltipText = React.useMemo<string>(() => {
    const {conflicts} = initiative

    const hasBudgetConflict = conflicts!.budgetConflicts !== null
    const hasJobRoleConflict = conflicts!.roleConflicts.size > 0

    let message = ''

    if (hasBudgetConflict && hasJobRoleConflict) {
      message = messages.hasBothRoleAndBudgetConflictMessage
    } else if (hasBudgetConflict) {
      message = messages.hasBudgetConflictMessage
    } else {
      message = messages.hasJobRoleConflictMessage
    }

    return message
  }, [
    initiative,
    messages.hasBudgetConflictMessage,
    messages.hasJobRoleConflictMessage,
    messages.hasBothRoleAndBudgetConflictMessage,
  ])

  return (
    <PopperComponent
      reference={<ErrorIcon className={errorIconClass} onClick={onClick} onKeyPress={onKeyPress} />}
      modifiers={{
        preventOverflow: {
          boundariesElement: 'window',
        },
      }}
      content={<TooltipContentComponent text={tooltipText} />}
      isInsidePortal={true}
      showOnOver={true}
      placement="top"
    />
  )
}
