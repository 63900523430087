import {connect} from 'react-redux'
import * as React from 'react'
import {Select} from '@phoenix/all'
import {globalRolesSelector} from '../../../../../data/globalRoles'
import {viewAccessSelector} from '../../../../../data/settings'
import {IRole} from '../../../../../shared/models'
import {IGlobalRoles} from '../../../../../shared/models/IGlobalRoles'
import {forEach} from 'lodash'
import {translate} from '../../../../../shared/utilities/TranslateHelper'

interface IJobRoleSelectComponentProps {
  scenarioRolesIds: string[]
  globalRoles: IGlobalRoles
  addRole: (role: IRole) => void
  testID: string
}

export const JobRoleSelect: React.FC<IJobRoleSelectComponentProps> = React.memo(
  ({globalRoles, scenarioRolesIds, addRole, testID}) => {
    const getAvailableRoles = (globalRoles: IGlobalRoles, scenarioRolesIds: string[]) => {
      const availableRoles: IRole[] = []

      forEach(globalRoles, (globalRole, globalRoleId) => {
        if (!scenarioRolesIds.find((roleId) => globalRoleId === roleId)) {
          availableRoles.push({
            ...globalRole,
            id: globalRoleId,
          })
        }
      })

      return availableRoles
    }

    const availableRoles = getAvailableRoles(globalRoles, scenarioRolesIds)

    return (
      <Select
        search={true}
        name="supply-roles"
        options={availableRoles}
        placeholder={translate('form.start.typing.role')}
        onChange={addRole}
        textNoResults={translate('global.no.result')}
        optionsAlignment="left"
        optionsMinWidth={323}
        testID={testID}
        className="header-people-roles-select"
      />
    )
  }
)

JobRoleSelect.displayName = 'JobRoleSelect'

const mapStateToProps = (state) => ({
  globalRoles: globalRolesSelector(state),
  isViewMode: viewAccessSelector(state),
})

export const JobRoleSelectComponent = connect(mapStateToProps)(JobRoleSelect)
