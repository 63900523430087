import * as React from 'react'
import {Routes, useNavigate, useParams, Route} from 'react-router-dom'
import {PageContentSlot} from '@wf-mfe/layout'
import {Phoenix} from '@phoenix/all'
import {useIsTreatmentEnabled} from '@wf-mfe/toggles'

import {PlanWrapperComponent} from './view/Plan/PlanWrapperComponent'
import {PlanListWrapperComponent} from './view/PlanList'
import {RoleService} from './shared/services/RoleService'

const ScenarioPlannerWrapper: React.FC = ({children}) => {
  return (
    <PageContentSlot>
      <Phoenix injectContainers={false}>
        <Routes>
          <Route path="*" element={children} />
        </Routes>
      </Phoenix>
    </PageContentSlot>
  )
}

export const ScenarioPlanner = () => {
  return (
    <ScenarioPlannerWrapper>
      <PlanListWrapperComponent />
    </ScenarioPlannerWrapper>
  )
}

export const ScenarioPlan = () => {
  return (
    <ScenarioPlannerWrapper>
      <PlanWrapperWrapper />
    </ScenarioPlannerWrapper>
  )
}

const roleService = new RoleService()

const PlanWrapperWrapper = () => {
  const navigate = useNavigate()
  const {planID = ''} = useParams<{planID: string}>()
  const isNewPlan = planID === 'new'
  const navigateToList = React.useCallback(
    (planId?, scenarioId?) => {
      if (isNewPlan && planId && scenarioId) {
        navigate(`/plan/${planId}#${scenarioId}`, {replace: true})
      } else if (planId && typeof planId === 'string') {
        navigate(`/plan/${planId}`)
      } else {
        navigate(`/plans`)
      }
    },
    [isNewPlan, navigate]
  )

  const toggles = {
    FILTER_SP_PLANS_LIST: useIsTreatmentEnabled('new-filter-sp-plans-list'),
  }

  const loadRoles = () => roleService.searchRoles('')
  return (
    <PlanWrapperComponent
      toggles={toggles}
      planId={isNewPlan ? null : planID}
      rolesLoadFunction={loadRoles}
      navigateToListFunction={navigateToList}
      onSuccessfulSaveFunction={navigateToList}
    />
  )
}
