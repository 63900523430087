import {css} from 'emotion'
import * as React from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import {VariableSizeListProps, VariableSizeList as List, VariableSizeList} from 'react-window'
import {RoleDistributionContext} from '../../../../../../shared/context/RoleDistributionContext'
import {HEADER_HEIGHT, ROW_HEIGHT} from './constans'
import {RoleDistributionTableHeaderComponent} from './RoleDistributionTableHeaderComponent'
import {RoleDistributionTableRowComponent} from './RoleDistributionTableRowComponent'

interface IStickyListProps extends VariableSizeListProps {
  stickyIndices: number[]
  roleIds: string[]
  listRef: React.Ref<VariableSizeList>
}

const StickyListContext = React.createContext<{
  stickyIndices: number[]
}>(undefined!)

const stickyIndices = [0]

const ItemWrapper = ({data, index, style}) => {
  const {ItemRenderer, stickyIndices, roleIds} = data

  if (stickyIndices && stickyIndices.includes(index)) {
    return null
  }

  const roleId = roleIds[index - stickyIndices.length]

  return <ItemRenderer index={index} style={style} data={roleId} key={`renderer-${roleId}`} />
}

const innerElementType = React.forwardRef<HTMLDivElement>(({children, ...rest}, ref) => (
  <StickyListContext.Consumer>
    {({stickyIndices}) => (
      <div
        ref={ref}
        {...rest}
        className={css`
          min-height: 100%;
        `}
      >
        {stickyIndices.map((index) => {
          if (index === 0) {
            return <RoleDistributionTableHeaderComponent />
          }
        })}

        {children}
      </div>
    )}
  </StickyListContext.Consumer>
))

innerElementType.displayName = 'innerElementType'

const ListWrapper: React.FC<IStickyListProps> = ({
  children,
  stickyIndices,
  roleIds,
  width,
  listRef,
  ...rest
}) => (
  <StickyListContext.Provider value={{stickyIndices}}>
    <List
      ref={listRef}
      itemData={{ItemRenderer: children, stickyIndices, roleIds}}
      width={width}
      {...rest}
      className="scrollContainer"
    >
      {ItemWrapper}
    </List>
  </StickyListContext.Provider>
)

ListWrapper.displayName = 'StickyList'

export const RoleDistributionTableComponent = React.memo(
  React.forwardRef<VariableSizeList>((props, ref) => {
    const {roleIds} = React.useContext(RoleDistributionContext)

    const rowHeight = React.useCallback((index: number): number => {
      return index === 0 ? HEADER_HEIGHT : ROW_HEIGHT
    }, [])

    return (
      <AutoSizer>
        {({width, height}) => (
          <ListWrapper
            listRef={ref}
            height={height}
            innerElementType={innerElementType}
            itemCount={roleIds.length + stickyIndices.length}
            itemSize={rowHeight}
            stickyIndices={stickyIndices}
            roleIds={roleIds}
            width={width}
            overscanCount={0}
          >
            {RoleDistributionTableRowComponent}
          </ListWrapper>
        )}
      </AutoSizer>
    )
  })
)

RoleDistributionTableComponent.displayName = 'RoleDistributionTableComponent'
