import {css} from 'emotion'
import * as React from 'react'
import {Manager, Popper, Reference} from 'react-popper'
import {connect} from 'react-redux'
import {
  isBudgetWidgetExpandedSelector,
  shouldAnimateBudgetWidgetSelector,
  toggleBudgetWidget,
  toggleBudgetWidgetAnimation,
} from '../../../../../data/viewOptions'
import {fadeWidgetBorderClass} from '../AnimationHelper'
import {ExceededBudgetWarningComponent} from './ExceededBudgetWarningComponent'
import {HeaderBudgetExpandedWidgetComponent} from './HeaderBudgetExpandedWidgetComponent'
import {HeaderBudgetWidgetComponent} from './HeaderBudgetWidgetComponent'

export interface IHeaderBudgetWidgetContainerComponentProps {
  isWidgetExpanded: boolean
  shouldAnimateBudgetWidget: boolean
  collapseWidgetFunction: () => void
  toggleBudgetWidgetAnimationFunction: (shouldAnimateBudgetWidget: boolean) => void
}

const containerClass = css`
  position: relative;
`

const expandedWidgetContainer = css`
  position: absolute;
  margin-top: 2px;
  left: 0px;
  z-index: 1;
`

export class HeaderBudgetWidgetContainer extends React.Component<
  IHeaderBudgetWidgetContainerComponentProps
> {
  containerRef

  constructor(props: IHeaderBudgetWidgetContainerComponentProps) {
    super(props)
    this.containerRef = React.createRef()
  }

  componentDidMount(): void {
    document.addEventListener('mousedown', this.collapseWidget, true)
  }

  componentWillUnmount(): void {
    document.removeEventListener('mousedown', this.collapseWidget)
  }

  componentDidUpdate(prevProps: Readonly<IHeaderBudgetWidgetContainerComponentProps>): void {
    if (prevProps.shouldAnimateBudgetWidget !== this.props.shouldAnimateBudgetWidget) {
      setTimeout(() => {
        this.props.toggleBudgetWidgetAnimationFunction(false)
      }, 2000)
    }
  }

  render() {
    return (
      <div
        ref={(node) => (this.containerRef = node)}
        className={this.props.shouldAnimateBudgetWidget ? fadeWidgetBorderClass : containerClass}
      >
        {!this.props.isWidgetExpanded && <ExceededBudgetWarningComponent />}
        <Manager>
          <Reference>{() => <HeaderBudgetWidgetComponent />}</Reference>
          {this.props.isWidgetExpanded && (
            <Popper placement="bottom-start">
              {() => (
                <div className={expandedWidgetContainer} data-testid="expanded-budget-widget">
                  <HeaderBudgetExpandedWidgetComponent />
                </div>
              )}
            </Popper>
          )}
        </Manager>
      </div>
    )
  }

  private collapseWidget = ({target}) => {
    if (this.props.isWidgetExpanded) {
      const canSetState =
        !this.containerRef.contains(target) && target.closest('#dialog-container') === null

      if (canSetState) {
        setTimeout(() => {
          this.props.collapseWidgetFunction()
        })
      }
    }
  }
}

const mapStateToProps = (state) => ({
  isWidgetExpanded: isBudgetWidgetExpandedSelector(state),
  shouldAnimateBudgetWidget: shouldAnimateBudgetWidgetSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  collapseWidgetFunction: () => dispatch(toggleBudgetWidget(false)),
  toggleBudgetWidgetAnimationFunction: (shouldAnimateBudgetWidget) =>
    dispatch(toggleBudgetWidgetAnimation(shouldAnimateBudgetWidget)),
})

export const HeaderBudgetWidgetContainerComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderBudgetWidgetContainer)
