import cloneDeep from 'lodash/cloneDeep'
import {
  getInitiativeWithCalculatedFields,
  getInitiativeWithRecalculatedConflicts,
  getPreCalculatedDataForCurrentInitiative,
} from '../../shared/helpers'
import {
  CHANGE_CURRENT_INITIATIVE,
  CHANGE_PRE_CALCULATED_DATA,
  SCROLL_TO_INITIATIVES,
  UPDATE_CURRENT_INITIATIVE,
} from './actions'
import {IInitiativeState} from './IInitiativeState'

const scenarioInitialState: IInitiativeState = {
  scrollToInitiatives: [],
  currentInitiative: null,
  preCalculatedData: null,
}

export function initiative(state = scenarioInitialState, action): IInitiativeState {
  switch (action.type) {
    case SCROLL_TO_INITIATIVES: {
      return {
        ...state,
        scrollToInitiatives: action.initiatives,
      }
    }
    case CHANGE_CURRENT_INITIATIVE: {
      const preCalculatedData = action.currentScenario
        ? getPreCalculatedDataForCurrentInitiative(
            action.currentInitiative,
            action.currentScenario.initiatives,
            action.currentScenario.people.roles,
            action.currentScenario.budgets && action.currentScenario.budgets.distribution,
            action.usePeopleCostOnConflictCalculation,
            action.hasFinancialAccess
          )
        : null

      return {
        ...state,
        currentInitiative: cloneDeep(action.currentInitiative),
        preCalculatedData,
      }
    }
    case UPDATE_CURRENT_INITIATIVE: {
      const changedInitiative = {
        ...state.currentInitiative,
        ...action.changes,
      }

      const currentInitiative = action.showConflicts
        ? getInitiativeWithRecalculatedConflicts(
            getInitiativeWithCalculatedFields(
              changedInitiative,
              action.usePeopleCostOnConflictCalculation,
              action.hasFinancialAccess,
              action.useHoursForMeasurements
            ),
            state.preCalculatedData!,
            action.usePeopleCostOnConflictCalculation,
            action.hasFinancialAccess
          )
        : getInitiativeWithCalculatedFields(
            changedInitiative,
            action.usePeopleCostOnConflictCalculation,
            action.hasFinancialAccess,
            action.useHoursForMeasurements
          )

      return {
        ...state,
        currentInitiative,
      }
    }
    case CHANGE_PRE_CALCULATED_DATA: {
      const currentInitiative =
        state.currentInitiative !== null
          ? action.preCalculatedData !== null
            ? getInitiativeWithRecalculatedConflicts(
                state.currentInitiative,
                action.preCalculatedData!,
                action.usePeopleCostOnConflictCalculation,
                action.hasFinancialAccess
              )
            : {...state.currentInitiative, conflicts: null}
          : null

      return {
        ...state,
        currentInitiative,
        preCalculatedData: action.preCalculatedData,
      }
    }
    default:
      return state
  }
}
