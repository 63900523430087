import {IInitiative} from '../../models/initiative'
import {calculateDifference} from '../../utilities'

export function processNetValueAfterInitiativeUpdate(
  netValue: number | null,
  oldNetValue: number | null,
  newNetValue: number | null
): number | null {
  if (newNetValue === 0 && oldNetValue === netValue) {
    return 0
  } else {
    const netValueDiff = calculateDifference(newNetValue, oldNetValue)
    return netValue! + netValueDiff!
  }
}

export function processNetValueAfterInitiativeCopy(
  netValue: number | null,
  initiativeNetValue: number | null
): number | null {
  if (netValue && initiativeNetValue) return netValue + initiativeNetValue

  return netValue || initiativeNetValue
}

export function processNetValueAfterInitiativeDelete(
  netValue: number | null,
  initiativeNetValue: number | null
): number | null {
  return initiativeNetValue === 0 ? netValue : netValue! - initiativeNetValue!
}

export function calculateNetValueTotal(initiatives: IInitiative[]): number | null {
  // here can enter only when have netValue
  const initiativesWithNetValue = initiatives.filter(
    (initiative) => initiative.netValue!.calculatedNetValue !== null
  )
  if (initiativesWithNetValue.length > 0) {
    return initiatives.reduce((sum, initiative) => {
      return sum + initiative.netValue!.calculatedNetValue!
    }, 0)
  } else {
    return 0
  }
}
