interface IState {
  plan: {
    selectedInitiatives: {
      [initiativeID: string]: boolean
    }
  }
}

export const selectedInitiativeCountSelector = (state: IState) =>
  Object.keys(state.plan.selectedInitiatives).length
