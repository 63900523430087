export const UNAUTHORIZED_STATUS_CODE = 403
export const UNAUTHENTICATED_STATUS_CODE = 401
export const NO_CONTENT_STATUS_CODE = 204

export const UNAUTHORIZED_ERROR_MESSAGE = 'unauthorized'
export const UNAUTHENTICATED_ERROR_MESSAGE = 'unauthenticated'
export const UNKNOWN_FAILURE_ERROR_MESSAGE = 'unknown_failure'

export enum AccessMode {
  view = 'VIEW',
  edit = 'EDIT',
  noAccess = 'NO_ACCESS',
}
