import {SettingService} from '../../shared/services'
import {LOAD_SETTINGS_FAILURE, LOAD_SETTINGS_SUCCESS, SET_SIDEBAR_SIZE} from './actions'
import {processPermissions} from './SettingsHelper'

const settingService = new SettingService()

function loadSettingsFail(error) {
  return {
    type: LOAD_SETTINGS_FAILURE,
    error,
  }
}

function loadSettingsSuccess(settings) {
  return {
    type: LOAD_SETTINGS_SUCCESS,
    settings,
  }
}

export function loadSettings() {
  return function (dispatch) {
    return settingService.getSettings().then(
      (settings) => {
        dispatch(loadSettingsSuccess(settings))
      },
      (error) => dispatch(loadSettingsFail(error))
    )
  }
}

export function loadPermissions(objId) {
  return function (dispatch) {
    return settingService.getPermissions(objId).then(
      (permissions) => {
        const processedPermission = processPermissions(permissions)
        dispatch(loadSettingsSuccess({permissions: processedPermission}))
        return processedPermission
      },
      (error) => {
        dispatch(loadSettingsFail(error))
        return error
      }
    )
  }
}

export function setSidebarSize(size: number) {
  return {
    type: SET_SIDEBAR_SIZE,
    sidebarSize: size,
  }
}
