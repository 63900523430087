import {encodeAsFormData, wfetch} from '@wf-mfe/api'

export class RoleService {
  FIELDS = ['ID', 'name', 'costPerHour']
  OBJCODE = 'ROLE'
  MAX_RESULT_COUNT = 2000
  CICONTAINS = 'cicontains'
  CICONTAINSALL = 'cicontainsall'
  LITERAL_STRING_REGEX = /^"(.*)"$/
  api

  searchRoles(searchTerm: string) {
    const searchQuery = this.getRoleQuery(searchTerm)

    return wfetch(`/attask/api-internal/${this.OBJCODE}/search`, {
      method: 'POST',
      body: encodeAsFormData({
        fields: this.FIELDS,
        filters: searchQuery,
        method: 'GET',
      }),
    })
  }

  getRoleQuery(searchTerm) {
    const literalStringSearchMatch = searchTerm.match(this.LITERAL_STRING_REGEX)
    const filter = {
      name: literalStringSearchMatch ? literalStringSearchMatch[1] : searchTerm,
      name_Mod: literalStringSearchMatch ? this.CICONTAINS : this.CICONTAINSALL,
      name_Sort: 'asc',
      isActive: true,
      $$LIMIT: this.MAX_RESULT_COUNT,
    }

    const excludedValuesFilter = {}

    return {
      ...filter,
      ...excludedValuesFilter,
    }
  }
}
