import React from 'react'
import {css} from 'react-emotion'
import {IconComponent} from '@phoenix/all'

const menuItemContainerClass = css`
  display: flex;
  align-items: center;
`

const iconContainerClass = css`
  width: 14px;
  height: 14px;
  margin-right: 9px;
`

const menuItemTextClass = css`
  line-height: 14px;
`

interface IMenuItemContentProps {
  Icon: IconComponent
  text: string | JSX.Element
}

export const MenuItemContent: React.FC<IMenuItemContentProps> = ({Icon, text}) => (
  <div className={menuItemContainerClass}>
    <div className={iconContainerClass}>
      <Icon width={14} height={14} />
    </div>
    <div className={menuItemTextClass}>{text}</div>
  </div>
)
