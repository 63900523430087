import {IDestructuredRoleConflict, IPeriodRemainingData} from '../../models/initiative'

export const getPreviouslyUsedAmount = (
  periodDetails: Map<string, IPeriodRemainingData>,
  month: string
) => {
  return Math.abs(
    periodDetails.get(month)
      ? periodDetails.get(month)!.totalAmount - periodDetails.get(month)!.remainingAmount
      : 0
  )
}

export const getAllocatedAmount = (
  periodDetails: IDestructuredRoleConflict,
  month: string,
  key: string
) => {
  return periodDetails[month] ? periodDetails[month][key] : 0
}
