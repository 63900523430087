import React from 'react'
import {css, cx} from 'react-emotion'
import {primary} from '@phoenix/all'

const containerDefaultClass = css`
  background: ${primary.white()};
  box-shadow: 0px 0px 8px rgba(33, 38, 47, 0.2);
  border-radius: 4px;
`

interface IInfoTooltipContentComponentProps {
  containerClass?: string
}

export const InfoTooltipContentComponent: React.FC<IInfoTooltipContentComponentProps> = ({
  children,
  containerClass,
}) => <div className={cx(containerDefaultClass, containerClass)}>{children}</div>
