import * as React from 'react'

export interface ISplitContext {
  FILTER_SP_PLANS_LIST: boolean | null
}

export const defaultValue: ISplitContext = {
  FILTER_SP_PLANS_LIST: null,
}

const SplitContext = React.createContext(defaultValue)

export default SplitContext
