import React, {useCallback, useEffect, useRef, useState} from 'react'
import CloseSmallIcon from 'phoenix-icons/dist/CloseSmallIcon.js'
import SearchIcon from 'phoenix-icons/dist/SearchIcon.js'
import {getQuickFilterShortcut, isQuickFilterShortcutPressed} from './quickFilterShortcut'
import ToolbarButton from '../../../../shared/toolbar/components/ToolbarButton'
import {IDataTableAPI} from '@wftypes/list'
import {closeIconStyles, inputStyles, inputWrapper, posr} from './styles'

interface QuickFilterProps {
  listApi: IDataTableAPI
  value: string
  isOpen: boolean
  handleOpenStateChanged: (isOpen: boolean) => void
  handleChange: React.ChangeEventHandler<HTMLInputElement>
  handleValueCleared: () => void
  placeholder: string
}
export default function QuickFilter(props: QuickFilterProps): JSX.Element {
  const {
    listApi,
    value,
    isOpen,
    handleOpenStateChanged,
    handleChange,
    handleValueCleared,
    placeholder,
  } = props

  const [shouldFocus, setShouldFocus] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const handleQuickFilterIconClick = useCallback(() => {
    handleOpenStateChanged(!isOpen)
    if (isOpen) {
      // clear input value when we close quick filter
      handleValueCleared()
    } else {
      // focus input when we open quick filter
      setShouldFocus(true)
    }
  }, [isOpen, handleOpenStateChanged, handleValueCleared])

  const handleClearIconClick = useCallback(() => {
    inputRef.current!.focus()
    handleValueCleared()
  }, [handleValueCleared])

  const handleKeyDown = useCallback(
    (e) => {
      if (e.keyCode === 27) {
        handleClearIconClick()
        e.stopPropagation()
      }
    },
    [handleClearIconClick]
  )

  useEffect(() => {
    if (isOpen && shouldFocus) {
      inputRef.current!.focus()
      setShouldFocus(false)
    }
  }, [shouldFocus, isOpen])

  useEffect(() => {
    return listApi.keyboard.registerKey(isQuickFilterShortcutPressed, (evt) => {
      if (isOpen) {
        inputRef.current!.focus()
      } else {
        handleOpenStateChanged(true)
        setShouldFocus(true)
      }
      evt.preventDefault()
    })
  }, [listApi, isOpen, handleOpenStateChanged])

  const tooltipLabel = placeholder + '  ' + getQuickFilterShortcut()
  return (
    <>
      <span className={posr}>
        <ToolbarButton
          tooltipLabel={tooltipLabel}
          icon={SearchIcon}
          onClick={handleQuickFilterIconClick}
        />
      </span>
      {isOpen && (
        <span className={inputWrapper}>
          <input
            ref={inputRef}
            title={tooltipLabel}
            value={value}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            className={inputStyles}
            placeholder={tooltipLabel}
          />
          {value !== '' && (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
            <span className={closeIconStyles} onClick={handleClearIconClick}>
              <CloseSmallIcon />
            </span>
          )}
        </span>
      )}
    </>
  )
}
