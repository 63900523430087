import * as React from 'react'
import {connect} from 'react-redux'
import {timelineModeSelector} from '../../../../data'

import {IPeriod} from '../../../../shared/models'
import {TimelineCellComponent} from './TimelineCellComponent'
import {InfoTooltipContentComponent} from '../../../shared/InfoTooltipContentComponent'
import {MonthResourceSummaryComponent} from '../MonthResourceSummaryComponent'
import {TimelineMode} from '../../../../shared/enums'
import {PopperComponent} from '../../../shared'
import {clearOldHighlightedColumnIndex, setHighlightedColumnIndex} from '../../../../data'
import {highlightColumnSelector} from '../../../../data/scenario/selectors/highlightColumnSelector'
import {highlightedColumnIndexSelector} from '../../../../data/scenario/selectors/highlightedColumnIndexSelector'

interface ITimelineHeaderComponentProps {
  periods: IPeriod[]
  cellWidth: number
  timelineMode: number
  highlightColumn: boolean
  highlightedColumnIndex: number | null
  setHighlightedColumnIndex: (index: number) => void
  clearOldHighlightedColumnIndex: (index: number) => void
}

class TimelineHeader extends React.Component<ITimelineHeaderComponentProps> {
  resolveIconRef
  oldResolveIconRef

  render() {
    const {cellWidth, periods, highlightColumn, highlightedColumnIndex} = this.props
    let previousCellCount = 0
    const timelineCells: JSX.Element[] = []
    for (let i = 0; i < periods.length; i++) {
      const timelineCellContent = (
        <TimelineCellComponent
          key={periods[i].label || periods[i].startDate.format()}
          mode={this.props.timelineMode}
          index={i}
          lineY1={10}
          lineY2={28}
          // The background color should NOT be transparent so that the mouse enter/leave events are properly fired
          defaultFill="white"
          translateX={previousCellCount * cellWidth}
          text={periods[i].label}
          width={cellWidth * periods[i].monthCount}
          highlight={highlightColumn && highlightedColumnIndex === i}
          hideLeftLine={
            highlightColumn && (highlightedColumnIndex === i || highlightedColumnIndex === i - 1)
          }
          showBorders
        />
      )
      let timelineCell
      if (this.props.timelineMode === TimelineMode.MONTHLY) {
        timelineCell = (
          <PopperComponent
            key={periods[i].label || periods[i].startDate.format()}
            placement="bottom"
            keepPopupVisible={true}
            useTopIndent={false}
            content={
              <InfoTooltipContentComponent>
                <MonthResourceSummaryComponent
                  periodTitle={periods[i].label}
                  startDate={periods[i].startDate.clone().startOf('month')}
                  endDate={periods[i].startDate.clone().endOf('month')}
                />
              </InfoTooltipContentComponent>
            }
            showOnOver
            reference={timelineCellContent}
            onOpen={(e) => this.onOpenHandler(e, periods[i])}
            onClose={(e) => this.onCloseHandler(e, periods[i])}
            isInSvg={true}
            openDelay={300}
          />
        )
      } else {
        timelineCell = timelineCellContent
      }
      if (highlightColumn && highlightedColumnIndex === i) {
        timelineCells.push(timelineCell)
      } else {
        timelineCells.unshift(timelineCell)
      }
      previousCellCount += periods[i].monthCount
    }
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={previousCellCount * cellWidth}
        height="100%"
        data-testid="timeline-header"
      >
        <defs>
          <filter id="hoverShadow" x="-10" y="0" width="110" height="100%">
            <feOffset result="offOut" in="SourceAlpha" dx="0" dy="0" />
            <feColorMatrix
              result="matrixOut"
              in="offOut"
              type="matrix"
              values="0.18823529 0 0 0 0 0 0.21568627 0 0 0 0 0.26666667 0 0 0 0 0 0 0.2 0"
            />
            <feGaussianBlur result="blurOut" in="matrixOut" stdDeviation="8" />
            <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
          </filter>
        </defs>
        {timelineCells}
      </svg>
    )
  }

  private onOpenHandler = (e: React.MouseEvent, period: IPeriod) => {
    const i = this.props.periods.findIndex((p) => p.label === period.label)
    const startDate = period.startDate.format('YYYY-MM')

    this.props.setHighlightedColumnIndex(i)
    const targetClosestDiv = e.target && (e.target as Element).closest('div')
    if (this.resolveIconRef) {
      this.resolveIconRef.removeAttribute('style')
      this.oldResolveIconRef = this.resolveIconRef
    } else {
      this.oldResolveIconRef = null
    }

    this.resolveIconRef =
      targetClosestDiv && targetClosestDiv.querySelector(`.resolve-icon-${startDate}`)

    if (this.resolveIconRef) {
      setTimeout(() => {
        this.resolveIconRef.style.opacity = 0
      })
    }
  }

  private onCloseHandler = (e, period: IPeriod) => {
    const i = this.props.periods.findIndex((p) => p.label === period.label)

    this.props.clearOldHighlightedColumnIndex(i)

    if (this.oldResolveIconRef) {
      this.oldResolveIconRef.style.opacity = 1
    }

    if (this.resolveIconRef) {
      this.resolveIconRef.style.opacity = 1
    }
  }
}

const mapStateToProps = (state) => ({
  periods: state.plan.timeline.periods,
  cellWidth: state.plan.timeline.cellWidth,
  timelineMode: timelineModeSelector(state),
  highlightColumn: highlightColumnSelector(state),
  highlightedColumnIndex: highlightedColumnIndexSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  setHighlightedColumnIndex: (index) => dispatch(setHighlightedColumnIndex(index)),
  clearOldHighlightedColumnIndex: (index) => dispatch(clearOldHighlightedColumnIndex(index)),
})

export const TimelineHeaderComponent = connect(mapStateToProps, mapDispatchToProps)(TimelineHeader)
