import {
  CONFLICT_COLOR,
  CONFLICT_COLOR_ACTIVE,
  CONFLICT_COLOR_HOVER,
  GRAY,
  GRAY_ACTIVE,
} from '../constants/Colors'

export function getBackgroundColor(hasConflict, isCurrentInitiative, isEditSidebarOpen) {
  if (isEditSidebarOpen && isCurrentInitiative) {
    return hasConflict ? CONFLICT_COLOR_ACTIVE : GRAY_ACTIVE
  }

  if (isCurrentInitiative) {
    return hasConflict ? CONFLICT_COLOR_HOVER : GRAY_ACTIVE
  }

  return hasConflict ? CONFLICT_COLOR : 'transparent'
}

export function getBackgroundHoverColor(hasConflict, isCurrentInitiative) {
  if (hasConflict && !isCurrentInitiative) {
    return CONFLICT_COLOR_HOVER
  }

  if (!hasConflict && isCurrentInitiative) {
    return GRAY_ACTIVE
  }

  if (hasConflict && isCurrentInitiative) {
    return CONFLICT_COLOR_ACTIVE
  }

  return GRAY
}

export function getColorFullOpacity(color) {
  switch (color) {
    case CONFLICT_COLOR_ACTIVE:
      return '#fee8ee'
    case CONFLICT_COLOR_HOVER:
      return '#feeef2'
    case CONFLICT_COLOR:
      return '#fef4f6'
    case GRAY_ACTIVE:
      return '#f4f4f4'
    case GRAY:
      return '#f9f9f9'
    default:
      return 'white'
  }
}
