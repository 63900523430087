import React, {
  Suspense,
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
  memo,
  useEffect,
} from 'react'

import {Filters} from '@workfront/filters-ui'
import {quickFilterKeys} from './quickFilterFields'
import {Project} from 'workfront-objcodes'
import {ObjectMetadataProcessor} from '@workfront/filter-redrock-integration'

import {css} from 'react-emotion'
import Loader from './Loader'
import {IDataTableAPI} from '@wftypes/list'
import {FiltersChangeParams} from './types'
import {makeControllerCall} from '../../../import/services/apiFactory'
import {
  loadCurrentUserAccessLevel,
  localizeWildcardsLabels,
} from '../../../import/plugins/toolbar/filters/loadFilters'
import {CurrentUser} from '@workfront/filters-ui/typings/models'

export const UITYPE_FILTER = 'filter'

interface FVGOverlayProps {
  listApi: IDataTableAPI
  onClosePanel: () => void
  onFilterChange: (filters: FiltersChangeParams[], expression?: any) => void
}

const overlayStyles = css`
  width: 472px;
  height: 100%;
  box-shadow: rgba(0, 35, 64, 0.2) 0 3px 6px 0;
  position: relative;
  z-index: 1;
`

const cachedData = new Map()

function NFVGOverlay(props: FVGOverlayProps, ref) {
  const {onClosePanel, onFilterChange} = props
  const skipFieldFn = (field) => !field.isFilterable
  const [quickFilterFields, setQuickFilterFields] = useState([])
  const [currentUser, setCurrentUser] = useState({} as CurrentUser)
  const [advancedFieldGroups, setAdvancedFieldGroups] = useState([])
  const [uiType, setUiType] = useState('')
  const [customQuarters, setCustomQuarters] = useState([])

  useImperativeHandle(
    ref,
    () => ({
      setUiType,
      getUiType: () => uiType,
    }),
    [uiType]
  )

  const handleClosePanel = useCallback(() => {
    onClosePanel()
  }, [onClosePanel])

  useEffect(() => {
    if (uiType !== '') {
      if (!cachedData.has(Project)) {
        cachedData.set(
          Project,
          Promise.all([
            makeControllerCall('objectMetadataForObjCode', 'POST', {objCode: Project}),
            localizeWildcardsLabels(),
          ]).then(([data, wildcardLabels]) => {
            const objectMetadataProcessor = new ObjectMetadataProcessor(
              Project,
              data,
              skipFieldFn,
              wildcardLabels
            )
            return {
              quickFilterFields: objectMetadataProcessor.getFieldsMetadata(quickFilterKeys),
              advancedFieldGroups: objectMetadataProcessor.getFieldGroups(),
            }
          })
        )
      }

      cachedData.get(Project).then(({quickFilterFields, advancedFieldGroups}) => {
        setQuickFilterFields(quickFilterFields)
        setAdvancedFieldGroups(advancedFieldGroups)
      })

      if (!cachedData.has('currentUser')) {
        cachedData.set('currentUser', loadCurrentUserAccessLevel())
      }

      cachedData.get('currentUser').then((data) => {
        setCustomQuarters(data.customQuarters)
        setCurrentUser(data.currentUserPermissions)
      })
    }
  }, [uiType])

  return uiType !== '' && currentUser.ID && quickFilterFields ? (
    <div className={overlayStyles}>
      <Suspense fallback={<Loader />}>
        <Filters
          isTextModeSupported
          filterKey={Project}
          quickFilterFields={quickFilterFields}
          advancedFieldGroups={advancedFieldGroups}
          applyFiltersCallback={onFilterChange}
          onCloseCallback={handleClosePanel}
          currentUser={currentUser}
          isAutoApply={true}
          customQuarters={customQuarters}
        />
      </Suspense>
    </div>
  ) : null
}

export default memo(forwardRef(NFVGOverlay))
