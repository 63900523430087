import {css} from 'emotion'
import React from 'react'
import {connect} from 'react-redux'
import {currentInitiativeSelector} from '../../../../data/initiative'
import {visibleConflictSelector} from '../../../../data/viewOptions'
import {IInitiative, IRoleConflict} from '../../../../shared/models/initiative'
import {TimelineBarWrapperComponent} from '../Timeline/TimelineBarWrapperComponent'
import {TimelineRowComponent} from '../Timeline/TimelineRowComponent'
import {MeasurementsContext} from '../../../../shared/context/MeasurementsContext'

export interface IInitiativeRightComponentProps {
  initiative: IInitiative
  currentInitiative: IInitiative | null
  isHovered: boolean
  visibleConflict: IRoleConflict | null
}

const initiativeRightClass = css`
  flex: 1 0 0;
`

export const InitiativeRight: React.FunctionComponent<IInitiativeRightComponentProps> = React.memo(
  (props) => {
    return (
      <div
        className={`initiative-right-container ${initiativeRightClass}`}
        data-testid="timeline-row"
      >
        <MeasurementsContext.Consumer>
          {({initiativeRowHeight}) => (
            <TimelineRowComponent height={initiativeRowHeight} initiative={props.initiative}>
              <TimelineBarWrapperComponent
                initiative={props.initiative}
                isRowHovered={props.isHovered}
              />
            </TimelineRowComponent>
          )}
        </MeasurementsContext.Consumer>
      </div>
    )
  }
)

InitiativeRight.displayName = 'InitiativeRight'

const mapStateToProps = (state) => ({
  currentInitiative: currentInitiativeSelector(state),
  visibleConflict: visibleConflictSelector(state),
})
export const InitiativeRightComponent = connect(mapStateToProps)(InitiativeRight)
