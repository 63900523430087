import {useEffect} from 'react'
import {IDataTableAPI} from '@wftypes/list'
import {FilterData} from './useWrapperReducer'

type TUseLoadData = (
  listApi: IDataTableAPI,
  isDataLoaded: boolean,
  setData: (data: FilterData) => void,
  loadFn: (listApi: IDataTableAPI) => Promise<FilterData>
) => void
const useLoadData: TUseLoadData = (listApi, isDataLoaded, setData, loadFn) => {
  useEffect(() => {
    if (!isDataLoaded) {
      loadFn(listApi).then(setData, (err) => {
        listApi.showErrorNotification(err.message)
      })
    }
  }, [isDataLoaded, listApi, setData, loadFn])
}
export default useLoadData
