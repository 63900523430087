import {css, cx} from 'emotion'
import * as React from 'react'
import {connect} from 'react-redux'
import {primary, FormLabel, Text} from '@phoenix/all'
import {currencySelector} from '../../data/plan'
import {formatNumber} from '../../shared/utilities'
import {FormattedCurrencyComponent} from './FormattedCurrencyComponent'
import {PopperComponent} from './PopperComponent'
import {TooltipContentComponent} from './TooltipContentComponent'
import {Translate} from './Translate'

interface IMoneyFieldViewComponentProps {
  cost: number | null
  labelMessageKey?: string
  label?: string
  currency: string
  isClickable?: boolean
  isActive?: boolean
  handleYearFocus?: (event, label) => void
  testID?: string
}

const costContainerClass = css`
  height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
`

const underlineClass = css`
  > span {
    border-bottom: 1px solid #303744;
  }
`

const cutTextClass = css`
  cursor: pointer;
  :hover {
    > span {
      border-bottom: 1px solid #303744;
    }
  }
`

const costValueClass = css`
  color: ${primary.gray(700)};
`

const leftIntervalClass = css`
  padding-left: 13px;
`

const MoneyFieldView: React.FunctionComponent<IMoneyFieldViewComponentProps> = (props) => {
  const {
    cost,
    labelMessageKey,
    label,
    currency,
    isClickable,
    isActive,
    handleYearFocus,
    testID,
  } = props

  const showTooltip = () => {
    return !!(cost && (cost > 1000 || cost < -1000))
  }

  const getFormattedCost = () => {
    return (
      <span data-testid={testID}>
        <Text className={cx(costValueClass, leftIntervalClass)}>{formatNumber(cost, 1, true)}</Text>
      </span>
    )
  }

  const onClickHandler = (e) => {
    isClickable && handleYearFocus && handleYearFocus(e, label)
  }

  const handleKeyPress = (e) => {
    const keyboardClickKeys = ['Enter', 'Space', ' ']
    if (keyboardClickKeys.includes(e.key)) {
      onClickHandler(e)
    }
  }

  return (
    <React.Fragment>
      <FormLabel>{labelMessageKey ? <Translate messageKey={labelMessageKey} /> : label}</FormLabel>
      <div
        role="button"
        tabIndex={0}
        className={`${costContainerClass} ${isActive ? underlineClass : ''} ${
          isClickable ? cutTextClass : ''
        }`}
        onClick={onClickHandler}
        onKeyPress={handleKeyPress}
      >
        {showTooltip() ? (
          <PopperComponent
            reference={getFormattedCost()}
            content={
              <TooltipContentComponent
                text={
                  <FormattedCurrencyComponent
                    cost={cost}
                    currency={currency}
                    isAbbreviated={false}
                  />
                }
              />
            }
            showOnOver={true}
            placement="top"
            isInsidePortal={true}
          />
        ) : (
          getFormattedCost()
        )}
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  currency: currencySelector(state),
})

export const MoneyFieldViewComponent = connect(mapStateToProps)(MoneyFieldView)
