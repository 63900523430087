export const messagesObject = {
  'timeline.mode.year': 'Year',
  'scenario.add.description': 'Add description',
  'form.description': 'Description',
  'sp.scenario.actions.delete.lastScenario.confirmation':
    'You are about to delete your plan. Are you sure you want to proceed?',
  'sp.scenario.actions.delete.published.confirmation':
    'All initiatives in this scenario will be deleted. The projects associated with initiatives in this scenario will not be deleted. Are you sure you want to proceed?',
  'sp.scenario.actions.delete.initial.confirmation':
    'You are about to delete your initial scenario. Your second scenario becomes the initial scenario. Are you sure you want to proceed?',
  'sp.scenario.actions.delete.confirmation':
    'When you delete a scenario, you delete all the initiatives associated with it. Are you sure you want to proceed?',
  'sp.scenario.initial': 'Initial scenario',
  'sp.scenario': (params) => `Scenario ${params[0]}`,
  'sp.scenarios': 'Scenarios',
  'scenario.widget.budget': 'Budget',
  'scenario.card.costs': 'Costs',
  'scenario.card.utilization': 'Utilization',
  'sp.initiatives': 'initiatives',
  'scenario.card.conflicting.initiatives': 'Conflicting',
  'scenario.published': 'Published',
  'header.widget.role.available': 'Available',
  'header.widget.role.required': 'Required',
  'scenario.widget.net': 'Net Value',
  'scenario.actions.copy': 'Copy',
  'scenario.actions.delete': 'Delete',
  'sp.dialog.header.scenario.delete': 'Delete Scenario',
  'form.button.delete': 'Yes, delete it',
  'form.button.cancel': 'Cancel',
  'sp.back.to.scenario': 'Back to scenario',
  'sp.scenario.header.count': `{ count , plural , one {# scenario} other {# scenarios} }`,
  'sp.messages.publish.button.no.project.access':
    "You don't have sufficient access for projects to publish a scenario. Contact your System administrator to learn more.",
  'sp.messages.publish.button.disabled':
    "You don't have any initiatives yet. Add initiatives to your scenario to publish them.",
  'form.button.publish': 'Go to Publish',
  'scenario.unsaved': 'Unsaved changes',
  'form.button.publish.save': 'Save',
  'sp.messages.publish.button': 'Save your plan to publish the latest changes',
  'sp.import.projects.header': (params) =>
    `Import projects to the plan for the following timeframe: ${params[0]} - ${params[1]}`,
  'sp.import.projects.tooltip':
    'The following project information is imported into initiatives: Project name, planned dates, job roles assigned to project tasks. The task planned hours are converted to FTEs for the imported job roles.',
  'import.button.tooltip': (params) =>
    `You can import up to ${params[0]} projects at a time. Currently selected: ${params[1]}`,
  import: 'Import',
  'sp.messages.publish.dialog.title': 'Publish initiatives for',
  'sp.messages.publish.dialog.info':
    'Publishing a scenario creates or updates projects from initiatives. Select the initiatives for which you want to create or update projects.',
  'sp.form.button.publishing.initiatives': 'Publishing initiatives...',
  'sp.form.button.publish.initiatives': 'Publish initiatives',
  'publish.dialog.button.tooltip': (params) =>
    `You can publish up to ${params[0]} items at a time. Currently selected: ${params[1]}`,
  'see.associated.projects': 'See associated projects',
  'see.associated.project': 'See associated project',
  'global.na': 'N/A',
  'sidebar.benefit': 'Planned benefit',
  available: 'Available',
  'previously.allocated': 'Previously allocated',
  'timeline.conflicts.overallocated': 'Overallocated',
  'timeline.conflicts.roles': 'Job Roles',
  'scenario.unsaved.message': 'You have unsaved changes. Are you sure you want to discard them?',
  'form.button.discard': 'Discard',
  'sp.scenario.back.to.list': 'Back to plans',
  'sp.sidebar.duration.month': '{ count , plural , one {# month} other {# months} }',
  'form.button.apply': 'Apply',
  'global.unapplied': 'Unapplied changes',
  'global.no.result': 'No results',
  'sidebar.confirmation.message': 'You have unapplied changes. Would you like to apply them now?',
  'form.start.typing.role': 'Start typing job role',
  'job.roles.hour': 'Job Roles (Hours)',
  'sp.sidebar.duration': 'Initiative Duration',
  'sidebar.required.job.roles': 'Required Job Roles',
  'sp.sidebar.people.conflict.message':
    'There are not enough job roles available for this initiative.To resolve this conflict, update the number of roles,the initiative priority, or time period.',
  'sp.sidebar.budget.conflict.message':
    'The plan budget does not cover the costs of this initiative.To resolve this conflict, reduce the costs,or update the initiative priority, or time period.',
  'sidebar.costs': 'Costs',
  'sidebar.net': 'Net value',
  'sidebar.resolve.conflict': 'Update available job roles',
  'sp.timeline.conflicts.competingInitiatives': 'Conflicting Initiatives',
  'timeline.conflicting.months': 'Conflicting months',
  'timeline.available': 'Available',
  'sidebar.costs.fixed': 'Fixed Costs',
  'sidebar.costs.people': 'People Costs',
  'sp.initiative': 'Initiative',
  project: 'Project',
  'timeline.conflicts.preview': 'Timeline preview',
  'sidebar.resolve.budget.conflict.contouring.off': 'Update available budget',
  'sp.scenario.previously.allocated.description':
    'Job roles allocated to higher-ranking initiatives',
  'sp.scenario.previously.allocated.cost.description':
    'Budget allocated to higher-ranking initiatives',
  'scenario.previously.allocated': 'Previously allocated',
  'scenario.hide.details': 'Hide details',
  'scenario.show.details': 'Show details',
  'sp.scenario.resolve.role.conflict.description':
    'This updates the number of job roles available on the scenario',
  'sp.scenario.add.job.roles.to.available.resources':
    "Add roles to the scenario's available resources",
  'sp.scenario.resolve.cost.conflict.description': "This updates the scenario's budget",
  'sp.sidebar.resolve.budget.conflict': "Add amount to the scenario's budget",
  'scenario.widget.people.hourly.rate': 'Hourly rate',
  avg: 'Avg',
  'header.widget.role.utilization': 'Utilization',
  'job.roles.fte': 'Job Roles (FTE)',
  'total.available': 'Total available',
  'max.available': 'Max available',
  'total.required': 'Total required',
  'max.required': 'Max required',
  'header.widget.role.info.view.mode':
    'Review the available and required job roles for this scenario',
  'sp.header.widget.role.info.hour':
    'Specify the number of available job role hours for this scenario',
  'sp.header.widget.role.info.view.mode':
    'Review the available and required job roles for this scenario',
  'sp.header.widget.role.info.fte':
    'Specify the number of available job role FTEs for this scenario',
  'job.role.distribution': 'Job role distribution',
  'form.button.reset': 'Reset',
  'scenario.widget.budget.exceeded': 'Exceeded',
  'scenario.widget.budget.remaining': 'Remaining',
  'scenario.widget.budget.costs.fixed': 'Fixed costs',
  'sp.scenario.widget.budget.costs.description': 'Total costs of initiatives',
  'scenario.widget.budget.costs.people': 'People costs',
  'scenario.enable.people.costs.conflicts': 'Include people costs',
  'sp.scenario.widget.budget.info.view': 'Review the budget and costs for this scenario',
  'sp.scenario.widget.budget.info.edit': 'Indicate the budget available for this plan',
  'header.widget.budget.costs': 'Costs',
  'scenario.widget.util': 'util',
  'scenario.widget.budget.top.title': 'Yearly budget for',
  'scenario.widget.budget.top.advanced': 'Advanced',
  'scenario.widget.delete.role': 'Delete Role',
  'sp.form.button.delete.message': (params) =>
    `This role is used in ${params[0]} ${params[1]}. Applying this change removes the role from all initiatives. Are you sure you want to delete it?`,
  required: 'Required',
  'sp.role.distribution-dialog.title': 'Distribute job roles for the duration of the scenario',
  'sp.role.distribution-dialog.sub.title.hour':
    'Indicate the number of available job role hours for each month of this scenario',
  'sp.role.distribution-dialog.sub.title.fte':
    'Indicate the number of available job role FTEs for each month of this scenario',
  'scenario.widget.budget.warning':
    'The total of your monthly budget amounts must be less or equal to the yearly budget',
  'show.conflicts': 'Show conflicts',
  'sp.scenario.compare': 'Compare scenarios',
  'uneven.warning.hourly':
    'Updating the number of available hours redistributes the hours evenly for the duration of the plan',
  'uneven.warning.fte':
    'Updating the number of available FTEs applies the same number to each month that has been allocated',
  'sp.scenario.widget.people.role.change.warning':
    'Updating the number of people here removes the changes you made. This becomes the total number of available roles for the plan.',
  'scenario.widget.people.role.initial': (params) => `Initial number ${params[0]}`,
  'scenario.widget.people.role.change.contouring': (params) => `+${params[0]} in ${params[1]}`,
  summary: 'Summary',
  financial: (params) => `Financial (${params[0]})`,
  'sp.have.not.roles.in.list.view.mode': 'There are no available job roles for this scenario yet',
  'sp.have.not.roles.in.list': "You don't have any roles for your scenario yet.",
  'start.typing.role': 'Start typing a job role to begin.',
  'fully.allocated': 'Fully allocated',
  'timeline.overallocated': 'Overallocated',
  underallocated: 'Underallocated',
  'timeline.today': 'Today',
  'timeline.see.today': 'Click to see today',
  'scenario.conflict.tooltip.heading': (params) => `Required resources for ${params[0]}`,
  'sp.timeline.conflicts.priority':
    'Changing the priority of this initiative might fit this into your plan.',
  'timeline.conflicts.role': '{ count , plural , one {Job Role} other {Job Roles} }',
  'timeline.mode.month': 'Month',
  'timeline.mode.quarter': 'Quarter',
  'sp.initiative.new': 'New initiative',
  'import.projects': 'Import Projects',
  'sp.messages.initiative.warning.last_published.delete':
    'Deleting the only published initiative in the scenario identifies the scenario as drafted. Are you sure you want to proceed?',
  'sp.messages.initiative.warning.delete': 'Are you sure you want to delete this initiative ?',
  'initiative.options.details': 'Details',
  'initiative.options.edit': 'Edit',
  'initiative.options.copy': 'Copy',
  'initiative.options.delete': 'Delete',
  'sp.scenario.initiative.delete': 'Delete Initiative',
  'sp.scenario.initiative.delete.plural': 'Delete Initiatives',
  'initiative.import.project.icon': 'Open associated project',
  'scenario.timeline.resolved.roles': (params) => `Since ${params[0]}`,
  'sp.initiative.selected.single': '1 initiative selected',
  'sp.initiative.selected.plural': (params) => `${params[0]} initiatives selected`,
  'initiative.deselect.all': 'Deselect All',
  'initiative.select.all': 'Select All',
  'sp.messages.plan.noaccess': "You don't have access to Scenario Planner.",
  'messages.plan.contact.administrator': 'Contact your system administrator to request access.',
  'sp.messages.plan.deleted': 'That plan no longer exists or you have the wrong URL.',
  'sp.plan.new': 'New Plan',
  'form.name': 'Name',
  'required.field': 'This is a required field.',
  'form.start.typing': 'Start typing',
  'sp.form.dates.label': 'Set the Start date and Duration of your plan.',
  'form.dates.start': 'Start date',
  'form.dates.duration': 'Duration',
  'form.duration.year': '1 year',
  'form.duration.years': (params) => `${params[0]} years`,
  'form.button.next': 'Next',
  'go.to.start': 'Go to start',
  'sp.form.button.saving': 'Saving Plan…',
  'sp.form.button.save': 'Save plan',
  'initiative.options.prioritize.top': 'Top',
  'initiative.options.prioritize.bottom': 'Bottom',
  'initiative.options.prioritize.after': 'After',
  'initiative.options.prioritize': 'Prioritize',
  'sp.plan': 'Plan',
  'sp.dialog.header.plan.delete': '{ count , plural , one {Delete Plan} other {Delete Plans} }',
  'sp.plan.delete.message':
    'When you delete a plan, you delete all the scenarios and initiatives associated with the plan. Are you sure you want to proceed?',
  'required.resources.for': (params) => `Required resources for ${params[0]}`,
  'initiative.options.share': 'Share',
  'sp.plan.share.search.unavailable': 'You must save the plan before you can share it.',
  'fte.hours.label': 'Estimate available and required job roles using',
  'header.widget.role.job.fte': 'FTE',
  'header.widget.role.job.fte.long': 'Full-time equivalent',
  hours: 'Hours',
  'sp.messages.plan.save.initial.scenario': 'You successfully saved your plan',
  'sp.messages.plan.delete': 'You have successfully deleted the plan',
  'import.projects.failed': 'The selected projects failed to import',
  'import.projects.success.one': '1 project imported successfully',
  'import.projects.success.all': (params) => `${params[0]} projects imported successfully`,
  'import.projects.success': (params) =>
    `${params[0]} out of ${params[1]} projects imported successfully`,
  'sp.publish.initiative.permission.failed':
    'One initiative failed to publish because of your access to projects',
  'sp.publish.initiative.failed': 'The selected initiative failed to publish',
  'sp.publish.initiatives.permission.failed':
    'Some initiatives failed to publish because of your access to projects',
  'sp.publish.initiative.success': '1 initiative published successfully',
  'sp.publish.initiatives.all.success': (params) =>
    `${params[0]} initiatives published successfully`,
  'sp.publish.initiatives.success': (params) =>
    `${params[0]} out of ${params[1]} initiatives published successfully`,
  'sp.initiative.copy.success.one': 'Initiative copied successfully',
  'sp.initiative.copy.success.all': (params) => `${params[0]} initiatives copied successfully`,
  'initiative.copy.prefix': 'Copy of',
  'sp.messages.plan.save': 'You successfully saved the changes to your plan.',
  'sp.messages.plan.save.changes': (params) =>
    `You successfully saved the changes to your plan. The following scenarios have been updated: ${params[0]}.`,
  'sp.favorite.action.disabled': 'Save the plan first to add it to your Favorites',
  'favorite.action.add': 'Add to Favorites',
  'favorite.action.delete': 'Remove from Favorites',
  'form.start.typing.name': 'Start typing name...',
  'sp.initiative.default.name': 'Untitled Initiative',
  difference: 'Difference',
  'header.widget.role.available.max': 'max',
  total: 'total',
}

export const messagesKeys = Object.entries(messagesObject).map((message) => message[0])
