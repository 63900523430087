import {EspPlan} from 'workfront-objcodes'

export const LIST_ID = 'ESP_PLAN_LIST'
export const PAGE_SIZES = [100, 250, 500]

export const COLUMN_MESSAGES = [
  ['plan.list.name', 'Name'],
  ['plan.list.owner', 'Owner'],
  ['plan.list.shared.with', 'Shared with'],
  ['sp.plan.list.number.of.scenario', 'Number of Scenarios'],
  ['plan.list.created.on', 'Created on'],
  ['plan.list.updated.on', 'Updated on'],
]

export const metadataDefinition = {
  objCode: EspPlan,
  isDashboard: null,
  detailObj: {objCode: null, id: null},
  isHierarchic: false,
  pagination: {
    pageCount: 1,
    pageSizes: [],
    pageNumber: 1,
    count: 0,
    pageSize: 2000,
    recordLimit: 2000,
  },
  compactMode: false,
  options: {
    objCode: EspPlan,
    viewID: LIST_ID,
    listTileName: LIST_ID,
    isShortView: false,
  },
  listTileName: LIST_ID,
  currency: null,
  showContextMenu: null,
  objCodeMessageKey: 'plan',
  sortInfo: [
    {
      number: 1,
      key: 'lastUpdatedOn',
      direction: 'desc',
    },
  ],
  showInlineAdd: false,
  rawValueTypes: {
    createdOn: 'date',
    lastUpdatedOn: 'date',
  },
  emptyMessageKey: null,
  worksheetRefreshTimer: -1,
  useColumnWidths: false,
  contextMenu: {
    toolbarActionsRequirements: {},
    toolbarActions: [],
  },
  baseCollapseLevel: 0,
  applyWindowScrolling: false,
  startOrderNum: null,
  queryMap: {},
  hidePageSize: false,
  isShortView: false,
  isInModal: false,
  isReactListToolbarsEnabled: true,
}

export const columnsDefinition = [
  {
    defaultMinWidth: 30,
    display: '',
    index: 0,
    reorderable: false,
    sortable: false,
    sortQuery: null,
    field: '',
    width: 60,
    defaultMaxWidth: 60,
  },
  {
    template: 'component.planname',
    defaultMinWidth: 296,
    width: 296,
    defaultMaxWidth: 296,
    display: '',
    index: 1,
    reorderable: false,
    sortable: true,
    sortQuery: 'name',
    field: 'name',
  },
  {
    template: 'component.owner',
    defaultMinWidth: 164,
    width: 164,
    defaultMaxWidth: 164,
    display: '',
    index: 2,
    reorderable: false,
    sortable: true,
    sortQuery: 'owner.name',
    field: 'owner',
  },
  {
    template: 'component.sharedwith',
    defaultMinWidth: 164,
    width: 164,
    defaultMaxWidth: 164,
    display: '',
    index: 3,
    reorderable: false,
    sortable: false,
    field: 'accessorObjects',
  },
  {
    template: 'component.scenarios',
    defaultMinWidth: 120,
    width: 120,
    defaultMaxWidth: 120,
    display: '',
    index: 4,
    reorderable: false,
    sortable: true,
    sortQuery: 'scenarioCount',
    field: 'scenarioCount',
  },
  {
    template: 'component.createdon',
    defaultMinWidth: 120,
    width: 120,
    defaultMaxWidth: 120,
    display: '',
    index: 5,
    reorderable: false,
    sortable: true,
    sortQuery: 'createdOn',
    field: 'createdOn',
  },
  {
    template: 'component.updatedon',
    defaultMinWidth: 120,
    width: 120,
    defaultMaxWidth: 120,
    display: '',
    index: 6,
    reorderable: false,
    sortable: true,
    sortQuery: 'lastUpdatedOn',
    sortNumber: 1,
    sortDirection: 'desc',
    field: 'lastUpdatedOn',
  },
]
