import * as React from 'react'
import {formatFloating} from '../../../../../../shared/utilities'
import {CounterComponent} from '../../../../../shared'
import {RoleDistributionDetailsCalculatedData} from './RoleDistributionDetailsCalculatedData'
import {RoleDistributionContext} from '../../../../../../shared/context/RoleDistributionContext'

export interface IRoleDistributionDetailsCellProps {
  availableValue: number | null
  availableForCalculations: number
  requiredValue: number
  roleId: string
  distributionKey: string
  className: string
  updateInputValuesState: (key: string, value: number | null) => void
  onBlur: React.FocusEventHandler
  onFocus: React.FocusEventHandler
}

export const RoleDistributionDetailsCell: React.FC<IRoleDistributionDetailsCellProps> = React.memo(
  ({
    availableValue,
    availableForCalculations,
    requiredValue,
    roleId,
    distributionKey,
    updateInputValuesState,
    onBlur,
    onFocus,
    className,
  }) => {
    const {updateRoleAvailablePerPeriod} = React.useContext(RoleDistributionContext)

    const onBlurHandler = (value) => {
      const changedValue = value || 0

      if (!value) {
        updateInputValuesState(distributionKey, changedValue)
      }

      if (changedValue !== formatFloating(availableForCalculations, 3)) {
        updateRoleAvailablePerPeriod(roleId, {[distributionKey]: value || 0})
      }
      onBlur(value)
    }

    const onChangeHandler = (value: number | null) => {
      updateInputValuesState(distributionKey, value)
    }

    return (
      <>
        <CounterComponent
          changePeopleCount={onChangeHandler}
          onBlur={onBlurHandler}
          onFocus={onFocus}
          roleCount={
            typeof availableValue === 'number' ? formatFloating(availableValue, 3) : availableValue
          }
          id={`role-distribution-input-${roleId}-${distributionKey}`}
          testID={`role-distribution-input-${roleId}-${distributionKey}`}
          className={className}
        />

        <RoleDistributionDetailsCalculatedData
          availableValue={availableForCalculations}
          requiredValue={requiredValue}
          testID={`${roleId}-${distributionKey}`}
        />
      </>
    )
  }
)

RoleDistributionDetailsCell.displayName = 'RoleDistributionDetailsCell'
