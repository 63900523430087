import React from 'react'
import {connect} from 'react-redux'
import {isInitiativeDeleteConfirmationDialogOpenSelector} from '../../../data/scenario/selectors/isInitiativeDeleteConfirmationDialogOpenSelector'
import {isDeletingMultipleInitiativesSelector} from '../../../data/scenario/selectors/isDeletingMultipleInitiativesSelector'
import {ConfirmDialog, Text} from '@phoenix/all'
import {hideInitiativeDeleteConfirmationDialog, confirmBulkInitiativeDeletion} from '../../../data'
import {useLocalization} from '@wf-mfe/localize-react'
import {ConfirmationDialogHeaderComponent} from '../../shared/ConfirmationDialogHeaderComponent'

interface IDeleteInitiativeConfirmDialogContentProps {
  isOpen: boolean
  deleteMultiple: boolean
  hideDialog: () => void
  confirmBulkDelete: () => void
}

const DeleteInitiativeConfirmDialogContent: React.FC<IDeleteInitiativeConfirmDialogContentProps> = ({
  isOpen,
  deleteMultiple,
  hideDialog,
  confirmBulkDelete,
}) => {
  const messages = {
    confirmText: useLocalization('form.button.delete', 'Yes, delete it')[0],
    confirmTextPlural: useLocalization('form.button.delete.plural', 'Yes, delete them')[0],
    denyText: useLocalization('form.button.cancel', 'Cancel')[0],
    deleteMessage: useLocalization(
      'sp.messages.initiative.warning.delete',
      'Are you sure you want to delete this initiative ?'
    )[0],
    deleteMessagePlural: useLocalization(
      'sp.messages.initiative.warning.delete.plural',
      'Are you sure you want to delete these initiatives ?'
    )[0],
  }
  const confirmBulkDeleteHandler = React.useCallback(() => {
    confirmBulkDelete()
    hideDialog()
  }, [confirmBulkDelete, hideDialog])

  const dialogHeaderMessageKey = deleteMultiple
    ? 'sp.scenario.initiative.delete.plural'
    : 'sp.scenario.initiative.delete'

  return (
    <>
      {isOpen && (
        <ConfirmDialog
          header={<ConfirmationDialogHeaderComponent messageKey={dialogHeaderMessageKey} />}
          confirmText={deleteMultiple ? messages.confirmTextPlural : messages.confirmText}
          denyText={messages.denyText}
          onConfirmClick={confirmBulkDeleteHandler}
          onDenyClick={hideDialog}
          testID="delete-initiative-confirmation-dialog"
        >
          <Text>{deleteMultiple ? messages.deleteMessagePlural : messages.deleteMessage}</Text>
        </ConfirmDialog>
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  isOpen: isInitiativeDeleteConfirmationDialogOpenSelector(state),
  deleteMultiple: isDeletingMultipleInitiativesSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  hideDialog: () => dispatch(hideInitiativeDeleteConfirmationDialog()),
  confirmBulkDelete: () => dispatch(confirmBulkInitiativeDeletion()),
})

export const DeleteInitiativeConfirmDialogComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteInitiativeConfirmDialogContent)
