import {css} from 'emotion'
import moment, {Moment} from 'moment'
import {Placement} from 'popper.js'
import * as React from 'react'
import {PopperComponent} from './PopperComponent'

import CalendarIcon from 'phoenix-icons/dist/CalendarIcon.js'
import {primary, DateRangePicker, FormLabel} from '@phoenix/all'
import {LABEL_DATE_FORMAT} from '../../shared/constants/dateFormats'

interface IMonthDatePickerState {
  date: Moment
}

interface IMonthDatePickerProps {
  onDateChange: (startDate: Moment) => void
  value?: Moment
  maxValue?: Moment
  minValue?: Moment
  label?: string | JSX.Element
  placement?: Placement
  testId: string
}

const pickerWrapperClass = css`
  box-sizing: border-box;
  height: 32px;
  border: 1px solid ${primary.gray(300)};
  border-radius: 4px;
  margin: 0;
  display: flex;
  margin-bottom: 4px;
`
const prefixWrapperClass = css`
  background: ${primary.gray(100)};
  color: ${primary.gray(700)};
  border-right: 1px solid ${primary.gray(300)};
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const inputClass = css`
  padding: 0px 12px;
  font-size: 14px;
  line-height: 30px;
  color: ${primary.gray(700)};
`

export class MonthDatePicker extends React.Component<IMonthDatePickerProps, IMonthDatePickerState> {
  static getDerivedStateFromProps(props: IMonthDatePickerProps, state: IMonthDatePickerState) {
    state = {
      date: props.value ? props.value : moment(new Date()),
    }
    return state
  }

  public state: IMonthDatePickerState = {date: moment()}

  render() {
    return (
      <>
        {this.props.label && <FormLabel>{this.props.label}</FormLabel>}
        <PopperComponent
          reference={
            <div
              className={pickerWrapperClass}
              data-testid={this.props.testId}
              role="button"
              tabIndex={0}
              aria-label={`${
                typeof this.props.label === 'string' ? this.props.label : ''
              }, ${this.state.date.format(LABEL_DATE_FORMAT)}`}
            >
              <div className={prefixWrapperClass}>
                <CalendarIcon />
              </div>

              <span className={inputClass}> {this.state.date.format(LABEL_DATE_FORMAT)}</span>
            </div>
          }
          placement={this.props.placement || 'bottom-start'}
          content={
            <div>
              <DateRangePicker
                mode={'MODE_MONTH'}
                endDate={this.state.date}
                startDate={this.state.date}
                maxValue={this.props.maxValue}
                minValue={this.props.minValue}
                onChange={(dateRange) => {
                  const {startDate} = dateRange
                  this.setState({
                    date: startDate,
                  })
                  this.props.onDateChange(startDate)
                }}
                primaryMode={'MODE_MONTH'}
              />
            </div>
          }
          isInsidePortal={false}
          showOnOver={false}
        />
      </>
    )
  }
}
