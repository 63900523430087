import {primary} from '@phoenix/all'
import {css, cx} from 'emotion'
import * as React from 'react'
import {useSelector} from 'react-redux'
import {isEditSidebarOpenSelector} from '../../../../data'
import {inlineCenterItemsClass} from '../StyleHelper'

interface IHeaderWidgetWrapperComponentProps {
  isWidgetExpanded?: boolean
  toggleWidgetFunction?: (isWidgetExpanded: boolean) => void
  type: string
  icon: React.ReactElement
}

const widgetClass = css`
  ${inlineCenterItemsClass};
  padding: 0 16px 4px;
  height: 70px;
  width: 235px;
  border: 1px solid ${primary.gray(200)};
  border-radius: 4px;
  white-space: nowrap;
`

const largeWidthClass = css`
  width: 255px;
`

const mediumWidthClass = css`
  width: 235px;
`

const smallWidthClass = css`
  width: 150px;
`

const widgetInnerClass = css`
  flex-grow: 1;
`

const widgetHoverClass = css`
  cursor: pointer;

  &:hover {
    box-shadow: 0 3px 6px 0 rgba(0, 35, 64, 0.2);
    border-color: transparent;
  }
`

const expandedWidgetClass = css`
  box-shadow: 0 3px 6px 0 rgba(0, 35, 64, 0.2);
  border-color: transparent;
`

const iconClass = css`
  padding-right: 8px;
`

export const HeaderWidgetWrapperComponent: React.FC<IHeaderWidgetWrapperComponentProps> = React.memo(
  ({children, isWidgetExpanded, toggleWidgetFunction, type, icon}) => {
    const isSidebarOpen = useSelector(isEditSidebarOpenSelector)

    const className = React.useMemo(() => {
      const widthClass =
        type === 'people' ? mediumWidthClass : type === 'budget' ? largeWidthClass : smallWidthClass
      const hoverClass = !isSidebarOpen && type !== 'net-value' ? widgetHoverClass : ''

      return cx(widgetClass, widthClass, isWidgetExpanded ? expandedWidgetClass : '', hoverClass)
    }, [isWidgetExpanded, type, isSidebarOpen])

    const onWidgetClickHandler = () => {
      toggleWidgetFunction && toggleWidgetFunction(!isWidgetExpanded)
    }

    const handleKeyPress = (e) => {
      const keyboardClickKeys = ['Enter', 'Space', ' ']
      if (keyboardClickKeys.includes(e.key)) {
        onWidgetClickHandler()
      }
    }

    const accessibilityProps =
      type === 'net-value'
        ? {}
        : {
            role: 'button',
            tabIndex: 0,
            onKeyPress: handleKeyPress,
            onClick: onWidgetClickHandler,
          }

    return (
      <div {...accessibilityProps} className={className} data-testid={`${type}-widget`}>
        <div className={iconClass}>{icon}</div>

        <div className={widgetInnerClass}>{children}</div>
      </div>
    )
  }
)

HeaderWidgetWrapperComponent.displayName = 'HeaderWidgetWrapperComponent'
