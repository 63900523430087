import {css} from 'react-emotion'
import {primary, secondary} from '@phoenix/all'

export const panelHeader = css`
  display: flex;
  align-items: center;
  height: 41px;
  border-bottom: 1px solid ${primary.gray(200)};
  padding: 0 17px 0 24px;
`

export const closeButton = css`
  padding: 0;
  contain: content;
  outline: none !important;
  background: none;
  line-height: 0;
  cursor: pointer;
  border: 2px solid transparent;
  &:focus {
    border: 2px solid ${secondary.lightblue(200)};
  }
`

export const panelCaption = css`
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  color: ${primary.gray(700)};
  display: inline-block;
  flex-grow: 1;
`

export const height100 = css`
  height: 100%;
`
