import {secondary} from '@phoenix/all'
import {css} from 'emotion'
import React from 'react'

const patternClass = css`
  stroke: ${secondary.red(200)};
  stroke-width: 1px;
`

export const DiagonalHatchPatternComponent: React.FunctionComponent = React.memo(() => (
  <defs>
    <pattern
      id="diagonalHatch"
      width="5"
      height="5"
      patternTransform="rotate(45 0 0)"
      patternUnits="userSpaceOnUse"
    >
      <line x1="0" y1="0" x2="0" y2="5" className={patternClass} />
    </pattern>
  </defs>
))

DiagonalHatchPatternComponent.displayName = 'DiagonalHatchPatternComponent'
