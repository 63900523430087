import * as React from 'react'
import {css} from 'react-emotion'
import {primary} from '@phoenix/all'

const buttonLabelWrapper = css`
  max-width: 94px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 18px;
  font-weight: 600;
  color: ${primary.gray(500)};
  display: inline-block;
  margin: 0 4px 0 4px;
`
interface ToolbarButtonLabelProps {
  children: React.ReactNode
}
export default function ToolbarButtonLabel(props: ToolbarButtonLabelProps): JSX.Element {
  const {children} = props

  return (
    <span aria-hidden={true} className={buttonLabelWrapper}>
      {children}
    </span>
  )
}
