import {css} from 'react-emotion'
import {Moment} from 'moment'
import * as React from 'react'
import {connect} from 'react-redux'
import DeleteIcon from 'phoenix-icons/dist/DeleteIcon.js'

import {primary, Text} from '@phoenix/all'

import {
  currencySelector,
  currentPlanSelector,
  currentPlanStartDateSelector,
  deleteScenarioRole,
  financialAccessDeniedSelector,
  planDurationSelector,
  updateScenarioRoles,
  viewAccessSelector,
} from '../../../../../data'
import {generateRoleDistribution} from '../../../../../shared/helpers'
import {IScenarioRole} from '../../../../../shared/models'
import {formatCurrency, formatFloating} from '../../../../../shared/utilities'
import {resetButtonDefaultStyles, TextWithTooltipComponent} from '../../../../shared'
import {AvailableRolesInputComponent} from './AvailableRolesInputComponent'
import {
  availableRolesCellClass,
  hourlyRateCellClass,
  nameCellClass,
  requiredRolesCellClass,
} from './RoleListStyleHelper'
import {peopleCostOnConflictCalculationSelector} from '../../../../../data/plan/selectors/peopleCostOnConflictCalculationSelector'
import {hoursForMeasurementsSelector} from '../../../../../data/plan/selectors/hoursForMeasurementsSelector'
import {Translate} from '../../../../shared/Translate'
import {RemoveRoleConfirmationDialog} from './RemoveRoleConfirmationDialog'

export interface IHeaderRoleRowComponentProps {
  scenarioRole: IScenarioRole
  planDurationInMonths: number
  currency: string
  isViewMode: boolean
  isFocused: boolean
  hasFinancialAccess: boolean
  deleteRoleFunction: (
    scenarioRoleIds: Set<string>,
    usePeopleCostOnConflictCalculation: boolean
  ) => void
  updateRolesFunction: (scenarioRoles: IScenarioRole[]) => void
  usePeopleCostOnConflictCalculation: boolean
  planDuration: number
  planStartDate: Moment
  useHoursForMeasurements: boolean
}

interface IHeaderRoleRowComponentState {
  isConfirmDialogOpen: boolean
  isInputFocus: boolean
}

const roleRowWrapperClass = css`
  display: table-row;
  z-index: 1;
  overflow: hidden;

  .role-empty-available-input {
    display: none;
  }

  &:hover {
    .number-input {
      border: 1px solid ${primary.gray(300)};
    }

    .role-delete {
      opacity: 1;
    }

    .role-empty-available-value {
      display: none;
    }

    .role-empty-available-input {
      display: block;
    }
  }
`
const roleRowWrapperFocusClass = css`
  .number-input {
    border: 1px solid ${primary.gray(300)};
  }

  .role-delete {
    opacity: 1;
  }

  .role-empty-available-value {
    display: none;
  }

  .role-empty-available-input {
    display: block;
  }
`
const textClass = css`
  color: ${primary.gray(700)};
`

const hourlyRateTextClass = css`
  ${textClass};
`

const deleteButtonClass = css`
  ${resetButtonDefaultStyles};
  cursor: pointer;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 16px;
`

export class HeaderRoleRow extends React.Component<
  IHeaderRoleRowComponentProps,
  IHeaderRoleRowComponentState
> {
  constructor(props: IHeaderRoleRowComponentProps) {
    super(props)
    this.state = {
      isConfirmDialogOpen: false,
      isInputFocus: false,
    }
  }

  render() {
    const {scenarioRole, currency, isFocused, hasFinancialAccess} = this.props

    return (
      <React.Fragment>
        <div
          className={`${roleRowWrapperClass} ${
            this.state.isInputFocus ? roleRowWrapperFocusClass : ''
          }`}
          data-testid="role-row"
        >
          <div className={nameCellClass}>
            <TextWithTooltipComponent
              testID="role-name"
              className={textClass}
              text={scenarioRole.role.name}
            />
          </div>

          <div className={availableRolesCellClass}>
            <AvailableRolesInputComponent
              onFocusFunction={() => this.setFocusState(true)}
              onBlurFunction={() => this.setFocusState(false)}
              role={scenarioRole}
              isFocused={isFocused}
              onRoleCountChangedFunction={this.updateRole}
            />
          </div>
          <div className={requiredRolesCellClass}>
            <Text className={textClass} data-testid="role-required">
              {scenarioRole.requiredValue !== null
                ? formatFloating(scenarioRole.requiredValue, 3)
                : '0'}
            </Text>
          </div>
          {hasFinancialAccess && (
            <div className={hourlyRateCellClass}>
              <Text className={hourlyRateTextClass}>
                {scenarioRole.role.costPerHour == null ? (
                  <Translate messageKey={'global.na'} />
                ) : (
                  formatCurrency(scenarioRole.role.costPerHour, currency)
                )}
              </Text>

              {this.deleteIcon()}
            </div>
          )}
        </div>
        {this.state.isConfirmDialogOpen && (
          <RemoveRoleConfirmationDialog
            onConfirmHandler={this.onConfirm}
            onDenyHandler={this.onDeny}
            usedInitiativeCount={scenarioRole.usedInitiativeCount}
          />
        )}
      </React.Fragment>
    )
  }

  private setFocusState = (isInputFocus) => {
    this.setState({
      isInputFocus,
    })
  }
  private deleteIcon = () => {
    return (
      !this.props.isViewMode && (
        <button
          onBlur={() => this.setFocusState(false)}
          onFocus={() => this.setFocusState(true)}
          data-testid="role-delete"
          className={`${deleteButtonClass} role-delete`}
          onClick={this.deleteRole}
        >
          <DeleteIcon />
        </button>
      )
    )
  }

  private updateRole = (count: number | null) => {
    const {
      scenarioRole: {availableValue},
    } = this.props
    if (availableValue !== count) {
      const {planStartDate, planDuration} = this.props
      const duration = planDuration * 12
      const distributionCount: number = count
        ? this.props.useHoursForMeasurements
          ? count / duration
          : count
        : 0

      this.props.updateRolesFunction([
        {
          ...this.props.scenarioRole,
          availableValue: count,
          availablePerPeriod: generateRoleDistribution(
            planStartDate,
            planDuration,
            distributionCount
          ),
          changes: [],
        },
      ])
    }
  }

  private deleteRole = () => {
    if (this.props.scenarioRole.usedInitiativeCount > 0) {
      this.setState({
        isConfirmDialogOpen: true,
      })
    } else {
      const deletedRoleIds: Set<string> = new Set()
      deletedRoleIds.add(this.props.scenarioRole.role.id)

      this.props.deleteRoleFunction(deletedRoleIds, this.props.usePeopleCostOnConflictCalculation)
    }
  }

  private onConfirm = () => {
    const deletedRoleIds: Set<string> = new Set()
    deletedRoleIds.add(this.props.scenarioRole.role.id)

    this.setState({
      isConfirmDialogOpen: false,
    })
    this.props.deleteRoleFunction(deletedRoleIds, this.props.usePeopleCostOnConflictCalculation)
  }

  private onDeny = () => {
    this.setState({
      isConfirmDialogOpen: false,
    })
  }
}

const mapStateToProps = (state): Partial<IHeaderRoleRowComponentProps> => ({
  currency: currencySelector(state),
  planDurationInMonths: currentPlanSelector(state)!.duration * 12,
  hasFinancialAccess: !financialAccessDeniedSelector(state),
  isViewMode: viewAccessSelector(state),
  usePeopleCostOnConflictCalculation: peopleCostOnConflictCalculationSelector(state),
  planStartDate: currentPlanStartDateSelector(state),
  planDuration: planDurationSelector(state),
  useHoursForMeasurements: hoursForMeasurementsSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  updateRolesFunction: (scenarioRoles) => dispatch(updateScenarioRoles(scenarioRoles)),
  deleteRoleFunction: (deletedRoleIds, usePeopleCostOnConflictCalculation) =>
    dispatch(deleteScenarioRole(deletedRoleIds, usePeopleCostOnConflictCalculation)),
})

export const HeaderRoleRowComponent = connect(mapStateToProps, mapDispatchToProps)(HeaderRoleRow)

// TODO: Localization: initiative and initiatives in confirm dialog
