import {Moment} from 'moment'
import {
  convertInitiativesData,
  copyInitiative as getInitiativeCopy,
  deserializeInitiative,
  getHourMeasurement,
  updateScenarioPublishedInitiatives,
} from '../../shared/helpers'
import {
  IInitiative,
  IPublishedInitiatives,
  IScenario,
  IScenarioBudgets,
  IScenarioRole,
  IUnpublishedInitiativeObj,
} from '../../shared/models'
import {ProjectService, PublishService} from '../../shared/services'
import {
  currentPlanStartDateSelector,
  deselectAllInitiatives,
  deselectInitiative,
  planDurationSelector,
  resetPublishedScenario,
  updateScenarioInitiativesRefobjects,
} from '../plan'
import {
  ADD_INITIATIVE,
  ADD_SCENARIO_ROLE,
  CHANGE_CURRENT_SCENARIO,
  CHANGE_LAST_SCENARIO_AFTER_COPY,
  CLEAR_OLD_HIGHLIGHTED_COLUMN_INDEX,
  COPY_INITIATIVES,
  DELETE_INITIATIVE,
  DELETE_INITIATIVES,
  DELETE_SCENARIO_ROLE,
  FINALIZE_BULK_DRAG,
  HIDE_INITIATIVES,
  IMPORT_INITIATIVES,
  IMPORT_INITIATIVES_FAIL,
  MARK_DRAGGABLE_INITIATIVES,
  PRIORITIZE_INITIATIVES,
  RECALCULATE_BUDGET_CONFLICTS,
  REMOVE_INITIATIVES_FROM_ORDER,
  REORDER_INITIATIVES,
  RESOLVE_CONFLICTS,
  SET_HIGHLIGHTED_COLUMN_INDEX,
  SET_IMPORTED_INITIATIVES_IDS,
  SET_INITIATIVE_ROW_TRANSITION,
  SET_UNSAVED_CHANGES_DIALOG_VISIBILITY,
  SHOW_INITIATIVES,
  SHRINK_INITIATIVES,
  TOGGLE_CONFLICTS,
  UNSHRINK_INITIATIVES,
  UPDATE_CURRENT_SCENARIO_ACCORDING_PLAN_STARTDATE_AND_DURATION,
  UPDATE_INITIATIVE,
  UPDATE_INITIATIVE_AFTER_DRAG_DROP,
  UPDATE_INITIATIVE_AND_RECALCULATE_CONFLICTS,
  UPDATE_INITIATIVE_DATES,
  UPDATE_INITIATIVE_NAME,
  UPDATE_SCENARIO_BUDGET,
  UPDATE_SCENARIO_ROLE,
} from './actions'
import {toast} from '@phoenix/all'
import {currentScenarioInitiativesSelector} from './selectors/currentScenarioInitiativesSelector'
import {selectedInitiativesSelector} from '../plan/selectors/selectedInitiativesSelector'
import {IPlanState} from '../plan/IPlanState'
import {currentScenarioSelector} from './selectors/currentScenarioSelector'
import {changeCurrentInitiative, scrollToInitiatives} from '../initiative'
import {peopleCostOnConflictCalculationSelector} from '../plan/selectors/peopleCostOnConflictCalculationSelector'
import {draggableInitiativesSelector} from './selectors/draggableInitiativesSelector'
import {draggableInitiativeIdSelector} from './selectors/draggableInitiativeIdSelector'
import {INITIATIVE_ANIMATION_DURATION} from '../../shared/constants/Animations'
import {financialAccessDeniedSelector} from '../settings'
import {hoursForMeasurementsSelector} from '../plan/selectors/hoursForMeasurementsSelector'
import {translate} from '../../shared/utilities/TranslateHelper'

const projectService = new ProjectService()
const publishService = new PublishService()

export function changeCurrentScenario(
  scenario: IScenario | null,
  lastScenario: IScenario | null = null,
  usePeopleCostOnConflictCalculation = true
) {
  return function (dispatch, getState) {
    window.location.hash = scenario !== null ? scenario.id : 'compare'
    const hasFinancialAccess = !financialAccessDeniedSelector(getState())

    return dispatch({
      type: CHANGE_CURRENT_SCENARIO,
      scenario,
      lastScenario,
      usePeopleCostOnConflictCalculation,
      hasFinancialAccess,
    })
  }
}

const showMessageForImportedProjects = (
  requestedProjectsCount: number,
  importedProjectsCount: number
) => {
  if (importedProjectsCount === 0) {
    toast.error(translate('import.projects.failed'))
  } else if (requestedProjectsCount === importedProjectsCount) {
    if (requestedProjectsCount === 1) {
      toast.success(translate('import.projects.success.one'))
    } else {
      toast.success(translate('import.projects.success.all', [importedProjectsCount]))
    }
  } else {
    toast.success(
      translate('import.projects.success', [importedProjectsCount, requestedProjectsCount])
    )
  }
}

export function importInitiatives(
  projectIDs: string[],
  planStartDate: Moment,
  duration: number,
  usePeopleCostOnConflictCalculation: boolean
) {
  return function (dispatch, getState) {
    return projectService
      .importProjects({
        projectIDs,
        planStartDate,
        duration,
      })
      .then(
        (initiatives: IInitiative[]) => {
          const state = getState()
          const hasFinancialAccess = !financialAccessDeniedSelector(state)
          const useHoursForMeasurements = hoursForMeasurementsSelector(state)
          const ids: string[] = []
          let importedInitiatives = initiatives.map((initiative, index) => {
            ids.push(initiative.id)
            return deserializeInitiative(
              initiative,
              index + 1,
              usePeopleCostOnConflictCalculation,
              hasFinancialAccess
            )
          })

          if (useHoursForMeasurements) {
            const measurement = getHourMeasurement()
            importedInitiatives = convertInitiativesData(
              importedInitiatives,
              measurement,
              usePeopleCostOnConflictCalculation,
              hasFinancialAccess
            )
          }

          showMessageForImportedProjects(projectIDs.length, initiatives.length)

          dispatch(setImportedInitiativesIds(ids))
          dispatch(loadInitiatives(importedInitiatives, usePeopleCostOnConflictCalculation))
          return importedInitiatives
        },
        (error) => {
          return dispatch(loadInitiativesFail(error))
        }
      )
  }
}

export function publishInitiatives(
  scenario: IScenario,
  initiativesID: string[],
  shouldUpdateCurrentScenario: boolean,
  usePeopleCostOnConflictCalculation
) {
  return function (dispatch) {
    return publishService
      .publishInitiatives(scenario.id, initiativesID)
      .then((publishedInitiatives: IPublishedInitiatives) => {
        const selectedInitiativesLength = initiativesID.length
        const publishedInitiativesLength = publishedInitiatives.publishedObjects.length

        if (publishedInitiativesLength) {
          updatePublishedScenario(
            publishedInitiatives,
            shouldUpdateCurrentScenario,
            scenario,
            dispatch,
            usePeopleCostOnConflictCalculation
          )
        }

        const successMessage = getPublishSuccessMessages(
          publishedInitiatives,
          publishedInitiativesLength,
          selectedInitiativesLength
        )
        return {
          successMessage,
          publishedInitiatives,
        }
      })
      .catch((unpublishedObjects: IUnpublishedInitiativeObj[]) => {
        const selectedInitiativesLength = initiativesID.length
        if (unpublishedObjects.length === 1 && selectedInitiativesLength === 1) {
          if (unpublishedObjects.find((item) => item.accessDenied)) {
            toast.error(translate('sp.publish.initiative.permission.failed'))
          } else {
            toast.error(translate('sp.publish.initiative.failed'))
          }
        } else {
          if (unpublishedObjects.find((item) => item.accessDenied)) {
            toast.error(translate('sp.publish.initiatives.permission.failed'))
          }
        }
        throw ''
      })
  }
}

function getPublishSuccessMessages(
  publishedInitiatives: IPublishedInitiatives,
  publishedInitiativesLength: number,
  selectedInitiativesLength: number
): string {
  if (publishedInitiativesLength && selectedInitiativesLength === 1) {
    return translate('sp.publish.initiative.success')
  } else if (selectedInitiativesLength === publishedInitiativesLength) {
    return translate('sp.publish.initiatives.all.success', [publishedInitiativesLength])
  } else if (publishedInitiativesLength !== 0) {
    return translate('sp.publish.initiatives.success', [
      publishedInitiativesLength,
      selectedInitiativesLength,
    ])
  } else {
    throw publishedInitiatives.unpublishedObjects
  }
}

function updatePublishedScenario(
  publishedInitiatives: IPublishedInitiatives,
  shouldUpdateCurrentScenario: boolean,
  scenario: IScenario,
  dispatch: any,
  usePeopleCostOnConflictCalculation: boolean
): void {
  const publishedInitiativesMap = new Map()
  for (const initiative of publishedInitiatives.publishedObjects) {
    publishedInitiativesMap.set(initiative.id, initiative)
  }

  dispatch(updateScenarioInitiativesRefobjects(publishedInitiativesMap, scenario.id))

  if (shouldUpdateCurrentScenario) {
    const scenarioAfterUpdates = updateScenarioPublishedInitiatives(
      scenario,
      publishedInitiativesMap,
      scenario.id
    )
    dispatch(changeCurrentScenario(scenarioAfterUpdates, null, usePeopleCostOnConflictCalculation))
  }
}

export function addInitiativeInternal(initiative: IInitiative) {
  return {
    type: ADD_INITIATIVE,
    initiative,
  }
}

export const addInitiative = (initiative: IInitiative) => (dispatch) => {
  dispatch(addInitiativeInternal(initiative))
  dispatch(scrollToInitiatives([initiative]))
}

const showMessageForCopiedInitiatives = (copiedInitiativesCount: number) => {
  if (copiedInitiativesCount === 1) {
    toast.success(translate('sp.initiative.copy.success.one'))
  } else {
    toast.success(translate('sp.initiative.copy.success.all', [copiedInitiativesCount]))
  }
}

export function copyInitiativesInternal(
  initiatives: IInitiative[],
  placeNextToInitiative: IInitiative,
  usePeopleCostOnConflictCalculation: boolean
) {
  return (dispatch, getState) => {
    const state = getState()
    const hasFinancialAccess = !financialAccessDeniedSelector(state)
    const planStartDate = currentPlanStartDateSelector(state)
    const planDuration = planDurationSelector(state)
    const useHoursForMeasurements = hoursForMeasurementsSelector(state)

    return dispatch({
      type: COPY_INITIATIVES,
      initiatives,
      placeNextToInitiative,
      usePeopleCostOnConflictCalculation,
      hasFinancialAccess,
      planInfo: {
        planStartDate,
        planDuration,
      },
      useHoursForMeasurements,
    })
  }
}

export const copyInitiative = (
  initiative: IInitiative,
  usePeopleCostOnConflictCalculation: boolean
) => async (dispatch, getState) => {
  const currentScenario = currentScenarioSelector(getState())
  const newInitiative = getInitiativeCopy(initiative)
  dispatch(copyInitiativesInternal([newInitiative], initiative, usePeopleCostOnConflictCalculation))
  dispatch(scrollToInitiatives([newInitiative]))
  dispatch(
    changeCurrentInitiative(newInitiative, currentScenario, usePeopleCostOnConflictCalculation)
  )
  dispatch(deselectAllInitiatives())
  await dispatch(playInsertAnimation([newInitiative.id], false))
  showMessageForCopiedInitiatives(1)
}

export const copySelectedInitiatives = (usePeopleCostOnConflictCalculation: boolean) => async (
  dispatch,
  getState
) => {
  const state = getState()
  const allInitiatives = currentScenarioInitiativesSelector(state)
  const selectedInitiativeIds = selectedInitiativesSelector(state)
  const selectedInitiatives = allInitiatives.filter(
    (initiative) => selectedInitiativeIds[initiative.id]
  )

  if (selectedInitiatives.length === 1) {
    return dispatch(copyInitiative(selectedInitiatives[0], usePeopleCostOnConflictCalculation))
  }

  const newInitiatives = selectedInitiatives.map(getInitiativeCopy)
  const lastSelectedInitiative = selectedInitiatives[selectedInitiatives.length - 1]
  dispatch(
    copyInitiativesInternal(
      newInitiatives,
      lastSelectedInitiative,
      usePeopleCostOnConflictCalculation
    )
  )
  dispatch(scrollToInitiatives([newInitiatives[0], newInitiatives[newInitiatives.length - 1]]))
  dispatch(deselectAllInitiatives())
  showMessageForCopiedInitiatives(newInitiatives.length)
  await dispatch(
    playInsertAnimation(
      newInitiatives.map((init) => init.id),
      true
    )
  )
}

export function prioritizeInitiativesInternal(
  initiatives: IInitiative[],
  placeAfterPriority: number,
  usePeopleCostOnConflictCalculation: boolean
) {
  return function (dispatch, getState) {
    const hasFinancialAccess = !financialAccessDeniedSelector(getState())

    return dispatch({
      type: PRIORITIZE_INITIATIVES,
      initiatives,
      placeAfterPriority,
      usePeopleCostOnConflictCalculation,
      hasFinancialAccess,
    })
  }
}

export const prioritizeSelectedInitiatives = (
  placeAfterPriority: number,
  usePeopleCostOnConflictCalculation: boolean
) => (dispatch, getState) => {
  const state = getState()
  const allInitiatives = currentScenarioInitiativesSelector(state)
  const selectedInitiativeIds = selectedInitiativesSelector(state)
  const selectedInitiatives = allInitiatives.filter(
    (initiative) => selectedInitiativeIds[initiative.id]
  )

  dispatch(
    prioritizeInitiativesInternal(
      selectedInitiatives,
      placeAfterPriority,
      usePeopleCostOnConflictCalculation
    )
  )
  dispatch(
    scrollToInitiatives([
      selectedInitiatives[0],
      selectedInitiatives[selectedInitiatives.length - 1],
    ])
  )
  dispatch(setImportedInitiativesIds(selectedInitiatives.map((init) => init.id)))
}

export function deleteInitiativeInternal(
  initiative: IInitiative,
  usePeopleCostOnConflictCalculation: boolean
) {
  return function (dispatch, getState) {
    const state = getState()
    const hasFinancialAccess = !financialAccessDeniedSelector(state)
    const useHoursForMeasurements = hoursForMeasurementsSelector(state)

    return dispatch({
      type: DELETE_INITIATIVE,
      initiative,
      usePeopleCostOnConflictCalculation,
      hasFinancialAccess,
      useHoursForMeasurements,
    })
  }
}

export const deleteInitiative = (
  initiative: IInitiative,
  usePeopleCostOnConflictCalculation: boolean
) => (dispatch) => {
  dispatch(deleteInitiativeInternal(initiative, usePeopleCostOnConflictCalculation))
}

export const deleteInitiativeWithAnimation = (
  initiative: IInitiative,
  usePeopleCostOnConflictCalculation: boolean
) => async (dispatch) => {
  await dispatch(playRemoveAnimation([initiative.id]))
  dispatch(deleteInitiativeInternal(initiative, usePeopleCostOnConflictCalculation))
  dispatch(deselectInitiative(initiative.id))
}

export function deleteInitiatives({
  selectedInitiatives,
  selectedInitiativeIds,
  publishedInitiativeCount,
  usePeopleCostOnConflictCalculation,
}: {
  selectedInitiatives: IInitiative[]
  selectedInitiativeIds: IPlanState['selectedInitiatives']
  publishedInitiativeCount: number
  usePeopleCostOnConflictCalculation: boolean
}) {
  return function (dispatch, getState) {
    const state = getState()
    const hasFinancialAccess = !financialAccessDeniedSelector(state)
    const useHoursForMeasurements = hoursForMeasurementsSelector(state)

    return dispatch({
      type: DELETE_INITIATIVES,
      selectedInitiatives,
      selectedInitiativeIds,
      publishedInitiativeCount,
      usePeopleCostOnConflictCalculation,
      hasFinancialAccess,
      useHoursForMeasurements,
    })
  }
}

export const confirmBulkInitiativeDeletion = () => async (dispatch, getState) => {
  const state = getState()

  const allInitiatives = currentScenarioInitiativesSelector(state)
  const selectedInitiativeIds = selectedInitiativesSelector(state)
  const usePeopleCostOnConflictCalculation = peopleCostOnConflictCalculationSelector(state)

  const selectedInitiatives: IInitiative[] = []

  let publishedInitiativeCount = 0

  for (const initiative of allInitiatives) {
    if (selectedInitiativeIds[initiative.id]) {
      selectedInitiatives.push(initiative)

      if (initiative.lastPublishedDate !== null) {
        publishedInitiativeCount++
      }
    }
  }

  await dispatch(playRemoveAnimation(selectedInitiatives.map((initiative) => initiative.id)))

  dispatch(
    deleteInitiatives({
      selectedInitiatives,
      selectedInitiativeIds,
      publishedInitiativeCount,
      usePeopleCostOnConflictCalculation,
    })
  )

  const currentScenario = currentScenarioSelector(getState())

  if (currentScenario && currentScenario.publishedInitiativesCount === 0) {
    dispatch(resetPublishedScenario())
  }

  dispatch(deselectAllInitiatives())
}

export function loadInitiatives(
  initiatives: IInitiative[],
  usePeopleCostOnConflictCalculation: boolean
) {
  return function (dispatch, getState) {
    const state = getState()
    const planStartDate = currentPlanStartDateSelector(state)
    const planDuration = planDurationSelector(state)
    const hasFinancialAccess = !financialAccessDeniedSelector(state)
    const useHoursForMeasurements = hoursForMeasurementsSelector(state)

    return dispatch({
      type: IMPORT_INITIATIVES,
      initiatives,
      usePeopleCostOnConflictCalculation,
      hasFinancialAccess,
      planInfo: {
        planStartDate,
        planDuration,
      },
      useHoursForMeasurements,
    })
  }
}

export function setImportedInitiativesIds(importedInitiativesIds: string[]) {
  return {
    type: SET_IMPORTED_INITIATIVES_IDS,
    importedInitiativesIds,
  }
}

function loadInitiativesFail(error) {
  return {
    type: IMPORT_INITIATIVES_FAIL,
    error,
  }
}

export function updateInitiative(initiative: IInitiative, usePeopleCostOnConflictCalculation) {
  return function (dispatch, getState) {
    const state = getState()
    const hasFinancialAccess = !financialAccessDeniedSelector(state)
    const planStartDate = currentPlanStartDateSelector(state)
    const planDuration = planDurationSelector(state)
    const useHoursForMeasurements = hoursForMeasurementsSelector(state)

    return dispatch({
      type: UPDATE_INITIATIVE,
      initiative,
      usePeopleCostOnConflictCalculation,
      hasFinancialAccess,
      planInfo: {
        planStartDate,
        planDuration,
      },
      useHoursForMeasurements,
    })
  }
}

export function updateInitiativeName(initiative: IInitiative) {
  return {
    type: UPDATE_INITIATIVE_NAME,
    initiative,
  }
}

export function updateInitiativeDates(initiative: IInitiative) {
  return function (dispatch) {
    return dispatch({
      type: UPDATE_INITIATIVE_DATES,
      initiative,
    })
  }
}

export function updateInitiativeAndRecalculateConflicts(
  initiative: IInitiative,
  usePeopleCostOnConflictCalculation: boolean
) {
  return function (dispatch, getState) {
    const state = getState()
    const hasFinancialAccess = !financialAccessDeniedSelector(state)
    const useHoursForMeasurements = hoursForMeasurementsSelector(state)

    return dispatch({
      type: UPDATE_INITIATIVE_AND_RECALCULATE_CONFLICTS,
      initiative,
      usePeopleCostOnConflictCalculation,
      hasFinancialAccess,
      useHoursForMeasurements,
    })
  }
}

export function updateInitiativeAfterDragDrop(
  initiative: IInitiative,
  usePeopleCostOnConflictCalculation: boolean
) {
  return function (dispatch, getState) {
    const state = getState()
    const useHoursForMeasurements = hoursForMeasurementsSelector(state)

    return dispatch({
      type: UPDATE_INITIATIVE_AFTER_DRAG_DROP,
      initiative,
      usePeopleCostOnConflictCalculation,
      useHoursForMeasurements,
    })
  }
}

export function reorderInitiatives(
  initiatives: IInitiative[],
  usePeopleCostOnConflictCalculation: boolean
) {
  return function (dispatch, getState) {
    const hasFinancialAccess = !financialAccessDeniedSelector(getState())

    return dispatch({
      type: REORDER_INITIATIVES,
      initiatives,
      usePeopleCostOnConflictCalculation,
      hasFinancialAccess,
    })
  }
}

export function updateCurrentScenarioAccordingPlanStatdateAndDuration(
  changes: Record<string, unknown>
) {
  return function (dispatch, getState) {
    const hasFinancialAccess = !financialAccessDeniedSelector(getState())

    return dispatch({
      type: UPDATE_CURRENT_SCENARIO_ACCORDING_PLAN_STARTDATE_AND_DURATION,
      changes,
      hasFinancialAccess,
    })
  }
}

export function toggleHideConflict(
  showConflicts: boolean,
  usePeopleCostOnConflictCalculation: boolean
) {
  return function (dispatch, getState) {
    const hasFinancialAccess = !financialAccessDeniedSelector(getState())

    return dispatch({
      type: TOGGLE_CONFLICTS,
      showConflicts,
      usePeopleCostOnConflictCalculation,
      hasFinancialAccess,
    })
  }
}

export function changeLastScenarioAfterCopy(scenario: IScenario) {
  return {
    type: CHANGE_LAST_SCENARIO_AFTER_COPY,
    scenario,
  }
}

export function addScenarioRoles(roles: IScenarioRole[], fromRoleDistribution = false) {
  return {
    type: ADD_SCENARIO_ROLE,
    roles,
    fromRoleDistribution,
  }
}

export function updateScenarioRoles(
  scenarioRoles: IScenarioRole[],
  isFromDistributionDialog = false
) {
  return {
    type: UPDATE_SCENARIO_ROLE,
    scenarioRoles,
    isFromDistributionDialog,
  }
}

export function deleteScenarioRole(
  deletedRoleIds: Set<string>,
  usePeopleCostOnConflictCalculation: boolean
) {
  return function (dispatch, getState) {
    const state = getState()
    const hasFinancialAccess = !financialAccessDeniedSelector(state)
    const useHoursForMeasurements = hoursForMeasurementsSelector(state)

    return dispatch({
      type: DELETE_SCENARIO_ROLE,
      deletedRoleIds,
      usePeopleCostOnConflictCalculation,
      hasFinancialAccess,
      useHoursForMeasurements,
    })
  }
}

export function updateScenarioBudget(
  budget: IScenarioBudgets,
  usePeopleCostOnConflictCalculation: boolean
) {
  return {
    type: UPDATE_SCENARIO_BUDGET,
    budget,
    usePeopleCostOnConflictCalculation,
  }
}

export function resolveConflicts(
  initiative: IInitiative,
  resolvedRoles: IScenarioRole[],
  budgets: IScenarioBudgets | null,
  usePeopleCostOnConflictCalculation
) {
  return function (dispatch, getState) {
    const state = getState()
    const hasFinancialAccess = !financialAccessDeniedSelector(state)
    const planStartDate = currentPlanStartDateSelector(state)
    const planDuration = planDurationSelector(state)
    const useHoursForMeasurements = hoursForMeasurementsSelector(state)

    return dispatch({
      type: RESOLVE_CONFLICTS,
      initiative,
      resolvedRoles,
      budgets,
      usePeopleCostOnConflictCalculation,
      hasFinancialAccess,
      planInfo: {
        planStartDate,
        planDuration,
      },
      useHoursForMeasurements,
    })
  }
}

export function recalculateScenarioBudgetConflicts(
  showConflicts: boolean,
  usePeopleCostOnConflictCalculation: boolean
) {
  return function (dispatch, getState) {
    const hasFinancialAccess = !financialAccessDeniedSelector(getState())

    return dispatch({
      type: RECALCULATE_BUDGET_CONFLICTS,
      showConflicts,
      usePeopleCostOnConflictCalculation,
      hasFinancialAccess,
    })
  }
}

export function showInitiativeDeleteConfirmationDialog(deleteMultiple) {
  return function (dispatch, getState) {
    const hasFinancialAccess = !financialAccessDeniedSelector(getState())

    return dispatch({
      type: SET_UNSAVED_CHANGES_DIALOG_VISIBILITY,
      isOpen: true,
      deleteMultiple,
      hasFinancialAccess,
    })
  }
}

export function hideInitiativeDeleteConfirmationDialog() {
  return {
    type: SET_UNSAVED_CHANGES_DIALOG_VISIBILITY,
    isOpen: false,
  }
}

export function setHighlightedColumnIndex(index: number) {
  return {
    type: SET_HIGHLIGHTED_COLUMN_INDEX,
    index,
  }
}

export function clearOldHighlightedColumnIndex(index: number) {
  return {
    type: CLEAR_OLD_HIGHLIGHTED_COLUMN_INDEX,
    index,
  }
}

export function removeInitiativesFromOrder(initiativeIds: string[]) {
  return {
    type: REMOVE_INITIATIVES_FROM_ORDER,
    initiativeIds,
  }
}

export function findEarliestInitiativeIndex(scenarioList: IInitiative[], searchList: string[]) {
  return scenarioList.findIndex((initiative) => searchList.includes(initiative.id))
}

export function hideInitiatives(initiatives: string[]) {
  return {
    type: HIDE_INITIATIVES,
    initiatives: initiatives,
  }
}

export function showInitiatives(initiatives: string[]) {
  return {
    type: SHOW_INITIATIVES,
    initiatives: initiatives,
  }
}

export function shrinkInitiatives(initiatives: string[]) {
  return {
    type: SHRINK_INITIATIVES,
    initiatives: initiatives,
  }
}

export function unshrinkInitiatives(initiatives: string[]) {
  return {
    type: UNSHRINK_INITIATIVES,
    initiatives: initiatives,
  }
}

export const playRemoveAnimation = (initiatives: string[]) => async (dispatch) => {
  dispatch(removeInitiativesFromOrder(initiatives))
  dispatch(setInitiativeRowTransition(true))
  await new Promise((resolve) =>
    setTimeout(() => {
      dispatch(hideInitiatives(initiatives))
      dispatch(shrinkInitiatives(initiatives))
      resolve()
    })
  )
  await new Promise((resolve) =>
    setTimeout(() => {
      dispatch(setInitiativeRowTransition(false))
      resolve()
    }, INITIATIVE_ANIMATION_DURATION)
  )
}

export const playInsertAnimation = (initiatives: string[], highlight: boolean) => async (
  dispatch
) => {
  dispatch(hideInitiatives(initiatives))
  dispatch(shrinkInitiatives(initiatives))
  await new Promise((resolve) =>
    setTimeout(() => {
      dispatch(setInitiativeRowTransition(true))
      resolve()
    })
  )
  await new Promise((resolve) =>
    setTimeout(() => {
      dispatch(showInitiatives(initiatives))
      dispatch(unshrinkInitiatives(initiatives))
      resolve()
    })
  )

  if (highlight) {
    new Promise((resolve) =>
      setTimeout(() => {
        dispatch(setImportedInitiativesIds(initiatives))
        resolve()
      }, INITIATIVE_ANIMATION_DURATION / 3)
    )
  }

  await new Promise((resolve) =>
    setTimeout(() => {
      dispatch(setInitiativeRowTransition(false))
      resolve()
    }, INITIATIVE_ANIMATION_DURATION)
  )
}

export function setInitiativeRowTransition(status: boolean) {
  return {
    type: SET_INITIATIVE_ROW_TRANSITION,
    status: status,
  }
}

export function markDraggableInitiatives(
  draggableInitiativeId: string,
  draggableInitiatives: IInitiative[],
  filteredInitiatives: IInitiative[]
) {
  return {
    type: MARK_DRAGGABLE_INITIATIVES,
    draggableInitiativeId,
    draggableInitiatives,
    filteredInitiatives,
  }
}

export const startMultipleInitiativeDragging = (draggableInitiativeOrder) => (
  dispatch,
  getState
) => {
  const state = getState()

  const selectedInitiativeIds = selectedInitiativesSelector(state)

  const allInitiatives = currentScenarioInitiativesSelector(state)

  const draggableInitiatives: IInitiative[] = []

  let filteredInitiatives: IInitiative[] = []

  /*
                First, process all the initiatives which come before the draggable initiative.
              */

  let removedInitiativeCount = 0

  for (let i = 0; i < draggableInitiativeOrder - 1; i++) {
    const initiative = allInitiatives[i]

    if (selectedInitiativeIds[initiative.id]) {
      removedInitiativeCount++
      draggableInitiatives.push(initiative)
    } else {
      filteredInitiatives.push(initiative)
    }
  }

  const draggableInitiative = allInitiatives[draggableInitiativeOrder - 1]

  draggableInitiatives.push(draggableInitiative)

  /*
                Now, fill in the gaps which are caused by removing previous initiatives.
              */

  for (let i = draggableInitiativeOrder; i < allInitiatives.length; i++) {
    const initiative = allInitiatives[i]

    if (selectedInitiativeIds[initiative.id]) {
      draggableInitiatives.push(initiative)
    } else {
      if (
        removedInitiativeCount === 0 &&
        filteredInitiatives.length + 1 === draggableInitiativeOrder
      ) {
        filteredInitiatives.push(draggableInitiative)
      }
      filteredInitiatives.push(initiative)
      removedInitiativeCount--
    }
  }

  if (removedInitiativeCount >= 0 && filteredInitiatives.length + 1 === draggableInitiativeOrder) {
    filteredInitiatives.push(draggableInitiative)
  }

  filteredInitiatives = filteredInitiatives.map((initiative, index) => ({
    ...initiative,
    order: index + 1,
  }))

  dispatch(
    markDraggableInitiatives(draggableInitiative.id, draggableInitiatives, filteredInitiatives)
  )
}

export function finalizeMultipleInitiativeDrag(
  updatedInitiatives: IInitiative[],
  usePeopleCostOnConflictCalculation: boolean
) {
  return function (dispatch, getState) {
    const hasFinancialAccess = !financialAccessDeniedSelector(getState())

    return dispatch({
      type: FINALIZE_BULK_DRAG,
      updatedInitiatives,
      usePeopleCostOnConflictCalculation,
      hasFinancialAccess,
    })
  }
}

export const confirmMultipleInitiativeDragging = (
  dropIndex: number,
  usePeopleCostOnConflictCalculation: boolean
) => (dispatch, getState) => {
  const state = getState()

  const allInitiatives = currentScenarioInitiativesSelector(state)

  const draggableInitiatives = draggableInitiativesSelector(state)

  const draggableInitiativeId = draggableInitiativeIdSelector(state)

  let updatedInitiatives = [...allInitiatives].filter(
    (initiative) => initiative.id !== draggableInitiativeId
  )

  updatedInitiatives.splice(dropIndex, 0, ...draggableInitiatives)

  updatedInitiatives = updatedInitiatives.map((initiative, index) => ({
    ...initiative,
    order: index + 1,
  }))

  const draggableInitiativeIds = draggableInitiatives.map((initiative) => initiative.id)

  dispatch(finalizeMultipleInitiativeDrag(updatedInitiatives, usePeopleCostOnConflictCalculation))

  dispatch(setImportedInitiativesIds(draggableInitiativeIds))

  dispatch(deselectAllInitiatives())
}
