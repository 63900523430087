import {fetchData} from './api/ApiService'

export class ListService {
  deletePlans(ids: string[]) {
    return fetchData(`/plans`, {
      data: ids,
      method: 'DELETE',
    })
  }
}
