import {IColumns} from '../shared/types/columns'

export const LIST_ID = 'ESP_PROJECT_IMPORT_LIST'
export const PAGE_SIZES = [100, 250, 500]

export const metadata = {
  objCode: 'PROJ',
  isDashboard: null,
  detailObj: {objCode: null, id: null},
  isHierarchic: false,
  pagination: {
    pageCount: 1,
    pageSizes: [],
    pageNumber: 1,
    count: 0,
    pageSize: 2000,
    recordLimit: 2000,
  },
  compactMode: true,
  options: {
    objCode: 'PROJ',
    viewID: LIST_ID,
    listTileName: LIST_ID,
    isShortView: false,
  },
  listTileName: LIST_ID,
  currency: null,
  addMoreSingleMessageKey: 'proj.list.action.add',
  baseParams: {projectId: null},
  showContextMenu: null,
  objCodeMessageKey: 'project',
  sortInfo: [],
  showInlineAdd: false,
  rawValueTypes: {
    plannedStartDate: 'dateTime',
    actualCompletionDate: 'dateTime',
    projectedCompletionDate: 'dateTime',
    actualStartDate: 'dateTime',
    plannedCompletionDate: 'dateTime',
    projectedStartDate: 'dateTime',
  },
  emptyMessageKey: null,
  worksheetRefreshTimer: -1,
  useColumnWidths: false,
  contextMenu: {
    toolbarActionsRequirements: {},
    toolbarActions: [],
  },
  addMoreMessageKey: 'proj.list.action.add.more',
  baseCollapseLevel: 0,
  applyWindowScrolling: false,
  startOrderNum: null,
  queryMap: {},
  hidePageSize: false,
  viewID: LIST_ID,
  isShortView: false,
  isInModal: false,
  isReactListToolbarsEnabled: true,
}

export const columns = (): IColumns[] => {
  const columnList = [
    {
      defaultMinWidth: 54,
      display: '',
      reorderable: false,
      sortable: false,
      sortQuery: null,
      field: '',
      defaultMaxWidth: 54,
      width: 54,
    },
    {
      template: 'component.object.link',
      defaultMinWidth: 55,
      display: '',
      reorderable: false,
      resizable: false,
      sortable: true,
      sortQuery: 'name',
      field: 'name',
      defaultMaxWidth: 203,
      width: 200,
    },
    {
      defaultMinWidth: 140,
      display: '',
      index: 2,
      reorderable: false,
      sortable: true,
      sortQuery: 'portfolioPriority',
      field: 'portfolioPriority',
      defaultMaxWidth: 140,
      width: 140,
    },
    {
      template: 'component.object.link',
      defaultMinWidth: 55,
      display: '',
      reorderable: false,
      resizable: false,
      sortable: true,
      sortQuery: 'portfolio:name',
      field: 'portfolio:name',
      defaultMaxWidth: 203,
      width: 200,
    },
    {
      template: 'component.object.link',
      defaultMinWidth: 55,
      display: '',
      reorderable: false,
      resizable: false,
      sortable: true,
      sortQuery: 'program:name',
      field: 'program:name',
      defaultMaxWidth: 203,
      width: 200,
    },
    {
      template: 'component.object.link',
      defaultMinWidth: 55,
      display: '',
      reorderable: false,
      resizable: false,
      sortable: true,
      sortQuery: 'owner:name',
      field: 'owner:name',
      defaultMaxWidth: 203,
      width: 200,
    },
    {
      defaultMinWidth: 80,
      display: '',
      reorderable: false,
      resizable: false,
      sortable: true,
      sortQuery: 'status',
      field: 'status',
      defaultMaxWidth: 115,
      width: 100,
    },
    {
      template: 'component.percentcompletelistview',
      defaultMinWidth: 80,
      display: '',
      reorderable: false,
      resizable: false,
      sortable: true,
      sortQuery: 'percentComplete',
      templateOptions: {},
      field: 'percentComplete',
      defaultMaxWidth: 115,
      width: 100,
    },
    {
      defaultMinWidth: 80,
      display: '',
      reorderable: false,
      resizable: false,
      sortable: true,
      sortQuery: 'plannedStartDate',
      field: 'plannedStartDate',
      defaultMaxWidth: 115,
      width: 105,
    },
    {
      defaultMinWidth: 105,
      display: '',
      reorderable: false,
      resizable: false,
      sortable: true,
      sortQuery: 'plannedCompletionDate',
      field: 'plannedCompletionDate',
      defaultMaxWidth: 115,
      width: 105,
    },
  ] as IColumns[]

  return columnList.map((col, index) => {
    return {
      ...col,
      index,
    }
  })
}
