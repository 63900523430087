import forEach from 'lodash/forEach'
import size from 'lodash/size'
import {Moment} from 'moment'
import {IInitiative, IInitiativeConflicts, IScenarioBudgets, IScenarioRole} from '../../models'
import {IDistribution} from '../../models/initiative/IDistribution'
import {calculateBudgetConflicts} from './BudgetConflictHelper'
import {calculateSingleRoleConflicts} from './RoleConflictHelper'

// This function calculates all conflicts (all roles and budget) for entire timeline
// Note: when using this function  the conflicts should be resetted
export function calculateConflicts(
  scenarioRoles: IScenarioRole[],
  scenarioBudgets: IScenarioBudgets | null,
  initiatives: IInitiative[],
  usePeopleCostOnConflictCalculation: boolean,
  hasFinancialAccess: boolean
): Map<string, IInitiativeConflicts> {
  const conflicts: Map<string, IInitiativeConflicts> = new Map()

  processRoleConflicts(scenarioRoles, initiatives, conflicts)

  if (hasFinancialAccess) {
    processBudgetConflicts(
      scenarioBudgets!, // can't be null when hasFinancialAccess
      initiatives,
      conflicts,
      usePeopleCostOnConflictCalculation
    )
  }

  processPriorityConflicts(conflicts, initiatives)

  return conflicts
}

function processRoleConflicts(
  scenarioRoles: IScenarioRole[],
  initiatives: IInitiative[],
  conflicts: Map<string, IInitiativeConflicts>
) {
  for (const scenarioRole of scenarioRoles) {
    const roleConflicts = calculateSingleRoleConflicts(scenarioRole, initiatives)
    for (const [order, roleConflict] of roleConflicts.entries()) {
      if (roleConflict !== null) {
        if (conflicts.has(order)) {
          conflicts.get(order)!.roleConflicts.set(roleConflict.roleId, roleConflict)
        } else {
          conflicts.set(order, {
            roleConflicts: new Map([[roleConflict.roleId, roleConflict]]),
            budgetConflicts: null,
            isPriorityConflict: false,
          })
        }
      }
    }
  }
}

export const processBudgetConflicts = (
  scenarioBudgets: IScenarioBudgets,
  initiatives: IInitiative[],
  conflicts: Map<string, IInitiativeConflicts>,
  usePeopleCostOnConflictCalculation: boolean,
  startDate?: Moment,
  endDate?: Moment
): void => {
  const budgetConflicts = calculateBudgetConflicts(
    scenarioBudgets,
    initiatives,
    usePeopleCostOnConflictCalculation,
    startDate,
    endDate
  )
  for (const [order, budgetConflict] of budgetConflicts.entries()) {
    if (conflicts.has(order)) {
      conflicts.get(order)!.budgetConflicts = budgetConflict
    } else {
      conflicts.set(order, {
        roleConflicts: new Map(),
        budgetConflicts: budgetConflict,
        isPriorityConflict: false,
      })
    }
  }
}

function processPriorityConflicts(
  conflicts: Map<string, IInitiativeConflicts>,
  initiatives: IInitiative[]
) {
  const firstConflictedInitiativeOrder = Math.min(...[...conflicts.keys()].map((key) => +key))
  let i = firstConflictedInitiativeOrder + 1
  while (i <= initiatives.length) {
    if (!conflicts.has(i.toString())) {
      conflicts.set(i.toString(), {
        roleConflicts: new Map(),
        budgetConflicts: null,
        isPriorityConflict: true,
      })
    }
    i++
  }
}

// When using this function it should be considered that priority conflicts should be handled
// This function cannot handle them as it is working only on the subset of  conflicts

export function calculateConflictsSubset(
  scenarioRoles: IScenarioRole[],
  scenarioBudgets: IScenarioBudgets | null,
  initiatives: IInitiative[],
  roleIds: string[],
  isBudgetIncluded: boolean,
  usePeopleCostOnConflictCalculation: boolean,
  startDate?: Moment,
  endDate?: Moment
): Map<string, IInitiativeConflicts> {
  const conflicts: Map<string, IInitiativeConflicts> = new Map()

  if (roleIds.length === 0 && !isBudgetIncluded) {
    return conflicts
  }
  const roles = new Set(roleIds)
  processRoleConflictsSubset(
    scenarioRoles.filter((r) => roles.has(r.role.id)),
    initiatives,
    conflicts,
    startDate,
    endDate
  )

  if (isBudgetIncluded) {
    processBudgetConflicts(
      scenarioBudgets!, //when isBudgetIncluded is true the scenarioBudgets can't be null
      initiatives,
      conflicts,
      usePeopleCostOnConflictCalculation,
      startDate,
      endDate
    )
  }

  for (const [key, value] of conflicts) {
    if (value.roleConflicts.size === 0 && value.budgetConflicts === null) {
      conflicts.delete(key)
    }
  }

  return conflicts
}

export function processRoleConflictsSubset(
  scenarioRoles: IScenarioRole[],
  initiatives: IInitiative[],
  conflicts: Map<string, IInitiativeConflicts>,
  startDate?: Moment,
  endDate?: Moment
) {
  for (const scenarioRole of scenarioRoles) {
    const roleConflicts = calculateSingleRoleConflicts(
      scenarioRole,
      initiatives,
      startDate,
      endDate
    )
    for (const [order, roleConflict] of roleConflicts) {
      if (conflicts.has(order)) {
        if (roleConflict !== null) {
          conflicts.get(order)!.roleConflicts.set(roleConflict.roleId, roleConflict)
        } else {
          conflicts.get(order)!.roleConflicts.delete(scenarioRole.role.id)
        }
      } else {
        const conflict = {
          roleConflicts: new Map(),
          budgetConflicts: null,
          isPriorityConflict: false,
        }
        conflict!.roleConflicts.set(roleConflict.roleId, roleConflict)
        conflicts.set(order, conflict)
      }
    }
  }
}

export function filterInitiativesByPeriod(
  initiatives: IInitiative[],
  startDate: Moment,
  endDate: Moment
): IInitiative[] {
  return initiatives.filter((initiative) => {
    return (
      initiative.dates.startDate.isBetween(startDate, endDate, 'd', '[]') ||
      startDate.isBetween(initiative.dates.startDate, initiative.dates.endDate, 'd', '[]')
    )
  })
}

export function hasDistributionPositiveValue(distribution: IDistribution): boolean {
  let hasPositiveValue = false
  for (const key in distribution) {
    if (distribution[key] > 0) {
      hasPositiveValue = true
      break
    }
  }

  return hasPositiveValue
}

export function hasDistributionChangedValue(
  oldDistribution: IDistribution,
  newDistribution: IDistribution
): boolean {
  let isChanged = false
  if (size(newDistribution) !== size(oldDistribution)) {
    return true
  }

  forEach(newDistribution, (value, key) => {
    if (value !== oldDistribution[key]) {
      isChanged = true
      return
    }
  })

  return isChanged
}
