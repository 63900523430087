import FavoriteFilledIcon from 'phoenix-icons/dist/FavoriteFilledIcon.js'
import FavoriteIcon from 'phoenix-icons/dist/FavoriteIcon.js'
import {ClickableIcon, primary, secondary} from '@phoenix/all'
import {css} from 'emotion'
import * as React from 'react'
import {PopperComponent, TooltipContentComponent} from '../shared'
import {translate} from '../../shared/utilities/TranslateHelper'
import {addFavorite, removeFavorite, useFavorites} from '@wf-mfe/navigation'

interface IFavoriteActionComponentProps {
  objCode: string
  objID: string
  objName: string
  disabled: boolean
}

const addRemoveFavoriteButtonClassName = css`
  font-size: 0;
  margin: 0 9px 0 8px;
  & button {
    &:hover {
      border-radius: 3px;
      background-color: ${primary.gray(100)};
    }

    &:focus {
      border-radius: 3px;
      box-shadow: 0 0 0 3px ${secondary.lightblue(200)} !important;
      outline: none !important;

      &:before {
        content: none;
      }
    }
  }
`

export const FavoriteComponent: React.FunctionComponent<IFavoriteActionComponentProps> = (
  props
) => {
  const {objID, objCode, objName, disabled} = props
  const [isLoading, setIsLoading] = React.useState(false)
  const {isCurrentPageFavorited} = useFavorites()

  const onStarIconClick = () => {
    setIsLoading(true)
    const actionFunction = isCurrentPageFavorited ? removeFavorite : addFavorite
    actionFunction({
      objCode: objCode,
      objID: objID,
      name: objName,
    }).finally(() => setIsLoading(false))
  }

  const Icon = isCurrentPageFavorited ? FavoriteFilledIcon : FavoriteIcon
  const dataChecked = {}
  if (isCurrentPageFavorited) {
    dataChecked['data-checked'] = true
  }
  const tooltipMessage = disabled
    ? translate('sp.favorite.action.disabled')
    : !isCurrentPageFavorited
    ? translate('favorite.action.add')
    : translate('favorite.action.delete')
  return (
    <div className={addRemoveFavoriteButtonClassName}>
      <PopperComponent
        reference={
          <ClickableIcon
            testID="add-remove-favorite"
            {...dataChecked}
            label={tooltipMessage}
            icon={Icon}
            color={primary.gray(600)}
            width={24}
            height={24}
            onClick={onStarIconClick}
            title={''}
            disabled={disabled || isLoading}
          />
        }
        content={<TooltipContentComponent text={tooltipMessage} />}
        isInsidePortal={false}
        showOnOver={true}
        placement="top"
      />
    </div>
  )
}
