import {css} from 'emotion'
import * as React from 'react'
import {connect} from 'react-redux'

import {Input} from '@phoenix/all'
import {showConflictsSelector} from '../../../../data'
import {updateCurrentInitiative} from '../../../../data/initiative'
import {currentInitiativeSelector} from '../../../../data/initiative'
import {financialViewAccessSelector} from '../../../../data/settings'
import {IInitiativeNetValue} from '../../../../shared/models/initiative'
import {isValidIntegerNumber} from '../../../../shared/utilities'
import {MoneyFieldViewComponent} from '../../../shared'
import {peopleCostOnConflictCalculationSelector} from '../../../../data/plan/selectors/peopleCostOnConflictCalculationSelector'
import {Translate} from '../../../shared/Translate'

interface IPlannedBenefitComponentProps {
  netValue: IInitiativeNetValue
  isViewMode: boolean
  updateCurrentInitiativeFunction: (
    changes: any,
    showConflicts: boolean,
    usePeopleCostOnConflictCalculation: boolean
  ) => void
  showConflicts: boolean
  usePeopleCostOnConflictCalculation: boolean
}

const netValueContainerClass = css`
  width: 198px;
`

export const PlannedBenefit: React.FunctionComponent<IPlannedBenefitComponentProps> = React.memo(
  (props) => {
    const plannedBenefitRef: React.RefObject<HTMLInputElement> = React.useRef(null)
    const [plannedBenefit, setPlannedBenefit] = React.useState(props.netValue.plannedBenefit)

    const changePlannedBenefit = ({target}) => {
      const value = target.value
      if (value === '' || isValidIntegerNumber(value)) {
        setPlannedBenefit(value)
      }
    }

    const blurPlannedBenefit = ({target}) => {
      const value = target.value
      if (value === '' || isValidIntegerNumber(value)) {
        const netValue = {
          ...props.netValue,
          plannedBenefit: value ? parseInt(value, 10) : 0,
        }
        setPlannedBenefit(netValue.plannedBenefit)
        props.updateCurrentInitiativeFunction(
          {netValue},
          props.showConflicts,
          props.usePeopleCostOnConflictCalculation
        )
      }
    }

    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        plannedBenefitRef.current!.blur()
      }
    }

    React.useEffect(() => {
      setPlannedBenefit(props.netValue.plannedBenefit)
    }, [props.netValue.plannedBenefit])

    return (
      <div className={netValueContainerClass}>
        {props.isViewMode ? (
          <MoneyFieldViewComponent cost={plannedBenefit} labelMessageKey={'sidebar.benefit'} />
        ) : (
          <Input
            onKeyDown={handleKeyDown}
            forwardedRef={plannedBenefitRef}
            label={<Translate messageKey={'sidebar.benefit'} />}
            hideMaxLengthInfo={true}
            autoComplete="off"
            maxLength="14"
            testID="planned-benefit"
            value={plannedBenefit === null || plannedBenefit === undefined ? '' : plannedBenefit}
            name="planned-benefit"
            onChange={changePlannedBenefit}
            onBlur={blurPlannedBenefit}
          />
        )}
      </div>
    )
  }
)

PlannedBenefit.displayName = 'PlannedBenefit'

const mapDispatchToProps = (dispatch) => ({
  updateCurrentInitiativeFunction: (changes, showConflicts, usePeopleCostOnConflictCalculation) =>
    dispatch(updateCurrentInitiative(changes, showConflicts, usePeopleCostOnConflictCalculation)),
})

const mapStateToProps = (state) => ({
  netValue: currentInitiativeSelector(state)!.netValue,
  isViewMode: financialViewAccessSelector(state),
  showConflicts: showConflictsSelector(state),
  usePeopleCostOnConflictCalculation: peopleCostOnConflictCalculationSelector(state),
})

export const PlannedBenefitFormComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlannedBenefit)
