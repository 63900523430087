import * as React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {css} from 'emotion'
import {Button, Text} from '@phoenix/all'
import {
  addScenarioRoles,
  currentPlanStartDateSelector,
  deleteScenarioRole,
  planDurationSelector,
  toggleRoleDistributionDialog,
  updateScenarioRoles,
  viewAccessSelector,
} from '../../../../../../data'
import {currentScenarioRolesSelectorSelector} from '../../../../../../data/scenario/selectors/currentScenarioRolesSelector'
import {
  createScenarioRole,
  getDistributionSum,
  getMaxValueOfDistribution,
} from '../../../../../../shared/helpers'
import {IRole, IScenarioRole} from '../../../../../../shared/models'
import {IDistribution} from '../../../../../../shared/models/initiative/IDistribution'
import {SlidingPaneComponent} from '../../../../../shared/SlidingPaneComponent/SlidingPaneComponent'
import {EmptyRolesList} from '../EmptyRolesList'
import {JobRoleSelectComponent} from '../JobRoleSelectComponent'
import {CircleLabelsComponent} from './CircleLabelsComponent'
import {RoleDistributionTableComponent} from './RoleDistributionTableComponent'
import {RoleDistributionContext} from '../../../../../../shared/context/RoleDistributionContext'
import {hoursForMeasurementsSelector} from '../../../../../../data/plan/selectors/hoursForMeasurementsSelector'
import {Translate} from '../../../../../shared/Translate'
import {
  getDeletedRoleIds,
  getNewAndUpdatedRoles,
  getRolesDetailsFromState,
  getRolesIds,
  IRolesDetails,
} from './RoleDistributionHelper'
import {peopleCostOnConflictCalculationSelector} from '../../../../../../data/plan/selectors/peopleCostOnConflictCalculationSelector'
import {VariableSizeList} from 'react-window'

interface IRoleDistributionDialogComponentProps {
  show: boolean
}

const dialogTitleClass = css`
  font-size: 18px;
  padding-top: 13px;
`

const headerBottomClass = css`
  margin-top: 15px;
  display: flex;
  padding-right: 24px;
`

const roleSelectClass = css`
  width: 323px;
  padding-bottom: 6px;
  min-height: 45px;
`

const bodyInnerClass = css`
  padding-right: 2px;
  height: 100%;
`

export const RoleDistributionDialogComponent: React.FC<IRoleDistributionDialogComponentProps> = React.memo(
  ({show}) => {
    const dispatch = useDispatch()
    const listRef: React.RefObject<VariableSizeList> = React.useRef(null)
    const rolesFromState = useSelector(currentScenarioRolesSelectorSelector)
    const planStartDate = useSelector(currentPlanStartDateSelector)
    const planDuration = useSelector(planDurationSelector)
    const useHoursForMeasurements = useSelector(hoursForMeasurementsSelector)
    const usePeopleCostOnConflictCalculation = useSelector(peopleCostOnConflictCalculationSelector)
    const isViewMode = useSelector(viewAccessSelector)
    const [roleIds, setRoleIds] = React.useState<string[]>([])
    const [rolesDetails, setRoleDetails] = React.useState<IRolesDetails>({})
    const [addedRoleIds, setAddedRoleIds] = React.useState<Set<string>>(new Set())

    React.useEffect(() => {
      setRoleIds(getRolesIds(rolesFromState))
      setRoleDetails(getRolesDetailsFromState(rolesFromState))
    }, [rolesFromState, show])

    const onApplyHandler = React.useCallback(() => {
      const {newRoles, updatedRoles} = getNewAndUpdatedRoles(roleIds, rolesDetails, addedRoleIds)
      const deletedRoleIds = getDeletedRoleIds(rolesDetails, rolesFromState, addedRoleIds)

      dispatch(toggleRoleDistributionDialog(false))

      if (deletedRoleIds.size) {
        dispatch(deleteScenarioRole(deletedRoleIds, usePeopleCostOnConflictCalculation))
      }

      if (newRoles.length) {
        dispatch(addScenarioRoles(newRoles, true))
      }

      if (updatedRoles.length) {
        dispatch(updateScenarioRoles(updatedRoles, true))
      }
    }, [rolesDetails, roleIds, rolesFromState])

    const addRoleHandler = React.useCallback(
      (role: IRole): void => {
        if (role) {
          const newRole = createScenarioRole(role, planStartDate, planDuration)

          setRoleDetails((rolesDetails) => {
            return {
              ...rolesDetails,
              [role.id]: {
                ...newRole,
              },
            }
          })

          setRoleIds((roleIds) => {
            return [role.id, ...roleIds]
          })

          setAddedRoleIds(new Set(addedRoleIds).add(role.id))
          listRef.current!.scrollToItem(0, 'start')
          setTimeout(() => {
            const firstInput = document.querySelectorAll(
              '.scrollContainer div[data-testid=role-distribution-row-' + role.id + '] input'
            )[0] as HTMLInputElement
            firstInput.select()
          })
        }
      },
      [planStartDate, planDuration, addedRoleIds]
    )

    const updateRoleAvailablePerPeriod = React.useCallback(
      (roleId: string, values: IDistribution): void => {
        setRoleDetails((rolesDetails) => {
          const availablePerPeriod = {
            ...rolesDetails[roleId].availablePerPeriod,
            ...values,
          }

          const availableValue = useHoursForMeasurements
            ? getDistributionSum(availablePerPeriod)
            : getMaxValueOfDistribution(availablePerPeriod)

          return {
            ...rolesDetails,
            [roleId]: {
              ...rolesDetails[roleId],
              availableValue,
              availablePerPeriod,
            },
          }
        })
      },
      [rolesDetails]
    )

    const removeRole = React.useCallback(
      (roleId) => {
        const clonedDetails = {...rolesDetails}
        delete clonedDetails[roleId]

        const clonedRoleIds = [...roleIds]
        clonedRoleIds.splice(roleIds.indexOf(roleId), 1)

        if (addedRoleIds.has(roleId)) {
          const changedIds = new Set(addedRoleIds)
          changedIds.delete(roleId)
          setAddedRoleIds(changedIds)
        }

        setRoleIds(clonedRoleIds)
        setRoleDetails(clonedDetails)
      },
      [addedRoleIds, rolesDetails, roleIds]
    )

    const onCloseHandler = (): void => {
      dispatch(toggleRoleDistributionDialog(false))
    }

    const hasUpdatedRoles = (): boolean => {
      if (addedRoleIds.size > 0) {
        return true
      }

      const deletedRoleIds = getDeletedRoleIds(rolesDetails, rolesFromState, addedRoleIds)
      if (deletedRoleIds.size > 0) {
        return true
      }

      if (!roleIds.length) {
        return false
      }

      return !!rolesFromState.find((role: IScenarioRole) => {
        if (
          !rolesDetails[role.role.id] ||
          JSON.stringify(rolesDetails[role.role.id].availablePerPeriod) !==
            JSON.stringify(role.availablePerPeriod)
        ) {
          return true
        }
      })
    }

    const isApplyButtonDisabled = React.useMemo(() => !hasUpdatedRoles(), [
      rolesDetails,
      addedRoleIds,
      roleIds,
    ])

    const message = isViewMode
      ? 'sp.header.widget.role.info.view.mode'
      : useHoursForMeasurements
      ? 'sp.role.distribution-dialog.sub.title.hour'
      : 'sp.role.distribution-dialog.sub.title.fte'

    return (
      <SlidingPaneComponent show={show}>
        <RoleDistributionContext.Provider
          value={{
            updateRoleAvailablePerPeriod,
            removeRole,
            roleIds,
            rolesDetails,
          }}
        >
          <SlidingPaneComponent.Header>
            <h4 className={dialogTitleClass}>
              <Translate messageKey="sp.role.distribution-dialog.title" />
            </h4>
            <p>
              <Text.Small>
                <Translate messageKey={message} />
              </Text.Small>
            </p>

            <div className={headerBottomClass}>
              <div className={roleSelectClass}>
                {!isViewMode && (
                  <JobRoleSelectComponent
                    addRole={addRoleHandler}
                    scenarioRolesIds={roleIds}
                    testID="role-distribution-roles-options"
                  />
                )}
              </div>

              <CircleLabelsComponent />
            </div>
          </SlidingPaneComponent.Header>

          <SlidingPaneComponent.Body>
            <div className={bodyInnerClass}>
              <RoleDistributionTableComponent ref={listRef} />
              {roleIds.length === 0 && <EmptyRolesList />}
            </div>
          </SlidingPaneComponent.Body>

          <SlidingPaneComponent.Footer>
            <Button
              primary
              onClick={onApplyHandler}
              testID="apply-role-distribution-dialog"
              disabled={isApplyButtonDisabled}
            >
              <Translate messageKey={'form.button.apply'} />
            </Button>

            <Button text onClick={onCloseHandler} testID="close-role-distribution-dialog">
              <Translate messageKey={'form.button.cancel'} />
            </Button>
          </SlidingPaneComponent.Footer>
        </RoleDistributionContext.Provider>
      </SlidingPaneComponent>
    )
  }
)

RoleDistributionDialogComponent.displayName = 'RoleDistributionDialogComponent'
