import {primary, secondary} from '@phoenix/all'
import {css} from 'emotion'
import {Moment} from 'moment'
import {
  IBudgetConflict,
  IInitiative,
  IInitiativeDates,
  IInitiativeRole,
  IRoleConflict,
} from '../models/initiative'
import {IInitiativeConflictsDetailsDates} from '../models/initiative/IInitiativeConflicsDetailsByDate'
import {formatFloating, isFirefox} from '../utilities'
import {YEAR_MONTH_FORMAT} from '../constants/dateFormats'
import size from 'lodash/size'
import {IDistribution} from '../models/initiative/IDistribution'
import {RefObject} from 'react'

export const arrowClass = css`
  svg path {
    fill: transparent;
  }
`
export const conflictArrowClass = css`
  ${arrowClass};
  fill: ${secondary.red(400)};
`

export const standardArrowClass = css`
  ${arrowClass};
  fill: ${primary.blue(400)};
`

export const standardArrowHoverWithoutDistribution = css`
  rect {
    fill: ${primary.blue(500)};
  }
  svg path {
    fill: ${primary.gray(0)};
  }
`

export const standardArrowHover = css`
  fill: ${primary.blue(500)};

  & + svg path {
    fill: ${primary.gray(0)};
  }
`

export const conflictArrowHoverWithoutDistribution = css`
  rect {
    fill: ${secondary.red(500)};
  }
  svg path {
    fill: ${primary.gray(0)};
  }
`

export const conflictEdArrowHover = css`
  fill: ${secondary.red(500)};

  & + svg path {
    fill: ${primary.gray(0)};
  }
`
export const dragBarClass = css`
  .arrow {
    cursor: ew-resize;
  }
`
export const timelineBarClass = css`
  cursor: pointer;
  &.react-draggable-dragging {
    ${isFirefox() ? '' : 'filter: url(#f1);'}
    cursor: move;
  }
`

export const conflictTimelineBarClass = css`
  ${timelineBarClass};
  fill: ${secondary.red(400)};
`

export const standardTimelineBarClass = css`
  ${timelineBarClass};
  fill: ${primary.blue(400)};
`
export const timelineBarShadowClass = css`
  @keyframes ShadowAnimation {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  opacity: 0;
  stroke-dasharray: 3 5;
  stroke-width: 1;
  stroke: ${primary.blue()};
  fill: ${primary.blue(100)};
  animation: ShadowAnimation 0.5s;
  animation-fill-mode: forwards;
  animation-delay: 0.1s;
`

export const conflictTimelineBarShadowClass = css`
  ${timelineBarShadowClass};
  stroke: ${secondary.red()};
  fill: ${secondary.red(100)};
`

export const standardTimelineBarShadowClass = css`
  ${timelineBarShadowClass};
  stroke: ${primary.blue()};
  fill: ${primary.blue(100)};
`
export const dragClass = css`
  -webkit-filter: url(#f1);
  filter: url(#f1);
`

export const hideContouringClass = css`
  opacity: 0;
  visibility: hidden;
`

export const SCROLL_BAR_SIZE = 0
export const DELTA_MAX_VALUE = 50
export const DELTA_MIN_VALUE = -50
export const SCROLL_STEP = 10
export const BAR_RADIUS = 4
export const SHADOW_Y_POSITION = 8
export const ICON_WIDTH = 14

export const calculateStartDate = (
  startDate: Moment,
  planStartDate: Moment,
  cellWidth: number
): number => {
  return startDate.diff(planStartDate, 'months') * cellWidth
}

export const calculateBarWidthAndPosition = (
  dates: IInitiativeDates,
  planStartDate: Moment,
  cellWidth: number
) => {
  const {startDate, endDate} = dates
  const startPosition = calculateStartDate(startDate, planStartDate, cellWidth)
  const initiativeDuration = Math.round(endDate.diff(startDate, 'months', true))
  const width = Math.round(initiativeDuration * cellWidth)
  return {
    startPosition,
    width,
  }
}

export const hasInitiativeConflict = (conflicts): boolean => {
  return conflicts && (conflicts.roleConflicts!.size > 0 || conflicts.budgetConflicts != null)
}

export const getInitiativeConflictsByDate = (
  initiative: IInitiative,
  date,
  usePeopleCostOnConflictCalculation
) => {
  const conflictsByDate: IInitiativeConflictsDetailsDates = {
    roleConflicts: null,
    budgetConflicts: null,
  }

  if (initiative.conflicts) {
    if (initiative.conflicts.roleConflicts) {
      initiative.conflicts.roleConflicts.forEach((conflictDetails, roleId: string) => {
        const roleDistribution = initiative.people.roles.find((item) => item.id === roleId)
        const details = getDateConflictsDetails(
          conflictDetails.periodConflicts,
          roleDistribution!.distribution,
          date
        )

        if (details.length) {
          const roleConflict = {
            availableValue: details[0],
            requiredValue: details[1],
            missingValue: details[2],
          }
          if (conflictsByDate!.roleConflicts) {
            conflictsByDate!.roleConflicts[roleId] = roleConflict
          } else {
            conflictsByDate!.roleConflicts = {
              [roleId]: roleConflict,
            }
          }
        }
      })
    }

    if (initiative.conflicts.budgetConflicts && initiative.costs) {
      const distribution = usePeopleCostOnConflictCalculation
        ? initiative.costs!.overallCostDistribution
        : initiative.costs!.fixedCostDistribution

      const details = getDateConflictsDetails(
        initiative.conflicts.budgetConflicts.periodConflicts,
        distribution,
        date
      )
      if (details.length) {
        conflictsByDate.budgetConflicts = details
      }
    }
  }

  if (conflictsByDate.budgetConflicts || size(conflictsByDate.roleConflicts)) {
    return conflictsByDate
  }

  return null
}

const getDateConflictsDetails = (periodConflicts, distribution, date): number[] => {
  const periodConflict = periodConflicts.find((item) => {
    if (item.period.format('YYYY-MM') === date) {
      return item
    }
  })

  if (periodConflict) {
    return [periodConflict.totalAmount, distribution[date], periodConflict.missingAmount]
  }
  return []
}

export const calculateDurationWidthAndStartPosition = (
  width: number,
  startPosition: number,
  rightDragDelta = 0,
  leftDragDelta = 0,
  leftDragScrollPosition = 0
) => {
  let durationWidth = width
  let durationStartPosition = startPosition
  if (rightDragDelta !== 0) {
    durationWidth = rightDragDelta + width
  } else if (leftDragDelta !== 0) {
    durationStartPosition = startPosition - leftDragDelta - leftDragScrollPosition
    durationWidth = leftDragDelta + width
  }
  return {
    durationWidth,
    durationStartPosition,
  }
}

export const calculateBarWidth = (
  durationWidth: number,
  cellWidth: number,
  isDragStarted,
  arrowWidth: number,
  rightDragScrollPosition = 0,
  leftDragScrollPosition = 0
): number => {
  const barWidth =
    durationWidth + rightDragScrollPosition - leftDragScrollPosition < cellWidth
      ? cellWidth - (isDragStarted ? 0 : arrowWidth)
      : durationWidth +
        rightDragScrollPosition -
        leftDragScrollPosition -
        (isDragStarted ? 0 : arrowWidth)
  return barWidth < 0 ? 0 : barWidth
}

export const calculateBarPosition = (
  durationStartPosition: number,
  isDragStarted,
  arrowWidth: number,
  leftDragScrollPosition = 0
): number => {
  return durationStartPosition + leftDragScrollPosition + (isDragStarted ? 0 : arrowWidth / 2)
}

export const calculateArrowWidth = (mode: number): number => (mode === 0 ? 12 : 16)

export const recalculateBarWidthAndPosition = (props, resizingState, isDragStarted: boolean) => {
  const {startPosition, width} = calculateBarWidthAndPosition(
    props.initiative.dates,
    props.planStartDate,
    props.cellWidth
  )

  const {durationWidth, durationStartPosition} = calculateDurationWidthAndStartPosition(
    width,
    startPosition,
    resizingState.rightDragDelta,
    resizingState.leftDragDelta,
    resizingState.leftDragScrollPosition
  )

  const arrowWidth = calculateArrowWidth(props.mode)

  const barWidth = calculateBarWidth(
    durationWidth,
    props.cellWidth,
    isDragStarted,
    arrowWidth,
    resizingState.rightDragScrollPosition,
    resizingState.leftDragScrollPosition
  )
  const barPosition = calculateBarPosition(
    durationStartPosition,
    isDragStarted,
    arrowWidth,
    resizingState.leftDragScrollPosition
  )

  return {
    barWidth,
    barPosition,
  }
}

export const getResizingProperties = (props) => {
  const {startPosition, width} = calculateBarWidthAndPosition(
    props.initiative.dates,
    props.planStartDate,
    props.cellWidth
  )
  const arrowWidth = calculateArrowWidth(props.mode)
  const leftDraggablePosition = startPosition
  const rightDraggablePosition = startPosition + width - arrowWidth
  const leftIconPosition = leftDraggablePosition - (props.mode === 0 ? 3 : 1)
  const rightIconPosition = rightDraggablePosition + (props.mode === 0 ? 2 : 5)

  return {
    leftDraggablePosition,
    rightDraggablePosition,
    rightIconPosition,
    leftIconPosition,
    arrowWidth,
  }
}

export const getResizeLinePosition = (
  arrowRect: RefObject<HTMLDivElement>,
  arrowWidth: number,
  sidebarSize: number
): number => {
  // here arrowRect.current can't be null
  const arrowPosition = arrowRect.current?.getBoundingClientRect().left
  return arrowPosition! - sidebarSize + arrowWidth
}

export const getEmptyBarProperties = (props, state, isDragStarted: boolean) => {
  const {startPosition, width} = calculateBarWidthAndPosition(
    props.initiative.dates,
    props.planStartDate,
    props.cellWidth
  )

  const {durationWidth, durationStartPosition} = calculateDurationWidthAndStartPosition(
    width,
    startPosition,
    state.rightDragDelta,
    state.leftDragDelta,
    state.leftDragScrollPosition
  )

  const arrowWidth = calculateArrowWidth(props.mode)

  const barWidth = calculateBarWidth(durationWidth, props.cellWidth, isDragStarted, arrowWidth)

  return {
    barWidth,
    leftDraggablePosition: durationStartPosition,
    arrowWidth,
  }
}

export interface IVisibleConflictInfoByDate {
  details: string | number
  isBudgetInfo: boolean
}

export const getVisibleRoleConflictByDate = (
  visibleConflict: IRoleConflict,
  initiativeRoles: IInitiativeRole[],
  date: string
): IVisibleConflictInfoByDate | null => {
  let roleInfo
  const role = initiativeRoles.find((role: IInitiativeRole) => role.id === visibleConflict.roleId)

  if (role) {
    visibleConflict.periodConflicts.forEach((periodConflict) => {
      if (periodConflict.period.format(YEAR_MONTH_FORMAT) === date && role.distribution![date]) {
        roleInfo = {
          details: `${formatFloating(role.distribution![date], 3)} ${role.name}`,
          isBudgetInfo: false,
        }
      }
    })
  }

  return roleInfo ? roleInfo : null
}

export const getVisibleBudgetConflictByDate = (
  visibleConflict: IBudgetConflict,
  costs: IDistribution | null,
  date: string
): IVisibleConflictInfoByDate | null => {
  let budgetInfo: IVisibleConflictInfoByDate | null = null

  if (costs) {
    visibleConflict.periodConflicts.forEach((periodConflict) => {
      if (periodConflict.period.format(YEAR_MONTH_FORMAT) === date && costs[date]) {
        budgetInfo = {
          details: costs[date],
          isBudgetInfo: true,
        }
      }
    })
  }

  return budgetInfo
}

export const isBudgetConflict = (visibleConflict): visibleConflict is IBudgetConflict => {
  return !visibleConflict.roleId
}

export const getVisibleConflictByDate = (
  visibleConflict: IRoleConflict | IBudgetConflict,
  initiative: IInitiative,
  date: string,
  usePeopleCostOnConflictCalculation: boolean
): IVisibleConflictInfoByDate | null => {
  if (isBudgetConflict(visibleConflict)) {
    const costs = usePeopleCostOnConflictCalculation
      ? initiative.costs!.overallCostDistribution
      : initiative.costs!.fixedCostDistribution

    return getVisibleBudgetConflictByDate(visibleConflict, costs, date)
  }

  if (!isBudgetConflict(visibleConflict)) {
    return getVisibleRoleConflictByDate(visibleConflict, initiative.people.roles, date)
  }

  return null
}

export const getInitiative = (currentInitiative: IInitiative | null, initiative: IInitiative) => {
  return currentInitiative && initiative.id === currentInitiative.id
    ? currentInitiative
    : initiative
}

export const isHScrollable = (scrollParent) => {
  return scrollParent && scrollParent.scrollWidth > scrollParent.clientWidth + SCROLL_BAR_SIZE
}

export const canScroll = (scrollParent, scrollTo = true) => {
  return (
    (!scrollTo && scrollParent.scrollLeft > SCROLL_BAR_SIZE) ||
    (scrollTo && scrollParent.scrollLeft + scrollParent.offsetWidth < scrollParent.scrollWidth)
  )
}

export const parentScrollTo = (scrollParentElement, scrollLeft) =>
  scrollParentElement?.scrollTo(scrollLeft, scrollParentElement.scrollTop)
