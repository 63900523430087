import moment, {Moment} from 'moment'
import * as React from 'react'
import {connect} from 'react-redux'
import forEach from 'lodash/forEach'
import Draggable from 'react-draggable'
import {changePreCalculatedData} from '../../../../data/initiative'
import {currentPlanSelector} from '../../../../data/plan'
import {timelineCellWidthSelector} from '../../../../data/plan'
import {
  currentScenarioBudgetSelector,
  currentScenarioInitiativesSelector,
  currentScenarioPeopleSelector,
  updateInitiativeAfterDragDrop,
} from '../../../../data/scenario'
import {draggingInitiativeIdSelector, setDraggingInitiativeId} from '../../../../data/viewOptions'
import {getPreCalculatedDataForCurrentInitiative} from '../../../../shared/helpers'
import {
  BAR_RADIUS,
  calculateBarWidthAndPosition,
  canScroll,
  DELTA_MAX_VALUE,
  DELTA_MIN_VALUE,
  hasInitiativeConflict,
  isHScrollable,
  parentScrollTo,
  recalculateBarWidthAndPosition,
  SCROLL_STEP,
  SHADOW_Y_POSITION,
} from '../../../../shared/helpers/TimelineBarHelper'
import {
  conflictTimelineBarShadowClass,
  dragBarClass,
  dragClass,
  standardTimelineBarClass,
  standardTimelineBarShadowClass,
  hideContouringClass,
} from '../../../../shared/helpers/TimelineBarHelper'
import {
  IInitiative,
  IInitiativeRole,
  IPreCalculatedData,
} from '../../../../shared/models/initiative'
import {IDistribution} from '../../../../shared/models/initiative/IDistribution'
import {IScenarioBudgets, IScenarioPeople} from '../../../../shared/models/scenario'
import {isFirefox} from '../../../../shared/utilities'
import {MeasurementsContext} from '../../../../shared/context/MeasurementsContext'
import {peopleCostOnConflictCalculationSelector} from '../../../../data/plan/selectors/peopleCostOnConflictCalculationSelector'
import {RoleContouringListComponent} from './RoleContouring/RoleContouringListComponent'
import {financialAccessDeniedSelector} from '../../../../data/settings'
import {TimelineBarResizeComponent} from './TimelineBarResizeComponent'
import {sidebarSizeSelector} from '../../../../data/settings/selectors/sidebarSizeSelector'

export interface IResizingData {
  rightDragDelta: number
  leftDragDelta: number
  leftDragScrollPosition: number
  rightDragScrollPosition: number
}

interface ITimelineBarComponentProps {
  planStartDate: Moment
  planDuration: number
  cellWidth: number
  isRowHovered: boolean
  initiative: IInitiative
  updateInitiativeAfterDragDropFunction: (
    initiative: IInitiative,
    usePeopleCostOnConflictCalculation: boolean
  ) => void
  changePreCalculatedDataFunction: (
    data: IPreCalculatedData,
    usePeopleCostOnConflictCalculation: boolean
  ) => void
  handleOnMouseOut: () => void
  handleOnClick: () => void
  handleOnMouseOver: (event, ref) => void
  initiatives: IInitiative[]
  budgets: IScenarioBudgets
  people: IScenarioPeople
  usePeopleCostOnConflictCalculation: boolean
  hasFinancialAccess: boolean
  setDraggingInitiativeIdFunction: (id: string | null) => void
  draggingInitiativeId: string | null
  mode: number
  sidebarSize: number
}

interface ITimelineBarComponentState {
  isDrag: boolean
  isHover: boolean
  shadowBoxStartPosition: number
  startPositionOnScroll: number
  isMouseInsideTooltip: boolean
  isLeftArrowConflicted: boolean //it is changed by setBarArrowsStates
  isRightArrowConflicted: boolean //it is changed by setBarArrowsStates
  shouldScaleLeftArrow: boolean //it is changed by setBarArrowsStates
  shouldScaleRightArrow: boolean //it is changed by setBarArrowsStates
  barWidth: number
  barPosition: number
}

class TimelineBar extends React.Component<ITimelineBarComponentProps, ITimelineBarComponentState> {
  private isBarDragging = false
  private canClick = true
  private mousePosition
  private debounce
  private scrollParentElement
  private scrollDebounce
  private readonly planEndDate
  private readonly rectRef
  private readonly draggableRef
  private readonly timelineBarRef

  constructor(props) {
    super(props)

    this.planEndDate = props.planStartDate
      .clone()
      .add(props.planDuration, 'years')
      .startOf('month')
      .subtract(1, 'day')
      .endOf('day')

    const {barPosition, barWidth} = recalculateBarWidthAndPosition(props, {}, false)

    this.state = {
      isDrag: false,
      isHover: false,
      shadowBoxStartPosition: -1,
      startPositionOnScroll: 0,
      isMouseInsideTooltip: false,
      isLeftArrowConflicted: false,
      isRightArrowConflicted: false,
      shouldScaleLeftArrow: false,
      shouldScaleRightArrow: false,
      barWidth: barWidth,
      barPosition: barPosition,
    }

    this.rectRef = React.createRef()
    this.draggableRef = React.createRef()
    this.timelineBarRef = React.createRef()
  }

  setMouseInsideTooltip = (value: boolean) => {
    this.setState({
      isMouseInsideTooltip: value,
    })
  }

  render() {
    const {barWidth, barPosition} = this.state
    const isArrowDrag =
      (this.props.draggingInitiativeId || this.props.isRowHovered) && !isFirefox() ? dragClass : ''
    const hasConflict = hasInitiativeConflict(this.props.initiative.conflicts)

    return (
      <MeasurementsContext.Consumer>
        {({timelineBarArrowHeight, timelineBarArrowYPosition}) => (
          <>
            {this.state.isDrag && this.state.shadowBoxStartPosition >= 0 && (
              <rect
                data-testid="drag-shadow"
                className={
                  hasConflict ? conflictTimelineBarShadowClass : standardTimelineBarShadowClass
                }
                x={this.state.shadowBoxStartPosition}
                y={SHADOW_Y_POSITION}
                rx={BAR_RADIUS}
                ry={BAR_RADIUS}
                width={barWidth}
                height="26"
              />
            )}
            <g
              data-testid="drag-bar-wrapper"
              ref={this.timelineBarRef}
              className={`${dragBarClass} ${isArrowDrag}`}
              onMouseEnter={this.onBarMouseOver}
              onMouseLeave={this.onBarMouseOut}
            >
              {!this.isBarDragging && (
                <TimelineBarResizeComponent
                  timelineBarArrowHeight={timelineBarArrowHeight}
                  timelineBarArrowYPosition={timelineBarArrowYPosition}
                  isRowHovered={this.props.isRowHovered}
                  initiative={this.props.initiative}
                  changeBarWidthAndPositionState={this.changeBarWidthAndPositionState}
                  isLeftArrowConflicted={this.state.isLeftArrowConflicted}
                  isRightArrowConflicted={this.state.isRightArrowConflicted}
                  shouldScaleLeftArrow={this.state.shouldScaleLeftArrow}
                  shouldScaleRightArrow={this.state.shouldScaleRightArrow}
                />
              )}
              <Draggable
                enableUserSelectHack={false}
                axis="x"
                position={{x: this.state.startPositionOnScroll, y: 0}}
                ref={this.draggableRef}
                onStart={this.handleStart}
                onStop={this.handleStop}
                onDrag={this.handleDrag}
                disabled={this.state.isMouseInsideTooltip}
              >
                <g onClick={this.onBarClick}>
                  <rect
                    data-testid="initiative-duration-bar"
                    className={`${standardTimelineBarClass}`}
                    x={this.getBarCoordinates(barPosition)}
                    y={timelineBarArrowYPosition}
                    rx={BAR_RADIUS}
                    ry={BAR_RADIUS}
                    width={this.getBarCoordinates(barWidth, true)}
                    height={timelineBarArrowHeight}
                    ref={this.rectRef}
                  />

                  {this.props.initiative.conflicts && (
                    <RoleContouringListComponent
                      className={this.props.draggingInitiativeId ? hideContouringClass : ''}
                      initiative={this.props.initiative}
                      setMouseInsideTooltip={this.setMouseInsideTooltip}
                      setBarArrowsStates={this.setBarArrowsStates}
                    />
                  )}
                </g>
              </Draggable>
            </g>
          </>
        )}
      </MeasurementsContext.Consumer>
    )
  }

  private getBarCoordinates = (value, subtract = false) => {
    const {shouldScaleLeftArrow, shouldScaleRightArrow} = this.state
    const two = subtract ? -2 : 2
    const one = subtract ? -1 : 1

    return shouldScaleLeftArrow && shouldScaleRightArrow
      ? value + two
      : shouldScaleLeftArrow || shouldScaleRightArrow
      ? value + one
      : value
  }

  private setBarArrowsStates = (state) => {
    this.setState({
      ...state,
    })
  }

  private onBarClick = () => {
    if (this.canClick && !this.state.isMouseInsideTooltip) {
      const {
        initiative,
        initiatives,
        people: {roles},
        budgets,
        hasFinancialAccess,
      } = this.props
      const preCalculatedData = getPreCalculatedDataForCurrentInitiative(
        initiative,
        initiatives,
        roles,
        budgets && budgets.distribution,
        this.props.usePeopleCostOnConflictCalculation,
        hasFinancialAccess
      )
      this.props.changePreCalculatedDataFunction(
        preCalculatedData,
        this.props.usePeopleCostOnConflictCalculation
      )

      this.props.handleOnClick()
    }
  }

  private onBarMouseOver = () => {
    if (!this.state.isHover) {
      this.setState({isHover: true})

      if (!this.props.draggingInitiativeId) {
        this.changeBarWidthAndPositionState()
      }
    }
  }

  private onBarMouseOut = (roleContouringSplit) => {
    if (this.state.isHover && !this.props.draggingInitiativeId) {
      this.setState({isHover: false})

      if (!this.props.draggingInitiativeId) {
        this.changeBarWidthAndPositionState()
      }
    }

    if (!roleContouringSplit) {
      this.props.handleOnMouseOut()
    }
  }

  private checkShowShadow = (axisX) => {
    if (this.state.isDrag) {
      this.setDrag(false)
    }
    this.showShadow(axisX)
  }

  private showShadow = (axisX: number) => {
    clearTimeout(this.debounce)
    this.debounce = setTimeout(() => {
      if (this.isBarDragging) {
        const {dates} = this.calculateInitiativeDatesWithoutDistribution(axisX)

        const {planStartDate, cellWidth} = this.props
        const {startPosition} = calculateBarWidthAndPosition(dates, planStartDate, cellWidth)

        this.setState({
          shadowBoxStartPosition: startPosition,
          isDrag: true,
        })
      }
    }, 5)
  }

  private disableDefaultScroll = (scrollParentElement) => {
    scrollParentElement.style.overflow = 'hidden'
  }

  private enableDefaultScroll = (scrollParentElement) => {
    scrollParentElement.style.overflow = 'auto'
  }

  private setDrag = (isDrag) => this.setState({isDrag})

  private validInitiativeDates = (
    startDate: Moment,
    endDate: Moment,
    duration: number,
    shiftedMonths: number,
    planStartDate: Moment
  ) => {
    let newStartDate = startDate.clone().add(shiftedMonths, 'month').startOf('month')
    let newEndDate = endDate.clone().add(shiftedMonths, 'month').endOf('month')

    if (newStartDate.isBefore(planStartDate)) {
      newStartDate = planStartDate.clone()
      newEndDate = newStartDate.clone().add(duration, 'month').subtract(1, 'day')
    } else if (newEndDate.isAfter(this.planEndDate)) {
      newEndDate = this.planEndDate.clone()
      newStartDate = newEndDate.clone().subtract(duration, 'month').add(4, 'day').startOf('month')
    }
    return {
      newStartDate,
      newEndDate,
    }
  }

  private beforeDragStart = () => {
    document.body.classList.add('draging')
    this.scrollParentElement = document.querySelector('.scrollContent')
  }

  private afterDragEnd = () => {
    document.body.classList.remove('draging')
    if (this.setState) {
      this.setState({
        shadowBoxStartPosition: -1,
      })
    }

    this.props.setDraggingInitiativeIdFunction(null)
    if (this.scrollParentElement) {
      this.enableDefaultScroll(this.scrollParentElement)
      this.scrollParentElement = null
    }
  }

  // calculation
  private calculateInitiativeDatesWithoutDistribution = (axisX: number) => {
    const {cellWidth, planStartDate, initiative} = this.props
    const {startDate, endDate, duration} = initiative.dates
    const shiftedMonths = Math.round(axisX / cellWidth)
    const {newStartDate, newEndDate} = this.validInitiativeDates(
      startDate,
      endDate,
      duration,
      shiftedMonths,
      planStartDate
    )
    return {
      shiftedMonths,
      dates: {
        duration,
        startDate: newStartDate,
        endDate: newEndDate,
      },
    }
  }

  private calculateInitiativeDates = (axisX: number) => {
    const {cellWidth, planStartDate, initiative} = this.props
    const {startDate, endDate, duration} = initiative.dates
    let shiftedMonths = Math.round(axisX / cellWidth)
    const {newStartDate, newEndDate} = this.validInitiativeDates(
      startDate,
      endDate,
      duration,
      shiftedMonths,
      planStartDate
    )

    shiftedMonths = newStartDate.diff(startDate, 'month')

    return {
      shiftedMonths,
      dates: {
        duration,
        startDate: newStartDate,
        endDate: newEndDate,
      },
    }
  }

  // scroll
  private calculateScroll = (scrollLeft, align, scrollTo) => {
    this.scrollDebounce = setInterval(() => {
      if (canScroll(this.scrollParentElement, scrollTo) && this.draggableRef.current) {
        const {x} = this.draggableRef.current.state
        const step = align * SCROLL_STEP
        this.setState({
          startPositionOnScroll: x + step,
        })
        scrollLeft = scrollLeft + step
        parentScrollTo(this.scrollParentElement, scrollLeft)
        this.checkShowShadow(this.state.startPositionOnScroll)
      }
    }, 10)
  }

  private handleStart = (event) => {
    this.beforeDragStart()
    this.mousePosition = event.screenX
    this.canClick = true
    this.setDrag(true)
  }

  private changeBarWidthAndPositionState = (data?: IResizingData) => {
    const resizingData = data || {
      rightDragDelta: 0,
      leftDragDelta: 0,
      leftDragScrollPosition: 0,
      rightDragScrollPosition: 0,
    }

    this.setState(recalculateBarWidthAndPosition(this.props, resizingData, this.isBarDragging))
  }

  private handleDrag = (event, {x}) => {
    if (!this.isBarDragging) {
      this.isBarDragging = true
      this.props.setDraggingInitiativeIdFunction(this.props.initiative.id)
      this.changeBarWidthAndPositionState()
    }

    const delta = event.screenX - this.mousePosition

    if (delta > 3 || delta < -3) {
      this.canClick = false
    }

    this.disableDefaultScroll(this.scrollParentElement)
    clearInterval(this.scrollDebounce)

    if (isHScrollable(this.scrollParentElement)) {
      const rect = this.rectRef.current.getBoundingClientRect()
      const rectX = rect.x || rect.left
      const scrollLeft = this.scrollParentElement.scrollLeft
      const scrollToRight =
        rect.width + rectX >= window.innerWidth &&
        canScroll(this.scrollParentElement, true) &&
        delta >= DELTA_MAX_VALUE
      const scrollToLeft =
        rectX <= this.props.sidebarSize &&
        canScroll(this.scrollParentElement, false) &&
        delta <= DELTA_MIN_VALUE
      if (scrollToRight) {
        this.calculateScroll(scrollLeft, 1, true)
      } else if (scrollToLeft) {
        this.calculateScroll(scrollLeft, -1, false)
      } else {
        this.checkShowShadow(x)
      }
    } else {
      this.checkShowShadow(x)
    }
  }

  private handleStop = (event, {x}) => {
    this.isBarDragging = false
    clearInterval(this.scrollDebounce)
    clearTimeout(this.debounce)
    this.setState({
      startPositionOnScroll: 0,
    })
    this.setDrag(false)

    const {shiftedMonths, dates} = this.calculateInitiativeDates(x)

    if (shiftedMonths === 0) {
      this.afterDragEnd()
      return
    }

    const {initiative} = this.props
    const newRoles = initiative.people.roles.map((role: IInitiativeRole) => {
      const distribution = this.getShiftedDistribution(role.distribution!, shiftedMonths)
      return {...role, distribution}
    })

    const newInitiative = {
      ...initiative,
      people: {
        ...initiative.people,
        roles: newRoles,
      },
      dates,
      costs: initiative.costs && {
        ...initiative.costs,
        fixedCostDistribution: this.getShiftedDistribution(
          initiative.costs.fixedCostDistribution!,
          shiftedMonths
        ),
        peopleCostDistribution: this.getShiftedDistribution(
          initiative.costs.peopleCostDistribution!,
          shiftedMonths
        ),
        overallCostDistribution: this.getShiftedDistribution(
          initiative.costs.overallCostDistribution!,
          shiftedMonths
        ),
      },
    }
    this.props.updateInitiativeAfterDragDropFunction(
      newInitiative,
      this.props.usePeopleCostOnConflictCalculation
    )
    this.afterDragEnd()
    this.changeBarWidthAndPositionState()
  }

  private getShiftedDistribution = (
    distribution: IDistribution,
    shiftedMonths: number
  ): IDistribution => {
    if (!distribution) {
      return distribution
    }
    const newDistribution = {}
    forEach(distribution, (count, key) => {
      const newKey = moment(key).add(shiftedMonths, 'month').format('YYYY-MM')
      newDistribution[newKey] = count
    })
    return newDistribution
  }
}

const mapStateToProps = (state) => ({
  planStartDate: currentPlanSelector(state)!.startDate,
  planDuration: currentPlanSelector(state)!.duration,
  cellWidth: timelineCellWidthSelector(state),
  mode: state.plan.timeline.mode, //need for calculation barWidth
  initiatives: currentScenarioInitiativesSelector(state),
  people: currentScenarioPeopleSelector(state),
  budgets: currentScenarioBudgetSelector(state),
  usePeopleCostOnConflictCalculation: peopleCostOnConflictCalculationSelector(state),
  hasFinancialAccess: !financialAccessDeniedSelector(state),
  draggingInitiativeId: draggingInitiativeIdSelector(state),
  sidebarSize: sidebarSizeSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  updateInitiativeAfterDragDropFunction: (initiative, usePeopleCostOnConflictCalculation) =>
    dispatch(updateInitiativeAfterDragDrop(initiative, usePeopleCostOnConflictCalculation)),
  changePreCalculatedDataFunction: (data, usePeopleCostOnConflictCalculation) =>
    dispatch(changePreCalculatedData(data, usePeopleCostOnConflictCalculation)),
  setDraggingInitiativeIdFunction: (initiativeId) =>
    dispatch(setDraggingInitiativeId(initiativeId)),
})

export const TimelineBarComponent = connect(mapStateToProps, mapDispatchToProps)(TimelineBar)
