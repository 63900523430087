import {css} from 'emotion'
import * as React from 'react'
import {DiagonalHatchPatternComponent} from './DiagonalHatchPatternComponent'
import {TimelineBarShadowFilterComponent} from './TimelineBarShadowFilterComponent'

const svgPatternsClass = css`
  position: absolute;
`

export const SVGPatternsWrapperComponent: React.FunctionComponent = React.memo(() => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="0" height="0" className={svgPatternsClass}>
      <DiagonalHatchPatternComponent />
      <TimelineBarShadowFilterComponent />
    </svg>
  )
})

SVGPatternsWrapperComponent.displayName = 'SVGPatternsWrapperComponent'
