import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import {TimelineMode} from '../../shared/enums'
import {PlanExistance} from '../../shared/enums/PlanExistance'
import {
  calculateCellWidth,
  calculateDefaultTimelineMode,
  calculateNetValueTotal,
  calculateScenarioConflictCount,
  generateBudgetDistributions,
  getTimelinePeriods,
  hasPublishedScenario,
  processBudgetAfterInitiativeListChange,
  recalculatesInitiativesNetValues,
  updateScenarioPublishedInitiatives,
} from '../../shared/helpers'
import {deselectInitiative, toggleInitiative} from '../../shared/helpers/SelectedInitiativesHelper'
import {
  ADD_SCENARIO,
  CHANGE_PLAN_SETTINGS,
  CHANGE_TIMELINE_CONTAINER_WIDTH,
  CHANGE_TIMELINE_MODE,
  CREATE_NEW_PLAN_SUCCESS,
  DELETE_SCENARIO,
  DESELECT_ALL_INITIATIVES,
  DESELECT_INITIATIVE,
  LOAD_PLAN_FAILURE,
  LOAD_PLAN_SUCCESS,
  RECALCULATE_PLAN_SCENARIOS_BUDGETS,
  RESET_PUBLISHED_SCENARIO,
  SET_LAST_SELECTED_INITIATIVE,
  SET_SELECTED_INITIATIVES,
  TOGGLE_INITIATIVE_SELECTION,
  UPDATE_CURRENT_PLAN,
  UPDATE_PLAN_ACCESSOR_OBJECTS,
  UPDATE_PLAN_SUCCESS,
  UPDATE_PLAN_WITH_STARTDATE_OR_DURATION,
  UPDATE_SCENARIO,
  UPDATE_SCENARIO_INITIATIVES_REF_OBJECT,
} from './actions'

import {IPlanState} from './IPlanState'

const initialState: IPlanState = {
  plan: null,
  currentPlan: null,
  accessorObjects: [],
  timeline: {
    mode: TimelineMode.MONTHLY,
    periods: getTimelinePeriods(TimelineMode.MONTHLY, moment().startOf('month'), 1)!,
    containerWidth: 0,
    cellWidth: 0,
  },
  isAccessDeniedPlan: false,
  planExistence: PlanExistance.NULL,
  selectedInitiatives: {},
  lastSelectedInitiativeId: '',
}

export function plan(state: IPlanState = initialState, action) {
  switch (action.type) {
    case LOAD_PLAN_SUCCESS: {
      const timelineMode = calculateDefaultTimelineMode(action.plan.duration)!
      const currentPlan = cloneDeep(action.plan)
      const usePeopleCostOnConflictCalculation =
        currentPlan.settings.usePeopleCostOnConflictCalculation

      return {
        ...state,
        plan: action.plan,
        currentPlan: {
          ...currentPlan,
          scenarios: currentPlan.scenarios.map((scenario) => {
            return {
              ...scenario,
              conflictsCount: calculateScenarioConflictCount(
                scenario,
                usePeopleCostOnConflictCalculation,
                action.hasFinancialAccess
              ),
            }
          }),
        },
        timeline: {
          ...state.timeline,
          mode: timelineMode,
          periods: getTimelinePeriods(timelineMode, action.plan.startDate, action.plan.duration)!,
          cellWidth: calculateCellWidth(state.timeline.containerWidth, timelineMode),
        },
      }
    }
    case LOAD_PLAN_FAILURE: {
      return {
        ...state,
        planExistence: action.error.status === 404 ? PlanExistance.NOTEXIST : PlanExistance.EXIST,
        isAccessDeniedPlan: action.error.status === 403 || action.error.status === 401,
      }
    }
    case CREATE_NEW_PLAN_SUCCESS: {
      const currentPlan = cloneDeep(action.plan)
      const usePeopleCostOnConflictCalculation =
        currentPlan.settings.usePeopleCostOnConflictCalculation

      return {
        ...state,
        plan: action.plan,
        currentPlan: {
          ...currentPlan,
          scenarios: currentPlan.scenarios.map((scenario) => {
            return {
              ...scenario,
              conflictsCount: calculateScenarioConflictCount(
                scenario,
                usePeopleCostOnConflictCalculation,
                action.hasFinancialAccess
              ),
            }
          }),
        },
      }
    }
    case UPDATE_PLAN_SUCCESS: {
      const currentPlan = cloneDeep(action.plan)
      const usePeopleCostOnConflictCalculation =
        currentPlan.settings.usePeopleCostOnConflictCalculation

      return {
        ...state,
        plan: action.plan,
        currentPlan: {
          ...currentPlan,
          scenarios: currentPlan.scenarios.map((scenario) => {
            return {
              ...scenario,
              conflictsCount: calculateScenarioConflictCount(
                scenario,
                usePeopleCostOnConflictCalculation,
                action.hasFinancialAccess
              ),
            }
          }),
        },
      }
    }
    case UPDATE_PLAN_ACCESSOR_OBJECTS: {
      return {
        ...state,
        accessorObjects: [...action.accessorObjects],
      }
    }
    case UPDATE_CURRENT_PLAN: {
      return {
        ...state,
        currentPlan: {
          ...state.currentPlan,
          ...action.changes,
        },
      }
    }
    case UPDATE_PLAN_WITH_STARTDATE_OR_DURATION: {
      const updatedPlan = {
        ...state.currentPlan,
        ...action.changes,
      }
      updatedPlan.scenarios = updatedPlan.scenarios.map((scenario) => {
        return {
          ...scenario,
          budgets: {
            peopleCosts: 0,
            fixedCosts: 0,
            costs: 0,
            distribution: generateBudgetDistributions(updatedPlan.duration, updatedPlan.startDate),
            total: 0,
            utilization: 0,
          },
        }
      })
      const timelineMode = calculateDefaultTimelineMode(updatedPlan.duration)!

      return {
        ...state,
        currentPlan: updatedPlan,
        timeline: {
          ...state.timeline,
          mode: timelineMode,
          periods: getTimelinePeriods(timelineMode, updatedPlan.startDate, updatedPlan.duration)!,
          cellWidth: calculateCellWidth(state.timeline.containerWidth, timelineMode),
        },
      }
    }
    case CHANGE_TIMELINE_MODE: {
      return {
        ...state,
        timeline: {
          ...state.timeline,
          mode: action.timelineMode,
          periods: getTimelinePeriods(
            action.timelineMode,
            state.currentPlan!.startDate,
            state.currentPlan!.duration
          )!,
          cellWidth: calculateCellWidth(state.timeline.containerWidth, action.timelineMode),
        },
      }
    }
    case CHANGE_TIMELINE_CONTAINER_WIDTH: {
      return {
        ...state,
        timeline: {
          ...state.timeline,
          containerWidth: action.containerWidth,
          cellWidth: calculateCellWidth(action.containerWidth, state.timeline.mode),
        },
      }
    }
    case ADD_SCENARIO: {
      const scenarios = state.currentPlan!.scenarios
      const order = scenarios[0].isInitial ? scenarios.length : scenarios.length + 1
      return {
        ...state,
        currentPlan: {
          ...state.currentPlan,
          scenarios: [...scenarios, {...action.scenario, order}],
          scenarioCount: scenarios.length + 1,
        },
      }
    }
    case UPDATE_SCENARIO: {
      const scenarios = state.currentPlan!.scenarios.map((scenario, index, scenarios) => {
        if (scenario.id === action.scenario.id) {
          return {...action.scenario, order: scenarios[0].isInitial ? index : index + 1}
        } else {
          return {...scenario, order: scenarios[0].isInitial ? index : index + 1}
        }
      })
      if (!hasPublishedScenario({...state.currentPlan!, scenarios})) {
        scenarios[0].isInitial = true
      }
      const usePeopleCostOnConflictCalculation = !!state.currentPlan!.settings!
        .usePeopleCostOnConflictCalculation

      return {
        ...state,
        currentPlan: {
          ...state.currentPlan,
          scenarios: scenarios.map((scenario) => {
            return {
              ...scenario,
              conflictsCount: calculateScenarioConflictCount(
                scenario,
                usePeopleCostOnConflictCalculation,
                action.hasFinancialAccess
              ),
            }
          }),
        },
      }
    }
    case UPDATE_SCENARIO_INITIATIVES_REF_OBJECT: {
      const scenarios = state.currentPlan!.scenarios.map((scenario) =>
        updateScenarioPublishedInitiatives(
          scenario,
          action.publishedInitiativesMap,
          action.publishedScenarioId
        )
      )
      return {
        ...state,
        currentPlan: {
          ...state.currentPlan,
          scenarios,
        },
        plan: {
          ...state.plan,
          scenarios,
        },
      }
    }
    case DELETE_SCENARIO: {
      const scenarios = state.currentPlan!.scenarios.filter(
        (scenario) => scenario.id !== action.scenario.id
      )

      const currentPlan = {
        ...state.currentPlan!,
        scenarios,
      }
      if (!hasPublishedScenario(currentPlan)) {
        scenarios[0].isInitial = true
      }

      currentPlan.scenarios = scenarios.map((scenario, index, scenarios) => {
        return {...scenario, order: scenarios[0].isInitial ? index : index + 1}
      })
      return {
        ...state,
        currentPlan,
      }
    }
    case RESET_PUBLISHED_SCENARIO: {
      const scenarios = state.currentPlan!.scenarios.map((scenario, index) => {
        return {
          ...scenario,
          order: index,
          isPublished: false,
        }
      })
      scenarios[0].isInitial = true
      return {
        ...state,
        currentPlan: {
          ...state.currentPlan,
          scenarios,
        },
      }
    }
    case CHANGE_PLAN_SETTINGS: {
      return {
        ...state,
        [action.planKey]: {
          ...state[action.planKey],
          settings: {
            ...state.currentPlan!.settings,
            ...action.planSettings,
          },
        },
      }
    }
    case RECALCULATE_PLAN_SCENARIOS_BUDGETS: {
      // here can enter only when have FinancialAccess
      const scenarios = state.currentPlan!.scenarios.map((scenario) => {
        const initiatives = recalculatesInitiativesNetValues(
          scenario.initiatives,
          action.usePeopleCostOnConflictCalculation
        )
        const budgets = processBudgetAfterInitiativeListChange(
          scenario.budgets!, // can't be null as can enter only when hasFinancialAccess
          initiatives,
          action.usePeopleCostOnConflictCalculation
        )
        return {
          ...scenario,
          budgets,
          netValue: calculateNetValueTotal(initiatives),
        }
      })

      return {
        ...state,
        currentPlan: {
          ...state.currentPlan,
          scenarios,
        },
      }
    }

    case TOGGLE_INITIATIVE_SELECTION:
      return {
        ...state,
        selectedInitiatives: toggleInitiative(state.selectedInitiatives, action.initiativeId),
      }
    case DESELECT_INITIATIVE:
      return {
        ...state,
        selectedInitiatives: deselectInitiative(state.selectedInitiatives, action.initiativeId),
      }
    case SET_SELECTED_INITIATIVES:
      return {
        ...state,
        selectedInitiatives: action.selectedInitiatives,
      }
    case DESELECT_ALL_INITIATIVES:
      return {
        ...state,
        selectedInitiatives: {},
      }
    case SET_LAST_SELECTED_INITIATIVE:
      return {
        ...state,
        lastSelectedInitiativeId: action.lastSelectedInitiativeId,
      }
    default:
      return state
  }
}
