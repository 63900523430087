import * as React from 'react'
import {ConfirmDialog, Text} from '@phoenix/all'
import {Translate} from '../../../../shared/Translate'
import {ConfirmationDialogHeaderComponent} from '../../../../shared/ConfirmationDialogHeaderComponent'

export interface IRemoveRoleConfirmationDialogProps {
  onConfirmHandler: () => void
  onDenyHandler: () => void
  usedInitiativeCount: number
}

export const RemoveRoleConfirmationDialog: React.FC<IRemoveRoleConfirmationDialogProps> = React.memo(
  ({onConfirmHandler, onDenyHandler, usedInitiativeCount}) => {
    return (
      <ConfirmDialog
        header={<ConfirmationDialogHeaderComponent messageKey={'scenario.widget.delete.role'} />}
        testID="delete-role-confirmation-dialog"
        confirmText={<Translate messageKey={'form.button.delete'} />}
        denyText={<Translate messageKey={'form.button.cancel'} />}
        onConfirmClick={onConfirmHandler}
        onDenyClick={onDenyHandler}
      >
        <Text>
          <Translate
            messageKey={'sp.form.button.delete.message'}
            args={[
              usedInitiativeCount,
              // change static text to translations
              usedInitiativeCount > 1 ? 'initiatives' : 'initiative',
            ]}
          />
        </Text>
      </ConfirmDialog>
    )
  }
)

RemoveRoleConfirmationDialog.displayName = 'RemoveRoleConfirmationDialog'
