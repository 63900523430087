import {primary, Text} from '@phoenix/all'
import {css} from 'emotion'
import * as React from 'react'
import styled from 'react-emotion'
import {useSelector} from 'react-redux'
import {planDurationSelector} from '../../../../../../data'
import {hoursForMeasurementsSelector} from '../../../../../../data/plan/selectors/hoursForMeasurementsSelector'
import {timelineCellMonthWidthSelector} from '../../../../../../data/plan/selectors/timelineCellMonthWidthSelector'
import {timelineMonthPeriodsSelector} from '../../../../../../data/plan/selectors/timelineMonthperiodsSelector'
import {timelineWidthOnMonthViewSelector} from '../../../../../../data/plan/selectors/timelineWidthOnMonthViewSelector'
import {IPeriod} from '../../../../../../shared/models'
import {LEFT_SECTION_WIDTH} from './constans'
import {Translate} from '../../../../../shared/Translate'

const HeaderStyled = styled('div')<{containerWidth: string}>`
  display: flex;
  position: sticky;
  top: 0;
  z-index: 1000;
  background: ${primary.white()};
  width: ${(props) => props.containerWidth};
`

const HeaderMonthStyled = styled('div')<{cellWidth: number}>`
  color: ${primary.gray(200)};
  text-transform: uppercase;
  min-width: ${(props) => props.cellWidth}px;
  padding: 11px 0;

  & + & {
    span {
      border-left: 1px solid ${primary.gray(200)};
    }
  }

  span {
    width: 100%;
    text-align: center;
    display: block;
    padding: 3px 0;
  }
`

const headerRightClass = css`
  flex-grow: 1;
  display: flex;
  border-bottom: 1px solid ${primary.gray(200)};
`

const headerLeftClass = css`
  min-width: ${LEFT_SECTION_WIDTH}px;
  border-bottom: 1px solid ${primary.gray(200)};
  flex-direction: column;
  justify-content: center;
  display: flex;
  padding-left: 16px;
  position: sticky;
  left: 0;
  background-color: ${primary.white()};
`

const leftTitleClass = css`
  color: ${primary.gray(600)};
  border-right: 1px solid ${primary.gray(200)};
`

export const RoleDistributionTableHeaderComponent: React.FC = React.memo(() => {
  const periods = useSelector(timelineMonthPeriodsSelector)
  const timelineWidth = useSelector(timelineWidthOnMonthViewSelector)
  const planDuration = useSelector(planDurationSelector)
  const useHoursForMeasurements = useSelector(hoursForMeasurementsSelector)

  const cellWidth = useSelector(timelineCellMonthWidthSelector)

  const fteHoursMessageKey = React.useMemo(
    () => (useHoursForMeasurements ? 'job.roles.hour' : 'job.roles.fte'),
    [useHoursForMeasurements]
  )

  return (
    <HeaderStyled containerWidth={planDuration > 1 ? `${timelineWidth}px` : 'auto'}>
      <div className={headerLeftClass} data-testid="roles-left-title">
        <Text className={leftTitleClass}>
          <Translate messageKey={fteHoursMessageKey} />
        </Text>
      </div>

      <div className={headerRightClass} data-testid="roles-months-wrapper">
        {periods.map((period: IPeriod) => (
          <HeaderMonthStyled cellWidth={cellWidth} key={period.label}>
            <Text.Micro>{period.label}</Text.Micro>
          </HeaderMonthStyled>
        ))}
      </div>
    </HeaderStyled>
  )
})

RoleDistributionTableHeaderComponent.displayName = 'RoleDistributionTableHeaderComponent'
