import {Moment} from 'moment'
import React, {useMemo} from 'react'
import styled from 'react-emotion'
import {timelineSelector} from '../../../../data/plan'
import {connect} from 'react-redux'
import {ITimeline} from '../../../../shared/models/timeline'
import UpArrowFilledSmallIcon from 'phoenix-icons/dist/UpArrowFilledSmallIcon.js'

interface ITimelineResolvedIconStyledProps {
  left: number
}

interface ITimelineResolvedIconComponentProps {
  timeline: ITimeline
  startDate: Moment
  period: Moment
}

const TimelineResolvedIconStyled = styled('div')<ITimelineResolvedIconStyledProps>`
  left: ${(props) => props.left}px;
  width: 16px;
  height: 16px;
  position: absolute;
  transition: all 0.05s;
`

export const TimelineResolvedIcon: React.FunctionComponent<ITimelineResolvedIconComponentProps> = React.memo(
  (props) => {
    const calculateBubblePosition = useMemo(() => {
      const position = props.period.diff(props.startDate, 'month') * props.timeline.cellWidth

      return position + props.timeline.cellWidth / 2 - 10
    }, [props.period, props.timeline.cellWidth, props.startDate])
    return (
      <TimelineResolvedIconStyled
        left={calculateBubblePosition}
        className={`resolve-icon-${props.period.format('YYYY-MM')}`}
      >
        <UpArrowFilledSmallIcon />
      </TimelineResolvedIconStyled>
    )
  }
)

const mapStateToProps = (state) => ({
  timeline: timelineSelector(state),
})

TimelineResolvedIcon.displayName = 'TimelineResolvedIcon'

export const TimelineResolvedIconComponent = connect(mapStateToProps)(TimelineResolvedIcon)
