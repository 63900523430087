import moment from 'moment'
import {Moment} from 'moment'
import {
  ICompetingInitiative,
  IDestructuredRoleConflict,
  IInitiative,
  IInitiativeRole,
  IPeriodConflict,
  IRoleConflict,
} from '../../models/initiative'
import {IDistribution} from '../../models/initiative/IDistribution'
import {IScenarioRole} from '../../models/scenario'
import {filterInitiativesByPeriod, hasDistributionPositiveValue} from './ConflictHelper'
import {IUsedResourceData} from './IUsedResourceData'
import {YEAR_MONTH_FORMAT} from '../../constants/dateFormats'
import forEach from 'lodash/forEach'

export function calculateSingleRoleConflicts(
  scenarioRole: IScenarioRole,
  initiatives: IInitiative[],
  startDate?: Moment,
  endDate?: Moment
): Map<string, IRoleConflict> {
  const roleConflicts: Map<string, IRoleConflict> = new Map()

  // when available is not filled or no initiatives uses this role
  if (scenarioRole.availableValue === null || scenarioRole.requiredValue === null) {
    return roleConflicts
  }

  let conflictedMonths = detectConflictedMonths(scenarioRole)
  if (conflictedMonths.length === 0) {
    return roleConflicts
  }

  // intersect conflicted months and calculation period
  if (startDate && endDate) {
    conflictedMonths = conflictedMonths.filter((month) =>
      moment(month).isBetween(startDate, endDate, 'd', '[]')
    )
  }

  // filter possible conflicted initiatives
  let filteredInitiatives = initiatives
  if (startDate && endDate) {
    filteredInitiatives = filterInitiativesByPeriod(initiatives, startDate, endDate)
  }

  const roleUsagePerMonth: Map<string, IUsedResourceData> = new Map()

  for (const initiative of filteredInitiatives) {
    // initiative has not used this role
    const initiativeRole = initiative.people.roles.find((r) => {
      return r.id === scenarioRole.role.id && hasDistributionPositiveValue(r.distribution!)
    })
    if (initiativeRole === undefined) {
      continue
    }

    // calculate period conflicts for initiative
    const periodStart =
      startDate && startDate.isAfter(initiative.dates.startDate)
        ? startDate
        : initiative.dates.startDate
    const periodEnd =
      endDate && endDate.isBefore(initiative.dates.endDate) ? endDate : initiative.dates.endDate
    const month = periodStart.clone()

    const monthConflicts: IPeriodConflict[] = calculateMonthConflicts(
      month,
      periodEnd,
      conflictedMonths,
      initiativeRole,
      roleUsagePerMonth,
      initiative,
      scenarioRole
    )
    if (
      startDate &&
      endDate &&
      (initiative.dates.startDate.isBefore(startDate) || initiative.dates.endDate.isAfter(endDate))
    ) {
      if (
        initiative.conflicts !== null &&
        initiative.conflicts.roleConflicts.get(scenarioRole.role.id)
      ) {
        const existingRoleConflict = initiative.conflicts.roleConflicts.get(scenarioRole.role.id)
        for (const periodConflict of existingRoleConflict!.periodConflicts) {
          if (periodConflict.period.isBefore(startDate)) {
            monthConflicts.unshift(periodConflict)
          } else if (periodConflict.period.isAfter(endDate)) {
            monthConflicts.push(periodConflict)
          }
        }
      }
    }

    if (monthConflicts.length > 0) {
      roleConflicts.set(
        initiative.order.toString(),
        createRoleConflict(scenarioRole.role.id, scenarioRole.role.name, monthConflicts)
      )
    }
  }

  return roleConflicts
}

function calculateMonthConflicts(
  month,
  periodEnd,
  conflictedMonths,
  initiativeRole,
  roleUsagePerMonth,
  initiative,
  scenarioRole
) {
  const monthConflicts: IPeriodConflict[] = []
  while (month.isBefore(periodEnd)) {
    const monthKey = month.format('YYYY-MM')
    if (
      conflictedMonths.includes(monthKey) &&
      hasDistributionMonthConflict(monthKey, initiativeRole.distribution!)
    ) {
      const roleUsage: IUsedResourceData = roleUsagePerMonth.has(monthKey)
        ? roleUsagePerMonth.get(monthKey)!
        : {
            usedAmount: 0,
            competingInitiatives: [],
          }
      const monthConflict = calculateMonthConflict(scenarioRole, initiativeRole, month, roleUsage)
      if (monthConflict !== null) {
        monthConflicts.push(monthConflict)
      }
      roleUsagePerMonth.set(monthKey, {
        usedAmount: roleUsage.usedAmount + initiativeRole.distribution![monthKey],
        competingInitiatives: [
          ...roleUsage.competingInitiatives,
          {
            id: initiative.id,
            priority: initiative.order,
          },
        ],
      })
    }
    month = month.add(1, 'month')
  }
  return monthConflicts
}

function hasDistributionMonthConflict(monthKey: string, distribution: IDistribution): boolean {
  return distribution[monthKey] !== 0
}

function createRoleConflict(roleId: string, roleName: string, periodConflicts: IPeriodConflict[]) {
  const missingRoleCount = Math.max(
    ...periodConflicts.map((periodConflict) => periodConflict.missingAmount)
  )
  const totalRoleCount = Math.max(
    ...periodConflicts.map((periodConflict) => periodConflict.totalAmount)
  )
  const competingInitiatives: ICompetingInitiative[] = []
  const existingInitiatives = new Map()
  for (const periodConflict of periodConflicts) {
    periodConflict.competingInitiatives.forEach((initiative) => {
      if (!existingInitiatives.has(initiative.id)) {
        competingInitiatives.push(initiative)
        existingInitiatives.set(initiative.id, true)
      }
    })
  }
  competingInitiatives.sort((i1, i2) => i1.priority - i2.priority)
  return {
    roleId,
    roleName,
    periodConflicts,
    missingRoleCount,
    totalRoleCount,
    competingInitiatives,
  }
}

function calculateMonthConflict(
  scenarioRole: IScenarioRole,
  initiativeRole: IInitiativeRole,
  month: Moment,
  usedRoleData: IUsedResourceData
): IPeriodConflict | null {
  const monthKey = month.format('YYYY-MM')
  const available = scenarioRole.availablePerPeriod[monthKey]
  const roleCount = initiativeRole.distribution![monthKey]

  if (available! >= usedRoleData.usedAmount + roleCount!) {
    return null
  } else {
    return {
      period: month.clone(),
      missingAmount: usedRoleData.usedAmount + roleCount! - available!,
      totalAmount: available!,
      competingInitiatives: usedRoleData.competingInitiatives,
    }
  }
}

function detectConflictedMonths(scenarioRole: IScenarioRole): string[] {
  const conflictedMonths: string[] = []
  for (const key of Object.keys(scenarioRole.requiredPerPeriod)) {
    const available = scenarioRole.availablePerPeriod[key]
    if (scenarioRole.requiredPerPeriod[key] > available!) {
      conflictedMonths.push(key)
    }
  }
  return conflictedMonths
}

const getTotalAmount = (periodAvailableValue: number | undefined, availableValue: number) => {
  return typeof periodAvailableValue !== 'undefined' ? periodAvailableValue : availableValue || 0
}

export function destructureConflicts(
  periodConflicts: IPeriodConflict[] | null,
  distribution: IDistribution | null,
  availablePerPeriod: Record<string, number>,
  availableValue = 0
): IDestructuredRoleConflict | null {
  let conflicts: IDestructuredRoleConflict | null = null

  if (periodConflicts) {
    periodConflicts.forEach((item) => {
      const date = item.period.format(YEAR_MONTH_FORMAT)

      if (!conflicts) {
        conflicts = {}
      }

      conflicts[date] = {
        missingAmount: item.missingAmount,
        totalAmount: getTotalAmount(availablePerPeriod[date], availableValue),
      }
    })

    if (distribution) {
      forEach(distribution, (item, dateStr) => {
        if (!conflicts![dateStr]) {
          conflicts![dateStr] = {
            missingAmount: 0,
            totalAmount: getTotalAmount(availablePerPeriod[dateStr], availableValue),
          }
        }
      })
    }
  }

  return conflicts
}
