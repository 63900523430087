import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import moment, {Moment} from 'moment'
import {
  IBudgetsMonth,
  IInitiative,
  IPlan,
  IPublishedInitiativeObj,
  IRole,
  IScenario,
  IScenarioBudgets,
  IScenarioPeople,
  IScenarioRole,
  IScenarioRoleChange,
  IYearlyBudget,
} from '../models'
import {IDistribution} from '../models/initiative/IDistribution'
import {distributionToMap, mapToArray} from '../utilities'
import {calculateConflicts} from './conflicts/ConflictHelper'
import {
  deserializeInitiative,
  getMaxValueOfDistribution,
  serializeInitiative,
} from './InitiativeHelper'
import {
  calculateScenarioBudgetCosts,
  calculateScenarioBudgetTotal,
  calculateScenarioBudgetUtilization,
} from './scenario/ScenarioBudgetCalculationHelper'
import {calculateNetValueTotal} from './scenario/ScenarioNetValueCaluclationHelper'
import {
  calculateAvailableTotal,
  calculateRequiredTotal,
  calculateRequiredValue,
} from './scenario/ScenarioRolesCalculationHelper'
import {MONTHS_COUNT_IN_YEAR} from './TimelineHelper'
import {primary, secondary} from '@phoenix/all'
import {YEAR_MONTH_FORMAT} from '../constants/dateFormats'
import nanoid from 'nanoid'

export function createScenario(
  hasFinancialAccess,
  isInitial = false,
  startDate: Moment,
  order = 0
): IScenario {
  const id = nanoid()
  return {
    id,
    isInitial,
    order,
    isPublished: false,
    description: null,
    initiatives: [],
    publishedInitiativesCount: 0,
    people: {
      available: 0,
      required: 0,
      roles: [],
    },
    budgets: hasFinancialAccess
      ? {
          peopleCosts: 0,
          fixedCosts: 0,
          costs: 0,
          total: 0,
          utilization: 0,
          distribution: new Map([
            [
              moment().year().toString(),
              {
                total: 0,
                budgetMonths: generateBudgetMonths(startDate),
              },
            ],
          ]),
        }
      : null,
    netValue: hasFinancialAccess ? 0 : null,
    createdOn: null,
    conflictsCount: null,
    lastUpdatedOn: null,
  }
}

export function deserializeScenario(
  scenarioJson,
  startDate: Moment,
  duration: number,
  order: number,
  usePeopleCostOnConflictCalculation: boolean,
  hasFinancialAccess
): IScenario {
  const initiatives = scenarioJson.initiatives.map((initiativeJson, index) =>
    deserializeInitiative(
      initiativeJson,
      index + 1,
      usePeopleCostOnConflictCalculation,
      hasFinancialAccess
    )
  )
  const people = deserializeScenarioPeople(
    scenarioJson.roleDistributions,
    initiatives,
    startDate,
    duration
  )
  const budgets = hasFinancialAccess
    ? deserializeScenarioBudgets(
        scenarioJson.budgets,
        startDate,
        duration,
        initiatives,
        usePeopleCostOnConflictCalculation
      )
    : null
  const netValue = hasFinancialAccess ? calculateNetValueTotal(initiatives) : null
  return {
    id: scenarioJson.id,
    order,
    description: scenarioJson.description,
    isInitial: scenarioJson.isInitial,
    isPublished: scenarioJson.isPublished,
    people,
    budgets,
    netValue,
    initiatives,
    publishedInitiativesCount: calculateScenarioPublishedInitiativesCount(initiatives),
    conflictsCount: null,
    lastUpdatedOn: moment(scenarioJson.lastUpdatedOn),
    createdOn: moment(scenarioJson.createdOn),
  }
}

function deserializeScenarioPeople(
  rolesJson,
  initiatives: IInitiative[],
  planStartDate: Moment,
  planDuration: number
): IScenarioPeople {
  const requiredRoles = retrieveRequiredRolesMap(initiatives)

  const roles = rolesJson.map((roleJson) => {
    const roleId = roleJson.role.ID
    const requiredPerPeriod = requiredRoles[roleId] ? requiredRoles[roleId].requiredPerPeriod : {}
    const initiativeCount = requiredRoles[roleId] ? requiredRoles[roleId].initiativeCount : 0
    return deserializeScenarioRole(
      roleJson,
      requiredPerPeriod,
      initiativeCount,
      planStartDate,
      planDuration
    )
  })
  return {
    available: calculateAvailableTotal(roles),
    required: calculateRequiredTotal(roles),
    roles,
  }
}

function deserializeScenarioRole(
  roleJson,
  requiredPerPeriod,
  initiativeCount,
  planStartDate: Moment,
  planDuration: number
): IScenarioRole {
  const changes = deserializeScenarioChanges(roleJson.changes)
  const planEndDate = planStartDate.clone().add(planDuration, 'year').subtract(1, 'month')

  return {
    role: {
      id: roleJson.role.ID,
      name: roleJson.role.name,
      costPerHour: roleJson.role.costPerHour || null,
    },
    changes,
    usedInitiativeCount: initiativeCount,
    availableValue: getMaxValueOfDistribution(roleJson.distribution),
    availablePerPeriod:
      deserializeDistribution(roleJson.distribution, planStartDate, planEndDate) || {},
    requiredPerPeriod: requiredPerPeriod,
    requiredValue: calculateRequiredValue(requiredPerPeriod),
  }
}

export function deserializeDistribution(
  distributionJson,
  startDate: Moment,
  endDate: Moment
): IDistribution {
  const distribution: IDistribution = {}
  const startDateClone = startDate.clone()

  while (startDateClone.isSameOrBefore(endDate)) {
    const key = startDateClone.format('YYYY-MM')

    if (key in distributionJson) {
      distribution[key] = distributionJson[key]
    } else {
      distribution[key] = 0
    }

    startDateClone.add(1, 'month')
  }

  return distribution
}

function retrieveRequiredRolesMap(initiatives: IInitiative[]) {
  const requiredRoles = {}
  for (const initiative of initiatives) {
    for (const role of initiative.people.roles) {
      const condition = role.distribution

      if (condition) {
        if (!requiredRoles[role.id]) {
          requiredRoles[role.id] = {
            requiredPerPeriod: {},
            initiativeCount: 0,
          }
        }

        requiredRoles[role.id].initiativeCount++
        const requiredPerPeriod = requiredRoles[role.id].requiredPerPeriod

        for (let i = 0; i < initiative.dates.duration; i++) {
          const period = initiative.dates.startDate.clone().add(i, 'month').format('YYYY-MM')
          const roleCount = role.distribution![period]

          requiredPerPeriod[period]
            ? (requiredPerPeriod[period] += roleCount || 0)
            : (requiredPerPeriod[period] = roleCount)
        }
      }
    }
  }
  return requiredRoles
}

function deserializeScenarioChanges(changesJson): IScenarioRoleChange[] {
  const changes: IScenarioRoleChange[] = []
  for (const key in changesJson) {
    changes.push({
      yearMonthDate: moment.utc(key),
      change: changesJson[key],
    })
  }
  return changes.sort((change1, change2) => {
    if (change1.yearMonthDate.isBefore(change2.yearMonthDate)) {
      return -1
    } else if (change2.yearMonthDate.isBefore(change1.yearMonthDate)) {
      return 1
    } else {
      return 0
    }
  })
}

function deserializeScenarioBudgets(
  budgetsJson,
  startDate: Moment,
  duration: number,
  initiatives: IInitiative[],
  usePeopleCostOnConflictCalculation: boolean
): IScenarioBudgets {
  const distribution =
    budgetsJson.length > 0
      ? distributionToMap(
          budgetsJson.map((budget, index: number) => {
            const budgetStartDate = startDate.clone().add(index, 'year')
            return getYearlyBudget(budget, budgetStartDate)
          }),
          startDate
        )
      : generateBudgetDistributions(duration, startDate)
  const total = calculateScenarioBudgetTotal(distribution)
  const {peopleCosts, fixedCosts, costs} = calculateScenarioBudgetCosts(
    initiatives,
    usePeopleCostOnConflictCalculation
  )

  const utilization = calculateScenarioBudgetUtilization(costs, total)

  return {
    distribution,
    total,
    peopleCosts,
    fixedCosts,
    costs,
    utilization,
  }
}

function getYearlyBudget(budget, startDate: Moment): IYearlyBudget {
  const yearlyBudget: IYearlyBudget = {
    total: budget.total,
    budgetMonths: [],
  }

  for (let i = 0; i < 12; i++) {
    const yearMonthDate = startDate.clone().add(i, 'month').format('YYYY-MM')
    yearlyBudget.budgetMonths.push({
      yearMonthDate: moment.utc(yearMonthDate),
      amount: budget.distribution[yearMonthDate] ? budget.distribution[yearMonthDate] : 0,
    })
  }

  return yearlyBudget
}

export function serializeScenario(scenario: IScenario): Record<string, unknown> {
  return {
    id: scenario.lastUpdatedOn !== null ? scenario.id : null,
    description: scenario.description,
    isInitial: scenario.isInitial,
    budgets: scenario.budgets
      ? mapToArray(scenario.budgets.distribution).map((item) => {
          return {
            total: item.total,
            distribution: serializeBudgetMonths(item.budgetMonths),
          }
        })
      : null,
    initiatives: scenario.initiatives.map((initiative) => serializeInitiative(initiative)),
    roleDistributions: scenario.people.roles.map((scenarioRole) => {
      return {
        role: {
          ID: scenarioRole.role.id,
        },
        changes: serializeScenarioChanges(scenarioRole.changes),
        distribution: scenarioRole.availablePerPeriod,
      }
    }),
    createdOn: null,
    lastUpdatedOn: null,
  }
}

function serializeBudgetMonths(budgetMonths: IBudgetsMonth[]) {
  interface IBudgetsObject {
    [name: string]: number
  }

  const budgetsObject: IBudgetsObject = {}
  budgetMonths.forEach((budgetMonth) => {
    const yearMonthDate = budgetMonth.yearMonthDate.format('YYYY-MM')
    budgetsObject[yearMonthDate] = budgetMonth.amount!
  })

  return budgetsObject
}

function serializeScenarioChanges(changes: IScenarioRoleChange[]) {
  const changesJson = {}
  for (const change of changes) {
    changesJson[change.yearMonthDate.format('YYYY-MM')] = change.change
  }
  return changesJson
}

export function generateBudgetDistributions(
  planDuration: number,
  startDate: Moment
): Map<string, IYearlyBudget> {
  const distributions: Map<string, IYearlyBudget> = new Map()
  for (let i = 0; i < planDuration; i++) {
    const distribution: IYearlyBudget = {
      total: 0,
      budgetMonths: generateBudgetMonths(startDate.clone().add(i, 'year')),
    }
    distributions.set(startDate.clone().add(i, 'year').year().toString(), distribution)
  }

  return distributions
}

function generateBudgetMonths(startDate: Moment): IBudgetsMonth[] {
  const budgetMonths: IBudgetsMonth[] = []
  const periodStartDate = startDate.clone()

  for (let i = 0; i < MONTHS_COUNT_IN_YEAR; i++) {
    const month = {
      yearMonthDate: periodStartDate.clone(),
      amount: 0,
    }

    budgetMonths.push(month)
    periodStartDate.add(1, 'months')
  }

  return budgetMonths
}

export const hasScenarioChanges = (plan: IPlan, currentScenario: IScenario | null): boolean => {
  let hasChanges = false
  if (currentScenario !== null) {
    const scenario = plan.scenarios.find((scenarioItem) => scenarioItem.id === currentScenario.id)
    hasChanges = scenario
      ? !isEqual(serializeScenario(scenario!), serializeScenario(currentScenario))
      : false
  }
  return hasChanges
}

export function createScenarioRole(
  role: IRole,
  planStartDate: Moment,
  planDuration: number
): IScenarioRole {
  return {
    role,
    availableValue: 0,
    availablePerPeriod: generateRoleDistribution(planStartDate, planDuration, 0),
    requiredValue: 0,
    requiredPerPeriod: {},
    usedInitiativeCount: 0,
    changes: [],
  }
}

export function generateRoleDistribution(
  planStartDate: Moment,
  planDuration: number,
  availableValue: number
): IDistribution {
  const startDate = planStartDate.clone()
  const endDate = planStartDate.clone().add(planDuration, 'year')
  const distribution = {}

  while (startDate.isBefore(endDate)) {
    distribution[startDate.format(YEAR_MONTH_FORMAT)] = availableValue
    startDate.add(1, 'month')
  }

  return distribution
}

export function cloneScenario(scenario: IScenario, order = 0): IScenario {
  const scenarioClone = cloneDeep(scenario)
  const initiatives = scenarioClone.initiatives.map((initiative) => {
    return {
      ...initiative,
      lastPublishedDate: null,
    }
  })
  const id = nanoid()
  return {
    ...scenarioClone,
    isInitial: false,
    isPublished: false,
    order,
    id,
    initiatives,
    publishedInitiativesCount: 0,
    createdOn: null,
    lastUpdatedOn: null,
  }
}

export function calculateScenarioConflictCount(
  scenario: IScenario,
  usePeopleCostOnConflictCalculation: boolean,
  hasFinancialAccess
): number {
  const conflicts = calculateConflicts(
    scenario.people.roles,
    scenario.budgets,
    scenario.initiatives,
    usePeopleCostOnConflictCalculation,
    hasFinancialAccess
  )
  return [...conflicts.values()].filter((c) => !c.isPriorityConflict).length
}

export function calculateScenarioPublishedInitiativesCount(initiatives: IInitiative[]) {
  return initiatives.reduce(
    (count, initiative) => (initiative.lastPublishedDate !== null ? count + 1 : count),
    0
  )
}

export const hasScenarioConflict = (scenario: IScenario): boolean => {
  return scenario.initiatives.some((initiative: IInitiative) => initiative.conflicts)
}

export const getLineColor = (hasPublishedScenario: boolean, isPublishedScenario: boolean) => {
  if (hasPublishedScenario) {
    if (isPublishedScenario) {
      return secondary.green()
    } else {
      return primary.gray(300)
    }
  } else {
    return primary.blue()
  }
}

export function updateScenarioPublishedInitiatives(
  scenario: IScenario,
  publishedInitiatives: Map<string, IPublishedInitiativeObj>,
  publishedScenarioId: string
): IScenario {
  const initiatives: IInitiative[] = scenario.initiatives.map((initiative) => {
    if (publishedInitiatives.has(initiative.id)) {
      const refObject = initiative.refObject
        ? initiative.refObject
        : {
            id: publishedInitiatives.get(initiative.id)!.refObject.id,
            plannedStartDate: null,
            plannedCompletionDate: null,
          }
      const lastPublishedDate =
        scenario.id === publishedScenarioId
          ? publishedInitiatives.get(initiative.id)!.lastPublishedDate
          : null
      return {
        ...initiative,
        refObject,
        lastPublishedDate,
      }
    } else {
      return initiative
    }
  })

  return {
    ...scenario,
    isPublished: scenario.id === publishedScenarioId,
    initiatives,
    publishedInitiativesCount: calculateScenarioPublishedInitiativesCount(initiatives),
  }
}

export const restructureBudgetsDistribution = (distribution: Map<string, IYearlyBudget>) => {
  const newDistribution = {}
  distribution.forEach((item) => {
    item.budgetMonths.forEach((details) => {
      newDistribution[details.yearMonthDate.format(YEAR_MONTH_FORMAT)] = details.amount
    })
  })

  return newDistribution
}
