import {
  UNAUTHORIZED_STATUS_CODE,
  UNAUTHORIZED_ERROR_MESSAGE,
  UNKNOWN_FAILURE_ERROR_MESSAGE,
  UNAUTHENTICATED_STATUS_CODE,
  UNAUTHENTICATED_ERROR_MESSAGE,
  NO_CONTENT_STATUS_CODE,
} from '../../shared/constants'
const BASE_URL = '/esp'

export function loadPlanListFilters(queryParams: {[key: string]: unknown} = {}) {
  const url = `${BASE_URL}/plans/filters?fields=*`

  return makeRequest(url, 'GET', queryParams)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function loadPlans(queryParams: {[key: string]: unknown}) {
  const url = `${BASE_URL}/plans`

  return makeRequest(url, 'GET', queryParams)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function loadPlanCount(queryParams: {[key: string]: unknown}) {
  const url = `${BASE_URL}/plans/count`

  return makeRequest(url, 'GET', queryParams)
}

export function deletePlans(ids: string[]) {
  const url = `${BASE_URL}/plans`

  return makeRequest(url, 'DELETE', null, ids)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function makeRequest(url: string, method, data: Record<string, unknown> | null, body?) {
  const options = {
    method: method || 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    body: body ? JSON.stringify(body) : undefined,
  }
  let queryParams = ''
  if (typeof data === 'object' && data !== null) {
    queryParams = Object.keys(data)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(String(data[key]))}`)
      .join('&')
  }
  const queryUrl = queryParams ? `?${queryParams}` : ''

  return fetch(`${url}${queryUrl}`, options).then((response) => {
    if (!response.ok) {
      let errorMessage = UNKNOWN_FAILURE_ERROR_MESSAGE
      switch (response.status) {
        case UNAUTHORIZED_STATUS_CODE:
          errorMessage = UNAUTHORIZED_ERROR_MESSAGE
          break
        case UNAUTHENTICATED_STATUS_CODE:
          errorMessage = UNAUTHENTICATED_ERROR_MESSAGE
          break
      }
      throw new Error(errorMessage)
    }
    if (response.status !== NO_CONTENT_STATUS_CODE) {
      return response.json()
    }
  })
}
