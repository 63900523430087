const language = () => (navigator.languages && navigator.languages[0]) || navigator.language

export function ceilToOneDecimal(num: number): number {
  const decimalDigits = num.toString().split('.')[1]

  if (decimalDigits && decimalDigits.length > 1) {
    return Math.ceil(num * 10) / 10
  }

  return num
}

export const formatNumber = (
  count: number | null,
  decimals = 1,
  isAbbreviated = true,
  precision = 4
): string | null => {
  if (count !== null) {
    if (isAbbreviated) {
      count = precise(count, precision)
      const COUNT_ABBRS = ['', 'K', 'M', 'B', 'T', 'P', 'E']
      const processedCount = Math.abs(Math.floor(count))
      const i = processedCount === 0 ? 0 : Math.floor(Math.log(Math.abs(count)) / Math.log(1000))
      const result = parseFloat((Math.abs(count) / Math.pow(1000, i)).toFixed(decimals))

      const minusSignPrefix = count < 0 ? '-' : ''
      return `${minusSignPrefix}${result + COUNT_ABBRS[i]}`
    } else {
      const formatter = getIntlNumberFormat(language(), {
        minimumFractionDigits: 0,
        maximumFractionDigits: 3,
      })
      return formatter.format(count)
    }
  } else {
    return null
  }
}

export const formatCurrency = (
  money: number | null,
  currency: string,
  isAbbreviated = true
): string | null => {
  if (typeof money === 'number') {
    const currencyObj = getCurrencyObj(money, currency)
    const {formatter} = currencyObj
    if (isAbbreviated) {
      const {currencySymbol} = currencyObj
      const isCurrencyAtStart = isNaN(Number(formatter.format(1).charAt(0)))
      if (isCurrencyAtStart) {
        if (money < 0) {
          return `-${currencySymbol}${formatNumber(Math.abs(money))}`
        } else {
          return `${currencySymbol}${formatNumber(Math.abs(money))}`
        }
      } else {
        return `${formatNumber(money)} ${currencySymbol}`
      }
    } else {
      return formatter.format(money)
    }
  } else {
    return null
  }
}

export function formatPercentage(value: number, isAbbreviated: boolean): string {
  return `${formatNumber(value, 1, isAbbreviated)}%`
}

const getIntlNumberFormat = (lang = language(), options) => {
  return new Intl.NumberFormat(lang, options)
}

function precise(num, precision: number): number {
  return +parseFloat(num).toPrecision(precision)
}

const getCurrencyObj = (money = 0, currency: string) => {
  const formatter = getIntlNumberFormat(language(), {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
  })

  const formatDetails = formatter.formatToParts(money)
  return {
    currencySymbol: formatDetails.find((detail) => detail.type === 'currency')!.value,
    formatter,
  }
}

export const formatFloating = (number: number, precision: number): number => {
  return number % 1 === 0 ? number : Number(number.toFixed(precision))
}
