import * as React from 'react'
import styled, {css} from 'react-emotion'
import {ICarouselContext, CarouselContext} from '../../../../../shared/context/CarouselContext'
import {CAROUSEL_ITEM_WIDTH} from './CarouselItemComponent'

interface IRoleCarouselComponentProps {
  className?: string
  testID?: string
}

const CarouselListStyled = styled('ul')<{carouselStep: number}>`
  transition: all 0.3s;
  transform: translateX(-${(props) => props.carouselStep * CAROUSEL_ITEM_WIDTH}px);
  display: flex;
`

const carouselWrapper = css`
  max-width: 180px;
  overflow: hidden;
`

export const CarouselComponent: React.FC<IRoleCarouselComponentProps> = React.memo(
  ({children, className, testID}) => {
    const {
      carouselStep: {step},
    }: ICarouselContext = React.useContext(CarouselContext)

    return (
      <div data-testid={testID} className={carouselWrapper}>
        <CarouselListStyled carouselStep={step} className={className || ''}>
          {children}
        </CarouselListStyled>
      </div>
    )
  }
)

CarouselComponent.displayName = 'CarouselComponent'
