import {css} from 'emotion'
import * as React from 'react'
import {connect} from 'react-redux'
import ErrorFilledSmallIcon from 'phoenix-icons/dist/ErrorFilledSmallIcon.js'
import {primary, secondary} from '@phoenix/all'

import {currentScenarioBudgetDistributionSelector} from '../../../../../data/scenario/selectors/currentScenarioBudgetDistributionSelector'
import {calculateRemaining} from '../../../../../shared/helpers'
import {IYearlyBudget} from '../../../../../shared/models/scenario'
import {PopperComponent, TooltipContentComponent} from '../../../../shared'
import {Translate} from '../../../../shared/Translate'

interface IExceededBudgetWarningComponent {
  distribution: Map<string, IYearlyBudget>
}

const budgetExceededWarningIconClass = css`
  line-height: 0;
  position: absolute;
  top: 0;
  left: 0;
  margin: -8px 0 0 -8px;
  background: ${primary.gray(0)};
  z-index: 1;
`

const hasExceededBudget = (distribution) => {
  return [...distribution.values()].some((item) => {
    return calculateRemaining(item) < 0
  })
}

export const ExceededBudgetWarning: React.FunctionComponent<IExceededBudgetWarningComponent> = (
  props: IExceededBudgetWarningComponent
) => {
  const hasExceededItem = hasExceededBudget(props.distribution)

  if (hasExceededItem) {
    return (
      <PopperComponent
        reference={
          <div className={budgetExceededWarningIconClass} data-testid="budget-widget-warning">
            <ErrorFilledSmallIcon color={secondary.yellow()} />
          </div>
        }
        content={
          <TooltipContentComponent
            text={<Translate messageKey={'scenario.widget.budget.warning'} />}
          />
        }
        showOnOver={true}
        placement="top"
      />
    )
  } else {
    return null
  }
}

const mapStateToProps = (state) => ({
  distribution: currentScenarioBudgetDistributionSelector(state),
})

export const ExceededBudgetWarningComponent = connect(mapStateToProps)(ExceededBudgetWarning)
