export const planMessageKeys = [
  'plan.list.name',
  'plan.list.owner',
  'plan.list.shared.with',
  'sp.plan.list.number.of.scenario',
  'plan.list.created.on',
  'plan.list.updated.on',
  'sp.plan.new',
  'messages.plan.noaccess',
  'messages.plan.contact.administrator',
  'sp.dialog.header.plan.delete',
  'sp.dialog.header.plan.delete.plural',
  'form.button.delete',
  'form.button.cancel',
  'sp.plan.delete.message',
  'sp.messages.plan.delete.plural',
  'sp.messages.plan.delete',
  'plan.list.pagination.plans',
  'plan.list.pagination.plan',
]
