import {primary} from '@phoenix/all'
import {css} from 'react-emotion'

export const posr = css`
  position: relative;
`

export const inputWrapper = css`
  margin: 0 12px 0 0;
  ${posr};
`

export const inputStyles = css`
  padding-right: 22px;
  width: 200px;

  color: ${primary.gray(500)};
  &::placeholder,
  &:-ms-input-placeholder {
    color: ${primary.gray(300)};
  }
  box-shadow: none;
  border: none;
  border-bottom: 1px solid ${primary.gray(100)};
  &:focus {
    border-bottom: 1px solid ${primary.blue()};
  }
`

export const closeIconStyles = css`
  position: absolute;
  right: 2px;
  top: 50%;
  margin-top: -8px;
  cursor: pointer;
`
