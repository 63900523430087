import {createSelector} from 'reselect'
import {AccessMode} from '../../../shared/enums'
import {projectAccessSelector} from './projectAccessSelector'

export const projectNoAccessSelector = createSelector(
  projectAccessSelector,
  (accessMode: AccessMode | null) => {
    return accessMode === AccessMode.view || accessMode === AccessMode.noAccess
  }
)
