import {primary} from '@phoenix/all'
import {css} from 'emotion'
import * as React from 'react'
import ReactDOM from 'react-dom'

import {SlidingPaneBodyComponent} from './SlidingPaneBodyComponent'
import {SlidingPaneFooterComponent} from './SlidingPaneFooterComponent'
import {SlidingPaneHeaderComponent} from './SlidingPaneHeaderComponent'
import styled from 'react-emotion'

interface ISlidingPaneComponentProps {
  show: boolean
}

interface IChildComponents {
  Header: React.FC
  Body: React.FC
  Footer: React.FC
}

const ANIMATION_TIME = 0.4

const SlidingPaneStyled = styled('div')`
  width: 100vw;
  height: 100%;
  z-index: 2;
  top: 0;
  right: 0;
  position: absolute;
  background-color: rgba(48, 55, 68, 0);
  transition: all ${ANIMATION_TIME}s;
`

const slidingPaneContentClass = css`
  transition: all ${ANIMATION_TIME}s;
  transform: translateX(100%);
  display: flex;
  flex-direction: column;
  width: calc(100vw - 112px);
  background-color: ${primary.gray(0)};
  float: right;
  height: 100%;
`
const showClass = css`
  background-color: rgba(48, 55, 68, 0.5);
  .${slidingPaneContentClass} {
    transform: translateX(0);
  }
`

export const SlidingPaneComponent: React.FC<ISlidingPaneComponentProps> & IChildComponents = (
  props
) => {
  const [show, setShow] = React.useState(false)

  React.useEffect(() => {
    const timeOutDelay = props.show ? 100 : ANIMATION_TIME * 1000

    setTimeout(() => {
      setShow(props.show)
    }, timeOutDelay)
  }, [props.show])

  const rootElement = document.getElementById('page-sidebar') || document.body

  const showSlidingPane = props.show ? props.show : show
  const slidingClass = props.show ? show : props.show

  return showSlidingPane
    ? ReactDOM.createPortal(
        <SlidingPaneStyled
          data-testid="sliding-pane"
          className={`sliding-pane ${slidingClass ? showClass : ''}`}
        >
          <div className={slidingPaneContentClass}>{props.children}</div>
        </SlidingPaneStyled>,
        rootElement
      )
    : null
}

SlidingPaneComponent.Header = SlidingPaneHeaderComponent
SlidingPaneComponent.Body = SlidingPaneBodyComponent
SlidingPaneComponent.Footer = SlidingPaneFooterComponent
