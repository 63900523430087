import ProjectSmallIcon from 'phoenix-icons/dist/ProjectSmallIcon.js'
import {css} from 'emotion'
import * as React from 'react'
import {IRefObject} from '../../../../shared/models/'
import {PopperComponent, resetFocusStateClass, TooltipContentComponent} from '../../../shared'
import {cx} from 'react-emotion'
import {Translate} from '../../../shared/Translate'
import type {DataUriParams} from 'phoenix-icons/dist'
import {getObjectLink} from '@quicksilver/objectmetadata'

const ImportIconClass = css`
  margin-right: 7px;
  margin-top: 2px;
  display: inline-block;
`

interface IInitiativeImportIcon {
  refObject: IRefObject | null
  className?: string
  icon?: React.FunctionComponent<DataUriParams>
  tooltipMessageKey?: string
}

const PROJ_OBJ_CODE = 'PROJ'

export const LinkedProjectIcon: React.FunctionComponent<IInitiativeImportIcon> = React.memo(
  (props) => {
    const projectURL = (ID: string) => `${getObjectLink({objCode: PROJ_OBJ_CODE, ID}).to}/overview`

    const ProjectIcon = props.icon ? props.icon : ProjectSmallIcon

    return (
      <React.Fragment>
        {props.refObject && (
          <div className={cx(ImportIconClass, props.className)}>
            <PopperComponent
              reference={
                <span>
                  <a
                    className={resetFocusStateClass}
                    target={'_blank'}
                    rel="noreferrer"
                    data-testid={'project-id'}
                    href={projectURL(props.refObject.id)}
                  >
                    <ProjectIcon />
                  </a>
                </span>
              }
              modifiers={{
                preventOverflow: {
                  boundariesElement: 'window',
                },
              }}
              placement="top"
              content={
                <TooltipContentComponent
                  text={
                    <Translate
                      messageKey={props.tooltipMessageKey || 'initiative.import.project.icon'}
                    />
                  }
                />
              }
              showOnOver={true}
              isInsidePortal={true}
            />
          </div>
        )}
      </React.Fragment>
    )
  }
)

LinkedProjectIcon.displayName = 'LinkedProjectIcon'
