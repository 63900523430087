import {Moment} from 'moment'
import forEach from 'lodash/forEach'
import reduce from 'lodash/reduce'
import size from 'lodash/size'
import {YEAR_MONTH_FORMAT} from '../../constants/dateFormats'
import {IScenarioRoleChange} from '../../models'
import {IInitiative, IInitiativeRole} from '../../models/initiative'
import {IDistribution} from '../../models/initiative/IDistribution'
import {IScenarioPeople, IScenarioRole} from '../../models/scenario'
import {getDistributionSum, getMaxValueOfDistribution} from '../InitiativeHelper'
import {track} from '../PendoHelper'
import {createScenarioRole} from '../ScenarioHelper'

export function processPeopleAfterScenarioRoleDelete(
  people: IScenarioPeople,
  deletedRoleIds: Set<string>
): IScenarioPeople {
  const roles = people.roles.filter((r) => !deletedRoleIds.has(r.role.id))

  return {
    available: calculateAvailableTotal(roles),
    required: calculateRequiredTotal(roles),
    roles,
  }
}

export function processPeopleAfterScenarioRoleUpdate(
  people: IScenarioPeople,
  scenarioRoles: IScenarioRole[],
  isAddingRoleFromDistribution = false
): IScenarioPeople {
  let roles: IScenarioRole[]

  if (isAddingRoleFromDistribution) {
    roles = [...scenarioRoles, ...people.roles]
  } else {
    roles = people.roles.map((peopleRole) => {
      const changedRole = scenarioRoles.find(
        (scenarioRole) => peopleRole.role.id === scenarioRole.role.id
      )
      return changedRole ? changedRole : peopleRole
    })
  }

  return {
    available: calculateAvailableTotal(roles),
    required: people.required,
    roles,
  }
}

export function processPeopleAfterInitiativeRoleChanges(
  people: IScenarioPeople,
  oldInitiative: IInitiative,
  newInitiative: IInitiative,
  planInfo,
  useHoursForMeasurements
): IScenarioPeople {
  if (oldInitiative.people.roles.length === 0 && newInitiative.people.roles.length === 0) {
    return people
  } else {
    const intersection: any = getIntersection(
      oldInitiative.people.roles,
      newInitiative.people.roles
    )

    const scenarioRolesSet = {}
    people.roles.forEach((scenarioRole) => (scenarioRolesSet[scenarioRole.role.id] = scenarioRole))

    let scenarioRoles = processAdd(
      people.roles,
      scenarioRolesSet,
      intersection.added,
      newInitiative.dates.startDate,
      newInitiative.dates.duration,
      planInfo,
      useHoursForMeasurements
    )
    scenarioRoles = processUpdate(
      scenarioRoles,
      scenarioRolesSet,
      intersection.updated,
      newInitiative.dates.startDate,
      newInitiative.dates.duration,
      useHoursForMeasurements
    )
    scenarioRoles = processDelete(
      scenarioRoles,
      scenarioRolesSet,
      intersection.deleted,
      newInitiative.dates.startDate,
      newInitiative.dates.duration,
      useHoursForMeasurements
    )

    const required = calculateRequiredTotal(scenarioRoles)
    return {
      available: people.available,
      required,
      roles: scenarioRoles,
    }
  }
}

export function processPeopleAfterInitiativesImport(
  people: IScenarioPeople,
  initiatives: IInitiative[],
  planInfo,
  useHoursForMeasurements: boolean
): IScenarioPeople {
  let tempPeopleRoles = people.roles
  for (const initiative of initiatives) {
    if (initiative.people.roles.length !== 0) {
      const scenarioRolesSet = {}
      tempPeopleRoles.forEach(
        (scenarioRole) => (scenarioRolesSet[scenarioRole.role.id] = scenarioRole)
      )

      tempPeopleRoles = processAdd(
        tempPeopleRoles,
        scenarioRolesSet,
        initiative.people.roles,
        initiative.dates.startDate,
        initiative.dates.duration,
        planInfo,
        useHoursForMeasurements
      )
    }
  }

  const required = calculateRequiredTotal(tempPeopleRoles)

  return {
    available: people.available,
    required,
    roles: tempPeopleRoles,
  }
}

export function processPeopleAfterInitiativeCopy(
  people: IScenarioPeople,
  initiative: IInitiative,
  planInfo,
  useHoursForMeasurements: boolean
): IScenarioPeople {
  if (initiative.people.roles.length === 0) {
    return people
  }

  const scenarioRolesSet = {}
  people.roles.forEach((scenarioRole) => (scenarioRolesSet[scenarioRole.role.id] = scenarioRole))

  const scenarioRoles = processAdd(
    people.roles,
    scenarioRolesSet,
    initiative.people.roles,
    initiative.dates.startDate,
    initiative.dates.duration,
    planInfo,
    useHoursForMeasurements
  )

  const required = calculateRequiredTotal(scenarioRoles)
  return {
    available: people.available,
    required,
    roles: scenarioRoles,
  }
}

export function processPeopleAfterInitiativeDelete(
  people: IScenarioPeople,
  initiative: IInitiative,
  useHoursForMeasurements: boolean
): IScenarioPeople {
  if (initiative.people.roles.length === 0) {
    return people
  } else {
    const scenarioRolesSet = {}
    people.roles.forEach((scenarioRole) => (scenarioRolesSet[scenarioRole.role.id] = scenarioRole))

    const scenarioRoles = processDelete(
      people.roles,
      scenarioRolesSet,
      initiative.people.roles,
      initiative.dates.startDate,
      initiative.dates.duration,
      useHoursForMeasurements
    )

    const required = calculateRequiredTotal(scenarioRoles)
    return {
      available: people.available,
      required,
      roles: scenarioRoles,
    }
  }
}

export function processPeopleAfterConflictsResolved(
  people: IScenarioPeople,
  resolvedRoles: IScenarioRole[]
): IScenarioPeople {
  const scenarioPeopleRolesMap = new Map()
  const resolvedRolesMap = new Map()
  const roles: IScenarioRole[] = []

  resolvedRoles.forEach((r) => resolvedRolesMap.set(r.role.id, r))
  people.roles.forEach((r) => scenarioPeopleRolesMap.set(r.role.id, r))

  const mergedMap = new Map([...scenarioPeopleRolesMap, ...resolvedRolesMap])
  mergedMap.forEach((item) => roles.push(item))

  return {
    available: calculateAvailableTotal(roles),
    required: people.required,
    roles: roles,
  }
}

export function processPeopleAfterInitiativeDateChange(
  people: IScenarioPeople,
  oldInitiative: IInitiative,
  newInitiative: IInitiative,
  useHoursForMeasurements: boolean
): IScenarioPeople {
  if (oldInitiative.people.roles.length === 0 && newInitiative.people.roles.length === 0) {
    return people
  } else {
    const scenarioRolesSet = {}
    people.roles.forEach((scenarioRole) => (scenarioRolesSet[scenarioRole.role.id] = scenarioRole))

    const updatedRoles = {}
    for (const role of newInitiative.people.roles) {
      const scenarioRole = scenarioRolesSet[role.id]
      let requiredRolePerPeriod = getUpdatedRequiredRolePerPeriod(
        scenarioRole.requiredPerPeriod,
        oldInitiative.dates.startDate,
        oldInitiative.dates.duration,
        oldInitiative.people.roles.find((r) => r.id === role.id)!.distribution,
        true
      )
      requiredRolePerPeriod = getUpdatedRequiredRolePerPeriod(
        requiredRolePerPeriod,
        newInitiative.dates.startDate,
        newInitiative.dates.duration,
        role.distribution
      )
      updatedRoles[role.id] = {
        ...scenarioRole,
        requiredPerPeriod: requiredRolePerPeriod,
        requiredValue: calculateRequiredValue(requiredRolePerPeriod, useHoursForMeasurements),
      }
    }

    const scenarioRoles = people.roles.map((r) =>
      r.role.id in updatedRoles ? updatedRoles[r.role.id] : r
    )
    const required = calculateRequiredTotal(scenarioRoles)

    return {
      available: people.available,
      required,
      roles: scenarioRoles,
    }
  }
}

const getMaxRequiredPerPeriod = (requiredPerPeriod: {[date: string]: number}) => {
  const requiredValues: number[] = Object.values(requiredPerPeriod).filter(
    (value) => value !== null
  )
  return requiredValues.length > 0 ? Math.max(...requiredValues) : 0
}

const getTotalRequiredPerPeriod = (requiredPerPeriod: {[date: string]: number}) => {
  return reduce(requiredPerPeriod, (sum, value) => sum + (value || 0), 0)
}

export function calculateRequiredValue(
  requiredPerPeriod: {[date: string]: number},
  useHoursForMeasurements?: boolean
): number | null {
  return useHoursForMeasurements
    ? getTotalRequiredPerPeriod(requiredPerPeriod)
    : getMaxRequiredPerPeriod(requiredPerPeriod)
}

export function calculateRequiredTotal(scenarioRoles: IScenarioRole[]): number | null {
  const roles = scenarioRoles.filter((r) => r.requiredValue !== null)
  return roles.length > 0 ? roles.reduce((sum, role) => sum + role.requiredValue!, 0) : 0
}

export function calculateAvailableTotal(scenarioRoles: IScenarioRole[]): number | null {
  const roles = scenarioRoles.filter((r) => r.availableValue !== null)
  return roles.length > 0 ? roles.reduce((sum, role) => sum + role.availableValue!, 0) : 0
}

export function calculateAvailableForRole(
  changes: IScenarioRoleChange[],
  availablePerPeriod: IDistribution,
  useHoursForMeasurements: boolean
) {
  const newAvailablePerPeriod = {...availablePerPeriod}

  changes.forEach((change) => {
    newAvailablePerPeriod[change.yearMonthDate.format(YEAR_MONTH_FORMAT)] += change.change
  })

  return {
    availablePerPeriod: newAvailablePerPeriod,
    availableValue: useHoursForMeasurements
      ? getDistributionSum(newAvailablePerPeriod)
      : getMaxValueOfDistribution(newAvailablePerPeriod),
  }
}

function getUpdatedRequiredRolePerPeriod(
  requiredPerPeriod: Record<string, number>,
  periodStart: Moment,
  periodDuration: number,
  distribution: IDistribution | number | null,
  subtract?: boolean
) {
  return getUpdatedRequiredRolePerPeriodWithDistribution(
    requiredPerPeriod,
    periodStart,
    periodDuration,
    distribution,
    subtract
  )
}

function getUpdatedRequiredRolePerPeriodWithDistribution(
  requiredPerPeriod: Record<string, number>,
  periodStart: Moment,
  periodDuration: number,
  distribution: IDistribution | number | null,
  subtract?: boolean
) {
  if (distribution === null) {
    return requiredPerPeriod
  } else {
    const updatedRequiredPerPeriod = {
      ...requiredPerPeriod,
    }
    for (let i = 0; i < periodDuration; i++) {
      const periodKey = periodStart.clone().add(i, 'month').format('YYYY-MM')

      if (distribution[periodKey] === undefined) {
        continue
      }

      const count = subtract ? -distribution[periodKey] : distribution[periodKey]
      if (!updatedRequiredPerPeriod[periodKey]) {
        updatedRequiredPerPeriod[periodKey] = count
      } else {
        updatedRequiredPerPeriod[periodKey] = updatedRequiredPerPeriod[periodKey] + count
      }
    }
    return updatedRequiredPerPeriod
  }
}

export function getIntersection(before: IInitiativeRole[], after: IInitiativeRole[]) {
  const set = {}
  const updated: IInitiativeRole[] = []
  const added: IInitiativeRole[] = []
  const deleted: IInitiativeRole[] = []

  before.forEach((role) => (set[role.id] = role))
  after.forEach((role) => {
    if (role.id in set) {
      if (hasRoleDistributionChanged(role, set[role.id])) {
        const distribution = {}

        forEach(role.distribution, (count, key) => {
          distribution[key] = (count || 0) - (set[role.id].distribution[key] || 0)
        })

        updated.push({...role, distribution})
      }

      set[role.id] = undefined
    } else {
      added.push(role)
    }
  })

  for (const roleId in set) {
    if (set[roleId] !== undefined) {
      deleted.push(set[roleId])
    }
  }

  return {
    added,
    updated,
    deleted,
  }
}

function hasRoleDistributionChanged(afterRole, beforeRole): boolean {
  let isChanged = false

  if (size(afterRole.distribution) !== size(beforeRole.distribution)) {
    return true
  }

  forEach(afterRole.distribution, (count, key) => {
    if (count !== beforeRole.distribution[key]) {
      isChanged = true
      return
    }
  })

  return isChanged
}

function processAdd(
  scenarioRoles: IScenarioRole[],
  scenarioRolesSet: Record<string, IScenarioRole>,
  initiativeRoles: IInitiativeRole[],
  periodStartDate: Moment,
  periodDuration: number,
  planInfo,
  useHoursForMeasurements: boolean
): IScenarioRole[] {
  const newScenarioRoles: IScenarioRole[] = []
  const updatedRoles = {}
  for (const role of initiativeRoles) {
    if (role.id in scenarioRolesSet) {
      const scenarioRole = scenarioRolesSet[role.id]
      const updatedRequiredRolePerPeriod = getUpdatedRequiredRolePerPeriod(
        scenarioRole.requiredPerPeriod,
        periodStartDate,
        periodDuration,
        role.distribution
      )

      updatedRoles[role.id] = {
        ...scenarioRole,
        usedInitiativeCount: scenarioRole.usedInitiativeCount + 1,
        requiredPerPeriod: updatedRequiredRolePerPeriod,
        requiredValue: calculateRequiredValue(
          updatedRequiredRolePerPeriod,
          useHoursForMeasurements
        ),
      }
    } else {
      track('Add job role from initiative', {
        roleId: role.id,
        roleName: role.name,
      })
      const {id, name, costPerHour} = role
      const newScenarioRole = createScenarioRole(
        {id, name, costPerHour},
        planInfo.planStartDate,
        planInfo.planDuration
      )

      newScenarioRole.requiredValue = calculateRequiredValue(
        role.distribution!,
        useHoursForMeasurements
      )

      newScenarioRole.requiredPerPeriod = getUpdatedRequiredRolePerPeriod(
        {},
        periodStartDate,
        periodDuration,
        role.distribution
      )
      newScenarioRole.usedInitiativeCount = 1
      newScenarioRoles.push(newScenarioRole)
    }
  }

  return [
    ...newScenarioRoles,
    ...scenarioRoles.map((r) => (r.role.id in updatedRoles ? updatedRoles[r.role.id] : r)),
  ]
}

function processUpdate(
  scenarioRoles: IScenarioRole[],
  scenarioRolesSet: Record<string, IScenarioRole>,
  initiativeRoles: IInitiativeRole[],
  periodStartDate: Moment,
  periodDuration: number,
  useHoursForMeasurements
): IScenarioRole[] {
  const updatedRoles = {}
  for (const role of initiativeRoles) {
    const scenarioRole = scenarioRolesSet[role.id]
    const updatedRequiredRolePerPeriod = getUpdatedRequiredRolePerPeriod(
      scenarioRole.requiredPerPeriod,
      periodStartDate,
      periodDuration,
      role.distribution
    )

    updatedRoles[role.id] = {
      ...scenarioRole,
      requiredPerPeriod: updatedRequiredRolePerPeriod,
      requiredValue: calculateRequiredValue(updatedRequiredRolePerPeriod, useHoursForMeasurements),
    }
  }
  return scenarioRoles.map((r) => (r.role.id in updatedRoles ? updatedRoles[r.role.id] : r))
}

function processDelete(
  scenarioRoles: IScenarioRole[],
  scenarioRolesSet: Record<string, IScenarioRole>,
  initiativeRoles: IInitiativeRole[],
  periodStartDate: Moment,
  periodDuration: number,
  useHoursForMeasurements: boolean
): IScenarioRole[] {
  let processedRoles = [...scenarioRoles]
  const updatedRoles = {}
  for (const role of initiativeRoles) {
    const scenarioRole = scenarioRolesSet[role.id]
    if (
      scenarioRole.usedInitiativeCount === 1 &&
      (scenarioRole.availableValue === null || scenarioRole.availableValue === 0)
    ) {
      processedRoles = processedRoles.filter((r) => r.role.id !== role.id)
    } else {
      const updatedRequiredRolePerPeriod =
        scenarioRole.usedInitiativeCount > 1
          ? getUpdatedRequiredRolePerPeriod(
              scenarioRole.requiredPerPeriod,
              periodStartDate,
              periodDuration,
              role.distribution,
              true
            )
          : {}

      updatedRoles[role.id] = {
        ...scenarioRolesSet[role.id],
        usedInitiativeCount: scenarioRole.usedInitiativeCount - 1,
        requiredPerPeriod: updatedRequiredRolePerPeriod,
        requiredValue: calculateRequiredValue(
          updatedRequiredRolePerPeriod,
          useHoursForMeasurements
        ),
      }
    }
  }

  return processedRoles.map((r) => (r.role.id in updatedRoles ? updatedRoles[r.role.id] : r))
}
