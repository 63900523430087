import {createSelector} from 'reselect'
import {AccessMode} from '../../../shared/enums'
import {financialAccessSelector} from './financialAccessSelector'
import {viewAccessSelector} from './viewAccessSelector'

export const financialViewAccessSelector = createSelector(
  [viewAccessSelector, financialAccessSelector],
  (viewAccess: boolean, financialAccessMode: AccessMode | null): boolean => {
    return viewAccess || financialAccessMode === AccessMode.view
  }
)
