import * as React from 'react'
import styled from 'react-emotion'
import {connect} from 'react-redux'

import {currentPlanSelector} from '../../../data/plan'

import {isReorderSelector} from '../../../data/viewOptions'
import {isNewPlan} from '../../../shared/helpers'
import {IPlan, IScenario} from '../../../shared/models'
import {fullHeightFlexClass} from '../../shared'
import {PlanDialogComponent} from '../PlanDialogComponent'
import {ScenarioHeaderComponent} from './Header/ScenarioHeaderComponent'
import {ScenarioListWrapperComponent} from './ScenarioListWrapperComponent'

export interface IScenarioDetailsComponentProps {
  plan: IPlan
  isReordering: boolean
  navigateToListFunction: () => void
  onSuccessfulSaveFunction: () => void
  currentScenario: IScenario
}

interface IScenarioDetailsStylesProps {
  isReordering: boolean
}

const ScenarioDetailsStyles = styled('div')<IScenarioDetailsStylesProps>`
  ${(props) =>
    props.isReordering &&
    ` 
        &, & * {
            user-select: none;
        }   
    `};
  ${fullHeightFlexClass};
  width: 100%;
  height: 100%;
  overflow: auto;
  min-width: 1024px;
`

export const ScenarioDetails: React.FunctionComponent<IScenarioDetailsComponentProps> = (props) => {
  const isPlanNameExists: boolean = props.plan.name === '' // this is for hiding dialog when coming back form compare scenario page and plan is not saved

  return (
    <ScenarioDetailsStyles isReordering={props.isReordering} data-testid="scenario-page">
      <ScenarioHeaderComponent
        navigateToListFunction={props.navigateToListFunction}
        onSuccessfulSaveFunction={props.onSuccessfulSaveFunction}
        currentScenario={props.currentScenario}
      />
      <ScenarioListWrapperComponent currentScenario={props.currentScenario} />
      {isNewPlan(props.plan) && isPlanNameExists && (
        <div data-testid="new-plan-dialog-test-wrapper">
          <PlanDialogComponent navigateToListFunction={props.navigateToListFunction} />
        </div>
      )}
    </ScenarioDetailsStyles>
  )
}

const mapStateToProps = (state) => ({
  plan: currentPlanSelector(state),
  isReordering: isReorderSelector(state),
})

// TODO: rename ScenarioListComponent to ScenarioContentComponent and put EditSidebar there

export const ScenarioComponent = connect(mapStateToProps)(ScenarioDetails)
