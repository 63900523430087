import {primary} from '@phoenix/all'
import {css} from 'emotion'
import * as React from 'react'

interface ITooltipContentComponent {
  text?: JSX.Element | string
  testID?: string
  className?: string
  hasList?: boolean
}

const tooltipClass = css`
  background-color: ${primary.gray()};
  color: ${primary.gray(0)};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  font-size: 12px;
  line-height: 16px;
  border-radius: 2px;
  max-width: 265px;
  z-index: 100;
  padding: 4px 8px;
`

const tooltipText = css`
  display: inline-block;
`

const tooltipTextWithPaddingClass = css`
  padding-top: 4px;
`

export const TooltipContentComponent: React.FunctionComponent<ITooltipContentComponent> = React.memo(
  (props) => {
    return (
      <div
        className={`${tooltipClass} ${props.className ? props.className : ''}`}
        data-testid={props.testID ? props.testID : ''}
      >
        {props.text && (
          <span
            className={`${tooltipText} ${props.hasList ? tooltipTextWithPaddingClass : ''}`}
            data-testid={`${props.testID ? `${props.testID}-label` : ''}`}
          >
            {props.text}
          </span>
        )}

        {props.children}
      </div>
    )
  }
)

TooltipContentComponent.displayName = 'TooltipContentComponent'
