import {
  CHANGE_BUDGET_RESOLVABLE,
  CHANGE_BUDGET_WIDGET_ANIMATION,
  CHANGE_IMPORT_LOADING_MODE,
  CHANGE_LOADING_MODE,
  CHANGE_PEOPLE_WIDGET_ANIMATION,
  CHANGE_RESOLVABLE_ROLES,
  CHANGE_VISIBLE_CONFLICT,
  OPEN_EDIT_SIDEBAR,
  REORDER_DRAG_AND_DROP,
  SLIDE_COPIED_SCENARIO,
  TOGGLE_BUDGET_WIDGET,
  TOGGLE_IMPORT_DIALOG,
  SET_DRAGGING_INITIATIVE_ID,
  TOGGLE_PEOPLE_WIDGET,
  TOGGLE_PUBLISH_DIALOG,
  UPDATE_RESIZE_LINE_POSITION,
  UPDATE_ROLE_CONFLICT_MONTH_INDEX,
  UPDATE_BUDGET_CONFLICT_MONTH_INDEX,
  OPEN_ROLE_DISTRIBUTION_DIALOG,
} from './actions'

export function toggleEditSidebar(isOpen: boolean) {
  return {
    type: OPEN_EDIT_SIDEBAR,
    isOpen,
  }
}

export function toggleImportDialog(isOpen: boolean) {
  return {
    type: TOGGLE_IMPORT_DIALOG,
    isOpen,
  }
}

export function togglePublishDialog(id: string | null) {
  return {
    type: TOGGLE_PUBLISH_DIALOG,
    id,
  }
}

export function updateLineResizePosition(resizeLinePosition: number | null) {
  return {
    type: UPDATE_RESIZE_LINE_POSITION,
    resizeLinePosition,
  }
}

export function reorderDragAndDrop(isReordering = true) {
  return {
    type: REORDER_DRAG_AND_DROP,
    isReordering,
  }
}

export function togglePlanLoading(isLoading: boolean) {
  return {
    type: CHANGE_LOADING_MODE,
    isLoading,
  }
}
export function toggleImportLoading(isLoading: boolean) {
  return {
    type: CHANGE_IMPORT_LOADING_MODE,
    isLoading,
  }
}

export function changeResolvableRoles(resolvableRoles: string[]) {
  return {
    type: CHANGE_RESOLVABLE_ROLES,
    resolvableRoles,
  }
}

export function toggleBudgetResolvable(isBudgetResolvable: boolean) {
  return {
    type: CHANGE_BUDGET_RESOLVABLE,
    isBudgetResolvable,
  }
}

export function changeVisibleConflict(conflict: string | null) {
  return {
    type: CHANGE_VISIBLE_CONFLICT,
    conflict,
  }
}

export function togglePeopleWidgetAnimation(shouldAnimatePeopleWidget: boolean) {
  return {
    type: CHANGE_PEOPLE_WIDGET_ANIMATION,
    shouldAnimatePeopleWidget,
  }
}

export function toggleBudgetWidgetAnimation(shouldAnimateBudgetWidget: boolean) {
  return {
    type: CHANGE_BUDGET_WIDGET_ANIMATION,
    shouldAnimateBudgetWidget,
  }
}

export function toggleSlideScenarioAnimation(shouldSlideScenario: boolean) {
  return {
    type: SLIDE_COPIED_SCENARIO,
    shouldSlideScenario,
  }
}

export function togglePeopleWidget(isPeopleWidgetExpanded: boolean) {
  return {
    type: TOGGLE_PEOPLE_WIDGET,
    isPeopleWidgetExpanded,
  }
}

export function toggleBudgetWidget(isBudgetWidgetExpanded: boolean) {
  return {
    type: TOGGLE_BUDGET_WIDGET,
    isBudgetWidgetExpanded,
  }
}

export function setDraggingInitiativeId(draggingInitiativeId: boolean) {
  return {
    type: SET_DRAGGING_INITIATIVE_ID,
    draggingInitiativeId,
  }
}

export function updateRoleConflictMonthIndex(roleConflictMonthIndex: number) {
  return {
    type: UPDATE_ROLE_CONFLICT_MONTH_INDEX,
    roleConflictMonthIndex,
  }
}

export function updateBudgetConflictMonthIndex(budgetConflictMonthIndex: number) {
  return {
    type: UPDATE_BUDGET_CONFLICT_MONTH_INDEX,
    budgetConflictMonthIndex,
  }
}

export function toggleRoleDistributionDialog(isOpenRoleDistributionDialog: boolean) {
  return {
    type: OPEN_ROLE_DISTRIBUTION_DIALOG,
    isOpenRoleDistributionDialog,
  }
}
