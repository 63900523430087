import React, {useCallback, useEffect, useState} from 'react'
import ToolbarButton from '../components/ToolbarButton'
import type {IToolbarWidgetProps} from '@wf-mfe/lists'
import type {IconComponent} from 'phoenix-icons/dist'
import {redrockClient} from '../../../../shared/L10n'

export interface GetApiParameter {
  setIsChecked: React.Dispatch<React.SetStateAction<boolean>>
  setLabelMessageKey: React.Dispatch<React.SetStateAction<string | undefined>>
  setLabelMessageArgs: React.Dispatch<React.SetStateAction<string | undefined>>
  setText: React.Dispatch<React.SetStateAction<string | undefined>>
  setTextMessageKey: React.Dispatch<React.SetStateAction<string | undefined>>
  enableBadge: React.Dispatch<React.SetStateAction<undefined>>
}

export interface IconWidgetOwnProps {
  icon: IconComponent
  label: string
  labelMessageKey: string
  text: string
  textMessageKey: string
  dataTestId: string
  isCheckable: boolean
  showBadge: boolean
  showText: boolean
  getApi: (param: GetApiParameter) => void
}

/**
 * @param props
 * @param {Component} props.icon   A component to render as an icon
 *
 * @param {string} props.label   A label to show as an icon tooltip
 * @param {string} props.labelMessageKey   A message key of label to be shown as an icon tooltip
 *
 * @param {string} props.text   A text to display near the icon. Used only if `showText` is true.
 * @param {string} props.textMessageKey   A message key of text to display near the icon. Used only if `showText` is true.
 * @param {boolean} props.showText   Whenever to show `text` after the icon
 *
 * @param {boolean} props.isCheckable   If true, icon will have persistent 'pressed' state
 *
 * @param {boolean} props.showBadge   If true, a badge will rendered over the icon
 *
 * @param {string} props.dataTestId   An attribute to use as identifier in tests
 *
 * @param {function} props.getApi   A function which will be called with widget public api
 */
function IconWidget(props: IToolbarWidgetProps & IconWidgetOwnProps) {
  const {
    commands,
    primaryCommandId,
    executingCommandId,
    icon,
    label = '',
    labelMessageKey = '',
    text = '',
    textMessageKey = '',
    dataTestId /*, location*/,
    isCheckable = false,
    showBadge = false,
    showText = false,
    getApi,
  } = props

  const [isChecked, setIsChecked] = useState(false)
  const [isBadgeEnabled, enableBadge] = useState(undefined)
  const [textOverride, setText] = useState<string | undefined>()
  const [textMessageKeyOverride, setTextMessageKey] = useState<string | undefined>()
  const [labelMessageKeyOverride, setLabelMessageKey] = useState<string | undefined>()
  const [labelMessageArgsOverride, setLabelMessageArgs] = useState<string | undefined>()

  const handleClick = useCallback(() => {
    commands[primaryCommandId!].execute()
    if (isCheckable) {
      setIsChecked(!isChecked)
    }
  }, [commands, primaryCommandId, isChecked, isCheckable])

  useEffect(() => {
    getApi &&
      getApi({
        setIsChecked,
        setLabelMessageKey,
        setLabelMessageArgs,
        setText,
        setTextMessageKey,
        enableBadge,
      })
  }, [getApi])

  const isDisabled = !!executingCommandId

  const wrapperProps = {
    dataTestId: dataTestId || primaryCommandId,
    onClick: handleClick,
    isDisabled,
    isChecked,
    isBadgeEnabled: isBadgeEnabled !== undefined ? isBadgeEnabled : showBadge,
    icon,
  }

  const effectiveText = textOverride || text

  if (label && (!showText || effectiveText)) {
    return <ToolbarButton {...wrapperProps} tooltipLabel={label} text={effectiveText} />
  }

  const labelMsg = redrockClient.getTextSync(
    labelMessageKeyOverride || labelMessageKey,
    '',
    labelMessageArgsOverride
  )
  const textMsg = redrockClient.getTextSync(textMessageKeyOverride || textMessageKey, '')
  return (
    <ToolbarButton
      {...wrapperProps}
      tooltipLabel={label ? label : labelMsg}
      text={effectiveText ? effectiveText : textMsg}
    />
  )
}

export default React.memo(IconWidget)
