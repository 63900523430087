import * as React from 'react'
import styled from 'react-emotion'

interface ILineComponentProps {
  testID?: string
  leftPosition: number
  rightPosition?: string | number
  lineHeight: string
  className?: string
}

interface ILineStyledProps {
  lineHeight: string
}

const LineStyled = styled('div')<ILineStyledProps>`
  position: absolute;
  z-index: 2;
  width: 1px;
  height: ${(props) => props.lineHeight};
`

export const LineComponent: React.FunctionComponent<ILineComponentProps> = React.memo((props) => {
  return (
    <LineStyled
      className={props.className}
      lineHeight={props.lineHeight}
      data-testid={props.testID}
      style={{
        transform: `translateX(${Math.floor(parseInt(props.leftPosition.toString(), 10) - 1)}px)`,
        right: props.rightPosition ? `right: ${props.rightPosition}` : '',
      }}
    >
      {props.children}
    </LineStyled>
  )
})

LineComponent.displayName = 'LineComponent'
