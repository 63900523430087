import {ArrowDirection} from '../../../../../shared/enums'

export function getArrowDirection(
  value1: number | null,
  value2: number | null
): ArrowDirection | null {
  if (value1 !== null && value2 !== null) {
    if (value1 > value2) {
      return ArrowDirection.ASCENDING
    } else if (value1 < value2) {
      return ArrowDirection.DESCENDING
    }
  }
  return null
}
