import {css, injectGlobal} from 'emotion'
import * as React from 'react'
import {connect} from 'react-redux'

import {viewAccessSelector, isEditSidebarOpenSelector, isLoadingSelector} from '../../data'
import {currentPlanSelector, loadPlan} from '../../data/plan'
import {loadRolesSuccess} from '../../data/globalRoles'
import {currentScenarioSelector} from '../../data/scenario'
import {
  defaultCurrencySelector,
  espAccessSelector,
  loadPermissions,
  loadSettings,
} from '../../data/settings'
import {shouldSlideScenarioSelector} from '../../data/viewOptions'
import {isImportLoadingSelector} from '../../data/viewOptions/selectors/isImportLoadingSelector'
import {AccessMode} from '../../shared/enums'
import {PlanExistance} from '../../shared/enums/PlanExistance'
import {isNewPlan} from '../../shared/helpers'
import {IPlan, IScenario} from '../../shared/models'
import {ErrorPageComponent, fullHeightFlexClass, LoaderComponent} from '../shared'
import {CompareScenarioComponent} from './CompareScenario'
import {FooterComponent} from './FooterComponent'
import {ProjectImportDialogComponent} from './ImportDialog/ProjectImportDialogComponent'
import {PublishScenarioDialogComponent} from './PublishScenario/PublishScenarioDialogComponent'
import {EditSidebarComponent} from './Scenario/EditSidebar'
import {ScenarioWrapperComponent} from './Scenario/ScenarioWrapperComponent'
import {SVGPatternsWrapperComponent} from './SVGPatterns/SVGPatternsWrapperComponent'
import '../shared/LocaleStyles'
import {hasAccessToPlanSelector} from '../../data/settings/selectors/hasAccessToPlanSelector'
import {hasAccessToEspSelector} from '../../data/settings/selectors/hasAccessToEspSelector'
import {RequestAccessComponent} from './RequestAccessComponent'
import {Translate} from '../shared/Translate'
import {translate} from '../../shared/utilities/TranslateHelper'

injectGlobal` 
    .-ms- [data-testid="scenario-header-body"] input,.-ms- [data-testid="edit-sidebar"] input{
        width: 99.9%;
        line-height: 1; 
    }
    .draging {
        user-select: none;
    }
    [data-testid="confirm-dialog-dialog-body"] {
        word-break: normal !important; 
    }
    
    [data-testid="header-people-roles-options"] {
        z-index: 100; 
    }
    
    [data-testid="initiative-roles-no-results"]{
        z-index: 4; 
    }
    
    [data-testid="new-plan-name-error"] {
        position: absolute;
        bottom: -32px;
        left: 0;
    }
    
    #initiative-roles-listbox {
        width: 337px;
    }
    #plan-roles-with-access-listbox {
        width: 334px;
    }
    #plan-roles-with-access-listbox [role="listbox"]{
        max-height: 285px;
    }
    #plan-roles-listbox {
        width: 390px;
    }
    #supply-roles-listbox {
      width: 323px;
       
      [role="listbox"]{
          max-height: 210px;
      }
    }
    #tooltip-container {
        z-index: 1001;
    }
    #options-container {
        z-index: 1000;
    }
    
    *:focus {
        outline: none;
    }
    
    input:focus {
        box-shadow: none;
    }
`
const planComponentWrapperClass = css`
  ${fullHeightFlexClass};
  max-width: 99.99%;
  z-index: 0;
`

const hideHorizontalScrollClass = css`
  overflow-x: hidden;
`

interface IPlanComponentProps {
  planId: string | null
  scenarioId: string | null
  currentPlan: IPlan | null
  currentScenario: IScenario | null
  defaultCurrency: string
  espAccess: AccessMode | null
  viewAccess: boolean
  hasAccessToEsp: boolean
  hasAccessToPlan: boolean
  isLoading: boolean
  planExistence: PlanExistance
  navigateToListFunction: () => void
  loadPlanFunction: (planId: string | null, scenarioId: string | null) => any
  loadPermissionsFunction: (planId) => Promise<any>
  loadSettingsFunction: () => Promise<any>
  rolesLoadFunction: () => Promise<any[]> // TODO: rename
  onSuccessfulSaveFunction: (planId: string, scenarioId: string | null) => void
  onSuccessfulRolesLoadFunction: (roles) => void
  shouldSlideScenario: boolean
  isEditSidebarOpen: boolean
}

interface IPlanComponentState {
  isDataLoaded: boolean
}

export class PlanComponent extends React.Component<IPlanComponentProps, IPlanComponentState> {
  constructor(props) {
    super(props)

    this.state = {
      isDataLoaded: false,
    }
  }

  fetchPermissions = () => {
    this.props.loadPermissionsFunction(this.props.planId).then(() => {
      this.props.loadPlanFunction(this.props.planId, this.props.scenarioId).then(() => {
        this.setState({
          isDataLoaded: true,
        })
      })
    })
  }

  componentDidMount() {
    this.fetchPermissions()
    if (this.props.planId === null) {
      this.props.loadSettingsFunction().then(() => {
        this.setState({
          isDataLoaded: true,
        })
      })
    }

    this.props.rolesLoadFunction().then((roles) => this.props.onSuccessfulRolesLoadFunction(roles))
  }
  componentDidUpdate(prevProps: Readonly<IPlanComponentProps>) {
    if (prevProps.planId !== this.props.planId) {
      this.fetchPermissions()
    }
    if (!this.props.planId) {
      document.title = prevProps.currentPlan?.name || translate('sp.plan.new')
    }
    if (this.props.currentPlan && this.props.currentPlan.name !== prevProps.currentPlan?.name) {
      document.title = this.props.currentPlan.name
    }
  }

  render() {
    if (
      this.props.planId === null &&
      (this.props.espAccess === AccessMode.noAccess || this.props.espAccess === AccessMode.view)
    ) {
      // This is the case when the user with view access or without access is trying to open plans/new route
      this.props.navigateToListFunction()
      return null
    } else if (this.props.espAccess === null || !this.state.isDataLoaded) {
      return null
    } else {
      return (
        <div
          className={`${planComponentWrapperClass} ${
            this.props.shouldSlideScenario ? hideHorizontalScrollClass : ''
          } plan-wrapper`}
        >
          <SVGPatternsWrapperComponent />

          {this.props.currentPlan !== null && (
            <React.Fragment>
              {this.props.currentScenario !== null &&
                (!isNewPlan(this.props.currentPlan!) || this.props.defaultCurrency !== '') && (
                  <ScenarioWrapperComponent
                    navigateToListFunction={this.props.navigateToListFunction}
                    onSuccessfulSaveFunction={this.props.onSuccessfulSaveFunction}
                  />
                )}
              {this.props.currentScenario === null && (
                <CompareScenarioComponent
                  navigateToListFunction={this.props.navigateToListFunction}
                  onSuccessfulSaveFunction={this.props.onSuccessfulSaveFunction}
                />
              )}

              {this.props.isEditSidebarOpen && <EditSidebarComponent />}

              {!this.props.viewAccess && (
                <FooterComponent
                  navigateToListFunction={this.props.navigateToListFunction}
                  onSuccessfulSaveFunction={this.props.onSuccessfulSaveFunction}
                />
              )}
            </React.Fragment>
          )}
          {!this.props.hasAccessToEsp && (
            <ErrorPageComponent
              primaryMessage={<Translate messageKey={'sp.messages.plan.noaccess'} />}
              secondaryMessage={<Translate messageKey={'messages.plan.contact.administrator'} />}
              testId={'noaccess-message'}
            />
          )}
          {this.props.planExistence === PlanExistance.EXIST &&
            this.props.hasAccessToEsp &&
            !this.props.hasAccessToPlan && (
              <RequestAccessComponent
                espAccess={this.props.espAccess!}
                planID={this.props.planId!}
              />
            )}
          {this.props.planExistence === PlanExistance.NOTEXIST && (
            <ErrorPageComponent
              primaryMessage={<Translate messageKey={'sp.messages.plan.deleted'} />}
              testId={'deleted-message'}
            />
          )}
          {this.props.currentScenario !== null && this.props.currentPlan !== null && (
            <ProjectImportDialogComponent />
          )}

          {this.props.currentPlan !== null && <PublishScenarioDialogComponent />}

          <LoaderComponent isLoading={this.props.isLoading} />
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => ({
  currentPlan: currentPlanSelector(state),
  isLoading: isLoadingSelector(state) || isImportLoadingSelector(state),
  currentScenario: currentScenarioSelector(state),
  defaultCurrency: defaultCurrencySelector(state),
  planExistence: state.plan.planExistence,
  espAccess: espAccessSelector(state),
  viewAccess: viewAccessSelector(state),
  hasAccessToEsp: hasAccessToEspSelector(state),
  hasAccessToPlan: hasAccessToPlanSelector(state),
  shouldSlideScenario: shouldSlideScenarioSelector(state),
  isEditSidebarOpen: isEditSidebarOpenSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  loadPlanFunction: (planId, scenarioId) => dispatch(loadPlan(planId, scenarioId)),
  loadPermissionsFunction: (planId) => dispatch(loadPermissions(planId)),
  onSuccessfulRolesLoadFunction: (roles) => dispatch(loadRolesSuccess(roles)),
  loadSettingsFunction: () => dispatch(loadSettings()),
})

export default connect(mapStateToProps, mapDispatchToProps)(PlanComponent)
