import * as React from 'react'
import {connect} from 'react-redux'

import {isEditSidebarOpenSelector, showConflictsSelector} from '../../../../data'
import {changeCurrentInitiative} from '../../../../data/initiative'
import {currentScenarioSelector} from '../../../../data/scenario'
import {viewAccessSelector} from '../../../../data/settings'
import {toggleEditSidebar} from '../../../../data/viewOptions'
import {draggingInitiativeIdSelector} from '../../../../data/viewOptions'
import {IInitiative} from '../../../../shared/models/initiative'
import {IScenario} from '../../../../shared/models/scenario'
import {TimelineBarComponent} from './TimelineBarComponent'
import {TimelineEmptyBarComponent} from './TimelineEmptyBarComponent'
import {peopleCostOnConflictCalculationSelector} from '../../../../data/plan/selectors/peopleCostOnConflictCalculationSelector'

export interface ITimelineBarWrapperComponentProps {
  changeCurrentInitiativeFunction: (
    initiative: IInitiative,
    scenario: IScenario | null,
    usePeopleCostOnConflictCalculation: boolean
  ) => void
  toggleEditSidebarFunction: (isOpen: boolean) => void
  showConflicts: boolean
  currentScenario: IScenario
  initiative: IInitiative
  isRowHovered: boolean
  isViewMode: boolean
  isSidebarIsOpen: boolean
  usePeopleCostOnConflictCalculation: boolean
  draggingInitiativeId: string | null
}

export const TimelineBarWrapper: React.FunctionComponent<ITimelineBarWrapperComponentProps> = React.memo(
  ({
    isViewMode,
    isRowHovered,
    isSidebarIsOpen,
    initiative,
    changeCurrentInitiativeFunction,
    showConflicts,
    currentScenario,
    usePeopleCostOnConflictCalculation,
    toggleEditSidebarFunction,
    draggingInitiativeId,
  }) => {
    const handleOnClick = () => {
      changeCurrentInitiativeFunction(
        initiative!,
        showConflicts ? currentScenario : null,
        usePeopleCostOnConflictCalculation
      )
      toggleEditSidebarFunction(true)
    }

    return (
      <>
        {!isViewMode &&
        !isSidebarIsOpen &&
        (isRowHovered || draggingInitiativeId === initiative.id) ? (
          <TimelineBarComponent
            isRowHovered={isRowHovered}
            initiative={initiative}
            handleOnClick={handleOnClick}
          />
        ) : (
          <TimelineEmptyBarComponent initiative={initiative} handleOnClick={handleOnClick} />
        )}
      </>
    )
  }
)

const mapStateToProps = (state) => ({
  showConflicts: showConflictsSelector(state),
  isViewMode: viewAccessSelector(state),
  draggingInitiativeId: draggingInitiativeIdSelector(state),
  currentScenario: currentScenarioSelector(state),
  isSidebarIsOpen: isEditSidebarOpenSelector(state),
  usePeopleCostOnConflictCalculation: peopleCostOnConflictCalculationSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  changeCurrentInitiativeFunction: (
    initiative,
    currentScenario,
    usePeopleCostOnConflictCalculation
  ) => {
    dispatch(
      changeCurrentInitiative(initiative, currentScenario, usePeopleCostOnConflictCalculation)
    )
  },
  toggleEditSidebarFunction: (isOpen) => dispatch(toggleEditSidebar(isOpen)),
})

TimelineBarWrapper.displayName = 'TimelineBarWrapper'

export const TimelineBarWrapperComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(TimelineBarWrapper)
