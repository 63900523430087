import {primary} from '@phoenix/all'
import {css} from 'emotion'
import * as React from 'react'

const slidingPaneHeaderClass = css`
  font-size: 18px;
  font-weight: 500;
  padding: 5px 16px;
  min-height: 52px;
  color: ${primary.gray(600)};
  align-items: center;
  border-bottom: 1px solid ${primary.gray(200)};
`

export const SlidingPaneHeaderComponent: React.FC = React.memo((props) => {
  return (
    <header className={slidingPaneHeaderClass} data-testid="sliding-pane-header">
      {props.children}
    </header>
  )
})

SlidingPaneHeaderComponent.displayName = 'SlidingPaneHeaderComponent'
