import React from 'react'
import UpCaratSmallIcon from 'phoenix-icons/dist/UpCaratSmallIcon.js'
import DownCaratSmallIcon from 'phoenix-icons/dist/DownCaratSmallIcon.js'
import {Button, primary, Text, Tooltip} from '@phoenix/all'
import {connect} from 'react-redux'
import {financialViewAccessSelector, viewAccessSelector} from '../../../../data/settings'
import {IDestructuredRoleConflict, TPreCalculatedBudget} from '../../../../shared/models/initiative'

import {css} from 'react-emotion'
import {IDistribution} from '../../../../shared/models/initiative/IDistribution'
import {ConflictDetailsDistributionComponent} from './ConflictDetailDistributionComponent'
import {conflictDetailLabelClass} from './roles/StyleHelper'
import {Translate} from '../../../shared/Translate'

export interface ISidebarConflictDetailsComponentProps {
  roleId?: string
  testID: string
  distribution: IDistribution | null
  periodConflictDetails: IDestructuredRoleConflict
  preCalculatedData: TPreCalculatedBudget | null | undefined
  isVisibleConflict: boolean
  expandCollapseHandler: () => void
  isViewMode: boolean
  isFinancialViewMode: boolean
}

const showDetailsClass = css`
  display: flex;
`

const arrowPositionClass = css`
  margin: 2px 0 0 4px;
`

const detailsRow = css`
  display: flex;
  align-items: center;
  &:first-child {
    & > div:first-child {
      padding-top: 11px;
    }
  }
  &:first-of-type {
    .carousel-conflicted-item {
      padding-top: 11px;
    }
  }
`

const textClass = css`
  color: ${primary.kyloRen()};
`

const paddingClass = css`
  padding-right: 32px;
`

const viewModePaddingClass = css`
  padding-right: 8px;
`

const buttonClass = css`
  height: auto;
  border-radius: 0;
  padding: 0;
  margin-top: 8px;
  box-shadow: none;
  font-weight: normal;
`

const tooltipContainerClass = css`
  width: 290px;
`

export const SidebarConflictDetails: React.FunctionComponent<ISidebarConflictDetailsComponentProps> = React.memo(
  (props) => {
    let showDetailsArrow, showDetailsMessageKey, showDetailsTestId

    if (props.isVisibleConflict) {
      showDetailsArrow = <UpCaratSmallIcon color={primary.doctorStrange()} />
      showDetailsMessageKey = 'scenario.hide.details'
      showDetailsTestId = 'hide-allocations-button'
    } else {
      showDetailsArrow = <DownCaratSmallIcon color={primary.doctorStrange()} />
      showDetailsMessageKey = 'scenario.show.details'
      showDetailsTestId = 'show-allocations-button'
    }

    const testId = props.roleId ? `${props.testID}-${props.roleId}` : props.testID
    const distributionWrapperClass = props.isViewMode ? viewModePaddingClass : paddingClass

    return (
      <div data-testid={`${props.testID}-details`}>
        {props.isVisibleConflict && (
          <>
            <div className={detailsRow} data-testid={'available-details'}>
              <div className={conflictDetailLabelClass}>
                <Text className={textClass}>
                  <Translate messageKey={'timeline.available'} />
                </Text>
              </div>
              <div className={distributionWrapperClass}>
                <ConflictDetailsDistributionComponent
                  testID={testId}
                  distribution={props.distribution}
                  periodConflictKey={'totalAmount'}
                  periodDetails={props.periodConflictDetails}
                  preCalculatedData={props.preCalculatedData}
                />
              </div>
            </div>
            <div className={detailsRow} data-testid={'previously-allocated-details'}>
              <div className={conflictDetailLabelClass}>
                <Tooltip
                  content={
                    <div className={tooltipContainerClass}>
                      <Translate
                        messageKey={
                          props.roleId
                            ? 'sp.scenario.previously.allocated.description'
                            : 'sp.scenario.previously.allocated.cost.description'
                        }
                      />
                    </div>
                  }
                  delay={300}
                  position={'top'}
                >
                  <div className={conflictDetailLabelClass}>
                    <Text className={textClass}>
                      <Translate messageKey={'scenario.previously.allocated'} />
                    </Text>
                  </div>
                </Tooltip>
              </div>

              <div className={distributionWrapperClass}>
                <ConflictDetailsDistributionComponent
                  testID={testId}
                  distribution={props.distribution}
                  periodConflictKey={'remainingAmount'}
                  periodDetails={props.periodConflictDetails}
                  preCalculatedData={props.preCalculatedData}
                />
              </div>
            </div>
          </>
        )}

        <div className={detailsRow} data-testid={'overallocated-details'}>
          <div className={conflictDetailLabelClass}>
            <Text className={textClass}>
              <Translate messageKey={'timeline.conflicts.overallocated'} />
            </Text>
          </div>
          <div className={distributionWrapperClass}>
            <ConflictDetailsDistributionComponent
              testID={testId}
              distribution={props.distribution}
              periodConflictKey={'missingAmount'}
              periodDetails={props.periodConflictDetails}
              preCalculatedData={props.preCalculatedData}
            />
          </div>
        </div>

        <Button
          onClick={props.expandCollapseHandler}
          text
          className={buttonClass}
          testID={showDetailsTestId}
        >
          <div className={showDetailsClass}>
            <div>
              <Translate messageKey={showDetailsMessageKey} />
            </div>
            <div className={arrowPositionClass}>{showDetailsArrow}</div>
          </div>
        </Button>
      </div>
    )
  }
)

SidebarConflictDetails.displayName = 'SidebarConflictDetails'

const mapStateToProps = (state) => ({
  isViewMode: viewAccessSelector(state),
  isFinancialViewMode: financialViewAccessSelector(state),
})

export const SidebarConflictDetailsComponent = connect(mapStateToProps)(SidebarConflictDetails)
