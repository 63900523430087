import {css} from 'emotion'
import {Moment} from 'moment'
import * as React from 'react'
import {connect} from 'react-redux'
import {forEach} from 'lodash'
import {Select} from '@phoenix/all'
import {
  currentInitiativeSelector,
  currentScenarioPeopleSelector,
  viewAccessSelector,
  globalRolesSelector,
  showConflictsSelector,
  updateCurrentInitiative,
  changePreCalculatedData,
  preCalculatedDataSelector,
} from '../../../../../data'
import {
  IInitiative,
  IInitiativeDates,
  IPeriodRemainingData,
  IPreCalculatedData,
  IRole,
  IScenarioRole,
} from '../../../../../shared/models'
import {IGlobalRoles} from '../../../../../shared/models/IGlobalRoles'
import {IInitiativeRole} from '../../../../../shared/models/initiative'
import {IDistribution} from '../../../../../shared/models/initiative/IDistribution'
import {PeopleListComponent} from './PeopleListComponent'
import {peopleCostOnConflictCalculationSelector} from '../../../../../data/plan/selectors/peopleCostOnConflictCalculationSelector'
import {translate} from '../../../../../shared/utilities/TranslateHelper'

interface IPeopleProps {
  globalRoles: IGlobalRoles
  scenarioRoles: IScenarioRole[]
  initiativeRoles: IInitiativeRole[]
  isViewMode: boolean
  showConflicts: boolean
  updateCurrentInitiativeRolesFunction: (
    roles,
    showConflicts,
    usePeopleCostOnConflictCalculation: boolean
  ) => void
  usePeopleCostOnConflictCalculation: boolean
  preCalculatedData: IPreCalculatedData
  currentInitiative: IInitiative
  changePreCalculatedDataFunction: (
    preCalculatedData: IPreCalculatedData,
    usePeopleCostOnConflictCalculation: boolean
  ) => void
}

interface IPeopleState {
  isNew: boolean
}

const shadowClass = css`
  position: relative;
  height: 100%;
  p {
    top: 46px;
  }
  #initiative-roles-label {
    display: none;
  }
`

const rolesSelectClass = css`
  > div {
    margin: 0;
  }
`

export class PeopleForm extends React.Component<IPeopleProps, IPeopleState> {
  constructor(props: IPeopleProps) {
    super(props)
    this.state = {
      isNew: false,
    }
  }

  render() {
    const {globalRoles, scenarioRoles, initiativeRoles, isViewMode} = this.props
    const availableRoles = this.getAvailableRoles(globalRoles, scenarioRoles, initiativeRoles)
    return (
      <div className={shadowClass}>
        {!isViewMode && (
          <Select
            className={rolesSelectClass}
            search={true}
            name="initiative-roles"
            options={availableRoles}
            placeholder={translate('form.start.typing.role')}
            onChange={(selectedItem) => this.addRole(selectedItem)}
            textNoResults={translate('global.no.result')}
            testID="initiative-roles"
          />
        )}
        <PeopleListComponent
          isNew={this.state.isNew}
          deleteRoleFunction={this.deleteRole}
          changePeopleCountFunction={this.changePeopleCount}
        />
      </div>
    )
  }

  private getAvailableRoles = (
    globalRoles: IGlobalRoles,
    scenarioRoles: IScenarioRole[],
    initiativeRoles: IRole[]
  ) => {
    const initiativeRolesMap = {}
    initiativeRoles.forEach((r) => (initiativeRolesMap[r.id] = r))

    const scenarioRolesMap = {}
    scenarioRoles.forEach((r) => (scenarioRolesMap[r.role.id] = r.role))

    const scenarioFilteredRoles = scenarioRoles
      .map((r) => r.role)
      .filter((r) => initiativeRolesMap[r.id] === undefined)

    const filteredRoles: IRole[] = []

    forEach(globalRoles, (role, roleId) => {
      if (scenarioRolesMap[roleId] === undefined && initiativeRolesMap[roleId] === undefined) {
        filteredRoles.push({
          ...role,
          id: roleId,
        })
      }
    })

    return [...scenarioFilteredRoles, ...filteredRoles]
  }

  private addRole = (selectedItem: IRole) => {
    if (selectedItem) {
      this.setState({
        isNew: true,
      })

      const newRole = {
        ...selectedItem,
        distribution: this.createDistributionObj(this.props.currentInitiative.dates),
        count: 0,
      }

      if (this.props.showConflicts) {
        this.changePreCalculatedData(newRole, this.props.currentInitiative)
      }

      const roles = [newRole, ...this.props.initiativeRoles]
      this.props.updateCurrentInitiativeRolesFunction(
        roles,
        this.props.showConflicts,
        this.props.usePeopleCostOnConflictCalculation
      )
    }
  }

  private changePreCalculatedData = (newRole, {dates}: IInitiative): void => {
    if (!this.props.preCalculatedData.roles.get(newRole.id)) {
      let newPreCalculatedData = {
        ...this.props.preCalculatedData,
      }

      const preCalculatedDataRoles = new Map(this.props.preCalculatedData.roles)

      preCalculatedDataRoles.set(
        newRole.id,
        this.createRolesDataForPreCalculatedData(dates.startDate, dates.endDate)
      )

      newPreCalculatedData = {
        ...newPreCalculatedData,
        roles: preCalculatedDataRoles,
      }

      this.props.changePreCalculatedDataFunction(
        newPreCalculatedData,
        this.props.usePeopleCostOnConflictCalculation
      )
    }
  }

  private createRolesDataForPreCalculatedData = (
    startDate: Moment,
    endDate: Moment
  ): Map<string, IPeriodRemainingData> => {
    const map = new Map()
    const startDateClone = startDate.clone()

    while (startDateClone.isBefore(endDate)) {
      map.set(startDateClone.format('YYYY-MM'), {
        competingInitiatives: [],
        remainingAmount: 0,
        totalAmount: 0,
      })

      startDateClone.add(1, 'month')
    }

    return map
  }

  private createDistributionObj = (initiativeDates: IInitiativeDates): IDistribution => {
    const distribution: IDistribution = {}
    const startDate = initiativeDates.startDate.clone()

    for (let i = 0; i < initiativeDates.duration; i++) {
      const key = startDate.format('YYYY-MM')
      distribution[key] = 0
      startDate.add(1, 'month')
    }

    return distribution
  }

  private deleteRole = (id: string) => {
    const roles = this.props.initiativeRoles.filter((role) => role.id !== id)
    this.props.updateCurrentInitiativeRolesFunction(
      roles,
      this.props.showConflicts,
      this.props.usePeopleCostOnConflictCalculation
    )
    this.setState({
      isNew: false,
    })
  }

  private changePeopleCount = (currentRole: IInitiativeRole) => {
    const roles: IRole[] = this.props.initiativeRoles.map((role) =>
      role.id === currentRole.id ? currentRole : role
    )
    this.props.updateCurrentInitiativeRolesFunction(
      roles,
      this.props.showConflicts,
      this.props.usePeopleCostOnConflictCalculation
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateCurrentInitiativeRolesFunction: (
    roles,
    showConflicts,
    usePeopleCostOnConflictCalculation
  ) => {
    const people = {roles}
    dispatch(updateCurrentInitiative({people}, showConflicts, usePeopleCostOnConflictCalculation))
  },
  changePreCalculatedDataFunction: (preCalculatedData, usePeopleCostOnConflictCalculation) =>
    dispatch(changePreCalculatedData(preCalculatedData, usePeopleCostOnConflictCalculation)),
})

const mapStateToProps = (state) => ({
  globalRoles: globalRolesSelector(state),
  scenarioRoles: currentScenarioPeopleSelector(state).roles,
  initiativeRoles: currentInitiativeSelector(state)!.people.roles,
  isViewMode: viewAccessSelector(state),
  showConflicts: showConflictsSelector(state),
  usePeopleCostOnConflictCalculation: peopleCostOnConflictCalculationSelector(state),
  currentInitiative: currentInitiativeSelector(state),
  preCalculatedData: preCalculatedDataSelector(state),
})

export const PeopleFormComponent = connect(mapStateToProps, mapDispatchToProps)(PeopleForm)
