import {IInitiative, IPreCalculatedData, IScenario} from '../../shared/models'
import {
  CHANGE_CURRENT_INITIATIVE,
  CHANGE_PRE_CALCULATED_DATA,
  SCROLL_TO_INITIATIVES,
  UPDATE_CURRENT_INITIATIVE,
} from './actions'
import {financialAccessDeniedSelector} from '../settings'
import {hoursForMeasurementsSelector} from '../plan/selectors/hoursForMeasurementsSelector'

export function scrollToInitiatives(initiatives: IInitiative[]) {
  return {
    type: SCROLL_TO_INITIATIVES,
    initiatives,
  }
}

export function changeCurrentInitiative(
  currentInitiative: IInitiative | null,
  currentScenario: IScenario | null = null,
  usePeopleCostOnConflictCalculation: boolean
) {
  return function (dispatch, getState) {
    const hasFinancialAccess = !financialAccessDeniedSelector(getState())

    return dispatch({
      type: CHANGE_CURRENT_INITIATIVE,
      currentInitiative,
      currentScenario,
      usePeopleCostOnConflictCalculation,
      hasFinancialAccess,
    })
  }
}

export function updateCurrentInitiative(
  changes: Record<string, unknown>,
  showConflicts: boolean,
  usePeopleCostOnConflictCalculation: boolean
) {
  return function (dispatch, getState) {
    const state = getState()
    const hasFinancialAccess = !financialAccessDeniedSelector(state)
    const useHoursForMeasurements = hoursForMeasurementsSelector(state)

    return dispatch({
      type: UPDATE_CURRENT_INITIATIVE,
      changes,
      showConflicts,
      usePeopleCostOnConflictCalculation,
      hasFinancialAccess,
      useHoursForMeasurements,
    })
  }
}

export function changePreCalculatedData(
  data: IPreCalculatedData | null,
  usePeopleCostOnConflictCalculation: boolean
) {
  return function (dispatch, getState) {
    const hasFinancialAccess = !financialAccessDeniedSelector(getState())

    return dispatch({
      type: CHANGE_PRE_CALCULATED_DATA,
      preCalculatedData: data,
      usePeopleCostOnConflictCalculation,
      hasFinancialAccess,
    })
  }
}
