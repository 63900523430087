import React from 'react'
import {
  MeasurementDataProvider,
  IMeasurementsContext,
} from '../../shared/context/MeasurementsContext'

export const MeasurementProvider: React.FC = ({children}) => {
  const initiativeRowHeight = 42
  const timelineBarArrowHeight = 20
  const timelineBarArrowYPosition = Math.round(initiativeRowHeight / 2 - timelineBarArrowHeight / 2)
  const initiativeHeaderHeight = 50

  const measurementData: IMeasurementsContext = {
    initiativeRowHeight,
    timelineBarArrowHeight,
    timelineBarArrowYPosition,
    initiativeHeaderHeight,
  }

  return <MeasurementDataProvider value={measurementData}>{children}</MeasurementDataProvider>
}
