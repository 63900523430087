import {css} from 'emotion'
import * as React from 'react'
import {connect} from 'react-redux'
import UserIcon from 'phoenix-icons/dist/UserIcon.js'

import {primary, Text} from '@phoenix/all'
import {hoursForMeasurementsSelector} from '../../../../../data/plan/selectors/hoursForMeasurementsSelector'

import {currentScenarioPeopleSelector} from '../../../../../data/scenario'
import {
  isEditSidebarOpenSelector,
  isPeopleWidgetExpandedSelector,
  togglePeopleWidget,
} from '../../../../../data/viewOptions'
import {formatNumber} from '../../../../../shared/utilities'
import {inlineCenterItemsClass} from '../../StyleHelper'
import {HeaderDescriptionComponent} from '../HeaderDescriptionComponent'
import {HeaderWidgetWrapperComponent} from '../HeaderWidgetWrapperComponent'
import {TooltipComponent} from '../TooltipComponent'
import {Translate} from '../../../../shared/Translate'

export interface IHeaderPeopleWidgetComponent {
  availableRolesCount: number | null
  requiredRolesCount: number | null
  isWidgetExpanded: boolean
  toggleWidgetFunction: (isWidgetExpanded) => void
  isSidebarOpen: boolean
  useHoursForMeasurements: boolean
}

const dataContainerClass = css`
  padding: 0px 8px;
  flex: 1 0 0;
`

const labelClass = css`
  text-transform: uppercase;
  color: ${primary.gray(700)};
  font-weight: 600;
  letter-spacing: 0.5px;
`

export const HeaderPeopleWidget: React.FunctionComponent<IHeaderPeopleWidgetComponent> = (
  props: IHeaderPeopleWidgetComponent
) => {
  const {
    isWidgetExpanded,
    toggleWidgetFunction,
    availableRolesCount,
    requiredRolesCount,
    useHoursForMeasurements,
  } = props

  return (
    <HeaderWidgetWrapperComponent
      isWidgetExpanded={isWidgetExpanded}
      toggleWidgetFunction={toggleWidgetFunction}
      type={'people'}
      icon={<UserIcon />}
    >
      <HeaderDescriptionComponent
        messageKey={useHoursForMeasurements ? 'job.roles.hour' : 'job.roles.fte'}
        testID="people"
      />
      <div className={inlineCenterItemsClass}>
        <div className={dataContainerClass}>
          <div>
            {availableRolesCount !== null ? (
              <TooltipComponent
                size="large"
                value={formatNumber(availableRolesCount, 2, true, 5)!}
                tooltipValue={formatNumber(availableRolesCount, 3, false, 6)!}
                testId={'available-role'}
              />
            ) : (
              <Text.Large>{'0'}</Text.Large>
            )}
          </div>
          <div>
            <Text.Small className={labelClass}>
              <Translate messageKey={'header.widget.role.available'} />
            </Text.Small>
          </div>
        </div>
        <div className={dataContainerClass}>
          <div>
            {requiredRolesCount !== null ? (
              <TooltipComponent
                size="large"
                value={formatNumber(requiredRolesCount, 2, true, 5)!}
                tooltipValue={formatNumber(requiredRolesCount, 3, false, 6)!}
                testId={'required-role'}
              />
            ) : (
              <Text.Large>{'0'}</Text.Large>
            )}
          </div>
          <div>
            <Text.Small className={labelClass}>
              <Translate messageKey={'header.widget.role.required'} />
            </Text.Small>
          </div>
        </div>
      </div>
    </HeaderWidgetWrapperComponent>
  )
}

const mapStateToProps = (state) => ({
  availableRolesCount: currentScenarioPeopleSelector(state).available,
  requiredRolesCount: currentScenarioPeopleSelector(state).required,
  isWidgetExpanded: isPeopleWidgetExpandedSelector(state),
  isSidebarOpen: isEditSidebarOpenSelector(state),
  useHoursForMeasurements: hoursForMeasurementsSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  toggleWidgetFunction: (isPeopleWidgetExpanded) =>
    dispatch(togglePeopleWidget(isPeopleWidgetExpanded)),
})

export const HeaderPeopleWidgetComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderPeopleWidget)
