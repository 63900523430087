import {css, cx} from 'emotion'
import * as React from 'react'

import {primary, Text} from '@phoenix/all'
import {connect} from 'react-redux'
import {viewAccessSelector} from '../../../../../data'

import {IScenarioRole} from '../../../../../shared/models'
import {formatFloating} from '../../../../../shared/utilities'
import {CounterComponent} from '../../../../shared'
import {inlineCenterItemsClass} from '../../StyleHelper'
import {RoleAvailableWarningTooltipComponent} from './RoleAvailableWarningTooltipComponent'

interface IAvailableRolesInputComponentProps {
  role: IScenarioRole
  isFocused: boolean
  isViewMode: boolean
  onFocusFunction: () => void
  onBlurFunction: () => void
  onRoleCountChangedFunction: (count) => void
  useHoursForMeasurements: boolean
}

interface IAvailableRolesInputComponentState {
  showWarning: boolean
  isInputFocused: boolean
  roleCount: number | null
}

const inputContainerClass = css`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
`

const rightIntervalClass = css`
  .number-input input {
    padding-right: 22px;
  }
`

const noAvailableValueClass = css`
  width: 73px;
  height: 24px;
  margin-left: 12px;
`

const textClass = css`
  color: ${primary.gray(700)};
  font-size: 14px;
`

export class AvailableRolesInput extends React.Component<
  IAvailableRolesInputComponentProps,
  IAvailableRolesInputComponentState
> {
  constructor(props: IAvailableRolesInputComponentProps) {
    super(props)
    this.state = {
      showWarning: false,
      isInputFocused: this.props.isFocused,
      roleCount: props.role.availableValue,
    }
  }

  render() {
    const {role, isFocused, isViewMode} = this.props
    return (
      <>
        {role.availableValue === null && !this.state.isInputFocused && (
          <div className={`role-empty-available-value ${noAvailableValueClass}`}>
            <Text className={textClass}>-</Text>
          </div>
        )}

        <div
          className={`${
            role.availableValue === null && !this.state.isInputFocused
              ? 'role-empty-available-input'
              : ''
          } ${inlineCenterItemsClass}`}
        >
          <div
            className={cx(inputContainerClass, this.state.showWarning ? rightIntervalClass : '')}
          >
            <CounterComponent
              roleCount={
                typeof this.state.roleCount === 'number'
                  ? formatFloating(this.state.roleCount, 3)
                  : this.state.roleCount
              }
              id={role.role.id}
              testID="supply-role-count"
              isFocused={isFocused}
              changePeopleCount={this.onRoleCountChangeHandler}
              isViewMode={isViewMode}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              inputHeight={30}
            />
            {this.state.showWarning && <RoleAvailableWarningTooltipComponent />}
          </div>
        </div>
      </>
    )
  }

  private onRoleCountChangeHandler = (roleCount) => {
    this.setState({
      roleCount,
    })
  }

  private onBlur = (count: number | null) => {
    this.props.onBlurFunction()
    this.setState({
      showWarning: false,
      isInputFocused: false,
      roleCount: count,
    })

    this.props.onRoleCountChangedFunction(count)
  }

  private onFocus = () => {
    this.props.onFocusFunction()
    const newState: IAvailableRolesInputComponentState = {
      ...this.state,
      isInputFocused: true,
    }

    if (this.isRoleDistributedUnevenly()) {
      newState.showWarning = true
    }
    this.setState(newState)
  }

  private isRoleDistributedUnevenly = () => {
    const {
      role: {availablePerPeriod},
    } = this.props
    const keys = Object.keys(availablePerPeriod)

    return keys.some(
      (monthKey) =>
        formatFloating(availablePerPeriod[keys[0]], 3) !==
        formatFloating(availablePerPeriod[monthKey], 3)
    )
  }
}

const mapStateToProps = (state) => ({
  isViewMode: viewAccessSelector(state),
})

export const AvailableRolesInputComponent = connect(mapStateToProps)(AvailableRolesInput)
