import AddIcon from 'phoenix-icons/dist/AddIcon.js'
import {ConfirmDialog, LabelDropdown, primary, Text} from '@phoenix/all'
import {Moment} from 'moment'
import * as React from 'react'
import styled, {css} from 'react-emotion'
import {connect} from 'react-redux'
import {
  addInitiative,
  changeCurrentInitiative,
  changePreCalculatedData,
  changeResolvableRoles,
  changeVisibleConflict,
  currentInitiativeSelector,
  currentPlanStartDateSelector,
  currentScenarioBudgetSelector,
  currentScenarioInitiativesSelector,
  currentScenarioSelector,
  financialAccessDeniedSelector,
  globalRolesSelector,
  isBudgetResolvableSelector,
  isEditSidebarOpenSelector,
  isReorderSelector,
  resizeLinePosition,
  resolvableRolesSelector,
  resolveConflicts,
  showConflictsSelector,
  timelineModeSelector,
  timelineResolvedRolesSelector,
  timelineWidthSelector,
  toggleBudgetResolvable,
  toggleEditSidebar,
  toggleImportDialog,
  updateInitiative,
  viewAccessSelector,
} from '../../../../data'
import {TimelineMode} from '../../../../shared/enums'
import {NewInitiativeOptions} from '../../../../shared/enums/NewInitiativeOptions'
import {
  createInitiative,
  hasInitiativeChanges,
  resolveBudgetConflict,
  resolveRoleConflicts,
} from '../../../../shared/helpers'
import {
  IInitiative,
  IPreCalculatedData,
  IScenario,
  IScenarioBudgets,
  IScenarioRole,
  ITimelineModeOption,
} from '../../../../shared/models'

import {ITimelineResolvedRole} from '../../../../shared/models/scenario/ITimelineResolvedRole'
import {inlineCenterItemsClass} from '../StyleHelper'
import {TimelineHeaderComponent} from '../Timeline/TimelineHeaderComponent'
import {peopleCostOnConflictCalculationSelector} from '../../../../data/plan/selectors/peopleCostOnConflictCalculationSelector'
import {TimelineResolvedIconsComponent} from './TimelineResolvedIconsComponent'
import {highlightColumnSelector} from '../../../../data/scenario/selectors/highlightColumnSelector'
import {highlightedColumnIndexSelector} from '../../../../data/scenario/selectors/highlightedColumnIndexSelector'
import {ShadowILeftStyled} from '../Timeline/TimelineColumnShadowComponent'
import {IGlobalRoles} from '../../../../shared/models/IGlobalRoles'
import {hoursForMeasurementsSelector} from '../../../../data/plan/selectors/hoursForMeasurementsSelector'
import {Translate} from '../../../shared/Translate'
import {translate} from '../../../../shared/utilities/TranslateHelper'
import {sidebarSizeSelector} from '../../../../data/settings/selectors/sidebarSizeSelector'
import {ConfirmationDialogHeaderComponent} from '../../../shared/ConfirmationDialogHeaderComponent'

export interface IInitiativeListHeaderProps {
  screenWidth: number
  timelineMode: TimelineMode
  planDurationInYears: number
  planStartDate: Moment
  scenario: IScenario
  addInitiativeFunction: (
    name: string,
    startDate: Moment,
    currentScenario: IScenario | null,
    usePeopleCostOnConflictCalculation: boolean,
    hasFinancialAccess: boolean
  ) => void
  timelineWidth: number
  resizeLinePosition: number | null
  initiatives: IInitiative[]
  currentInitiative: IInitiative
  innerRef: React.RefObject<HTMLDivElement>
  changeTimelineMode: (timelineOption: ITimelineModeOption) => void
  isScrollbarAtLeft: boolean
  applyChangesFunction: (
    initiative: IInitiative | null,
    usePeopleCostOnConflictCalculation: boolean
  ) => void
  toggleEditSidebarFunction: (toggleEdit: boolean) => void
  toggleImportDialogFunction: (isOpen: boolean) => void
  changeCurrentInitiativeFunction: (
    initiative: IInitiative | null,
    usePeopleCostOnConflictCalculation: boolean
  ) => void
  isViewMode: boolean
  isEditSidebarOpen: boolean
  timelineResolvedRoles: ITimelineResolvedRole[] | null
  showConflicts: boolean
  resolvableRoles: string[]
  resolveConflictsFunction: (
    initiative: IInitiative,
    resolvedRoles: IScenarioRole[],
    resolvedBudgets: IScenarioBudgets | null,
    usePeopleCostOnConflictCalculation: boolean
  ) => void
  resolvableRolesChangeFunction: (resolvableRoles: string[]) => void
  toggleBudgetResolvableFunction: (isBudgetResolvable: boolean) => void
  isBudgetResolvable: boolean
  changePreCalculatedDataFunction: (
    data: IPreCalculatedData | null,
    usePeopleCostOnConflictCalculation: boolean
  ) => void
  usePeopleCostOnConflictCalculation: boolean
  highlightColumn: boolean
  highlightedColumnIndex: number | null
  hasFinancialAccess: boolean
  globalRoles: IGlobalRoles
  useHoursForMeasurements: boolean
  sidebarSize: number
}

interface IInitiativesHeaderStyledProps {
  showRightArrow: boolean
  sidebarSize: number
}

const HeaderInnerStyled = styled('div')`
  ${inlineCenterItemsClass};
  align-items: flex-end;
  background-color: ${primary.gray(0)};
  height: 100%;
  position: sticky;
  z-index: 11;
`

const ResizeLinePositionStyled = css`
  background-color: ${primary.blue(100)};
  position: absolute;
  width: 1px;
  top: 27px;
  z-index: 3;
  height: calc(100% - 27px);
  pointer-events: none;
`

const HeaderContainerStyled = styled('div')`
  background-color: ${primary.gray(0)};
  position: relative;
  z-index: 1;
  margin-bottom: -2px;
  height: 52px;
  padding-bottom: 12px;
  overflow: hidden;
`

const periodDropdownClass = css`
  margin-left: auto;

  [data-testid*='period-label'] {
    margin-top: 0;
  }

  [data-testid*='period'] {
    svg path {
      fill: ${primary.blue(400)};
    }
  }

  button {
    border-radius: 3px;
  }
`

const timelineHeaderClass = css`
  flex: 1 0 0;
  display: flex;
  align-items: center;
  height: 100%;
`

const InitiativesHeaderStyled = styled('div')<IInitiativesHeaderStyledProps>`
  background-color: ${primary.gray(0)};
  border-top: 1px solid ${primary.gray(200)};
  border-bottom: 1px solid ${primary.gray(200)};
  min-width: ${(props) => props.sidebarSize}px;
  position: sticky;
  left: 0;
  z-index: 10;
  height: 40px;

  &:after {
    background-color: ${primary.gray(0)};
    content: '';
    display: block;
    height: 11px;
    position: relative;
    margin-top: 1px;
  }

  ${(props) =>
    props.showRightArrow &&
    `
  &:before {
    background-color: ${primary.gray(200)};
    content: '';
    position: absolute;
    right: 0;
    width: 1px;
    height: 18px;
    top: 50%;
    margin-top: -9px;
  }
  `}
  svg {
    margin-right: 10px;
  }
`

const InitiativeHeaderInnerStyled = styled('div')`
  padding: 0px 0px 0px 16px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
`

const headerBorderClass = css`
  position: relative;

  &:after {
    content: '';
    width: 100%;
    position: absolute;
    top: 50%;
    height: 1px;
    background-color: ${primary.gray(200)};
  }
`

const timelineHeaderBottomClass = css`
  ${headerBorderClass};
  background-color: ${primary.gray(0)};
  flex: 100%;
  height: 24px;
  position: absolute;
  bottom: -11px;

  .bubble {
    z-index: 1;
  }
`

const newInitiativeDropdownClass = css`
  display: flex;
  align-items: center;

  button,
  svg {
    border-radius: 3px;
  }

  svg {
    cursor: pointer;
  }
`

export const InitiativeListHeader: React.FunctionComponent<IInitiativeListHeaderProps> = React.memo(
  (props) => {
    const [dropdownValue, setDropdownValue] = React.useState(NewInitiativeOptions.ADD_ONE)
    const [showDialog, setDialog] = React.useState(false)

    const creatNewInitiative = (currentScenario: IScenario | null = null) => {
      props.addInitiativeFunction(
        translate('sp.initiative.default.name'),
        props.planStartDate,
        currentScenario,
        props.usePeopleCostOnConflictCalculation,
        props.hasFinancialAccess
      )
    }

    const creatNewInitiativeAndFocusNameInput = () => {
      const newScenario = {
        ...props.scenario,
        initiatives: props.scenario.initiatives.map((initiative: IInitiative) => {
          return initiative.id === props.currentInitiative.id ? props.currentInitiative : initiative
        }),
      }
      creatNewInitiative(newScenario)

      setTimeout(() => {
        const inputElement = document.querySelector(
          '[data-testid="edit-sidebar-header"] [data-testid="initiative-name"]'
        ) as HTMLInputElement

        inputElement.focus()
        inputElement.select()
      })
    }

    const beforeImportModalOpen = () => {
      props.toggleEditSidebarFunction(false)
      props.resolvableRolesChangeFunction([])
      props.toggleBudgetResolvableFunction(false)
      props.changePreCalculatedDataFunction(null, props.usePeopleCostOnConflictCalculation)
      props.changeCurrentInitiativeFunction(null, props.usePeopleCostOnConflictCalculation)
    }

    const denyChanges = () => {
      if (dropdownValue === NewInitiativeOptions.IMPORT_PROJECTS) {
        beforeImportModalOpen()
        props.toggleImportDialogFunction(true)
      } else if (dropdownValue === NewInitiativeOptions.ADD_ONE) {
        creatNewInitiativeAndFocusNameInput()
      }
      setDialog(false)
    }

    const applyChanges = () => {
      if (dropdownValue === NewInitiativeOptions.ADD_ONE) {
        creatNewInitiativeAndFocusNameInput()
      }

      const {
        showConflicts,
        currentInitiative,
        resolvableRoles,
        isBudgetResolvable,
        planStartDate,
        planDurationInYears,
        scenario,
        hasFinancialAccess,
        globalRoles,
        useHoursForMeasurements,
      } = props
      const {people, budgets} = scenario

      if (showConflicts && currentInitiative.conflicts !== null) {
        const resolvedRoles = resolveRoleConflicts(
          people.roles,
          resolvableRoles,
          currentInitiative.conflicts.roleConflicts,
          planStartDate,
          planDurationInYears,
          globalRoles,
          useHoursForMeasurements
        )
        const resolvedBudgets =
          isBudgetResolvable && currentInitiative.conflicts.budgetConflicts && hasFinancialAccess
            ? resolveBudgetConflict(budgets!, currentInitiative.conflicts.budgetConflicts)
            : null
        props.resolveConflictsFunction(
          currentInitiative,
          resolvedRoles,
          resolvedBudgets,
          props.usePeopleCostOnConflictCalculation
        )
      } else {
        props.applyChangesFunction(
          props.currentInitiative,
          props.usePeopleCostOnConflictCalculation
        )
      }

      props.resolvableRolesChangeFunction([])
      props.toggleBudgetResolvableFunction(false)

      if (dropdownValue === NewInitiativeOptions.IMPORT_PROJECTS) {
        props.toggleEditSidebarFunction(false)
        props.changeCurrentInitiativeFunction(null, props.usePeopleCostOnConflictCalculation)
        props.toggleImportDialogFunction(true)
      }

      setDialog(false)
    }

    const addNewInitiative = () => {
      if (props.isEditSidebarOpen) {
        if (hasInitiativeChanges(props.scenario, props.currentInitiative)) {
          setDialog(true)
        } else {
          creatNewInitiativeAndFocusNameInput()
        }
      } else {
        creatNewInitiative(props.scenario)
      }
    }

    const importProjects = () => {
      if (props.isEditSidebarOpen) {
        if (hasInitiativeChanges(props.scenario, props.currentInitiative)) {
          setDialog(true)
          return
        } else {
          beforeImportModalOpen()
        }
      }
      props.toggleImportDialogFunction(true)
    }
    const handleNewInitiativeChange = ({value}) => {
      setDropdownValue(value)
      if (value === NewInitiativeOptions.ADD_ONE) {
        addNewInitiative()
      } else if (value === NewInitiativeOptions.IMPORT_PROJECTS) {
        importProjects()
      }
    }

    const getOptions = () => {
      return [
        {
          value: TimelineMode.MONTHLY,
          key: TimelineMode.MONTHLY,
          label: translate('timeline.mode.month'),
          requiredYearCount: 1,
        },
        {
          value: TimelineMode.QUARTERLY,
          key: TimelineMode.QUARTERLY,
          label: translate('timeline.mode.quarter'),
          requiredYearCount: 3,
        },
        {
          value: TimelineMode.YEARLY,
          key: TimelineMode.YEARLY,
          label: translate('timeline.mode.year'),
          requiredYearCount: 5,
        },
      ]
    }

    const newInitiativeOptions = () => {
      return [
        {
          value: 0,
          key: 0,
          label: translate('sp.initiative.new'),
        },
        {
          value: NewInitiativeOptions.ADD_ONE,
          key: NewInitiativeOptions.ADD_ONE,
          label: translate('sp.initiative.new'),
        },
        {
          value: NewInitiativeOptions.IMPORT_PROJECTS,
          key: NewInitiativeOptions.IMPORT_PROJECTS,
          label: translate('import.projects'),
        },
      ]
    }
    const dropdownOptions = newInitiativeOptions().filter((item, index) => index)
    const timelineModeOption = getOptions().filter(
      (option) => option.requiredYearCount <= props.planDurationInYears
    )
    const selectedTimelineModeOption = timelineModeOption.find(
      (option) => option.value === props.timelineMode
    )
    const onAddInitiativeClickHandler = () => {
      handleNewInitiativeChange({
        value: NewInitiativeOptions.ADD_ONE,
      })
    }

    const onAddInitiativeKeyPressHandler = (e) => {
      const keyboardClickKeys = ['Enter', 'Space', ' ']

      if (keyboardClickKeys.includes(e.key)) {
        onAddInitiativeClickHandler()
      }
    }

    return (
      <>
        {props.resizeLinePosition !== null && props.resizeLinePosition > 0 && (
          <div
            style={{
              left:
                props.resizeLinePosition === 0 || props.resizeLinePosition === null
                  ? 0
                  : props.resizeLinePosition! + props.sidebarSize,
            }}
            className={ResizeLinePositionStyled}
          />
        )}
        <HeaderContainerStyled
          innerRef={props.innerRef}
          data-testid="initiative-row-header"
          style={{
            width: `${props.screenWidth}px`,
          }}
        >
          <HeaderInnerStyled
            style={{
              width: props.timelineWidth > 0 ? `${props.timelineWidth + props.sidebarSize}px` : '',
            }}
          >
            <InitiativesHeaderStyled
              showRightArrow={
                !props.isScrollbarAtLeft ||
                !props.highlightColumn ||
                props.highlightedColumnIndex !== 0
              }
              sidebarSize={props.sidebarSize}
              data-testid="initiative-details-wrapper"
            >
              <InitiativeHeaderInnerStyled>
                {!props.isViewMode && (
                  <div className={newInitiativeDropdownClass} data-testid="new-initiative-actions">
                    <AddIcon
                      role="button"
                      tabIndex={0}
                      width={16}
                      height={16}
                      color={primary.blue(400)}
                      onClick={onAddInitiativeClickHandler}
                      onKeyPress={onAddInitiativeKeyPressHandler}
                    />
                    <LabelDropdown
                      testID="initiative"
                      value={newInitiativeOptions()[0]}
                      onChange={handleNewInitiativeChange}
                      options={dropdownOptions}
                    />
                  </div>
                )}
                <div className={periodDropdownClass} data-testid="initiative-period-dropdown">
                  <LabelDropdown
                    testID="period"
                    value={selectedTimelineModeOption}
                    onChange={props.changeTimelineMode}
                    options={timelineModeOption}
                  />
                </div>
                {props.isScrollbarAtLeft &&
                  props.highlightColumn &&
                  props.highlightedColumnIndex === 0 && <ShadowILeftStyled right="0" />}
              </InitiativeHeaderInnerStyled>
            </InitiativesHeaderStyled>

            <div className={timelineHeaderClass} data-testid="initiative-header-timeline">
              <TimelineHeaderComponent />
              <div className={timelineHeaderBottomClass}>
                {props.timelineResolvedRoles && props.timelineMode === TimelineMode.MONTHLY && (
                  <TimelineResolvedIconsComponent
                    testID="timeline-resolved-role-indicator"
                    planStartDate={props.planStartDate}
                    timelineResolvedRoles={props.timelineResolvedRoles}
                  />
                )}
              </div>
            </div>
          </HeaderInnerStyled>
        </HeaderContainerStyled>
        {showDialog && (
          <ConfirmDialog
            header={<ConfirmationDialogHeaderComponent messageKey={'global.unapplied'} />}
            confirmText={<Translate messageKey={'form.button.apply'} />}
            denyText={<Translate messageKey={'form.button.discard'} />}
            onConfirmClick={applyChanges}
            onDenyClick={denyChanges}
          >
            <Text>
              <Translate messageKey={'sidebar.confirmation.message'} />{' '}
            </Text>
          </ConfirmDialog>
        )}
      </>
    )
  }
)

InitiativeListHeader.displayName = 'InitiativeListHeader'

const mapStateToProps = (state) => ({
  timelineMode: timelineModeSelector(state),
  planDurationInYears: state.plan.currentPlan.duration,
  planStartDate: currentPlanStartDateSelector(state),
  timelineWidth: timelineWidthSelector(state),
  resizeLinePosition: resizeLinePosition(state),
  initiatives: currentScenarioInitiativesSelector(state),
  currentInitiative: currentInitiativeSelector(state),
  isEditSidebarOpen: isEditSidebarOpenSelector(state),
  scenario: currentScenarioSelector(state),
  isViewMode: viewAccessSelector(state),
  timelineResolvedRoles: timelineResolvedRolesSelector(state),
  showConflicts: showConflictsSelector(state),
  isReordering: isReorderSelector(state),
  budgets: currentScenarioBudgetSelector(state),
  isBudgetResolvable: isBudgetResolvableSelector(state),
  resolvableRoles: resolvableRolesSelector(state),
  usePeopleCostOnConflictCalculation: peopleCostOnConflictCalculationSelector(state),
  highlightColumn: highlightColumnSelector(state),
  highlightedColumnIndex: highlightedColumnIndexSelector(state),
  hasFinancialAccess: !financialAccessDeniedSelector(state),
  globalRoles: globalRolesSelector(state),
  useHoursForMeasurements: hoursForMeasurementsSelector(state),
  sidebarSize: sidebarSizeSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  addInitiativeFunction: (
    name: string,
    startDate: Moment,
    currentScenario,
    usePeopleCostOnConflictCalculation,
    hasFinancialAccess
  ) => {
    const newInitiative = createInitiative(name, hasFinancialAccess, startDate)
    dispatch(addInitiative(newInitiative))
    const newInitiativeWithUpdatedOrder = {
      ...newInitiative,
      order: currentScenario.initiatives ? currentScenario.initiatives.length + 1 : 1,
    }
    dispatch(
      changeCurrentInitiative(
        newInitiativeWithUpdatedOrder,
        currentScenario,
        usePeopleCostOnConflictCalculation
      )
    )
    dispatch(changeVisibleConflict(null))
  },
  changeCurrentInitiativeFunction: (initiative, usePeopleCostOnConflictCalculation) =>
    dispatch(changeCurrentInitiative(initiative, null, usePeopleCostOnConflictCalculation)),
  applyChangesFunction: (currentInitiative, usePeopleCostOnConflictCalculation) =>
    dispatch(updateInitiative(currentInitiative, usePeopleCostOnConflictCalculation)),
  toggleEditSidebarFunction: (isOpen) => dispatch(toggleEditSidebar(isOpen)),
  resolveConflictsFunction: (
    initiative,
    resolvedRoles,
    resolvedBudgets,
    usePeopleCostOnConflictCalculation
  ) =>
    dispatch(
      resolveConflicts(
        initiative,
        resolvedRoles,
        resolvedBudgets,
        usePeopleCostOnConflictCalculation
      )
    ),
  toggleImportDialogFunction: (isOpen) => dispatch(toggleImportDialog(isOpen)),
  resolvableRolesChangeFunction: (resolvableRoles) =>
    dispatch(changeResolvableRoles(resolvableRoles)),
  toggleBudgetResolvableFunction: (isBudgetResolvable) =>
    dispatch(toggleBudgetResolvable(isBudgetResolvable)),
  changePreCalculatedDataFunction: (data, usePeopleCostOnConflictCalculation) =>
    dispatch(changePreCalculatedData(data, usePeopleCostOnConflictCalculation)),
})

export const InitiativeListHeaderComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(InitiativeListHeader)

// TODO: This component is too big and it has too many props. Refactor
