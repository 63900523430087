import {bindPluginClass, PluginBase} from '@workfront/list-plugin-utils'
import {IDataTableAPI} from '@wftypes/list'

import {resetSortPaginationInfo} from '../../shared/utils/queryUtils'
import {PAGE_SIZES} from '../table-data'
import {apiDateToDate} from '@workfront/fns'
import {loadInitiatives, loadInitiativesCount, loadSPFilters} from '../services/apiFactory'
import {trackSP} from '../../shared/utils/pendoTracker'
import {FilterChangeParams} from '../../shared/toolbar/filters/types'
import {getStorageUtil} from '@workfront/storage'
import {transformResult} from './toolbar/filters/loadFilters'
import {formatDateBasedOnLocale} from '../../shared/utils/dateHelpers'

export interface DataFetcherOptions {
  scenarioId: string
  planId: string
}
export interface DataFetcherAPI {
  filterListData: (filters: FilterChangeParams) => void
  getDefaultFilterPromise: () => Promise<{id: string; name?: string}>
}

export interface IRoleDistribution {
  [key: string]: number
}

const storageUtil = getStorageUtil()

class DataFetcherPlugin extends PluginBase<DataFetcherAPI, DataFetcherOptions> {
  static PLUGIN_NAME = 'DataFetcherPlugin'
  static PLUGIN_DEPS = []
  // @ts-ignore
  private filterID = ''
  private queryParams: {[key: string]: unknown} = {}
  private defaultFilterPromise
  init(listApi: IDataTableAPI, options: DataFetcherOptions) {
    this.defaultFilterPromise = new Promise<{id: string; key?: string}>((resolve) => {
      const id = storageUtil.get(`sp-export-initiative-default-filter-${options.planId}`) || ''
      if (id == '') {
        resolve({
          id,
        })
      }
      loadSPFilters()
        .then((data) => data.map(transformResult))
        .then((filters) => {
          const filter = filters.find((item) => item.id == id)
          resolve(filter)
        })
    }).then((filter) => {
      this.filterID = filter.id
      this.queryParams.filterId = filter.id === '' ? null : filter.id
      return filter
    })
    listApi.interceptors.enableInterceptor(
      listApi.interceptors.getDefaultInterceptorName(),
      listApi.interceptors.events.LOAD_PAGE_ITEMS,
      false
    )
    this.addShutdownRoutine(
      () => {
        listApi.interceptors.enableInterceptor(
          listApi.interceptors.getDefaultInterceptorName(),
          listApi.interceptors.events.LOAD_PAGE_ITEMS,
          true
        )
      },
      listApi.interceptors.registerInterceptor(
        DataFetcherPlugin.PLUGIN_NAME,
        listApi.interceptors.events.LOAD_PAGE_ITEMS,
        ({data: {sortInfo, pageNumber, pageSize}}) => {
          const [prevSortInfo] = listApi.sorting.getSortInfo()
          const [nextSortInfo] = sortInfo
          if (JSON.stringify(nextSortInfo) !== JSON.stringify(prevSortInfo)) {
            trackSP('export dialog sort change', nextSortInfo)
          }
          resetSortPaginationInfo(this.queryParams)
          sortInfo.forEach((sortInfoItem) => {
            this.queryParams['sort'] = `${sortInfoItem.key},${sortInfoItem.direction}`
          })
          if (pageSize) {
            this.queryParams['page'] = pageNumber - 1
            this.queryParams['size'] = pageSize
          }
          this.queryParams['scenarioId'] = options.scenarioId
          const {recordLimit} = listApi.metadata.getProperty('pagination')
          return this.loadData({pageNumber, pageSize: pageSize ?? recordLimit})
        }
      ),
      listApi.interceptors.registerInterceptor(
        DataFetcherPlugin.PLUGIN_NAME,
        listApi.interceptors.events.AFTER_LOAD_PAGE_ITEMS,
        () => {
          const selectedIDs = listApi.selection
            .getSelectionRanges()
            .reduce((acc, item) => acc.concat(item), [])
          window.requestAnimationFrame(() => {
            const rows = listApi.rows.getVisibleRowIndices(selectedIDs)
            const visibleRowIDs = Object.keys(rows)
            listApi.selection.setSelection(visibleRowIDs)
          })
        }
      ),
      () => {
        this.queryParams = {}
      }
    )
  }

  ready(listApi: IDataTableAPI) {
    this.defaultFilterPromise.then(() => {
      listApi.pagination.setCurrentPage(1)
    })
  }

  getApi(listApi: IDataTableAPI): DataFetcherAPI {
    return {
      filterListData: (filter) => {
        this.filterID = filter.ID
        this.queryParams.filterId = filter.ID === '' ? null : filter.ID
        trackSP(`export dialog filter changed to ${filter.text}`, {
          filterID: filter.ID,
        })
        listApi.pagination.setCurrentPage(1)
      },
      getDefaultFilterPromise: () => this.defaultFilterPromise,
    }
  }

  loadData = ({pageNumber, pageSize}) => {
    // use queryParams here
    return Promise.all([
      loadInitiatives(this.queryParams),
      loadInitiativesCount(this.queryParams),
    ]).then(([searchData, countData]) => {
      return {
        rows: this.convertApiData(searchData),
        metadata: {
          pagination: {
            count: countData,
            pageNumber,
            pageSize,
            pageSizes: PAGE_SIZES.filter((size) => {
              return size <= countData
            }).map(String),
          },
          rawValueTypes: {},
        },
        groups: {},
        collapsedGroups: [],
        collapsedRows: [],
      }
    })
  }

  private convertApiData = (initiativeData) => {
    return initiativeData.reduce((acc, initiative, index) => {
      return {
        ...acc,
        [initiative.id]: {
          objCode: 'no_obj_code',
          editable: false,
          columns: this.getColumnData(initiative),
          display: initiative.name,
          id: initiative.id,
          order: index,
          rawValues: {},
        },
      }
    }, {})
  }
  private formatFloating = (number) => {
    return number % 1 === 0 ? number : Number(number.toFixed(2))
  }
  private getColumnData = (initiative) => {
    return [
      {isEditable: false, value: ''},
      {
        isEditable: false,
        value: '',
        viewData: {
          'component.initiativename|initiativename': {
            name: initiative.name,
            isPublished: !!initiative.lastPublishedDate,
            refObject: initiative.refObject,
          },
        },
      },
      {
        isEditable: false,
        value: formatDateBasedOnLocale(apiDateToDate(initiative.startDate)),
      },
      {
        isEditable: false,
        value: formatDateBasedOnLocale(apiDateToDate(initiative.endDate)),
      },
      {
        isEditable: false,
        value: '',
        viewData: {
          'component.initiativeduration|initiativeduration': {
            duration: initiative.duration,
          },
        },
      },
      {
        isEditable: false,
        value: '',
        viewData: {
          'component.initiativeroles|initiativeroles': {
            roleList: initiative.roleCounts.map((role) => {
              const distribution: IRoleDistribution = role.distribution

              // use this function without after SP_ROLE_DISTRIBUTION removal
              if (distribution) {
                let roleCont = 0,
                  fteHours = 0
                Object.keys(distribution).forEach((month) => {
                  if (distribution[month] > roleCont) {
                    roleCont = distribution[month]
                  }
                  fteHours += distribution[month]
                })
                return {
                  value: `${role.role.name} ${this.formatFloating(
                    roleCont
                  )}FTE - ${this.formatFloating(fteHours * 160)}H`,
                  id: role.role.id,
                }
              } else {
                return {
                  value: `${role.role.name} - ${this.formatFloating(role.count)}`,
                  id: role.role.id,
                }
              }
            }),
          },
        },
      },
      {
        isEditable: false,
        value: initiative.lastPublishedDate
          ? formatDateBasedOnLocale(apiDateToDate(initiative.lastPublishedDate))
          : '',
      },
    ]
  }
}

export default bindPluginClass(
  () => new DataFetcherPlugin(),
  DataFetcherPlugin.PLUGIN_NAME,
  DataFetcherPlugin.PLUGIN_DEPS
)
