import {Moment} from 'moment'
import {fetchData} from './api/ApiService'
import {IInitiative} from '../models'
interface IImportProjects {
  projectIDs: string[]
  planStartDate: Moment
  duration: number
}
export class ProjectService {
  importProjects(data: IImportProjects) {
    return fetchData<IInitiative[]>('/initiatives/prepare', {
      data,
      method: 'POST',
    })
  }
}
