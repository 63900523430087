// Timeline
export const CHANGE_TIMELINE_MODE = 'CHANGE_TIMELINE_MODE'
export const CHANGE_TIMELINE_CONTAINER_WIDTH = 'CHANGE_TIMELINE_CONTAINER_WIDTH'

// Plan
export const LOAD_PLAN_FAILURE = 'LOAD_PLAN_FAILURE'
export const LOAD_PLAN_SUCCESS = 'LOAD_PLAN_SUCCESS'
export const CREATE_NEW_PLAN_SUCCESS = 'CREATE_NEW_PLAN_SUCCESS'
export const CREATE_NEW_PLAN_FAILURE = 'CREATE_NEW_PLAN_FAILURE'
export const UPDATE_PLAN_SUCCESS = 'UPDATE_PLAN_SUCCESS'
export const UPDATE_PLAN_FAILURE = 'UPDATE_PLAN_FAILURE'
export const DELETE_PLAN_SUCCESS = 'DELETE_PLAN_SUCCESS'
export const DELETE_PLAN_FAILURE = 'DELETE_PLAN_FAILURE'
export const UPDATE_CURRENT_PLAN = 'UPDATE_CURRENT_PLAN'
export const UPDATE_PLAN_WITH_STARTDATE_OR_DURATION = 'UPDATE_PLAN_WITH_STARTDATE_OR_DURATION'
export const RECALCULATE_PLAN_SCENARIOS_BUDGETS = 'RECALCULATE_PLAN_SCENARIOS_BUDGETS'
export const CHANGE_PLAN_SETTINGS = 'CHANGE_PLAN_SETTINGS'

// Accessor Objects
export const UPDATE_PLAN_ACCESSOR_OBJECTS = 'UPDATE_PLAN_ACCESSOR_OBJECTS'

// Scenario
export const ADD_SCENARIO = 'ADD_SCENARIO'
export const UPDATE_SCENARIO = 'UPDATE_SCENARIO'
export const DELETE_SCENARIO = 'DELETE_SCENARIO'
export const UPDATE_SCENARIO_INITIATIVES_REF_OBJECT = 'UPDATE_SCENARIO_INITIATIVES_REF_OBJECT'
export const RESET_PUBLISHED_SCENARIO = 'RESET_PUBLISHED_SCENARIO'

// Initiative list
export const TOGGLE_INITIATIVE_SELECTION = 'TOGGLE_INITIATIVE_SELECTION'
export const DESELECT_INITIATIVE = 'DESELECT_INITIATIVE'
export const SET_SELECTED_INITIATIVES = 'SET_SELECTED_INITIATIVES'
export const DESELECT_ALL_INITIATIVES = 'DESELECT_ALL_INITIATIVES'
export const SET_LAST_SELECTED_INITIATIVE = 'SET_LAST_SELECTED_INITIATIVE'
