import * as React from 'react'
import {formatCurrency} from '../../shared/utilities'
import {Translate} from './Translate'
import {TooltipComponent} from '../Plan/Scenario/Header/TooltipComponent'

interface IFormattedCurrencyComponentProps {
  cost: number | null
  currency: string
  isAbbreviated?: boolean | undefined
  className?: string
}

export const FormattedCurrencyComponent: React.FunctionComponent<IFormattedCurrencyComponentProps> = (
  props
) => {
  const isAbbreviated = typeof props.isAbbreviated === 'boolean' ? props.isAbbreviated : true
  return (
    <React.Fragment>
      {props.cost === null ? (
        <Translate messageKey={'global.na'} />
      ) : (
        <TooltipComponent
          className={props.className}
          value={formatCurrency(props.cost, props.currency, isAbbreviated)!}
          tooltipValue={formatCurrency(props.cost, props.currency, false)!}
        />
      )}
    </React.Fragment>
  )
}
