import React from 'react'
import {css} from 'react-emotion'
import {primary, Text, A} from '@phoenix/all'
import {connect} from 'react-redux'
import {selectedInitiativeCountSelector} from '../../../data/plan/selectors/selectedInitiativeCountSelector'
import {IInitiative} from '../../../shared/models/initiative'
import {
  copySelectedInitiatives as copyInitiatives,
  prioritizeSelectedInitiatives as prioritizeInitiatives,
  currentScenarioInitiativesSelector,
  isEditSidebarOpenSelector,
} from '../../../data'
import {selectAllInitiatives, deselectAllInitiatives} from '../../../data/plan'
import CopyIcon from 'phoenix-icons/dist/CopyIcon.js'
import DeleteIcon from 'phoenix-icons/dist/DeleteIcon.js'
import {FloatingMenuActionItemComponent} from './FloatingMenuActionItemComponent'
import {showInitiativeDeleteConfirmationDialog} from '../../../data/scenario'
import {peopleCostOnConflictCalculationSelector} from '../../../data/plan/selectors/peopleCostOnConflictCalculationSelector'
import {FloatingMenuPrioritizeDropdownComponent} from './FloatingMenuPrioritizeDropdownComponent'
import {Translate} from '../../shared/Translate'

const selectionCountTextClass = css`
  position: relative;
  top: -1px;
  color: ${primary.gray(700)};
`

const selectionCountTextValueClass = css`
  position: absolute;
`

const selectionCountTextPlaceholderClass = css`
  visibility: hidden;
`

const selectDeselectContainerClass = css`
  position: relative;
  margin: 0 0 0 6px;
`

const selectDeselectContainerButtonClass = css`
  position: absolute;
  z-index: 1;
  top: -1px;
  right: 0;
`

const selectDeselectContainerSpacerClass = css`
  visibility: hidden;
`

const separatorClass = css`
  margin: 0 0 0 16px;
  height: 15px;
  width: 1px;
  background-color: ${primary.gray(300)};
`

const copyContainerClass = css`
  margin: 0 0 0 16px;
`

const deleteContainerClass = css`
  margin: 0 0 0 16px;
`

const prioritizeContainerClass = css`
  margin: 0 0 0 16px;
`

const floatingMenuContainerClass = css`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  bottom: 18px;
  height: 0px;
`

const floatingMenuClass = css`
  display: flex;
  align-items: center;
  height: 47px;
  margin-top: -47px;
  padding: 13px 24px 14px 24px;
  border-radius: 4px;
  box-shadow: 0px 3px 6px rgba(0, 35, 64, 0.2);
  background: ${primary.white()};
`

interface IFloatingMenuProps {
  initiatives: IInitiative[]
  isEditSidebarOpen: boolean
  selectedInitiativeCount: number
  usePeopleCostOnConflictCalculation: boolean
  selectAll: () => void
  deselectAll: () => void
  copySelectedInitiatives: (usePeopleCostOnConflictCalculation: boolean) => void
  prioritizeSelectedInitiatives: (
    placeAfterPriority: number,
    usePeopleCostOnConflictCalculation: boolean
  ) => void
  showDeleteConfirmDialog: (multiple: boolean) => void
}

const FloatingMenu: React.FC<IFloatingMenuProps> = ({
  initiatives,
  isEditSidebarOpen,
  selectedInitiativeCount,
  selectAll,
  deselectAll,
  copySelectedInitiatives,
  prioritizeSelectedInitiatives,
  showDeleteConfirmDialog,
  usePeopleCostOnConflictCalculation,
}) => {
  const areAllInitiativesSelected = React.useMemo(() => {
    return initiatives.length === selectedInitiativeCount
  }, [initiatives, selectedInitiativeCount])

  const toggleSelections = React.useCallback(() => {
    if (areAllInitiativesSelected) {
      deselectAll()
    } else {
      selectAll()
    }
  }, [areAllInitiativesSelected, selectAll, deselectAll])

  const handleCopy = React.useCallback(() => {
    copySelectedInitiatives(usePeopleCostOnConflictCalculation)
  }, [copySelectedInitiatives, usePeopleCostOnConflictCalculation])

  const handlePrioritize = React.useCallback(
    (placeAfterPriority) => {
      prioritizeSelectedInitiatives(placeAfterPriority, usePeopleCostOnConflictCalculation)
    },
    [prioritizeSelectedInitiatives, usePeopleCostOnConflictCalculation]
  )

  const handleDelete = React.useCallback(() => {
    const areMultipleInitiativesSelected = selectedInitiativeCount > 1
    showDeleteConfirmDialog(areMultipleInitiativesSelected)
  }, [showDeleteConfirmDialog, selectedInitiativeCount])

  return (
    <>
      {selectedInitiativeCount > 0 && !isEditSidebarOpen && (
        <div className={floatingMenuContainerClass}>
          <div data-testid="floating-menu" className={floatingMenuClass}>
            <div>
              <Text className={selectionCountTextClass} data-testid="selected-initiatives">
                <span className={selectionCountTextValueClass}>
                  {selectedInitiativeCount === 1 ? (
                    <Translate messageKey={'sp.initiative.selected.single'} />
                  ) : (
                    <Translate
                      messageKey={'sp.initiative.selected.plural'}
                      args={[selectedInitiativeCount]}
                    />
                  )}
                </span>
                <span className={selectionCountTextPlaceholderClass}>
                  <Translate
                    messageKey={'sp.initiative.selected.plural'}
                    // TODO: is this argument normal
                    args={['000']}
                  />
                </span>
              </Text>
            </div>
            <div className={selectDeselectContainerClass}>
              <A
                className={selectDeselectContainerButtonClass}
                onClick={toggleSelections}
                data-testid="select-deselect-all"
              >
                {areAllInitiativesSelected ? (
                  <Translate messageKey="initiative.deselect.all" />
                ) : (
                  <Translate messageKey="initiative.select.all" />
                )}
              </A>
              <A className={selectDeselectContainerSpacerClass}>
                <Translate messageKey="initiative.deselect.all" />
              </A>
            </div>
            <div className={separatorClass} />
            <FloatingMenuActionItemComponent
              testId="bulk-copy"
              className={copyContainerClass}
              Icon={CopyIcon}
              text={<Translate messageKey={'initiative.options.copy'} />}
              onClick={handleCopy}
            />
            <FloatingMenuActionItemComponent
              testId="bulk-delete"
              className={deleteContainerClass}
              Icon={DeleteIcon}
              text={<Translate messageKey={'initiative.options.delete'} />}
              onClick={handleDelete}
            />
            <div className={separatorClass} />
            <div data-testid="bulk-prioritize" className={prioritizeContainerClass} tabIndex={-1}>
              <FloatingMenuPrioritizeDropdownComponent onChange={handlePrioritize} />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  initiatives: currentScenarioInitiativesSelector(state),
  isEditSidebarOpen: isEditSidebarOpenSelector(state),
  selectedInitiativeCount: selectedInitiativeCountSelector(state),
  usePeopleCostOnConflictCalculation: peopleCostOnConflictCalculationSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  selectAll: () => dispatch(selectAllInitiatives()),
  deselectAll: () => dispatch(deselectAllInitiatives()),
  copySelectedInitiatives: (usePeopleCostOnConflictCalculation) =>
    dispatch(copyInitiatives(usePeopleCostOnConflictCalculation)),
  prioritizeSelectedInitiatives: (placeAfterPriority, usePeopleCostOnConflictCalculation) =>
    dispatch(prioritizeInitiatives(placeAfterPriority, usePeopleCostOnConflictCalculation)),
  showDeleteConfirmDialog: (multiple) => dispatch(showInitiativeDeleteConfirmationDialog(multiple)),
})

export const FloatingMenuComponent = connect(mapStateToProps, mapDispatchToProps)(FloatingMenu)
