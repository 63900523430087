import {primary} from '@phoenix/all'
import {css} from 'emotion'
import * as React from 'react'
import {connect} from 'react-redux'

import {todayLinePositionSelector} from '../../../../data'
import {LineComponent} from './LineComponent'
import {TimelineTodayCircleComponent} from './TimelineTodayCircleComponent'
import {MeasurementsContext} from '../../../../shared/context/MeasurementsContext'
import {sidebarSizeSelector} from '../../../../data/settings/selectors/sidebarSizeSelector'

export interface ITodayLineComponentProps {
  isStickyToLeft: boolean
  isStickyToRight: boolean
  reactWindowHeight: number
  scrollToPosition: () => void
  todayLinePosition: number | null
  leftPosition: number
  sidebarSize: number
}

const getTodayLineClass = (initiativeHeaderHeight) => css`
  background: ${primary.gray(400)};
  top: ${initiativeHeaderHeight + 1}px;
  min-height: calc(100% - ${initiativeHeaderHeight}px);
`

export const TimeLineTodayStickyLine: React.FunctionComponent<ITodayLineComponentProps> = React.memo(
  (props) => {
    const testIdPostfix = props.isStickyToLeft ? '-left' : props.isStickyToRight ? '-right' : ''
    const linePosition =
      props.todayLinePosition !== null
        ? props.todayLinePosition + props.sidebarSize - props.leftPosition
        : null

    return (
      <MeasurementsContext.Consumer>
        {({initiativeRowHeight, initiativeHeaderHeight}) => (
          <>
            {linePosition !== null && (
              <LineComponent
                className={getTodayLineClass(initiativeHeaderHeight)}
                lineHeight={`${props.reactWindowHeight - initiativeRowHeight}px`}
                leftPosition={
                  props.isStickyToLeft
                    ? props.sidebarSize
                    : props.isStickyToRight
                    ? 0
                    : linePosition
                }
                rightPosition={props.isStickyToRight ? '10px' : 'auto'}
                testID={`today-sticky-line${testIdPostfix}`}
              >
                <TimelineTodayCircleComponent
                  scrollToPosition={props.scrollToPosition}
                  isClickable={props.isStickyToLeft || props.isStickyToRight}
                  testId={`today-circle${testIdPostfix}`}
                />
              </LineComponent>
            )}
          </>
        )}
      </MeasurementsContext.Consumer>
    )
  }
)

TimeLineTodayStickyLine.displayName = 'TimeLineTodayStickyLine'

const mapStateToProps = (state) => ({
  todayLinePosition: todayLinePositionSelector(state),
  sidebarSize: sidebarSizeSelector(state),
})

export const TodayLineComponent = connect(mapStateToProps)(TimeLineTodayStickyLine)
