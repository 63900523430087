import * as React from 'react'

export interface ICarouselContext {
  carouselStep: {
    step: number
    index: number
  }
  itemsPerStep: number
  isLeftArrowVisible: boolean
  isRightArrowVisible: boolean
  onLeftArrowClickHandler: () => void
  onRightArrowClickHandler: () => void
}

export const CarouselContext = React.createContext<ICarouselContext>(undefined!)
