import {css} from 'emotion'
import * as React from 'react'
import {connect} from 'react-redux'
import {getObjectLink} from '@quicksilver/objectmetadata'
import {Button, toast, primary, A} from '@phoenix/all'
import {Project} from 'workfront-objcodes'
import {currentPlanSelector} from '../../../data'
import {currentScenarioSelector, publishInitiatives} from '../../../data'
import {togglePublishDialog} from '../../../data'
import {publishedScenarioIdSelector} from '../../../data/viewOptions/selectors/publishedScenarioIdSelector'
import {IScenario} from '../../../shared/models'
import {LoaderComponent} from '../../shared'
import {SlidingPaneComponent} from '../../shared/SlidingPaneComponent/SlidingPaneComponent'
import {ButtonWithTooltipComponent} from '../ImportDialog/ButtonWithTooltipComponent'
import {peopleCostOnConflictCalculationSelector} from '../../../data/plan/selectors/peopleCostOnConflictCalculationSelector'
import SplitContext, {ISplitContext} from '../../../shared/context/SplitContext'
import {Translate} from '../../shared/Translate'
import {translate} from '../../../shared/utilities/TranslateHelper'
import InitiativeExportList from '../../../../spLists/export'

interface IPublishDialogProps {
  publishedScenarioIndex: number
  publishedScenario: IScenario
  publishInitiativesFunction: (
    scenario: IScenario,
    initiativesId: string[],
    shouldUpdateCurrentScenario: boolean,
    usePeopleCostOnConflictCalculation: boolean
  ) => Promise<any>
  planId: string
  shouldUpdateCurrentScenario: boolean
  togglePublishDialogFunction: (id: string | null) => void
  usePeopleCostOnConflictCalculation: boolean
}

const titleClass = css`
  color: ${primary.gray(600)};
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
`

const infoClass = css`
  color: ${primary.gray(500)};
  font-size: 11px;
  letter-spacing: 0;
  line-height: 13px;
`

const MAX_SELECTED_COUNT = 100

export const PublishScenarioDialog: React.FunctionComponent<IPublishDialogProps> = React.memo(
  (props) => {
    const projectURL = (ID: string) => getObjectLink({objCode: Project, ID}).to
    const isSplitEnabled: ISplitContext = React.useContext(SplitContext)
    const [buttonDisabled, setButtonDisabled] = React.useState(true)
    const [selectedInitiatives, setSelectedInitiatives] = React.useState([])
    const [isPublishing, setIsPublishing] = React.useState(false)

    const handleSelectionChange = React.useCallback((selectedIDs) => {
      setSelectedInitiatives(selectedIDs)
      const selectedCount = selectedIDs.length
      setButtonDisabled(selectedCount === 0 || selectedCount > MAX_SELECTED_COUNT)
    }, [])

    const onPublishClickHandler = () => {
      setIsPublishing(true)

      props
        .publishInitiativesFunction(
          props.publishedScenario,
          selectedInitiatives,
          props.shouldUpdateCurrentScenario,
          props.usePeopleCostOnConflictCalculation
        )
        .then(({publishedInitiatives, successMessage}) => {
          if (selectedInitiatives.length === 1) {
            const url = projectURL(publishedInitiatives.publishedObjects[0].refObject.id)

            toast.success(linkContainer(successMessage, `${url}/overview`))
          } else {
            toast.success(linkContainer(successMessage, '/projects/sp', true))
          }

          props.togglePublishDialogFunction(null)
        })
        .finally(() => {
          setIsPublishing(false)
        })
    }

    const linkContainer = (
      successMessage: string,
      url: string,
      isBulkPublished = false
    ): React.ReactElement => (
      <>
        <div>{successMessage}</div>
        <A
          data-testid={`${isBulkPublished ? 'bulk' : 'single'}-project-link-from-toast`}
          href={`${url}`}
          target="_blank"
        >
          {isBulkPublished
            ? translate('see.associated.projects')
            : translate('see.associated.project')}
        </A>
      </>
    )

    const onCancelClickHandler = () => {
      props.togglePublishDialogFunction(null)
    }

    const show = !!(props.publishedScenario && props.publishedScenario.id)

    return (
      <>
        <SlidingPaneComponent show={show}>
          <SlidingPaneComponent.Header>
            <div>
              <div className={titleClass} data-testid="publish-dialog">
                <Translate messageKey={'sp.messages.publish.dialog.title'} />
                {props.publishedScenarioIndex === 0 ? (
                  <Translate messageKey={'sp.scenario.initial'} />
                ) : (
                  <span data-testid="publish-scenario-text">
                    <Translate
                      messageKey={'sp.scenario'}
                      args={[props.publishedScenarioIndex + 1]}
                    />
                  </span>
                )}
              </div>
              <div className={infoClass}>
                <Translate messageKey={'sp.messages.publish.dialog.info'} />
              </div>
            </div>
          </SlidingPaneComponent.Header>
          <SlidingPaneComponent.Body>
            <React.Suspense fallback={<LoaderComponent isLoading={true} />}>
              {show && (
                <InitiativeExportList
                  scenarioId={props.publishedScenario.id}
                  planId={props.planId}
                  onSelectionChange={handleSelectionChange}
                  newFilterSpPlansListSplit={!!isSplitEnabled.FILTER_SP_PLANS_LIST}
                />
              )}
            </React.Suspense>
          </SlidingPaneComponent.Body>
          <SlidingPaneComponent.Footer>
            <ButtonWithTooltipComponent
              buttonMsgKey={
                isPublishing
                  ? 'sp.form.button.publishing.initiatives'
                  : 'sp.form.button.publish.initiatives'
              }
              onClickFunction={onPublishClickHandler}
              isDisabled={buttonDisabled}
              showTooltip={selectedInitiatives.length > MAX_SELECTED_COUNT}
              tooltipContent={
                <Translate
                  messageKey={'publish.dialog.button.tooltip'}
                  args={[MAX_SELECTED_COUNT, selectedInitiatives.length]}
                />
              }
              testId={'publish-initiatives'}
            />
            <Button text onClick={onCancelClickHandler} testID="cancel-initiatives">
              <Translate messageKey={'form.button.cancel'} />
            </Button>
          </SlidingPaneComponent.Footer>
        </SlidingPaneComponent>

        <LoaderComponent isLoading={isPublishing} insidePortal={true} />
      </>
    )
  }
)

PublishScenarioDialog.displayName = 'PublishScenarioDialog'

const mapStateToProps = (state) => ({
  publishedScenarioIndex: currentPlanSelector(state)!.scenarios.findIndex(
    (scenario) => scenario.id === publishedScenarioIdSelector(state)
  ),
  publishedScenario:
    publishedScenarioIdSelector(state) !== null
      ? currentScenarioSelector(state) !== null
        ? currentScenarioSelector(state)
        : currentPlanSelector(state)!.scenarios.find(
            (scenario) => scenario.id === publishedScenarioIdSelector(state)
          )
      : null,
  planId: currentPlanSelector(state)!.id,
  shouldUpdateCurrentScenario: currentScenarioSelector(state) !== null,
  usePeopleCostOnConflictCalculation: peopleCostOnConflictCalculationSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  publishInitiativesFunction: (
    scenario,
    initiativesId,
    shouldUpdateCurrentScenario,
    usePeopleCostOnConflictCalculation
  ) =>
    dispatch(
      publishInitiatives(
        scenario,
        initiativesId,
        shouldUpdateCurrentScenario,
        usePeopleCostOnConflictCalculation
      )
    ),
  togglePublishDialogFunction: (isOpen) => dispatch(togglePublishDialog(isOpen)),
})

export const PublishScenarioDialogComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(PublishScenarioDialog)
