import {Moment} from 'moment'
import {locale} from './browserInfo'
import {YEAR_MONTH_FORMAT} from '../constants/dateFormats'

export const isSameDateByMonthAndYear = (
  conflictPeriod: Moment,
  timelinePeriod: Moment
): boolean => {
  return (
    conflictPeriod.isSame(timelinePeriod, 'year') && conflictPeriod.isSame(timelinePeriod, 'month')
  )
}

const formatter = (options) => {
  return new Intl.DateTimeFormat(locale, options)
}

export const optionsForMonth = {year: 'numeric', month: 'short'}
export function formatDateBasedOnLocale(date: Moment, options = {}, applyTimezone = false): string {
  const dateObject = applyTimezone
    ? date.toDate()
    : new Date(date.year(), date.month(), date.date())

  return formatter(options).format(dateObject)
}

export function getBetweenDates(startDate: Moment, endDate: Moment): string[] {
  const start = startDate.clone()
  const distribution: string[] = []

  while (start <= endDate) {
    distribution.push(start.format(YEAR_MONTH_FORMAT))
    start.add(1, 'month')
  }

  return distribution
}
