import {createSelector} from 'reselect'
import {AccessMode} from '../../../shared/enums'
import {espAccessSelector} from './espAccessSelector'
import {planAccessSelector} from './planAccessSelector'

export const viewAccessSelector = createSelector(
  espAccessSelector,
  planAccessSelector,
  (espAccessMode: AccessMode | null, planAccessMode: AccessMode | null) => {
    return espAccessMode === AccessMode.view || planAccessMode === AccessMode.view
  }
)
