import {primary} from '@phoenix/all'
import * as React from 'react'
import styled from 'react-emotion'

interface ICircleInfoProps {
  icon: JSX.Element
  color?: string | null
  total?: number
  part?: number
}
interface ICircleStyledProps {
  dashoffset: number
  color: string
}
const CIRCUMFERENCE = 138

const CircleStyled = styled('div')<ICircleStyledProps>`
    @keyframes circle-${(props) => props.dashoffset}{
        from{
            stroke-dashoffset: 138px;
        }
        to{
            stroke-dashoffset:  ${(props) => props.dashoffset}px;
        }
    }
    
    display: table-cell;
    vertical-align: middle;
    width: 48px;
    height: 48px;
    position: relative;
    padding-right: 16px;
    
    svg:last-child {
        position: absolute;
        top: 50%;
        left: -16px;
        bottom: 0;
        right: 0;

        margin: -14px auto 0 auto;
    }
    
    .circle__svg{
        transform: rotate(-90deg);
    }
    
    .circle__progress {
        fill: none;
        stroke-width: 2;
        stroke-linecap: round;
    }
    
    .circle__progress--fill{
        stroke: ${(props) => props.color};
        ${(props) =>
          props.dashoffset === CIRCUMFERENCE
            ? 'display: none;'
            : `stroke-dasharray: ${CIRCUMFERENCE}px;
        animation: circle-${props.dashoffset} 1s forwards;`}   
        
    }
    
    .circle__progress--path{
        stroke: ${primary.gray(200)};
    }
`
export const CircleInfoComponent: React.FunctionComponent<ICircleInfoProps> = (props) => {
  const color = props.color !== null ? props.color! : primary.gray(200)
  let total = CIRCUMFERENCE
  let part = 0
  if (props.total && props.part) {
    if (props.total > props.part) {
      total = props.total
      part = props.part
    }
    if (props.total <= props.part) {
      total = props.total
      part = props.total
    }
  }
  const dashoffset = Math.round((CIRCUMFERENCE * (total - part)) / total)

  return (
    <CircleStyled dashoffset={dashoffset} color={color}>
      <svg width="48" height="48" className="circle__svg">
        <circle cx="24" cy="24" r="22" className="circle__progress circle__progress--path" />
        <circle
          cx="24"
          cy="24"
          r="22"
          className="circle__progress circle__progress--fill"
          data-testid="circle-progress"
        />
      </svg>
      {props.icon}
    </CircleStyled>
  )
}
