import * as React from 'react'
import {css, cx} from 'react-emotion'
import {createPortal} from 'react-dom'
import {IDataTableAPI} from '@wftypes/list'
import {Localization, LocalizationProvider} from '@wf-mfe/localize-react'
import {primary} from '@phoenix/all'
import {Font} from '@phoenix/all'
import {EMPTY_LIST_PLUGIN_NAME} from '../toolbar/constants'
import {redrockClientFactory} from '../../../shared/L10n'

interface EmptyListWidgetProps {
  listApi: IDataTableAPI
  mainMsgKey: string
  hintMsgKey: string
  wrapperStyles?: string
}

const colorKyloRen = css`
  color: ${primary.gray(500)};
`
const colorPebble = css`
  color: ${primary.gray(400)};
`
const wrapperElementStyles = css`
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const EmptyListWidget: React.FC<EmptyListWidgetProps> = ({
  listApi,
  mainMsgKey,
  hintMsgKey,
  wrapperStyles,
}) => {
  const [count, setCount] = React.useState<number | null>(null)
  React.useEffect(() => {
    return listApi.interceptors.registerInterceptor(
      EMPTY_LIST_PLUGIN_NAME,
      listApi.interceptors.events.AFTER_LOAD_PAGE_ITEMS,
      ({data: {pagination}}) => {
        setCount(pagination.count)
      }
    )
  })
  if (count === 0) {
    const wrapper = document.querySelector('.auto-sizer-wrapper')
    if (wrapper) {
      return createPortal(
        <LocalizationProvider clientFactory={redrockClientFactory}>
          <Localization messageKeys={[mainMsgKey, hintMsgKey]}>
            {(messages) => (
              <div
                className={cx(wrapperElementStyles, wrapperStyles)}
                data-testid="empty-list-placeholder"
              >
                <Font tagName="h2" type="h2" className={colorKyloRen}>
                  {messages[0]('No Results')}
                </Font>
                <Font tagName="h3" type="h3" className={colorPebble}>
                  {messages[1]('Revise your filters and try again.')}
                </Font>
              </div>
            )}
          </Localization>
        </LocalizationProvider>,
        wrapper
      )
    }
  }
  return null
}
