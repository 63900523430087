import LaunchSmallIcon from 'phoenix-icons/dist/LaunchSmallIcon.js'
import {Button, ConfirmDialog, Text} from '@phoenix/all'
import {css} from 'emotion'
import * as React from 'react'
import {connect} from 'react-redux'
import {currentInitiativeSelector} from '../../../../data/initiative'
import {currentPlanSelector} from '../../../../data/plan'
import {currentScenarioSelector} from '../../../../data/scenario'
import {viewAccessSelector, projectNoAccessSelector} from '../../../../data/settings'
import {togglePublishDialog} from '../../../../data/viewOptions'
import {hasPlanChanges, hasScenarioChanges, isNewPlan} from '../../../../shared/helpers'
import {useSavePlanHook} from '../../../../shared/helpers/useSavePlanHook'
import {IPlan, IScenario} from '../../../../shared/models'
import {PopperComponent, TooltipContentComponent} from '../../../shared'
import {Translate} from '../../../shared/Translate'
import {ConfirmationDialogHeaderComponent} from '../../../shared/ConfirmationDialogHeaderComponent'

const publishCLass = css`
  width: 100%;
  margin-bottom: 24px;
  margin-top: 4px;
`

interface IPublishButtonProps {
  currentPlan: IPlan
  savedPlan: IPlan
  isPublished: boolean
  hasPublishedScenario: boolean
  isHovered: boolean
  currentScenario: IScenario
  scenario: IScenario
  initiativeCount: number
  isPeopleCostsConflictsFeatureEnabled: boolean
  onSuccessfulSaveFunction: () => void
  togglePublishDialogFunction: (isOpen) => void
  hasNotProjectAccess: boolean
}
export const PublishButton: React.FunctionComponent<IPublishButtonProps> = (props) => {
  const [isConfirmDialogOpen, setConfirmDialog] = React.useState(false)
  const {savePlan} = useSavePlanHook(props.onSuccessfulSaveFunction)

  const isPlanChanged =
    isNewPlan(props.currentPlan) ||
    hasPlanChanges(props.savedPlan, props.currentPlan) ||
    hasScenarioChanges(props.currentPlan, props.currentScenario)
  const publishScenario = () => {
    if (isPlanChanged) {
      setConfirmDialog(true)
    } else {
      props.togglePublishDialogFunction(props.scenario.id)
    }
  }
  const cantPublish = () => {
    return (
      (props.hasPublishedScenario && !props.isPublished) ||
      !props.initiativeCount ||
      props.hasNotProjectAccess
    )
  }
  const saveChanges = () => {
    savePlan()
    setConfirmDialog(false)
  }

  const onCancel = () => {
    setConfirmDialog(false)
  }
  const reference = (
    <div>
      {(props.isHovered || isConfirmDialogOpen) && (
        <Button
          disabled={cantPublish()}
          icon={LaunchSmallIcon}
          className={`${publishCLass} publish`}
          onClick={publishScenario}
          secondary
          testID="publish-button"
        >
          <Translate messageKey={'form.button.publish'} />
        </Button>
      )}
    </div>
  )
  const disabledButtonTooltipContent = props.hasNotProjectAccess ? (
    <Translate messageKey={'sp.messages.publish.button.no.project.access'} />
  ) : (
    <Translate messageKey={'sp.messages.publish.button.disabled'} />
  )

  return (
    <React.Fragment>
      {props.isHovered && (props.hasNotProjectAccess || !props.initiativeCount) ? (
        <PopperComponent
          reference={reference}
          content={<TooltipContentComponent text={disabledButtonTooltipContent} />}
          isInsidePortal={true}
          showOnOver={true}
          placement="top"
        />
      ) : (
        reference
      )}
      {isConfirmDialogOpen && (
        <ConfirmDialog
          header={<ConfirmationDialogHeaderComponent messageKey={'scenario.unsaved'} />}
          confirmText={<Translate messageKey={'form.button.publish.save'} />}
          onConfirmClick={saveChanges}
          denyText={<Translate messageKey={'form.button.cancel'} />}
          onDenyClick={onCancel}
          testID="publish-scenario-confirmation-dialog"
        >
          <Text>
            <Translate messageKey={'sp.messages.publish.button'} />
          </Text>
        </ConfirmDialog>
      )}
    </React.Fragment>
  )
}
const mapDispatchToProps = (dispatch) => ({
  togglePublishDialogFunction: (isOpen) => dispatch(togglePublishDialog(isOpen)),
})
const mapStateToProps = (state) => ({
  hasEditAccess: !viewAccessSelector(state),
  hasNotProjectAccess: projectNoAccessSelector(state),
  savedPlan: state.plan.plan,
  currentPlan: currentPlanSelector(state),
  currentScenario: currentScenarioSelector(state),
  currentInitiative: currentInitiativeSelector(state),
})
export const PublishButtonComponent = connect(mapStateToProps, mapDispatchToProps)(PublishButton)
