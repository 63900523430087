import * as React from 'react'
import {useSelector} from 'react-redux'
import {timelineCellMonthWidthSelector} from '../../../../../../data/plan/selectors/timelineCellMonthWidthSelector'
import {RoleDistributionContext} from '../../../../../../shared/context/RoleDistributionContext'
import {
  CellStyled,
  dragEndingClassName,
  fillingInputClass,
  getDraggableElementStyle,
  inputItemClass,
  LEFT_BORDER_WIDTH,
} from './RoleDistributionHelper'
import {RoleDistributionDetailsCell} from './RoleDistributionDetailsCell'
import {DraggableFill} from '@workfront/draggable-fill'
import {IDistribution} from '../../../../../../shared/models/initiative/IDistribution'

export interface IRoleDistributionDetailsProps {
  availablePerPeriod: IDistribution
  requiredPerPeriod: IDistribution
  draggableElementTop: number
  roleId: string
}

export const RoleDistributionDetails: React.FC<IRoleDistributionDetailsProps> = React.memo(
  ({roleId, availablePerPeriod, requiredPerPeriod, draggableElementTop}) => {
    const {updateRoleAvailablePerPeriod} = React.useContext(RoleDistributionContext)
    const [inputValues, setInputValues] = React.useState<{[starDate: string]: number | null}>(
      availablePerPeriod ? availablePerPeriod : {}
    )

    const cellWidth = useSelector(timelineCellMonthWidthSelector)
    const updateInputValuesState = React.useCallback((key: string, value: number | null): void => {
      setInputValues((inputValues) => {
        return {
          ...inputValues,
          [key]: value,
        }
      })
    }, [])

    const onChangeHandlerForDraggable = React.useCallback(
      (key: string, value: number | null | '.'): void => {
        const convertedValue = typeof value !== 'number' ? 0 : value

        updateInputValuesState(key, convertedValue)
        updateRoleAvailablePerPeriod(roleId, {[key]: convertedValue!})
      },
      [roleId]
    )

    const valueGetter = React.useCallback(
      (key) => {
        return inputValues[key]
      },
      [inputValues]
    )

    const availablePerPeriodKeys: string[] = Object.keys(availablePerPeriod!)

    return (
      <DraggableFill
        cells={availablePerPeriodKeys}
        hoveringClassName={fillingInputClass}
        dragEndingClassName={dragEndingClassName}
        onChange={onChangeHandlerForDraggable}
        draggableElmClassName={getDraggableElementStyle(draggableElementTop)}
        valueGetter={valueGetter}
      >
        {({ref, data, onFocus, className, onBlur}) => {
          return (
            <CellStyled
              cellWidth={
                data === availablePerPeriodKeys[0] ? cellWidth : cellWidth + LEFT_BORDER_WIDTH
              }
              key={`${data}-${roleId}`}
              className={inputItemClass}
              innerRef={ref}
            >
              <RoleDistributionDetailsCell
                availableValue={inputValues[data]}
                requiredValue={requiredPerPeriod[data]}
                availableForCalculations={availablePerPeriod[data] || 0}
                roleId={roleId}
                distributionKey={data}
                updateInputValuesState={updateInputValuesState}
                onBlur={onBlur}
                onFocus={onFocus}
                className={className}
              />
            </CellStyled>
          )
        }}
      </DraggableFill>
    )
  }
)

RoleDistributionDetails.displayName = 'RoleDistributionDetails'
