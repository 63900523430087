import {css} from 'react-emotion'
import {primary, secondary} from '@phoenix/all'

export const toolbarIconWithText = css`
  width: auto !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
  min-width: 32px;
`

export const toolbarIcon = css`
  outline: none !important;
  text-align: center;
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  border-radius: 3px;
  box-sizing: border-box;
  align-content: center;
  margin: 0 8px !important;
  padding: 0;
  background: none;
  border: 2px solid transparent;
  line-height: 0;
  > svg {
    margin: 0 auto;
  }
  & + [data-toolbar-button] {
    margin-left: 0 !important;
  }
`

export const toolbarIconHover = css`
  &:hover,
  &:focus {
    background-color: ${primary.gray(100)};

    [data-testid='badge'] {
      border-color: ${primary.gray(100)};
    }
  }

  &:focus {
    border: 2px solid ${secondary.lightblue(200)};
  }
`

export const toolbarIconActive = css`
  &:active {
    background-color: ${primary.gray(200)};
    [data-testid='badge'] {
      border-color: ${primary.gray(200)};
    }
  }
`

export const toolbarIconChecked = css`
  background-color: ${primary.gray(200)};
  &:hover {
    background-color: ${primary.gray(200)};
  }
  &:focus {
    background-color: ${primary.gray(200)};
    border: 2px solid ${secondary.lightblue(200)};
  }
  [data-testid='badge'] {
    border-color: ${primary.gray(200)};
  }
  &:hover,
  &:focus {
    [data-testid='badge'] {
      border-color: ${primary.gray(200)};
    }
  }
`
