import * as React from 'react'
import {css} from 'react-emotion'

import {primary} from '@phoenix/all'
import {PopperComponent} from '../../../shared'

import {resetButtonDefaultStyles} from '../../../shared'
import {TooltipContentComponent} from '../../../shared'
import {Translate} from '../../../shared/Translate'

interface ITimelineTodayCircleComponentProps {
  isClickable: boolean
  scrollToPosition: (position: null | number) => void
  testId?: string
  className?: string
}

interface ITimelineTodayCircleComponentState {
  isOpen: boolean
}

const CIRCLE_WIDTH = 9

const todayCircleDefaultClass = css`
  position: absolute;
  border-radius: 50%;
  background-color: ${primary.gray(400)};
  height: ${CIRCLE_WIDTH}px;
  left: -4px;
  padding: 0;
  width: ${CIRCLE_WIDTH}px;
  top: 0;
  z-index: 100;
`

const todayCircleClickableClass = css`
  ${resetButtonDefaultStyles}
  ${todayCircleDefaultClass};
  transition: box-shadow 0.2s;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.7);
  }
`

export class TimelineTodayCircleComponent extends React.Component<
  ITimelineTodayCircleComponentProps,
  ITimelineTodayCircleComponentState
> {
  constructor(props: ITimelineTodayCircleComponentProps) {
    super(props)
    this.state = {
      isOpen: false,
    }
  }

  render() {
    return (
      <React.Fragment>
        <PopperComponent
          reference={this.circleRender()}
          placement="top"
          content={
            <TooltipContentComponent
              text={
                this.props.isClickable ? (
                  <Translate messageKey={'timeline.see.today'} />
                ) : (
                  <Translate messageKey={'timeline.today'} />
                )
              }
            />
          }
          showOnOver={true}
        />
      </React.Fragment>
    )
  }

  private circleRender = () => {
    if (this.props.isClickable) {
      return (
        <button
          className={todayCircleClickableClass}
          onClick={() => this.props.scrollToPosition(null)}
          data-testid={this.props.testId}
        />
      )
    } else {
      return <span className={todayCircleDefaultClass} data-testid={this.props.testId} />
    }
  }
}
