import {css} from 'emotion'
import * as React from 'react'
import {Translate} from './Translate'

interface IConfirmationDialogHeaderComponent {
  messageKey: string
  args?: {[key: string]: string | number}
}

const headerClass = css`
  line-height: normal;
`

export const ConfirmationDialogHeaderComponent: React.FunctionComponent<IConfirmationDialogHeaderComponent> = ({
  messageKey,
  args,
}) => (
  <div className={headerClass}>
    <Translate messageKey={messageKey} args={args} />
  </div>
)
