import * as React from 'react'
import {SubValueStyled} from './RoleDistributionHelper'
import {primary, secondary, Ellipse} from '@phoenix/all'
import {formatFloating, formatNumber} from '../../../../../../shared/utilities'

interface IRoleDistributionDetailsCalculatedDatatProps {
  availableValue: number | null
  requiredValue?: number
  testID: string
}

export const RoleDistributionDetailsCalculatedData: React.FC<IRoleDistributionDetailsCalculatedDatatProps> = React.memo(
  ({availableValue, requiredValue, testID}) => {
    const getUtilizationColor = () => {
      const available = availableValue || 0
      const required = requiredValue || 0

      if (available === 0 && required === 0) {
        return primary.gray(900)
      }

      if (required > available) {
        return secondary.red()
      }

      if (required < available) {
        return primary.blue()
      }

      return secondary.green()
    }

    const getUtilizationValue = () => {
      const utilization = availableValue ? ((requiredValue || 0) / availableValue) * 100 : 0
      return formatNumber(utilization, 2)
    }

    const calculateDifference = (available: number | null, required: number | undefined): number =>
      (available || 0) - (required || 0)

    return (
      <>
        <SubValueStyled data-testid={`required-number-${testID}`}>
          <Ellipse>{requiredValue ? formatFloating(requiredValue, 3) : 0}</Ellipse>
        </SubValueStyled>

        <SubValueStyled data-testid={`difference-number-${testID}`}>
          <Ellipse>{formatFloating(calculateDifference(availableValue, requiredValue), 3)}</Ellipse>
        </SubValueStyled>
        <SubValueStyled color={getUtilizationColor()} data-testid={`utilization-number-${testID}`}>
          {getUtilizationValue()}%
        </SubValueStyled>
      </>
    )
  }
)

RoleDistributionDetailsCalculatedData.displayName = 'RoleDistributionDetailsCalculatedData'
