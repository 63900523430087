import {AccessMode, TimelineMode} from '../../shared/enums'
import {LOAD_SETTINGS_FAILURE, LOAD_SETTINGS_SUCCESS, SET_SIDEBAR_SIZE} from './actions'
import {ISettingsState} from './ISettingsState'
import {translate} from '../../shared/utilities/TranslateHelper'
import {getSidebarInitialSize} from '../../shared/utilities'

const settingsInitialState: ISettingsState = {
  currency: 'USD',
  durationOptions: [
    {label: translate('form.duration.years', [1]), value: 1},
    {label: translate('form.duration.years', [3]), value: 3},
    {label: translate('form.duration.years', [5]), value: 5},
  ],
  timelineModeOptions: [
    {
      value: TimelineMode.MONTHLY,
      label: translate('timeline.mode.month'),
      requiredYearCount: 1,
    },
    {
      value: TimelineMode.QUARTERLY,
      label: translate('timeline.mode.quarter'),
      requiredYearCount: 3,
    },
    {
      value: TimelineMode.YEARLY,
      label: translate('timeline.mode.year'),
      requiredYearCount: 5,
    },
  ],
  permissions: new Map([
    ['PLAN', null],
    ['FINANCIAL_DATA', AccessMode.noAccess],
    ['ESP_DATA', null],
    ['PROJECT', AccessMode.noAccess],
  ]),
  sidebarSize: getSidebarInitialSize(),
}

export function settings(state = settingsInitialState, action): ISettingsState {
  switch (action.type) {
    case LOAD_SETTINGS_SUCCESS: {
      return {
        ...state,
        ...action.settings,
      }
    }
    case LOAD_SETTINGS_FAILURE: {
      return {
        ...state,
        permissions: new Map([
          ['PLAN', AccessMode.noAccess],
          ['FINANCIAL_DATA', AccessMode.noAccess],
          ['ESP_DATA', AccessMode.noAccess],
          ['PROJECT', AccessMode.noAccess],
        ]),
      }
    }
    case SET_SIDEBAR_SIZE: {
      return {
        ...state,
        sidebarSize: action.sidebarSize,
      }
    }
    default:
      return state
  }
}

// TODO: Leave only currency and permissions here. Other data is fixed, it should be moved to corresponding view
