const BASE_URL = '/esp'

export function loadSPFilters(queryParams: {[key: string]: unknown} = {}) {
  const url = `${BASE_URL}/initiatives/filters`

  return makeRequest(url, queryParams)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function loadInitiatives(queryParams: {[key: string]: unknown}) {
  const url = `${BASE_URL}/initiatives`
  if (queryParams['filterId'] === null) delete queryParams['filterId']

  return makeRequest(url, queryParams)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function loadInitiativesCount(queryParams: {[key: string]: unknown}) {
  const url = `${BASE_URL}/initiatives/count`
  if (queryParams['filterId'] === null) delete queryParams['filterId']

  return makeRequest(url, queryParams)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function makeRequest(url: string, data: Record<string, unknown>) {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }
  const queryParams = Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(String(data[key]))}`)
    .join('&')
  const queryUrl = queryParams ? `?${queryParams}` : ''

  return fetch(`${url}${queryUrl}`, options).then((response) => response.json())
}
