import {LocalizationProvider, LoadLocalizations} from '@wf-mfe/localize-react'
import {getStorageUtil} from '@workfront/storage'
import * as React from 'react'
import {Provider} from 'react-redux'
import {applyMiddleware, createStore} from 'redux'
import thunk from 'redux-thunk'
import {combinedPlanReducer} from '../../data/reducers'
import {scenarioInitialState} from '../../data'
import {ISplitContext} from '../../shared/context/SplitContext'
import {composeEnhancers} from '../../shared/DevTool'
import PlanComponent from './PlanComponent'
import {MeasurementProvider} from './MeasurementProvider'
import {SplitProvider} from '../../shared/context/SplitProvider'
import {messagesKeys} from '../../../MessageKeys'
import {espClientFactory} from '../../../shared/L10n'

export interface IPlanWrapperComponentProps {
  planId: string | null
  rolesLoadFunction: () => Promise<any[]>
  navigateToListFunction: () => void
  onSuccessfulSaveFunction: (planId: string, scenarioId: string | null) => void
  toggles: ISplitContext
}

export class PlanWrapperComponent extends React.Component<IPlanWrapperComponentProps> {
  private readonly store

  constructor(props: IPlanWrapperComponentProps) {
    super(props)

    // get data from localStorage and pass to the store before it will be created
    const storageUtil = getStorageUtil()
    const showConflicts = storageUtil.get(props.planId!)
    const scenario = {
      scenario: {
        ...scenarioInitialState,
        showConflicts:
          showConflicts === null ? scenarioInitialState.showConflicts : JSON.parse(showConflicts),
      },
    }

    this.store = createStore(
      combinedPlanReducer,
      scenario,
      composeEnhancers('ESP Plan')(applyMiddleware(thunk))
    )
  }

  render() {
    const {hash} = window.location
    let scenarioId: string | null = null
    if (hash !== '#compare') {
      scenarioId = hash.slice(1)
    }

    return (
      <SplitProvider value={this.props.toggles} store={this.store}>
        <LocalizationProvider clientFactory={espClientFactory}>
          <LoadLocalizations messageKeys={messagesKeys}>
            <MeasurementProvider>
              <Provider store={this.store}>
                <PlanComponent
                  navigateToListFunction={this.props.navigateToListFunction}
                  planId={this.props.planId}
                  scenarioId={scenarioId}
                  rolesLoadFunction={this.props.rolesLoadFunction}
                  onSuccessfulSaveFunction={this.props.onSuccessfulSaveFunction}
                />
              </Provider>
            </MeasurementProvider>
          </LoadLocalizations>
        </LocalizationProvider>
      </SplitProvider>
    )
  }
}
