import {createSelector} from 'reselect'
import {AccessMode} from '../../../shared/enums'
import {planAccessSelector} from './planAccessSelector'

export const hasEditAccessToPlanSelector = createSelector(
  planAccessSelector,
  (planAccessMode: AccessMode | null) => {
    return planAccessMode !== null && planAccessMode === AccessMode.edit
  }
)
