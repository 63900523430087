import InfoSmallIcon from 'phoenix-icons/dist/InfoSmallIcon.js'
import {css} from 'emotion'
import {Moment} from 'moment'
import * as React from 'react'
import {connect} from 'react-redux'

import {Button} from '@phoenix/all'
import {
  currentPlanIdSelector,
  currentPlanStartDateSelector,
  currentScenarioInitiativesSelector,
  importInitiatives,
  planDurationSelector,
  planEndDateSelector,
  toggleImportDialog,
  toggleImportLoading,
} from '../../../data'
import {isImportDialogOpenSelector} from '../../../data/viewOptions/selectors/isImportDialogOpenSelector'
import {track} from '../../../shared/helpers'
import {IInitiative} from '../../../shared/models'
import {formatDateBasedOnLocale} from '../../../shared/utilities/dateHelpers'
import {LoaderComponent, PopperComponent, TooltipContentComponent} from '../../shared'
import {SlidingPaneComponent} from '../../shared/SlidingPaneComponent/SlidingPaneComponent'
import {ButtonWithTooltipComponent} from './ButtonWithTooltipComponent'
import {peopleCostOnConflictCalculationSelector} from '../../../data/plan/selectors/peopleCostOnConflictCalculationSelector'
import {Translate} from '../../shared/Translate'
import ProjectImportList from '../../../../spLists/import'

interface IProjectImportDialogProps {
  isImportDialogOpen: boolean
  planStartDate: Moment
  planDuration: number
  planEndDate: Moment
  currentScenarioInitiatives: IInitiative[]
  toggleImportDialogFunction: (isOpen: boolean) => void
  toggleLoadingFunction: (isOpen: boolean) => void
  importInitiativesFunction: (
    selectedProjects,
    planStartDate,
    planDuration,
    usePeopleCostOnConflictCalculation: boolean
  ) => Promise<IInitiative[]>
  currentPlanId: string
  usePeopleCostOnConflictCalculation: boolean
}

const dialogHeaderInnerClass = css`
  display: flex;
  align-items: center;
  height: 100%;
`

const titleClass = css`
  letter-spacing: 0;
  line-height: 24px;
`
const iconClass = css`
  margin-left: 8px;
  margin-bottom: -4px;
`

export const MAX_SELECTED_COUNT = 100

export const ProjectImportDialog: React.FunctionComponent<IProjectImportDialogProps> = React.memo(
  (props) => {
    const [buttonDisabled, setButtonDisabled] = React.useState(true)
    const [selectedProjects, setSelectedProjects] = React.useState([])
    const handleSelectionChange = React.useCallback((selectedIDs) => {
      setSelectedProjects(selectedIDs)
      const selectedCount = selectedIDs.length
      setButtonDisabled(selectedCount === 0 || selectedCount > MAX_SELECTED_COUNT)
    }, [])
    const onCancelClickHandler = () => {
      props.toggleImportDialogFunction(false)
    }
    const onImportClickHandler = () => {
      props.toggleImportDialogFunction(false)
      props.toggleLoadingFunction(true)
      props
        .importInitiativesFunction(
          selectedProjects,
          props.planStartDate!,
          props.planDuration,
          props.usePeopleCostOnConflictCalculation
        )
        .then((initiatives) => {
          if (initiatives) {
            track('imported initiatives at a time', {
              [props.currentPlanId]: initiatives.length,
            })
          }
        })
        .finally(() => {
          props.toggleLoadingFunction(false)
        })
    }
    const startDate = props.planStartDate
    const endDate = props.planEndDate.clone().subtract(1, 'day')

    const startDateToDate = React.useRef<Date>()
    const endDateToDate = React.useRef<Date>()
    const importedInitiatives = React.useRef<string[]>([])
    React.useEffect(() => {
      startDateToDate.current = startDate.toDate()
      endDateToDate.current = props.planEndDate.toDate()
    }, [startDate.toDate().getTime(), props.planEndDate.toDate().getTime()])
    React.useEffect(() => {
      if (props.currentScenarioInitiatives) {
        importedInitiatives.current = props.currentScenarioInitiatives
          .filter((initiative: IInitiative) => initiative.refObject)
          .map((initiative: IInitiative) => initiative.refObject!.id)
      } else {
        importedInitiatives.current = []
      }
    }, [props.currentScenarioInitiatives])
    return (
      <SlidingPaneComponent show={props.isImportDialogOpen}>
        <SlidingPaneComponent.Header>
          <div className={dialogHeaderInnerClass}>
            <span className={titleClass}>
              <Translate
                messageKey={'sp.import.projects.header'}
                args={[formatDateBasedOnLocale(startDate), formatDateBasedOnLocale(endDate)]}
              />
            </span>

            <PopperComponent
              reference={
                <span className={iconClass}>
                  <InfoSmallIcon />
                </span>
              }
              placement="right"
              content={
                <TooltipContentComponent
                  text={<Translate messageKey={'sp.import.projects.tooltip'} />}
                />
              }
              showOnOver={true}
              isInsidePortal={true}
            />
          </div>
        </SlidingPaneComponent.Header>
        <SlidingPaneComponent.Body>
          <React.Suspense fallback={<LoaderComponent isLoading={true} />}>
            <ProjectImportList
              onSelectionChange={handleSelectionChange}
              startDate={startDateToDate.current}
              endDate={endDateToDate.current}
              excludeProjectIDs={importedInitiatives.current}
            />
          </React.Suspense>
        </SlidingPaneComponent.Body>
        <SlidingPaneComponent.Footer>
          <ButtonWithTooltipComponent
            buttonMsgKey={'import'}
            onClickFunction={onImportClickHandler}
            isDisabled={buttonDisabled}
            showTooltip={selectedProjects.length > MAX_SELECTED_COUNT}
            tooltipContent={
              <Translate
                messageKey={'import.button.tooltip'}
                args={[MAX_SELECTED_COUNT, selectedProjects.length]}
              />
            }
            testId={'import-project'}
          />

          <Button text onClick={onCancelClickHandler} testID="cancel-project">
            <Translate messageKey={'form.button.cancel'} />
          </Button>
        </SlidingPaneComponent.Footer>
      </SlidingPaneComponent>
    )
  }
)

ProjectImportDialog.displayName = 'ProjectImportDialog'

const mapStateToProps = (state) => ({
  isImportDialogOpen: isImportDialogOpenSelector(state),
  planStartDate: currentPlanStartDateSelector(state),
  planDuration: planDurationSelector(state),
  planEndDate: planEndDateSelector(state),
  currentScenarioInitiatives: currentScenarioInitiativesSelector(state),
  currentPlanId: currentPlanIdSelector(state),
  usePeopleCostOnConflictCalculation: peopleCostOnConflictCalculationSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  toggleLoadingFunction: (isLoading) => dispatch(toggleImportLoading(isLoading)),
  toggleImportDialogFunction: (isOpen) => dispatch(toggleImportDialog(isOpen)),
  importInitiativesFunction: (
    selectedProjects,
    planStartDate,
    planDuration,
    usePeopleCostOnConflictCalculation
  ) =>
    dispatch(
      importInitiatives(
        selectedProjects,
        planStartDate,
        planDuration,
        usePeopleCostOnConflictCalculation
      )
    ),
})

export const ProjectImportDialogComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectImportDialog)
