import {css, cx} from 'emotion'
import * as React from 'react'
import {connect} from 'react-redux'
import {primary, Switch, Text} from '@phoenix/all'
import {
  changePlanSettings,
  currencySelector,
  currentPlanSelector,
  recalculatePlanScenariosBudgets,
} from '../../../../../data/plan'
import {
  recalculateScenarioBudgetConflicts,
  showConflictsSelector,
} from '../../../../../data/scenario'
import {currentScenarioBudgetFixedCostsSelector} from '../../../../../data/scenario/selectors/currentScenarioBudgetFixedCostsSelector'
import {currentScenarioBudgetPeopleCostsSelector} from '../../../../../data/scenario/selectors/currentScenarioBudgetPeopleCostsSelector'
import {FormattedCurrencyComponent} from '../../../../shared'
import {financialViewAccessSelector, viewAccessSelector} from '../../../../../data/settings'
import {peopleCostOnConflictCalculationSelector} from '../../../../../data/plan/selectors/peopleCostOnConflictCalculationSelector'
import {isNewPlan} from '../../../../../shared/helpers'
import {Translate} from '../../../../shared/Translate'

interface IHeaderBudgetCostSectionProps {
  peopleCosts: number | null
  fixedCosts: number | null
  currency: string
  isViewMode: boolean
  usePeopleCostOnConflictCalculation: boolean
  changePlanSettingsHandler: (settings: {[key: string]: any}, planId?: string) => void
  recalculateBudgetConflictsFunction: (
    showConflicts: boolean,
    usePeopleCostOnConflictCalculation: boolean
  ) => void
  recalculatePlanScenariosBudgetsFunction: (usePeopleCostOnConflictCalculation: boolean) => void
  planId?: string
  isNew: boolean
  showConflicts: boolean
  viewAccess: boolean
}

const flexClass = css`
  display: flex;
`
const costsDescriptionClass = css`
  padding-bottom: 16px;
`
const fixedCostsClass = css`
  min-width: 93px;
  padding-top: 2px;
  padding-right: 16px;
  flex-basis: 10%;
  border-right: 1px solid ${primary.wallE()};
`
const peopleCostsClass = css`
  padding: 2px 16px;
  flex-basis: 90%;

  .switch-label {
    padding: 0;
    margin-left: 8px !important;
    margin-top: -2px;
  }
`
const costNameClass = css`
  color: ${primary.gray(400)};
  text-transform: uppercase;
  font-weight: 600;
`
const disableClass = css`
  color: rgba(21, 24, 30, 0.5);
`
const costsClass = css`
  border-top: 1px solid ${primary.gray(300)};
  padding: 16px 0 0;
`
const costClass = css`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`
const costValueClass = css`
  color: ${primary.gray(700)};
  font-size: 14px;
  padding: 8px 0 10px 0;
`

export const HeaderBudgetCostSection: React.FunctionComponent<IHeaderBudgetCostSectionProps> = ({
  peopleCosts,
  fixedCosts,
  showConflicts,
  usePeopleCostOnConflictCalculation,
  changePlanSettingsHandler,
  recalculateBudgetConflictsFunction,
  recalculatePlanScenariosBudgetsFunction,
  planId,
  currency,
  isNew,
  viewAccess,
}) => {
  const toggleOnChangeHandler = () => {
    const planSettings = {
      usePeopleCostOnConflictCalculation: !usePeopleCostOnConflictCalculation,
    }

    changePlanSettingsHandler(planSettings, !isNew ? planId : undefined)
    recalculateBudgetConflictsFunction(showConflicts, !usePeopleCostOnConflictCalculation)
    recalculatePlanScenariosBudgetsFunction(!usePeopleCostOnConflictCalculation)
  }
  return (
    <div className={costsClass}>
      <div className={costsDescriptionClass}>
        <Text.Small>
          <Translate messageKey={'sp.scenario.widget.budget.costs.description'} />
        </Text.Small>
      </div>
      <div className={flexClass}>
        <div className={cx(costClass, fixedCostsClass)}>
          <Text.Small className={costNameClass}>
            <Translate messageKey={'scenario.widget.budget.costs.fixed'} />
          </Text.Small>

          <div className={costValueClass}>
            <FormattedCurrencyComponent
              cost={fixedCosts}
              currency={currency}
              isAbbreviated={true}
            />
          </div>
        </div>
        <div className={cx(costClass, peopleCostsClass)}>
          <Text.Small className={costNameClass}>
            <Translate messageKey={'scenario.widget.budget.costs.people'} />
          </Text.Small>

          <div
            className={cx(costValueClass, !usePeopleCostOnConflictCalculation ? disableClass : '')}
          >
            <FormattedCurrencyComponent
              cost={peopleCosts}
              currency={currency}
              isAbbreviated={true}
            />
          </div>

          <Switch
            testID={'people-costs-conflicts-show-hide-toggle'}
            name="people-costs-conflict-toggle"
            label={<Translate messageKey={'scenario.enable.people.costs.conflicts'} />}
            onChange={toggleOnChangeHandler}
            checked={usePeopleCostOnConflictCalculation}
            disabled={viewAccess}
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const currentPlan = currentPlanSelector(state)

  return {
    peopleCosts: currentScenarioBudgetPeopleCostsSelector(state),
    fixedCosts: currentScenarioBudgetFixedCostsSelector(state),
    currency: currencySelector(state),
    isViewMode: financialViewAccessSelector(state),
    usePeopleCostOnConflictCalculation: peopleCostOnConflictCalculationSelector(state),
    planId: currentPlan!.id,
    isNew: currentPlan ? isNewPlan(currentPlan) : false,
    showConflicts: showConflictsSelector(state),
    viewAccess: viewAccessSelector(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  changePlanSettingsHandler: (planSettings, planId) =>
    dispatch(changePlanSettings(planSettings, planId)),
  recalculateBudgetConflictsFunction: (showConflicts, usePeopleCostOnConflictCalculation) =>
    dispatch(recalculateScenarioBudgetConflicts(showConflicts, usePeopleCostOnConflictCalculation)),
  recalculatePlanScenariosBudgetsFunction: (usePeopleCostOnConflictCalculation) =>
    dispatch(recalculatePlanScenariosBudgets(usePeopleCostOnConflictCalculation)),
})

export const HeaderBudgetCostSectionComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderBudgetCostSection)
