import React from 'react'
import {connect} from 'react-redux'

import {Switch} from '@phoenix/all'
import {getStorageUtil} from '@workfront/storage'

import {changePreCalculatedData, currentInitiativeSelector} from '../../../../data/initiative'
import {currentPlanSelector} from '../../../../data/plan'
import {
  currentScenarioSelector,
  showConflictsSelector,
  toggleHideConflict,
} from '../../../../data/scenario'
import {isEditSidebarOpenSelector} from '../../../../data/viewOptions'
import {getPreCalculatedDataForCurrentInitiative} from '../../../../shared/helpers'
import {IPlan} from '../../../../shared/models'
import {IInitiative, IPreCalculatedData} from '../../../../shared/models/initiative'
import {IScenario} from '../../../../shared/models/scenario'
import {peopleCostOnConflictCalculationSelector} from '../../../../data/plan/selectors/peopleCostOnConflictCalculationSelector'
import {financialAccessDeniedSelector} from '../../../../data/settings'
import {Translate} from '../../../shared/Translate'

interface IConflictsShowHideToggleProps {
  isEditSidebarOpen: boolean
  currentInitiative: IInitiative
  toggleHideConflictFunction: (
    showConflicts: boolean,
    usePeopleCostOnConflictCalculation: boolean
  ) => void
  currentScenario: IScenario
  changePreCalculatedDataFunction: (
    preCalculatedData: IPreCalculatedData | null,
    usePeopleCostOnConflictCalculation: boolean
  ) => void
  plan: IPlan
  showConflicts: boolean
  usePeopleCostOnConflictCalculation: boolean
  hasFinancialAccess: boolean
}

export const ConflictsShowHideToggle = React.memo<IConflictsShowHideToggleProps>((props) => {
  const storageUtil = getStorageUtil()
  const toggleOnChangeHandler = (showConflicts: boolean): void => {
    props.toggleHideConflictFunction(showConflicts, props.usePeopleCostOnConflictCalculation)

    if (props.isEditSidebarOpen) {
      const preCalculatedData = showConflicts
        ? getPreCalculatedDataForCurrentInitiative(
            props.currentInitiative,
            props.currentScenario.initiatives,
            props.currentScenario.people.roles,
            props.currentScenario.budgets && props.currentScenario.budgets.distribution,
            props.usePeopleCostOnConflictCalculation,
            props.hasFinancialAccess
          )
        : null
      props.changePreCalculatedDataFunction(
        preCalculatedData,
        props.usePeopleCostOnConflictCalculation
      )
    }

    storageUtil.set(props.plan.id!, showConflicts)
  }

  const checked: boolean | string =
    storageUtil.get(props.plan.id!) !== null ? storageUtil.get(props.plan.id!) : props.showConflicts

  return (
    <Switch
      testID={'' + 'show-hide-toggle'}
      name="conflict-toggle"
      label={<Translate messageKey="show.conflicts" />}
      onChange={() => toggleOnChangeHandler(!props.showConflicts)}
      checked={checked}
    />
  )
})

ConflictsShowHideToggle.displayName = 'ConflictsShowHideToggle'

const mapStateToProps = (state) => ({
  plan: currentPlanSelector(state),
  currentScenario: currentScenarioSelector(state),
  showConflicts: showConflictsSelector(state),
  currentInitiative: currentInitiativeSelector(state),
  isEditSidebarOpen: isEditSidebarOpenSelector(state),
  usePeopleCostOnConflictCalculation: peopleCostOnConflictCalculationSelector(state),
  hasFinancialAccess: !financialAccessDeniedSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  toggleHideConflictFunction: (hideConflict, usePeopleCostOnConflictCalculation) =>
    dispatch(toggleHideConflict(hideConflict, usePeopleCostOnConflictCalculation)),
  changePreCalculatedDataFunction: (preCalculatedData, usePeopleCostOnConflictCalculation) => {
    dispatch(changePreCalculatedData(preCalculatedData, usePeopleCostOnConflictCalculation))
  },
})

export const ConflictsShowHideToggleComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConflictsShowHideToggle)
