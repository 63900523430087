import {Moment} from 'moment'
import {TimelineMode} from '../enums'
import {IPeriod} from '../models/timeline'
import {formatDateBasedOnLocale, optionsForMonth} from '../utilities/dateHelpers'

const QUARTERS = ['Q1', 'Q2', 'Q3', 'Q4']

export const MONTHS_COUNT_IN_YEAR = 12
export const QUARTERS_COUNT_IN_YEAR = 4
export const MONTHS_COUNT_IN_QUARTER = 3

export function getTimelinePeriods(
  mode: TimelineMode,
  startDate: Moment,
  durationInYears: number
): IPeriod[] {
  if (mode === TimelineMode.MONTHLY) {
    return createMonthPeriods(startDate, durationInYears)
  } else if (mode === TimelineMode.QUARTERLY) {
    return createQuarterPeriods(startDate, durationInYears)
  } else if (mode === TimelineMode.YEARLY) {
    return createYearPeriods(startDate, durationInYears)
  } else {
    throw new Error(`Unsupported timeline mode ${mode}`)
  }
}

function createMonthPeriods(startDate: Moment, durationInYears: number): IPeriod[] {
  const durationInMonths = durationInYears * MONTHS_COUNT_IN_YEAR
  const monthPeriods: IPeriod[] = []
  const periodStartDate = startDate.clone()
  let index = 0
  while (index < durationInMonths) {
    monthPeriods.push({
      label: createMonthPeriodLabels(periodStartDate),
      startDate: periodStartDate.clone(),
      monthCount: 1,
    })
    periodStartDate.add(1, 'months')
    index++
  }
  return monthPeriods
}

export function createMonthPeriodLabels(startDate: Moment): string {
  return formatDateBasedOnLocale(startDate, optionsForMonth)
}

function createQuarterPeriods(startDate: Moment, durationInYears: number): IPeriod[] {
  let quarterPeriods: IPeriod[] = []
  const hasOnlyCompleteQuarters = startDate.month() % MONTHS_COUNT_IN_QUARTER === 0
  const quarterStartDate = hasOnlyCompleteQuarters
    ? startDate.clone()
    : startDate
        .clone()
        .add(MONTHS_COUNT_IN_QUARTER - (startDate.month() % MONTHS_COUNT_IN_QUARTER), 'months')
  const normalQuartersCount = hasOnlyCompleteQuarters
    ? durationInYears * QUARTERS_COUNT_IN_YEAR
    : durationInYears * QUARTERS_COUNT_IN_YEAR - 1
  for (let i = 0; i < normalQuartersCount; i++) {
    quarterPeriods.push({
      label: `${QUARTERS[quarterStartDate.quarter() - 1]} ${quarterStartDate.year()}`,
      startDate: quarterStartDate.clone(),
      monthCount: MONTHS_COUNT_IN_QUARTER,
    })
    quarterStartDate.add(1, 'quarters')
  }
  if (!hasOnlyCompleteQuarters) {
    const firstQuarter = {
      label: '',
      startDate: startDate.clone(),
      monthCount: MONTHS_COUNT_IN_QUARTER - (startDate.month() % MONTHS_COUNT_IN_QUARTER),
    }

    const lastQuarter = {
      label: '',
      startDate: quarterStartDate.clone(),
      monthCount: startDate.month() % MONTHS_COUNT_IN_QUARTER,
    }
    quarterPeriods = [firstQuarter, ...quarterPeriods, lastQuarter]
  }
  return quarterPeriods
}

function createYearPeriods(startDate: Moment, durationInYears: number): IPeriod[] {
  let yearPeriods: IPeriod[] = []
  const hasOnlyCompleteYears = startDate.month() === 0
  const yearStartDate = hasOnlyCompleteYears
    ? startDate.clone()
    : startDate.clone().add(MONTHS_COUNT_IN_YEAR - startDate.month(), 'months')
  const completeYearCount = hasOnlyCompleteYears ? durationInYears : durationInYears - 1
  for (let i = 0; i < completeYearCount; i++) {
    yearPeriods.push({
      label: yearStartDate.year().toString(),
      startDate: yearStartDate.clone(),
      monthCount: MONTHS_COUNT_IN_YEAR,
    })
    yearStartDate.add(1, 'years')
  }
  if (!hasOnlyCompleteYears) {
    const firstYear = {
      label: '',
      startDate: startDate.clone(),
      monthCount: MONTHS_COUNT_IN_YEAR - (startDate.month() % MONTHS_COUNT_IN_YEAR),
    }

    const lastYear = {
      label: '',
      startDate: yearStartDate.clone(),
      monthCount: startDate.month() % MONTHS_COUNT_IN_YEAR,
    }
    yearPeriods = [firstYear, ...yearPeriods, lastYear]
  }
  return yearPeriods
}

export function getTimelineMonths(mode: TimelineMode): number {
  if (mode === TimelineMode.MONTHLY) {
    return MONTHS_COUNT_IN_YEAR
  } else if (mode === TimelineMode.YEARLY) {
    return 5 * MONTHS_COUNT_IN_YEAR
  } else {
    return 3 * MONTHS_COUNT_IN_YEAR
  }
}
export function calculateCellWidth(containerWidth: number, mode: TimelineMode): number {
  const minWidth = mode ? 24 : 18
  const cellWidth = containerWidth / getTimelineMonths(mode)
  return cellWidth > minWidth ? cellWidth : minWidth
}

export function calculateDefaultTimelineMode(durationInYears: number): TimelineMode {
  if (durationInYears === 1) {
    return TimelineMode.MONTHLY
  } else if (durationInYears === 3) {
    return TimelineMode.QUARTERLY
  } else {
    return TimelineMode.YEARLY
  }
}
