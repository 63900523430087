import {
  toolbarIcon,
  toolbarIconActive,
  toolbarIconChecked,
  toolbarIconHover,
  toolbarIconWithText,
} from '../util/toolbarIconStyles'
import * as React from 'react'
import type {IconComponent} from 'phoenix-icons/dist'
import ToolbarButtonLabel from './ToolbarButtonLabel'
import ToolbarButtonIcon from './ToolbarButtonIcon'

interface ToolbarButtonProps {
  icon: IconComponent // A component to render as an icon
  tooltipLabel: string // A label to show as an icon tooltip
  dataTestId?: string // An attribute to use as identifier in tests
  onClick: () => void // The onClick handler to assign to toolbar button
  text?: string // A text to display near the icon. Optional.
  isChecked?: boolean // If true, icon will rendered as 'pressed'. Defaults to false.
  isBadgeEnabled?: boolean // If true, a badge will be rendered over the icon. Defaults to false.
  isDisabled?: boolean // Whenever to put disabled attribute on toolbar button. Defaults to false.
}
export default function ToolbarButton(props: ToolbarButtonProps): JSX.Element {
  const {icon, tooltipLabel, dataTestId, onClick, isDisabled, text, isChecked = false} = props

  const isTextEnabled = !!text

  return (
    <button
      className={[
        toolbarIcon,
        toolbarIconHover,
        toolbarIconActive,
        isChecked && toolbarIconChecked,
        isTextEnabled && toolbarIconWithText,
      ]
        .filter(Boolean)
        .join(' ')}
      data-toolbar-button=""
      data-testid={dataTestId}
      onClick={onClick}
      disabled={isDisabled}
      title={tooltipLabel}
    >
      <ToolbarButtonIcon icon={icon} />
      {isTextEnabled && <ToolbarButtonLabel>{text}</ToolbarButtonLabel>}
    </button>
  )
}
