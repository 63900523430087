import * as React from 'react'
import {TRenderer} from '@wftypes/list'
import {QuickFilterMatcher} from '../../../shared/types/quickFilter'

type Ti18nWrapper<R> = (messages) => R

// eslint-disable-next-line react/display-name
export const InitiativeDuration: Ti18nWrapper<TRenderer> = (messages) => (props) => {
  const {duration, highlight, filterValue} = props

  const durationString =
    duration === 1 ? messages.espPublishMonth : messages.espPublishMonths([duration])
  return <div>{highlight(filterValue, durationString)}</div>
}

export const initiativeDurationMatcher: Ti18nWrapper<QuickFilterMatcher> = (messages) => (
  {duration},
  filterValue
) => {
  const durationString =
    duration === 1 ? messages.espPublishMonth : messages.espPublishMonths([duration])
  return durationString.toLowerCase().indexOf(filterValue.toLowerCase()) > -1
}
