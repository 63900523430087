import {css, cx} from 'react-emotion'
import * as React from 'react'
import {connect} from 'react-redux'

import DeleteIcon from 'phoenix-icons/dist/DeleteIcon.js'
import {primary} from '@phoenix/all'
import {viewAccessSelector} from '../../../../../data/settings'
import {changeResolvableRoles, resolvableRolesSelector} from '../../../../../data/viewOptions'

import {IInitiativeRole, IRoleConflict} from '../../../../../shared/models/initiative'
import {TextWithTooltipComponent} from '../../../../shared'
import {ConflictDetailWrapperComponent} from '../ConflictDetailWrapperComponent'
import {PeopleListItemConflictComponent} from './PeopleListItemConflictComponent'
import {RoleDistributionComponent} from './RoleDistributionComponent'
import {destructureConflicts} from '../../../../../shared/helpers/conflicts/RoleConflictHelper'
import {conflictDetailLabelClass} from './StyleHelper'
import {currentScenarioPeopleSelector} from '../../../../../data/scenario'
import {IScenarioPeople} from '../../../../../shared/models/scenario'

export interface IPeopleListItemComponentProps {
  role: IInitiativeRole
  isNew: boolean
  isViewMode?: boolean
  deleteRoleFunction: (id: string) => void
  resolvableRoles: string[]
  resolvableRolesChangeFunction: (resolvableRoles: string[]) => void
  isFocused: boolean
  roleConflict: IRoleConflict | null
  index: number
  scenarioPeople: IScenarioPeople
}

const sidebarRoleRowClass = css`
  &:hover {
    .deleteButton {
      opacity: 1;
    }
  }
`
const sidebarRoleRowFocusClass = css`
  .deleteButton {
    opacity: 1;
  }
`
const sidebarRoleClass = css`
  display: flex;
  align-items: center;
`

const bottomBorderClass = css`
  border-bottom: 1px solid ${primary.wallE()};
`

const roleNameClass = css`
  color: ${primary.blackWidow()};
  font-size: 14px;
`

const roleCountClass = css`
  margin-right: 8px;
`

const deleteButtonClass = css`
  border: 0;
  background-color: transparent;
  padding: 0;
  opacity: 0;
  margin: 0;
  cursor: pointer;
`

export const PeopleListItem: React.FunctionComponent<IPeopleListItemComponentProps> = React.memo(
  (props) => {
    const [isRowFocused, setRoleFocused] = React.useState(false)
    const deleteResolvableRoleId = () => {
      props.resolvableRolesChangeFunction(
        props.resolvableRoles.filter((id: string) => id !== props.role.id)
      )
    }

    const onClickHandler = () => {
      props.deleteRoleFunction(props.role.id)
      deleteResolvableRoleId()
    }

    const scenarioRole = props.scenarioPeople.roles.find((item) => item.role.id === props.role.id)
    const availablePerPeriod = scenarioRole ? scenarioRole.availablePerPeriod : {}
    const availableValue = scenarioRole ? scenarioRole.availableValue : 0

    const destructuredPeriodConflicts = props.roleConflict
      ? destructureConflicts(
          props.roleConflict.periodConflicts,
          props.role.distribution!,
          availablePerPeriod,
          availableValue || 0
        ) // here props.role.distribution can't be null
      : null

    return (
      <ConflictDetailWrapperComponent
        className={cx(
          sidebarRoleRowClass,
          props.roleConflict ? 'initiative-conflict' : '',
          isRowFocused ? sidebarRoleRowFocusClass : ''
        )}
        hasConflict={!!props.roleConflict}
        testID="sidebar-role-row"
        index={props.index}
      >
        <div className={cx(sidebarRoleClass, props.roleConflict ? bottomBorderClass : '')}>
          <div className={conflictDetailLabelClass}>
            <TextWithTooltipComponent
              testID="sidebar-role-name"
              className={roleNameClass}
              text={props.role.name}
            />
          </div>

          <div className={roleCountClass}>
            <RoleDistributionComponent
              onBlur={() => setRoleFocused(false)}
              onFocus={() => setRoleFocused(true)}
              isFocused={props.isNew && props.isFocused}
              role={props.role}
              periodConflictDetails={destructuredPeriodConflicts}
            />
          </div>

          {!props.isViewMode && (
            <button
              data-testid="delete-role"
              className={`deleteButton ${deleteButtonClass}`}
              onClick={onClickHandler}
              onBlur={() => setRoleFocused(false)}
              onFocus={() => setRoleFocused(true)}
            >
              <DeleteIcon color={primary.gray(500)} />
            </button>
          )}
        </div>

        {props.roleConflict && (
          <PeopleListItemConflictComponent
            role={props.role}
            periodConflictDetails={destructuredPeriodConflicts}
          />
        )}
      </ConflictDetailWrapperComponent>
    )
  }
)

PeopleListItem.displayName = 'PeopleListItem'

const mapDispatchToProps = (dispatch) => ({
  resolvableRolesChangeFunction: (resolvableRoles) =>
    dispatch(changeResolvableRoles(resolvableRoles)),
})

const mapStateToProps = (state) => ({
  resolvableRoles: resolvableRolesSelector(state),
  isViewMode: viewAccessSelector(state),
  scenarioPeople: currentScenarioPeopleSelector(state),
})

export const PeopleListItemComponent = connect(mapStateToProps, mapDispatchToProps)(PeopleListItem)
