import * as React from 'react'
import {useSelector} from 'react-redux'
import {inputsWrapperClass} from './RoleDistributionHelper'
import {IScenarioRole} from '../../../../../../shared/models/scenario'
import {viewAccessSelector} from '../../../../../../data/settings'
import {RoleDistributionDetailsViewMode} from './RoleDistributionDetailsViewMode'
import {RoleDistributionDetails} from './RoleDistributionDetails'

export interface IRoleDistributionDetailsRowProps {
  roleDetails: IScenarioRole
  draggableElementTop: number
  roleId: string
}

export const RoleDistributionDetailsRow: React.FC<IRoleDistributionDetailsRowProps> = React.memo(
  ({roleId, roleDetails, draggableElementTop}) => {
    const availablePerPeriod = roleDetails.availablePerPeriod
    const requiredPerPeriod = roleDetails.requiredPerPeriod
    const isViewMode = useSelector(viewAccessSelector)
    const rowRef = React.useRef<HTMLDivElement>(null)

    return (
      <div className={inputsWrapperClass} ref={rowRef}>
        {isViewMode && (
          <RoleDistributionDetailsViewMode
            availablePerPeriod={availablePerPeriod}
            requiredPerPeriod={requiredPerPeriod}
            roleId={roleId}
          />
        )}

        {!isViewMode && (
          <RoleDistributionDetails
            availablePerPeriod={availablePerPeriod}
            requiredPerPeriod={requiredPerPeriod}
            draggableElementTop={draggableElementTop}
            roleId={roleId}
          />
        )}
      </div>
    )
  }
)

RoleDistributionDetailsRow.displayName = 'RoleDistributionDetailsRow'
