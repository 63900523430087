import {css} from 'emotion'
import * as React from 'react'
import {connect} from 'react-redux'
import RoiIcon from 'phoenix-icons/dist/RoiIcon.js'
import {primary, secondary, Text} from '@phoenix/all'
import {currencySelector} from '../../../../data/plan'
import {currentScenarioSelector} from '../../../../data/scenario'
import {formatCurrency, formatNumber} from '../../../../shared/utilities'
import {HeaderDescriptionComponent} from './HeaderDescriptionComponent'
import {HeaderWidgetWrapperComponent} from './HeaderWidgetWrapperComponent'
import {TooltipComponent} from './TooltipComponent'
import {Translate} from '../../../shared/Translate'

export interface IHeaderNetValueWidgetComponent {
  netValue: number | null
  currency: string
}

const dataContainerClass = css`
  padding: 0px 8px;
  flex: 1 0 0;
`

const labelClass = css`
  text-transform: uppercase;
  color: ${primary.gray(700)};
  font-weight: 600;
  letter-spacing: 0.5px;
`

const negativeValueClass = css`
  color: ${secondary.red()};
`

export const HeaderNetValueWidget: React.FunctionComponent<IHeaderNetValueWidgetComponent> = (
  props: IHeaderNetValueWidgetComponent
) => {
  const {netValue, currency} = props

  return (
    <HeaderWidgetWrapperComponent type="net-value" icon={<RoiIcon />}>
      <HeaderDescriptionComponent messageKey={'summary'} testID="net-value" />

      <div className={dataContainerClass}>
        <div>
          {netValue !== null ? (
            <TooltipComponent
              size="large"
              value={formatNumber(netValue)!}
              tooltipValue={formatCurrency(netValue, currency, false)!}
              testId={'net-value-widget-value'}
              className={netValue < 0 ? negativeValueClass : ''}
            />
          ) : (
            <Text.Large>{'0'}</Text.Large>
          )}
        </div>
        <div>
          <Text.Small className={labelClass}>
            <Translate messageKey={'scenario.widget.net'} />
          </Text.Small>
        </div>
      </div>
    </HeaderWidgetWrapperComponent>
  )
}

const mapStateToProps = (state) => ({
  netValue: currentScenarioSelector(state)!.netValue,
  currency: currencySelector(state),
})

export const HeaderNetValueWidgetComponent = connect(mapStateToProps)(HeaderNetValueWidget)
