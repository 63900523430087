import {css} from 'emotion'
import * as React from 'react'
import {connect} from 'react-redux'
import {primary, Tooltip, Checkbox} from '@phoenix/all'
import {espAccessSelector} from '../../../../data/settings'
import {AccessMode} from '../../../../shared/enums'
import {Translate} from '../../../shared/Translate'

interface IResolveCheckboxComponentProps {
  isEditMode: boolean
  isChecked: boolean
  onChange: () => void
  name: string
  label: string
  tooltipContent: string
  testID: string
  tooltipContainerClass: string
}

const resolveConflictClass = css`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 32px;
  border: 1px solid ${primary.voldemort()};
  margin-top: 12px;
  border-radius: 2px;

  input {
    display: block;
    float: left;
    margin-top: 6px !important;
    width: auto !important;
  }

  span span {
    display: block;
    float: left;
    margin-top: 3px !important;
    max-width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`
export const ResolveCheckbox: React.FC<IResolveCheckboxComponentProps> = React.memo(
  ({
    isEditMode,
    isChecked,
    onChange,
    name,
    label,
    tooltipContent,
    testID,
    tooltipContainerClass,
  }) => {
    return isEditMode ? (
      <Tooltip
        content={
          <div className={tooltipContainerClass}>
            <Translate messageKey={tooltipContent} />
          </div>
        }
        delay={300}
        position={'top'}
      >
        <div className={resolveConflictClass}>
          <Checkbox
            testID={testID}
            name={name}
            checked={isChecked}
            onChange={onChange}
            label={<Translate messageKey={label} />}
          />
        </div>
      </Tooltip>
    ) : null
  }
)

ResolveCheckbox.displayName = 'ResolveCheckbox'

const mapStateToProps = (state) => ({
  isEditMode: espAccessSelector(state) === AccessMode.edit,
})

export const ResolveCheckboxComponent = connect(mapStateToProps)(ResolveCheckbox)
