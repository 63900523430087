import {messagesObject} from '../../../MessageKeys'
import {espClient} from '../../../shared/L10n'

export const getTranslationFallback = (
  key: string,
  args?: string[] | number[] | Record<string, any>
): string => {
  return Array.isArray(args) && args.length ? messagesObject[key]([...args]) : messagesObject[key]
}

export const translate = (
  key: string,
  args?: string[] | number[] | Record<string, any>
): string => {
  const fallback = getTranslationFallback(key, args)
  return Array.isArray(args) && args.length
    ? espClient.getTextSync(key, fallback, args)
    : espClient.getTextSync(key, fallback)
}
