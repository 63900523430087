import {css} from 'emotion'
import * as React from 'react'
import {connect} from 'react-redux'

import {updateScenarioBudget} from '../../../../../data/scenario'

import {currentScenarioBudgetSelector} from '../../../../../data/scenario'
import {financialViewAccessSelector} from '../../../../../data/settings'
import {toggleBudgetWidget} from '../../../../../data/viewOptions'
import {calculateRemaining} from '../../../../../shared/helpers'
import {IBudgetsMonth} from '../../../../../shared/models/scenario'
import {IScenarioBudgets} from '../../../../../shared/models/scenario'
import {IYearlyBudget} from '../../../../../shared/models/scenario'
import {isListItemsEqual} from '../../../../../shared/utilities'
import {isValidIntegerNumber} from '../../../../../shared/utilities'
import {HeaderExpandedWidgetComponent} from '../HeaderExpandedWidgetComponent'
import {HeaderBudgetAdvancedSectionComponent} from './HeaderBudgetAdvancedSectionComponent'
import {HeaderBudgetCostSectionComponent} from './HeaderBudgetCostSectionComponent'
import {HeaderBudgetDetailSectionComponent} from './HeaderBudgetDetailSectionComponent'
import {peopleCostOnConflictCalculationSelector} from '../../../../../data/plan/selectors/peopleCostOnConflictCalculationSelector'
import {Translate} from '../../../../shared/Translate'

interface IHeaderBudgetExpandedWidgetProps {
  budgets: IScenarioBudgets
  updateBudgetFunction: (
    budget: IScenarioBudgets,
    usePeopleCostOnConflictCalculation: boolean
  ) => void
  collapseWidget: () => void
  isViewMode: boolean
  usePeopleCostOnConflictCalculation: boolean
}

interface IHeaderBudgetExpandedWidgetState {
  isDetailOpen: boolean
  key: string
  isResetting: boolean
}

const contentClass = css`
  width: 388px;

  p {
    top: 44px;
  }
`

const MONTHS_COUNT_IN_YEAR = 12

class HeaderBudgetExpandedWidget extends React.Component<
  IHeaderBudgetExpandedWidgetProps,
  IHeaderBudgetExpandedWidgetState
> {
  constructor(props) {
    super(props)

    this.state = {
      isDetailOpen: false,
      key: props.budgets.distribution.keys().next().value,
      isResetting: false,
    }
  }

  render() {
    const {collapseWidget, isViewMode} = this.props
    const {key, isDetailOpen} = this.state

    return (
      <HeaderExpandedWidgetComponent
        paddingBottom={16}
        text={
          <Translate
            messageKey={
              isViewMode
                ? 'sp.scenario.widget.budget.info.view'
                : 'sp.scenario.widget.budget.info.edit'
            }
          />
        }
        collapseWidget={collapseWidget}
      >
        <div className={`${contentClass} expanded-budget-widget-inner`}>
          <HeaderBudgetDetailSectionComponent
            toggleDetailFunction={this.toggleDetail}
            isDetailOpen={isDetailOpen}
            handleYearFocus={this.handleYearFocus}
            handleYearBlur={this.handleYearBlur}
          />

          {isDetailOpen && (
            <HeaderBudgetAdvancedSectionComponent
              currentDistributionKey={key}
              handleBudgetMonthBlur={this.handleBudgetMonthBlur}
              resetBudget={this.resetBudget}
            />
          )}
          <HeaderBudgetCostSectionComponent />
        </div>
      </HeaderExpandedWidgetComponent>
    )
  }

  private toggleDetail = (isDetailOpen: boolean) => {
    this.setState({
      isDetailOpen,
    })
  }

  private handleBudgetMonthBlur = (value, index) => {
    const {
      budgets,
      budgets: {distribution},
    } = this.props
    const {key} = this.state
    const distributionClone: Map<string, IYearlyBudget> = new Map(distribution)
    const currentDistribution = distributionClone.get(key)!
    const currentBudgetMonths = [...currentDistribution.budgetMonths]

    if (this.isValueNotChanged(value, currentBudgetMonths[index].amount)) {
      return
    }

    currentBudgetMonths.splice(index, 1, {
      yearMonthDate: currentBudgetMonths[index].yearMonthDate,
      amount: isValidIntegerNumber(value) ? parseInt(value, 10) : 0,
    })

    distributionClone.set(key, {
      total: currentDistribution.total,
      budgetMonths: currentBudgetMonths,
    })

    const changedBudget = {
      ...budgets,
      distribution: distributionClone,
    }
    this.props.updateBudgetFunction(changedBudget, this.props.usePeopleCostOnConflictCalculation)
  }

  private handleYearBlur = (value, key) => {
    const {
      budgets: {distribution},
    } = this.props

    if (this.isValueNotChanged(value, distribution.get(key)!.total)) {
      return
    }

    this.calculateDistribution(value, key)
  }

  private calculateDistribution = (value, key) => {
    const total = isValidIntegerNumber(value) ? parseInt(value, 10) : 0
    const distributionClone = new Map(this.props.budgets.distribution)
    let currentDistribution = distributionClone.get(key)!
    const budgetMonths = [...currentDistribution.budgetMonths]

    if (this.canDistributedAutomatically(currentDistribution)) {
      currentDistribution = {
        total,
        budgetMonths: budgetMonths.map((month: IBudgetsMonth) => ({
          yearMonthDate: month.yearMonthDate,
          amount: this.calculateDistributionBudgetMonths(total),
        })),
      }
    } else {
      currentDistribution = {
        ...currentDistribution,
        total,
      }
    }

    const distribution = distributionClone.set(key, currentDistribution)
    const changedBudget = {
      ...this.props.budgets,
      distribution: distributionClone,
    }

    calculateRemaining(distribution.get(this.state.key)!)
    this.props.updateBudgetFunction(changedBudget, this.props.usePeopleCostOnConflictCalculation)

    this.setState({
      isResetting: false,
    })
  }

  private calculateDistributionBudgetMonths = (total: number | null) => {
    return total === null ? total : Math.floor(total / MONTHS_COUNT_IN_YEAR)
  }

  private canDistributedAutomatically = (distribution) => {
    const remaining = calculateRemaining(distribution)
    const isBudgetMonthsEqual =
      remaining < 12 && remaining >= 0 && isListItemsEqual(distribution.budgetMonths)

    return isBudgetMonthsEqual || this.state.isResetting
  }

  private handleYearFocus = (e, key) => {
    this.setState({
      key: key,
    })
  }

  private resetBudget = () => {
    const {key} = this.state
    const {budgets} = this.props

    const total = budgets.distribution.get(key)!.total

    this.setState(
      {
        isResetting: true,
      },
      () => {
        this.calculateDistribution(total, key)
      }
    )
  }

  private isValueNotChanged = (currentInputValue, prevInputValue) => {
    return currentInputValue === '' && prevInputValue === null
  }
}

const mapStateToProps = (state) => ({
  budgets: currentScenarioBudgetSelector(state),
  isViewMode: financialViewAccessSelector(state),
  usePeopleCostOnConflictCalculation: peopleCostOnConflictCalculationSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  updateBudgetFunction: (budget, usePeopleCostOnConflictCalculation) =>
    dispatch(updateScenarioBudget(budget, usePeopleCostOnConflictCalculation)),
  collapseWidget: () => dispatch(toggleBudgetWidget(false)),
})

export const HeaderBudgetExpandedWidgetComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderBudgetExpandedWidget)

// TODO: there is some weird behaviour regarding calculateRemaining. Examine it
