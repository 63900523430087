export const getPillWidth = (txt, fontName, fontSize, fontWeight) => {
  const element = document.createElement('span')
  element.id = 'temp-element'
  element.innerText = txt
  element.style.fontFamily = fontName
  element.style.fontSize = fontSize + 'px'
  element.style.visibility = 'hidden'
  element.style.padding = '0 8px'
  element.style.whiteSpace = 'nowrap'
  element.style.fontWeight = fontWeight
  document.body.appendChild(element)
  const width = element.offsetWidth
  element.parentElement!.removeChild(element)

  return width
}
