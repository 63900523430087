import * as React from 'react'
import {IconComponent} from 'phoenix-icons/dist'
import {Dropdown} from '@phoenix/all'
import {useCallback, useRef, useState} from 'react'
import {IOption} from '../widgets/DropDownWidget'
import {ALL_PLANS_FILTER} from '../../../plan/plugins/constants'
import ToolbarFilterButton from './ToolbarFilterButton'

interface ToolbarDropDownProps {
  dataTestId: string
  onChange: (filter: IOption) => void
  options: IOption[]
  selectedOption: IOption
  icon: IconComponent
}

export default function ToolbarDropDown(props: ToolbarDropDownProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false)
  const triggerRef = useRef(null)

  const changeOpenState = () => {
    setIsOpen(!isOpen)
  }

  const {dataTestId, onChange, options, selectedOption} = props

  const handleWidgetApi = useCallback(
    (api) => {
      if (selectedOption) {
        api.setIsActive(!(!selectedOption.id || selectedOption.id === ALL_PLANS_FILTER))
      }
    },
    [selectedOption]
  )

  return (
    <Dropdown
      multiSelect={false}
      onChange={onChange}
      options={options}
      selectedOptions={[selectedOption]}
      testID={'optionsTestId'}
      triggerRef={triggerRef}
      onIsOpenChanged={changeOpenState}
    >
      <div ref={triggerRef}>
        <ToolbarFilterButton
          typeMessageKey={selectedOption?.id?.toLowerCase() || ''}
          typeFallback={selectedOption?.label || ''}
          dataTestId={dataTestId}
          getApi={handleWidgetApi}
          commands={{}}
          location={'secondary'}
        />
      </div>
    </Dropdown>
  )
}
