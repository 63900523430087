import * as React from 'react'
import {Spinner, Avatar} from '@phoenix/all'
import {fetchData} from '../../shared/services/api/ApiService'
import {getAvatarUrl, AvatarSize} from '../../shared/helpers/User'

interface IRequestAccessOwnerComponentProps {
  planID: string
}

interface IUserData {
  name: string
  avatarUrl: string
}

const getPlanOwnerEndpoint = (planID) => `/accessorObject/granters/${planID}`

const loadPlanOwner = async (planID) => {
  const endpoint = getPlanOwnerEndpoint(planID)

  const owners = await fetchData<any>(endpoint)

  return owners[0]
}

const loadAvatarDate = async (userID) => {
  const response = await fetchData<any>(`/attask/api-internal/USER/${userID}?fields=avatarDate`, {
    omitBaseUrl: true,
  })

  return response.data.avatarDate
}

export const RequestAccessOwnerComponent: React.FC<IRequestAccessOwnerComponentProps> = ({
  planID,
}) => {
  const [isLoading, setIsLoading] = React.useState(true)
  const [userData, setUserData] = React.useState<IUserData | null>(null)

  React.useEffect(() => {
    ;(async () => {
      /*
        Dear diary,
        Today I was asked to add the owner information to the "Request Access" page.
        Naturally, I was surprised that we should add a piece of information that the current user has no access to.
        I did my best to convince the PM and UX NOT to show the owner information at all but did not succeed.
        
        In order to load the owner data, we had to implement a special endpoint which doesn't do any access checks 
        and returns the owner id.
        Unfortunately, the endpoint has no access to the avatar date (because the avatar date is in Redrock, not in the SP).
        So, we have to make an additional call to the Redrock to retrieve the avatar date.
        I know that this sucks, but apparently the owner name and the avatar are very important for the "Request Access" page.
        Anyway, I think stoicism will help me to overcome these difficult times.
        "If it is endurable, then endure it. Stop complaining."
                                                            Marcus Aurelius (C)
      */
      const owner = await loadPlanOwner(planID)

      const avatarDate = await loadAvatarDate(owner.id)

      const avatarUrl = getAvatarUrl(owner.id, avatarDate, AvatarSize.MEDIUM)

      setUserData({
        name: owner.name,
        avatarUrl,
      })

      setIsLoading(false)
    })()
  }, [planID])

  if (isLoading) {
    return <Spinner />
  }

  if (userData) {
    return <Avatar type="medium" avatarURL={userData.avatarUrl} label={userData.name} showLabel />
  }

  return null
}
