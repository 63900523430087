import * as React from 'react'

const DEFAULT_MIN_VALUE = -9007199254740991
const DEFAULT_MAX_VALUE = 9007199254740991

const defaultNumberValidator = (value: string, precision: number) => {
  const finalPrecision = typeof precision === 'number' ? precision : 0
  const numberRegex = new RegExp(`^[+-]{0,1}[0-9]*\\.?[0-9]{0,${finalPrecision}}$`)
  return numberRegex.test(value)
}

interface INumberEditorProps {
  maxValue?: number
  minValue?: number
  onChange: (value) => void
  onBlur: (value) => void
  parseValue?: (value) => void
  precision: number
  testID: string
  validateValue?: () => void
  value: number | null
  ref: React.Ref<HTMLInputElement>
}

export const NumberEditorComponent: React.FunctionComponent<INumberEditorProps> = React.forwardRef(
  (
    {
      testID,
      value,
      minValue,
      maxValue,
      validateValue,
      precision,
      parseValue,
      children,
      onChange,
      onBlur,
      ...rest
    },
    ref: React.Ref<HTMLInputElement>
  ) => {
    const [valueAsString, setValueAsString] = React.useState(value ? value.toString() : '')

    const isEmpty = React.useCallback((newValue) => {
      const trimmedValue = newValue.trim()
      return trimmedValue === ''
    }, [])

    const parseNewValue = React.useCallback(
      (newValue) => {
        if (isEmpty(newValue)) {
          return null
        }
        if (parseValue) {
          return parseValue(newValue)
        }
        return parseFloat(newValue)
      },
      [isEmpty, parseValue]
    )

    const notifyAboutChange = React.useCallback(
      (value, stringValue) => {
        onChange(value)
        setValueAsString(stringValue)
      },
      [onChange]
    )

    const onChangeHandler = React.useCallback(
      (e) => {
        const newValue = e.target.value
        if (newValue === '.') {
          onChange(newValue)
          return
        }

        if (isEmpty(newValue)) {
          notifyAboutChange(null, newValue.trim())
        } else {
          const validator = validateValue ? validateValue : defaultNumberValidator
          const isValueValid = validator(newValue, precision)

          if (isValueValid) {
            const initialParsedValue = parseNewValue(newValue)
            const parsedValue = isNaN(initialParsedValue as number) ? null : initialParsedValue
            if (parsedValue !== null) {
              const actualMinValue = typeof minValue === 'number' ? minValue : DEFAULT_MIN_VALUE
              const actualMaxValue = typeof maxValue === 'number' ? maxValue : DEFAULT_MAX_VALUE
              const isWithinConstraints =
                parsedValue >= actualMinValue && parsedValue <= actualMaxValue
              if (isWithinConstraints) {
                notifyAboutChange(parsedValue, newValue)
              }
            } else {
              notifyAboutChange(null, '')
            }
          }
        }
      },
      [isEmpty, notifyAboutChange, validateValue, precision, parseNewValue, minValue, maxValue]
    )

    const onBlurHandler = ({target}) => {
      const parsedValue = target.value && target.value !== '.' ? parseFloat(target.value) : ''
      setValueAsString(`${parsedValue}`)
      onBlur(parsedValue)
    }

    React.useEffect(() => {
      const currentParsedValue = parseNewValue(valueAsString)
      if (value !== currentParsedValue) {
        /*
                  If the string representation of the current value does not match the current numeric value,
                  we should recalculate it.
                */
        let newValueAsString
        if (value === null) {
          newValueAsString = ''
        } else {
          newValueAsString = value.toString()
        }
        setValueAsString(newValueAsString)
      }
    }, [parseNewValue, value, valueAsString])

    const inputElement =
      typeof children === 'function' ? (
        children(
          {
            ...rest,
            testID,
            value: valueAsString,
            onChange: onChangeHandler,
            onBlur: onBlurHandler,
          },
          ref
        )
      ) : (
        <input
          {...rest}
          autoComplete="off"
          data-testid={testID}
          value={valueAsString}
          onChange={onChangeHandler}
          onBlur={onBlurHandler}
          ref={ref}
        />
      )

    return inputElement
  }
)

NumberEditorComponent.displayName = 'NumberEditorComponent'
