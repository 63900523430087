import {Ellipse} from '@phoenix/all'
import * as React from 'react'
import {useSelector} from 'react-redux'
import {formatFloating} from '../../../../../../shared/utilities'
import {
  CellStyled,
  inputItemClass,
  LEFT_BORDER_WIDTH,
  SubValueStyled,
} from './RoleDistributionHelper'
import {RoleDistributionDetailsCalculatedData} from './RoleDistributionDetailsCalculatedData'
import {IDistribution} from '../../../../../../shared/models/initiative/IDistribution'
import map from 'lodash/map'
import {timelineCellMonthWidthSelector} from '../../../../../../data/plan/selectors/timelineCellMonthWidthSelector'

export interface IRoleDistributionDetailsViewModeProps {
  availablePerPeriod: IDistribution
  requiredPerPeriod: IDistribution
  roleId: string
}

export const RoleDistributionDetailsViewMode: React.FC<IRoleDistributionDetailsViewModeProps> = React.memo(
  ({availablePerPeriod, requiredPerPeriod, roleId}) => {
    const cellWidth = useSelector(timelineCellMonthWidthSelector)

    return map(availablePerPeriod, (value, distributionKey, index) => (
      <CellStyled
        cellWidth={index === 0 ? cellWidth : cellWidth + LEFT_BORDER_WIDTH}
        key={`${index}-${roleId}`}
        className={inputItemClass}
      >
        <SubValueStyled data-testid={`role-distribution-value-${roleId}-${distributionKey}`}>
          <Ellipse>{formatFloating(value, 3)}</Ellipse>
        </SubValueStyled>

        <RoleDistributionDetailsCalculatedData
          availableValue={value}
          requiredValue={requiredPerPeriod[distributionKey]}
          testID={`${roleId}-${distributionKey}`}
        />
      </CellStyled>
    ))
  }
)

RoleDistributionDetailsViewMode.displayName = 'RoleDistributionDetailsViewMode'
