import * as React from 'react'
import {Text} from '@phoenix/all'
import {PopperComponent, TooltipContentComponent} from '../../../shared'

type TSize = 'large' | 'normal' | 'small' | 'micro'

interface ITooltipComponent {
  value: number | string
  tooltipValue: string
  testId?: string
  className?: string
  size?: TSize
}

interface ITextProps extends React.HTMLAttributes<HTMLElement> {
  size?: TSize
}

const TextComponent: React.FC<ITextProps> = React.memo(({size, children, ...rest}) =>
  size === 'large' ? (
    <Text.Large {...rest}>{children}</Text.Large>
  ) : size === 'small' ? (
    <Text.Small {...rest}>{children}</Text.Small>
  ) : size === 'micro' ? (
    <Text.Micro {...rest}>{children}</Text.Micro>
  ) : (
    <Text {...rest}>{children}</Text>
  )
)

TextComponent.displayName = 'TextComponent'

export const TooltipComponent: React.FunctionComponent<ITooltipComponent> = (props) => {
  const reference = (
    <span>
      <TextComponent
        className={props.className || ''}
        data-testid={props.testId || ''}
        size={props.size}
      >
        {props.value}
      </TextComponent>
    </span>
  )
  const tooltipContent = <div>{props.tooltipValue}</div>
  const showTooltip = props.tooltipValue.indexOf(props.value.toString()) !== -1

  return showTooltip ? (
    reference
  ) : (
    <PopperComponent
      reference={reference}
      content={<TooltipContentComponent text={tooltipContent} />}
      isInsidePortal={false}
      showOnOver={true}
      placement="top"
    />
  )
}

TooltipComponent.defaultProps = {
  size: 'normal',
}
