import {css} from 'emotion'
import React from 'react'
import styled from 'react-emotion'

import DownArrowExtraSmallIcon from 'phoenix-icons/dist/DownArrowExtraSmallIcon.js'
import UpArrowExtraSmallIcon from 'phoenix-icons/dist/UpArrowExtraSmallIcon.js'
import {primary, Text} from '@phoenix/all'

import {ArrowDirection} from '../../../../../shared/enums'
import {CellToolTipComponent} from '../CellTooltipComponent'
import {Translate} from '../../../../shared/Translate'

export interface IInfoComponentProps {
  labelMessageKey: string
  abbreviatedValue: string
  value: string
  arrowDirection?: ArrowDirection | null
  color?: string | null
  testId?: string
}

interface IInfoTextStyledProps {
  color: string
}

const infoTextWrapperClass = css`
  align-items: center;
  display: flex;
  margin-bottom: 2px;
`

const InfoTextStyled = styled('span')<IInfoTextStyledProps>`
  font-size: 14px;
  color: ${(props) => props.color};
  overflow: hidden;
`

const labelClass = css`
  color: ${primary.gray(400)};
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 0.2px;

  @media (min-width: 1350px) {
    line-height: 16px;
  }

  @media (min-width: 1440px) {
    font-size: 11px;
  }
`

const arrowClass = css`
  margin-left: 4px;
`

const containerClass = css`
  display: table-cell;
  vertical-align: middle;
  padding-right: 10px;

  &:last-child {
    padding-right: 0;
  }
`

export const InfoComponent: React.FunctionComponent<IInfoComponentProps> = React.memo((props) => {
  const arrowRenderer = () => {
    const direction = props.arrowDirection
    return direction === ArrowDirection.DESCENDING ? (
      <DownArrowExtraSmallIcon
        data-testid={props.testId ? `${props.testId}-info-icon-down` : 'info-icon-down'}
      />
    ) : direction === ArrowDirection.ASCENDING ? (
      <UpArrowExtraSmallIcon
        data-testid={props.testId ? `${props.testId}-info-icon-up` : 'info-icon-up'}
      />
    ) : null
  }

  return (
    <div className={containerClass} data-testid={props.testId}>
      <div className={infoTextWrapperClass}>
        <InfoTextStyled
          data-testid="info-text"
          color={props.color !== null ? props.color! : primary.gray(700)}
        >
          <CellToolTipComponent abbreviatedValue={props.abbreviatedValue} value={props.value} />
        </InfoTextStyled>
        <span className={arrowClass}>{arrowRenderer()}</span>
      </div>

      <Text.Small className={labelClass}>
        <Translate messageKey={props.labelMessageKey} />
      </Text.Small>
    </div>
  )
})

InfoComponent.displayName = 'InfoComponent'
InfoComponent.defaultProps = {
  color: primary.gray(700),
}
