import {css} from 'emotion'
import isEqual from 'lodash/isEqual'
import {Moment} from 'moment'
import * as React from 'react'
import {connect} from 'react-redux'
import UpCaratSmallIcon from 'phoenix-icons/dist/UpCaratSmallIcon.js'
import DownCaratSmallIcon from 'phoenix-icons/dist/DownCaratSmallIcon.js'
import {Text, primary, Input} from '@phoenix/all'

import {currentPlanStartDateSelector} from '../../../../../data/plan'
import {planDurationSelector} from '../../../../../data/plan'
import {currentScenarioBudgetSelector} from '../../../../../data/scenario'
import {financialViewAccessSelector} from '../../../../../data/settings'
import {IScenarioBudgets} from '../../../../../shared/models/scenario'
import {isValidIntegerNumber} from '../../../../../shared/utilities'
import {formatDateBasedOnLocale, optionsForMonth} from '../../../../../shared/utilities/dateHelpers'
import {MoneyFieldViewComponent} from '../../../../shared'
import {HeaderBudgetYearLabelComponent} from './HeaderBudgetYearLabelComponent'
import {Translate} from '../../../../shared/Translate'

interface IHeaderBudgetDetailSectionComponentProps {
  toggleDetailFunction: (isDetailOpen: boolean) => void
  handleYearBlur: (value, key) => void
  handleYearFocus: (event, key) => void
  isDetailOpen: boolean
  planDuration: number
  planStartDate: Moment
  budgets: IScenarioBudgets
  isViewMode: boolean
}

interface IHeaderBudgetDetailSectionComponentState {
  focusedInput: string | null
  propsBudget: null | IScenarioBudgets
}

interface IYear {
  label: string
  total: number | null
}

const topSectionClass = css`
  display: flex;
  justify-content: space-between;
  padding: 16px 0 8px 0;
  border-top: 1px solid ${primary.gray(300)};
`
const durationClass = css`
  color: ${primary.gray(500)};
  font-weight: 600;
  line-height: 16px;
`
const durationTitleClass = css`
  font-size: 12px;
`
const durationDatesClass = css`
  font-size: 14px;
`
const buttonClass = css`
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    color: ${primary.blue(400)};
    margin-right: 5px;
  }
`
const yearsClass = css`
  display: flex;
  padding-bottom: 13px;
  &div:first-child {
    padding: 0 3px 0 0;
  }
  &div:last-child {
    padding: 0 0 0 3px;
  }
`
const yearClass = css`
  padding: 0 3px;
  max-width: 192px;

  label {
    line-height: 12px;
    margin-bottom: 3px;
    position: relative;
  }
`

const focusedStyles = {
  boxShadow: `${primary.blue(100)} 0px 0px 0px 2px`,
  borderColor: primary.blue(100),
}

export class HeaderBudgetDetailSection extends React.Component<
  IHeaderBudgetDetailSectionComponentProps,
  IHeaderBudgetDetailSectionComponentState
> {
  static getDerivedStateFromProps(
    nextProps: IHeaderBudgetDetailSectionComponentProps,
    prevState: IHeaderBudgetDetailSectionComponentState
  ) {
    const prevBudget = prevState.propsBudget
    const nextBudget = nextProps.budgets

    if (!isEqual(prevBudget, nextBudget)) {
      const newState = {
        propsBudget: nextProps.budgets,
      }
      for (const [key, value] of nextBudget.distribution) {
        newState[key] = value.total
      }

      return newState
    }

    return null
  }

  constructor(props: IHeaderBudgetDetailSectionComponentProps) {
    super(props)

    this.state = {
      focusedInput: null,
      propsBudget: null,
    }
  }

  componentDidMount(): void {
    const {
      budgets: {distribution},
    } = this.props
    const distributionKeys = distribution.keys()

    this.setState({
      focusedInput: distribution.keys().next().value,
    })

    for (let i = 0; i < distribution.size; i++) {
      const key = distributionKeys.next().value
      this.setState({
        [key]: distribution.get(key)!.total,
      } as Pick<IHeaderBudgetDetailSectionComponentState, keyof IHeaderBudgetDetailSectionComponentState>)
    }
  }

  render() {
    const {
      isDetailOpen,
      planStartDate,
      planDuration,
      budgets: {distribution},
      isViewMode,
    } = this.props
    const width = `${100 / planDuration}%`
    const {start, end} = this.getDates(planStartDate, planDuration)

    return (
      <div>
        <div className={topSectionClass} data-testid="budget-top-section">
          <div className={durationClass}>
            <span className={durationTitleClass}>
              <Translate messageKey={'scenario.widget.budget.top.title'} />
            </span>
            <span className={durationDatesClass}>
              {' '}
              {start} - {end}
            </span>
          </div>
          <div
            role="button"
            tabIndex={0}
            data-testid="advanced-button"
            className={buttonClass}
            onClick={this.onWidgetClickHandler}
            onKeyPress={this.handleKeyPress}
          >
            <Text>
              <Translate messageKey={'scenario.widget.budget.top.advanced'} />
            </Text>
            {this.renderCaratIcon(isDetailOpen)}
          </div>
        </div>
        <div className={yearsClass} data-testid="budget-year">
          {this.getYears(planStartDate, planDuration, distribution).map((year) => {
            return (
              <div key={year.label} className={yearClass} style={{width}}>
                {isViewMode ? (
                  <MoneyFieldViewComponent
                    cost={this.state[year.label]}
                    label={year.label}
                    handleYearFocus={this.handleYearFocus}
                    isClickable={true}
                    isActive={this.state.focusedInput === year.label}
                  />
                ) : (
                  <Input
                    label={
                      <HeaderBudgetYearLabelComponent
                        currentDistribution={distribution.get(year.label)!}
                        label={year.label}
                      />
                    }
                    maxLength="14"
                    hideMaxLengthInfo={true}
                    autoComplete={'off'}
                    name={year.label}
                    value={this.getInputValue(year.label)}
                    style={
                      isDetailOpen && this.state.focusedInput === year.label ? focusedStyles : null
                    }
                    onChange={({target}) => {
                      const {value} = target
                      this.changeBudgetTotal(value, year.label)
                    }}
                    onBlur={({target}) => {
                      this.handleYearBlur(target.value, year.label)
                    }}
                    onKeyDown={this.handleKeyDown}
                    onFocus={(e) => this.handleYearFocus(e, year.label)}
                  />
                )}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  private onWidgetClickHandler = () => {
    this.props.toggleDetailFunction(!this.props.isDetailOpen)
  }

  private handleKeyPress = (e) => {
    const keyboardClickKeys = ['Enter', 'Space', ' ']
    if (keyboardClickKeys.includes(e.key)) {
      this.onWidgetClickHandler()
    }
  }

  private handleKeyDown = ({key, target}) => {
    if (key === 'Enter') {
      target.blur()
    }
  }

  private getInputValue = (label) => {
    return !isNaN(this.state[label]) && this.state[label] !== null ? this.state[label] : ''
  }

  private renderCaratIcon = (isDetailOpen) => {
    return isDetailOpen ? (
      <UpCaratSmallIcon data-testid="caratUp" color={primary.blue(400)} />
    ) : (
      <DownCaratSmallIcon data-testid="caratDown" color={primary.blue(400)} />
    )
  }

  private handleYearFocus = (e, key) => {
    this.props.handleYearFocus(e, key)

    this.setState({
      focusedInput: key,
    })
  }
  private updateBudgetTotal = (value: string, key: string) => {
    if (value === '' || isValidIntegerNumber(value)) {
      this.setState(({
        [key]: parseInt(value, 10),
      } as unknown) as Pick<IHeaderBudgetDetailSectionComponentState, keyof IHeaderBudgetDetailSectionComponentState>)
    }
  }

  private changeBudgetTotal = (value: string, key: string) => {
    this.updateBudgetTotal(value, key)
  }

  private handleYearBlur = (value: string, key: string) => {
    this.props.handleYearBlur(value, key)
    const finalValue = value === '' ? '0' : value
    this.updateBudgetTotal(finalValue, key)
  }

  private getDates = (planStartDate, planDuration) => {
    return {
      start: formatDateBasedOnLocale(planStartDate, optionsForMonth),
      end: formatDateBasedOnLocale(
        planStartDate.clone().add(planDuration, 'years'),
        optionsForMonth
      ),
    }
  }

  private getYears = (planStartDate, planDuration, distribution) => {
    const years: IYear[] = []
    let i = 0
    distribution.forEach((item) => {
      const total = item.total ? item.total : 0
      years.push({
        label: planStartDate
          .clone()
          .add(i++, 'years')
          .format('YYYY'),
        total,
      })
    })

    return years
  }
}

const mapStateToProps = (state) => ({
  planStartDate: currentPlanStartDateSelector(state),
  planDuration: planDurationSelector(state),
  budgets: currentScenarioBudgetSelector(state),
  isViewMode: financialViewAccessSelector(state),
})

export const HeaderBudgetDetailSectionComponent = connect(mapStateToProps)(
  HeaderBudgetDetailSection
)
