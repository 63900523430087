import {css} from 'emotion'
import {useSelector} from 'react-redux'
import ErrorSmallIcon from 'phoenix-icons/dist/ErrorSmallIcon.js'

import {alerts} from '@phoenix/all'
import * as React from 'react'
import {hoursForMeasurementsSelector} from '../../../../../data/plan/selectors/hoursForMeasurementsSelector'
import {PopperComponent} from '../../../../shared'
import {TooltipWarningContentComponent} from '../../../../shared/TooltipWarningContentComponent'
import {Translate} from '../../../../shared/Translate'

const warningIconClass = css`
  margin-left: auto;
`

export const RoleAvailableWarningTooltipComponent: React.FC = React.memo(() => {
  const useHoursForMeasurements = useSelector(hoursForMeasurementsSelector)

  const warningMessage = React.useMemo(
    () => ({
      messageKey: useHoursForMeasurements ? 'uneven.warning.hourly' : 'uneven.warning.fte',
    }),
    [useHoursForMeasurements]
  )

  return (
    <PopperComponent
      modifiers={{
        preventOverflow: {
          boundariesElement: 'window',
        },
      }}
      reference={
        <div className={warningIconClass}>
          <ErrorSmallIcon data-testid="role-error-icon" color={alerts.warn(500)} />
        </div>
      }
      placement="top"
      content={
        <TooltipWarningContentComponent>
          <Translate messageKey={warningMessage.messageKey} />
        </TooltipWarningContentComponent>
      }
      showOnOver={true}
    />
  )
})

RoleAvailableWarningTooltipComponent.displayName = 'RoleAvailableWarningTooltipComponent'
