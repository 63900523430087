import {wfetch} from '@wf-mfe/api'
import {toast} from '@phoenix/all'

type FetchDataOptionsTypes = {
  data?: unknown
  method?: string
  showToast?: boolean
  omitBaseUrl?: boolean
}

export const fetchData = <T = unknown>(
  url: string,
  options?: FetchDataOptionsTypes
): Promise<T> => {
  const {method, data, omitBaseUrl, showToast = true} = options || {}

  return wfetch((omitBaseUrl ? '' : '/esp') + url, {
    body: data && JSON.stringify(data),
    method,
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!(response instanceof Response)) {
        return {data: response}
      }
      return response.headers.get('content-length') !== '0' ? response.json() : undefined
    })
    .catch((err: Error) => {
      if (showToast) {
        toast.error(err.message)
      }

      throw {
        message: err.message,
      }
    })
}
