import {Moment} from 'moment'
import {IYearlyBudget} from '../models'
import {IDistribution} from '../models/initiative/IDistribution'
import {getStorageUtil} from '@workfront/storage'
import {SIDEBAR_SIZE_KEY} from '../constants/localStorage'
import {SIDEBAR_SIZE} from '../constants/Sizes'

export const isListItemsEqual = (list: Array<{amount: number | null}>): boolean => {
  return list.every((value) => value.amount === list[0].amount)
}

export const distributionToMap = (budgets, startDate: Moment): Map<string, IYearlyBudget> => {
  const map = budgets.map((budget, index) => {
    return [startDate.clone().add(index, 'year').year().toString(), budget]
  })

  return new Map(map)
}

export function mapToArray<T>(map: Map<string, T>): T[] {
  const array: T[] = []

  map.forEach((item) => {
    array.push(item)
  })

  return array
}

export function calculateDifference(num1: number | null, num2: number | null): number | null {
  return num1! - num2!
}

export const getDefaultDistribution = (startDate, endDate) => {
  const distribution: IDistribution = {}
  const startDateClone = startDate.clone()

  while (startDateClone.isSameOrBefore(endDate)) {
    const key = startDateClone.format('YYYY-MM')
    distribution[key] = 0
    startDateClone.add(1, 'month')
  }

  return distribution
}

export const getSidebarInitialSize = (): number => {
  return getStorageUtil().get(SIDEBAR_SIZE_KEY) || SIDEBAR_SIZE
}
