import * as React from 'react'
import {css, cx} from 'react-emotion'
import {getObjectLink} from '@quicksilver/objectmetadata'
import {primary} from '@phoenix/all'
import {A} from '@phoenix/all'
import {TObjCode} from 'workfront-objcodes'
import {CSSProperties} from 'react'
import {QuickFilterMatcher} from '../types/quickFilter'
import {TRenderer} from '@wftypes/list'

const nonWorkingLink = css`
  color: ${primary.white()} !important;
`
// list item names were given bold by default (font-weight: 600px), that's why linkClass is implemented
const linkCLass = css`
  font-weight: normal !important;
`

interface ObjectLinkWrapperProps {
  objID: string
  objCode: TObjCode
  style?: CSSProperties
  cellContent?: string
}

export const ObjectLink: typeof ObjectLinkRenderer = (props) => {
  const {objID, objCode, style, isSelected} = props
  const clickHandler = React.useCallback(
    (e) => {
      if (isSelected) {
        e.preventDefault()
      } else {
        e.stopPropagation()
      }
    },
    [isSelected]
  )

  const objectLinkDetails = getObjectLink({ID: objID, objCode})
  const anchorProps = {
    style,
    onClick: clickHandler,
    ...objectLinkDetails,
    target: '_blank',
  }
  const className = cx(anchorProps['className'], {
    [linkCLass]: true,
    [nonWorkingLink]: isSelected,
  })

  return (
    <A className={className} {...anchorProps} data-test-id="LinkSimple" key="link">
      {props.children}
    </A>
  )
}

export const ObjectLinkRenderer: TRenderer<ObjectLinkWrapperProps> = (props) => {
  const {objCode, id, highlight, filterValue, value} = props
  if (objCode && id) {
    return (
      <ObjectLink {...props} objCode={objCode} objID={id}>
        {highlight(filterValue, value)}
      </ObjectLink>
    )
  }
  return null
}

export const objectLinkMatcher: QuickFilterMatcher = ({value}, filterValue) =>
  typeof value === 'string' && value.toLowerCase().indexOf(filterValue.toLowerCase()) > -1
