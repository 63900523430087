import {primary, secondary, Text} from '@phoenix/all'
import {css, cx} from 'emotion'
import * as React from 'react'
import {Translate} from '../../../../../shared/Translate'

const centerStyles = `
  align-items: center;
  display: flex;
`

const labelCirclesClass = css`
  ${centerStyles};
  margin-left: auto;
`

const circleWrapperClass = css`
  ${centerStyles};

  & + & {
    margin-left: 16px;
  }
`

const circleClass = css`
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 7px;
`

const availableClass = css`
  background-color: ${primary.gray(500)};
`

const fullyUtilizedClass = css`
  background-color: ${secondary.green()};
`

const overutilizedClass = css`
  background-color: ${secondary.red()};
`

const underutilizedClass = css`
  background-color: ${primary.blue()};
`

const labelClass = css`
  color: ${primary.gray(400)};
`

export const CircleLabelsComponent: React.FC = React.memo(() => (
  <div className={labelCirclesClass}>
    <div className={circleWrapperClass}>
      <span className={cx(circleClass, availableClass)} data-testid={'gray-circle'} />

      <Text.Small className={labelClass}>
        <Translate messageKey="header.widget.role.available" />
      </Text.Small>
    </div>

    <div className={circleWrapperClass}>
      <span className={cx(circleClass, fullyUtilizedClass)} data-testid={'green-circle'} />

      <Text.Small className={labelClass}>
        <Translate messageKey="fully.allocated" />
      </Text.Small>
    </div>

    <div className={circleWrapperClass}>
      <span className={cx(circleClass, overutilizedClass)} data-testid={'red-circle'} />

      <Text.Small className={labelClass}>
        <Translate messageKey="timeline.overallocated" />
      </Text.Small>
    </div>

    <div className={circleWrapperClass}>
      <span className={cx(circleClass, underutilizedClass)} data-testid={'blue-circle'} />

      <Text.Small className={labelClass}>
        <Translate messageKey="underallocated" />
      </Text.Small>
    </div>
  </div>
))

CircleLabelsComponent.displayName = 'CircleLabelsComponent'
