import {css} from 'emotion'
import * as React from 'react'

const slidingPaneBodyClass = css`
  flex-grow: 1;
  overflow: hidden;
  height: 0;
`

export const SlidingPaneBodyComponent: React.FunctionComponent = React.memo((props) => {
  return (
    <div className={slidingPaneBodyClass} data-testid="sliding-pane-body">
      {props.children}
    </div>
  )
})

SlidingPaneBodyComponent.displayName = 'SlidingPaneBodyComponent'
