import {Api, ResponseHandler} from 'workfront-api'
import {getSessionID, getXSRFToken, readCookie} from 'workfront-cookie'

interface TGetApi {
  (): Api
  instance?: Api
}

export const getApi: TGetApi = function () {
  if (!getApi.instance) {
    getApi.instance = new Api({
      url: document.location.origin,
      version: 'internal',
    })
  }

  const xsrfToken = getXSRFToken()
  if (xsrfToken) {
    getApi.instance.setXSRFToken(xsrfToken)
  } else {
    getApi.instance.setSessionID(getSessionID())
  }
  return getApi.instance
}

export function getStandardHeaders(useJsonContentType: boolean): Headers {
  const headers = new Headers()
  headers.append(
    'Content-type',
    useJsonContentType ? 'application/json' : 'application/x-www-form-urlencoded'
  )
  headers.append('X-Requested-With', 'XMLHttpRequest')

  const xsrfToken = getXSRFToken()
  if (xsrfToken) {
    headers.append('X-XSRF-TOKEN', xsrfToken)
  } else {
    headers.append('sessionID', getSessionID()!)
  }

  return headers
}

export function makeControllerCall<T = unknown>(
  endPoint: string,
  method: keyof typeof Api.Methods,
  data?: Record<string, unknown>,
  useJsonContentType = false
): Promise<T> {
  const headers = getStandardHeaders(useJsonContentType)

  const options: RequestInit = {
    method: method || 'GET',
    credentials: 'same-origin',
    headers,
  }

  if (data) {
    if (useJsonContentType) {
      options.body = JSON.stringify(data)
    } else {
      const bodyParts = Object.keys(data).map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(String(data[key]))}`
      )
      if (options.method === 'GET') {
        endPoint += '?' + bodyParts.join('&')
      } else {
        options.body = bodyParts.join('&')
      }
    }
  }

  return fetch('/internal' + (endPoint.charAt(0) === '/' ? '' : '/') + endPoint, options).then(
    ResponseHandler.success
  )
}

export const filterServiceRequest = (endpoint, method, data?) => {
  const BASE_URL = 'filter-service'

  return fetch(`/${BASE_URL}/${endpoint}`, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      sessionID: readCookie('JSESSIONID')!,
    },
    ...{
      ...(data ? {body: JSON.stringify(data)} : {}),
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.errors) {
        throw new Error(JSON.stringify(res.errors))
      }
      return res
    })
    .catch(console.error)
}
