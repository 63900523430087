import {css} from 'emotion'
import * as React from 'react'
import {connect} from 'react-redux'

import {currentInitiativeSelector} from '../../../../data/initiative'
import {IInitiativeDates, IRefObject} from '../../../../shared/models/initiative'
import {DateRangeInfoComponent} from './DateRangeInfoComponent'

interface IDurationComponent {
  dates: IInitiativeDates
  refObject: IRefObject | null
}

const refObjectDatesClass = css`
  margin-top: 16px;
`

export const Duration: React.FunctionComponent<IDurationComponent> = (props) => {
  const {dates, refObject} = props

  return (
    <>
      <DateRangeInfoComponent
        labelMessageKey={'sp.initiative'}
        startDate={dates.startDate}
        endDate={dates.endDate}
        testId={'initiative-dates'}
      />
      {refObject !== null && refObject.plannedStartDate && refObject.plannedCompletionDate && (
        <div className={refObjectDatesClass}>
          <DateRangeInfoComponent
            labelMessageKey={'project'}
            startDate={refObject.plannedStartDate}
            endDate={refObject.plannedCompletionDate}
            testId={'project-dates'}
            applyTimezone={true}
          />
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  dates: currentInitiativeSelector(state)!.dates,
  refObject: currentInitiativeSelector(state)!.refObject,
})

export const DurationComponent = connect(mapStateToProps)(Duration)
