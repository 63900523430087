import {fetchData} from './api/ApiService'

export class SettingService {
  getSettings() {
    return fetchData('/settings/defaults')
  }

  getPermissions(objId) {
    const suffix = objId ? `?objId=${objId}` : ''
    return fetchData<Record<string, unknown>[]>(`/accessPermissions${suffix}`)
  }
}
