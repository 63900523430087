import {css} from 'emotion'
import * as React from 'react'
import {PopperComponent, TooltipContentComponent} from '../../../shared'

interface IFormattedCurrencyWithFontSize {
  abbreviatedValue: string
  value: string
}
const ellipsis = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  width: 100%;
`
export const CellToolTipComponent: React.FunctionComponent<IFormattedCurrencyWithFontSize> = (
  props
) => {
  const reference = (
    <span className={ellipsis}>
      <React.Fragment>{props.abbreviatedValue}</React.Fragment>
    </span>
  )
  const tooltipContent = <React.Fragment>{props.value}</React.Fragment>

  const showTooltip = props.abbreviatedValue !== props.value

  return showTooltip ? (
    <PopperComponent
      reference={reference}
      content={<TooltipContentComponent text={tooltipContent} />}
      isInsidePortal={true}
      showOnOver={true}
      placement="top"
    />
  ) : (
    reference
  )
}
