import * as React from 'react'
import {css} from 'react-emotion'
import {primary} from '@phoenix/all'
import {PluginBase, bindPluginClass} from '@workfront/list-plugin-utils'
import {IDataTableAPI} from '@wftypes/list'
import {IToolbarApi} from '@wf-mfe/lists'
import {
  TOOLBAR_PLUGIN_NAME,
  PLUGIN_NAME,
  DATA_FETCHER_PLUGIN_NAME,
} from '../../../shared/toolbar/constants'
import {AddNewPlanComponent} from './widgets/AddNewPlanComponent'
import {IPlan} from '../../../shared/types'
import {SharePlanComponent} from './widgets/SharePlanComponent'
import {DataFetcherAPI} from '../dataFetcher'
import {DeletePlanComponent} from './widgets/DeletePlanComponent'
import {EmptyListWidget} from '../../../shared/renderers/EmptyListComponent'
import QuickFilterWidget from '../../../shared/toolbar/widgets/QuickFilterWidget'
import {IShareAccessorObject} from '@workfront/sp-sharing-dialog'
import {hasEditAccess} from '../../../shared/utils/hasEditAccess'

const NEW_PLAN_WIDGET_ID = 'new-plan'

const ADD_BUTTON_POSITION = 10
const SHARE_BUTTON_POSITION = 20
const DELETE_BUTTON_POSITION = 30

interface IPlanListToolbarOptions {
  currentUserID: string
  hasOnlyViewAccess: boolean
  createNewPlanFunction: () => void
  onShareFunction: (plans: IPlan[]) => void
  onDeleteFunction: (plans: IPlan[]) => void
  newPlanMessage: string
}

class ToolbarDefinitionsPlugin extends PluginBase<IToolbarApi, IPlanListToolbarOptions> {
  static PLUGIN_NAME = PLUGIN_NAME
  static PLUGIN_DEPS = [TOOLBAR_PLUGIN_NAME, DATA_FETCHER_PLUGIN_NAME]

  registerNewPlanAction(listApi: IDataTableAPI, options: IPlanListToolbarOptions) {
    const toolbarApi = listApi.getPluginAPI(TOOLBAR_PLUGIN_NAME) as IToolbarApi

    const newPlanMessage = options.newPlanMessage

    this.addShutdownRoutine(
      toolbarApi.registerWidget(NEW_PLAN_WIDGET_ID, () => (
        <AddNewPlanComponent
          newPlanMessage={newPlanMessage}
          createNewPlanFunction={options.createNewPlanFunction}
        />
      )),
      toolbarApi.registerWidget('quickFilter', QuickFilterWidget)
    )

    this.addShutdownRoutine(
      toolbarApi.insertComponent(toolbarApi.ToolbarLocations.primary, {
        position: ADD_BUTTON_POSITION,
        widgetType: 'new-plan',
        id: 'new-plan',
      }),
      toolbarApi.insertComponent(toolbarApi.ToolbarLocations.secondary, {
        id: 'quickFilter',
        position: 0,
        widgetType: 'quickFilter',
        widgetOptions: {
          listApi,
        },
      })
    )
  }

  getSelectedPlans(listApi: IDataTableAPI) {
    const dataFetcherApi = listApi.getPluginAPI(DATA_FETCHER_PLUGIN_NAME) as DataFetcherAPI
    const selectedPlanIDs = listApi.selection
      .getSelectionRanges()
      .reduce((acc, item) => acc.concat(item), [])
    return dataFetcherApi.getPlans(selectedPlanIDs)
  }

  registerShareAction(listApi: IDataTableAPI, options: IPlanListToolbarOptions) {
    const toolbarApi = listApi.getPluginAPI(TOOLBAR_PLUGIN_NAME) as IToolbarApi

    this.addShutdownRoutine(
      toolbarApi.registerRequirement(
        'selectionOwnerIsCurrentUser',
        () => {
          const selectedPlans = this.getSelectedPlans(listApi)
          const selectedOwnerIDs = selectedPlans.map((plan) => plan.owner.ID)
          const isCurrentUserTheOwner = selectedOwnerIDs.every(
            (ownerID) => ownerID === options.currentUserID
          )
          const result = selectedPlans.filter((plan) => {
            return (
              plan.accessorObjects &&
              hasEditAccess(
                options.currentUserID,
                plan.owner.ID,
                plan.accessorObjects as IShareAccessorObject[]
              )
            )
          })
          return (
            selectedPlans.length > 0 &&
            (isCurrentUserTheOwner || result.length == selectedPlans.length)
          )
        },
        true
      ),
      toolbarApi.registerCommand({
        id: 'share',
        requirements: {
          selectionOwnerIsCurrentUser: true,
        },
        executor: options.onShareFunction,
      }),
      toolbarApi.registerWidget('share-plans', () => (
        <SharePlanComponent
          onShareFunction={() => {
            const selectedPlans = this.getSelectedPlans(listApi)

            options.onShareFunction(selectedPlans)
          }}
        />
      )),
      toolbarApi.insertComponent(toolbarApi.ToolbarLocations.primary, {
        position: SHARE_BUTTON_POSITION,
        widgetType: 'share-plans',
        id: 'share-plans',
        primaryCommandId: 'share',
      })
    )
  }

  registerDeleteAction(listApi: IDataTableAPI, options: IPlanListToolbarOptions) {
    const toolbarApi = listApi.getPluginAPI(TOOLBAR_PLUGIN_NAME) as IToolbarApi

    this.addShutdownRoutine(
      toolbarApi.registerRequirement(
        'areSelectionsOwnedByCurrentUser',
        () => {
          const selectedPlans = this.getSelectedPlans(listApi)

          const selectedOwnerIDs = selectedPlans.map((plan) => plan.owner.ID)

          const isCurrentUserTheOwner = selectedOwnerIDs.every(
            (ownerID) => ownerID === options.currentUserID
          )
          return selectedPlans.length > 0 && isCurrentUserTheOwner
        },
        true
      ),
      toolbarApi.registerCommand({
        id: 'delete',
        requirements: {
          areSelectionsOwnedByCurrentUser: true,
        },
        executor: options.onDeleteFunction,
      }),
      toolbarApi.registerWidget('delete-plans', () => (
        <DeletePlanComponent
          deletePlansFunction={() => {
            const selectedPlans = this.getSelectedPlans(listApi)

            options.onDeleteFunction(selectedPlans)
          }}
        />
      )),
      toolbarApi.insertComponent(toolbarApi.ToolbarLocations.primary, {
        position: DELETE_BUTTON_POSITION,
        widgetType: 'delete-plans',
        id: 'delete-plans',
        primaryCommandId: 'delete',
      })
    )
  }

  registerEmptyListWidget(listApi: IDataTableAPI, options: IPlanListToolbarOptions) {
    const toolbarApi = listApi.getPluginAPI(TOOLBAR_PLUGIN_NAME) as IToolbarApi

    this.addShutdownRoutine(
      toolbarApi.registerWidget('emptyList', EmptyListWidget),
      toolbarApi.insertComponent(toolbarApi.ToolbarLocations.primary, {
        id: 'emptyList',
        position: 0,
        widgetType: 'emptyList',
        widgetOptions: {
          listApi,
          mainMsgKey: 'sp.plan.list.noresults',
          hintMsgKey: options.hasOnlyViewAccess
            ? 'sp.plan.list.noresults.viewaccess.hint'
            : 'sp.plan.list.noresults.hint',
          wrapperStyles: css`
            position: relative;
            top: -40px;
            height: 80%;
            background: ${primary.white()};
          `,
        },
      })
    )
  }

  ready(listApi: IDataTableAPI, options: IPlanListToolbarOptions) {
    if (!options.hasOnlyViewAccess) {
      this.registerNewPlanAction(listApi, options)

      this.registerShareAction(listApi, options)

      this.registerDeleteAction(listApi, options)
    }

    this.registerEmptyListWidget(listApi, options)

    // Prevent row deselection when clicking outside the list
    this.addShutdownRoutine(
      listApi.interceptors.registerInterceptor(
        PLUGIN_NAME,
        listApi.interceptors.events.BEFORE_OUTSIDE_CLICK,
        () => true
      )
    )
  }
}

export default bindPluginClass(
  () => new ToolbarDefinitionsPlugin(),
  ToolbarDefinitionsPlugin.PLUGIN_NAME,
  ToolbarDefinitionsPlugin.PLUGIN_DEPS || []
)
