import {createSelector} from 'reselect'
import {MONTHS_COUNT_IN_YEAR} from '../../../shared/helpers'
import {planDurationSelector} from './planDurationSelector'
import {timelineCellWidthSelector} from './timelineCellWidthSelector'

export const timelineWidthSelector = createSelector(
  [planDurationSelector, timelineCellWidthSelector],
  (planDuration: number, timelineCellWidth: number) => {
    return planDuration * MONTHS_COUNT_IN_YEAR * timelineCellWidth
  }
)
