import * as React from 'react'
import {ToolbarButton} from '@workfront/panel-components'
import FilterIcon from 'phoenix-icons/dist/FilterIcon.js'
import {ToolbarButtonApi} from '@workfront/panel-components/typings/ToolbarButton/ToolbarButton'
import {IToolbarWidgetProps} from '@wf-mfe/lists'

interface ToolbarFilterButtonProps extends IToolbarWidgetProps {
  typeMessageKey: string
  typeFallback: string
  getApi: (api: ToolbarButtonApi) => void
  dataTestId: string
}

export default function ToolbarFilterButton(props: ToolbarFilterButtonProps): JSX.Element {
  return <ToolbarButton icon={FilterIcon} {...props} />
}
