import DeleteIcon from 'phoenix-icons/dist/DeleteIcon.js'
import * as React from 'react'
import {Localization, LocalizationProvider} from '@wf-mfe/localize-react'
import ToolbarButton from '../../../../shared/toolbar/components/ToolbarButton'
import {redrockClientFactory} from '../../../../../shared/L10n'

export interface IDeletePlanComponent {
  deletePlansFunction: () => void
}

export const DeletePlanComponent: React.FunctionComponent<IDeletePlanComponent> = (props) => {
  return (
    <LocalizationProvider clientFactory={redrockClientFactory}>
      <Localization messageKey="action.delete" fallback="Delete">
        {(getDeleteMessage) => (
          <ToolbarButton
            dataTestId="delete-plans"
            icon={DeleteIcon}
            tooltipLabel={getDeleteMessage()}
            onClick={props.deletePlansFunction}
          />
        )}
      </Localization>
    </LocalizationProvider>
  )
}
