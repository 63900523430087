import * as React from 'react'
import {TRenderer} from '@wftypes/list'
import ProjectSmallHeroIcon from 'phoenix-icons/dist/ProjectSmallHeroIcon.js'
import {Tooltip} from '@phoenix/all'
import {A} from '@phoenix/all'
import {getObjectLink} from '@quicksilver/objectmetadata'
import {css} from 'emotion'
import {Moment} from 'moment'
import {Project} from 'workfront-objcodes'
import {QuickFilterMatcher} from '../../../shared/types/quickFilter'
import {Localization, LocalizationProvider} from '@wf-mfe/localize-react'
import {espClientFactory} from '../../../../shared/L10n'

interface InitiativeNameProps {
  name: string
  refObject: {
    id: string
    plannedStartDate: Moment
    plannedCompletionDate: Moment
  } | null
}

const nameWrapperClass = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const textOverflowClass = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 5px;
`

const tooltipClass = css`
  max-width: calc(100% - 20px);
`

const linkClass = css`
  position: relative;
  display: block;
`

const isPublishedClass = css`
  width: 8px;
  height: 8px;
  background-color: #50b83c;
  border-radius: 50%;
  position: absolute !important;
  top: -2px;
  right: -3px;
  border: 1px solid #fff;
`
type TWrapper<R> = () => R

// eslint-disable-next-line react/display-name
export const InitiativeName: TWrapper<TRenderer<InitiativeNameProps>> = () => (props) => {
  const {name = '', refObject, isPublished, highlight, filterValue} = props
  const clickHandler = (e) => {
    e.stopPropagation()
  }
  const tooltipContent = isPublished ? (
    <Localization
      messageKey="initiative.publish.project.icon"
      fallback="Published: Open associated %low_project%"
    />
  ) : (
    <Localization
      messageKey="initiative.import.project.icon"
      fallback="Open associated %low_project%"
    />
  )

  return (
    <LocalizationProvider clientFactory={espClientFactory}>
      <div className={nameWrapperClass}>
        <Tooltip position={'auto'} className={tooltipClass} content={name}>
          <div className={textOverflowClass}>
            <span>{highlight(filterValue, name)}</span>
          </div>
        </Tooltip>
        {refObject && (
          <Tooltip position={'top'} className={tooltipClass} content={tooltipContent}>
            <A
              href={`${getObjectLink({objCode: Project, ID: refObject.id}).to}/overview`}
              onClick={clickHandler}
              target="_blank"
              className={linkClass}
            >
              <ProjectSmallHeroIcon />
              {isPublished && <div className={isPublishedClass} />}
            </A>
          </Tooltip>
        )}
      </div>
    </LocalizationProvider>
  )
}

export const initiativeNameMatcher: QuickFilterMatcher = ({name}, filterValue) =>
  typeof name === 'string' && name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1
