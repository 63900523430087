import {css} from 'emotion'
import * as React from 'react'
import CloseIcon from 'phoenix-icons/dist/CloseIcon.js'
import {primary, Text} from '@phoenix/all'
import {resetButtonDefaultStyles} from '../../../shared'
import styled from 'react-emotion'

export interface IHeaderExpandedWidgetComponentProps {
  text: string | JSX.Element
  collapseWidget: () => void
  paddingBottom: number
}

const ContainerStyled = styled('div')<{paddingBottom: number}>`
  min-height: 248px;
  padding: 16px 16px ${(props) => props.paddingBottom}px 16px;
  border-radius: 3px;
  background-color: ${primary.gray(0)};
  box-shadow: 0 3px 6px 0 rgba(0, 35, 64, 0.2);
  display: flex;
  flex-direction: column;
  position: relative;
`

const labelClass = css`
  flex: 1;
  color: ${primary.gray(500)};
  font-weight: normal;
  line-height: 16px;
`

const closeButtonClass = css`
  ${resetButtonDefaultStyles};
  margin-left: 8px;
`

const contentClass = css`
  flex: 1 0 0;
  flex-direction: column;
`

const containerInnerClass = css`
  display: flex;
  align-items: start;
  margin-bottom: 4px;
`

export class HeaderExpandedWidgetComponent extends React.Component<
  IHeaderExpandedWidgetComponentProps
> {
  render() {
    const {text, children, paddingBottom} = this.props
    return (
      <ContainerStyled paddingBottom={paddingBottom}>
        <div className={containerInnerClass}>
          <Text.Small className={labelClass}>{text}</Text.Small>
          <button
            className={closeButtonClass}
            onClick={this.props.collapseWidget}
            data-testid="collapse-widget-button"
          >
            <CloseIcon />
          </button>
        </div>

        <div className={contentClass}>{children}</div>
      </ContainerStyled>
    )
  }
}
