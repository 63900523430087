import {bindPluginClass, PluginBase} from '@workfront/list-plugin-utils'
import {IDataTableAPI} from '@wftypes/list'
import {MOD, Operators} from 'workfront-api-constants'

import {filterServiceRequest, getApi} from '../services/apiFactory'
import {Project} from 'workfront-objcodes'
import {apiDateToDate} from '@workfront/fns'
import {trackSP} from '../../shared/utils/pendoTracker'
import {columns, PAGE_SIZES} from '../table-data'
import {
  FilterChangeParams,
  FiltersChangeParams,
  FiltersDetails,
} from '../../shared/toolbar/filters/types'
import {formatDateBasedOnLocale} from '../../shared/utils/dateHelpers'
import {convert, getAppliedFiltersCount} from '@workfront/filter-redrock-integration'

export interface DataFetcherAPI {
  filterListData: (
    filters: FilterChangeParams | any,
    filtersDetails?: {
      selectedFilters: FiltersChangeParams[]
      filterExpression: FiltersChangeParams
    }
  ) => void
  getDefaultFilterPromise: () => Promise<string>
  getDefaultFiltersDetailsPromise: () => Promise<any>
}

export interface DataFetcherOptions {
  excludeProjectIDs: string[]
  startDate?: Date
  endDate?: Date
}

class DataFetcherPlugin extends PluginBase<DataFetcherAPI, DataFetcherOptions> {
  static PLUGIN_NAME = 'DataFetcherPlugin'
  static PLUGIN_DEPS = []

  private projectFields = [
    'plannedStartDate',
    'plannedCompletionDate',
    'percentComplete',
    'statusLabel',
    'owner',
    'program',
    'portfolio',
    'portfolioPriority',
  ]
  private queryParams: {[key: string]: unknown} = {}
  private listOptions: Record<string, unknown> = {
    sort1: 'plannedStartDate|asc',
  }
  private queryFilters: Record<string, unknown> = {}
  private filtersQueryMap = {}

  private defaultFilterPromise
  private defaultFiltersDetailsPromise

  init(listApi: IDataTableAPI, options: DataFetcherOptions) {
    this.defaultFiltersDetailsPromise = new Promise<any>((resolve) => {
      filterServiceRequest(`appliedFilter/${Project}`, 'GET').then(
        ({data: {filterIDs, filterExpression}}) => {
          const count = getAppliedFiltersCount(filterIDs || [], filterExpression)
          if (count > 0) {
            const appliedFilters = filterIDs.map((ID) => ({ID})) || []
            appliedFilters.push({filterExpression})

            convert(appliedFilters, Project).then((data) => {
              resolve({
                filtersQueryMap: data,
                selectedFilterIDs: filterIDs,
                count,
              })
            })
          } else {
            resolve({
              filtersQueryMap: {},
              selectedFilterIDs: filterIDs,
              count,
            })
          }
        }
      )
    }).then((filtersDetails: FiltersDetails) => {
      this.filtersQueryMap = filtersDetails.filtersQueryMap
      return filtersDetails
    })

    listApi.interceptors.enableInterceptor(
      listApi.interceptors.getDefaultInterceptorName(),
      listApi.interceptors.events.LOAD_PAGE_ITEMS,
      false
    )
    this.addShutdownRoutine(
      () => {
        listApi.interceptors.enableInterceptor(
          listApi.interceptors.getDefaultInterceptorName(),
          listApi.interceptors.events.LOAD_PAGE_ITEMS,
          true
        )
      },
      listApi.interceptors.registerInterceptor(
        DataFetcherPlugin.PLUGIN_NAME,
        listApi.interceptors.events.LOAD_PAGE_ITEMS,
        ({data: {sortInfo, pageNumber, pageSize}}) => {
          const [prevSortInfo] = listApi.sorting.getSortInfo()
          const [nextSortInfo] = sortInfo
          if (JSON.stringify(nextSortInfo) !== JSON.stringify(prevSortInfo)) {
            trackSP('import dialog sort change', nextSortInfo)
          }
          sortInfo.forEach((sortInfoItem, sortIndex) => {
            this.listOptions[
              `sort${sortIndex + 1}`
            ] = `${sortInfoItem.key}|${sortInfoItem.direction}`
          })
          if (pageSize) {
            this.listOptions.pageSize = pageSize
            this.listOptions.pageNumber = pageNumber
          }
          this.appendAndTransformFilterParam(options)
          const {recordLimit} = listApi.metadata.getProperty('pagination')
          return this.loadData({
            pageNumber,
            pageSize: pageSize ?? recordLimit,
          })
        }
      )
    )
  }

  ready(listApi: IDataTableAPI) {
    this.addShutdownRoutine(
      listApi.interceptors.registerInterceptor(
        DataFetcherPlugin.PLUGIN_NAME,
        listApi.interceptors.events.AFTER_LOAD_PAGE_ITEMS,
        ({data: {sortInfo}}) => {
          const selectedIDs = listApi.selection
            .getSelectionRanges()
            .reduce((acc, item) => acc.concat(item), [])

          if (JSON.stringify(sortInfo) !== JSON.stringify(listApi.sorting.getSortInfo())) {
            const sortInfoMap = sortInfo.reduce((acc, sortInfo) => {
              acc[sortInfo.key] = sortInfo
              return acc
            }, {})

            columns().forEach((column) => {
              delete column.sortNumber
              delete column.sortDirection

              if (column.sortQuery && column.sortQuery in sortInfoMap) {
                column.sortNumber = sortInfoMap[column.sortQuery].number
                column.sortDirection = sortInfoMap[column.sortQuery].direction
              }
            })
          }
          window.requestAnimationFrame(() => {
            const rows = listApi.rows.getVisibleRowIndices(selectedIDs)
            const visibleRowIDs = Object.keys(rows)
            listApi.selection.setSelection(visibleRowIDs)
          })
        }
      )
    )
    this.defaultFiltersDetailsPromise.then(() => {
      listApi.pagination.setCurrentPage(1)
    })
  }

  getApi(listApi: IDataTableAPI): DataFetcherAPI {
    return {
      filterListData: (filter, filtersDetails?) => {
        this.filtersQueryMap = filter
        const filtersIds: string[] = []

        filtersDetails?.selectedFilters?.forEach((item) => {
          filtersIds.push(item.ID)
        })

        const idsString = filtersIds.join(', ')

        trackSP(
          `import dialog filter changed to '${idsString}  ${
            filtersDetails?.filterExpression
              ? ', ' + JSON.stringify(filtersDetails.filterExpression)
              : ''
          }'`,
          {
            filterID: idsString,
          }
        )
        listApi.pagination.setCurrentPage(1)
      },
      getDefaultFilterPromise: () => this.defaultFilterPromise,
      getDefaultFiltersDetailsPromise: () => this.defaultFiltersDetailsPromise,
    }
  }

  loadData = ({pageNumber, pageSize}) => {
    let queryFilters = this.queryFilters

    queryFilters = {
      ...queryFilters,
      ...this.filtersQueryMap,
    }

    this.queryParams.listOptions = JSON.stringify(this.listOptions)
    this.queryParams.filters = JSON.stringify(queryFilters)
    return getApi()
      .search(Project, this.queryParams, this.projectFields, true)
      .then(({items: searchData, metaData: {paginationCount}}) => {
        return {
          rows: this.convertApiData(searchData),
          metadata: {
            pagination: {
              count: paginationCount,
              pageNumber,
              pageSize,
              pageSizes: PAGE_SIZES.filter((size) => {
                return size <= paginationCount
              }).map(String),
            },
            rawValueTypes: {},
          },
          groups: {},
          collapsedGroups: [],
          collapsedRows: [],
        }
      })
  }

  private appendAndTransformFilterParam = (options: DataFetcherOptions) => {
    this.queryFilters = {}
    if (Array.isArray(options.excludeProjectIDs) && options.excludeProjectIDs.length > 0) {
      this.queryFilters['AND:exclude:ID' + MOD] = Operators.NOTIN
      this.queryFilters['AND:exclude:ID'] = options.excludeProjectIDs
    }
    if (options.startDate && options.endDate) {
      this.queryFilters['AND:date:plannedStartDate' + MOD] = Operators.LESSTHANEQUAL
      this.queryFilters['AND:date:plannedStartDate'] = options.endDate.toISOString()
      this.queryFilters['AND:date:plannedCompletionDate' + MOD] = Operators.GREATERTHANEQUAL
      this.queryFilters['AND:date:plannedCompletionDate'] = options.startDate.toISOString()
    }
  }

  private convertApiData = (projectData) => {
    return projectData.reduce((acc, project, index) => {
      return {
        ...acc,
        [project.ID]: {
          objCode: project.objCode,
          editable: false,
          columns: this.getColumnData(project),
          display: project.name,
          id: project.ID,
          order: index,
          rawValues: {},
        },
      }
    }, {})
  }
  private getColumnData = (project) => {
    const columnData = [
      {isEditable: false, value: ''},
      {
        isEditable: false,
        value: '',
        viewData: {
          'component.object.link|name': {
            objCode: project.objCode,
            id: project.ID,
            value: project.name,
          },
        },
      },
      {
        isEditable: false,
        value: project.portfolioPriority ? project.portfolioPriority.toString() : '',
      },
      {
        isEditable: false,
        value: '',
        viewData: {
          'component.object.link|portfolio:name': {
            ...(project.portfolio && {
              objCode: project.portfolio.objCode,
              id: project.portfolio.ID,
              value: project.portfolio.name,
            }),
          },
        },
      },
      {
        isEditable: false,
        value: '',
        viewData: {
          'component.object.link|program:name': {
            ...(project.program && {
              objCode: project.program.objCode,
              id: project.program.ID,
              value: project.program.name,
            }),
          },
        },
      },
      {
        isEditable: false,
        value: '',
        viewData: {
          'component.object.link|owner:name': {
            ...(project.owner && {
              objCode: project.owner.objCode,
              id: project.owner.ID,
              value: project.owner.name,
            }),
          },
        },
      },
      {
        isEditable: false,
        value: project.statusLabel,
      },
      {
        isEditable: false,
        value: '',
        viewData: {
          'component.percentcompletelistview|percentComplete': {
            value: project.percentComplete,
            percentComplete: project.percentComplete,
          },
        },
      },
      {
        isEditable: false,
        value: formatDateBasedOnLocale(apiDateToDate(project.plannedStartDate)),
      },
      {
        isEditable: false,
        value: formatDateBasedOnLocale(apiDateToDate(project.plannedCompletionDate)),
      },
    ]

    return columnData
  }
}

export default bindPluginClass(
  () => new DataFetcherPlugin(),
  DataFetcherPlugin.PLUGIN_NAME,
  DataFetcherPlugin.PLUGIN_DEPS
)
