import {ISettingsState} from '../../settings/ISettingsState'
import {IPlanState} from '../IPlanState'

export const currencySelector = (state: {plan: IPlanState; settings: ISettingsState}): string => {
  const planSettings = state.plan.currentPlan!.settings
  return (planSettings && planSettings.currency) || state.settings.currency
}

// TODO: change the code in a way that another selector is used
// TODO: Do we need to get currency from settings here
