import * as React from 'react'
import {CarouselContext} from '../../../../../shared/context/CarouselContext'
import {IDistribution} from '../../../../../shared/models/initiative/IDistribution'

interface ICarouselProviderComponentProps {
  distribution: IDistribution
  conflictIndex: number
}

const ITEMS_PER_STEP = 3

export const CarouselProviderComponent: React.FC<ICarouselProviderComponentProps> = React.memo(
  ({children, distribution, conflictIndex}) => {
    const [carouselStep, setCarouselStep] = React.useState<{step: number; index: number}>({
      step: 0,
      index: 0,
    })
    const [isLeftArrowVisible, setIsLeftArrowVisible] = React.useState(false)
    const [isRightArrowVisible, setIsRightArrowVisible] = React.useState(false)

    const months = [...Object.keys(distribution).sort()]

    const step = conflictIndex > ITEMS_PER_STEP ? conflictIndex - ITEMS_PER_STEP : 0

    React.useEffect(() => {
      setCarouselStep({
        step: step,
        index: step,
      })
      setIsLeftArrowVisible(!!step)
      setIsRightArrowVisible(
        step ? months.length > step + ITEMS_PER_STEP : months.length > ITEMS_PER_STEP
      )
    }, [months.length])

    const onLeftArrowClickHandler = React.useCallback(() => {
      setIsRightArrowVisible(true)
      const splitStart = carouselStep.index > ITEMS_PER_STEP ? carouselStep.index : 0
      months.splice(splitStart, months.length)

      if (months.length >= ITEMS_PER_STEP) {
        setCarouselStep((prevState) => ({
          step: prevState.step - ITEMS_PER_STEP,
          index: prevState.index - ITEMS_PER_STEP,
        }))
      } else {
        setCarouselStep({
          step: months.length ? -months.length : 0,
          index: 0,
        })
        setIsLeftArrowVisible(false)
      }
    }, [carouselStep])

    const onRightArrowClickHandler = React.useCallback(() => {
      setIsLeftArrowVisible(true)
      months.splice(0, carouselStep.index + ITEMS_PER_STEP)

      if (months.length > ITEMS_PER_STEP) {
        setCarouselStep((prevState) => ({
          step: prevState.step + ITEMS_PER_STEP,
          index: prevState.index + ITEMS_PER_STEP,
        }))
      } else {
        setCarouselStep((prevState) => ({
          step: prevState.step + months.length,
          index: prevState.index + months.length,
        }))
        setIsRightArrowVisible(false)
      }
    }, [carouselStep])

    return (
      <CarouselContext.Provider
        value={{
          carouselStep,
          itemsPerStep: ITEMS_PER_STEP,
          onLeftArrowClickHandler,
          onRightArrowClickHandler,
          isLeftArrowVisible,
          isRightArrowVisible,
        }}
      >
        {children}
      </CarouselContext.Provider>
    )
  }
)
