import {primary} from '@phoenix/all'
import {css} from 'emotion'
import * as React from 'react'
import {connect} from 'react-redux'
import {financialViewAccessSelector, viewAccessSelector} from '../../../../../data/settings'
import {ICarouselContext, CarouselContext} from '../../../../../shared/context/CarouselContext'
import {IDestructuredRoleConflict} from '../../../../../shared/models/initiative'
import {IDistribution} from '../../../../../shared/models/initiative/IDistribution'
import {formatFloating} from '../../../../../shared/utilities'
import {CounterComponent, MoneyFieldViewComponent} from '../../../../shared'
import {CarouselItemComponent} from './CarouselItemComponent'
import {CarouselComponent} from './CarouselComponent'

export interface ICarouselDistributionComponentProps {
  isViewMode: boolean
  isFocused: boolean
  isFinancialViewMode: boolean
  distribution: IDistribution
  testID: string
  onBlur: (distribution) => void
  onFocus: () => void
  periodConflictDetails: IDestructuredRoleConflict
  roleId?: string
}

const carouselItemClass = css`
  input {
    min-height: 32px !important;
  }

  .number-input {
    min-height: 32px;
    border-radius: 0;
    background: ${primary.white()};
    margin-bottom: 1px;
    margin-top: 1px;
  }

  &:first-child .number-input {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-child .number-input {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`

export const CarouselDistribution: React.FC<ICarouselDistributionComponentProps> = React.memo(
  ({
    isViewMode,
    isFocused,
    isFinancialViewMode,
    distribution: propsDistribution,
    testID,
    onFocus,
    onBlur,
    periodConflictDetails,
    roleId,
  }) => {
    const carouselContext: ICarouselContext = React.useContext(CarouselContext)
    const [distribution, setDistribution] = React.useState<{
      [starDate: string]: number | null
    }>(propsDistribution)

    React.useEffect(() => {
      setDistribution(propsDistribution)
    }, [propsDistribution])

    const onChangeHandler = React.useCallback(
      (value: number | null, month: string): void => {
        setDistribution({
          ...distribution,
          [month]: value,
        })
      },
      [distribution]
    )

    const onBlurHandler = React.useCallback(
      (value: number | null, month: string): void => {
        const newDistribution = {
          ...distribution,
        }

        if (newDistribution[month] === null || typeof newDistribution[month] !== 'number') {
          newDistribution[month] = 0
        }

        onBlur(newDistribution)
      },
      [distribution, onBlur]
    )

    const handleKeyDown = (event, index) => {
      if (
        !event.shiftKey &&
        event.keyCode === 9 &&
        index === carouselContext.carouselStep.step + 2 &&
        carouselContext.isRightArrowVisible
      ) {
        carouselContext.onRightArrowClickHandler()
        event.preventDefault()
        const target = event.target
        setTimeout(() => {
          const inputElement = target.closest('li').nextElementSibling.querySelector('input')
          inputElement.focus()
          inputElement.select()
        }, 300)
      }
      if (
        event.shiftKey &&
        index === carouselContext.carouselStep.step &&
        carouselContext.isLeftArrowVisible
      ) {
        carouselContext.onLeftArrowClickHandler()
        event.preventDefault()
        const target = event.target
        setTimeout(() => {
          const inputElement = target.closest('li').previousElementSibling.querySelector('input')
          inputElement.focus()
          inputElement.select()
        }, 300)
      }
    }

    return (
      <CarouselComponent testID={testID}>
        {Object.keys(distribution).map((month, index) => {
          const isVisible =
            carouselContext.carouselStep.step <= index &&
            index < carouselContext.carouselStep.step + 3
          return (
            <CarouselItemComponent
              periodConflictDetails={periodConflictDetails}
              month={month}
              key={`${month}-${roleId}`}
              className={carouselItemClass}
              testID={`${testID}-${month}`}
              isCountValue={distribution[month]! > 0}
            >
              {!roleId && isFinancialViewMode ? ( // if roleId is provided it means this is the cost distribution
                <MoneyFieldViewComponent
                  testID={`cost-view-mode-${month}`}
                  cost={distribution[month]}
                  labelMessageKey={'sidebar.costs.fixed'}
                />
              ) : (
                <CounterComponent
                  isFocused={isFocused && !index}
                  testID={`${testID}-${month}-count`}
                  tabIndex={isVisible ? 0 : -1}
                  onKeyDown={(event) => {
                    handleKeyDown(event, index)
                  }}
                  roleCount={
                    distribution[month] !== null && typeof distribution[month] === 'number'
                      ? formatFloating(distribution[month]!, 3)
                      : distribution[month]
                  }
                  id={`${testID}-${month}`}
                  changePeopleCount={(value) => onChangeHandler(value, month)}
                  onBlur={(value) => onBlurHandler(value, month)}
                  onFocus={onFocus}
                  isViewMode={isViewMode}
                />
              )}
            </CarouselItemComponent>
          )
        })}
      </CarouselComponent>
    )
  }
)

const mapStateToProps = (state) => ({
  isViewMode: viewAccessSelector(state),
  isFinancialViewMode: financialViewAccessSelector(state),
})

CarouselDistribution.displayName = 'CarouselDistribution'

export const CarouselDistributionComponent = connect(mapStateToProps)(CarouselDistribution)
