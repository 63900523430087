import {createSelector} from 'reselect'
import {MONTHS_COUNT_IN_YEAR} from '../../../shared/helpers'
import {LEFT_SECTION_WIDTH} from '../../../view/Plan/Scenario/Header/People/roleDistributionDialog/constans'
import {planDurationSelector} from './planDurationSelector'
import {timelineCellMonthWidthSelector} from './timelineCellMonthWidthSelector'

export const timelineWidthOnMonthViewSelector = createSelector(
  [planDurationSelector, timelineCellMonthWidthSelector],
  (planDuration: number, timelineMonthCellWidth: number) => {
    return planDuration * MONTHS_COUNT_IN_YEAR * timelineMonthCellWidth + LEFT_SECTION_WIDTH
  }
)
