import MoreStrokeIcon from 'phoenix-icons/dist/MoreStrokeIcon.js'
import {LabelDropdown, Option, Tooltip} from '@phoenix/all'
import {useCurrentUser} from '@wf-mfe/auth'
import {css} from 'emotion'
import * as React from 'react'
import {connect} from 'react-redux'
import {currentPlanSelector} from '../../data/plan'
import {viewAccessSelector} from '../../data/settings'
import {isNewPlan} from '../../shared/helpers'
import {IPlan} from '../../shared/models'
import {translate} from '../../shared/utilities/TranslateHelper'
import {hasEditAccess} from '../../../spLists/shared/utils/hasEditAccess'

interface IPlanMoreOptionsComponentProps {
  isViewMode: boolean
  performAction: (action: string) => void
  plan: IPlan
}

const planMoreIconWrapperClass = css`
  button {
    border-radius: 3px;
  }
`

export const PlanMoreOptions = (props: IPlanMoreOptionsComponentProps): JSX.Element | boolean => {
  const {isViewMode, performAction, plan} = props

  const currentUser = useCurrentUser()

  const showOptions = React.useMemo(() => {
    return (
      !isViewMode &&
      (!plan.owner ||
        (!!plan.owner?.id &&
          !!plan.accessorObjects &&
          hasEditAccess(currentUser.ID, plan.owner.id, plan.accessorObjects)))
    )
  }, [currentUser, isViewMode, plan])

  const messages = {
    share: translate('initiative.options.share'),
    unavailable: translate('sp.plan.share.search.unavailable'),
    delete: translate('initiative.options.delete'),
  }

  return (
    showOptions && (
      <div className={planMoreIconWrapperClass}>
        <LabelDropdown
          testID="plan-more-icon"
          onChange={(action) => performAction(action.value)}
          trigger={<MoreStrokeIcon />}
        >
          <Option
            value={{
              label: messages.share,
              value: 'share',
            }}
            checked={false}
            testID="share"
            disabled={!plan.owner}
          >
            {(!plan.owner && (
              <Tooltip position="top" content={messages.unavailable}>
                {messages.share}
              </Tooltip>
            )) ||
              messages.share}
          </Option>
          {plan.owner?.id == currentUser.ID && (
            <Option
              testID="delete"
              checked={false}
              label={messages.delete}
              value={{
                label: messages.delete,
                value: 'delete',
              }}
              disabled={isNewPlan(plan)}
            />
          )}
        </LabelDropdown>
      </div>
    )
  )
}

const mapStateToProps = (state) => ({
  plan: currentPlanSelector(state),
  isViewMode: viewAccessSelector(state),
})

export const PlanMoreOptionsComponent = connect(mapStateToProps)(PlanMoreOptions)
