import {css} from 'emotion'
import {Moment} from 'moment'
import * as React from 'react'

import CalendarIcon from 'phoenix-icons/dist/CalendarIcon.js'
import {FormLabel, Text} from '@phoenix/all'
import {formatDateBasedOnLocale} from '../../../../shared/utilities/dateHelpers'

import {inlineCenterItemsClass} from '../StyleHelper'
import {Translate} from '../../../shared/Translate'

interface IDateRangeInfoComponent {
  labelMessageKey: string
  startDate: Moment
  endDate: Moment
  testId: string
  applyTimezone?: boolean
}

const textClass = css`
  margin-left: 4px;
`

export const DateRangeInfoComponent: React.FunctionComponent<IDateRangeInfoComponent> = (props) => {
  return (
    <div data-testid={props.testId}>
      <FormLabel>
        <Translate messageKey={props.labelMessageKey} />
      </FormLabel>
      <div className={inlineCenterItemsClass}>
        <CalendarIcon width={16} height={16} />
        <Text className={textClass}>{`${formatDateBasedOnLocale(
          props.startDate,
          {},
          props.applyTimezone
        )} - ${formatDateBasedOnLocale(props.endDate, {}, props.applyTimezone)}`}</Text>
      </div>
    </div>
  )
}

DateRangeInfoComponent.defaultProps = {
  applyTimezone: false,
}
