import {css} from 'emotion'
import * as React from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import {IScenario} from '../../../shared/models/scenario'

import {ScenarioListComponent} from './ScenarioListComponent'
import {MeasurementsContext} from '../../../shared/context/MeasurementsContext'

interface IScenarioListWrapperComponentProps {
  currentScenario: IScenario
}

const gridClass = css`
  overflow: hidden;
  flex-grow: 1;
  z-index: 1;
`

export const ScenarioListWrapperComponent: React.FunctionComponent<IScenarioListWrapperComponentProps> = React.memo(
  (props) => {
    return (
      <div className={gridClass}>
        <AutoSizer>
          {({width, height}) => (
            <MeasurementsContext.Consumer>
              {({initiativeRowHeight}) => (
                <ScenarioListComponent
                  initiativeRowHeight={initiativeRowHeight}
                  width={width}
                  height={height}
                  currentScenario={props.currentScenario}
                />
              )}
            </MeasurementsContext.Consumer>
          )}
        </AutoSizer>
      </div>
    )
  }
)

ScenarioListWrapperComponent.displayName = 'ScenarioListWrapperComponent'
