export const exportMessageKeys = [
  'form.name',
  'form.dates.start',
  'form.dates.end',
  'form.dates.duration',
  'sp.initiative.publish.list.roles.fte',
  'initiative.publish.list.roles',
  'initiative.publish.list.last_published',
  'esp.publish.month',
  'esp.publish.months',
  'initiative.publish.project.icon',
  'initiative.import.project.icon',
  'sp.initiatives',
  'sp.initiative',
]
