import * as React from 'react'

import AddIcon from 'phoenix-icons/dist/AddIcon.js'
import {Button} from '@phoenix/all'
import {css} from 'emotion'

interface IAddNewPlanComponent {
  newPlanMessage: string | JSX.Element
  createNewPlanFunction: () => void
}

const buttonClass = css`
  margin-right: 14px;
`

const ICON_SIZE = 24

export const AddNewPlanComponent: React.FunctionComponent<IAddNewPlanComponent> = (props) => {
  return (
    <Button
      className={buttonClass}
      icon={AddIcon}
      iconSize={ICON_SIZE}
      text
      onClick={props.createNewPlanFunction}
      testID="new-plan"
    >
      {props.newPlanMessage}
    </Button>
  )
}
