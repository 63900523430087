import {MouseEventHandler, Ref, useEffect, useRef, useState} from 'react'
import {findDOMNode} from 'react-dom'

export function useToggleStateOnDocumentClick<T extends HTMLElement>(
  isOpenInitialState = false,
  onOpen?: MouseEventHandler,
  onClose?: MouseEventHandler
): {
  isOpen: boolean
  open: (e: any) => void
  close: (e, force?: boolean) => void
  closeWithoutTimeOut: (e: any) => void
  elementRef: Ref<T>
} {
  const [isOpen, setIsOpen] = useState(isOpenInitialState)
  const elementRef = useRef(null)

  const closeWithoutTimeOut = (e) => {
    setIsOpen(false)
    if (onClose) {
      onClose(e)
    }
  }

  const close = useRef((e, force = false) => {
    // eslint-disable-next-line react/no-find-dom-node
    const currentElement = findDOMNode(elementRef.current)

    // TODO: fix workarounds
    if (
      !force &&
      currentElement &&
      currentElement.contains(e.target) &&
      !(e.target.parentElement.dataset.month || e.target.dataset.month)
    ) {
      return
    }

    setIsOpen(false)
    if (onClose) {
      onClose(e)
    }
  }).current

  const open = useRef((e) => {
    e.stopPropagation()
    setIsOpen(true)
    if (onOpen) {
      onOpen(e)
    }
  }).current

  useEffect(() => {
    const onKeyDownHandler = (e) => {
      const keyboardClickKeys = ['Enter', 'Space', ' ']

      if (keyboardClickKeys.includes(e.key)) {
        close(e)
      }
    }

    if (isOpen) {
      document.addEventListener('click', close)
      document.addEventListener('keydown', onKeyDownHandler)
    }
    return () => {
      document.removeEventListener('click', close)
      document.removeEventListener('keydown', onKeyDownHandler)
    }
  }, [isOpen])

  return {
    isOpen,
    open,
    close,
    elementRef,
    closeWithoutTimeOut,
  }
}
