import {createSelector} from 'reselect'
import {IPlan} from '../../../shared/models'
import {currentPlanSelector} from './currentPlanSelector'

export const initialScenarioSelector = createSelector(
  currentPlanSelector,
  (currentPlan: IPlan | null) => {
    return currentPlan!.scenarios[0]
  }
)
