import {css, cx} from 'emotion'
import {Modifiers} from 'popper.js'
import {MouseEventHandler} from 'react'
import * as React from 'react'
import ReactDOM from 'react-dom'
import {Manager, Popper, Reference} from 'react-popper'
import {useToggleStateOnDocumentClick} from '../Plan/Scenario/hooks/useToggleStateOnDocumentClick'
import {checkPlacement, tooltipBoxShadowClass} from './StyleHelper'

interface IPopperComponentProps {
  reference: React.ReactElement
  content: React.ReactElement | null
  showOnOver: boolean
  placement: string
  className?: string
  isInsidePortal?: boolean
  modifiers?: Modifiers
  testID?: string
  keepPopupVisible?: boolean
  useTopIndent?: boolean
  onOpen?: MouseEventHandler
  onClose?: MouseEventHandler
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  isInSvg?: boolean
  openDelay?: number
}

const popperWrapper = css`
  ${tooltipBoxShadowClass};
  z-index: 10;
`

const popperStyles = (styles, placement, useTopIndent = true) => {
  const isTop = checkPlacement(placement, 'top')
  const isRight = checkPlacement(placement, 'right')
  const postition: {left?: string; top: string} = {
    top: isTop && useTopIndent ? '-10px' : '0',
  }
  if (isRight) {
    postition.left = '6px'
  }
  return {
    ...styles,

    ...postition,
  }
}

export const PopperComponent: React.FunctionComponent<IPopperComponentProps> = (props) => {
  const {isOpen, open, close, elementRef} = useToggleStateOnDocumentClick<HTMLDivElement>(
    false,
    props.onOpen,
    props.onClose
  )

  const openWithDelayTimeoutId = React.useRef<number>()
  const closeTimeoutId = React.useRef<number>()
  const popperLeaveTimeoutId = React.useRef<number>()

  const reference = ({ref}) => {
    const onKeyPress = (e) => {
      const keyboardClickKeys = ['Enter', 'Space', ' ']

      if (keyboardClickKeys.includes(e.key)) {
        open(e)
      }
    }

    const onClose = (e) => {
      window.clearTimeout(openWithDelayTimeoutId.current)

      closeTimeoutId.current = window.setTimeout(() => {
        close(e)
      }, 0)
    }

    const openWithDelay = (e) => {
      if (isOpen) {
        window.clearTimeout(popperLeaveTimeoutId.current)
      }
      window.clearTimeout(openWithDelayTimeoutId.current)
      openWithDelayTimeoutId.current = window.setTimeout(() => {
        open(e)
      }, props.openDelay || 0)
    }

    if (!props.showOnOver) {
      return React.cloneElement(props.reference, {ref: ref, onClick: open, onKeyPress})
    }

    if (props.isInSvg) {
      return (
        <g onMouseEnter={openWithDelay} onMouseLeave={onClose}>
          {React.cloneElement(props.reference, {ref: ref})}
        </g>
      )
    }

    return React.cloneElement(props.reference, {
      ref: ref,
      onMouseOver: openWithDelay,
      onMouseLeave: onClose,
    })
  }

  const popper = () => {
    const onMouseLeave = (e) => {
      popperLeaveTimeoutId.current = window.setTimeout(() => {
        close(e, true)
      }, 0)

      if (props.onMouseLeave) {
        props.onMouseLeave()
      }
    }

    const onMouseEnter = () => {
      window.clearTimeout(closeTimeoutId.current)

      if (props.onMouseEnter) {
        props.onMouseEnter()
      }
    }

    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <Popper placement={props.placement} modifiers={props.modifiers}>
        {({placement, ref, style}) => (
          <div
            data-testid={props.testID}
            ref={ref}
            style={popperStyles(style, placement, props.useTopIndent)}
            data-placement={placement}
            className={cx(popperWrapper, props.className)}
            onMouseLeave={props.keepPopupVisible ? onMouseLeave : undefined}
            onMouseEnter={props.keepPopupVisible ? onMouseEnter : undefined}
          >
            <div ref={elementRef}>{props.content}</div>
          </div>
        )}
      </Popper>
    )
  }

  const renderPopper = () => {
    const rootElement = document.getElementById('tooltip-container') || document.body
    if (isOpen) {
      return (
        <React.Fragment>
          {props.isInsidePortal ? ReactDOM.createPortal(popper(), rootElement) : popper()}
        </React.Fragment>
      )
    }

    return null
  }

  return (
    <Manager>
      <Reference>{reference}</Reference>

      {renderPopper()}
    </Manager>
  )
}

PopperComponent.defaultProps = {
  isInsidePortal: true,
  keepPopupVisible: false,
  useTopIndent: true,
}
