import * as React from 'react'
import {connect} from 'react-redux'

import UserIcon from 'phoenix-icons/dist/UserIcon.js'
import {activeScenarioSelector} from '../../../../data/plan'
import {formatNumber} from '../../../../shared/utilities'
import {CircleInfoComponent, getArrowDirection, InfoComponent} from './shared'

export interface IRolesRowComponent {
  available: number | null
  activeScenarioAvailable: number | null
  required: number | null
  activeScenarioRequired: number | null
}

export const RolesRow: React.FunctionComponent<IRolesRowComponent> = (
  props: IRolesRowComponent
) => {
  const {available, activeScenarioAvailable, required, activeScenarioRequired} = props

  const availableArrowDirection = getArrowDirection(available, activeScenarioAvailable)
  const availableAbbreviatedValue = available !== null ? formatNumber(available, 2, true, 5) : '-'
  const availableValue = available !== null ? formatNumber(available, 2, false, 5) : '-'

  const requiredArrowDirection = getArrowDirection(required, activeScenarioRequired)
  const requiredAbbreviatedValue = required !== null ? formatNumber(required, 2, true, 5) : '-'
  const requiredValue = required !== null ? formatNumber(required, 2, false, 5) : '-'

  return (
    <>
      <CircleInfoComponent icon={<UserIcon />} />
      <InfoComponent
        labelMessageKey={'header.widget.role.available'}
        arrowDirection={availableArrowDirection}
        abbreviatedValue={availableAbbreviatedValue!}
        value={availableValue!}
        testId={'available'}
      />
      <InfoComponent
        labelMessageKey={'header.widget.role.required'}
        arrowDirection={requiredArrowDirection}
        abbreviatedValue={requiredAbbreviatedValue!}
        value={requiredValue!}
        testId={'required'}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  activeScenarioAvailable: activeScenarioSelector(state).people.available,
  activeScenarioRequired: activeScenarioSelector(state).people.required,
})

export const RolesRowComponent = connect(mapStateToProps)(RolesRow)
