import {css} from 'emotion'

export const inlineCenterItemsClass = css`
  display: flex;
  align-items: center;
  .role-name *::selection {
    color: inherit;
    background-color: transparent;
  }
  .role-hourly-rate *::selection {
    color: inherit;
    background-color: transparent;
  }
`
