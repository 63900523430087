import ErrorSmallIcon from 'phoenix-icons/dist/ErrorSmallIcon.js'
import {Text, alerts, primary, secondary} from '@phoenix/all'
import {css} from 'emotion'
import * as React from 'react'

const wrapperClass = css`
  display: flex;
  padding: 22px 19px;
  width: 378px;
  background-color: ${primary.white()};
  border-top: 4px solid ${secondary.yellow(500)};
`

const contentClass = css`
  flex-grow: 1;
  margin-left: 15px;
`

export const TooltipWarningContentComponent: React.FC = React.memo(({children}) => (
  <div className={wrapperClass}>
    <span>
      <ErrorSmallIcon color={alerts.warn(500)} />
    </span>
    <div className={contentClass}>
      <Text>{children}</Text>
    </div>
  </div>
))

TooltipWarningContentComponent.displayName = 'TooltipWarningContentComponent'
