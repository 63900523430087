import {SET_FEATURES} from './actions'
import {ISplitContext, defaultValue} from '../../shared/context/SplitContext'

const initialState: ISplitContext = {
  ...defaultValue,
}

export function features(state: ISplitContext = initialState, action) {
  switch (action.type) {
    case SET_FEATURES: {
      return {
        ...state,
        ...action.features,
      }
    }
    default:
      return state
  }
}
