import {Input} from '@phoenix/all'
import * as React from 'react'
import {NumberEditorComponent} from './NumberEditorComponent'

interface IPhoenixNumberEditorComponentProps extends React.HTMLAttributes<HTMLElement> {
  maxValue?: number
  minValue?: number
  onChange: (value) => void
  onBlur: (value) => void
  parseValue?: (value) => void
  precision: number
  testID: string
  validateValue?: () => void
  value: number | null
  ref: React.Ref<HTMLInputElement>
  label?: string
  autoComplete: string
  name?: string
  className?: string
  hideMaxLengthInfo: boolean
}

export const PhoenixNumberEditorComponent: React.FunctionComponent<IPhoenixNumberEditorComponentProps> = React.forwardRef(
  (props, ref: React.Ref<HTMLInputElement>) => {
    return (
      <NumberEditorComponent ref={ref} {...props}>
        {(editorProps, inputRef) => <Input {...editorProps} forwardedRef={inputRef} />}
      </NumberEditorComponent>
    )
  }
)

PhoenixNumberEditorComponent.displayName = 'PhoenixNumberEditorComponent'
