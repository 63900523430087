import * as React from 'react'
import {connect} from 'react-redux'

import {financialAccessDeniedSelector} from '../../../../data/settings'
import {HeaderBudgetWidgetContainerComponent} from './Budget'
import {HeaderNetValueWidgetComponent} from './HeaderNetValueWidgetComponent'
import {HeaderPeopleWidgetContainerComponent} from './People'

interface IScenarioHeaderWidgetsComponent {
  hasFinancialAccess: boolean
}

export const ScenarioHeaderWidgets: React.FunctionComponent<IScenarioHeaderWidgetsComponent> = (
  props
) => {
  const {hasFinancialAccess} = props

  return (
    <React.Fragment>
      <HeaderPeopleWidgetContainerComponent />
      {hasFinancialAccess && <HeaderBudgetWidgetContainerComponent />}
      {hasFinancialAccess && <HeaderNetValueWidgetComponent />}
    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  hasFinancialAccess: !financialAccessDeniedSelector(state),
})

export const ScenarioHeaderWidgetsComponent = connect(mapStateToProps)(ScenarioHeaderWidgets)
