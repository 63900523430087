import * as React from 'react'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {AvatarGroup, Avatar} from '@phoenix/all'
import {getAvatarUrl} from '../../shared/helpers/User'
import {css} from 'react-emotion'
import {IShareAccessorObject} from '@workfront/sp-sharing-dialog'

export interface ISharedUserListComponentProps {
  accessorObjects: IShareAccessorObject[]
  maxVisibleUsers?: number
  onSelect: () => void
}

const avatarContainerStyles = css`
  display: inline-block;
  cursor: pointer;
`

export const SharedUserListComponent: React.FC<ISharedUserListComponentProps> = ({
  accessorObjects,
  maxVisibleUsers,
  onSelect,
}) => {
  const onContainerClick = React.useCallback(
    (event) => {
      // We are stopping the event propagation in the capture phase to make sure that the click event is NOT handled by the Avatar Group component.
      // Otherwise, if there are extra N avatars, when the user clicks the "+N" button, those avatars will be shown (which is something we do not want to happen because we have to show the "Sharing" dialog regardless of whether the click happened).
      event.stopPropagation()
      onSelect()
    },
    [onSelect]
  )

  return (
    <div className={avatarContainerStyles} onClickCapture={onContainerClick}>
      <AvatarGroup groupMax={maxVisibleUsers} type="small">
        {accessorObjects.map((accessorObj) => (
          <Avatar
            key={accessorObj.guid}
            type="small"
            avatarURL={getAvatarUrl(accessorObj.guid, accessorObj.avatarDate)}
            label={accessorObj.name}
          />
        ))}
      </AvatarGroup>
    </div>
  )
}
