export const LIST_ID = 'ESP_INITIATIVE_EXPORT_LIST'
export const PAGE_SIZES = [100, 250, 500]

export const metadata = {
  objCode: 'ESPPLN',
  isDashboard: null,
  detailObj: {objCode: null, id: null},
  isHierarchic: false,
  pagination: {
    pageCount: 1,
    pageSizes: [],
    pageNumber: 1,
    count: 0,
    pageSize: 500,
    recordLimit: 500,
  },
  compactMode: false,
  options: {
    objCode: 'ESPPLN',
    viewID: LIST_ID,
    listTileName: LIST_ID,
    isShortView: false,
  },
  listTileName: LIST_ID,
  currency: null,
  addMoreSingleMessageKey: 'proj.list.action.add',
  baseParams: {projectId: null},
  showContextMenu: null,
  objCodeMessageKey: 'initiative',
  sortInfo: [],
  showInlineAdd: false,
  rawValueTypes: {
    plannedStartDate: 'dateTime',
    actualCompletionDate: 'dateTime',
    projectedCompletionDate: 'dateTime',
    actualStartDate: 'dateTime',
    plannedCompletionDate: 'dateTime',
    projectedStartDate: 'dateTime',
  },
  emptyMessageKey: null,
  worksheetRefreshTimer: -1,
  useColumnWidths: false,
  contextMenu: {
    toolbarActionsRequirements: {},
    toolbarActions: [],
  },
  addMoreMessageKey: 'proj.list.action.add.more',
  baseCollapseLevel: 0,
  applyWindowScrolling: false,
  startOrderNum: null,
  queryMap: {},
  hidePageSize: false,
  viewID: LIST_ID,
  isShortView: false,
  isInModal: false,
  isReactListToolbarsEnabled: true,
}

export const columns = [
  {
    defaultMinWidth: 55,
    display: '',
    index: 0,
    reorderable: false,
    sortable: false,
    sortQuery: null,
    field: '',
    defaultMaxWidth: 59,
    width: 59, // TODO: ditavoryal a nenc arac vor min 55, max 54 ?
  },
  {
    template: 'component.initiativename',
    defaultMinWidth: 120,
    display: '',
    index: 1,
    reorderable: false,
    sortable: true,
    sortQuery: 'name',
    field: 'initiativename',
    defaultMaxWidth: 278,
    width: 278,
  },
  {
    defaultMinWidth: 55,
    display: '',
    index: 2,
    reorderable: false,
    sortable: true,
    sortQuery: 'startDate',
    field: 'startDate',
    defaultMaxWidth: 76,
    width: 76,
  },
  {
    defaultMinWidth: 55,
    display: '',
    index: 3,
    reorderable: false,
    sortable: true,
    sortQuery: 'endDate',
    field: 'endDate',
    defaultMaxWidth: 76,
    width: 76,
  },
  {
    template: 'component.initiativeduration',
    defaultMinWidth: 55,
    display: '',
    index: 4,
    reorderable: false,
    sortable: true,
    sortQuery: 'duration',
    field: 'initiativeduration',
    defaultMaxWidth: 76,
    width: 76,
  },
  {
    template: 'component.initiativeroles',
    defaultMinWidth: 150,
    display: '',
    index: 5,
    reorderable: false,
    sortable: false,
    sortQuery: null,
    field: 'initiativeroles',
    defaultMaxWidth: 512,
    width: 512,
  },
  {
    defaultMinWidth: 120,
    display: '',
    index: 6,
    reorderable: false,
    sortable: true,
    sortQuery: 'lastPublishedDate',
    field: 'lastPublishedDate',
    defaultMaxWidth: 120,
    width: 120,
  },
]
