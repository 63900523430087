import * as React from 'react'
import {IDistribution} from '../models/initiative/IDistribution'
import {IRolesDetails} from '../../view/Plan/Scenario/Header/People/roleDistributionDialog/RoleDistributionHelper'

interface IRoleDistributionContext {
  updateRoleAvailablePerPeriod: (roleId: string, value: IDistribution) => void
  removeRole: (roleId: string) => void
  roleIds: string[]
  rolesDetails: IRolesDetails
}

export const RoleDistributionContext = React.createContext<IRoleDistributionContext>(undefined!)
