import ExpensesSmallIcon from 'phoenix-icons/dist/ExpensesSmallIcon.js'
import GroupSmallIcon from 'phoenix-icons/dist/GroupSmallIcon.js'
import HoursSmallIcon from 'phoenix-icons/dist/HoursSmallIcon.js'
import RoiSmallIcon from 'phoenix-icons/dist/RoiSmallIcon.js'
import {css} from 'emotion'
import * as React from 'react'
import {connect} from 'react-redux'
import {currentInitiativeSelector} from '../../../../data/initiative'
import {currencySelector} from '../../../../data/plan'
import {financialAccessDeniedSelector} from '../../../../data/settings'
import {getFixedCostSum, getOverallCostSum} from '../../../../shared/helpers'
import {IInitiativeConflicts} from '../../../../shared/models/initiative'
import {formatCurrency} from '../../../../shared/utilities'
import {CostFormComponent} from './costs/CostFormComponent'
import {DurationComponent} from './DurationComponent'
import {PlannedBenefitFormComponent} from './PlannedBenefitFormComponent'
import {PeopleFormComponent} from './roles/PeopleFormComponent'
import {SectionComponent} from './SectionComponent'
import {peopleCostOnConflictCalculationSelector} from '../../../../data/plan/selectors/peopleCostOnConflictCalculationSelector'
import {Translate} from '../../../shared/Translate'

export interface IEditSideBarBodyComponentProps {
  currency: string
  hasFinancialAccess: boolean
  conflicts: IInitiativeConflicts | null
  cost: number | null
  netValue: number | null
  rolesCount: number | null
  duration: number
}

const bodyClassName = css`
  padding: 0;
  height: calc(100% - 52px);
`
const sidebarBodyClass = css`
  padding: 0 16px;
  min-height: 100%;
`

const tooltipContainerClass = css`
  width: 355px;
`

export const EditSidebarBody: React.FunctionComponent<IEditSideBarBodyComponentProps> = React.memo(
  (props) => {
    const durationValue = (
      <Translate messageKey={'sp.sidebar.duration.month'} args={{count: props.duration}} />
    )
    const hasRoleConflicts: boolean = !!props.conflicts && !!props.conflicts.roleConflicts!.size
    const hasBudgetConflicts: boolean = !!props.conflicts && !!props.conflicts.budgetConflicts

    return (
      <div className={sidebarBodyClass}>
        <SectionComponent
          icon={<HoursSmallIcon />}
          titleMessageKey={'sp.sidebar.duration'}
          value={durationValue}
          testId={'initiative-duration-section'}
        >
          <DurationComponent />
        </SectionComponent>

        <SectionComponent
          hasError={hasRoleConflicts}
          icon={<GroupSmallIcon />}
          titleMessageKey={'sidebar.required.job.roles'}
          value={null}
          errorMessage={'sp.sidebar.people.conflict.message'}
          className={bodyClassName}
          testId={'initiative-people-section'}
          tooltipContainerClass={tooltipContainerClass}
        >
          <PeopleFormComponent />
        </SectionComponent>

        {props.hasFinancialAccess && (
          <SectionComponent
            hasError={hasBudgetConflicts}
            errorMessage={'sp.sidebar.budget.conflict.message'}
            icon={<ExpensesSmallIcon />}
            titleMessageKey={'sidebar.costs'}
            value={props.cost !== null ? formatCurrency(props.cost, props.currency, false) : null}
            testId={'initiative-costs-section'}
            tooltipContainerClass={tooltipContainerClass}
          >
            <CostFormComponent />
          </SectionComponent>
        )}

        {props.hasFinancialAccess && (
          <SectionComponent
            icon={<RoiSmallIcon />}
            titleMessageKey={'sidebar.net'}
            value={
              props.netValue !== null ? formatCurrency(props.netValue, props.currency, false) : null
            }
            testId={'initiative-net-value-section'}
          >
            <PlannedBenefitFormComponent />
          </SectionComponent>
        )}
      </div>
    )
  }
)

EditSidebarBody.displayName = 'EditSidebarBody'

const mapStateToProps = (state) => {
  const currentInitiative = currentInitiativeSelector(state)
  const costs = currentInitiative && currentInitiative.costs
  const usePeopleCostOnConflictCalculation = peopleCostOnConflictCalculationSelector(state)
  let cost
  if (costs) {
    cost = usePeopleCostOnConflictCalculation ? getOverallCostSum(costs) : getFixedCostSum(costs)
  }

  return {
    currency: currencySelector(state),
    hasFinancialAccess: !financialAccessDeniedSelector(state),
    cost: cost || null,
    conflicts: currentInitiative!.conflicts,
    netValue:
      currentInitiative &&
      currentInitiative.netValue &&
      currentInitiative.netValue.calculatedNetValue,
    rolesCount: currentInitiative!.people.total,
    duration: currentInitiative!.dates.duration,
  }
}

export const EditSidebarBodyComponent = connect(mapStateToProps)(EditSidebarBody)
