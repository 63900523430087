import {DATA_FETCHER_PLUGIN_NAME} from '../../shared/toolbar/constants'
import {bindPluginClass, PluginBase} from '@workfront/list-plugin-utils'
import {IDataTableAPI} from '@wftypes/list'

import {resetSortPaginationInfo} from '../../shared/utils/queryUtils'
import {PAGE_SIZES} from '../table-data'
import {apiDateToDate} from '@workfront/fns'
import {trackSP} from '../../shared/utils/pendoTracker'
import {FilterChangeParams} from '../../shared/toolbar/filters/types'
import {formatDateBasedOnLocale} from '../../shared/utils/dateHelpers'
import {loadPlans, loadPlanCount, loadPlanListFilters} from '../services/apiFactory'
import {EspPlan, User} from 'workfront-objcodes'
import {IPlan} from '../../shared/types'
import {getStorageUtil} from '@workfront/storage'
import {transformResult} from './toolbar/filters/loadFilters'
import {FILTER_LOCAL_STORAGE_KEY, ALL_PLANS_FILTER} from './constants'
import {UNAUTHORIZED_ERROR_MESSAGE, UNAUTHENTICATED_ERROR_MESSAGE} from '../../shared/constants'

export interface DataFetcherAPI {
  getPlans: (planIDs: string[]) => IPlan[]
  filterListData: (filters: FilterChangeParams) => void
  getDefaultFilterPromise: () => Promise<{id: string; name?: string}>
}

export interface DataFetcherOptions {
  onShareClick: (plans: IPlan[]) => void
  onAccessDeniedDetected: () => void
  redirectToLoginPage: () => void
  hasOnlyViewAccess: boolean
}

const storageUtil = getStorageUtil()

class DataFetcherPlugin extends PluginBase<DataFetcherAPI, DataFetcherOptions> {
  static PLUGIN_NAME = DATA_FETCHER_PLUGIN_NAME
  static PLUGIN_DEPS = []
  private filterID = ALL_PLANS_FILTER
  private listApi: IDataTableAPI | null = null
  private options: DataFetcherOptions | null = null
  private queryParams: {[key: string]: unknown} = {}
  private defaultFilterPromise
  private planMap = new Map<string, IPlan>()

  private planFields = [
    'id',
    'name',
    'owner:name',
    'owner:ID',
    'scenarioCount',
    'createdOn',
    'lastUpdatedOn',
    'accessorObjects:*',
  ]

  windowMessageListener = (message) => {
    if (this.listApi && message.data && message.data.action === 'reloadPlans') {
      // The list of plans is reloaded when the current page is set to 1
      this.listApi.pagination.setCurrentPage(1)
    }
  }

  registerReloadListener() {
    window.addEventListener('message', this.windowMessageListener)

    return () => {
      window.removeEventListener('message', this.windowMessageListener)
    }
  }

  ready(listApi: IDataTableAPI, options: DataFetcherOptions) {
    this.listApi = listApi
    this.options = options
    this.defaultFilterPromise = new Promise<{id: string; key?: string}>((resolve) => {
      const id = storageUtil.get(FILTER_LOCAL_STORAGE_KEY) || ''
      if (id === '') {
        resolve({
          id,
        })
        return
      }
      loadPlanListFilters()
        .then((data) => data.map(transformResult))
        .then((filters) => {
          const filter = filters.find((item) => item.id == id)
          resolve(filter)
        })
    }).then((filter) => {
      this.filterID = filter?.id || ALL_PLANS_FILTER
      this.queryParams.filter = this.filterID
      listApi.pagination.setCurrentPage(1)
      return filter
    })

    listApi.interceptors.enableInterceptor(
      listApi.interceptors.getDefaultInterceptorName(),
      listApi.interceptors.events.LOAD_PAGE_ITEMS,
      false
    )
    this.addShutdownRoutine(
      () => {
        listApi.interceptors.enableInterceptor(
          listApi.interceptors.getDefaultInterceptorName(),
          listApi.interceptors.events.LOAD_PAGE_ITEMS,
          true
        )
      },
      listApi.interceptors.registerInterceptor(
        DataFetcherPlugin.PLUGIN_NAME,
        listApi.interceptors.events.LOAD_PAGE_ITEMS,
        ({data: {sortInfo, pageNumber, pageSize}}) => {
          const [prevSortInfo] = listApi.sorting.getSortInfo()
          const [nextSortInfo] = sortInfo
          if (JSON.stringify(nextSortInfo) !== JSON.stringify(prevSortInfo)) {
            trackSP('plan list sort change', nextSortInfo)
          }
          resetSortPaginationInfo(this.queryParams)
          sortInfo.forEach((sortInfoItem) => {
            this.queryParams['sort'] = `${sortInfoItem.key},${sortInfoItem.direction}`
          })
          if (pageSize) {
            this.queryParams['page'] = pageNumber - 1
            this.queryParams['size'] = pageSize
          }
          this.queryParams['fields'] = this.planFields.join(',')

          // Make sure that the last selected filter is applied
          this.queryParams.filter = this.filterID

          const {recordLimit} = listApi.metadata.getProperty('pagination')
          return this.loadData({pageNumber, pageSize: pageSize ?? recordLimit})
        }
      ),
      listApi.interceptors.registerInterceptor(
        DataFetcherPlugin.PLUGIN_NAME,
        listApi.interceptors.events.AFTER_LOAD_PAGE_ITEMS,
        () => {
          const selectedIDs = listApi.selection
            .getSelectionRanges()
            .reduce((acc, item) => acc.concat(item), [])
          window.requestAnimationFrame(() => {
            const rows = listApi.rows.getVisibleRowIndices(selectedIDs)
            const visibleRowIDs = Object.keys(rows)
            listApi.selection.setSelection(visibleRowIDs)
          })
        }
      ),
      this.registerReloadListener()
    )
  }

  getApi(listApi: IDataTableAPI): DataFetcherAPI {
    return {
      filterListData: (filter) => {
        this.filterID = filter.ID || ALL_PLANS_FILTER
        this.queryParams.filter = this.filterID
        trackSP(`plan list filter changed to ${filter.text}`, {
          filterID: this.filterID,
        })
        listApi.pagination.setCurrentPage(1)
      },
      getDefaultFilterPromise: () => this.defaultFilterPromise,
      getPlans: (planIDs: string[]) => {
        const plans: IPlan[] = []
        for (const planID of planIDs) {
          const plan = this.planMap.get(planID)
          if (plan) {
            plans.push(plan)
          }
        }

        return plans
      },
    }
  }

  recreatePlanMap(plans: IPlan[]) {
    this.planMap.clear()
    for (const plan of plans) {
      this.planMap.set(plan.id, plan)
    }
  }

  loadData = ({pageNumber, pageSize}) => {
    return Promise.all([loadPlans(this.queryParams), loadPlanCount(this.queryParams)])
      .then(([searchData, countData]) => {
        this.recreatePlanMap(searchData)
        return {
          rows: this.convertApiData(searchData),
          metadata: {
            pagination: {
              count: countData,
              pageNumber,
              pageSize,
              pageSizes: PAGE_SIZES.filter((size) => {
                return size <= countData
              }).map(String),
            },
            rawValueTypes: {},
          },
          groups: {},
          collapsedGroups: [],
          collapsedRows: [],
        }
      })
      .catch((err) => {
        if (this.options) {
          switch (err.message) {
            case UNAUTHORIZED_ERROR_MESSAGE:
              this.options.onAccessDeniedDetected()
              break
            case UNAUTHENTICATED_ERROR_MESSAGE:
              this.options.redirectToLoginPage()
              break
          }
        }
        throw err
      })
      .finally(() => (this.queryParams = {}))
  }

  private convertApiData = (planData) => {
    return planData.reduce((acc, plan, index) => {
      return {
        ...acc,
        [plan.id]: {
          objCode: EspPlan,
          editable: false,
          columns: this.getColumnData(plan),
          display: plan.name,
          id: plan.id,
          order: index,
          rawValues: {},
        },
      }
    }, {})
  }

  private getColumnData = (plan) => {
    return [
      ...(!this.options!.hasOnlyViewAccess ? [{isEditable: false, value: ''}] : []),
      {
        isEditable: false,
        value: '',
        viewData: {
          'component.planname|name': {
            id: plan.id,
            name: plan.name,
            objCode: EspPlan,
          },
        },
      },
      {
        isEditable: false,
        value: '',
        viewData: {
          'component.owner|owner': {
            id: plan.owner.ID,
            name: plan.owner.name,
            objCode: User,
          },
        },
      },
      {
        isEditable: false,
        value: '',
        viewData: {
          'component.sharedwith|accessorObjects': {
            plan,
            maxVisibleUsers: 4,
            onSelect: () => {
              if (this.options) {
                this.options.onShareClick([plan])
              }
            },
          },
        },
      },
      {
        isEditable: false,
        value: plan.scenarioCount.toString(),
      },
      {
        isEditable: false,
        value: formatDateBasedOnLocale(apiDateToDate(plan.createdOn)),
      },
      {
        isEditable: false,
        value: formatDateBasedOnLocale(apiDateToDate(plan.lastUpdatedOn)),
      },
    ]
  }
}

export default bindPluginClass(
  () => new DataFetcherPlugin(),
  DataFetcherPlugin.PLUGIN_NAME,
  DataFetcherPlugin.PLUGIN_DEPS
)
