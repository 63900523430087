import * as React from 'react'
import {LoadLocalizations, LocalizationProvider} from '@wf-mfe/localize-react'

import {PlanListComponent} from './PlanList'
import {planMessageKeys} from './messageKeys'
import {espClientFactory} from '../../shared/L10n'

interface IPlanListProps {
  currentUserID: string
  loadPermissionsFunction: (objID: string | null) => Promise<Map<string, string>>
  createNewPlanFunction: () => void
  redirectToLoginPage: () => void
  newFilterSpPlansListSplit: boolean
}

export const PlanListWrapperComponent: React.FC<IPlanListProps> = ({
  loadPermissionsFunction,
  redirectToLoginPage,
  createNewPlanFunction,
  currentUserID,
  newFilterSpPlansListSplit,
}) => {
  return (
    <LocalizationProvider clientFactory={espClientFactory}>
      <LoadLocalizations messageKeys={planMessageKeys}>
        <PlanListComponent
          loadPermissionsFunction={loadPermissionsFunction}
          redirectToLoginPage={redirectToLoginPage}
          createNewPlanFunction={createNewPlanFunction}
          currentUserID={currentUserID}
          newFilterSpPlansListSplit={newFilterSpPlansListSplit}
        />
      </LoadLocalizations>
    </LocalizationProvider>
  )
}
