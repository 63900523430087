import {useReducer, useRef} from 'react'

export interface FilterData {
  saved: Array<Record<string, unknown>>
  shared: Array<Record<string, unknown>>
}

const initialState = {
  data: {
    saved: [],
    shared: [],
  },
  isDataLoaded: false,
}

function reducer(state, action) {
  switch (action.type) {
    case 'SET_DATA':
      return {
        ...state,
        data: action.data,
        isDataLoaded: action.isDataLoaded,
      }
    case 'SET_IS_DATA_LOADED':
      return {
        ...state,
        isDataLoaded: action.isDataLoaded,
      }
  }
}

export interface UseWrapperReducerReturnType {
  data: FilterData
  isDataLoaded: boolean
  setData: (data: FilterData) => void
}
export default function useWrapperReducer(): UseWrapperReducerReturnType {
  const [state, dispatch] = useReducer(reducer, initialState)

  const setData = useRef((data) => {
    dispatch({
      type: 'SET_DATA',
      data,
      isDataLoaded: true,
    })
  }).current

  return {
    ...state,
    setData,
  }
}
